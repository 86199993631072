import React, {Component} from "react";
import moment from 'moment';
import { ProgramServices } from "./ProgramServices";
import { BudgetServices } from "../budget/BudgetServices";
import { Fieldset } from "primereact/fieldset";
import { Spinner } from "primereact/spinner";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import SelectGroup from 'react-select';
import { Button } from "primereact/button";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { GLAccountServices } from "../../scp/accounting/gl_accounts/GLAccountServices";
import { showNotification } from '../../core/service/NotificationService';
import { InputText } from "primereact/inputtext";
import { FieldWrapper } from '../../core/components/FieldWrapper';
import { BUDGET_TARGET_TYPE } from "../../constants";

const highlightChanged = (a, b) => {
    let flag = false;
        
    if(a){
        if(typeof a === "string" && typeof b === "number") {
            flag = Number(a) !== Number(b);
        } else {
            flag = a !== b;
        }
    }
    return flag;
}

const highlightLabelChanged = (a, b) => {
    return a !== b ? " labels-highlight" : "";
}

const highlightDateChanged = (a, b) => {
    let flag = false;
    if(typeof a === "string") {
        flag = a.length !== b.length;
    } else {
        const data = new Date(a);
        const termData = new Date(b);
        flag = data.getTime() !== termData.getTime();
    }
    return flag ? " input-highlight" : "";
}

export class PriceSettingsForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: this.props.data,
            termData: this.props.data,
            feeAssistances: [],
            errors: {},
            viewMode: true,
            isDirty: false,
        }
        this.programServices = new ProgramServices();
        this.glAccountServices = new GLAccountServices();
        this.budgetServices = new BudgetServices();
        // // Preserve the initial form state in a new object
        this.baseState = this.state;
    }

    componentDidMount(){
        this.loadAllGLAccounts('Cash');
        this.loadAllGLAccounts('Receivable');
        this.loadAllGLAccounts('Deferred');
        this.loadAllGLAccounts('Program');
        this.loadAllDeptFeeAssistances(this.props.campaignId, this.props.deptId);
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.data).length!==0){
    //         if(JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)){
    //             if(JSON.stringify(this.state.data) !== JSON.stringify(this.state.termData)){
    //                 this.props.formEvent.emit('ENTER_EDIT_MODE')
    //             }else{
    //                 this.props.updating(false);
    //             }
    //         }
    //     }
    // }
    
    loadAllDeptFeeAssistances = (campaignId, dept) => {
        if(dept && campaignId){
            this.budgetServices.getListBudgetOptionGroups(BUDGET_TARGET_TYPE.campaign, campaignId, dept)
            .then(res => {
                this.setState({feeAssistances: res});
            });
        }else{
            this.setState({feeAssistances: []});
        }
    }

    loadAllGLAccounts = (type) => {
        this.glAccountServices.getAccountList(null, type)
        .then(res => {
            res.unshift({value:null, label: 'Not use'});

            switch(type){
                case 'Cash':
                    this.setState({cashGLAccounts: res});
                    break;
                case 'Receivable':
                    this.setState({receivableGLAccounts: res});
                    break;
                case 'Deferred':
                    this.setState({deferredGLAccounts: res});
                    break;
                case 'Program':
                    this.setState({programGLAccounts: res});
                    break;
                case 'Assistance':
                    this.setState({scholarshipGLAccounts: res});
                    break;
                default: break;
            }
        });
    }

    handleSavePriceSetting = (e) => {
        this.programServices.savePriceSettings(this.state.data).then(res => {
            if(!res.errorCode){
                let tempData = res;
                tempData.applyTax = this.state.data.applyTax
                this.props.refreshData(tempData);
                showNotification('success', 'Success Message', 'Action Submitted');
                this.setState({
                    errors: {},
                    data: this.props.data,
                    termData: this.props.data
                });
                this.props.formEvent.emit('CHANGE_SAVED');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });

    }

    onCbAllowProratedChange = (value) => {
        this.setState({
            data: {...this.state.data, allowProrated: value}
        },() => {
                if(!value) this.setState({data: {...this.state.data, allowProrated: false}});
            }
        );

        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onCbUseGlobalStaffDiscountChange = (value) => {
        this.setState({
            data: {...this.state.data, useGlobalStaffDiscount: value}
        },() => {
                if(!value) this.setState({data: {...this.state.data, useCustomStaffDiscount: false}});
            }
        );

        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onFullPaymentDueDateChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                fullPaymentDueDate: e.value?moment(e.value).format(moment.HTML5_FMT.DATE):'',
                fullPaymentDueDateValue: e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onDeferredPaymentDueDateChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                deferredPaymentDueDate: e.value?moment(e.value).format(moment.HTML5_FMT.DATE):'',
                deferredPaymentDueDateValue: e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    addFrequency = () => {
        let tmpFrequencies = [...this.state.data.frequencies];
        tmpFrequencies.push({name: '', periodType: 'monthly', periodLength: 1, numberOfOccurances: 2});
        this.setState({
            data: {
                ...this.state.data,
                frequencies: tmpFrequencies
            }
        });
    }

    removeFrequency = (idx) => {
        let tmpFrequencies = [...this.state.data.frequencies];
        tmpFrequencies.splice(idx, 1);
        this.setState({
            data: {
                ...this.state.data,
                frequencies: tmpFrequencies
            }
        });
    }

    onFrequencyElmChange = (idx, key, value) => {
        let tmpFrequencies = [...this.state.data.frequencies];
        let frequencyItem = tmpFrequencies[idx];
        frequencyItem[key] = value;

        tmpFrequencies.splice(idx, 1, frequencyItem);
        this.setState({
            data: {
                ...this.state.data,
                frequencies: tmpFrequencies
            }
        });
    }

    onChangeDicarded = () => {
        this.setState(this.baseState);
    }

    resetChange = (keyField) => {
        this.setState({
            data: {
                ...this.state.data,
                [keyField]: this.state.termData[keyField],
            }
        })
    }

    resetDateChange = (keyField) => {
        let keyFieldLabel = keyField.replace("Value", "");
        this.setState({
            data: {
                ...this.state.data,
                [keyFieldLabel]: this.state.termData[keyFieldLabel],
                [keyField]: this.state.termData[keyField],
            }
        })
    }

    resetFrequencyChange = (keyField) => {
        this.setState({
            data: {...this.state.data, 
                frequencies:[{
                    [keyField]: this.state.termData.frequencies[keyField],
                }]
            }
        })
    }
    onBasicPriceGLAccountChange = (e) => {
        this.setState({ data: { ...this.state.data, 
                basicPriceGLAccount: e.value,
                membershipPriceGLAccount: this.state.data.membershipPriceGLAccount?this.state.data.membershipPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.data.staffPriceGLAccount?this.state.data.staffPriceGLAccount:e.value
            }
        })
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onMembershipPriceGLAccountChange = (e) => {
        this.setState({ data: { ...this.state.data, 
                membershipPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.data.basicPriceGLAccount?this.state.data.basicPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.data.staffPriceGLAccount?this.state.data.staffPriceGLAccount:e.value
            }
        })
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onStaffPriceGLAccountChange = (e) => {
        this.setState({ data: { ...this.state.data, 
                staffPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.data.basicPriceGLAccount?this.state.data.basicPriceGLAccount:e.value,
                membershipPriceGLAccount: this.state.data.membershipPriceGLAccount?this.state.data.membershipPriceGLAccount:e.value
            }
        })
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    displayFeeAssistance = () => {
        let parentLabel;
        let data;

        this.state.feeAssistances.map((val, idx) => {
            data = val.options.find(e => e.value==this.state.data.feeAssistanceId);
            parentLabel = val.label;

            if(data){
                parentLabel = val.label;
            }
        });

        return data?parentLabel + ' -> ' + data.label:'Not use';
    }

    getDefaultFeeAssistanceSelected = () => {
        let data;

        this.state.feeAssistances.map((val, idx) => {
            data = val.options.find(e => e.value==this.state.data.feeAssistanceId);
        });

        return data?data:'';
    }

    focusField = (e) => {
        if(e.target.value === '0') {
            e.target.select()
        }
    }

    render(){
        const periodTypes = [
            // {label: 'Weekly', value:'weekly'},
            // {label: 'Anually', value:'yearly'},
            {label: 'Monthly', value:'monthly'},
            {label: 'Biweekly', value:'biweekly'}
        ];

        const asterik = <span style={{color: 'red',}}>*</span>;

        return (
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Fieldset legend="General Fees">
                        <div className="p-grid p-fluid form-group">
                            {!this.props.viewMode?
                            <React.Fragment>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <FieldWrapper col="p-col-12 p-md-6" keyField="registrationFee" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.registrationFee, this.state.termData.registrationFee)}>
                                            <label className="p-label p-margin-bottom-10">Registration fee</label>
                                            <div className="p-inputgroup">
                                                <InputText keyfilter="money" value={this.state.data.registrationFee} onFocus={(e) => this.focusField(e)} onChange={(e) => (this.setState({data: { ...this.state.data, registrationFee: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                                    <Checkbox inputId="cbUseRegistrationFee" checked={this.state.data.useRegistrationFee} onChange={(e) => (this.setState({data: { ...this.state.data, useRegistrationFee: e.checked}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                    <label htmlFor="cbUseRegistrationFee" className={'p-margin-left-10' +  highlightLabelChanged(this.state.data.useRegistrationFee, this.state.termData.useRegistrationFee)}>Use?</label>
                                                </span>
                                            </div>
                                            <div className="p-form-error p-margin-top-5">{this.state.errors.registrationFee}</div>
                                        </FieldWrapper>
                                    </div>
                                </div>
                                <FieldWrapper col="p-col-12 p-md-6" keyField="basicPrice" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.basicPrice, this.state.termData.basicPrice)}>
                                    <label className="p-label p-margin-bottom-10">Non-member</label>
                                    <div className="p-inputgroup">
                                        <InputText keyfilter="money" value={this.state.data.basicPrice} onClick={(e) => this.focusField(e)} onFocus={(e) => this.focusField(e)}  onChange={(e) => (this.setState({data: { ...this.state.data, basicPrice: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                        <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                            <Checkbox inputId="cbNoneMemberUse" checked={this.state.data.noneMemberUse} onChange={(e) => (this.setState({data: { ...this.state.data, noneMemberUse: e.checked}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                            <label htmlFor="cbNoneMemberUse" className={'p-margin-left-10' +  highlightLabelChanged(this.state.data.noneMemberUse, this.state.termData.noneMemberUse)}>Use?</label>
                                        </span>
                                    </div>
                                    <div className="p-form-error p-margin-top-5">{this.state.errors.basicPrice}</div>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12 p-md-6" keyField="memberPrice" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.memberPrice, this.state.termData.memberPrice)}>
                                    <label className="p-label p-margin-bottom-10">Member</label>
                                    <div className="p-inputgroup">
                                        <InputText keyfilter="money" value={this.state.data.memberPrice} onFocus={(e) => this.focusField(e)} onChange={(e) => (this.setState({data: { ...this.state.data, memberPrice: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                        <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                            <Checkbox inputId="cbMembershipUse" checked={this.state.data.membershipUse} onChange={(e) => (this.setState({data: { ...this.state.data, membershipUse: e.checked}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                            <label htmlFor="cbMembershipUse" className={'p-margin-left-10' +  highlightLabelChanged(this.state.data.membershipUse, this.state.termData.membershipUse)}>Use?</label>
                                        </span>
                                    </div>
                                </FieldWrapper>
                                {/* <div className="p-col-12">
                                    <InputSwitch inputId="cbApplyTax" checked={this.state.data.applyTax} onChange={(e) => (this.setState({data: { ...this.state.data, applyTax: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                    <label htmlFor="cbApplyTax" className={'p-margin-left-10' +  highlightLabelChanged(this.state.data.applyTax, this.state.termData.applyTax)}>Apply tax?</label>
                                </div>
                                <div className="p-col-12">
                                    <InputSwitch inputId="cbAllowProrated" checked={this.state.data.allowProrated} onChange={(e) => this.onCbAllowProratedChange(e.value)}/>
                                    <label htmlFor="cbAllowProrated" className={'p-margin-left-10' + highlightLabelChanged(this.state.data.allowProrated, this.state.termData.allowProrated)}>Allow Prorated? </label>
                                </div> */}
                                <div className="p-col-12">
                                    <InputSwitch inputId="cbAllowPayFull" onLabel="Yes" offLabel="No" checked={this.state.data.allowPayFull} onChange={(e) => (this.setState({data: { ...this.state.data, allowPayFull: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                    <label htmlFor="cbAllowPayFull" className={'p-margin-left-10' + highlightLabelChanged(this.state.data.allowPayFull, this.state.termData.allowPayFull)}>Allow pay full? (Use in case draft payment or deposit enabled)</label>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <label className="">Registration fee: <strong>{this.state.data.registrationFee?' $' + this.state.data.registrationFee:''}{!this.state.data.useRegistrationFee?' (Not use)':''}</strong></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="">Non-member:<strong>{this.state.data.basicPrice?' $' + this.state.data.basicPrice:''}{!this.state.data.noneMemberUse?' (Not use)':''}</strong></label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="">Member:<strong>{this.state.data.memberPrice?' $' + this.state.data.memberPrice:''}{!this.state.data.membershipUse?' (Not use)':''}</strong></label>
                                </div>
                                {/* <div className="p-col-12">
                                    <label className="">Apply tax: <strong>{this.state.data.applyTax?'Yes':'No'}</strong></label>
                                </div>
                                <div className="p-col-12">
                                    <label className="">Allow Prorated: <strong>{this.state.data.allowProrated?'Yes':'No'}</strong></label>
                                </div> */}
                                <div className="p-col-12">
                                    <label className="">Allow pay full (Use in case draft payment or deposit enabled): <strong>{this.state.data.allowPayFull?'Yes':'No'}</strong></label>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </Fieldset>
                    {/* <Fieldset legend="Staff Discount">
                    {!this.props.viewMode ?
                        <div className="p-grid p-fluid form-group">         
                            <div className="p-col-12">
                                <InputSwitch inputId="cbUseGlobalStaffDiscount" checked={this.state.data.useGlobalStaffDiscount} onChange={(e) => this.onCbUseGlobalStaffDiscountChange(e.value)}/>
                                <label htmlFor="cbUseGlobalStaffDiscount" className={'p-margin-left-10' + highlightLabelChanged(this.state.data.useGlobalStaffDiscount, this.state.termData.useGlobalStaffDiscount)}>Allow Staff Discount? </label>
                            </div>
                            {this.state.data.useGlobalStaffDiscount?
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <InputSwitch inputId="cbUseCustomStaffDiscount" checked={this.state.data.useCustomStaffDiscount} onChange={(e) => (this.setState({data: {...this.state.data, useCustomStaffDiscount: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                        <label htmlFor="cbUseCustomStaffDiscount" className={'p-margin-left-10' + highlightLabelChanged(this.state.data.useCustomStaffDiscount, this.state.termData.useCustomStaffDiscount)}>Use custom input for discount or fixed fee?</label>
                                    </div>
                                    {this.state.data.useCustomStaffDiscount?
                                        <React.Fragment>
                                            <FieldWrapper col="p-col-12 p-md-6" keyField="staffPrice01" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.staffPrice01, this.state.termData.staffPrice01)}>
                                            <label className="p-label p-margin-bottom-15">Full-time</label>
                                                <div className="p-inputgroup">
                                                    <InputText keyfilter="money" value={this.state.data.staffPrice01} onFocus={(e) => this.focusField(e)} onChange={(e) => this.setState({data: { ...this.state.data, staffPrice01: e.target.value}})} />
                                                    <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                                        <Checkbox inputId="cbStaffUse" checked={this.state.data.staffFullTimeUse} onChange={(e) => this.setState({data: { ...this.state.data, staffFullTimeUse: e.checked}})} />
                                                        <label htmlFor="cbStaffUse" className={'p-margin-left-10' + highlightLabelChanged(this.state.data.staffFullTimeUse, this.state.termData.staffFullTimeUse)}>Use?</label>
                                                    </span>
                                                </div>
                                                <div className="p-form-error p-margin-top-5">{this.state.errors.staffPrice01}</div>
                                            </FieldWrapper>
                                            <FieldWrapper col="p-col-12 p-md-6" keyField="staffPrice02" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.staffPrice02, this.state.termData.staffPrice02)}>
                                            <label className="p-label p-margin-bottom-15">Part-time</label>
                                                <div className="p-inputgroup">
                                                    <InputText keyfilter="money" value={this.state.data.staffPrice02} onFocus={(e) => this.focusField(e)} onChange={(e) => (this.setState({data: { ...this.state.data, staffPrice02: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                    <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                                        <Checkbox inputId="cbPartimeUse" checked={this.state.data.staffPartTimeUse} onChange={(e) => (this.setState({data: { ...this.state.data, staffPartTimeUse: e.checked}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                        <label htmlFor="cbPartimeUse" className={'p-margin-left-10' + highlightLabelChanged(this.state.data.staffPartTimeUse, this.state.termData.staffPartTimeUse)}>Use?</label>
                                                    </span>
                                                </div>
                                                <div className="p-form-error p-margin-top-5">{this.state.errors.staffPrice02}</div>
                                            </FieldWrapper>
                                            <div className="p-col-12">
                                                <Checkbox inputId="cbStaffPricePercentage" checked={this.state.data.staffPricePercentage} onChange={(e) => (this.setState({data: { ...this.state.data, staffPricePercentage: e.checked}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                <label htmlFor="cbStaffPricePercentage" className={'p-checkbox-label' + highlightLabelChanged(this.state.data.staffPricePercentage, this.state.termData.staffPricePercentage)}>Use % discount base on general fee?</label>
                                            </div>
                                        </React.Fragment>
                                        :''
                                    }
                                </React.Fragment>
                                :''
                            }    
                        </div>
                        :
                        <div className="p-grid p-fluid form-group">         
                            <div className="p-col-12">
                                <label>Allow staft discount: <strong>{this.state.data.useGlobalStaffDiscount ? 'Yes' : 'No'}</strong></label>
                            </div>
                            {this.state.data.useGlobalStaffDiscount?
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <label>Use custom input for discount or fixed fee: <strong>{this.state.data.useCustomStaffDiscount ? 'Yes' : 'No'}</strong></label>
                                    </div>
                                    {this.state.data.useCustomStaffDiscount?
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-6">
                                                <label className="">Full-time: <strong>{this.state.data.staffPrice01 && !this.state.data.staffPricePercentage?'$':''}{this.state.data.staffPrice01?this.state.data.staffPrice01:''} {this.state.data.staffPrice01 && this.state.data.staffPricePercentage?'%':''}{!this.state.data.staffFullTimeUse?' (Not use)':''}</strong></label>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="">Part-time: <strong>{this.state.data.staffPrice02 && !this.state.data.staffPricePercentage?'$':''}{this.state.data.staffPrice02?this.state.data.staffPrice02:''} {this.state.data.staffPrice02 && this.state.data.staffPricePercentage?'%':''}{!this.state.data.staffPartTimeUse?' (Not use)':''}</strong></label>
                                            </div>
                                        </React.Fragment>
                                        :''
                                    }
                                </React.Fragment>
                                :''
                            }    
                        </div>
                    }
                    </Fieldset> */}
                    {this.state.data && this.state.data.workType==='simple'?
                        <React.Fragment>
                            {/* <Fieldset legend="Draft payment">
                                {!this.props.viewMode ?
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.data.allowDraftPayment} onChange={(e) => (this.setState({data: { ...this.state.data, allowDraftPayment: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                        <label className={'p-margin-left-10' + highlightLabelChanged(this.state.data.allowDraftPayment, this.state.termData.allowDraftPayment)}>Allow draft payment?</label> {this.state.data.allowDraftPayment && !this.state.data.splitPrice?<label className="p-w-bold p-margin-left-15"><i>(* <u>Note:</u> General fee will be fee/month)</i></label>:''}
                                    </div>
                                    {this.state.data.allowDraftPayment &&
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.data.splitPrice} onChange={(e) => (this.setState({data: { ...this.state.data, splitPrice: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                <label className={'p-margin-left-10' + highlightLabelChanged(this.state.data.splitPrice, this.state.termData.splitPrice)}>Split the price?</label>
                                            </div>
                                            <div className="p-col-12">
                                                {this.state.data.frequencies.map((elm, idx) => {
                                                    return (
                                                        <div key={"fequency_"+idx}>
                                                            <Fieldset legend={"Draft Option " + (idx+1)}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-10">
                                                                        <div className="p-grid p-fluid form-group">
                                                                            <FieldWrapper col="p-col-5" keyField="name" resetChange={this.resetFrequencyChange}>
                                                                                <label className="p-label">Label</label>
                                                                                <InputText value={elm.name} onChange={(e) => this.onFrequencyElmChange(idx, 'name', e.target.value)}/>
                                                                            </FieldWrapper>

                                                                            <FieldWrapper col="p-col-12 p-md-4" keyField="periodType" resetChange={this.resetFrequencyChange}>
                                                                                <label className="p-label">Occurence type</label>
                                                                                <Dropdown value={elm.periodType} options={periodTypes} onChange={(e) => this.onFrequencyElmChange(idx, 'periodType', e.value)} style={{width:'100%'}} placeholder="Select a period type"/>
                                                                            </FieldWrapper>

                                                                            //<FieldWrapper col="p-col-12 p-md-4" keyField="periodLength" resetChange={this.resetFrequencyChange}>
                                                                            //    <label className="p-label">Recurrence</label>                                        
                                                                            //    <InputText type="text" keyfilter="pint" value={elm.periodLength} onChange={(e) => this.onFrequencyElmChange(idx, 'periodLength', Number(e.target.value))} />
                                                                            //</FieldWrapper>

                                                                            <FieldWrapper col="p-col-12 p-md-3" keyField="numberOfOccurances" resetChange={this.resetFrequencyChange}>
                                                                                <label className="p-label"># of Occurences</label>
                                                                                <InputText type="text" keyfilter="pint" value={elm.numberOfOccurances} onFocus={(e) => this.focusField(e)} onChange={(e) => this.onFrequencyElmChange(idx, 'numberOfOccurances', Number(e.target.value))} />
                                                                                
                                                                            </FieldWrapper>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-col-2">
                                                                        {this.state.data.frequencies.length>1?
                                                                            <div className="p-col-12 p-md-1">
                                                                                <Button icon="pi-md-close" className="p-button-danger" style={{top: "10px"}} onClick={() => this.removeFrequency(idx)}/>
                                                                            </div>
                                                                        :''}
                                                                    </div>
                                                                </div>
                                                            </Fieldset>
                                                        </div>   
                                                    )
                                                })}
                                                <div className="p-form-error">{this.state.errors.frequency}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <Button label="Add Draft Option" style={{width: 'auto'}} icon="pi pi-plus" iconPos="left" onClick={this.addFrequency}/>
                                            </div>
                                            //<div className="p-col-6">
                                            //    <label className="p-label">Occurence payment day of month</label>                                        
                                            //    <Spinner value={this.state.data.occurancePayAtDay} min={1} max={28} onChange={(e) => (this.setState({data: { ...this.state.data, occurancePayAtDay: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                            //</div>
                                        </React.Fragment>
                                    }       
                                </div>
                                :
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <label>Allow draft payment: <strong>{this.state.data.allowDraftPayment ? 'Yes' : 'No'}</strong></label> {this.state.data.allowDraftPayment && !this.state.data.splitPrice?<label className="p-w-bold p-margin-left-15"><i>(* <u>Note:</u> General fee will be fee/month)</i></label>:''}
                                    </div>
                                    {this.state.data.allowDraftPayment && 
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label>Split the price: <strong>{this.state.data.splitPrice ? 'Yes' : 'No'}</strong></label>
                                            </div>
                                            <div className="p-col-12">
                                                {this.state.data.frequencies.map((elm, idx) => {
                                                    return (
                                                        <label key={"fequency_"+idx} className="p-margin-bottom-10" style={{display: 'block'}}>Draft Option #{idx+1}: <strong>{elm.name}</strong></label>
                                                    )
                                                })}
                                            </div>
                                        </React.Fragment>
                                    }       
                                </div>
                                }
                            </Fieldset> */}
                            <Fieldset legend="Deposit">
                                <div className="p-grid p-fluid form-group">
                                    {!this.props.viewMode?
                                    <React.Fragment>
                                        <div className="p-col-12">
                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.data.allowDeposit} onChange={(e) => (this.setState({data: { ...this.state.data, allowDeposit: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                            <label className={'p-margin-left-10' + highlightLabelChanged(this.state.data.allowDeposit, this.state.termData.allowDeposit)}>Allow deposit?</label>
                                        </div>
                                        {this.state.data.allowDeposit?
                                        <React.Fragment>
                                            <FieldWrapper col="p-col-12 p-md-6" keyField="depositAmount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.depositAmount,this.state.termData.depositAmount)}>
                                                <label className="p-label">* Deposit amount</label>
                                                <InputText type="text" keyfilter="pint" value={this.state.data.depositAmount} onFocus={(e) => this.focusField(e)} onChange={(e) => (this.setState({data: { ...this.state.data, depositAmount: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                <div className="p-form-error">{this.state.errors.depositAmount}</div>
                                            </FieldWrapper>
                                            <FieldWrapper col="p-col-12 p-md-6" keyField="fullPaymentDueDateValue" resetChange={this.resetDateChange} highlight={highlightDateChanged(this.state.data.fullPaymentDueDateValue,this.state.termData.fullPaymentDueDateValue)}>
                                                <label className="p-label">* Full payment due date</label>
                                                <MaskedCalendar value={this.state.data.fullPaymentDueDateValue} showIcon={true} onChange={this.onFullPaymentDueDateChange} dateFormat="mm-dd-yy" />
                                                <div className="p-form-error">{this.state.errors.fullPaymentDueDate}</div>
                                            </FieldWrapper>
                                        </React.Fragment>
                                        :''}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="p-col-12">
                                            <label>Allow deposit: <strong>{this.state.data.allowDeposit ? 'Yes' : 'No'}</strong></label>
                                        </div>
                                        {this.state.data.allowDeposit?
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-6">
                                                <label>Deposit amount: <strong>{this.state.data.depositAmount}</strong></label>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label>Full payment due date: <strong>{this.state.data.fullPaymentDueDate ? this.state.data.fullPaymentDueDate : 'None'}</strong></label>
                                            </div>
                                        </React.Fragment>
                                        :''}
                                    </React.Fragment>
                                    }
                                </div>
                            </Fieldset>
                            {/* <Fieldset legend="Waitlsit Registration">
                                <div className="p-grid p-fluid form-group">
                                    {!this.props.viewMode?
                                    <React.Fragment>
                                        <div className="p-col-12">
                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.data.allowWaitingReg} onChange={(e) => (this.setState({data: { ...this.state.data, allowWaitingReg: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                            <label className={'p-margin-left-10'+ highlightLabelChanged(this.state.data.allowWaitingReg, this.state.termData.allowWaitingReg)}>Allow registration as waiting when full?</label>
                                        </div>
                                        {this.state.data.allowWaitingReg?
                                        <React.Fragment>
                                            <FieldWrapper col="p-col-12 p-md-6" keyField="waitingRegMaxCount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.waitingRegMaxCount,this.state.termData.waitingRegMaxCount)}>
                                                <label className="p-label">* Max count</label>
                                                <InputText type="text" keyfilter="pint" value={this.state.data.waitingRegMaxCount ? this.state.data.waitingRegMaxCount : 0} onFocus={(e) => this.focusField(e)} onChange={(e) => (this.setState({data: { ...this.state.data, waitingRegMaxCount: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                <div className="p-form-error">{this.state.errors.waitingRegMaxCount}</div>
                                            </FieldWrapper>
                                            <FieldWrapper col="p-col-12 p-md-6" keyField="waitingRegDepositAmount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.waitingRegDepositAmount,this.state.termData.waitingRegDepositAmount)}>
                                                <label className="p-label">Waiting reg. deposit amount</label>
                                                <InputText type="text" keyfilter="pint" value={this.state.data.waitingRegDepositAmount ? this.state.data.waitingRegDepositAmount : 0} onFocus={(e) => this.focusField(e)} onChange={(e) => (this.setState({data: { ...this.state.data, waitingRegDepositAmount: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                                <div className="p-form-error">{this.state.errors.waitingRegDepositAmount}</div>
                                            </FieldWrapper>
                                        </React.Fragment>
                                        :''}
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="p-col-12">
                                            <label>Allow registration as waiting when full: <strong>{this.state.data.allowWaitingReg ? 'Yes' : 'No'}</strong></label>
                                        </div>
                                        {this.state.data.allowWaitingReg?
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-6">
                                                <label >Max count: <strong>{this.state.data.waitingRegMaxCount ? this.state.data.waitingRegMaxCount : 0}</strong></label>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label>Waiting reg. deposit amount: <strong>{this.state.data.waitingRegDepositAmount ? this.state.data.waitingRegDepositAmount : 0}</strong></label>
                                            </div>
                                        </React.Fragment>
                                        :''}
                                    </React.Fragment>
                                    }
                                </div>
                            </Fieldset> */}
                        </React.Fragment>
                        :''
                    }
                </div>
                {/* <div className="p-col-12 p-md-6">
                    <Fieldset legend="Scholarship">
                        <div className="p-grid p-fluid form-group">
                        {!this.props.viewMode?
                        <React.Fragment>
                            <div className="p-col-12">
                                <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.data.scholarship} onChange={(e) => (this.setState({data: { ...this.state.data, scholarship: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                <label className={'p-margin-left-10' + highlightLabelChanged(this.state.data.scholarship, this.state.termData.scholarship)}>Scholarship support?</label>
                            </div>
                            {this.state.data.scholarship?
                            <FieldWrapper col="p-col-12" keyField="feeAssistanceId" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.feeAssistanceId, this.state.termData.feeAssistanceId)}>
                                <label className="p-label">{asterik}Fee assistance</label>
                                <SelectGroup isClearable={true} defaultValue={this.getDefaultFeeAssistanceSelected()} options={this.state.feeAssistances} onChange={(e) => (this.setState({ data: { ...this.state.data, feeAssistanceId: e ? e.value : ''}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} style={{ width: '100%' }} />
                                <div className="p-form-error">{this.state.errors.feeAssistanceId}</div>
                            </FieldWrapper>
                            :
                            ''}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="p-col-12">
                                <label>Scholarship support: <strong>{this.state.data.scholarship ? 'Yes' : 'No'}</strong></label>
                            </div>
                            {this.state.data.scholarship?
                            <div className="p-col-12">
                                <label>Fee assistance: <strong>{this.displayFeeAssistance()}</strong></label>
                            </div>
                            :''}
                        </React.Fragment>
                        }
                        </div>
                    </Fieldset>
                    <Fieldset legend="Accounting">
                        <div className="p-grid p-fluid form-group">
                            {!this.props.viewMode?
                            <React.Fragment>
                                <FieldWrapper col="p-col-12" keyField="cashGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.cashGLAccount, this.state.termData.cashGLAccount)}>
                                    <label className="p-label">{asterik} Cash account</label>
                                    <Dropdown value={this.state.data.cashGLAccount} options={this.state.cashGLAccounts} filter={true} onChange={(e) => (this.setState({data: { ...this.state.data, cashGLAccount: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                    <div className="p-form-error">{this.state.errors.cashGLAccount}</div>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12" keyField="receivableGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.receivableGLAccount, this.state.termData.receivableGLAccount)}>
                                    <label className="p-label">{asterik} Account receivable</label>
                                    <Dropdown value={this.state.data.receivableGLAccount} options={this.state.receivableGLAccounts} filter={true} onChange={(e) => (this.setState({data: { ...this.state.data, receivableGLAccount: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                    <div className="p-form-error">{this.state.errors.receivableGLAccount}</div>
                                </FieldWrapper>
                                <div className="p-fieldset-line"></div>
                                <div className="p-col-12 p-margin-top-10">
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.data.deferred} onChange={(e) => (this.setState({data: { ...this.state.data, deferred: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                    <label className={'p-margin-left-10' + highlightLabelChanged(this.state.data.deferred, this.state.termData.deferred)}>Is Deferred?</label>
                                </div>
                                <FieldWrapper col="p-col-12" keyField="deferredGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.deferredGLAccount, this.state.termData.deferredGLAccount)}>
                                    <label className="p-label">{asterik} Deferred Account</label>
                                    <Dropdown value={this.state.data.deferredGLAccount} options={this.state.deferredGLAccounts} filter={true} onChange={(e) => (this.setState({data: { ...this.state.data, deferredGLAccount: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} />
                                    <div className="p-form-error">{this.state.errors.deferredGLAccount}</div>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12" keyField="deferredPaymentDueDateValue" resetChange={this.resetDateChange} highlight={highlightDateChanged(this.state.data.deferredPaymentDueDateValue, this.state.termData.deferredPaymentDueDateValue)}>
                                    <label className="p-label">{asterik} {this.state.data.allowDraftPayment?'Occurence deferred payment day start at':'Deferred payment due date'}</label>
                                    {this.state.data.allowDraftPayment?
                                        <Spinner value={this.state.data.deferredPaymentDueDate} min={1} max={28} onChange={(e) => (this.setState({data: { ...this.state.data, deferredPaymentDueDate: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                        :
                                        <MaskedCalendar value={this.state.data.deferredPaymentDueDateValue} showIcon={true} onChange={this.onDeferredPaymentDueDateChange} dateFormat="mm-dd-yy" />
                                    }
                                    <div className="p-form-error">{this.state.errors.deferredPaymentDueDate}</div>
                                </FieldWrapper>
                                {this.state.data.useRegistrationFee?
                                <React.Fragment>
                                    <div className="p-fieldset-line"></div>
                                    <FieldWrapper col="p-col-12 p-margin-top-10" keyField="registrationFeeGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.registrationFeeGLAccount, this.state.termData.registrationFeeGLAccount)}>
                                        <label className="p-label">{asterik} Registration fee</label>
                                        <Dropdown value={this.state.data.registrationFeeGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => (this.setState({data: { ...this.state.data, registrationFeeGLAccount: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} style={{width: "100%"}} />
                                        <div className="p-form-error">{this.state.errors.registrationFeeGLAccount}</div>
                                    </FieldWrapper>
                                </React.Fragment>
                                :''}
                                <div className="p-fieldset-line"></div>
                                <div className="p-col-12">
                                    <div className="p-hr">Program Revenue Account</div>
                                </div>
                                <FieldWrapper col="p-col-12" keyField="basicPriceGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.basicPriceGLAccount, this.state.termData.basicPriceGLAccount)}>
                                    <label className="p-label">{asterik} Non-member</label>
                                    <Dropdown value={this.state.data.basicPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onBasicPriceGLAccountChange(e)} style={{width: "100%"}} />
                                    <div className="p-form-error">{this.state.errors.basicPriceGLAccount}</div>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12" keyField="membershipPriceGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.membershipPriceGLAccount, this.state.termData.membershipPriceGLAccount)}>
                                    <label className="p-label"> Member</label>
                                    <Dropdown value={this.state.data.membershipPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onMembershipPriceGLAccountChange(e)} style={{width: "100%"}} />
                                    <div className="p-form-error">{this.state.errors.membershipPriceGLAccount}</div>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12" keyField="staffPriceGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.staffPriceGLAccount, this.state.termData.staffPriceGLAccount)}>
                                    <label className="p-label">{asterik} Staff</label>
                                    <Dropdown value={this.state.data.staffPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onStaffPriceGLAccountChange(e)} style={{width: "100%"}} />
                                    <div className="p-form-error">{this.state.errors.staffPriceGLAccount}</div>
                                </FieldWrapper>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label>Cash account: <strong>{this.state.data.cashGLAccount ? this.state.data.cashGLAccount : 'N/A'}</strong></label>
                                </div>
                                <div className="p-col-12">
                                    <label>Account receivable: <strong>{this.state.data.receivableGLAccount ? this.state.data.receivableGLAccount : 'N/A'}</strong></label>
                                </div>
                                <div className="p-fieldset-line"></div>
                                <div className="p-col-12 p-margin-top-10">
                                    <label>Is Deferred: <strong>{this.state.data.deferred ? 'Yes' : 'No'}</strong></label>
                                </div>
                                {this.state.data.deferred?
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <label>Deferred account: <strong>{this.state.data.deferredGLAccount? this.state.data.deferredGLAccount : 'N/A' }</strong></label>
                                    </div>
                                    <div className="p-col-12">
                                        <label>
                                            {this.state.data.allowDraftPayment?'Occurence deferred payment day start at':'Deferred payment due date'}:&nbsp;
                                            <React.Fragment>
                                            {this.state.data.allowDraftPayment?
                                            <strong>{this.state.data.deferredPaymentDueDate ? this.state.data.deferredPaymentDueDate : 1}</strong>
                                            :
                                            <strong>{this.state.data.deferredPaymentDueDateValue ? moment(this.state.data.deferredPaymentDueDateValue).format('MM-DD-YYYY'): 'N/A'}</strong>
                                            }
                                            </React.Fragment>
                                        </label>
                                    </div>
                                </React.Fragment>
                                :''}
                                {this.state.data.useRegistrationFee?
                                <React.Fragment>
                                    <div className="p-fieldset-line"></div>
                                    <div className="p-col-12 p-margin-top-10">
                                        <label>Registration fee: <strong>{this.state.data.registrationFeeGLAccount ? this.state.data.registrationFeeGLAccount : 'N/A'}</strong></label>
                                    </div>
                                </React.Fragment>
                                :''}
                                <div className="p-fieldset-line"></div>
                                <div className="p-col-12">
                                    <div className="p-hr">Program Revenue Account</div>
                                </div>
                                <div className="p-col-12">
                                    <label>Non-member: <strong>{this.state.data.basicPriceGLAccount ? this.state.data.basicPriceGLAccount : 'N/A' }</strong></label>
                                </div>
                                <div className="p-col-12">
                                    <label>Member: <strong>{this.state.data.membershipPriceGLAccount ? this.state.data.membershipPriceGLAccount : 'N/A'}</strong></label>
                                </div>
                                <div className="p-col-12">
                                    <label>Staff: <strong>{this.state.data.staffPriceGLAccount ? this.state.data.staffPriceGLAccount : 'N/A' }</strong></label>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </Fieldset>
                </div> */}
                
                {this.state.data.id?
                    <div className="p-col-12">
                        {!this.props.viewMode ?
                        <React.Fragment>
                            <Button label="Cancel Edit" icon="pi pi-lock" iconPos="right" style={{ 'float': 'right' }} onClick={(e) => this.props.onCancelViewMode()} />
                            <Button label="Save Price Settings" icon="pi pi-save" iconPos="left" style={{'float':'right'}} onClick={(e) => this.handleSavePriceSetting()}/>   
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button label={this.props.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={this.props.acquireFormLock} />
                        </React.Fragment>
                        }
                    </div>
                    :''
                }
            </div>
        );
    }
}