import React, { Component } from 'react';
import moment from 'moment';
import { ProgramServices } from './ProgramServices';
import { AttributeServices, getListAttributeSets } from '../../scp/attribute/AttributeServices';
import { DepartmentServices, getListDepartments } from "../../scp/departments/DepartmentServices";
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { withNamespaces } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

import { AttributeForm } from "./AttributeForm";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { Spinner } from 'primereact/spinner';
import { RegistrationSettingsForm } from './RegistrationSettingsForm';
import { SplitButton } from 'primereact/splitbutton';
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { PROG_STATUSES, TABLE_PAGING_LENGTH, BUDGET_TARGET_TYPE, PROGRAM_WORK_TYPE, HR_RESOURCE_TYPE, PROPERTY_TYPE, ATTRIBUTE_FIELD_SET_TYPE} from '../../constants';
import { Dialog } from 'primereact/dialog';
import { NotifyChangeTab } from '../../core/NotifyChangeTab';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { showNotification } from '../../core/service/NotificationService';
import { GLAccountServices } from '../../scp/accounting/gl_accounts/GLAccountServices';
import { BudgetServices } from '../budget/BudgetServices';
import { getListGrades } from '../../scp/tenant-configuration/ConfigruationServices';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { CloneProgramForm } from './CloneProgramForm';
import { AuditLog } from '../../audit/AuditLog';
import { TalentServices } from '../../constituent-management/talent/TalentServices';
import SelectGroup from 'react-select';
import PreventTransitionPrompt from '../../core/PreventTransitionPrompt';
import { FormLockServices } from '../../core/FormLockServices';
import { NotifyLocked } from '../../core/NotifyLocked';
import EventEmitter from 'eventemitter3';
import { NotifyOutDated } from '../../core/NotifyOutDated';
import { FieldWrapper } from '../../core/components/FieldWrapper';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Tooltip } from 'react-tippy';
import { SelectButton } from 'primereact/selectbutton';
import { Sidebar } from 'primereact/sidebar';
import { DepartmentForm } from '../../scp/departments/DepartmentForm';
import { CampaignForm } from '../../scp/campaign/CampaignForm';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import classNames from 'classnames';
import { CancelProgramForm } from './CancelProgramForm';
import { getListHRs } from '../human_resource/HRServices';
import { getLabelFromListSimple } from '../../core/service/ControlServices';

const formEvent = new EventEmitter();
const highlightChanged = (a, b) => {
    let flag = false;
    if (Array.isArray(a)) {
        if ((a === [] && b === undefined) || (a === [] && b === []) || (a == [] && b !== [])) {
            flag = false;
        } else if ((a !== [] && b === undefined) || (a !== [] && b === [])) {
            flag = true;
        } else {

            flag = a.length !== b.length && a.sort() !== b.sort();
        }
    } else {
        flag = a !== b;
    }
    return flag ? " labels-highlight" : "";
}

const highlightLabelChanged = (a, b) => {
    return a !== b ? " labels-highlight" : "";
}


class ProgramDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddCourse: false,
            goToDetail: '',
            program: {
                instructorIds: [],
                calendars: []
            },
            course: {
                instructorIds: []
            },
            coursesList: [],
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            activeTabIndex: 0,
            viewMode: true,
            isDirty: false,
            discardVisible: false,
            filter: {
                campaignId: '',
                status: '',
                currentTalent: '',
                currentLevel: '',
                requireTalent: '',
                requireLevel: ''
            },
            headerCheckbox: false,
            arrCourses: [],
            errors: {},
            visibleChangeTab: false,
            isDirty: false,
            tmpProgram: {},
            talents: [],
            talentsLevels: [],
            departments: [],
            calendars: [],
            categories: [],
            feeAssistances: [],
            instructors: [],
            trainers: [],
            tags: [],
            formKey: '',
            editingUser: {},
            showLockedDialog: false,
            showOutDatedDialog: false,
            editButtonLabel: "Edit",
            dataTemplateAllCourse: {},
            tmpImage: {}
        };

        this.programServices = new ProgramServices();
        this.campaignServices = new CampaignServices();
        this.attributeServices = new AttributeServices();
        this.departmentServices = new DepartmentServices();
        this.glAccountServices = new GLAccountServices();
        this.budgetServices = new BudgetServices();
        this.talentServices = new TalentServices();
        this.formlockServices = new FormLockServices();
    }

    componentWillMount() {
        formEvent.on('CHANGE_SAVED', this.onChangeSaved);
        formEvent.on('RELOAD', this.reloadProgram);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.loadAllCalendar();
        this.getProgram();
        this.loadHRs(HR_RESOURCE_TYPE.marshaller.value);
        this.loadHRs(HR_RESOURCE_TYPE.trainer.value);
        this.loadProperties(PROPERTY_TYPE.location.value);
        this.loadProperties(PROPERTY_TYPE.branch.value);

        window.onbeforeunload = () => {
            if (this.state.isDirty || !this.state.viewMode) {
                this.releaseFormLock();
                return "Leave?";
            }
        }
    }

    resize = () => {
        this.setState({changeScreenSize: window.innerWidth <= 600});
    }

    componentWillUnmount() {
        formEvent.removeListener('CHANGE_SAVED', this.onChangeSaved);
        formEvent.removeListener('RELOAD', this.reloadProgram);
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.program).length!==0){
    //         if(JSON.stringify(prevState.program) !== JSON.stringify(this.state.program)){
    //             if(JSON.stringify(this.state.program) !== JSON.stringify(this.state.tmpProgram)){
    //                 this.setState({ isDirty: true })
    //             }else{
    //                 this.setState({ isDirty: false })
    //             }
    //         }
    //     }
    // }

    getProgram = () => {
        this.programServices.getProgram(this.props.match.params.id, 'program')
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        program: {
                            ...res,
                            typeUpdateDataTemplateToCourse: 'all',
                            updateDataTemplateToSpecificCourses: []
                        },
                        tmpProgram: res
                    });
                    this.loadAllDepartments();
                    this.loadAllDeptCategories(res.departmentId);
                    this.loadAllCampaigns();
                    this.loadLocations();
                    this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.program);
                    this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.custom_field);
                    this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.waiver);
                    this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.personal);
                    this.loadAllGLAccounts('Cash');
                    this.loadAllGLAccounts('Receivable');
                    this.loadAllGLAccounts('Deferred');
                    this.loadAllGLAccounts('Program');
                    this.loadAllGLAccounts('Assistance');
                    // this.loadAllGrades();
                    this.getPageProgramCourses();
                    this.loadDepartmentTalentsLevels(res.departmentId);
                    this.loadTalentsLevels(res.id);
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                }
            });
    }

    reloadProgram = () => {
        this.programServices.getProgram(this.props.match.params.id, 'program')
            .then(res => {
                if (!res.errorCode) {
                    this.setState({ program: res, tmpProgram: res, showOutDatedDialog: false });
                }
            });
    }

    getListAttributeSet = (type) => {
        getListAttributeSets(type)
            .then(res => {
                res.unshift({ value: null, label: 'Not use' });
                switch (type) {
                    case ATTRIBUTE_FIELD_SET_TYPE.program:
                        this.setState({ attrSets: res });
                        break;
                    case ATTRIBUTE_FIELD_SET_TYPE.custom_field:
                        this.setState({ customFieldSets: res });
                        break;
                    case ATTRIBUTE_FIELD_SET_TYPE.waiver:
                        this.setState({ waiverFieldSets: res });
                        break;
                    case ATTRIBUTE_FIELD_SET_TYPE.personal:
                        this.setState({personalFieldSets: res});
                        break;
                    default: break;
                }
            });
    }

    loadAllGrades = () => {
        getListGrades()
            .then(res => {
                this.setState({ grades: res });
            });
    }

    loadAllGLAccounts = (type) => {
        this.glAccountServices.getAccountList(null, type)
            .then(res => {
                res.unshift({ value: null, label: 'Not use' });

                switch (type) {
                    case 'Cash':
                        this.setState({ cashGLAccounts: res });
                        break;
                    case 'Receivable':
                        this.setState({ receivableGLAccounts: res });
                        break;
                    case 'Deferred':
                        this.setState({ deferredGLAccounts: res });
                        break;
                    case 'Program':
                        this.setState({ programGLAccounts: res });
                        break;
                    case 'Assistance':
                        this.setState({ scholarshipGLAccounts: res });
                        break;
                    default: break;
                }
            });
    }

    loadAllBudgetFeeAssistances = (campaignId, dept) => {
        if (dept && campaignId) {
            this.budgetServices.getListBudgetOptionGroups(BUDGET_TARGET_TYPE.campaign, campaignId, dept)
                .then(res => {
                    this.setState({ feeAssistances: res });
                });
        } else {
            this.setState({ feeAssistances: [] });
        }
    }

    loadAllCampaigns = () => {
        const inactive = false;
        const active = true;
        this.campaignServices.getListCampaign(active).then(res => { this.setState({ activeCampaigns: res ? res : [] }) });
        this.campaignServices.getListCampaign(inactive).then(res => { this.setState({ campaigns: res ? res : [] }) });
    }

    loadLocations = () => {
        // getListProperties({type: PROPERTY_TYPE.location.value}, true).then(res => { this.setState({ locations: res ? res : [] }) });
    }

    loadAllDepartments = () => {
        getListDepartments().then(res => { this.setState({ departments: res ? res : [] }) });
    }

    loadAllDeptCategories = () => {
        // getCategories({departmentId: dept}).then(res => { this.setState({ categories: res ? res : [] }) });
    }

    loadHRs = (type) => {
        getListHRs({type: type})
        .then(res => {
            switch (type) {
                case HR_RESOURCE_TYPE.marshaller.value:
                    this.setState({ instructors: res })
                    break;
                case HR_RESOURCE_TYPE.trainer.value:
                    this.setState({ trainers: res })
                    break;
                default: break;
            }
        })
    }

    loadProperties = () => {
        // getListProperties({type: type})
        // .then(res => {
        //     switch (type) {
        //         case PROPERTY_TYPE.location.value:
        //             this.setState({ locations: res })
        //             break;
        //         case PROPERTY_TYPE.branch.value:
        //             this.setState({ branchs: res })
        //             break;
        //         default: break;
        //     }
        // });
    }

    loadAllCalendar = () => {
        this.programServices.getCalendars()
            .then(res => { this.setState({ calendars: res ? res : [] }) });
    }

    handleLockSaveBasicInformation = () => {
        this.programServices.acquireFormLock('basicInformation', this.state.program.id).then(() => {
            // console.log(res);
        });
    }
    //#region Information
    handleSaveBasicInformation = () => {
        this.programServices.saveBasicInformation(this.state.program).then(res => {
            if (!res.errorCode) {
                this.setState({
                    isDirty: false,
                    viewMode: true,
                });
                formEvent.emit("CHANGE_SAVED");
                showNotification('success', 'Success Message', 'Action submitted');
                this.refreshData(this.state.program);
            } else {
                showNotification('error', 'Error Message', res.errorMessage);
                if (res.errorCode === 400)
                    this.setState({ errors: res.errorObj });
            }
        });
    }

    onDepartmentChange = (e) => {
        let dept = e.value;
        this.setState({
            program: {
                ...this.state.program,
                departmentId: dept,
                categoryId: this.state.tmpProgram.departmentId===dept ? this.state.tmpProgram.categoryId : '',
                enabledTalents: this.state.tmpProgram.departmentId===dept ? this.state.tmpProgram.enabledTalents : [],
            },
            isDirty: true
        });

        this.loadAllDeptCategories(dept);
        this.loadDepartmentTalentsLevels(dept);
    }

    loadDepartmentTalentsLevels = (id) => {
        this.departmentServices.getDepartmentTalentsLevels(id)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        talents: res
                    });
                }
            });
    }

    loadTalentsLevels = (id) => {
        this.programServices.getTalentsLevels(id)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        talentsLevels: res
                    });
                }
            });
    }

    displayByListSimpleKeyValue = (list, id) => {
        if(!list || !id) return 'Not use'
        const item = list.find(item => item.value === id);
        return (
            <div>
                {item ? item.label : 'Not use'}
            </div>
        )
    }

    displayDepartment = (id) => {
        const department = this.state.departments.find(department => department.value === id);
        return (
            <div>
                {department ? department.label : 'Not use'}
            </div>
        )
    }

    displayCategory = (id) => {
        const category = this.state.categories.find(category => category.value === id);
        return (
            <div>
                {category ? category.label : 'Not use'}
            </div>
        )
    }

    displayCalendar = (data) => {
        let arrayHolder = [];

        // for (let i = 0; i < data.length; i++) {
        //     for (let j = 0; j < calendars.length; j++) {
        //         if (data[i] === calendars[j].value) {
        //             arrayHolder.push(calendars[j].label)
        //             arrayHolder.push(",")
        //         }
        //     }
        // }
        for (let i = 0; i < data.length; i++) {
            arrayHolder.push(data[i].label)
            arrayHolder.push(",")
        }
        return (
            arrayHolder
        )
    }

    displayDefaulCalendar = () => {
        let data = this.state.program.calendars;
        let arrayHolder = [];
        for (let i = 0; i < data.length; i++) {
            arrayHolder.push(data[i].value)
        }
        return (
            arrayHolder
        )
    }

    onChangeCalendar = (data) => {
        let changeCalendars = [];
        let calendars = this.state.calendars;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < calendars.length; j++) {
                if (data[i] === calendars[j].value) {
                    changeCalendars.push(calendars[j])
                }
            }
        }
        this.setState({
            program: { ...this.state.program,
                calendars: changeCalendars }
        })
    }

    displayTalents = (id) => {
        let talent = this.state.talentsLevels.find(talent => talent.value === id);
        return (
            <div>
                {talent ? talent.label : 'Not use'}
            </div>
        )
    }

    displayFeeAssistance = () => {
        let parentLabel;
        let data;

        this.state.feeAssistances.map((val) => {
            data = val.options.find(e => e.value == this.state.program.feeAssistanceId);
            parentLabel = val.label;

            if (data) {
                parentLabel = val.label;
            }
        });

        return data ? parentLabel + ' -> ' + data.label : 'Not use';
    }

    getDefaultFeeAssistanceSelected = () => {
        let data;

        this.state.feeAssistances.map((val) => {
            data = val.options.find(e => e.value == this.state.program.feeAssistanceId);
        });

        return data ? data : '';
    }

    onChangeSelectTalent = (values) => {
        let termArr = [];
        if (values) {
            values.map((item) => {
                termArr.push(item.value);
            })
            this.setState({
                isDirty: true,
                program: {
                    ...this.state.program,
                    enabledTalents: termArr
                }
            })
        }
    }

    onCreateNewDept = (data) => {
        let tempArr = this.state.departments ? [...this.state.departments] : [];
        tempArr.push({ label: data.name, value: data.id });
        this.setState({
            program: {
                ...this.state.program,
                departmentId: data.id
            },
            departments: tempArr,
            isDirty: true,
        })
    }

    onCreateNewCategory = (data) => {
        let tempArr = this.state.categories ? [...this.state.categories] : [];
        tempArr.push({ value: data.id, label: data.name });
        this.setState({
            program: {
                ...this.state.program,
                departmentId: data.department ? data.department.id : '',
                categoryId: data.id
            },
            categories: tempArr,
            isDirty: true
        })
    }

    onAddOrEditImage = (data, index) => {
        this.setState({
            tmpImage: {
                url: data?data:'',
                index: index
            },
            errors: {...this.state.errors,
                image: ''
            },
            isAddOrEditImage: true
        })
    }

    onSubmitImage = () => {
        let tmpArr = this.state.program.images?[...this.state.program.images]:[]
        let tmpImage = {...this.state.tmpImage}

        if(!tmpImage.url){
            this.setState({
                errors: {...this.state.errors,
                    image: 'Field is required'
                }
            })
            return
        }

        if(tmpImage.index || tmpImage.index === 0){
            tmpArr.splice(tmpImage.index, 1, tmpImage.url)
        }else{
            tmpArr.push(tmpImage.url)
        }
        this.setState({
            program: {...this.state.program,
                images: tmpArr
            },
            tmpImage: {},
            isAddOrEditImage: false
        })
    }

    onRemoveImage = (index) => {
        let tmpArr = [...this.state.program.images]
        tmpArr.splice(index, 1)
        this.setState({
            program: {...this.state.program,
                images: tmpArr
            }
        })
    }

    onCancelImage = () =>{
        this.setState({
            tmpImage: { url: '' },
            isAddOrEditImage: false
        })
    }

    imageDisplayTemplate = (data, index) => {
        return(
            <div className="p-grid">
                <div className={classNames('p-col-12', {'p-md-8': !this.state.viewMode})} style={{wordBreak: 'break-word'}}>{data}</div>
                {!this.state.viewMode &&
                    <div className="p-col-12 p-md-4 p-r">
                        <Button label="Edit" onClick={() => this.onAddOrEditImage(data, index)} />
                        <Button label="Remove" className="p-button-danger" onClick={() => this.onRemoveImage(index)} />
                    </div>
                }
            </div>
        )
    }

    imageFormTemplate = () => {
        return(
            <Fieldset legend="">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <InputText value={this.state.tmpImage.url} onChange={(e) => this.setState({tmpImage: {...this.state.tmpImage, url: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.image}</div>
                    </div>
                    <div className="p-col-12 p-r">
                        <Button label="Submit" className="p-button-info" onClick={() => this.onSubmitImage()} style={{width: 'auto'}} />
                        <Button label="Cancel" onClick={() => this.onCancelImage()} style={{width: 'auto'}} />
                    </div>
                </div>
            </Fieldset>
        )
    }
    //#endregion

    refreshData = (data) => {
        this.setState({
            program: data,
            tmpProgram: data,
            isDirty: false,
            errors: {}
        });
    }

    acquireFormLock = (formKey) => {
        this.setState({
            formKey: formKey,
            editButtonLabel: "Checking..."
        }, () => { this.onEditMode() });
    }

    releaseFormLock = () => {
        if (!this.state.formKey) return;
        this.formlockServices.releaseFormLock(this.state.formKey, this.props.match.params.id)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        formKey: '',
                        editButtonLabel: "Edit"
                    });
                }
            });
    }

    //#region View mode
    onEditMode = () => {
        if (!this.state.formKey) return;
        this.formlockServices.acquireFormLock(this.state.formKey, this.props.match.params.id, this.state.program.version)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        viewMode: false,
                        isDirty: false,
                        showLockedDialog: false
                    });
                } else {

                }
            })
            .catch(error => {
                let res = error.response;
                if (res.status === 423) {
                    this.setState({
                        viewMode: true,
                        isDirty: false,
                        editingUser: res.data,
                        showLockedDialog: true
                    });
                }
                if (res.status === 409) {
                    this.setState({
                        viewMode: true,
                        isDirty: false,
                        showOutDatedDialog: true
                    });
                }
            });
    }

    onCancelViewMode = () => {
        this.setState({
            discardVisible: this.state.isDirty ? true : false,
            viewMode: this.state.isDirty ? false : true,
            isAddOrEditImage: false
        });

        this.releaseFormLock();
    }

    onDiscardChange = () => {
        let data = this.state.tmpProgram;
        this.releaseFormLock();
        this.setState({
            program: data,
            isDirty: false,
            discardVisible: false,
            viewMode: true,
        });
    }

    onCloseLockedDialog = () => {
        this.setState({
            editButtonLabel: "Edit",
            showLockedDialog: false,
        })
    }

    onCloseOutDatedDialog = () => {
        this.setState({
            editButtonLabel: "Edit",
            showOutDatedDialog: false,
        })
    }

    onChangeSaved = () => {
        this.setState({
            isDirty: false,
            viewMode: true,
            formKey: '',
            editButtonLabel: "Edit"
        });
    }

    onCancelViewMode = () => {
        this.setState({
            discardVisible: this.state.isDirty ? true : false,
            viewMode: this.state.isDirty ? false : true
        });
        this.releaseFormLock();
    }
    //#endregion

    // displayAttribute = (id) => {
    //     const attributeSet = this.state.attrSets.find(attributeSet => attributeSet.value === id);
    //     return (
    //         <div>
    //             {attributeSet ? this.attributeSet.label : 'Not use'}
    //         </div>
    //     )
    // }

    //#region Courser list
    getPageProgramCourses = () => {
        this.programServices.getPageProgramCourses(this.props.match.params.id, this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                this.setState({
                    coursesList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page,
            arrCourses: [],
            headerCheckbox: false
        }, () => {
            this.getPageProgramCourses();
        });

    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder,
            arrCourses: [],
            headerCheckbox: false
        }, () => {
            this.getPageProgramCourses()
        })
    }

    removeProgram = (id) => {
        this.programServices.removeProgram(id)
            .then(res => {
                if (!res.errorCode) {
                    this.getPageProgramCourses();
                    showNotification('success', 'Success Message', 'Action Submitted');
                }
            })
    }

    actionTemplate = (rowData) => {
        let now = moment()
        let endDate = moment(rowData.endDate+' '+rowData.endTime)

        let isPass = false;
        if(now.isAfter(endDate)){
            isPass = true
        }
        let dropActions = [
            { label: 'Course Setting ', icon: 'pi-md-settings', command: () => { this.props.history.push(`/programs/${rowData.parentId}/courses/${rowData.id}/setup`) } },
            { label: 'Copy Course',icon: 'pi pi-clone p-size-20',command: () => this.cloneProgram.popularState(rowData) },
            { label: 'Delete Course', icon: 'pi-md-trash', command: () => { this.deleteNotify.delete(rowData.id); } }
        ];
        if(!isPass){
            let item = { label: 'Cancel Course', icon: 'pi-md-close', command: () => this.cancelProgramForm.action(rowData) }
            dropActions.splice(2, 0, item)
        }
        return <SplitButton label="Manage" className="p-button-constrast p-l" model={dropActions} tooltip={'View Course Manage'} tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(`/programs/${this.props.match.params.id}/courses/${rowData.id}/manage`)}/>

    }

    onCheckboxChange = (e) => {
        let arrValues = [...this.state.arrCourses];
        if (e.checked) {
            if (arrValues.indexOf(e.value) === -1)
                arrValues.push(e.value)
        } else {
            if (arrValues.indexOf(e.value) !== -1)
                arrValues.splice(arrValues.indexOf(e.value), 1)
        }
        this.setState({
            arrCourses: arrValues
        })
    }

    checkboxTemplate = (rowData) => {
        return (
            <Checkbox value={rowData.id} checked={this.state.arrCourses.find(x => x === rowData.id)} onChange={(e) => this.onCheckboxChange(e)} />
        );
    }

    toggleHeaderCheckbox = (e) => {
        let arrValues = [];
        if (e.checked) {
            this.state.coursesList.map(item => {
                arrValues.push(item.id);
            })
        }
        this.setState({
            arrCourses: arrValues,
            headerCheckbox: e.checked
        })
    }

    onChagePageLength = (e) => {
        this.setState({
            rows: e.value,
            page: 0,
            arrCourses: [],
            headerCheckbox: false
        }, () => {
            this.applyFilter();
        })
    }

    activeTemplate = (rowData) => {
        return (
            <span className={classNames('customeStatus', 'status-' + rowData.active)}>{rowData.active ? 'Active' : 'Inactive'}</span>
        );
    }

    statusCourseTemplate = (rowData) => {
        let now = moment()
        let startDate = moment(rowData.startDate+' '+rowData.startTime)
        let endDate = moment(rowData.endDate+' '+rowData.endTime)
        let strStatus = ''
        if(now.isBefore(startDate)){
            strStatus = <span className="customeStatus status-true">UP COMING</span>
        }else if(now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate)){
            strStatus = <span className="customeStatus status-true">STARTING</span>;;
        }else{
            strStatus = <span className="customeStatus status-false">PASS</span>;
        }

        return strStatus
    }
    //#endregion

    //#region Change tab
    onTabChange = (e) => {
        this.setState({
            tmpTabIndex: e.index
        });
        if (!this.state.viewMode && this.state.isDirty) {
            this.setState({ visibleChangeTab: true })
        } else {
            this.releaseFormLock();
            this.setState({
                activeTabIndex: e.index,
                isDirty: false,
                viewMode: true
            });
        }
    }

    discardTab = () => {
        this.setState({
            program: this.state.tmpProgram,
            visibleChangeTab: false,
            // activeTabIndex: this.state.tmpTabIndex
        });
        formEvent.emit('CHANGE_SAVED', this.onChangeSaved);
        this.loadDepartmentTalentsLevels(this.state.tmpProgram.departmentId);
    }

    saveChangeTab = () => {
        switch (this.state.activeTabIndex) {
            case 1: // tab Details
                this.handleSaveBasicInformation();
                break;
            case 2: // tab Registrion Setting
                this.regSettingForm.handleSaveRegistrationSettings();
                break;
            case 3: // tab Attribute
                this.attributeForm.handleSaveAttributeSettings();
                break;
            case 4: //tab Course Data Template
                this.handleSaveCourseTemplateSettings();
                break;
            default: break;
        }
        this.setState({
            activeTabIndex: this.state.tmpTabIndex,
            visibleChangeTab: false,
            isDirty: false,
        });
    }

    resetChange = (keyField) => {
        this.setState({
            program: {
                ...this.state.program,
                [keyField]: this.state.tmpProgram[keyField]
            }
        }, () => {
            if (keyField === 'departmentId') {
                this.loadAllDeptCategories(this.state.program.departmentId);
                this.loadDepartmentTalentsLevels(this.state.program.departmentId);
            }
        })
    }
    //#endregion

    //#region Courser data template
    handleSaveCourseTemplateSettings = () => {
        this.programServices.saveCourseDataTemplateSettings(this.state.program).then(res => {
            if (!res.errorCode) {
                showNotification('success', 'Success Message', 'Action submitted');
                this.refreshData(res);
                formEvent.emit("CHANGE_SAVED");
                this.setState({
                    isDirty: false,
                    viewMode: true,
                    visibleUpdateDataTemplate: false
                });
            } else {
                showNotification('error', 'Error Message', res.errorMessage);
                if (res.errorCode === 400)
                    this.setState({ errors: res.errorObj });
            }
        });
    }

    updateStatusCourses = (status) => {
        if (this.state.arrCourses && this.state.arrCourses.length > 0) {
            this.programServices.updateStatusCourses(this.props.match.params.id, this.state.arrCourses, status)
                .then(res => {
                    if (res.status.code === 200) {
                        this.getPageProgramCourses();
                    }
                })
        }
    }

    loadAllCoursesByProgramCampaign = () => {
        this.programServices.getListProgramCourses(this.state.program.id, this.state.program.campaignIdToUpdateDataTemplateCourses)
            .then(res => {
                this.setState({ courses: res ? res : [] });
            });
    }

    onChangeCampaignFrmUpdateDataTemplate = (e) => {
        this.setState({
            program: {
                ...this.state.program,
                campaignIdToUpdateDataTemplateCourses: e.value,
                updateDataTemplateToSpecificCourses: []
            },
        }, () => {
            this.loadAllCoursesByProgramCampaign();
        })
    }

    onChkCourseUpdateDataTemple = (e) => {
        let tempArr = [...this.state.program.updateDataTemplateToSpecificCourses];
        if (e.checked) {
            if (!tempArr.includes(e.value))
                tempArr.push(e.value);
        } else {
            tempArr.splice(tempArr.indexOf(e.value), 1);
        }
        this.setState({
            program: { ...this.state.program, updateDataTemplateToSpecificCourses: tempArr }
        })
    }

    onChkAllCourseUpdateDataTemple = (e) => {
        let tempArr = [...this.state.program.updateDataTemplateToSpecificCourses];
        if (e.checked) {
            this.state.courses.map(item => {
                if (!tempArr.includes(item.value))
                    tempArr.push(item.value);
            })

        } else {
            this.state.courses.map(() => {
                tempArr.splice(tempArr.indexOf(e.value), 1);
            })
        }
        this.setState({
            program: { ...this.state.program, updateDataTemplateToSpecificCourses: tempArr },
            dataTemplateAllCourse: {
                ...this.state.dataTemplateAllCourse,
                chkAllCourse: e.checked
            }
        })
    }

    showDialogUpdateAllCourses = () => {
        if (this.state.coursesList && this.state.coursesList.length > 0) {
            this.setState({
                program: {
                    ...this.state.program,
                    typeUpdateDataTemplateToCourse: 'all',
                    updateDataTemplateToSpecificCourses: []
                },
                dataTemplateAllCourse: {
                    campaignId: ''
                },
                courses: [],
                visibleUpdateDataTemplate: true
            });
        } else {
            this.handleSaveCourseTemplateSettings();
        }
    }

    confirmUpdateDataAllCourses = (toUpdate) => {
        this.setState({
            program: {
                ...this.state.program,
                typeUpdateDataTemplateToCourse: toUpdate ? this.state.program.typeUpdateDataTemplateToCourse : 'all',
                updateDataTemplateToSpecificCourses: toUpdate ? this.state.program.updateDataTemplateToSpecificCourses : []
            }
        }, () => {
            this.handleSaveCourseTemplateSettings();
        })
    }

    courseDateTemplate = (rowData) => {
        let startDate = moment(moment(rowData.startDate, moment.HTML5_FMT.DATE).toDate()).format('MM-DD-YYYY');
        let endDate = moment(moment(rowData.endDate, moment.HTML5_FMT.DATE).toDate()).format('MM-DD-YYYY');
        return <div>{startDate !== endDate ? startDate + ' - ' + endDate : startDate}</div>;
    }

    courseTimeTemplate = (rowData) => {
        let startTime = moment(moment(rowData.startTime, moment.HTML5_FMT.TIME).toDate()).format('hh:mm A');
        let endTime = moment(moment(rowData.endTime, moment.HTML5_FMT.TIME).toDate()).format('hh:mm A');
        return <div>{startTime} - {endTime}</div>;
    }

    onCreateNewCampaign = (data) => {
        let tempArr = this.state.campaigns ? [...this.state.campaigns] : [];
        tempArr.push({ label: data.name, value: data.id })
        this.setState({
            course: {
                ...this.state.course,
                campaignId: data.id
            },
            campaigns: tempArr,
        })
    }

    onAddLocation = (type) => {
        this.setState({
            addLocationType: type
        });
        // this.locationForm.action('room');
        this.locationForm.action(null, PROPERTY_TYPE.location.value);
    }

    onCreateNewLocation = (data) => {
        let tempArr = this.state.locations ? [...this.state.locations] : [];
        tempArr.push({ label: data.name, value: data.id })
        this.setState({
            course: {
                ...this.state.course,
                locationId: this.state.addLocationType === 'primary' ? data.id : this.state.course.locationId,
                secondaryLocationId: this.state.addLocationType === 'secondary' ? data.id : this.state.course.secondaryLocationId
            },
            locations: tempArr,
        })
    }

    onProgramBasicPriceGLAccountChange = (e) => {
        this.setState({ program: { ...this.state.program, 
                basicPriceGLAccount: e.value,
                membershipPriceGLAccount: this.state.program.membershipPriceGLAccount?this.state.program.membershipPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.program.staffPriceGLAccount?this.state.program.staffPriceGLAccount:e.value
            }, 
            isDirty: true 
        })
    }

    onProgramMembershipPriceGLAccountChange = (e) => {
        this.setState({ program: { ...this.state.program, 
                membershipPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.program.basicPriceGLAccount?this.state.program.basicPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.program.staffPriceGLAccount?this.state.program.staffPriceGLAccount:e.value
            }, 
            isDirty: true 
        })
    }

    onProgramStaffPriceGLAccountChange = (e) => {
        this.setState({ program: { ...this.state.program, 
                staffPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.program.basicPriceGLAccount?this.state.program.basicPriceGLAccount:e.value,
                membershipPriceGLAccount: this.state.program.membershipPriceGLAccount?this.state.program.membershipPriceGLAccount:e.value
            }, 
            isDirty: true 
        })
    }
    //#endregion

    //#region Add course
    addCourse = () => {
        this.setState({
            isAddCourse: true,
            course: this.state.program
        }, () => {
            this.clearNewCourseProperty(this.state.program);
        });
    }

    clearNewCourseProperty = (program) => {
        this.setState({
            course: {
                ...this.state.course,
                id: '',
                parentId: program.id,
                type: 'course',
                code: '',
                name: '',
                description: '',
                weight: 0,
                basicPrice: 0,
                memberPrice: 0,
                noneMemberUse: true,
                membershipUse: true,
                active: false,
                allowOnlineRegistration: program.workType === 'dw' ? false : program.allowOnlineRegistration,
                showOnWeb: program.workType === 'dw' ? false : program.showOnWeb,
                showNotAvailable: program.workType === 'dw' ? false : program.showNotAvailable,
                minAgeYear: program.workType === 'dw' ? 0 : program.minAgeYear,
                minAgeMonth: program.workType === 'dw' ? 0 : program.minAgeMonth,
                maxAgeYear: program.workType === 'dw' ? 0 : program.maxAgeYear,
                maxAgeMonth: program.workType === 'dw' ? 0 : program.maxAgeMonth,
                gender: program.workType === 'dw' ? 'Any' : program.gender,
                grades: program.workType === 'dw' ? null : program.grades,
                minReg: 0,
                maxReg: 0
            }
        });
    }

    onCampaignChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                campaignId: e.value,
                feeAssistanceId: ''
            }
        }, () => {
            this.loadAllBudgetFeeAssistances(this.state.course.campaignId, this.state.program.departmentId);
        });
    }

    handleSaveNewProgramCourse = () => {
        this.programServices.saveProgram(this.state.course).then(res => {
            if (!res.errorCode) {
                this.setState({ goToDetail: res });
            } else {
                showNotification('error', 'Error Message', res.errorMessage);
                if (res.errorCode === 400)
                    this.setState({ errors: res.errorObj });
            }
        });

    }

    onStartDateChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                startDate: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
                startDateValue: e.value
            }
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                endDate: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
                endDateValue: e.value
            }
        });

    }

    onStartTimeChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                startTime: moment(e.value).format(moment.HTML5_FMT.TIME),
                startTimeValue: e.value,
            }
        });

    }

    onEndTimeChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                endTime: moment(e.value).format(moment.HTML5_FMT.TIME),
                endTimeValue: e.value,
            }
        });
    }

    onRegStartDateChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                regStartDate: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '',
                regStartDateValue: e.value,
                memberRegStartDate: this.state.course.memberRegStartDate ? this.state.course.memberRegStartDate : (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegStartDateValue: this.state.course.memberRegStartDateValue ? this.state.course.memberRegStartDateValue : e.value
            }
        });
    }

    onRegEndDateChange = (e) => {
        this.setState({
            course: {
                ...this.state.course, regEndDate: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '',
                regEndDateValue: e.value,
                memberRegEndDate: this.state.course.memberRegEndDate?this.state.course.memberRegEndDate:(e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegEndDateValue: this.state.course.memberRegEndDateValue?this.state.course.memberRegEndDateValue:e.value
            }
        });
    }

    onMemberRegStartDateChange = (e) => {
        this.setState({
            course: {
                ...this.state.course, memberRegStartDate: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '',
                memberRegStartDateValue: e.value,
                regStartDate: this.state.course.regStartDate ? this.state.course.regStartDate : (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                regStartDateValue: this.state.course.regStartDateValue ? this.state.course.regStartDateValue : e.value
            }
        });
    }

    onMemberRegEndDateChange = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                memberRegEndDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegEndDateValue: e.value,
                regEndDate: this.state.course.regEndDate ? this.state.course.regEndDate : (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                regEndDateValue: this.state.course.regEndDateValue ? this.state.course.regEndDateValue : e.value
            }
        });
    }

    onChangeSwitchRegistration = (e, type) => {
        if (type === 'program') {
            this.setState({
                program: {
                    ...this.state.program,
                    allowOnlineRegistration: e.value,
                    showOnWeb: e.value ? true : this.state.program.showOnWeb
                },
                isDirty: true,
                isDirty: true
            })
        } else if (type === 'course') {
            this.setState({
                course: {
                    ...this.state.course,
                    allowOnlineRegistration: e.value,
                    showOnWeb: e.value ? true : this.state.course.showOnWeb
                },
                isDirty: true
            })
        }
    }

    onCourseBasicPriceGLAccountChange = (e) => {
        this.setState({ course: { ...this.state.course, 
                basicPriceGLAccount: e.value,
                membershipPriceGLAccount: this.state.course.membershipPriceGLAccount?this.state.course.membershipPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.course.staffPriceGLAccount?this.state.course.staffPriceGLAccount:e.value
            }
        })
    }

    onCourseMembershipPriceGLAccountChange = (e) => {
        this.setState({ course: { ...this.state.course, 
                membershipPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.course.basicPriceGLAccount?this.state.course.basicPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.course.staffPriceGLAccount?this.state.course.staffPriceGLAccount:e.value
            }
        })
    }

    onCourseStaffPriceGLAccountChange = (e) => {
        this.setState({ course: { ...this.state.course, 
                staffPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.course.basicPriceGLAccount?this.state.course.basicPriceGLAccount:e.value,
                membershipPriceGLAccount: this.state.course.membershipPriceGLAccount?this.state.course.membershipPriceGLAccount:e.value
            }
        })
    }

    onDisplaySet = (id, options) => {
        let option = options ? options.find(option => option.value === id) : '';
        return (
            <div>
                {option ? option.label : 'Not use'}
            </div>
        );
    }
    //#endregion

    applyFilter = () => {
        this.setState({
            page: 0
        }, () => {
            this.getPageProgramCourses();
        })
    }

    clearFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                campaignId: '',
                status: '',
                currentTalent: '',
                currentLevel: '',
                requireTalent: '',
                requireLevel: ''
            }
        }, () => { this.applyFilter() });
    }

    render() {
        if (this.state.goToDetail) {
            return <Redirect to={`/programs/${this.state.program.id}/courses/${this.state.goToDetail}/setup`} />
        }

        const asterik = <span style={{ color: "red" }}>*</span>
        const splitItems = [
            {
                label: "Active Selected", icon: 'pi pi-circle-on',
                command: () => {
                    this.updateStatusCourses(true);
                }
            },
            {
                label: "Deactive Selected", icon: 'pi pi-circle-off',
                command: () => {
                    this.updateStatusCourses(false);
                }
            }
        ];

        // const chips = this.state.program.tags.map((item, key) =>
        //     <li key={item.id}>{item.label}</li>
        // )

        let tblHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            {/* <Button icon="pi pi-plus" className="p-button-constrast" style={{'float':'left'}} onClick={() => this.setState({programCourseFormDialogVisible:true})}/> */}
            <Button label="Add Course" icon="pi pi-plus" className="p-button-constrast" style={{ 'float': 'left' }} onClick={() => this.addCourse()} />
            {/* <Button icon="pi pi-refresh" style={{'float':'right'}} onClick={() => this.getPageProgramCourses()}/> */}
            <div style={{ float: 'right', textAlign: 'left' }}>
                Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChagePageLength(e)} style={{ minWidth: "0" }} />
                <SplitButton tooltip="Refresh Course" tooltipOptions={{ position: "top" }} className="p-margin-left-10 p-button-constrast" icon="pi pi-refresh" model={splitItems} onClick={() => this.getPageProgramCourses()} />
            </div>
        </div>;

        let headerCheckbox = <Checkbox checked={this.state.headerCheckbox} onChange={(e) => this.toggleHeaderCheckbox(e)} />;

        const optionsUpdateDataTemplateAllCourse = [
            { label: 'All courses', value: 'all' },
            { label: 'Specific course', value: 'specific' }
        ];

        if(this.state.changeScreenSize === true) {
        } else {
        }

        return (
            <div className="p-grid">
                {/* <ProgramCourseForm header="Add Program Course"
                    visible={this.state.programCourseFormDialogVisible}
                    hideFormDialog={(e) => this.setState({programCourseFormDialogVisible: false})}
                    parent={this.state.program}
                    reloadTable={(e) => this.getPageProgramCourses(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}
                /> */}

                <BreadcrumbsItem to="/programs">Programs</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>{this.state.program.name}</BreadcrumbsItem>

                <PreventTransitionPrompt
                    when={this.state.isDirty}
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={() => this.state.isDirty}
                    handleSave={this.handleSaveBasicInformation}
                    handleDiscard={this.discardTab}
                />

                <NotifyChangeTab
                    visible={this.state.visibleChangeTab}
                    saveChangeTab={() => this.saveChangeTab()}
                    discardTab={() => this.discardTab()}
                    cancelChangeTab={() => this.setState({ visibleChangeTab: false })}
                />
                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeProgram(e)}
                />

                <NotifyLocked
                    visible={this.state.showLockedDialog}
                    user={this.state.editingUser}
                    onEditMode={() => this.onEditMode()}
                    onHide={() => this.onCloseLockedDialog()}
                />

                <NotifyOutDated
                    visible={this.state.showOutDatedDialog}
                    onHide={() => this.onCloseOutDatedDialog()}
                    formEvent={formEvent}
                />

                <Dialog visible={this.state.discardVisible} contentStyle={styles.dialogContent} responsive={true}
                    modal={true} dismissableMask={true} showHeader={false} onHide={() => this.setState({ discardVisible: false })}
                    footer={<React.Fragment>
                        <Button label="Discard Changes" icon="pi-md-close" onClick={() => this.onDiscardChange()} />
                        <Button label="Review Changes" className="p-button-secondary" onClick={() => this.setState({ discardVisible: false })} />
                    </React.Fragment>}
                >
                    The changes have not been saved!
                </Dialog>

                <Sidebar visible={this.state.visibleUpdateDataTemplate} dismissable={false} style={{ overflowY: 'auto' }} className="p-sidebar-md" position="right" blockScroll={true} onHide={() => this.setState({ visibleUpdateDataTemplate: false })}>
                    <h2>Update data template changes for all courses</h2>

                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <SelectButton value={this.state.program.typeUpdateDataTemplateToCourse} options={optionsUpdateDataTemplateAllCourse} onChange={(e) => this.setState({ program: { ...this.state.program, typeUpdateDataTemplateToCourse: e.value } })} />
                        </div>
                        {this.state.program.typeUpdateDataTemplateToCourse === 'specific' ?
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label>* Campaign</label>
                                    <Dropdown value={this.state.program.campaignIdToUpdateDataTemplateCourses} options={this.state.campaigns} onChange={(e) => this.onChangeCampaignFrmUpdateDataTemplate(e)} />
                                    <div className="p-form-error">{this.state.errors.dataTemplateAllCourse ? this.state.errors.dataTemplateAllCourse.campaign : ''}</div>
                                </div>
                                <div className="p-col-12">
                                    {/* <label>Course</label> */}
                                    {/* <MultiSelect value={this.state.program.updateDataTemplateToSpecificCourses} options={this.state.courses} onChange={(e) => this.setState({program: {...this.state.program, updateDataTemplateToSpecificCourses: e.value}})} /> */}
                                    <table cellPadding={0} cellSpacing={0} style={{ width: "100%", border: "none" }} >
                                        <thead>
                                            <tr>
                                                <td className="p-padding-all-5 p-c" style={{ border: "1px solid #e6e6e6" }} >
                                                    <Checkbox checked={this.state.dataTemplateAllCourse.chkAllCourse} onChange={(e) => this.onChkAllCourseUpdateDataTemple(e)} />
                                                </td>
                                                <td className="p-padding-all-5" style={{ border: "1px solid #e6e6e6" }} ><strong>Course Name</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.courses.map(course => {
                                                return (
                                                    <tr>
                                                        <td className="p-padding-all-5 p-c" style={{ border: "1px solid #e6e6e6" }} >
                                                            <Checkbox value={course.value} checked={this.state.program.updateDataTemplateToSpecificCourses.includes(course.value)} onChange={(e) => this.onChkCourseUpdateDataTemple(e)} />
                                                        </td>
                                                        <td className="p-padding-all-5" style={{ border: "1px solid #e6e6e6" }} >
                                                            {course.label}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                            : ''
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12  p-r p-margin-top-30 p-line-top">
                            <Button label="Cancel" className="p-button-secondary" onClick={() => this.setState({ visibleUpdateDataTemplate: false })} />
                            <Button label="Deny" icon="pi-md-close" onClick={() => this.confirmUpdateDataAllCourses(false)} />
                            <Button label="Accept" icon="pi-md-check" onClick={() => this.confirmUpdateDataAllCourses(true)} />
                        </div>
                    </div>
                </Sidebar>

                <CloneProgramForm ref={el => this.cloneProgram = el} 
                    refreshTable={() => this.getPageProgramCourses()}
                />

                <CampaignForm ref={el => this.campaignForm = el}
                    campaign={(data) => this.onCreateNewCampaign(data)}
                />

                <CancelProgramForm ref={el => this.cancelProgramForm=el} />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Program: {this.state.program.name}
                            <div className="p-toolbar-group-right">
                                <Link to={`/programs`} className="p-button p-component p-button-primary p-button-icon-only">
                                    <span className="pi pi-bars p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-3">Type: {this.state.program.workTypeName}</div>
                            <div className="p-col-12 p-md-3">Department: {this.state.program.departmentName}</div>
                            <div className="p-col-12 p-md-3">Category: {this.state.program.categoryName}</div>
                        </div>
                    </div>
                </div>
                <div className={"p-col-12 " + (this.state.isAddCourse ? 'p-hide' : '')}>
                    <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.onTabChange(e)} >
                        <TabPanel key="tp_list" header="Course List">
                            {this.state.program.id ?
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <Fieldset legend="Course Filters">
                                                <div className="p-grid p-fluid">
                                                    <div className="p-col-12 p-md-5">
                                                        <div className="p-grid">
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <div className="p-col-12 p-md-3">
                                                                        <label>Campaign</label>
                                                                    </div>
                                                                    <div className="p-col-12 p-md-9">
                                                                        <Dropdown value={this.state.filter.campaignId} options={this.state.campaigns} showClear={true} onChange={(e) => this.setState({ filter: { ...this.state.filter, campaignId: e.value } }, () => this.applyFilter())} style={{ width: '100%' }} placeholder="Select a Campaign" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <div className="p-col-12 p-md-3">
                                                                        <label>Current Level</label>
                                                                    </div>
                                                                    <div className="p-col-12 p-md-9">
                                                                        <SelectGroup isClearable={true} options={this.state.talentsLevels} onChange={(e) => this.setState({ filter: { ...this.state.filter, currentTalent: e ? e.value.talentKey : '', currentLevel: e ? e.value.levelValue : '' } }, () => this.applyFilter())} style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="p-md-2 p-sm-2 p-padding-top-0 p-padding-bottom-0"></div>
                                                    <div className="p-col-12 p-md-5">
                                                        <div className="p-grid">
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <div className="p-col-12 p-md-3">
                                                                        <label>Status</label>
                                                                    </div>
                                                                    <div className="p-col-12 p-md-9">
                                                                        <Dropdown value={this.state.filter.status} options={PROG_STATUSES} onChange={(e) => this.setState({ filter: { ...this.state.filter, status: e.value } }, () => this.applyFilter())} style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <div className="p-col-12 p-md-3">
                                                                        <label>Required Level</label>
                                                                    </div>
                                                                    <div className="p-col-12 p-md-9">
                                                                        <SelectGroup isClearable={true} options={this.state.talentsLevels} onChange={(e) => this.setState({ filter: { ...this.state.filter, requireTalent: e ? e.value.talentKey : '', requireLevel: e ? e.value.levelValue : '' } }, () => this.applyFilter())} style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-grid">
                                                    <div className="p-col-12" style={{ "textAlign": "center" }}>
                                                        <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => this.applyFilter()} />
                                                        <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={this.clearFilter} />
                                                    </div>
                                                </div>
                                            </Fieldset>
                                            <DataTable lazy={true} paginator={true} value={this.state.coursesList} className="datatableProgramDetails"
                                                first={this.state.page * this.state.rows}
                                                rows={this.state.rows} totalRecords={this.state.total}
                                                onPage={this.onPage} header={tblHeader} onSort={this.onSort}
                                                sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                                                selectionMode="single"
                                            >
                                                <Column header={headerCheckbox} body={this.checkboxTemplate} style={{ width: '3.3em' }} />
                                                <Column field="code" header="Course Code" sortable={true} style={{ width: '12em' }}
                                                    body={(rowData) => {
                                                        return (
                                                            <Link to={`/programs/${this.props.match.params.id}/courses/${rowData.id}/setup`} className="nav-link">
                                                                {rowData.code}
                                                            </Link>
                                                        );
                                                    }}
                                                />
                                                <Column field="name" header="Course Name" sortable={true} style={{width: '26em'}}
                                                    body={(rowData) => {
                                                        return (
                                                            <Link to={`/programs/${this.props.match.params.id}/courses/${rowData.id}/setup`} className="nav-link">
                                                                {rowData.name}
                                                            </Link>
                                                        );
                                                    }}
                                                />
                                                <Column field="startDate" header="Date" body={this.courseDateTemplate} sortable={true} style={{ width: '15em' }} />
                                                <Column field="startTime" header="Time" body={this.courseTimeTemplate} sortable={true} style={{ width: '12em' }} />
                                                <Column header="Status" body={this.statusCourseTemplate} style={{ width: '8em' }} />
                                                <Column field="active" header="Active" body={this.activeTemplate} sortable={true} bodyClassName="p-c" style={{ width: '8em' }} />
                                                <Column field="totalReg" header="Total Reg" style={{ width: '8em' }} />
                                                <Column header="Actions" body={this.actionTemplate} className='tc-actions' style={{width: '10em', overlay: 'visible' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </TabPanel>
                        <TabPanel key="tp_details" header="Information">
                            <DepartmentForm ref={el => this.departmentForm = el}
                                dept={(data) => this.onCreateNewDept(data)}
                            />

                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="Basic Information">
                                        <div className="p-grid p-fluid form-group">
                                            {!this.state.viewMode ?
                                                <React.Fragment>
                                                    <FieldWrapper col="p-col-12" keyField="branchId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.branchId, this.state.tmpProgram.branchId)}>
                                                        <label className="p-label">{asterik} Branch </label>
                                                        <Dropdown value={this.state.program.branchId} options={this.state.branchs} onChange={(e) => this.setState({ program: { ...this.state.program, branchId: e.value }, isDirty: true })} placeholder="Select a branch"/>
                                                        <span className="p-form-error">{this.state.errors.code}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12 p-md-6" keyField="code" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.code, this.state.tmpProgram.code)}>
                                                        <label className="p-label">{asterik} Code </label>
                                                        <InputText keyfilter={/[^\s]/} value={this.state.program.code} onChange={(e) => this.setState({ program: { ...this.state.program, code: replaceSpaceAndUpperCase(e.target.value) }, isDirty: true })} />
                                                        <span className="p-form-error">{this.state.errors.code}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12 p-md-6" keyField="name" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.name, this.state.tmpProgram.name)}>
                                                        <div style={{ display: "flex" }}>
                                                            <label className="p-label">{asterik} Name </label>
                                                            <Tooltip
                                                                title="Welcome to React"
                                                                position="right"
                                                                className="pi-md-help"
                                                                arrow="true"
                                                                trigger="click"
                                                                size="small"
                                                                style={{ fontSize: "15px", marginLeft: "5px" }}
                                                            >
                                                            </Tooltip>
                                                        </div>
                                                        <InputText value={this.state.program.name} onChange={(e) => this.setState({ program: { ...this.state.program, name: titleCaseText(e.target.value) }, isDirty: true })} />
                                                        <span className="p-form-error">{this.state.errors.name}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="description" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.description, this.state.tmpProgram.description)}>
                                                        <label className="p-label">Description</label>
                                                        <InputTextarea rows={4} cols={100} value={this.state.program.description} placeholder="Enter program description" onChange={(e) => this.setState({ program: { ...this.state.program, description: e.target.value }, isDirty: true })} />
                                                        <span className="p-form-error">{(this.state.errors.description)}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="moderatorEmail" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.moderatorEmail, this.state.tmpProgram.moderatorEmail)}>
                                                        <label className="p-label">{asterik} Marshaller Emails (Seperated by comma)</label>
                                                        <InputText value={this.state.program.moderatorEmail} onChange={(e) => this.setState({ program: { ...this.state.program, moderatorEmail: e.target.value }, isDirty: true })} />
                                                        <span className="p-form-error">{(this.state.errors.moderatorEmail)}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="locationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.locationId,this.state.tmpProgram.locationId)}>
                                                        <label className="p-label">{asterik} Primary Location</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown id="location" value={this.state.program.locationId} filter={true} showClear={true} options={this.state.locations} onChange={(e) => this.setState({ program: { ...this.state.program, locationId: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select a Location" />
                                                            <Button label="New" icon="pi-md-plus" />
                                                        </div>
                                                        <span className="p-form-error">{(this.state.errors.locationId)}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="secondaryLocationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.secondaryLocationId,this.state.tmpProgram.secondaryLocationId)}>
                                                        <label className="p-label">Secondary Location</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown id="location" value={this.state.program.secondaryLocationId} filter={true} showClear={true} options={this.state.locations} onChange={(e) => this.setState({ program: { ...this.state.program, secondaryLocationId: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select a Location" />
                                                            <Button label="New" icon="pi-md-plus" />
                                                        </div>
                                                    </FieldWrapper>
                                                    {/* <FieldWrapper col="p-col-12" keyField="attributeSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.attributeSetId, this.state.tmpProgram.attributeSetId)}>
                                                        <label className="p-label">Attribute Set</label>
                                                        <Dropdown options={this.state.attributeSet} value={this.state.program.attributeSetId} onChange={(e) => this.setState({ program: { ...this.state.program, attributeSetId: e.value }, isDirty: true })} style={{ width: "100%" }} />
                                                        <span className="p-form-error">{(this.state.errors.attributeSet)}</span>
                                                    </FieldWrapper> */}
                                                    <FieldWrapper col="p-col-4" keyField="weight" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.weight, this.state.tmpProgram.weight)}>
                                                        <label className="p-label">Weight (for order)</label>
                                                        <InputText value={this.state.program.weight} keyfilter="int" onChange={(e) => this.setState({ program: { ...this.state.program, weight: e.target.value ? e.target.value : 0 }, isDirty: true })} />
                                                        <span className="p-form-error">{(this.state.errors.weight)}</span>
                                                    </FieldWrapper>

                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <FieldWrapper col="p-col-12" keyField="branch">
                                                        <label className="p-label">Branch</label>
                                                        <div>{this.state.program.branchName}</div>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12 p-md-6" keyField="code">
                                                        <label className="p-label">Code</label>
                                                        <div>{this.state.program.code}</div>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12 p-md-6" keyField="name">
                                                        <label className="p-label">Name</label>
                                                        <div>{this.state.program.name}</div>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="description">
                                                        <label className="p-label">Description</label>
                                                        <div>{this.state.program.description}</div>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="moderatorEmail">
                                                        <label className="p-label">Marshaller Emails (Seperated by comma)</label>
                                                        <div>{this.state.program.moderatorEmail}</div>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="locationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.locationId,this.state.tmpProgram.locationId)}>
                                                        <label className="p-label">Primary Location</label>
                                                        {getLabelFromListSimple(this.state.locations, this.state.program.locationId)}
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="secondaryLocationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.secondaryLocationId,this.state.tmpProgram.secondaryLocationId)}>
                                                        <label className="p-label">Secondary Location</label>
                                                        {getLabelFromListSimple(this.state.locations, this.state.program.secondaryLocationId)}
                                                    </FieldWrapper>
                                                    {/* <FieldWrapper col="p-col-12" keyField="attributeSetId">
                                                        <label className="p-label">Attribute Set</label>
                                                        <div>Not use</div>
                                                    </FieldWrapper> */}
                                                    <FieldWrapper col="p-col-12" keyField="weight">
                                                        <label className="p-label">Weight</label>
                                                        <div>{this.state.program.weight}</div>
                                                    </FieldWrapper>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Calendar">
                                        <div>
                                            {!this.state.viewMode ?
                                                <React.Fragment>
                                                    <FieldWrapper col="p-col-12" keyField="calendars" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.calendars, this.state.tmpProgram.calendars)}>
                                                        <MultiSelect value={this.displayDefaulCalendar()} options={this.state.calendars} onChange={(e) => this.onChangeCalendar(e.value)} style={{ width: "100%" }} />
                                                        <span className="p-form-error">{(this.state.errors.calendarId)}</span>
                                                    </FieldWrapper>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <div>{this.displayCalendar(this.state.program.calendars)}</div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="Classification">
                                        <div className="p-grid p-fluid form-group">
                                            {!this.state.viewMode ?
                                                <React.Fragment>
                                                    <FieldWrapper col="p-col-12" keyField="departmentId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.departmentId, this.state.tmpProgram.departmentId)}>
                                                        <label className="p-label" >Department</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown id="department" value={this.state.program.departmentId} options={this.state.departments} filter={true} onChange={(e) => this.onDepartmentChange(e)} style={{width:'100%'}} placeholder="Select a Department"/>
                                                            <Button label="New" icon="pi-md-plus" onClick={() => this.departmentForm.actionForm()} />
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.department}</span>
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="categoryId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.categoryId, this.state.tmpProgram.categoryId)}>
                                                        <label className="p-label" >Category</label>
                                                        <div className="p-inputgroup">
                                                            <Dropdown id="category" value={this.state.program.categoryId} options={this.state.categories} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, categoryId: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select a Category" />
                                                            <Button label="New" icon="pi-md-plus" onClick={() => this.categoryForm.action()} />
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.category}</span>
                                                    </FieldWrapper>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <FieldWrapper col="p-col-12" keyField="departmentId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.departmentId, this.state.tmpProgram.departmentId)}>
                                                        <label className="p-label" >Department</label>
                                                        {this.displayDepartment(this.state.program.departmentId)}
                                                    </FieldWrapper>
                                                    <FieldWrapper col="p-col-12" keyField="departmentId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.departmentId, this.state.tmpProgram.departmentId)}>
                                                        <label className="p-label" >Category</label>
                                                        {this.displayCategory(this.state.program.categoryId)}
                                                    </FieldWrapper>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Instructor">
                                        <div className="p-grid p-fluid form-group">
                                            <FieldWrapper col="p-col-12" keyField="instructorIds" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.instructorIds, this.state.tmpProgram.instructorIds)}>
                                                <labe className="p-label">Instructor</labe>
                                                <MultiSelect disabled={!this.state.viewMode ? false : true} value={this.state.program.instructorIds} options={this.state.instructors} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, instructorIds: e.value }, isDirty: true })} placeholder="Select Instructor" />
                                            </FieldWrapper>
                                            <FieldWrapper col="p-col-12" keyField="trainerIds" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.trainerIds, this.state.tmpProgram.trainerIds)}>
                                                <labe className="p-label">Trainer</labe>
                                                <MultiSelect disabled={!this.state.viewMode ? false : true} value={this.state.program.trainerIds} options={this.state.trainers} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, trainerIds: e.value }, isDirty: true })} placeholder="Select Trainer" />
                                            </FieldWrapper>
                                        </div>
                                    </Fieldset>
                                    {this.state.program.workType === 'dw' ?
                                        <Fieldset legend="Display">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.allowOnlineRegistration} onChange={(e) => this.onChangeSwitchRegistration(e, 'program')} />
                                                    <label className="p-margin-left-10">Allow online registration?</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.showOnWeb} disabled={this.state.program.allowOnlineRegistration ? true : false} onChange={(e) => this.setState({ program: { ...this.state.program, showOnWeb: e.value }, isDirty: true })} />
                                                    <label className="p-margin-left-10">Show on web?</label>
                                                </div>
                                                {/* <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.showNotAvailable} onChange={(e) => this.setState({program: { ...this.state.program, showNotAvailable: e.value}, isDirty: true})} />
                                                    <label className="p-margin-left-10">Show when not available (Coming soon)?</label>
                                                </div> */}
                                            </div>
                                        </Fieldset>
                                        : ''
                                    }
                                    <Fieldset legend="Tags">
                                        <div className="p-grid p-fluid form-group">
                                            <FieldWrapper col="p-col-12" keyField="tags" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.tags, this.state.tmpProgram.tags)}>
                                                <React.Fragment>
                                                    <Chips disabled={!this.state.viewMode ? false : true} placeholder="Type your tag here" value={this.state.program.tags} onChange={(e) => this.setState({ program: { ...this.state.program, tags: e.value }, isDirty: true })}></Chips>
                                                </React.Fragment>
                                            </FieldWrapper>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Images">
                                        <div className="p-grid">
                                            {!this.state.viewMode && !this.state.isAddOrEditImage &&
                                                <div className="p-col-12">
                                                    <Button label="Add Image" onClick={() => this.onAddOrEditImage()} />
                                                </div>
                                            }
                                            {!this.state.viewMode && this.state.isAddOrEditImage && !this.state.tmpImage.index && this.state.tmpImage.index !== 0 &&
                                                <div className="p-col-12">
                                                    {this.imageFormTemplate()}
                                                </div>
                                            }
                                            {this.state.program.images && this.state.program.images.map((value, index) => {
                                                return <React.Fragment key={index}>
                                                    {index > 0 && <div className="p-col-12"><div className="p-hr"></div></div>}
                                                    <div className="p-col-12">
                                                        {(()=>{
                                                            if(!this.state.viewMode && this.state.tmpImage.index === index){
                                                                return this.imageFormTemplate()
                                                            }else {
                                                                return this.imageDisplayTemplate(value, index)
                                                            }
                                                        })()}
                                                    </div>
                                                </React.Fragment>
                                            })}
                                        </div>
                                    </Fieldset>
                                    {/* <Fieldset legend="Talents">
                                        <div className="p-grid p-fluid form-group">
                                            <FieldWrapper col="p-col-12" keyField="enabledTalents" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.enabledTalents, this.state.tmpProgram.enabledTalents)}>
                                                <label>Enabled Talents <i >(changed by the department)</i></label>
                                                <React.Fragment>
                                                    <Select multi
                                                        disabled={!this.state.viewMode ? false : true}
                                                        values={this.state.talents && this.state.program.enabledTalents ? this.state.talents.filter(x1 => this.state.program.enabledTalents.some(x2 => x2 === x1.value)) : []}
                                                        options={this.state.talents} onChange={(values) => this.onChangeSelectTalent(values)}
                                                        style={{ width: '100%' }} placeholder="Select talents"
                                                        noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data by the department</span>) }}
                                                    />
                                                </React.Fragment>
                                            </FieldWrapper>
                                        </div>
                                    </Fieldset> */}
                                </div>
                                {this.state.program.id ?
                                    <div className="p-col-12">
                                        {!this.state.viewMode ?
                                            <React.Fragment>
                                                <Button label="Cancel Edit" icon="pi pi-lock" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.onCancelViewMode()} />
                                                <Button label="Save Basic Information" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => this.handleSaveBasicInformation()} />
                                            </React.Fragment>
                                            :
                                            <Button label={this.state.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.acquireFormLock('programLock')} />
                                        }
                                    </div>
                                    : ''
                                }
                            </div>
                        </TabPanel>
                        <TabPanel header={this.state.program && this.state.program.workType === PROGRAM_WORK_TYPE.dw ? 'Registration Settings' : ''} disabled={this.state.program && this.state.program.workType === 'dw' ? false : true}>
                            <RegistrationSettingsForm ref={el => this.regSettingForm = el}
                                data={this.state.program}
                                refreshData={(e) => this.refreshData(e)}
                                isDirty={this.state.isDirty}
                                viewMode={this.state.viewMode}
                                acquireFormLock={() => this.acquireFormLock('programLock')}
                                editButtonLabel={this.state.editButtonLabel}
                                onCancelViewMode={this.onCancelViewMode}
                                formEvent={formEvent}
                            />
                        </TabPanel>
                        <TabPanel header={this.state.program && this.state.program.attributeSet ? 'Attribute Set: ' + this.state.program.attributeSet.name : ''} disabled={this.state.program && this.state.program.attributeSet ? false : true}>
                            <AttributeForm ref={el => this.attributeForm = el}
                                data={this.state.program}
                                refreshData={(e) => this.refreshData(e)}
                                updating={(e) => this.setState({ isDirty: e })}
                            />
                        </TabPanel>
                        <TabPanel header="Course Data Template">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    {this.state.program.workType === 'simple' ?
                                        <Fieldset legend="Display">
                                            {!this.state.viewMode ?
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.allowOnlineRegistration} onChange={(e) => this.onChangeSwitchRegistration(e, 'program')} />
                                                        <label className={'p-margin-left-10' + highlightLabelChanged(this.state.program.allowOnlineRegistration, this.state.tmpProgram.allowOnlineRegistration)}>Allow online registration?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.showOnWeb} disabled={this.state.program.allowOnlineRegistration ? true : false} onChange={(e) => this.setState({ program: { ...this.state.program, showOnWeb: e.value }, isDirty: true })} />
                                                        <label className={'p-margin-left-10' + highlightLabelChanged(this.state.program.showOnWeb, this.state.tmpProgram.showOnWeb)}>Show on web?</label>
                                                    </div>
                                                </div>
                                                :
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label>Allow online registration: {this.state.program.allowOnlineRegistration ? 'YES' : 'NO'}</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label>Show on web: {this.state.program.showOnWeb ? 'YES' : 'NO'}</label>
                                                    </div>
                                                </div>
                                            }
                                        </Fieldset>
                                        : ''
                                    }
                                    {/* <Fieldset legend="Scholarship">
                                        {!this.state.viewMode ?
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.scholarship} onChange={(e) => this.setState({ program: { ...this.state.program, scholarship: e.target.value }, isDirty: true })} />
                                                    <label className={'p-margin-left-10' + highlightLabelChanged(this.state.program.scholarship, this.state.tmpProgram.scholarship)}>Scholarship support?</label>
                                                </div>
                                                <FieldWrapper col="p-col-12" keyField="scholarshipGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.feeAssistanceId, this.state.tmpProgram.feeAssistanceId)}>
                                                        <label className="p-label">Fee assistance</label>
                                                        <SelectGroup isClearable={true} defaultValue={this.getDefaultFeeAssistanceSelected()} options={this.state.feeAssistances} onChange={(e) => this.setState({ program: { ...this.state.program, feeAssistanceId: e ? e.value : ''}, isDirty: true})} style={{ width: '100%' }} />
                                                        <div className="p-form-error">{this.state.errors.feeAssistanceId}</div>
                                                </FieldWrapper>
                                            </div>
                                            :
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <label>Scholarship support: {this.state.program.scholarship ? 'YES' : 'NO'}</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Fee assistance:</label>
                                                    <div>{this.displayFeeAssistance()}</div>
                                                </div>
                                            </div>
                                        }
                                    </Fieldset> */}

                                    {/* <Fieldset legend="Accounting">
                                        {!this.state.viewMode ?
                                            <div className="p-grid p-fluid form-group">
                                                <FieldWrapper col="p-col-12" keyField="cashGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.cashGLAccount, this.state.tmpProgram.cashGLAccount)}>
                                                    <label className="p-label">Cash Account</label>
                                                    <Dropdown value={this.state.program.cashGLAccount} options={this.state.cashGLAccounts} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, cashGLAccount: e.value }, isDirty: true })} />
                                                    <div className="p-form-error">{this.state.errors.cashGLAccount}</div>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="receivableGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.receivableGLAccount, this.state.tmpProgram.receivableGLAccount)}>
                                                    <label className="p-label">Account Receivable</label>
                                                    <Dropdown value={this.state.program.receivableGLAccount} options={this.state.receivableGLAccounts} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, receivableGLAccount: e.value }, isDirty: true })} />
                                                    <div className="p-form-error">{this.state.errors.receivableGLAccount}</div>
                                                </FieldWrapper>
                                                <div className="p-fieldset-line"></div>
                                                <div className="p-col-12 p-margin-top-10">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.deferred} onChange={(e) => this.setState({ program: { ...this.state.program, deferred: e.target.value }, isDirty: true })} />
                                                    <label className={'p-margin-left-10' + highlightLabelChanged(this.state.program.deferred, this.state.tmpProgram.deferred)}>Is Deferred?</label>
                                                </div>
                                                <FieldWrapper col="p-col-12" keyField="deferredGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.deferredGLAccount, this.state.tmpProgram.deferredGLAccount)}>
                                                    <label className="p-label">Deferred Account</label>
                                                    <Dropdown value={this.state.program.deferredGLAccount} options={this.state.deferredGLAccounts} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, deferredGLAccount: e.value }, isDirty: true })} />
                                                    <div className="p-form-error">{this.state.errors.deferredGLAccount}</div>
                                                </FieldWrapper>
                                                <div className="p-fieldset-line"></div>
                                                <div className="p-col-12">
                                                    <div className="p-hr">Program Revenue Account</div>
                                                </div>
                                                <FieldWrapper col="p-col-12" keyField="basicPriceGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.basicPriceGLAccount, this.state.tmpProgram.basicPriceGLAccount)}>
                                                    <label className="p-label">Non-member</label>
                                                    <Dropdown value={this.state.program.basicPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onProgramBasicPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                    <div className="p-form-error">{this.state.errors.basicPriceGLAccount}</div>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="membershipPriceGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.membershipPriceGLAccount, this.state.tmpProgram.membershipPriceGLAccount)}>
                                                    <label className="p-label">Member</label>
                                                    <Dropdown value={this.state.program.membershipPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onProgramMembershipPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                    <div className="p-form-error">{this.state.errors.membershipPriceGLAccount}</div>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="staffPriceGLAccount" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.staffPriceGLAccount, this.state.tmpProgram.staffPriceGLAccount)}>
                                                    <label className="p-label">Staff</label>
                                                    <Dropdown value={this.state.program.staffPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onProgramStaffPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                    <div className="p-form-error">{this.state.errors.staffPriceGLAccount}</div>
                                                </FieldWrapper>
                                            </div>
                                            :
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <label className="p-label">Cash Account</label>
                                                    <div>{this.state.program.cashGLAccount ? this.state.program.cashGLAccount : 'Not use'}</div>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Account Receivable</label>
                                                    <div>{this.state.program.receivableGLAccount}</div>
                                                </div>
                                                <div className="p-fieldset-line"></div>
                                                <div className="p-col-12 p-margin-top-10">
                                                    <label>Is Deferred: {this.state.program.deferred ? 'YES' : 'NO'}</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Deferred Account</label>
                                                    <div>{this.state.program.deferredGLAccount ? this.state.program.deferredGLAccount : 'Not use'}</div>
                                                </div>
                                                <div className="p-fieldset-line"></div>
                                                <div className="p-col-12">
                                                    <div className="p-hr">Program Revenue Account</div>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Non-member</label>
                                                    <div>{this.state.program.basicPriceGLAccount ? this.state.program.basicPriceGLAccount : 'Not use'}</div>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Member</label>
                                                    <div>{this.state.program.membershipPriceGLAccount ? this.state.program.membershipPriceGLAccount : 'Not use'}</div>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Staff</label>
                                                    <div>{this.state.program.staffPriceGLAccount ? this.state.program.staffPriceGLAccount : 'Not use'}</div>
                                                </div>
                                            </div>
                                        }
                                    </Fieldset> */}

                                </div>
                                <div className="p-col-12 p-md-6">
                                    {this.state.program.workType === 'simple' ?
                                        [
                                            <Fieldset key="fs_fieldset" legend="Field Set">
                                                {!this.state.viewMode ?
                                                    <div className="p-grid p-fluid form-group">
                                                        <FieldWrapper col="p-col-12" keyField="customFieldSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.customFieldSetId, this.state.tmpProgram.customFieldSetId)}>
                                                            <label className="p-label">Custom Field Set</label>
                                                            <Dropdown value={this.state.program.customFieldSetId} options={this.state.customFieldSets} onChange={(e) => this.setState({ program: { ...this.state.program, customFieldSetId: e.value }, isDirty: true })} style={{ width: '100%' }} />
                                                        </FieldWrapper>
                                                        <FieldWrapper col="p-col-12" keyField="waiverFieldSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.waiverFieldSetId, this.state.tmpProgram.waiverFieldSetId)}>
                                                            <label className="p-label">Waiver Field Set</label>
                                                            <Dropdown value={this.state.program.waiverFieldSetId} options={this.state.waiverFieldSets} onChange={(e) => this.setState({ program: { ...this.state.program, waiverFieldSetId: e.value }, isDirty: true })} style={{ width: '100%' }} />
                                                        </FieldWrapper>
                                                    </div>
                                                    :
                                                    <div className="p-grid p-fluid form-group">
                                                        <div className="p-col-12">
                                                            <label className="p-label">Custom Field Set</label>
                                                            <div>{this.onDisplaySet(this.state.program.customFieldSetId, this.state.customFieldSets)}</div>
                                                        </div>
                                                        <div className="p-col-12">
                                                            <label className="p-label">Waiver Field Set</label>
                                                            <div>{this.onDisplaySet(this.state.program.waiverFieldSetId, this.state.waiverFieldSets)}</div>
                                                        </div>
                                                    </div>
                                                }
                                            </Fieldset>,
                                            // <Fieldset key="fs_attendee" legend="Attendee Required">
                                            //     {!this.state.viewMode ?
                                            //         <div className="p-grid p-fluid form-group">
                                            //             <FieldWrapper col="p-col-12" keyField="gender" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.gender, this.state.tmpProgram.gender)}>
                                            //                 <label className="p-label">Gender</label>
                                            //                 <Dropdown id="gender" value={this.state.program.gender} options={GENDERS} onChange={(e) => this.setState({ program: { ...this.state.program, gender: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select gender" />
                                            //             </FieldWrapper>
                                            //             <FieldWrapper col="p-col-12" keyField="grades" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.grades, this.state.tmpProgram.grades)}>
                                            //                 <label className="p-label">Grades</label>
                                            //                 <MultiSelect value={this.state.program.grades} options={this.state.grades} onChange={(e) => this.setState({ program: { ...this.state.program, grades: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select grade" />
                                            //             </FieldWrapper>
                                            //             <div className="p-col-12 p-md-6" style={{ overflow: "hidden" }}>
                                            //                 <label className="p-label">Min age</label>
                                            //                 <div className="p-inputgroup p-grid" >
                                            //                     <FieldWrapper addStyle="hidden" col="p-col" keyField="minAgeYear" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.minAgeYear, this.state.tmpProgram.minAgeYear)}>
                                            //                         <span className={'p-inputgroup-addon'}>YY:</span>
                                            //                         <InputText style={{ position: "absolute" }} value={this.state.program.minAgeYear} keyfilter="int" onFocus={(e) => e.target.select()} onChange={(e) => this.setState({ program: { ...this.state.program, minAgeYear: e.target.value }, isDirty: true })} />
                                            //                     </FieldWrapper>
                                            //                     <FieldWrapper addStyle="hidden" col="p-col" keyField="minAgeMonth" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.minAgeMonth, this.state.tmpProgram.minAgeMonth)}>
                                            //                         <span className={'p-inputgroup-addon'}>MM:</span>
                                            //                         <InputText style={{ position: "absolute" }} value={this.state.program.minAgeMonth} keyfilter="int" onFocus={(e) => e.target.select()} onChange={(e) => this.setState({ program: { ...this.state.program, minAgeMonth: e.target.value }, isDirty: true })} />
                                            //                     </FieldWrapper>
                                            //                 </div>
                                            //                 <span className="p-form-error">{this.state.errors.minAge}</span>
                                            //             </div>
                                            //             <div className="p-col-12 p-md-6" style={{ overflow: "hidden" }}>
                                            //                 <label className="p-label">Max age</label>
                                            //                 <div className="p-inputgroup p-grid">
                                            //                     <FieldWrapper addStyle="hidden" col="p-col" keyField="maxAgeYear" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.maxAgeYear, this.state.tmpProgram.maxAgeYear)}>
                                            //                         <span className={'p-inputgroup-addon'}>YY:</span>
                                            //                         <InputText style={{ position: "absolute" }} value={this.state.program.maxAgeYear} keyfilter="int" onFocus={(e) => e.target.select()} onChange={(e) => this.setState({ program: { ...this.state.program, maxAgeYear: e.target.value }, isDirty: true })} />
                                            //                     </FieldWrapper>
                                            //                     <FieldWrapper addStyle="hidden" col="p-col" keyField="maxAgeMonth" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.maxAgeMonth, this.state.tmpProgram.maxAgeMonth)}>
                                            //                         <span className={'p-inputgroup-addon'}>MM:</span>
                                            //                         <InputText style={{ position: "absolute" }} value={this.state.program.maxAgeMonth} keyfilter="int" onFocus={(e) => e.target.select()} onChange={(e) => this.setState({ program: { ...this.state.program, maxAgeMonth: e.target.value }, isDirty: true })} />
                                            //                     </FieldWrapper>
                                            //                 </div>
                                            //                 <span className="p-form-error">{this.state.errors.maxAge}</span>
                                            //             </div>
                                            //         </div>
                                            //         :
                                            //         <div className="p-grid p-fluid form-group">
                                            //             <div className="p-col-12">
                                            //                 <label className="p-label">Gender</label>
                                            //                 <div>{this.state.program.gender ? this.state.program.gender : 'Not use'}</div>
                                            //             </div>
                                            //             <div className="p-col-12">
                                            //                 <label className="p-label">Grades</label>
                                            //                 <div>{!this.state.program.grades ? this.state.program.grades.join(', ') : ''}</div>
                                            //             </div>
                                            //             <div className="p-col-12 p-md-6">
                                            //                 <label className="p-label">Min age</label>
                                            //                 <div className="p-inputgroup">
                                            //                     <span className="p-inputgroup-addon">YY: {this.state.program.minAgeYear}</span>
                                            //                     <span className="p-inputgroup-addon">MM: {this.state.program.minAgeMonth}</span>
                                            //                 </div>
                                            //             </div>
                                            //             <div className="p-col-12 p-md-6">
                                            //                 <label className="p-label">Max age</label>
                                            //                 <div className="p-inputgroup">
                                            //                     <span className="p-inputgroup-addon">YY: {this.state.program.maxAgeYear}</span>
                                            //                     <span className="p-inputgroup-addon">MM: {this.state.program.maxAgeMonth}</span>
                                            //                 </div>
                                            //             </div>
                                            //         </div>
                                            //     }
                                            // </Fieldset>
                                        ]
                                        : ''
                                    }
                                    <Fieldset legend="Notification">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">Notification by:</label>
                                            </div>
                                            <div className="p-col-12">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6">
                                                        <Checkbox inputId="cbNotifyWhenFull" disabled={this.state.viewMode ? true : false} checked={this.state.program.notifyWhenFull} onChange={(e) => this.setState({ program: { ...this.state.program, notifyWhenFull: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyWhenFull" className={'p-checkbox-label' + highlightLabelChanged(this.state.program.notifyWhenFull, this.state.tmpProgram.notifyWhenFull)}>Notify when full?</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <Checkbox inputId="cbNotifyWhenWaitlistFull" disabled={this.state.viewMode ? true : false} checked={this.state.program.notifyWhenWaitlistFull} onChange={(e) => this.setState({ program: { ...this.state.program, notifyWhenWaitlistFull: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyWhenWaitlistFull" className={'p-checkbox-label' + highlightLabelChanged(this.state.program.notifyWhenWaitlistFull, this.state.tmpProgram.notifyWhenWaitlistFull)}>Notify when waitlist full?</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6">
                                                        <Checkbox inputId="cbNotifyEveryRegistration" disabled={this.state.viewMode ? true : false} checked={this.state.program.notifyEveryRegistration} onChange={(e) => this.setState({ program: { ...this.state.program, notifyEveryRegistration: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyEveryRegistration" className={'p-checkbox-label p-margin-right-30' + highlightLabelChanged(this.state.program.notifyEveryRegistration, this.state.tmpProgram.notifyEveryRegistration)}>Notify Every Registration?</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <Checkbox inputId="cbNotifyRegistrationReach" disabled={this.state.viewMode ? true : false} checked={this.state.program.notifyRegistrationReach} onChange={(e) => this.setState({ program: { ...this.state.program, notifyRegistrationReach: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyRegistrationReach" className={'p-checkbox-label' + highlightLabelChanged(this.state.program.notifyRegistrationReach, this.state.tmpProgram.notifyRegistrationReach)}>Registration Reached Threshold?</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <FieldWrapper keyField="notifyRegistrationReachQuantity" col="p-col-12" resetChange={this.resetChange} highlight={highlightChanged(this.state.program.notifyRegistrationReachQuantity, this.state.tmpProgram.notifyRegistrationReachQuantity)}>
                                                <label className="p-label">Registration Number Threshold</label>
                                                {!this.state.viewMode ?
                                                    <React.Fragment>
                                                        <InputText keyfilter="int" value={this.state.program.notifyRegistrationReachQuantity} onChange={(e) => this.setState({ program: { ...this.state.program, notifyRegistrationReachQuantity: e.target.value }, isDirty: true })} />
                                                        <span className="p-form-error">{this.state.errors.notifyRegReachQuantity}</span>
                                                    </React.Fragment>
                                                    : <div>{this.state.program.notifyRegistrationReachQuantity}</div>
                                                }

                                            </FieldWrapper>
                                        </div>
                                    </Fieldset>
                                </div>
                                {this.state.program.id &&
                                    <div className="p-col-12">
                                        {!this.state.viewMode ?
                                            <React.Fragment>
                                                <Button label="Cancel Edit" icon="pi pi-lock" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.onCancelViewMode()} />
                                                {/* <Button label="Save Course Template Settings" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={(e) => this.handleSaveCourseTemplateSettings()} /> */}
                                                <Button label="Save Course Template Settings" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => this.showDialogUpdateAllCourses()} />
                                            </React.Fragment>
                                            : <Button label={this.state.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.acquireFormLock('programLock')} />

                                        }
                                    </div>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel header="Audits">
                            <AuditLog type="program" id={this.props.match.params.id} />
                        </TabPanel>
                    </TabView>
                </div>
                <div className={"p-col-12 " + (this.state.isAddCourse ? '' : 'p-hide')}>
                    <div className="card card-w-title">
                        <h2>
                            Create Course
                            <div className="p-toolbar-group-right">
                                <Button icon="pi-md-close" className="p-button-primary" onClick={() => this.setState({ isAddCourse: false })} />
                            </div>
                        </h2>
                    </div>
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Basic Information">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">* Campaign</label>
                                            <div className="p-inputgroup">
                                                <Dropdown value={this.state.course.campaignId} options={this.state.activeCampaigns} onChange={(e) => this.onCampaignChange(e)} style={{ width: '100%' }} placeholder="Select a Campaign" />
                                                <Button label="New" icon="pi-md-plus" onClick={() => this.campaignForm.action()} />
                                            </div>
                                            <span className="p-form-error">{this.state.errors.campaign}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Code</label>
                                            <InputText keyfilter={/[^\s]/} value={this.state.course.code} onChange={(e) => this.setState({ course: { ...this.state.course, code: replaceSpaceAndUpperCase(e.target.value) }, isDirty: true })} />
                                            <span className="p-form-error">{this.state.errors.code}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Name</label>
                                            <InputText value={this.state.course.name} onChange={(e) => this.setState({ course: { ...this.state.course, name: titleCaseText(e.target.value) }, isDirty: true })} />
                                            <span className="p-form-error">{this.state.errors.name}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Description</label>
                                            <InputTextarea rows={4} cols={100} value={this.state.course.description} placeholder="Enter course description" onChange={(e) => this.setState({ course: { ...this.state.course, description: e.target.value } })} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">* Marshaller Emails (Seperated by comma)</label>
                                            <InputText value={this.state.course.moderatorEmail} onChange={(e) => this.setState({ course: { ...this.state.course, moderatorEmail: e.target.value } })} />
                                            <span className="p-form-error">{(this.state.errors.moderatorEmail)}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">* Primary Location</label>
                                            <div className="p-inputgroup">
                                                <Dropdown id="location" value={this.state.course.locationId} options={this.state.locations} filter={true} onChange={(e) => this.setState({ course: { ...this.state.course, locationId: e.value } })} style={{ width: '100%' }} placeholder="Select a Location" />
                                                <Button label="New" icon="pi-md-plus" onClick={() => this.onAddLocation('primary')} />
                                            </div>
                                            <span className="p-form-error">{this.state.errors.location}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Secondary Location</label>
                                            <div className="p-inputgroup">
                                                <Dropdown id="location" value={this.state.course.secondaryLocationId} options={this.state.locations} filter={true} showClear={true} onChange={(e) => this.setState({ course: { ...this.state.course, secondaryLocationId: e.value } })} style={{ width: '100%' }} placeholder="Select a Location" />
                                                <Button label="New" icon="pi-md-plus" onClick={() => this.onAddLocation('secondary')} />
                                            </div>
                                            <span className="p-form-error">{this.state.errors.secondaryLocation}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Instructors</label>
                                            <MultiSelect value={this.state.course.instructorIds} options={this.state.instructors} filter={true} onChange={(e) => this.setState({ course: { ...this.state.course, instructorIds: e.value } })} placeholder="Select Instructor" />
                                            <span className="p-form-error">{this.state.errors.instructor}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Trainers</label>
                                            <MultiSelect value={this.state.course.trainerIds} options={this.state.trainers} filter={true} onChange={(e) => this.setState({ course: { ...this.state.course, trainerIds: e.value } })} placeholder="Select Trainer" />
                                        </div>
                                        <div className="p-col-4">
                                            <label className="p-label">Weight (for order)</label>
                                            <Spinner value={this.state.course.weight} step={1} onChange={(e) => this.setState({ course: { ...this.state.course, weight: e.target.value ? e.target.value : 0 } })} />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>

                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Fee">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">Non-member</label>
                                            <div className="p-inputgroup">
                                                <Spinner id="basicPrice" value={this.state.course.basicPrice} min={0} step={0.25} onChange={(e) => this.setState({ course: { ...this.state.course, basicPrice: e.target.value } })} />
                                                <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                                    <Checkbox inputId="cbNoneMemberUse" checked={this.state.course.noneMemberUse} onChange={(e) => this.setState({ course: { ...this.state.course, noneMemberUse: e.checked } })} />
                                                    <label htmlFor="cbNoneMemberUse" className="p-margin-left-10">Use?</label>
                                                </span>
                                            </div>
                                            <div className="p-form-error p-margin-top-5">{this.state.errors.basicPrice}</div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">Member</label>
                                            <div className="p-inputgroup">
                                                <Spinner id="name" value={this.state.course.memberPrice} min={0} step={0.25} onChange={(e) => this.setState({ course: { ...this.state.course, memberPrice: e.target.value } })} />
                                                <span className="p-inputgroup-addon p-padding-top-0 p-padding-left-20 p-nowrap">
                                                    <Checkbox inputId="cbMembershipUse" checked={this.state.course.membershipUse} onChange={(e) => this.setState({ course: { ...this.state.course, membershipUse: e.checked } })} />
                                                    <label htmlFor="cbMembershipUse" className="p-margin-left-10">Use?</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Course Schedule ">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-6">
                                            <label className="p-label">* Start Date</label>
                                            <MaskedCalendar value={this.state.course.startDateValue} showIcon={true} onChange={this.onStartDateChange} dateFormat="mm-dd-yy" />
                                            <span className="p-form-error">{this.state.errors.startDate}</span>
                                        </div>
                                        <div className="p-col-6">
                                            <label className="p-label">* End Date</label>
                                            <MaskedCalendar value={this.state.course.endDateValue} showIcon={true} onChange={this.onEndDateChange} dateFormat="mm-dd-yy" />
                                            <span className="p-form-error">{this.state.errors.endDate}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Start Time</label>
                                            <MaskedCalendar value={this.state.course.startTimeValue} showIcon={true} onChange={this.onStartTimeChange} hourFormat="12" timeOnly={true} />
                                            <span className="p-form-error">{this.state.errors.startTime}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* End Time</label>
                                            <MaskedCalendar value={this.state.course.endTimeValue} showIcon={true} onChange={this.onEndTimeChange} hourFormat="12" timeOnly={true} i />
                                            <span className="p-form-error">{this.state.errors.endTime}</span>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Reg. Quantity Range">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-6">
                                            <label>Min Reg.</label>
                                            <Spinner value={this.state.course.minReg} min={0} onChange={(e) => this.setState({course: { ...this.state.course, minReg: e.target.value}})}/>
                                            <span className="p-form-error">{this.state.errors.minReg}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label>Max Reg.</label>
                                            <Spinner value={this.state.course.maxReg} min={0} onChange={(e) => this.setState({course: { ...this.state.course, maxReg: e.target.value}})}/>
                                            <span className="p-form-error">{this.state.errors.maxReg}</span>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Display">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.active} onChange={(e) => this.setState({ course: { ...this.state.course, active: e.value } })} />
                                            <label className="p-margin-left-10">Active?</label>
                                        </div>
                                        {this.state.program.workType === 'simple' ?
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.allowOnlineRegistration} onChange={(e) => this.onChangeSwitchRegistration(e, 'course')} />
                                                    <label className="p-margin-left-10">Allow online registration?</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.showOnWeb} disabled={this.state.course.allowOnlineRegistration ? true : false} onChange={(e) => this.setState({ course: { ...this.state.course, showOnWeb: e.value } })} />
                                                    <label className="p-margin-left-10">Show on web?</label>
                                                </div>
                                                {/* <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.showNotAvailable} onChange={(e) => this.setState({course: { ...this.state.course, showNotAvailable: e.value}})} />
                                                    <label className="p-margin-left-10">Show when not available (Coming soon)?</label>
                                                </div> */}
                                            </React.Fragment>
                                            : ''
                                        }
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12">
                                <Fieldset legend="Other Settings">
                                    <div className="p-grid">
                                        {/* <div className="p-col-12 p-md-6">
                                            <Fieldset legend="Scholarship">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.scholarship} onChange={(e) => this.setState({ course: { ...this.state.course, scholarship: e.target.value }, isDirty: true })} />
                                                        <label className="p-margin-left-10">Scholarship support?</label>
                                                    </div>
                                                    {this.state.course.scholarship &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">Fee assistance</label>
                                                            <SelectGroup isClearable={true} options={this.state.feeAssistances} onChange={(e) => this.setState({ course: { ...this.state.course, feeAssistanceId: e ? e.value : '' } })} style={{ width: '100%' }} />
                                                            <div className="p-form-error">{this.state.errors.feeAssistanceId}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </Fieldset>
                                            <Fieldset legend="Accounting">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label">* Cash Account</label>
                                                        <Dropdown value={this.state.course.cashGLAccount} options={this.state.cashGLAccounts} filter={true} onChange={(e) => this.setState({ course: { ...this.state.course, cashGLAccount: e.value } })} />
                                                        <div className="p-form-error">{this.state.errors.cashGLAccount}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">* Account Receivable</label>
                                                        <Dropdown value={this.state.course.receivableGLAccount} options={this.state.receivableGLAccounts} filter={true} onChange={(e) => this.setState({ course: { ...this.state.course, receivableGLAccount: e.value } })} />
                                                        <div className="p-form-error">{this.state.errors.receivableGLAccount}</div>
                                                    </div>
                                                    <div className="p-fieldset-line"></div>
                                                    <div className="p-col-12 p-margin-top-10">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.deferred} onChange={(e) => this.setState({ course: { ...this.state.course, deferred: e.target.value } })} />
                                                        <label className="p-margin-left-10">Is Deferred?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">* Deferred Account</label>
                                                        <Dropdown value={this.state.course.deferredGLAccount} options={this.state.deferredGLAccounts} filter={true} onChange={(e) => this.setState({ course: { ...this.state.course, deferredGLAccount: e.value } })} />
                                                        <div className="p-form-error">{this.state.errors.deferredGLAccount}</div>
                                                    </div>
                                                    <div className="p-fieldset-line"></div>
                                                    <div className="p-col-12">
                                                        <div className="p-hr">Program Revenue Account</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">* Non-member</label>
                                                        <Dropdown value={this.state.course.basicPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onCourseBasicPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                        <div className="p-form-error">{this.state.errors.basicPriceGLAccount}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">* Member</label>
                                                        <Dropdown value={this.state.course.membershipPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onCourseMembershipPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                        <div className="p-form-error">{this.state.errors.membershipPriceGLAccount}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">* Staff</label>
                                                        <Dropdown value={this.state.course.staffPriceGLAccount} options={this.state.programGLAccounts} filter={true} onChange={(e) => this.onCourseStaffPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                        <div className="p-form-error">{this.state.errors.staffPriceGLAccount}</div>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div> */}
                                        <div className="p-col-12 p-md-6">
                                            {this.state.program.workType === 'simple' ?
                                                [
                                                    <Fieldset key="fs_fieldSet" legend="Field Set">
                                                        <div className="p-grid p-fluid form-group">
                                                            <div className="p-col-12">
                                                                <label className="p-label">Custom Field Set</label>
                                                                <Dropdown value={this.state.course.customFieldSetId} options={this.state.customFieldSets} showClear={true} onChange={(e) => this.setState({ course: { ...this.state.course, customFieldSetId: e.value } })} style={{ width: '100%' }} />
                                                            </div>
                                                            <div className="p-col-12">
                                                                <label className="p-label">Waiver Field Set</label>
                                                                <Dropdown value={this.state.course.waiverFieldSetId} options={this.state.waiverFieldSets} showClear={true} onChange={(e) => this.setState({ course: { ...this.state.course, waiverFieldSetId: e.value } })} style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </Fieldset>,
                                                    <Fieldset key="fs_regSchedule" legend="Registration Schedule">
                                                        <div className="p-grid p-fluid form-group">
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">* Non-member start</label>
                                                                <MaskedCalendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.course.regStartDateValue} onChange={this.onRegStartDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                                <span className="p-form-error">{this.state.errors.regStartDate}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">* Non-member end</label>
                                                                <MaskedCalendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.course.regEndDateValue} onChange={this.onRegEndDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                                <span className="p-form-error">{this.state.errors.regEndDate}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">* Member start</label>
                                                                <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.course.memberRegStartDateValue} onChange={this.onMemberRegStartDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                                <span className="p-form-error">{this.state.errors.memberRegStartDate}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">* Member end</label>
                                                                <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.course.memberRegEndDateValue} onChange={this.onMemberRegEndDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                                <span className="p-form-error">{this.state.errors.memberRegEndDate}</span>
                                                            </div>
                                                        </div>
                                                    </Fieldset>,
                                                    // <Fieldset key="fs_attendee" legend="Attendee Required">
                                                    //     <div className="p-grid p-fluid form-group">
                                                    //         <div className="p-col-12">
                                                    //             <label className="p-label">Gender</label>
                                                    //             <Dropdown id="gender" value={this.state.course.gender} options={GENDERS} onChange={(e) => this.setState({ course: { ...this.state.course, gender: e.value } })} style={{ width: '100%' }} placeholder="Select gender" />
                                                    //         </div>
                                                    //         <div className="p-col-12">
                                                    //             <label className="p-label">Grades</label>
                                                    //             <MultiSelect value={this.state.course.grades} options={this.state.grades} onChange={(e) => this.setState({ course: { ...this.state.course, grades: e.value } })} style={{ width: '100%' }} placeholder="Select grade" />
                                                    //         </div>
                                                    //         <div className="p-col-12 p-md-6">
                                                    //             <label className="p-label">Min age</label>
                                                    //             <div className="p-inputgroup">
                                                    //                 <span className="p-inputgroup-addon">YY:</span>
                                                    //                 <InputText value={this.state.course.minAgeYear} keyfilter="int" onChange={(e) => this.setState({ course: { ...this.state.course, minAgeYear: e.target.value } })} />
                                                    //                 <span className="p-inputgroup-addon">MM:</span>
                                                    //                 <InputText value={this.state.course.minAgeMonth} keyfilter="int" onChange={(e) => this.setState({ course: { ...this.state.course, minAgeMonth: e.target.value } })} />
                                                    //             </div>
                                                    //             <span className="p-form-error">{this.state.errors.minAge}</span>
                                                    //         </div>
                                                    //         <div className="p-col-12 p-md-6">
                                                    //             <label className="p-label">Max age</label>
                                                    //             <div className="p-inputgroup">
                                                    //                 <span className="p-inputgroup-addon">YY:</span>
                                                    //                 <InputText value={this.state.course.maxAgeYear} keyfilter="int" onChange={(e) => this.setState({ course: { ...this.state.course, maxAgeYear: e.target.value } })} />
                                                    //                 <span className="p-inputgroup-addon">MM:</span>
                                                    //                 <InputText value={this.state.course.maxAgeMonth} keyfilter="int" onChange={(e) => this.setState({ course: { ...this.state.course, maxAgeMonth: e.target.value } })} />
                                                    //             </div>
                                                    //             <span className="p-form-error">{this.state.errors.maxAge}</span>
                                                    //         </div>
                                                    //     </div>
                                                    // </Fieldset>
                                                ]
                                                : ''
                                            }
                                            <Fieldset legend="Notification">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label p-margin-bottom-10">Notification by:</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-6">
                                                                <Checkbox inputId="cbNotifyWhenFull" checked={this.state.course.notifyWhenFull} onChange={(e) => this.setState({ course: { ...this.state.course, notifyWhenFull: e.checked } })} />
                                                                <label htmlFor="cbNotifyWhenFull" className="p-checkbox-label">Notify when full?</label>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <Checkbox inputId="cbNotifyWhenWaitlistFull" checked={this.state.course.notifyWhenWaitlistFull} onChange={(e) => this.setState({ course: { ...this.state.course, notifyWhenWaitlistFull: e.checked } })} />
                                                                <label htmlFor="cbNotifyWhenWaitlistFull" className="p-checkbox-label">Notify when waitlist full?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-6">
                                                                <Checkbox inputId="cbNotifyEveryRegistration" checked={this.state.course.notifyEveryRegistration} onChange={(e) => this.setState({ course: { ...this.state.course, notifyEveryRegistration: e.checked } })} />
                                                                <label htmlFor="cbNotifyEveryRegistration" className="p-checkbox-label">Notify Every Registration?</label>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <Checkbox inputId="cbNotifyRegistrationReach" checked={this.state.course.notifyRegistrationReach} onChange={(e) => this.setState({ course: { ...this.state.course, notifyRegistrationReach: e.checked } })} />
                                                                <label htmlFor="cbNotifyRegistrationReach" className="p-checkbox-label">Registration Reached Threshold?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-md-6 ">
                                                        <label className="p-label">Registration Number Threshold</label>
                                                        <InputText keyfilter="int" value={this.state.course.notifyRegistrationReachQuantity} onChange={(e) => this.setState({ course: { ...this.state.course, notifyRegistrationReachQuantity: e.target.value } })} />
                                                        <span className="p-form-error">{this.state.errors.notifyRegReachQuantity}</span>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12">
                                <Button label="Save Course" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => this.handleSaveNewProgramCourse()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    },
    dialogContentConfirmUpdateDataTemplate: {
        minWidth: '500px'
    },
}

export default withNamespaces('message')(ProgramDetails);
