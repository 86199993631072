import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RULE_FILTER_OBJECTS, RULE_FILTER_TYPES, DEFAULT_RULE_FILTER, RULE_FILTER_OBJECT, DROPDOWN_MULTI_STYLES, translateListSimple } from '../../constants';
import Select from 'react-select';
import { parseMessage } from "../../core/utils/TranslationUtils";

export class RuleFilters extends Component {
    constructor(props){
        super(props);
        this.state = {
            filters: props.data
        };
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({
                filters: this.props.data
            });
        }
    }

    addFilter = () => {
        let tmpFilters = [...this.state.filters];
        tmpFilters.push({...DEFAULT_RULE_FILTER});
        this.props.onChange(tmpFilters);
    }

    removeFilter = (idx) => {
        let tmpFilters = [...this.state.filters];
        tmpFilters.splice(idx, 1);
        this.props.onChange(tmpFilters);
    }

    onElementChange = (idx, key, data) => {
        let tmpFilters = [...this.state.filters];
        let item = tmpFilters[idx];
        switch(key){
            case 'applyObject':
                item.applyObject=data;
                item.applyTo=null;
                break;
            case 'applyType':
                item.applyType=data;
                break;
            case 'applyTo':
                let tmpData = [];
                tmpData = data && data.map(item => (item.value));

                item.applyTo=tmpData;
                break;
            default:
                break;
        }
            
        tmpFilters.splice(idx, 1, item);
        this.props.onChange(tmpFilters);
    }

    getFilterOptions = (applyObject) => {
        switch(applyObject){
            case RULE_FILTER_OBJECT.specific_products:
                return this.props.products;
            case RULE_FILTER_OBJECT.specific_menus:
                return this.props.menus;
            case RULE_FILTER_OBJECT.specific_categories:
                return this.props.categories;
            default:
                break;
        }
    }

    getFilterApplyTo = (applyObject, idx) => {
        let values = [];
        switch(applyObject){
            case RULE_FILTER_OBJECT.specific_products:
                values = this.props.products && this.state.filters[idx].applyTo ? this.props.products.filter(x1 => this.state.filters[idx].applyTo.some(x2 => x2 === x1.value)) : [];
                break;
            case RULE_FILTER_OBJECT.specific_menus:
                values = this.props.menus && this.state.filters[idx].applyTo ? this.props.menus.filter(x1 => this.state.filters[idx].applyTo.some(x2 => x2 === x1.value)) : [];
                break;
            case RULE_FILTER_OBJECT.specific_categories:
                values = this.props.categories && this.state.filters[idx].applyTo ? this.props.categories.filter(x1 => this.state.filters[idx].applyTo.some(x2 => x2 === x1.value)) : [];
                break;
            default:
                break;
        }
        return values.length>0?values:null;
    }

    render(){
        const trans = this.props.trans;
        const errorIndex = this.props.errorFilter.info === 0 ? 0 : this.props.errorFilter.info;
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {this.state.filters && this.state.filters.map((val, idx) => {
                        return (
                            <div key={"filter_"+idx} className="p-grid p-fluid">
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={val.applyObject}
                                        options={translateListSimple(trans, RULE_FILTER_OBJECTS)} className="p-frames" onChange={(e) => this.onElementChange(idx, 'applyObject', e.value)} />
                                </div>
                                {val.applyObject!==RULE_FILTER_OBJECT.all_product &&
                                <React.Fragment>
                                    <div className="p-col-12 p-md-2">
                                        <Dropdown value={val.applyType} options={translateListSimple(trans, RULE_FILTER_TYPES)} className="p-frames" onChange={(e) => this.onElementChange(idx, 'applyType', e.value)} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <Select value={this.getFilterApplyTo(val.applyObject, idx)} closeMenuOnSelect={false} isClearable isSearchable isMulti styles={DROPDOWN_MULTI_STYLES}
                                            options={this.getFilterOptions(val.applyObject)} className="p-select p-frames" onChange={(e) => this.onElementChange(idx, 'applyTo', e)}/>
                                        {errorIndex === idx &&
                                            <span className="p-form-error">{this.props.errorFilter && <div>{parseMessage(trans, this.props.errorFilter.code, this.props.errorFilter.info?{count: this.props.errorFilter.info[0]}:{})}</div>}</span>
                                        }
                                    </div>
                                </React.Fragment>
                                }
                                {this.state.filters.length>1 &&
                                    <div className="p-col-12 p-md-1">
                                        <Button icon="pi-md-close" className="p-button-danger btn-sm" style={{top: "2px"}} onClick={() => this.removeFilter(idx)}/>
                                    </div>
                                }

                                <div className="p-col-12 p-padding-top-0 p-padding-bottom-0 p-promo-note">
                                    {(() => {
                                        switch(val.applyObject){
                                            case RULE_FILTER_OBJECT.all_product:
                                                return trans('promotion.mgt.rule_filter_all_product_promo_text');
                                            case RULE_FILTER_OBJECT.specific_products:
                                                return trans('promotion.mgt.rule_filter_specific_product_promo_text');
                                            case RULE_FILTER_OBJECT.specific_categories:
                                                return trans('promotion.mgt.rule_filter_specific_categories_promo_text');
                                            case RULE_FILTER_OBJECT.specific_menus:
                                                return trans('promotion.mgt.rule_filter_specific_menus_promo_text');
                                            default: break;
                                        }
                                    })()}
                                </div>
                                <div className="p-col-12"><div className="p-hr p-padding-none"></div></div>
                            </div>                          
                        );
                    })}
                </div>
                <div className="p-col-12 p-padding-top-0 p-padding-bottom-0">
                    <Button label={trans('promotion.mgt.add_filter')} className="p-radius" icon="pi pi-plus" iconPos="left" onClick={this.addFilter}/>
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(RuleFilters)