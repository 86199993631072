import React, {Component} from "react";
import {Link} from 'react-router-dom';
import { ProgramServices } from "./ProgramServices";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgramAssessmentForm } from "./ProgramAssessmentForm";
import { ProgramAssessmentServices } from "./ProgramAssessmentServices";
import { ParticipantServices } from "../../constituent-management/participant/ParticipantServices";
import { showNotification } from '../../core/service/NotificationService';
import { DeleteNotification } from "../../core/components/DeleteNotification";
import moment from "moment";

export class ProgramAssessment extends Component{

    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            attendeeTotal: 0,
            course: {},
            assessment: null,
            formDialogVisible: false,
            headerFormDialog: 'New Assessment',
            formDialogDeleteVisible: false
        }
        this.programServices = new ProgramServices();
        this.assessmentServices = new ProgramAssessmentServices();
        this.participantServices = new ParticipantServices();
    }

    componentDidMount(){
        this.getProgram();
        this.getPageProgramAssessment();
        this.getListParticipants();
    }

    getProgram = () => {
        this.programServices.getProgram(this.props.match.params.cid, 'course')
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    course: res
                });
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    getPageProgramAssessment = () => {
        this.assessmentServices.getPageProgramAssessment(this.props.match.params.pid, this.props.match.params.cid, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize,
                });
            });
    }
    
    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        },()=> {
            this.getPageProgramAssessment();
        })
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => {
            this.getPageProgramAssessment();
        })
    }

    getListParticipants = () => {
        this.participantServices.getListParticipantByProgramCourse({childRefId: this.props.match.params.cid})
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    attendeeTotal: res.length
                });
            }
        })
    }

    deleteAssessment = (id) =>{
        this.assessmentServices.deleteProgramAssessment(this.props.match.params.pid, this.props.match.params.cid, id)
        .then(res => {
            if(res.status.code === 200){
                this.getPageProgramAssessment();
                showNotification('success', 'Success Message', 'Action deleted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button icon="pi pi-pencil" className="p-button-warning" onClick={(e)=> this.setState({formDialogVisible: true, assessment: rowData, headerFormDialog: 'Edit Assessment'})} />
            <Link to={`/programs/${this.props.match.params.pid}/courses/${this.props.match.params.cid}/manage/assessment/${rowData.id}`} className="p-button p-component cyan-btn p-button-icon-only">
                <span className="pi pi-search p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
            </Link>
            <Button icon="pi-md-close" className="p-button-danger" onClick={(e)=> this.deleteNotify.delete(rowData.id)} />
        </div>;
    }

    render(){
        let header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <div style={{ 'float': 'left' }}>
                <Button icon="pi pi-plus" onClick={(e) => this.setState({formDialogVisible: true, assessment: null, headerFormDialog: 'New Assessment'})}/>
            </div>
                <Button icon="pi pi-refresh" style={{'float':'right'}} onClick={(e) => this.getPageProgramAssessment()}/>
            </div>;

        return(
            <div className="p-grid">
                <ProgramAssessmentForm
                    header={this.state.headerFormDialog}
                    program={this.props.match.params.pid}
                    course={this.props.match.params.cid}
                    visible={this.state.formDialogVisible}
                    hideFormDialog={(e) => this.setState({formDialogVisible: false})}
                    refreshTable={(e) => this.getPageProgramAssessment()}
                    visibleDelete={this.state.formDialogDeleteVisible}
                    hideFormDeleteDialog={(e)=> this.setState({formDialogDeleteVisible: false})}
                    data={this.state.assessment}
                />
                <DeleteNotification ref={el => this.deleteNotify=el}
                    accessDelete={(e) => this.deleteAssessment(e)}
                />
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Course: {this.state.course.code + ' - ' + this.state.course.name}
                            <div className="p-toolbar-group-right">
                                <Link to={`/programs/${this.props.match.params.pid}/courses/${this.props.match.params.cid}/manage`} className="p-button p-component p-button-primary p-button-icon-only">
                                    <span className="pi pi-caret-left p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">Location: {this.state.course.locationName}</div>
                            <div className="p-col-12 p-md-4">Total attendees: {this.state.attendeeTotal}</div>
                            <div className="p-col-12 p-md-4">Active: {this.state.course.active?'Yes':'No'}</div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <h1>Assessments</h1>
                            <DataTable lazy={true} paginator={true} value={this.state.dataList}
                                first={this.state.page * this.state.rows}
                                rows={this.state.rows} totalRecords={this.state.total}
                                onPage={this.onPage} header={header} onSort={this.onSort}
                                sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                                selectionMode="single" responsive={true}
                            >
                                <Column field="id" header="ID" sortable={true}/>
                                <Column field="name" header="Name" sortable={true}/>
                                <Column field="type" header="Type" sortable={true}/>
                                <Column field="date" header="Date" sortable={true}
                                    body={(rowData) => {
                                        return(
                                            <React.Fragment>
                                                {moment(rowData.date).format("MM-DD-YYYY")}
                                            </React.Fragment>
                                        );
                                    }}
                                />
                                <Column header="" body={this.actionTemplate} style={{textAlign:'center',width:'12em'}}/>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}