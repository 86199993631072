import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { CONDITION_APPLY_TO_TYPES, CONDITION_APPLY_TO_TYPE, PROD_TYPE } from '../../constants';

class QuantityCondition extends Component {
    constructor(props){
        super(props);
        this.state = {
            condition: {
                applyToType: props.data?props.data.applyToType:CONDITION_APPLY_TO_TYPE.all_dish,
                applyToProducts: props.data?props.data.applyToProducts:[],
                applyToMenus: props.data?props.data.applyToMenus:[],
                applyToCategories: props.data?props.data.applyToCategories:[],
                excludeProducts: props.data?props.data.excludeProducts:[],
                acrossQuantity: props.data?props.data.acrossQuantity:false
            },
            menus: [],
            categories: [],
            dish: []
        }
    }

    componentDidMount(){
        this.loadMenus();
        this.loadCategories();
        this.loadDish({menuIds: this.state.condition.applyToMenus, categoryIds: this.state.condition.applyToCategories, types: [PROD_TYPE.dish]});
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({
                condition: this.props.data
            });
        }
    }

    loadMenus = () => {
        // getMenus(true).then(res => {
        //     this.setState({ menus: res?res:[] })
        // })
    }

    loadCategories = () => {
        // getCategories(false, false, true).then(res => {
        //     this.setState({ categories: res?res:[] })
        // })
    }

    loadDish = (filter) => {
        // getListProducts(filter, true).then(res => {
        //     this.setState({ dish: res?res:[] })
        // })
    }

    onElementChange = (key, value) => {
        let tmpCondition = {...this.state.condition};
        switch(key){
            case 'applyToType':
                tmpCondition.applyToType=value;
                tmpCondition.applyToProducts=[];
                tmpCondition.applyToMenus=[];
                tmpCondition.applyToCategories=[];
                this.loadDish({menuIds: [], categoryIds: [], types: [PROD_TYPE.dish]});
                break;
            case 'applyToProducts':
                tmpCondition.applyToProducts=value;
                break;
            case 'applyToMenus':
                tmpCondition.applyToMenus=value;
                this.loadDish({menuIds: value, types: [PROD_TYPE.dish]});
                break;
            case 'applyToCategories':
                tmpCondition.applyToCategories=value;
                this.loadDish({categoryIds: value, types: [PROD_TYPE.dish]});
                break;
            case 'excludeProducts':
                tmpCondition.excludeProducts=value;
                break;
            case 'acrossQuantity':
                tmpCondition.acrossQuantity=value;
                this.props.updateAcrossQuantity(value);
                break;
            default:
                break;
        }
            
        this.props.onChange(tmpCondition);
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend={trans('promotion.mgt.apply_dish')}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12 p-md-2">
                                <label style={{display: "block", marginTop: "2px"}}>{trans('promotion.mgt.apply_to')}:</label>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dropdown value={this.state.condition.applyToType} options={CONDITION_APPLY_TO_TYPES} onChange={(e) => this.onElementChange('applyToType', e.value)}/>
                            </div>
                            <div className="P-col-12 p-md-7">
                                <Checkbox inputId="cbAcrossQuantity" onChange={(e) => this.onElementChange('acrossQuantity', e.checked)} checked={this.state.condition.acrossQuantity}></Checkbox>
                                <label htmlFor="cbAcrossQuantity" className="p-checkbox-label">{trans('promotion.mgt.promotion_condition_apply_to_checkbox_message')}</label>
                            </div>
                            {(() => {
                                if(this.state.condition.applyToType===CONDITION_APPLY_TO_TYPE.specific_menus){
                                    return ([
                                        <div className="p-col-12 p-md-2">
                                            <label style={{display: "block", marginTop: "2px"}}>{trans('promotion.mgt.choose_menu')}:</label>
                                        </div>,
                                        <div className="p-col-12 p-md-10">
                                            <MultiSelect value={this.state.condition.applyToMenus} options={this.state.menus} onChange={(e) => this.onElementChange('applyToMenus', e.value)} filter={true}/>
                                        </div>
                                    ]);
                                }else if(this.state.condition.applyToType===CONDITION_APPLY_TO_TYPE.specific_categories){
                                    return (
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-2">
                                                <label style={{display: "block", marginTop: "2px"}}>{trans('promotion.mgt.choose_category')}:</label>
                                            </div>
                                            <div className="p-col-12 p-md-10">
                                                <MultiSelect value={this.state.condition.applyToCategories} options={this.state.categories} onChange={(e) => this.onElementChange('applyToCategories', e.value)} filter={true}/>
                                            </div>
                                        </React.Fragment>
                                    );
                                }else if(this.state.condition.applyToType===CONDITION_APPLY_TO_TYPE.specific_products){
                                    return (
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-2">
                                                <label style={{display: "block", marginTop: "2px"}}>{trans('promotion.mgt.choose_dish')}:</label>
                                            </div>
                                            <div className="p-col-12 p-md-10">
                                                <MultiSelect value={this.state.condition.applyToProducts} options={this.state.dish} onChange={(e) => this.onElementChange('applyToProducts', e.value)} filter={true}/>
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                            })()}
                        </div>
                    </Fieldset>
                </div>
                {this.state.condition.applyToType!==CONDITION_APPLY_TO_TYPE.specific_products &&
                    <div className="p-col-12">
                        <Fieldset legend={trans('promotion.mgt.exclude_product')}>
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <MultiSelect value={this.state.condition.excludeProducts} options={this.state.dish} onChange={(e) => this.onElementChange('excludeProducts', e.value)} filter={true}/>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                }
            </div>
        );
    }
}
export default withNamespaces('message') (QuantityCondition)