import React, {Component} from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ProgramServices } from "./ProgramServices";
import { TabView, TabPanel } from "primereact/tabview";
import { Fieldset } from "primereact/fieldset";
import { ParticipantServices } from "../../constituent-management/participant/ParticipantServices";
import { ProgramAssessmentServices } from "./ProgramAssessmentServices";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { showNotification } from '../../core/service/NotificationService';
import { Dropdown } from "primereact/dropdown";
import { TalentServices } from "../../constituent-management/talent/TalentServices";

export class ProgramAssessmentDetails extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            course: {
                parent: {}
            },
            attendanceTotal: 0,
            assessment: {},
            participants: [],
            assessmentParticipants: [],
            talents: [],
            participantAllLevels: [],
            participantsLevelsToAllSkills: {},
            attendanceToAll: false
        }
        this.programServices = new ProgramServices();
        this.assessmentServices = new ProgramAssessmentServices();
        this.participantServices = new ParticipantServices();
        this.talentServices = new TalentServices();
    }

    componentDidMount(){
        this.getProgram();
        this.getAssessmentParticipants();
        this.getListParticipants();
        this.getListSkillOfCourse();
    }

    handleSaveAssessmentParticipant = () => {
        this.assessmentServices.saveAssessmentParticipant(this.props.match.params.pid, this.props.match.params.cid, this.state.assessment)
        .then(res => {
            if(res.status.code === 200){
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', res.status.message);
            }
        })
    }

    getProgram = () => {
        this.programServices.getProgram(this.props.match.params.cid, 'course')
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    course: res
                });
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    getAssessmentParticipants = () => {
        this.assessmentServices.getAssessment(this.props.match.params.pid, this.props.match.params.cid, this.props.match.params.aid)
        .then(res => {
            if(res.status.code===200){
                let attendances = {};
                let temParticipantsLevels = {};
                let temParticipantsLevelsSkills = {};
                let temPartsLevelsAllSkills = {};

                if(res.programAssessmentParticipants){
                    res.programAssessmentParticipants.map((item) =>{
                        attendances[item.participant] = item.attended;
                        temParticipantsLevels[item.participant] = {...item.levels};
                        temParticipantsLevelsSkills[item.participant] = {...item.skills};

                        temPartsLevelsAllSkills = {...temPartsLevelsAllSkills,
                            [item.participant]: {}
                        }
                        Object.entries(item.skills).map(([talentKey, itemTalent]) => {
                            temPartsLevelsAllSkills[item.participant] = {
                                ...temPartsLevelsAllSkills[item.participant], 
                                [talentKey]: {} 
                            };

                            Object.entries(itemTalent).map(([levelKey, itemLevel]) => {
                                let allSkillsToChecked = true;
                                Object.entries(itemLevel).map(([skillKey, itemValue]) => {
                                    if(!itemValue){
                                        allSkillsToChecked = false;
                                        return;
                                    }
                                })
                                temPartsLevelsAllSkills[item.participant][talentKey] = {
                                    ...temPartsLevelsAllSkills[item.participant][talentKey],
                                    [levelKey]: allSkillsToChecked
                                }
                            })
                        })
                    })
                }
                this.setState({
                    assessment: {
                        ...res.programAssessment,
                        attendances: attendances,
                        participantsLevels: temParticipantsLevels,
                        participantsLevelsSkills: temParticipantsLevelsSkills
                    },
                    participantsLevelsToAllSkills: temPartsLevelsAllSkills
                });
            }else{
                showNotification('error', 'Error Message', res.status.message);
            }
        });
    }

    getListParticipants = () => {
        this.participantServices.getListParticipantByProgramCourse({childRefId: this.props.match.params.cid})
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    participants: res,
                    attendanceTotal: res.length
                });
            }else{
                showNotification('error', 'Error Message', res.status.message);
            }
        })
    }

    getListSkillOfCourse = () => {
        this.programServices.getTalentsLevelsOfCourse(this.props.match.params.pid, this.props.match.params.cid)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    talents: res
                });
            }else{
                showNotification('error', 'Error Message', res.status.message);
            }
        })
    }

    onCheckAttende = (e) =>{
        this.setState({
            assessment: {
                ...this.state.assessment,
                attendances: {
                    ...this.state.assessment.attendances,
                    [e.value] : e.checked
                }
            }
        })
    }

    onChangeSelectSkill = (id, key, value) => {
        this.setState({
            assessment: {
                ...this.state.assessment,
                participantsLevels: {
                    ...this.state.assessment.participantsLevels,
                    [id] : {
                        ...this.state.assessment.participantsLevels[id],
                        [key] : {
                            ...this.state.assessment.participantsLevels[id][key],
                            current: '',
                            promote: value
                        }
                    }
                }
            }
        })
    }

    onChangeSelectLevelToAllLevel = (e, key) => {
        let tempParts = {...this.state.assessment.participantsLevels};
        let tempPartAllLevels = {...this.state.participantAllLevels};

        Object.entries(tempParts).map(([itemKeyPart, itemValuePart]) => {
            Object.keys(itemValuePart).map((itemKey) => {
                if(itemKey === key){
                    tempParts[itemKeyPart][itemKey] = {
                        ...tempParts[itemKeyPart][itemKey],
                        promote: e.value
                    }
                    tempPartAllLevels[itemKey] = e.value;
                }
            });
        });
        this.setState({
            assessment:{
                ...this.state.assessment,
                participantsLevels: tempParts
            },
            participantAllLevels: tempPartAllLevels
        })
    }

    onCheckAttendanceToAll = (e) => {
        let termAttendaces = {...this.state.assessment.attendances};

        Object.keys(termAttendaces).map((key) => {
            termAttendaces[key] = e.checked
        });
        this.setState({
            assessment:{
                ...this.state.assessment,
                attendances: termAttendaces
            },
            attendanceToAll: e.checked
        })
    }

    onCheckSkill = (id, talent, level, skill, e) => {
        let temParticipantsLevelsToAllSkills = !this.state.participantsLevelsToAllSkills[id][talent] ? 
            {   ...this.state.participantsLevelsToAllSkills,
                [id]: { [talent]: {} }
            }
            : {...this.state.participantsLevelsToAllSkills};

        if(!e.checked){ //remove to check all skill of level
            temParticipantsLevelsToAllSkills = { ...temParticipantsLevelsToAllSkills,
                [id]: { ...temParticipantsLevelsToAllSkills[id],
                    [talent]: { ...temParticipantsLevelsToAllSkills[id][talent],
                        [level]: e.checked
                    }
                }
            }
        }

        this.setState({
            assessment: { ...this.state.assessment,
                participantsLevelsSkills: { ...this.state.assessment.participantsLevelsSkills,
                    [id]: { ...this.state.assessment.participantsLevelsSkills[id],
                        [talent]: { ...this.state.assessment.participantsLevelsSkills[id][talent],
                            [level]: { ...this.state.assessment.participantsLevelsSkills[id][talent][level],
                                [skill]: e.checked
                            }
                        }
                    }
                }
            },
            participantsLevelsToAllSkills: temParticipantsLevelsToAllSkills
        },() => {
            if(e.checked){ // checked all check also check level
                let temPartAssessment = {...this.state.assessment.participantsLevelsSkills}
                let tempPartLevelsAllSkills = {...this.state.participantsLevelsToAllSkills};
                this.state.talents.map((itemTalent, idx) => {
                    if(itemTalent.key === talent){
                        itemTalent.values.map(itemLevel => {
                            if(itemLevel.value === level){
                                let allSkillsToChecked = true;
                                if(Object.keys(temPartAssessment[id][talent][level]).length === itemLevel.skills.length){
                                    Object.entries(temPartAssessment[id][talent][level]).map(([itemKey, itemValue]) => {
                                        if(!itemValue){
                                            allSkillsToChecked = false;
                                        }
                                    })
                                }else{
                                    allSkillsToChecked = false;
                                }
                                if(allSkillsToChecked){
                                    tempPartLevelsAllSkills[id][talent] = { 
                                        ...tempPartLevelsAllSkills[id][talent],
                                        [level]: true
                                    }
                                }
                            }
                        })
                    }
                })
                this.setState({
                    participantsLevelsToAllSkills: tempPartLevelsAllSkills
                })
            }
        })
    }

    onCheckLevelToAllSkill = (id, talent, level, e) => {
        let temParticipantsLevelsSkills = {...this.state.assessment.participantsLevelsSkills[id][talent][level]};

        Object.entries(temParticipantsLevelsSkills).map(([keyLevel, valueLevel]) => {
            temParticipantsLevelsSkills[keyLevel] = e.checked;
        })
        
        let temParticipantsLevelsToAllSkills = !this.state.participantsLevelsToAllSkills[id] ? 
            {   ...this.state.participantsLevelsToAllSkills,
                [id]: { [talent]: {} }
            }
            : {...this.state.participantsLevelsToAllSkills};
        
        temParticipantsLevelsToAllSkills = { ...temParticipantsLevelsToAllSkills,
            [id]: { ...temParticipantsLevelsToAllSkills[id],
                [talent]: { ...temParticipantsLevelsToAllSkills[id][talent],
                    [level]: e.checked
                }
            }
        }
        this.setState({
            assessment: { ...this.state.assessment,
                participantsLevelsSkills: { ...this.state.assessment.participantsLevelsSkills,
                    [id]: { ...this.state.assessment.participantsLevelsSkills[id],
                        [talent]: { ...this.state.assessment.participantsLevelsSkills[id][talent],
                            [level]: temParticipantsLevelsSkills
                        }
                    }
                }
            },
            participantsLevelsToAllSkills: temParticipantsLevelsToAllSkills
        })
    }

    render(){
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Course: {this.state.course.code + ' - ' + this.state.course.name}
                            <div className="p-toolbar-group-right">
                                <Link to={`/programs/${this.state.course.parent.id}/courses/${this.state.course.id}/manage/assessment`} className="p-button p-component p-button-primary p-button-icon-only">
                                    <span className="pi pi-bars p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">Location: {this.state.course.locationName}</div>
                            <div className="p-col-12 p-md-4">Total attendees: {this.state.attendanceTotal}</div>
                            <div className="p-col-12 p-md-4">Active: {this.state.course.active?'Yes':'No'}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <TabView>
                        <TabPanel header="Information">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Fieldset legend="Basic Information">
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12 p-md-6">
                                                        <label >Name: {this.state.assessment.name}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label >Date: {this.state.assessment.date ? moment(this.state.assessment.date).format("MM-DD-YYYY") : ''}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label >Type: {this.state.assessment.type}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div className="p-col-12">
                                    <Fieldset legend="Participants">
                                        <table cellPadding={0} cellSpacing={0} style={{width: "100%", border: "none"}} >
                                            <thead>
                                                <tr>
                                                    <td className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}} ><strong>Name</strong></td>
                                                    <td className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}} ><strong>Day of bith</strong></td>
                                                    <td className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}} ><strong>Age</strong></td>
                                                    {this.state.assessment.type !== 'attendance' ?
                                                        this.state.talents.map((item, index) =>{
                                                            return(
                                                                <td key={index} className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}}>
                                                                    <strong>{item.name ? item.name : item.key}</strong> &nbsp;&nbsp;
                                                                    {this.state.assessment.type === 'level' ?
                                                                        <Dropdown value={this.state.participantAllLevels[item.key] ? this.state.participantAllLevels[item.key] : ''} options={item.values} onChange={(e) => this.onChangeSelectLevelToAllLevel(e, item.key)} style={{minWidth: "auto", maxWidth: "100%"}} />
                                                                        : ''
                                                                    }
                                                                </td>                                                
                                                            )})
                                                        : <td className="p-padding-5 p-c" style={{border: "1px solid #e6e6e6"}}>
                                                            <Checkbox onChange={(e) => this.onCheckAttendanceToAll(e)} checked={this.state.attendanceToAll} />
                                                        </td>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.participants.map((part, idx) => {
                                                    let maxAgePart = part.ageInMonth;
                                                    let colorBorder = '1px solid #e6e6e6';
                                                    let maxAge;
                                                    let talents = this.state.talents;
                                                    talents.map((talent, index) =>{
                                                        let temMaxAge = talent.maxAgeYear * 12 + talent.maxAgeMonth
                                                        if(!maxAge || maxAge > temMaxAge){ 
                                                            maxAge = temMaxAge;
                                                        }
                                                    });
                                                    if(this.state.assessment.type !== 'attendance' && maxAgePart > maxAge){
                                                        colorBorder = '1px solid #ce0b0b';
                                                    }else{
                                                        colorBorder = '1px solid #e6e6e6';
                                                    }
                                                    return (
                                                        <tr key={idx}>
                                                            <td className="p-padding-all-5" style={{border: colorBorder, borderRight: 'none'}} >{part.name}</td>
                                                            <td className="p-padding-all-5" style={{border: colorBorder}} >{moment(part.birthday).format('MM-DD-YYYY')}</td>
                                                            <td className="p-padding-all-5" style={{border: colorBorder}} >{part.ageString}</td>
                                                            {this.state.assessment.type === 'attendance' ?
                                                                <td className="p-padding-all-5 p-c" style={{width: "3em", border: colorBorder}}>
                                                                    <Checkbox value={part.id} onChange={(e) => this.onCheckAttende(e)} checked={this.state.assessment.attendances[part.id]} />
                                                                </td>
                                                                : ''
                                                            }
                                                            {this.state.assessment.type === 'level' ?
                                                                this.state.talents.map((level, index) =>{
                                                                    return(
                                                                        <td key={index} className="p-padding-all-5"style={{border: colorBorder}} >
                                                                            <div className="p-grid">
                                                                                <div className="p-col-6">
                                                                                    <div className="p-padding-5-0">Current</div>
                                                                                    <div>Not yet</div>
                                                                                </div>
                                                                                <div className="p-col-6">
                                                                                    <div className="p-padding-5-0">Promote</div>
                                                                                    <Dropdown options={level.values} onChange={(e) => this.onChangeSelectSkill(part.id, level.key, e.value)} style={{minWidth: "auto", maxWidth: "100%"}} 
                                                                                        value={this.state.assessment.participantsLevels[part.id][level.key]  ? this.state.assessment.participantsLevels[part.id][level.key].promote : ''}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    );
                                                                })
                                                                : ''
                                                            }
                                                            {this.state.assessment.type === 'skill' ?
                                                                this.state.talents.map((talent, index) =>{
                                                                        return(
                                                                            <td key={index} className="p-padding-0-10"style={{border: colorBorder, verticalAlign: "initial"}} >
                                                                                <ul className="p-padding-none" style={{listStyle: "none"}}>
                                                                                    {talent.values ? 
                                                                                        talent.values.map((level, idxLevel) => {
                                                                                            return(
                                                                                                <li key={idxLevel} className="p-margin-bottom-15" style={{borderBottom: "1px solid #e6e6e6"}}> 
                                                                                                    <Checkbox onChange={(e) => this.onCheckLevelToAllSkill(part.id, talent.key, level.value, e)} 
                                                                                                        checked={this.state.participantsLevelsToAllSkills[part.id] && this.state.participantsLevelsToAllSkills[part.id][talent.key] && this.state.participantsLevelsToAllSkills[part.id][talent.key] ? 
                                                                                                            this.state.participantsLevelsToAllSkills[part.id][talent.key][level.value] : false} 
                                                                                                    />
                                                                                                    {level.label ? level.label : level.value}
                                                                                                    <ul className="p-padding-left-10 p-margin-top-10" style={{listStyle: "none"}}> 
                                                                                                        {level.skills ? 
                                                                                                            level.skills.map((skill, idxSkill) => {
                                                                                                                return(
                                                                                                                    <li key={idxSkill} className="p-margin-bottom-5">
                                                                                                                        <Checkbox onChange={(e) => this.onCheckSkill(part.id, talent.key, level.value, skill.value, e)} 
                                                                                                                            checked={this.state.assessment.participantsLevelsSkills[part.id] && this.state.assessment.participantsLevelsSkills[part.id][talent.key] && this.state.assessment.participantsLevelsSkills[part.id][talent.key][level.value] ? 
                                                                                                                                this.state.assessment.participantsLevelsSkills[part.id][talent.key][level.value][skill.value] : false} 
                                                                                                                        />
                                                                                                                        {skill.label ? skill.label : skill.value}
                                                                                                                    </li>
                                                                                                                );
                                                                                                            })
                                                                                                            : ''
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    : ''}
                                                                                </ul>
                                                                            </td>
                                                                        );
                                                                    })
                                                                : ''
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="p-margin-top-10">
                                            <Button label="Save Participants" icon="pi pi-save" iconPos="left" style={{'float':'right'}} onClick={(e) => this.handleSaveAssessmentParticipant()}/>
                                        </div>
                                    </Fieldset>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}