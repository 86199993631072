import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Link, useRouteMatch } from 'react-router-dom';
import {TabView,TabPanel} from 'primereact/tabview';
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { titleCaseText } from "../../core/service/CommonService";
import { Spinner } from "primereact/spinner";
import { InputSwitch } from "primereact/inputswitch";
import { showNotification } from "../../core/service/NotificationService";
import { getListTaxons } from "../taxon/TaxonServices";
import { getListInventories } from "../inventory/InventoryService";
import { APP_FEP, ASSET_TYPE, ATTRIBUTE_FIELD_SET_TYPE, FIELD_DIRECTION_TYPE, FIELD_DISPLAY_TYPE, FIELD_EXPRESSION_TYPE, FIELD_TYPE, ASSET_SCHEDULE_STATUSES, ASSET_STATUSES, TABLE_PAGING_LENGTH, TAXONOMY_TYPE } from "../../constants";
import { getAsset, getCustomFields, getPageAssetDamagedTracking, saveAsset } from "./AssetServices";
import { AssetSchedules } from "../asset-schedule/AssetSchedules";
import { InputMask } from "primereact/inputmask";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getPageInvoices } from "../invoice/InvoiceServices";

export const AssetDetails = (props) => {
    const match = useRouteMatch();

    const [asset, setAsset] = useState({});
    const [tmpAsset, setTmpAsset] = useState({});
    const [branchs, setBranchs] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [errors, setErrors] = useState({});
    const [scheduleFilter, setScheduleFilter] = useState({});
    const [damagedTracking, setDamagedTracking] = useState({
        filter: {assetId: props.match.params.id},
        dataList: [],
        pageable: {
            page: 0,
            rows: 10,
            total: 0,
            sortField: 'createdAt',
            sortOrder: -1
        }
    })
    const [isLoadedDamageTrackingTable, setLoadedDamageTrackingTable] = useState(true)
    const [additionalFee, setAdditionalFee] = useState({
        filter: {assetId: props.match.params.id},
        dataList: [],
        pageable: {
            page: 0,
            rows: 10,
            total: 0,
            sortField: 'paymentCompletedAt',
            sortOrder: -1
        }
    })
    const [isLoadedAdditionalFeeTable, setLoadedAdditionalFeeTable] = useState(true)
    const currency = '$'

    useEffect(() =>{
        loadAsset();
        loadBranches();
        loadInventories();
        loadCategories();
        loadAssetDamagedTrackingPage()
    },[])

    useEffect(() => {
        if(isLoadedDamageTrackingTable){
            loadAssetDamagedTrackingPage()
        }
    },[isLoadedDamageTrackingTable])

    useEffect(() => {
        if(isLoadedAdditionalFeeTable){
            loadAssetAdditionalFee()
        }
    },[isLoadedAdditionalFeeTable])

    const loadAsset = () => {
        getAsset(props.match.params.id)
        .then(res => {
            if(!res.errorCode){
                setAsset(res);
                popularForm(res);
                if(res && res.category){
                    loadCustomFields(res.category.id, res.id);
                }
                setScheduleFilter({
                    assetId: res ? res.id : null
                })
            }else{
                showNotification('error', 'Error Message', res.errorMessage)
            }
        });
    }

    const loadBranches = () => {
    getListTaxons({type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res));
    }

    const loadInventories = () => {
        getListInventories({app: APP_FEP}, true).then(res => setInventories(res))
    }

    const loadCategories = () => {
        getListTaxons({type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value]}, true)
        .then(res => setCategories(res))
    }

    const loadCustomFields = (categoryId, assetId) => {
        if(!categoryId){
            setFieldValues([])
            setCustomFields([])
        }else{
            getCustomFields(categoryId, assetId)
            .then(res => {
                let tmpCustomFields = res.customFields?res.customFields:[]
                let mapFieldValues = res.mapFieldValues?res.mapFieldValues:{}
                let tmpFieldValues = [];

                tmpCustomFields.map(el => {
                    let itemValue = mapFieldValues[el.field.id];
                    let partAnswerObj = itemValue&&itemValue.storeType==='boolean'?JSON.parse(itemValue.value):{}
                    let item = {
                        id: itemValue?itemValue.id:'',
                        setType: ATTRIBUTE_FIELD_SET_TYPE.custom_field,
                        fieldId: el.field.id,
                        fieldName: el.field.name,
                        storeType: el.field.config.storeType,
                        value: itemValue?itemValue.value:'',
                        valueArrays: itemValue && itemValue.valueArrays?itemValue.valueArrays:[],
                        phoneValue: itemValue && itemValue.phoneObj ? (itemValue.phoneObj.countryCode!=='US' ? ('+' + itemValue.phoneObj.dialCode) : '') + itemValue.phoneObj.phoneNumber: '',
                        phoneCodeValue: itemValue && itemValue.phoneObj ? itemValue.phoneObj.countryCode.toLowerCase() : '',
                        dateValue: el.field.type===FIELD_TYPE.date && itemValue && itemValue.value?moment(itemValue.value, 'YYYY-MM-DD').toDate():'',
                        flagAnswer: itemValue?(partAnswerObj.accepted==='yes'?true:false):(el.defaultValue==='true'?true:false),
                    }

                    tmpFieldValues.push(item);
                });

                setFieldValues(tmpFieldValues);
                setCustomFields(tmpCustomFields);
            })
        }
    }

    const popularForm = (assetData) => {
        setTmpAsset({
            id:assetData.id,
            type: assetData ? assetData.type : null,
            branchId: assetData&&assetData.branch ? assetData.branch.id : null,
            categoryId: assetData&&assetData.category ? assetData.category.id : null,
            inventoryId: assetData&&assetData.inventory ? assetData.inventory.id : null,
            code: assetData ? assetData.code : '',
            name: assetData ? assetData.name : '',
            weight: assetData ? assetData.weight : 0,
            active: assetData ? assetData.active : true,
        })
    }

    const handleSave = () => {
        setErrors({});
        let assetData = {...tmpAsset};
        assetData.fieldValues = [...fieldValues]
        saveAsset(assetData)
        .then(res => {
            if (!res.errorCode) {
                setAsset(res);
                showNotification('success', 'Success Message', 'Action submitted');
            } else {
                if (res.errorCode === 400)  setErrors(res.errorObj);
                showNotification('error', 'Error Message', 'Cannot perform action');
            } 
        });
    }

    const onBranchChange = (e) => {
        setTmpAsset({...tmpAsset,
            branchId: e.value
        })
    }

    const onInventoryChange = (e) => {
        setTmpAsset({...tmpAsset,
            inventoryId: e.value
        })
    }

    const onCategoryChange = (e) => {
        setTmpAsset({...tmpAsset,
            categoryId: e.value
        })
        loadCustomFields(e.value, asset.id)
    }

    const onFieldChange = (idx, value, fieldId, type, displayType, storeType) => {
        let tmpFieldValues = [...fieldValues];

        switch(type){
            case FIELD_TYPE.phone:
                tmpFieldValues[idx].value = value.phoneNumber? value.countryCode + "|" + value.dialCode + "|" + value.phoneNumber: '';
                tmpFieldValues[idx].phoneValue = value.phoneNumber;
                break;
            case FIELD_TYPE.date:
                tmpFieldValues[idx].value = value?moment(value).format(moment.HTML5_FMT.DATE):'';
                tmpFieldValues[idx].dateValue = value;
                break;
            case FIELD_TYPE.select:
                switch(displayType){
                    case FIELD_DISPLAY_TYPE.dropdown:
                        if(storeType!=='array'){
                            tmpFieldValues[idx].value = value;
                        }else{
                            tmpFieldValues[idx].valueArrays = value;
                        }
                        break;
                    case FIELD_DISPLAY_TYPE.checkbox:
                            let selectedValues = [...tmpFieldValues[idx].valueArrays];
        
                            if(value.checked)
                                selectedValues.push(value.value);
                            else
                                selectedValues.splice(selectedValues.indexOf(value.value), 1);
                    
                            tmpFieldValues[idx].valueArrays = selectedValues;
                        break;
                    case FIELD_DISPLAY_TYPE.radio:
                        tmpFieldValues[idx].value = value;
                        break;
                    default:
                        break;
                }
                break;
            case FIELD_TYPE.boolean:
                tmpFieldValues[idx].value = value+'';
                tmpFieldValues[idx].flagAnswer = value;
                break;
            case FIELD_TYPE.waiver:
                if(displayType===FIELD_DISPLAY_TYPE.checkbox){
                    tmpFieldValues[idx].flagAnswer = value;
                    tmpFieldValues[idx].value = '';
                }else if(displayType===FIELD_DISPLAY_TYPE.textbox){
                    tmpFieldValues[idx].value = value;
                }
                else if(displayType===FIELD_DISPLAY_TYPE.file){
                    tmpFieldValues[idx].fieldFile = {[fieldId]: value};
                }
                break;
            default:
                tmpFieldValues[idx].value = value;
                break;
        }

        setFieldValues(tmpFieldValues);
    }

    const buildPhoneObj = (status, value, countryData) => {
        let phoneValue = '';
        if(value !== ''){
            if(value.indexOf(countryData.dialCode) !== -1){
                phoneValue = value.substr((countryData.dialCode).length);
            }else{
                phoneValue = value;
            }
        }
        let phoneObj = {
                phoneNumber: phoneValue,
                dialCode: countryData.dialCode,
                countryCode: countryData.iso2.toUpperCase(),
                phoneValid: status
        }
        return phoneObj;
    }

    const onPhoneChange = (status, value, countryData, idx) => {
        let phoneObj = buildPhoneObj(status, value, countryData);

        onFieldChange(idx, phoneObj, null, FIELD_TYPE.phone, '');
    }

    const clearScheduleFilter = () => {
        setScheduleFilter({
            assetId: asset ? asset.id :''
        })
    }

    //#region Damaged Tracking
    const loadAssetDamagedTrackingPage = () => {
        getPageAssetDamagedTracking(damagedTracking.filter, damagedTracking.pageable.page, damagedTracking.pageable.rows, damagedTracking.pageable.sortField, damagedTracking.pageable.sortOrder)
        .then(res => {
            setDamagedTracking({...damagedTracking,
                dataList: res.content,
                pageable: {...damagedTracking.pageable,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                }
            })
            setLoadedDamageTrackingTable(false)
        })
    };

    const onPageAssetDamagedTracking = (e) =>{
        setDamagedTracking({...damagedTracking,
            pageable: {...damagedTracking.pageable,
                page: e.page
            }
        })
        setLoadedDamageTrackingTable(true)
    }

    const onSortAssetDamagedTracking = (e) =>{
        setDamagedTracking({...damagedTracking,
            pageable: {...damagedTracking.pageable,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            }
        })
        setLoadedDamageTrackingTable(true)
    }

    const onChangePageLengthDamagedTracking = (e) => {
        setDamagedTracking({...damagedTracking,
            pageable: {...damagedTracking.pageable,
                rows: e.value
            }
        })
        setLoadedDamageTrackingTable(true)
    };

    const tblHeaderDamgedTracking = (
        <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
            <div style={{ 'float': 'right' }}>
                Show: <Dropdown className="p-datatable-length-selector" value={damagedTracking.pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChangePageLengthDamagedTracking(e)} />
                <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => loadAssetDamagedTrackingPage()}/>
            </div>
            {/* <h3 className="fle-datatable-header-title">Damaged Tracking Listing</h3> */}
        </div>
    )
    //#endregion

    //#region Additional Fee
    const loadAssetAdditionalFee = () => {
        getPageInvoices(additionalFee.filter, additionalFee.pageable.page, additionalFee.pageable.rows, additionalFee.pageable.sortField, additionalFee.pageable.sortOrder)
        .then(res => {
            setAdditionalFee({...additionalFee,
                dataList: res.content,
                pageable: {...additionalFee.pageable,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                }
            })
            setLoadedAdditionalFeeTable(false)
        })
    };

    const onPageAssetAdditionalFee = (e) =>{
        setAdditionalFee({...additionalFee,
            pageable: {...additionalFee.pageable,
                page: e.page
            }
        })
        setLoadedAdditionalFeeTable(true)
    }

    const onSortAssetAdditionalFee = (e) =>{
        setAdditionalFee({...additionalFee,
            pageable: {...additionalFee.pageable,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            }
        })
        setLoadedAdditionalFeeTable(true)
    }

    const onChangePageLengthAdditionalFee = (e) => {
        setLoadedAdditionalFeeTable({...additionalFee,
            pageable: {...additionalFee.pageable,
                rows: e.value
            }
        })
        setLoadedDamageTrackingTable(true)
    };

    const tblHeaderAdditionalFee = (
        <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
            <div style={{ 'float': 'right' }}>
                Show: <Dropdown className="p-datatable-length-selector" value={additionalFee.pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChangePageLengthAdditionalFee(e)} />
                <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => loadAssetAdditionalFee()}/>
            </div>
            {/* <h3 className="fle-datatable-header-title">Damaged Tracking Listing</h3> */}
        </div>
    )
    //#endregion

    return (
    <div className="p-grid">
        <BreadcrumbsItem to={`/assets-mgt`}>Assets</BreadcrumbsItem>
        <BreadcrumbsItem to={match.url}>{asset.name}</BreadcrumbsItem>
        
        <div className="p-col-12">
            <div className="card card-w-title">
                <h1>
                    {asset.name}
                    <div className="p-toolbar-group-right">
                        <Link to={`/assets-mgt`}>
                        <Button icon="pi pi-list" className="p-button-icon" tooltip="Back To List" tooltipOptions={{position: 'top'}}></Button>
                        </Link>
                    </div>
                </h1>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-3">Code: {asset.code}</div>
                </div>
                <div className="p-grid p-fluid">
                {asset.branch &&
                    <div className="p-col-12 p-md-3">Branch: {asset.branch.name}</div>
                }
                {asset.inventory &&
                    <div className="p-col-12 p-md-3">Inventory: {asset.inventory.name}</div>
                }
                {asset.category &&
                    <div className="p-col-12 p-md-3">Category: {asset.category.name}</div>
                }
                </div>
            </div>
        </div>
        <div className="p-col-12">
            <TabView >
                <TabPanel header='Information' leftIcon='pi pi-info' >
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Fieldset legend="Basic Information">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <label className="p-label">* Type</label>
                                        <Dropdown options={Object.values(ASSET_TYPE)} value={tmpAsset.type} onChange={(e) => setTmpAsset({...tmpAsset, type: e.value})} />
                                        <div className="p-form-error">{errors.type}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Branch</label>
                                        <Dropdown options={branchs} value={tmpAsset.branchId} onChange={(e) => onBranchChange(e)} placeholder="Select a branch"/>
                                        <div className="p-form-error">{errors.branchId}</div>
                                    </div> 
                                    <div className="p-col-12">
                                        <label className="p-label">* Inventory</label>
                                        <Dropdown options={inventories} value={tmpAsset.inventoryId} onChange={(e) => onInventoryChange(e)} placeholder="Select a inventory"/>
                                        <div className="p-form-error">{errors.inventoryId}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Category</label>
                                        <Dropdown options={categories} value={tmpAsset.categoryId} onChange={(e) => onCategoryChange(e)} placeholder="Select a Category"/>
                                        <div className="p-form-error">{errors.categoryId}</div>
                                    </div> 
                                    <div className="p-col-12">
                                        <label className="p-label">* Code</label>
                                        <InputText value={tmpAsset.code} keyfilter={/[^\s]/} onChange={(e) => setTmpAsset({ ...tmpAsset, code: e.target.value })} />
                                        <div className="p-form-error">{errors.code}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Name</label>
                                        <InputText value={tmpAsset.name} onChange={(e) => setTmpAsset({ ...tmpAsset, name: titleCaseText(e.target.value) })} />
                                        <div className="p-form-error">{errors.name}</div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Weight (for order)</label>
                                        <InputText value={tmpAsset.weight} keyfilter="int" onChange={(e) => setTmpAsset({ ...tmpAsset, weight: e.target.value })} />
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">Active</label>
                                        <InputSwitch checked={tmpAsset.active} onChange={(e) => setTmpAsset({ ...tmpAsset, active: e.value })} />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        {(customFields && customFields.length > 0) &&
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Additional">
                                    { customFields.map((el, idx) => {
                                        let field = el.field;
                                        let config = field.config;

                                        if(field.type!==FIELD_TYPE.boolean){
                                            return <div key={idx} className="p-grid p-fluid p-margin-bottom-10">
                                                <div className="p-col-12">
                                                    <label>{el.required?'* ':''}{field.label}</label>
                                                </div>
                                                <div className="p-col-12">
                                                    {(() => {
                                                        switch(field.type){
                                                            case FIELD_TYPE.text:
                                                                if(config.expressionValidationType === FIELD_EXPRESSION_TYPE.maskString){
                                                                    return <InputMask value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} mask={config.expressionValidationInput}></InputMask>;
                                                                }else{
                                                                    return <InputText value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} minLength={config.minLength?config.minLength:''} maxLength={config.maxLength?config.maxLength:''}/>;
                                                                }
                                                            case FIELD_TYPE.email:
                                                                return <InputText value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} keyfilter="email"/>;
                                                            case FIELD_TYPE.number:
                                                                return <InputText value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} keyfilter="int"/>;
                                                            case FIELD_TYPE.phone:
                                                                return <IntlTelInput value={fieldValues[idx]?fieldValues[idx].phoneValue:''} onPhoneNumberChange={(s,v,c,n) => onPhoneChange(s,v,c,idx)} onSelectFlag={(v,c,n,s) => onPhoneChange(s,v,c,idx)} autoPlaceholder={false} format={true} defaultCountry={config.defaultCountry.toLowerCase()} css={['intl-tel-input', 'p-inputtext  p-component']} style={{width: "100%"}}/>
                                                            case FIELD_TYPE.select:
                                                                let valueList = config.useCustomList?config.customList.values:config.dataList;

                                                                if(config.displayType===FIELD_DISPLAY_TYPE.dropdown){
                                                                    let dataList = [];

                                                                    valueList.map(el => {
                                                                        dataList.push({value: el, label: el})
                                                                    });

                                                                    if(config.storeType!=='array'){
                                                                        if(config.useNoneLabel){
                                                                            dataList.unshift({value: '', label: config.noneLabel});
                                                                        }
                                                                        
                                                                        return <Dropdown value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} options={dataList} style={{width: '100%'}}/>
                                                                    }else{
                                                                        return <MultiSelect value={fieldValues[idx]?fieldValues[idx].valueArrays:[]} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} options={dataList} style={{width: '100%'}}/>
                                                                    }
                                                                }else{
                                                                    return (
                                                                        <div className="p-grid">
                                                                            {config.directionType === FIELD_DIRECTION_TYPE.horizontal &&
                                                                                <React.Fragment>
                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                        <div className="p-col-12">
                                                                                            <RadioButton inputId={"rb_"+field.id+"_none"} value="" onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===''}></RadioButton>
                                                                                            <label htmlFor={"rb_"+field.id+"_none"} className="p-checkbox-label p-margin-left-5">{config.noneLabel}</label>
                                                                                        </div>
                                                                                    }
                                                                                    {valueList.map((v, i) => {
                                                                                            return (
                                                                                                <div key={i} className="p-col-12">
                                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                        <React.Fragment>
                                                                                                            <Checkbox inputId={"cb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e , field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].valueArrays.includes(v)}></Checkbox>
                                                                                                            <label htmlFor={"cb_"+field.id+"_"+i} className="p-checkbox-label p-margin-left-5">{v}</label>
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                        <React.Fragment>
                                                                                                            <RadioButton inputId={"cb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===v}></RadioButton>
                                                                                                            <label htmlFor={"cb_"+field.id+"_"+i} className="p-checkbox-label p-margin-left-5">{v}</label>
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })    
                                                                                    }
                                                                                </React.Fragment>
                                                                            }
                                                                            {config.directionType === FIELD_DIRECTION_TYPE.vertical &&
                                                                                <div className="p-col-12">
                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                        <React.Fragment>
                                                                                            <RadioButton inputId={"rb_"+field.id+"_none"} value="" onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===''}></RadioButton>
                                                                                            <label htmlFor={"rb_"+field.id+"_none"} className="p-checkbox-label p-margin-right-20">{config.noneLabel}</label>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    {valueList.map((v, i) => {
                                                                                        return (
                                                                                            <React.Fragment key={i}>
                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                    <React.Fragment>
                                                                                                        <Checkbox inputId={"cb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e , field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].valueArrays.includes(v)}></Checkbox>
                                                                                                        <label htmlFor={"cb_"+field.id+"_"+i} className="p-checkbox-label p-margin-right-20">{v}</label>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                    <React.Fragment>
                                                                                                        <RadioButton inputId={"rb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===v}></RadioButton>
                                                                                                        <label htmlFor={"rb_"+field.id+"_"+i} className="p-checkbox-label p-margin-right-20">{v}</label>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })}        
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            case FIELD_TYPE.textarea:
                                                                return <InputTextarea value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} rows={config.rows} autoResize={true}/>;
                                                            case FIELD_TYPE.date:
                                                                return <Calendar value={fieldValues[idx]?fieldValues[idx].dateValue:''} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type)} showIcon={true} dateFormat="mm-dd-yy" mask="99-99-9999" />
                                                            case FIELD_TYPE.file:
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    })()}
                                                    <div className="p-form-error">{errors["fieldValues_"+field.id]}</div>
                                                </div>
                                            </div>
                                        }else{
                                            return <div key={idx} className="p-grid p-fluid">
                                                <div className="p-col-12 "></div>
                                                <div className="p-col-12 ">
                                                    {(() => {
                                                        if(config.displayType===FIELD_DISPLAY_TYPE.radio){
                                                            return (
                                                                <React.Fragment>
                                                                    <label className="p-label p-margin-botton-5">{field.label}</label>
                                                                    <div className="p-grid p-margin-top-0">
                                                                        <div className="p-col-12">
                                                                            <RadioButton inputId={"rb_0_"+field.id} value={true} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type)} checked={fieldValues[idx]&&fieldValues[idx].flagAnswer}/>
                                                                            <label htmlFor={"rb_0_"+field.id} className="p-checkbox-label p-margin-right-10">Yes</label>
                                                                            <RadioButton inputId={"rb_1_"+field.id} value={false} onChange={(e) => onFieldChange(idx, e.value, field.id, field.value)} checked={fieldValues[idx]&&!fieldValues[idx].flagAnswer}/>
                                                                            <label htmlFor={"rb_1_"+field.id} className="p-checkbox-label p-margin-left-5">No</label>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        }else{
                                                            return (
                                                                <React.Fragment>
                                                                    <Checkbox inputId={"cb_"+field.id} onChange={(e) => onFieldChange(idx, e.checked, field.id, field.type)} checked={fieldValues[idx]&&fieldValues[idx].flagAnswer}></Checkbox>
                                                                    <label htmlFor={"cb_"+field.id} className="p-checkbox-label p-margin-left-5">{field.label}</label>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    })()}
                                                    {config.content &&
                                                        <div className="p-margin-top-10" style={{fontSize: '13px'}}>{config.content}</div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    })}
                                </Fieldset>
                            </div>
                        }
                        <div className="p-col-12 p-r">
                            <Button label="Save" icon="pi pi-save" onClick={() => handleSave()} />          
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Schedules" leftIcon="pi pi-calendar" >
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset legend="Filters">
                                <div className="p-grid p-fluid">

                                    <div className="p-col-12 p-md-6">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>Equipment Status</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <Dropdown options={ASSET_STATUSES} value={scheduleFilter.assetStatus} onChange={(e) => setScheduleFilter({...scheduleFilter, assetStatus: e.value})} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-md-6">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>Schedule Status</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <Dropdown options={ASSET_SCHEDULE_STATUSES} value={scheduleFilter.status} onChange={(e) => setScheduleFilter({...scheduleFilter, status: e.value})} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="p-grid">
                                    <div className="p-col-12" style={{ textAlign: "center" }}>
                                        {/* <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={applyScheduleFilter} /> */}
                                        <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={clearScheduleFilter} />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12">
                            <AssetSchedules id={props.match.params.id} filter={scheduleFilter}/>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Damage Tracking" >
                    <DataTable  lazy={true} header={tblHeaderDamgedTracking} paginator={true}
                        value={damagedTracking.dataList}
                        first={damagedTracking.pageable.page * damagedTracking.pageable.rows}
                        onPage={onPageAssetDamagedTracking} onSort={onSortAssetDamagedTracking}
                        rows={damagedTracking.pageable.rows} totalRecords={damagedTracking.pageable.total}
                        sortField={damagedTracking.pageable.sortField} sortOrder={damagedTracking.pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" />
                        <Column field="description" header="Description" />
                        <Column field="createdAt" header="Created At" sortable body={rowData => rowData.createdAt && moment(rowData.createdAt).format('MM/DD/YYYY hh:mm A')} />
                    </DataTable>
                </TabPanel>
                <TabPanel header="Additional Charges" >
                    <DataTable  lazy={true} header={tblHeaderAdditionalFee} paginator={true}
                        value={additionalFee.dataList}
                        first={additionalFee.pageable.page * additionalFee.pageable.rows}
                        onPage={onPageAssetAdditionalFee} onSort={onSortAssetAdditionalFee}
                        rows={additionalFee.pageable.rows} totalRecords={additionalFee.pageable.total}
                        sortField={additionalFee.pageable.sortField} sortOrder={additionalFee.pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" />
                        <Column header="Payor" body={rowData => 
                            <React.Fragment>
                                <div className="p-margin-bottom-10">{rowData.payorName}</div>
                                {rowData.payorEmail && <div className="p-margin-bottom-10">[{rowData.payorEmail}]</div>}
                                {rowData.payorPhone && <div>[{rowData.payorPhone}]</div>}
                            </React.Fragment>
                            }
                        />
                        <Column field="paid" header="Paid" body={rowData => currency+rowData.paid} />
                        <Column field="note" header="Description" />
                        <Column field="paymentCompletedAt" header="Completed At" sortable body={rowData => rowData.paymentCompletedAt && moment(rowData.paymentCompletedAt).format('MM/DD/YYYY hh:mm A')} />
                    </DataTable>
                </TabPanel>
            </TabView>
        </div>
    </div>
    );
}
