import axios from "axios";
import qs from 'qs';
import { PIM_BASE_URL, PIM_PATH } from "../../constants";

const PIM_ASSET_URL = PIM_BASE_URL + PIM_PATH + '/assets'

export function getAsset(id) {
  return axios.get(`${PIM_ASSET_URL}/${id}`)
    .then(res => res.data)
    .catch(error => console.log(error));
}

export function getPageAssets(filter, page, size, sortField, sortOrder) {
  const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
  const sort = sortField ? `${sortField},${order}` : '';
  return axios.get(`${PIM_ASSET_URL}/page`, {
    params: { ...filter, page, size, sort }, paramsSerializer: params => qs.stringify(params)
  }).then(res => { return res.data }).catch(error => console.log(error));
}

export function getListAssets(filter, simple=true) {
  return axios.get(`${PIM_ASSET_URL}/list`, {params: {simple, ...filter}, paramsSerializer: params => qs.stringify(params)}
  ).then(res => res.data).catch(error => console.log(error));
}

export function saveAsset(data) {
  return axios.post(`${PIM_ASSET_URL}`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeAsset(id) {
  return axios.delete(`${PIM_ASSET_URL}/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getCustomFields(categoryId, assetId) {
  return axios.get(`${PIM_ASSET_URL}/fields`, {params: {categoryId, assetId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function transferAsset(type, branchId, inventoryId, data) {
  return axios.post(`${PIM_ASSET_URL}/transfer`, data, {params: {type, branchId, inventoryId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function getPageAssetDamagedTracking(filter, page, size, sortField, sortOrder) {
  const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
  const sort = sortField ? `${sortField},${order}` : '';
  return axios.get(`${PIM_ASSET_URL}/damaged-tracking/page`, {
    params: { ...filter, page, size, sort }, paramsSerializer: params => qs.stringify(params)
  }).then(res => { return res.data }).catch(error => console.log(error));
}