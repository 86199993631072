import axios from "axios";
import {API_BASE_URL} from "../../../constants";

export class UserAccessServices{

    getPageUsers(filter,page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/acl/users/page`, {
            params: {...filter, page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getUser(id){
        return axios.get(`${API_BASE_URL}/scp/api/acl/users/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    saveRoleUser(id, data){
        return axios.post(`${API_BASE_URL}/scp/api/acl/users/${id}/roles`, 
            data
        ).then(res => res.data).catch(error => console.log(error))
    }

    getRoles(id){
        return axios.get(`${API_BASE_URL}/scp/api/acl/users/${id}/roles/available`)
            .then(res => res.data).catch(error => console.log(error));
    }
    
    getUsersByPermission(key, searchValue){
        return axios.get(`${API_BASE_URL}/scp/api/acl/permission/users`, {params: {p: key, search: searchValue}})
            .then(res => res.data).catch(error => console.log(error));
    }
}