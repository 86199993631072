import axios from "axios";
import { API_BASE_URL } from '../../constants';
let querystring = require("query-string");

export class MembershipServices{

    getPageMemberships(page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/memberships/page`, {
            params: {page, size, sort}
        }).then(res => {
            return res.data
        }).catch(error => console.log(error));
    }

    getMembership(id){
        return axios.get(`${API_BASE_URL}/scp/api/memberships/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    getUserMemberships(userId){
        return axios.get(`${API_BASE_URL}/scp/api/memberships/get_by_user`, {params: {uId: userId}})
            .then(res => res.data).catch(error => console.log(error));
    }

    updateStatusMembership(id, status){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/${id}/update_status`,  querystring.stringify({status: status}))
        .then(res => res.data).catch(error => console.log(error));
    }

    getMembershipInfoByMember(memberId){
        return axios.get(`${API_BASE_URL}/scp/api/memberships/info/get_by_user`, {params: {uId: memberId}})
            .then(res => res.data).catch(error => console.log(error));
    }

    getMembersOfMembership(membershipId, userId){
        return axios.get(`${API_BASE_URL}/scp/api/memberships/${membershipId}/members`, {params: {userId: userId}})
            .then(res => res.data).catch(error => console.log(error));
    }

    getMembershipMemberAvailabld(type, id){
        return axios.get(`${API_BASE_URL}/scp/api/memberships/members/available`, {params: {objectType: type, objectId: id}})
            .then(res => res.data).catch(error => console.log(error));
    }

    holdingMembership(data){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/${data.id}/update_status`, 
            querystring.stringify({...data})
        ).then(res => res.data).catch(error => console.log(error));
    }

    saveMemberForMembership(membershipId, data){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/${membershipId}/members`, 
            querystring.stringify({...data})
        ).then(res => res.data).catch(error => console.log(error));
    }

    removeMembershipMember(id){
        return axios.delete(`${API_BASE_URL}/scp/api/memberships/members/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }
}