import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { getRootDomain, moneyFormat } from '../../service/CommonService';
import { showErrorNotify, showSuccessNotify } from '../../service/NotificationService';
import { PAYMENT_GATEWAY, PAYMENT_METHOD, APP_FEP, PAYMENT_MODE } from '../../../constants';
import { useHistory } from 'react-router-dom';
import { showloading, stoploading } from '../../service/LoadingService';
import { completeOrderCheckout } from '../../../frontdesk/booking/BookingServices';
import { createHitPayPayment } from '../../../scp/tenant-configuration/TenantPaymentService';
import { OrderContext } from '../../../frontdesk/booking/OrderContext';

export const CheckoutPayment = (props) => {
    const history = useHistory()
    const orderContext = useContext(OrderContext)
    const currency = props.currency

    const onlinePaymentGateWay = props.paymentGateway
    const paymentMode = props.paymentMode
    const method = props.method
    const gateway = props.gateway
    const amount = props.amount
    const orderNumber = props.orderNumber
    const redirectTo = props.redirectTo

    async function handleCompleteCheckout(){
        if(gateway===PAYMENT_GATEWAY.HITPAY){
            showloading()
        }else{
            orderContext.setProcessCheckoutLoading(true)
            orderContext.setStepProcess(2)
        }

        const res = await completeOrderCheckout(orderNumber, paymentMode, method===PAYMENT_METHOD.online.value?gateway!==PAYMENT_GATEWAY.HITPAY?PAYMENT_METHOD.credit_card.value:null:method, gateway)

        if(!res.errorCode){
            if(gateway===PAYMENT_GATEWAY.HITPAY){
                const invoice = res;

                let hitPayRequest = {
                    reference_number: invoice.id,
                    purpose: 'Pay for invoice #: ' + res.id,
                    redirect_url: getRootDomain() + (redirectTo?redirectTo:'/monitoring/booking/'+orderNumber+'_'+onlinePaymentGateWay.id+'_'+PAYMENT_GATEWAY.HITPAY+'/order-completed')
                }

                createHitPayPayment(onlinePaymentGateWay.id, orderNumber, APP_FEP, hitPayRequest)
                .then(res => {
                    stoploading()
                    if(!res.errorCode){
                        if(res.url)
                            window.location.assign(res.url)
                    }else{
                        showErrorNotify('Can not init payment at the moment. Please refresh page and try again!', 'Error');
                    }
                })
            }else{
                console.log(res)
                orderContext.setProcessCheckoutLoading(false)
                orderContext.setOrder(res.order)
                orderContext.setInvoice(res.invoice)
            }
        }else{
            orderContext.setProcessCheckoutLoading(false)
            orderContext.setProcessOrderError(res.errorMessage)
        }
    }

    const moneyFmt = (price) => {
        return moneyFormat(price, currency)
    }

    return(
        <React.Fragment>
            {(()=>{
                if(amount>0 || method===PAYMENT_METHOD.cash.value){
                    if(paymentMode===PAYMENT_MODE.pre_pay.value){
                        switch(gateway){
                            case PAYMENT_GATEWAY.ONSITE:
                                switch(method){
                                    case PAYMENT_METHOD.cash.value:
                                        return <Button label={'COMPLETE ORDER'} className="p-p-4" disabled={!method} onClick={() => handleCompleteCheckout()} />
                                    default:
                                        return <Button label="PLACE ORDER" className="p-p-4" disabled={!method} onClick={() => handleCompleteCheckout()} />
                                }
                            default:
                                if(gateway === PAYMENT_GATEWAY.HITPAY){
                                    return <Button label={'PAY ('+moneyFmt(amount)+')'} className="p-p-4" disabled={!method} onClick={() => handleCompleteCheckout()} />
                                }
                                break;
                        }
                    }else{
                        return <Button label="PLACE ORDER" className="p-p-4" disabled={!method} onClick={() => handleCompleteCheckout()} />
                    }
                }else{
                    return <Button label={'COMPLETE ORDER'} className="p-p-4" disabled={!method} onClick={() => handleCompleteCheckout()} />
                }
            })()}
        </React.Fragment>
    )
        
}