import React, { Component } from 'react';

class ProgramTipInfo extends Component {
    render() {
        if(this.props.type === "program"){
            return (
                <div className="wrapper">
                    {/* {console.log(this.props.data)} */}
                    <span>Code: </span>
                    <span>{this.props.data.code}</span>
                    <span>Department: </span>
                    <span>{this.props.data.department}</span>
                    <span>Category: </span>
                    <span>{this.props.data.category}</span>
                </div>
            );
        } else if(this.props.type === "course"){
            return (
                <div className="wrapper">
                    {/* {console.log(this.props.data)} */}
                    <span>Code: </span>
                    <span>{this.props.data.code}</span>
                    <span>Location: </span>
                    <span>{this.props.data.location}</span>
                    <span>description: </span>
                    <span>{this.props.data.description}</span>
                </div>
            );
        }

    }
}

export default ProgramTipInfo;