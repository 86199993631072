import EventEmitter from 'eventemitter3';

export const loadingEvent = new EventEmitter();

export function showloading() {
  loadingEvent.emit('LOADING', true)
}

export function stoploading() {
  loadingEvent.emit('LOADING', false)
}