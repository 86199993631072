import React, {useState, useEffect} from "react";
import moment from 'moment';
import { DataTable } from "primereact/datatable";
import { useRouteMatch, useHistory} from "react-router-dom";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { APP_FEP, PAYMENT_STATE } from "../../constants";
import { getPageInvoices, sendInvoiceMailRemind } from "./InvoiceServices";
import { SelectButton } from "primereact/selectbutton";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { moneyFormat, getTablePageReport } from "../../core/service/CommonService";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";

export const InvoiceListView = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const [filter, setFilter] = useState({
        app: APP_FEP, 
        statuses: [PAYMENT_STATE.awaiting.value],
        id: '',
        orderIdOrNumber: '',
        customer: ''
    });

    const [dataList, setDataList] = useState([]);
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })
    const [viewType, setViewType] = useState(PAYMENT_STATE.awaiting.value);
    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(()=>{
        if(isLoadTable)
            loadPageInvoices();
    },[isLoadTable])

    const loadPageInvoices = () =>{
        showloading();
        getPageInvoices(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setDataList(res.content)
                setPageable({...pageable,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false);
            }
        })
        .finally(() => {
            stoploading()
        })
    }

    const onPage = (e) =>{
        setPageable({ ...pageable,
            loading: true,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({ ...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onViewTypeChange = (e) => {
        setViewType(e.value);
        setFilter({...filter,
            statuses: e.value===PAYMENT_STATE.completed.value?[PAYMENT_STATE.completed.value]:[PAYMENT_STATE.pending.value, PAYMENT_STATE.awaiting.value]
        })
        setLoadTable(true);
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true);
    };

    const clearInvoiceFilter = () => {
        setFilter({...filter,
            id: '',
            orderIdOrNumber: '',
            customer: ''
        })
        setLoadTable(true);
    }

    const sendRemindEmail = (id, reminded) => {
        showConfirmNotify({
            message: reminded?'This invoice had already send to customer. Are you sure you want to proceed?':'',
            accept: () => handleSendInvoiceRemindEmail(id)
        })
    }

    const handleSendInvoiceRemindEmail = (id) => {
        sendInvoiceMailRemind(id)
        .then(res => {
            if(!res.errorCode){
                loadPageInvoices()
                showSuccessNotify('Email sent successfully!');
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const VIEW_TYPE_LIST = {
        [PAYMENT_STATE.awaiting.value]: {label: 'Invoice', value: PAYMENT_STATE.awaiting.value},
        [PAYMENT_STATE.completed.value]: {label: 'Receipt', value: PAYMENT_STATE.completed.value}
    }
    
    const tblHeader = (
        <TableHeader title={VIEW_TYPE_LIST[viewType].label + "s"} 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChagePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Invoices-Receipts</BreadcrumbsItem>

            <div className="p-col-12 p-c">
                <SelectButton value={viewType} options={Object.values(VIEW_TYPE_LIST)} onChange={(e) => onViewTypeChange(e)}/>
            </div>
            <div className="p-col-12">
                <Fieldset legend="Filter">
                    <div className="p-grid p-fluid p-mt-1">
                        <div className="p-col-3 p-as-end">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon p-text-bold">{VIEW_TYPE_LIST[viewType].label} Number:</span>
                                <InputText value={filter.id} keyfilter={/^[0-9]*$/} onChange={e => setFilter({...filter, id: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                            </div>
                        </div>
                        <div className="p-col-3 p-as-end">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon p-text-bold">Order Id/Code:</span>
                                <InputText value={filter.orderIdOrNumber} onChange={e => setFilter({...filter, orderIdOrNumber: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                            </div>
                        </div>
                        <div className="p-col-3 p-as-end">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon p-text-bold">Customer:</span>
                                <InputText value={filter.customer} onChange={e => setFilter({...filter, customer: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)} placeholder="Name/email/phone"/>
                            </div>
                        </div>
                        <div className="p-col-3">
                            <Button label="Find" icon="pi pi-filter" iconPos="left" style={{width: 'auto'}} onClick={() => setLoadTable(true)} />
                            <Button label="Clear" icon="pi pi-trash" className="p-button-secondary p-ml-2" iconPos="left" style={{width: 'auto'}}  onClick={() => clearInvoiceFilter()} />
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12">
                <DataTable emptyMessage="There's no order" value={dataList} loading={isLoadTable}
                    lazy={true} paginator={true} 
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column header="No. #" field="id" sortable={true}  headerStyle={{width: '10em'}}
                        body={(rowData) => <Button label={rowData.id} icon="pi pi-search" className="p-button-info" tooltip={'View ' + VIEW_TYPE_LIST[viewType].label} tooltipOptions={{position: "top"}} onClick={() => history.push(`/invoices/${rowData.id}`)}/> }/>
                    <Column header="Customer" field="payorName" style={{width: '15%'}} sortable={true}
                        body={(rowData) => {
                            return <React.Fragment>
                                <div>{rowData.payorName}</div>
                                {rowData.payorEmail && <div className="p-margin-top-5">[{rowData.payorEmail}]</div>}
                                {rowData.payorPhone && <div className="p-margin-top-5">[{rowData.payorPhone}]</div>}
                                </React.Fragment>}
                        }
                    />
                    <Column header="Description" field="description" sortable={false} style={{width: '25%'}} 
                        body={rowData => {
                            return (
                                <React.Fragment>
                                    <div>{rowData.description}</div>
                                    {rowData.note && 
                                    <div className="p-size-12 p-mt-1">- {rowData.note}</div>
                                    }
                                </React.Fragment>
                            )
                        }}
                    />
                    <Column header="Total" field="total" bodyClassName="p-r" body={rowData => moneyFormat(rowData.total)}/>
                    <Column header={viewType===PAYMENT_STATE.awaiting.value?'Amount Due':'Paid'} field="checkout" bodyClassName="p-r" body={(rowData) => moneyFormat(rowData.checkout)} />
                    {viewType===PAYMENT_STATE.awaiting.value && 
                        <Column header="Paid" field="paid" bodyClassName="p-r" body={rowData => moneyFormat(rowData.paid)}/>
                    }
                    <Column header={viewType===PAYMENT_STATE.awaiting.value?'Due Date':'Paid At'} sortable={true}
                        bodyClassName="p-r" body={(rowData) => { 
                            if(viewType===PAYMENT_STATE.awaiting.value){
                                return rowData.paymentDueDate&&(moment(rowData.paymentDueDate).format('MM/DD/YYYY'))
                            }else{
                                return rowData.paymentCompletedAt&&(moment(rowData.paymentCompletedAt).format('MM/DD/YYYY hh:mm A'))
                            }
                        }}
                    />
                    
                    <Column header="Created At" field="dateCreated" sortable={true} headerStyle={{width: '12em'}}
                        bodyClassName="p-c" body={(rowData) => rowData.dateCreated&&(moment(rowData.dateCreated).format('MM/DD/YYYY hh:mm A'))}
                    />

                    {viewType===PAYMENT_STATE.awaiting.value && 
                        <Column header="Email reminder" bodyClassName="p-c" body={rowData => rowData.remind?'YES':'NO'}/>
                    }
                    {viewType===PAYMENT_STATE.awaiting.value && 
                        <Column headerStyle={{width: '12em'}} body={rowData => <Button label="Resend email" className="p-button-constrast" icon="pi pi-envelope" onClick={()=> sendRemindEmail(rowData.id, rowData.remind)} />}/>
                    }
                </DataTable>
            </div>
        </div>
    );
}