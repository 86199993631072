import React, { useEffect, useState, useRef, useContext} from 'react';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { PAYMENT_METHOD, PAYMENT_GATEWAY, PRODUCT_TYPE, PRODUCT_CLASSIFICATION, PRICING_MODE, DEPOSIT_TYPE, PAYMENT_MODE } from '../../constants';
import { showErrorNotify, showNotification, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { RadioButton } from 'primereact/radiobutton';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { AssignParticipantForm } from './AssignParticipantForm';
import classNames from 'classnames';
import { moneyFormat, getRootDomain } from '../../core/service/CommonService';
import { getPaymentConfig } from '../../scp/tenant-configuration/ConfigruationServices';
import { updateCartItemQuantity, removeCartItem, validateBeforePayment, removeParticipant, cancelFrontDeskOrder } from './BookingServices';
import { getOnlinePaymentGateway } from '../../scp/tenant-configuration/TenantPaymentService';
import { Divider } from '../../core/components/divider/Divider';
import { CheckoutPayment } from '../../core/components/payment/CheckoutPayment';
import { OrderContext } from './OrderContext';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { useHistory } from 'react-router-dom';

export const CartReview = (props) => {
    const history = useHistory()
    const orderContext = useContext(OrderContext)
    const frmParticipant = useRef(null)

    const pricingMode = orderContext.pricingMode
    const orderNumber = orderContext.orderNumber
    const order = orderContext.order

    const [paymentConfig, setPaymentConfig] = useState(null)
    const [onlinePaymentGateway, setOnlinePaymentGateway] = useState(null)
    const [isCheckout, setCheckout] = useState(false)
    const [errors, setErrors] = useState({})
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentMode, setPaymentMode] = useState(order.paymentMode)

    useEffect(() => {
        loadPaymentConfig()
    }, [])

    async function loadPaymentConfig(){
        const config = await getPaymentConfig()
        setPaymentConfig(config)

        let paymentGateWay = null
        if(config && config.onlinePaymentGateway){
            paymentGateWay = await getOnlinePaymentGateway(config.onlinePaymentGateway)
            setOnlinePaymentGateway(paymentGateWay)
        }
        
        setPaymentMethod(PAYMENT_METHOD.cash.value)
    }

    const onUpdateItemQuantity = (itemId, qty, parentId=null) => {
        setErrors({})

        updateCartItemQuantity({orderNumber: orderNumber, orderItemId: itemId, quantity: qty, parentOrderItemId: parentId})
        .then(res => {
            if(res){
                if(!res.errorCode){    
                    orderContext.setOrder(res.order)
                }else{
                    showErrorNotify(res.errorMessage);
                    if(res.errorCode===400)
                        setErrors(res.errorObj)
                }
            }
        })
    }

    const confirmRemoveCartItem = (id) => {
        showConfirmNotify({
            accept: () => handleRemoveCartItem(id)
        })
    }

    const handleRemoveCartItem = (id) => {
        removeCartItem(order.orderNumber, id)
        .then(res => {
            if(!res.errorCode) {
                const order = res.order
                orderContext.setOrder(order)

                if(order.items.length===0){
                    orderContext.setStepProcess(0)
                    showErrorNotify("Order has empty!")
                }
            }else{
                showErrorNotify("Error", res.errorMessage);   
            }
        })
    }

    // const handleApplyCouponCode = () => {
    //     if(this.state.coupon) {
    //         applyCouponCodeForOrder(this.state.order.id, this.state.coupon)
    //         .then(data => {
    //             if(data && data.id) {
    //                 console.log(data)
    //             }
    //         })
    //     }
    // }

    // const renderBtnCheckout = () => {
    //     if(selectePaymentOption) {
    //         switch (selectePaymentOption) {                    
    //             case PAYMENT_METHOD.online.value:
    //                 if(activePayment.gateway === PAYMENT_GATEWAY.HITPAY){
    //                     return(
    //                         <HitPayCheckoutForm 
    //                             orderNumber={order.orderNumber}
    //                             method={selectePaymentOption}
    //                             gateway={activePayment.gateway}
    //                             amount={order.checkoutTotal}
    //                             currency={currency}
    //                             activePayment={activePayment}
    //                             buyer={buyerInfo}
    //                             errors={errorData => setErrors(errorData?errorData:{})}
    //                         />
    //                     )
    //                     return <Button label={'Pay with HITPAY ('+moneyFormat(order.checkoutTotal)+')'} onClick={() => handleCashCheckout()} />
    //                 }
    //                 break;
    //             case PAYMENT_METHOD.Cash:
    //             default: 
    //                 return <Button label={'PAYMENT ('+moneyFormat(order.checkoutTotal)+')'} onClick={() => handleCashCheckout()} />
    //         }
    //     } else {
    //         return <Button label={'PAYMENT ('+moneyFormat(order.checkoutTotal)+')'} disabled={true} />
    //     }
    // }

    const onCheckout = () => {
        validateBeforePayment(order.orderNumber)
        .then(res=>{
            if(!res.errorCode){
                setCheckout(true)
            }else{
                showErrorNotify('Attendee has not been fully assigned', 'Error');
                if(res.errorCode===400 && res.errorObj) setErrors(res.errorObj)
            }
        })
    }

    const confirmRemoveParticipant = (itemId, participantIdx, id) => {
        showConfirmNotify({
            accept: () => handelRemoveParticipant(itemId, participantIdx, id)
        })
    }

    const handelRemoveParticipant = (itemId, participantIdx, id) => {
        removeParticipant(id)
        .then(res=>{
            if(!res.errorCode){
                actionParticipantSuccess(itemId, participantIdx)
                showSuccessNotify('Action submitted', 'Success')
            }else{
                showErrorNotify('', 'Error Message');
            }
        })
    }

    const onAddOrEditParticipant = (orderId, orderItemId, product, participant, participantIdx) => {
        frmParticipant.current.openForm(orderId, orderItemId, product, participant, participantIdx)
    }

    const actionParticipantSuccess = (itemId, participantIdx, participant) => {
        let tmpCartItems = [...order.items]
        for (const cartItem of tmpCartItems) {
            if(cartItem.id === itemId){
                cartItem.participants.splice(participantIdx, 1, participant)
            }else{
                if(cartItem.childItems){
                    for (const childItem of cartItem.childItems) {
                        if(childItem.id === itemId){
                            childItem.participants.splice(participantIdx, 1, participant)
                        } 
                    }
                }
            }
        }
        orderContext.setOrder({...order, items: tmpCartItems})
    }

    const cancelOrder = () => {
        showConfirmNotify({
            message: 'Are you sure to cancel this order? This action can not be undo',
            accept: () => handleCancelOrder()
        })
    }

    const handleCancelOrder = () => {
        showloading()

        cancelFrontDeskOrder(orderNumber)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify("Order has been canceled!")
                history.push('/monitoring/booking')
            }else{
                showErrorNotify(res.errorMessage)
            }
            stoploading()
        })
    }

    const moneyFmt = (value) =>{
        return moneyFormat(value, order.currency)
    }

    return(
        <React.Fragment>
            <div className="content-section">
                <div className="container">
                    <AssignParticipantForm ref={frmParticipant} 
                        assignSuccess={(itemId, participantIdx, participant) => actionParticipantSuccess(itemId, participantIdx, participant)}
                    />

                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card">
                                <div className="p-datatable p-component p-datatable-responsive p-margin-bottom-20">
                                    <div className="p-datatable-wrapper">
                                        <table style={{width: '100%'}}>
                                            <thead className="p-datatable-thead">
                                                <tr>
                                                    <th style={{width: '40%'}}>Product</th>
                                                    <th className="p-text-center" style={{width: '10%'}}>Q.ty</th>
                                                    <th className="" style={{width: '10%'}}>Price</th>
                                                    <th className="" style={{width: '20%'}}>Description</th>
                                                    <th className="p-text-right" style={{width: '10%'}}>Checkout</th>
                                                    <th className="" style={{width: '10%'}}></th>
                                                </tr>
                                            </thead>
                                            <tbody className="p-datatable-tbody">
                                                {order.items && order.items.map((item, itemIdx) => {
                                                    let tmpProduct = item.product;
                                                    let isShowUpdateQty = true
                                                    if(item.childItems && item.childItems.length>0){
                                                        isShowUpdateQty = false;
                                                    }
                                                    return(
                                                        <React.Fragment key={item.id}>
                                                            <tr key={item.id} className="p-datatable-row" style={{backgroundColor: 'rgb(245, 245, 245)'}}>
                                                                <td>
                                                                    <span className="p-column-title">Product</span>
                                                                    <div className="p-text-bold p-d-inline-block">
                                                                        {item.productName}{item.productVariantName&&(' - ['+item.productVariantName+']')} 
                                                                    </div>
                                                                    {(()=>{
                                                                        if(tmpProduct.classification !== PRODUCT_CLASSIFICATION.combo.value && tmpProduct.type === PRODUCT_TYPE.program.value){
                                                                            if(item.participants){
                                                                                let participantHtml = []

                                                                                for(let i=0; i<item.quantity; i++){
                                                                                    const participant = item.participants[i]

                                                                                    participantHtml.push(
                                                                                        <div className="item-participant p-d-flex p-justify-between p-py-1">
                                                                                            <div>
                                                                                                <i className="pi pi-user"></i>
                                                                                                <label className="p-ml-2">Slot {(i+1)}: </label>
                                                                                                <label className={classNames('p-ml-2 p-text-bold', {'p-error': errors['item_'+item.id]&&!participant})}>{participant?participant.name:'Not assigned yet'}</label>
                                                                                            </div>
                                                                                            <div>
                                                                                                {!participant?
                                                                                                <Button icon="pi pi-plus" className="btn-sm" onClick={() => onAddOrEditParticipant(item.orderId, item.id, tmpProduct, null, i)} tooltip="Add Participant" tooltipOptions={{position: 'top'}} />
                                                                                                :
                                                                                                <React.Fragment>
                                                                                                    <Button icon="pi pi-pencil" className="btn-sm p-mr-1" onClick={() => onAddOrEditParticipant(item.orderId, item.id, tmpProduct, participant, i)} tooltip="Edit Participant" tooltipOptions={{position: 'top'}} />
                                                                                                    <Button icon="pi pi-trash" className="btn-sm" onClick={(e) => confirmRemoveParticipant(item.id, i, participant.id)} tooltip="Remove Participant" tooltipOptions={{position: 'top'}} />
                                                                                                </React.Fragment>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                return participantHtml
                                                                            }
                                                                        }
                                                                    })()}
                                                                    {item.autoRecurring ? 
                                                                    <div className="p-mt-2 p-mb-1">
                                                                        - Subscription in every {item.subscriptionInfo.period} {item.subscriptionInfo.periodType}{item.subscriptionInfo.period>1?'s':''}
                                                                    </div>
                                                                    :
                                                                    item.rentalInfo && 
                                                                        <div className="p-mt-2 p-mb-1">
                                                                            - Rent in {item.rentalInfo.rentalQty} {item.rentalInfo.rentalUnit}{item.rentalInfo.rentalQty>1?'s':''}
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td className="p-text-center">
                                                                    <span className="p-column-title">Q.ty</span>
                                                                    {isShowUpdateQty ?
                                                                        <InputNumber value={item.quantity} inputClassName="input-sm p-text-center" inputStyle={{width: '100%'}} min={1} showButtons buttonLayout="horizontal" step={1} 
                                                                            decrementButtonClassName="p-button-danger btn-sm" incrementButtonClassName="p-button-success btn-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                                                            onChange={(e) => onUpdateItemQuantity(item.id, e.value)}/>
                                                                        : item.quantity
                                                                    }
                                                                    <div className="p-error">{errors['quantity_'+item.id]}</div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-column-title">Price</span>
                                                                    <div className="p-d-flex p-flex-column">
                                                                        <span>{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren?'':moneyFmt(item.total)}</span>
                                                                        {item.deposit&&
                                                                        <span className="p-size-12 p-text-italic">(Deposit {moneyFmt(item.depositTotal)})</span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-column-title">Description</span>
                                                                    <div className="p-d-inline-block">
                                                                        {item.deposit && <div className="p-my-2">- Deposit as {DEPOSIT_TYPE[item.depositType].label}</div>}
                                                                        {item.scheduleInfo &&
                                                                            <React.Fragment>
                                                                                {item.deposit && 
                                                                                <div className="p-mb-1">
                                                                                    - Schedule:
                                                                                </div>
                                                                                }
                                                                                <div className={classNames("p-mb-1", {'p-ml-3': item.deposit})}>
                                                                                    Start: {moment(item.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                                </div>
                                                                                <div className={classNames("p-mb-1", {'p-ml-3': item.deposit})}>
                                                                                    End: {moment(item.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                                </div>        
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="p-text-bold p-size-14">
                                                                    <span className="p-column-title">Checkout</span>
                                                                    <div className="p-text-right">
                                                                        {moneyFmt(item.deposit?item.depositType===DEPOSIT_TYPE.installment.value?item.checkout:item.itemBasePriceTotalFinal + item.depositTotal:item.itemBasePriceTotalFinal)}
                                                                    </div>
                                                                </td>
                                                                <td className="p-text-right">
                                                                    <Button icon="pi pi-pencil" className="p-button-info btn-sm" tooltip="Edit" tooltipOptions={{position: "top"}} onClick={() => orderContext.addOrUpdateCartItem(item.productId, item.id)} />
                                                                    <Button icon="pi pi-trash" className="p-button-danger btn-sm p-ml-1" tooltip="Remove" tooltipOptions={{position: "top"}} onClick={(e) => confirmRemoveCartItem(item.id)} />    
                                                                </td>
                                                            </tr>
                                                            {item.childItems && item.childItems.map((childItem, childItemIdx) => {
                                                                let tmpChildProduct = childItem.product;
                                                                return(
                                                                    <tr key={childItem.id} className="p-datatable-row" style={{backgroundColor: '#fff'}}>
                                                                        <td className="">
                                                                            <span className="p-column-title p-pl-4">Product</span>
                                                                            <div className="p-w-bold p-pl-4 p-d-inline-block">
                                                                                {childItem.productName}{childItem.productVariantName&&(' - ['+childItem.productVariantName+']')}
                                                                            </div>
                                                                            <div className="p-pl-4 p-pt-2">
                                                                            {(()=>{
                                                                                if(tmpChildProduct.classification !== PRODUCT_CLASSIFICATION.combo.value && tmpChildProduct.type === PRODUCT_TYPE.program.value){
                                                                                    if(childItem.participants){
                                                                                        let participantHtml = []

                                                                                        for(let i=0; i<childItem.quantity; i++){
                                                                                            const participant = childItem.participants[i]
                                                    
                                                                                            participantHtml.push(
                                                                                                <div className="item-participant p-d-flex p-justify-between p-py-1">
                                                                                                    <div>
                                                                                                        <i className="pi pi-user"></i>
                                                                                                        <label className="p-ml-2">Slot {(i+1)}: </label>
                                                                                                        <label className={classNames('p-ml-2 p-text-bold', {'p-error': errors['item_'+childItem.id]&&!participant})}>{participant?participant.name:'Not assigned yet'}</label>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {!participant?
                                                                                                        <Button icon="pi pi-plus" className="p-button-success btn-sm" onClick={() => onAddOrEditParticipant(childItem.orderId, childItem.id, tmpChildProduct, null, i)} />
                                                                                                        :
                                                                                                        <React.Fragment>
                                                                                                            <Button icon="pi pi-pencil" className="p-button-info btn-sm p-mr-1" onClick={() => onAddOrEditParticipant(childItem.orderId, childItem.id, tmpChildProduct, participant, i)} />
                                                                                                            <Button icon="pi pi-trash" className="p-button-danger btn-sm" onClick={(e) => confirmRemoveParticipant(childItem.id, i, participant.id)} />
                                                                                                        </React.Fragment>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        return participantHtml
                                                                                    }
                                                                                }
                                                                            })()}
                                                                            </div>
                                                                            {childItem.rentalInfo && 
                                                                            <div className="p-mt-2 p-mb-1 p-pl-6">
                                                                                - Rent in {childItem.rentalInfo.rentalQty} {childItem.rentalInfo.rentalUnit}{childItem.rentalInfo.rentalQty>1?'s':''}
                                                                            </div>
                                                                            }
                                                                        </td>
                                                                        <td className="p-text-center">
                                                                            <span className="p-column-title p-pl-6">Q.ty</span>
                                                                            {/* <InputNumber value={childItem.quantity} inputClassName="input-sm p-text-center" inputStyle={{width: '100%'}} min={1} showButtons buttonLayout="horizontal" step={1}
                                                                                decrementButtonClassName="p-button-danger btn-sm" incrementButtonClassName="p-button-success btn-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                                                                onChange={(e) => onUpdateItemQuantity(childItem.id, e.value, item.id)}/> */}
                                                                                {childItem.quantity}
                                                                            <div className="p-error">{errors['quantity_'+childItem.id]}</div>
                                                                        </td>
                                                                        <td>
                                                                            <span className="p-column-title p-pl-6">Price</span>
                                                                            {moneyFmt(childItem.total)}
                                                                            </td>
                                                                        <td>
                                                                            <span className="p-column-title p-pl-6">Description</span>
                                                                            {childItem.scheduleInfo &&
                                                                                <div className="p-d-inline-block">
                                                                                    <div className="p-mb-1">
                                                                                        Start: {moment(childItem.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                                    </div>
                                                                                    <div className="p-mt-2">
                                                                                        End: {moment(childItem.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <td></td>
                                                                        <td className="p-text-right">
                                                                            {/* <Button icon="pi pi-pencil" className="p-button-info btn-sm" tooltip="Edit" tooltipOptions={{position: "top"}} /> */}
                                                                            {/* <Button icon="pi pi-trash" className="p-button-danger btn-sm p-ml-1" tooltip="Remove" tooltipOptions={{position: "top"}}  onClick={() => handleRemoveCartItem(childItem.id)}/>     */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-5">
                            {/* {(()=>{
                                let useDonation = localStorage.getItem('useDonation')
                                if(useDonation && useDonation === 'true'){
                                    return(
                                        <DonationForm ref={el => this.donationForm = el}
                                            orderId={order.id} onAmountChange={this.onAmountChange} changeDonationAmount={this.handleChangeDonationAmount}
                                            reload={(data) => this.updateOrderCartInformation(data)} donationItem={this.state.donationItem} errors={this.state.errors}
                                        />            
                                    )
                                }
                            })()} */}
                            <div className="card">
                                <h4 className="p-text-bold">Order Summary</h4>
                                <Divider/>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <p className="p-d-flex p-jc-between p-size-16"><span>Sub Total:</span> <span>{moneyFmt(order.itemsTotal)}</span></p>
                                        {pricingMode===PRICING_MODE.included_tax && 
                                        <p className="p-d-flex p-jc-between"><span>Sub Total Without Tax:</span> <span>{moneyFmt(order.itemsTotal - order.taxTotal)}</span></p>
                                        }
                                        {order.adjustTotal!==0 && 
                                        <p className="p-d-flex p-jc-between"><span>Discount:</span> <span>{moneyFmt(order.adjustTotal)}</span></p>
                                        }
                                        
                                        {order.mapTaxes && Object.keys(order.mapTaxes).length>0 && 
                                            Object.values(order.mapTaxes).map(t => {
                                                return (
                                                <p className="p-d-flex p-jc-between"><span>{t.name}<span className="p-text-italic">({t.rate}%)</span> :</span> <span>{moneyFmt(t.total)}</span></p>
                                                )
                                            })
                                        }
                                        <Divider/>
                                        <p className="p-d-flex p-jc-between p-size-20 p-text-bold"><span>Order Total:</span> <span>{moneyFmt(order.total)}</span></p>
                                        {order.pledgedDepositTotal>0 &&
                                        <p className="p-d-flex p-jc-between p-size-16"><span>Security Deposit:</span> <span>{moneyFmt(order.pledgedDepositTotal)}</span></p>
                                        }
                                        
                                        {order.total!==order.checkoutTotal && 
                                        <React.Fragment>
                                            <Divider/>
                                            <p className="p-d-flex p-jc-between p-text-bold p-size-20"><span>Checkout Total:</span> <span>{moneyFmt(order.checkoutTotal)}</span></p>
                                        </React.Fragment>
                                        }
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="p-col-12 p-lg-7">
                            {!isCheckout? 
                            <div className="p-grid p-fluid p-justify-center">
                                <div className="p-col-12 p-sm-8 p-md-6">
                                    <Button label="CHECK OUT" className="p-py-4" onClick={() => onCheckout()}></Button>
                                    <Button label="CANCEL ORDER" className="p-button-danger p-py-2 p-mt-2" onClick={() => cancelOrder()}></Button>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <div className="card">
                                    {order.checkoutTotal>0 && 
                                    <h5 className="p-text-bold">Payment</h5>
                                    }
                                    <Divider/>
                                    
                                    <div className="p-grid p-fluid p-justify-center p-ai-center">
                                        {order.checkoutTotal>0 &&
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label className="p-w-bold p-mr-4">Payment mode:</label>
                                                <RadioButton inputId="rbPaymentMode0" name="paymentMode" value={PAYMENT_MODE.pre_pay.value} onChange={(e) => setPaymentMode(e.value)} checked={paymentMode===PAYMENT_MODE.pre_pay.value}/>
                                                <label htmlFor="rbPaymentMode0" className="p-radiobutton-label p-mr-5">{PAYMENT_MODE.pre_pay.label}</label>
                                                <RadioButton inputId="rbPaymentMode1" name="paymentMode" value={PAYMENT_MODE.post_pay.value} onChange={(e) => setPaymentMode(e.value)} checked={paymentMode===PAYMENT_MODE.post_pay.value}/>
                                                <label htmlFor="rbPaymentMode1" className="p-radiobutton-label">{PAYMENT_MODE.post_pay.label}</label>
                                            </div>
                                            {paymentMode && paymentMode===PAYMENT_MODE.pre_pay.value &&
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-w-bold">Select one of the payment options below:</label>
                                                </div>
                                                <div className="p-col-12 p-md-5 p-lg-4">
                                                    <div className="p-py-2">
                                                        <RadioButton inputId="rdPaymentMethod0" className="p-mr-2" value={PAYMENT_METHOD.cash.value} onChange={(e) => setPaymentMethod(e.value)} checked={paymentMethod === PAYMENT_METHOD.cash.value} />
                                                        <label htmlFor="rdPaymentMethod0">{PAYMENT_METHOD.cash.label}</label>
                                                    </div>
                                                    <div className="p-py-2">
                                                        <RadioButton inputId="rdPaymentMethod0" className="p-mr-2" value={PAYMENT_METHOD.bank_transfer.value} onChange={(e) => setPaymentMethod(e.value)} checked={paymentMethod === PAYMENT_METHOD.bank_transfer.value} />
                                                        <label htmlFor="rdPaymentMethod0">{PAYMENT_METHOD.bank_transfer.label}</label>
                                                    </div>
                                                    {paymentConfig.useOnlinePayment &&
                                                        <div className="p-py-2">
                                                            <RadioButton inputId="rdPaymentOnline" className="p-mr-2" value={PAYMENT_METHOD.online.value} onChange={(e) => setPaymentMethod(e.value)} checked={paymentMethod === PAYMENT_METHOD.online.value} />
                                                            <label htmlFor="rdPaymentOnline">{PAYMENT_METHOD.online.label}</label>
                                                        </div>
                                                    }
                                                </div> 
                                            </React.Fragment>
                                            }
                                        </React.Fragment>
                                        }

                                        <div className="p-col-12 p-md-7 p-lg-8 p-text-center p-d-flex p-flex-column p-ai-center p-justify-center">
                                            <CheckoutPayment 
                                                orderNumber={order.orderNumber} 
                                                currency={order.currency}
                                                amount={order.checkoutTotal} 
                                                paymentMode={paymentMode}
                                                method={paymentMethod} 
                                                gateway={paymentMethod===PAYMENT_METHOD.online.value ? onlinePaymentGateway ? onlinePaymentGateway.gateway : null : PAYMENT_GATEWAY.ONSITE}
                                                paymentGateway = {onlinePaymentGateway}
                                                onCompleteCheckout = {(e) => props.onCompleteCheckout(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                        {/* <div className="p-col-12 p-md-6">
                            <Fieldset legend="Discount">
                                <div className="p-inputgroup">
                                    <InputText value={this.state.coupon} placeholder="Discount Voucher Here" onChange={(e) => this.setState({coupon: e.target.value})} />
                                    <Button label="Apply" onClick={() => this.handleApplyCouponCode()} />
                                </div>
                            </Fieldset>
                        </div> */}
                        {/* <div className="p-col-12">
                            <Button label="Back to Products" icon="pi pi-arrow-circle-left" onClick={() => history.push('/bookings')} />
                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>   
    )
}
