import React, {Component} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import {TabView,TabPanel} from 'primereact/tabview';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from 'primereact/inputswitch';
import {PromotionForm} from './PromotionForm';
import { hasRole } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import {PromotionCouponForm} from './PromotionCouponForm';
import RuleCartConditions from './RuleCartConditions';
import DependentCondition from './DependentCondition';
import QuantityCondition from './QuantityCondition';
import RuleDiscount from './RuleDiscount';
import {RuleDiscountRanges} from './RuleDiscountRanges';
import { showNotification } from '../../core/service/NotificationService';
import { getPromotion, getPagePromotionRules, savePromotionRule, removePromotionRule, getPagePromotionCoupons, removePromotionCoupon, savePromotionRulePriority, uploadFlyingImage } from './PromotionService';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { translateListSimple, PROMOTION_RULE_TYPES, PROMOTION_RULE_TYPE,  CONDITION_APPLY_FOR_TYPE, CHANNEL_TYPES, CONDITION_APPLY_FOR_TYPES, PROMOTION_TYPE, RULE_FILTER_OBJECT, RULE_FILTER_TYPE, DEFAULT_RULE_FILTER, PROD_TYPE, DEFAULT_RULE_DISCOUNT, RULE_CONDITION_TYPE, CHANNEL_TYPE, APP_FEP, TAXONOMY_TYPE } from '../../constants';
import { translateOrderItemStatus } from '../../core/service/CommonService';
import { Panel } from 'primereact/panel';
import RuleFilters from './RuleFilters';
import RuleDiscounts from './RuleDiscounts';
import RuleConditions from './RuleConditions';
import { ColorPicker } from 'primereact/colorpicker';
import { parseMessage, parseMessage2 } from '../../core/utils/TranslationUtils.js';
import { InputTextarea } from 'primereact/inputtextarea';
import { getListProducts } from '../product/ProductServices';
import { getListTaxons } from '../taxon/TaxonServices';

class PromotionDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            promotion: {},
            ruleList: [],
            ruleTotal: 0,
            ruleLoading: false,
            rulePage: 0,
            ruleRows: 10,
            ruleSortField: '',
            ruleSortOrder: 0,
            rule: {
                // priority: 0,
                // cartConditions: [{cartType: 'cart_subtotal', methodType: 'equal', minValue: '0', maxValue: '0'}],
                // dependentCondition: {buyType: 'any', methodType: 'equal', minQuantity: '0', maxQuantity: '0', buyProducts: [], discountProducts: []},
                // quantityCondition: {applyToType: 'all_product', applyToProducts: [], applyToPrograms: [], applyToCategories: [], excludeProducts: [], acrossQuantity: false},
                // discount: {discountValue:'0', discountValueType: 'percentage'},
                // rangeDiscounts: [{loop: false, minQuantity: '0', maxQuantity: '0', discountValue: '0', discountValueType: 'percentage', applyForType: 'same_product', applyQuantity: '0', applyForProduct: ''}],
                // applyForTypes: [...CONDITION_APPLY_FOR_TYPES],
                acrossQuantity: false,
                conditionMatchAll: true,
                filters: [{...DEFAULT_RULE_FILTER}],
                discounts: [{...DEFAULT_RULE_DISCOUNT}],
                conditions: []
            },
            ruleActiveTabIndex: 0,
            menus: [],
            categories: [],
            dishes: [],
            couponList: [],
            couponTotal: 0,
            couponLoading: false,
            couponPage: 0,
            couponRows: 10,
            couponSortField: '',
            couponSortOrder: 0,
            couponFormData: null,
            availableRules: [],
            couponFormDialogVisible: false,
            promotionData: {},
            errors: {},
            cropImage: {
                unit: '%',
                width: 100,
                aspect: 1 / 1
            },
            imageSrc: null,
            files: []
        }
        this.onRowReorder = this.onRowReorder.bind(this);
    }

    componentDidMount(){
        this.loadPromotion();
        this.loadPagePromotionRules();
        this.loadCategories();
    }

    loadPromotion = () => {
        getPromotion(this.props.match.params.id).then(res => {
            if(res){
                this.setState({
                    promotion: res,
                    availableRules: res.rules?res.rules:[]
                });

                this.popularRuleFormData(null, res.type);
                this.loadProducts(res.refIds);
                
                if(res.allowCoupon){
                    this.loadPagePromotionCoupons();
                }
            }else{
                showNotification('error', 'Error Message', 'Not Found');
            }
        });
    }

    loadPagePromotionRules = () => {
        getPagePromotionRules(this.props.match.params.id, this.state.rulePage, this.state.ruleRows, this.state.ruleSortField, this.state.ruleSortOrder)
            .then(res => {
                this.setState({
                    ruleList: res.content,
                    ruleTotal: res.totalElements,
                    ruleLoading: false,
                    rulePage: res.pageable.pageNumber,
                    ruleRows: res.pageable.pageSize
                });
            });
    }

    loadCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value}, true).then(res => this.setState({ categories: res?res:[] }))
    }

    loadProducts = (refIds) => {
        let filter = {
            app: APP_FEP
        }
        getListProducts(filter, true).then(res => {
            this.setState({ products: res?res:[] })
        })
    }

    //#region RULE
    ruleActionTemplate = (rowData, column) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        return (
            <div>
                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) &&
                    <Button icon="pi pi-pencil" tooltip={trans('promotion.mgt.edit_promotion')} tooltipOptions={{position: 'top'}} className="p-button-warning" onClick={() => this.addOrEditRule(rowData)}/>
                }
                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_D]) &&
                    <Button icon="pi-md-close" tooltip={trans('promotion.mgt.delete_promotion')} tooltipOptions={{position: 'top'}} className="p-button-danger" onClick={() => this.removeRule(rowData.id)}/>
                }
            </div>
        );
    }

    onRulePage = (e) => {
        this.setState({
            ruleLoading: true,
            rulePage: e.page
        },() => this.loadPagePromotionRules());
    }

    onRuleSort = (e) => {
        this.setState({
            ruleLoading: true,
            ruleSortField: e.sortField,
            ruleSortOrder: e.sortOrder
        },() => this.loadPagePromotionRules())
    }

    popularRuleFormData = (data, promotionType) => {
        this.getPromotion();
        if(promotionType===PROMOTION_TYPE.promotion){
            let applyForTypes = [];

            if(data && data.type===PROMOTION_RULE_TYPE.product_bogo){
                applyForTypes = [...CONDITION_APPLY_FOR_TYPES];
                if(data.quantityCondition && data.quantityCondition.acrossQuantity){
                    applyForTypes.splice(applyForTypes.indexOf(applyForTypes.find(i => i.value===CONDITION_APPLY_FOR_TYPE.same_product)), 1);
                }
            }

            let conditions = data&&data.conditions?data.conditions:[];
            conditions.length>0 && conditions.map(c => {
                if(c.conditionType===RULE_CONDITION_TYPE.times){
                    let sValues = new Array(2);
                    c.values.length>0 && c.values.map((time, idx) => {
                        sValues[idx] = moment(time, moment.HTML5_FMT.TIME).toDate();
                    });
                    c.sValues=sValues;
                }
            });
            this.setState({
                rule: {
                    id: data?data.id:'',
                    name: data?data.name:'',
                    nameDisplay: data?data.nameDisplay:'',
                    type: data?data.type:null,
                    priority: data?data.priority: this.state.ruleList.length,
                    usageLimit: data && data.usageLimit?data.usageLimit+'':null,
                    validFrom: data && data.validFrom?data.validFrom:this.state.promotionData && this.state.promotionData.startAt ? this.state.promotionData.startAt : '',
                    validTo: data && data.validTo?data.validTo:this.state.promotionData && this.state.promotionData.endAt ? this.state.promotionData.endAt : '',
                    exclusive: data?data.exclusive:false,
                    active: data?data.active:true,
                    startDateValue: data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : this.state.promotionData.startAt ? moment(this.state.promotionData.startAt, 'YYYY-MM-DD HH:mm').toDate(): '',
                    endDateValue: data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : this.state.promotionData && this.state.promotionData.endAt ? moment(this.state.promotionData.endAt, 'YYYY-MM-DD HH:mm').toDate() : '',
                    acrossQuantity: data?data.acrossQuantity:false,
                    conditionMatchAll: data?data.conditionMatchAll:true,
                    applyForTypes: applyForTypes,
                    filters: data && data.filters.length>0?data.filters:[{...DEFAULT_RULE_FILTER}],
                    discounts: data && data.discounts.length>0?data.discounts:[{...DEFAULT_RULE_DISCOUNT}],
                    conditions: conditions,
                    // showDiscountBarOnMenu: data?data.showDiscountBarOnMenu:false,
                    // showDiscountBarOnProduct: data?data.showDiscountBarOnProduct:false,
                    // showDiscountBarFlying: data?data.showDiscountBarFlying:false,
                    // discountBarContent: data&&data.discountBarContent?data.discountBarContent:'',
                    // trans: {
                    //     nameDisplay: data && data.trans && data.trans.nameDisplay?data.trans.nameDisplay:{},
                    //     discountBarContent: data && data.trans && data.trans.discountBarContent?data.trans.discountBarContent:{}
                    // },
                    // discountBarBackgroundColor: data && data.discountBarBackgroundColor?data.discountBarBackgroundColor.replace('#', '') : localStorage.getItem('background_color'),
                    // discountBarTextColor: data && data.discountBarTextColor?data.discountBarTextColor.replace('#', '') : localStorage.getItem('text_color'),
                    // image: data && data.image ? data.image : {}
                },
                imageSrc: null
            });
        }else{
            this.setState({
                rule: {
                    id: data?data.id:'',
                    name: data?data.name:'',
                    nameDisplay: data?data.nameDisplay:'',
                    type: PROMOTION_RULE_TYPE.manual_order_discount,
                    validFrom: data && data.validFrom?data.validFrom:'',
                    validTo: data && data.validTo?data.validTo:'',
                    exclusive: data?data.exclusive:false,
                    active: data?data.active:true,
                    startDateValue: data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : '',
                    endDateValue: data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : '',
                    discounts: data&&data.discounts.length>0?data.discounts:[{...DEFAULT_RULE_DISCOUNT}]
                },
                errors: {}
            });
        }
    }

    resetRuleFormData = () => {
        this.popularFormData(null);
    }

    onRuleStartDateChange = (e) => {
        this.setState({
            rule: {...this.state.rule, validFrom: e.value?moment(e.value).format('YYYY-MM-DD HH:mm'):'', startDateValue: e.value?e.value:''}
        });
    }

    onRuleEndDateChange = (e) => {
        this.setState({
            rule: {...this.state.rule, validTo: e.value?moment(e.value).format('YYYY-MM-DD HH:mm'):'', endDateValue: e.value?e.value:''} 
        });
    }

    onAcrossQuantityChange = (e) => {
        if(this.state.rule.type===PROMOTION_RULE_TYPE.product_bogo){
            let applyForTypes = [...CONDITION_APPLY_FOR_TYPES];

            if(e) applyForTypes.splice(applyForTypes.indexOf(applyForTypes.find(i => i.value===CONDITION_APPLY_FOR_TYPE.same_product)), 1);

            let tmpRangeDiscounts = [...this.state.rule.rangeDiscounts];

            tmpRangeDiscounts.map(val => {
                if(e && val.applyForType===CONDITION_APPLY_FOR_TYPE.same_product){
                    val.applyForType = CONDITION_APPLY_FOR_TYPE.specific_product;
                }
            });

            this.setState({
                rule: {
                    ...this.state.rule, 
                    rangeDiscounts: tmpRangeDiscounts
                }
            }, () => this.frmQuantityCondition.updateApplyForTypes(applyForTypes));
        }
    }

    addOrEditRule = (data) => {
        this.setState({
            ruleActiveTabIndex: 1
        }, () => {
            this.popularRuleFormData(data, this.state.promotion.type);
        });
    }

    renderPromotionRuleSettings = () => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        if(this.state.rule.type){
            let type = this.state.rule.type;
            return (
                <div className="p-col-12">
                    <Fieldset legend={trans('promotion.mgt.configuration')}>
                        {(() => {
                            switch (type) {
                                case PROMOTION_RULE_TYPE.cart_adjustment:
                                    return (
                                        <Fieldset legend={trans('promotion.mgt.cart_condition')}>
                                            <RuleCartConditions data={this.state.rule.cartConditions} onChange={(e) => this.setState({rule: {...this.state.rule, cartConditions: e}})}/>
                                        </Fieldset>
                                    );
                                case PROMOTION_RULE_TYPE.product_dependent:
                                    return (
                                        <Fieldset legend={trans('promotion.mgt.depedent_condition')}>
                                            <DependentCondition data={this.state.rule.dependentCondition} channel={this.state.promotion.channel}  onChange={(e) => this.setState({rule: {...this.state.rule, dependentCondition: e}})}/>
                                        </Fieldset>
                                    );
                                case PROMOTION_RULE_TYPE.product_quantity:
                                case PROMOTION_RULE_TYPE.product_bogo:
                                    return (
                                        <Fieldset legend={trans('promotion.mgt.condition')}>
                                            <QuantityCondition data={this.state.rule.quantityCondition} channel={this.state.promotion.channel} updateAcrossQuantity={(e) => this.onAcrossQuantityChange(e)} onChange={(e) => this.setState({rule: {...this.state.rule, quantityCondition: e}})}/>
                                        </Fieldset>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                        {(() => {
                            switch (type) {
                                case PROMOTION_RULE_TYPE.cart_adjustment:
                                case PROMOTION_RULE_TYPE.product_dependent:
                                case PROMOTION_RULE_TYPE.manual_order_discount:
                                    return (                                        
                                        <Fieldset legend={trans('promotion.mgt.discount')}>
                                            <RuleDiscount data={this.state.rule.discount} onChange={(e) => this.setState({rule: {...this.state.rule, discount: e}})}/>
                                        </Fieldset>
                                    );
                                case PROMOTION_RULE_TYPE.product_quantity:
                                case PROMOTION_RULE_TYPE.product_bogo:
                                    return (
                                        <Fieldset legend={trans('promotion.mgt.discount')}>
                                            <RuleDiscountRanges trans={trans} ref={(el) => this.frmQuantityCondition = el} data={this.state.rule.rangeDiscounts} channel={this.state.promotion.channel} type={type} applyForTypes={this.state.rule.applyForTypes} acrossQuantity={this.state.rule.acrossQuantity} onChange={(e) => this.setState({rule: {...this.state.rule, rangeDiscounts: e}})}/>
                                        </Fieldset>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </Fieldset>
                </div>
            );
        }
    }

    handleSaveRule = () => {
        this.setState({ errors: {} })
        savePromotionRule(this.props.match.params.id, this.state.rule, this.state.rule.priority).then(res => {
            if(!res.errorCode){
                this.loadPagePromotionRules();
                this.setState({ruleActiveTabIndex: 0, imageSrc: null});
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({ errors: res.errorObj });
            }
        });
    }

    removeRule = (id) => {
        removePromotionRule(this.props.match.params.id, id).then(res => {
            if(!res.errorCode){
                this.loadPagePromotionRules();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }
    //#endregion

    //#region COUPON
    loadPagePromotionCoupons = () => {
        getPagePromotionCoupons({promotionId: this.props.match.params.id}, this.state.couponPage, this.state.couponRows, this.state.couponSortField, this.state.couponSortOrder)
            .then(res => {
                this.setState({
                    couponList: res.content,
                    couponTotal: res.totalElements,
                    couponLoading: false,
                    couponPage: res.pageable.pageNumber,
                    couponRows: res.pageable.pageSize
                });
            });
    }

    couponActionTemplate = (rowData, column) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        return (
            <div>
                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) &&
                    <Button icon="pi pi-pencil" tooltip={trans('promotion.mgt.edit_coupon')} tooltipOptions={{position: 'top'}} className="p-button-warning" onClick={() => this.addOrEditCoupon(rowData)}/>
                }
                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_D]) &&
                    <Button icon="pi-md-close" tooltip={trans('promotion.mgt.delete_coupon')} tooltipOptions={{position: 'top'}} className="p-button-danger" onClick={() => this.removeCoupon(rowData.id)}/>
                }
            </div>
        );
    }

    onCouponPage = (e) => {
        this.setState({
            couponLoading: true,
            couponPage: e.page
        },() => this.loadPagePromotionCoupons());
    }

    onCouponSort = (e) => {
        this.setState({
            couponLoading: true,
            couponSortField: e.sortField,
            couponSortOrder: e.sortOrder
        },() => this.loadPagePromotionCoupons())
    }

    addOrEditCoupon = (data) => {
        // this.setState({
        //     couponFormData:data,
        //     couponFormDialogHeader: data?'Edit Coupon':'Add Coupon',
        //     couponFormDialogVisible:true
        // });
        this.frmPromotionCoupon.popularFormData(data, this.state.availableRules);
    }

    removeCoupon = (id) => {
        removePromotionCoupon(id).then(res => {
            if(!res.errorcode){
                this.loadPagePromotionCoupons();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }
    //#endregion

    getChannelNames = (trans, channelList) => {
        let channelNames = [];
        channelList && channelList.map(c => {
            let channelItem = CHANNEL_TYPES.find(t => t.value === c);
            if(channelItem) 
                channelNames.push(channelItem.label);
        });

        return channelNames;
    }

    updateDiscountBarContent = (newValue) => {
        this.setState({
            rule: {...this.state.rule, 
                trans: {...this.state.rule.trans, discountBarContent: newValue},
                discountBarContent: newValue
            }
        });
    }

    updateNameDisplay = (newValue) => {
        this.setState({
            rule: {...this.state.rule, 
                trans: {...this.state.rule.trans, nameDisplay: newValue}
            }
        });
    }

    onRowReorder = (e) => {
        this.setState({ ruleList: e.value });
        let arrayId = [];
        e.value.map((item) => {
            arrayId.push(item.id);
        });
        savePromotionRulePriority(arrayId, this.props.match.params.id)
    }

    getPromotion = () => {
        getPromotion(this.props.match.params.id).then(res => {
            this.setState ({
                promotionData: res
            })
        })
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        const defaultLocale = localStorage.getItem('defaultLocale');
        const enabledLocales = localStorage.getItem('enabledLocales');

        let tblRuleHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) ?
                    <Button icon="pi pi-plus" label={trans('promotion.mgt.create_promotion')} className="p-button-constrast" style={{'float':'left'}} onClick={() => this.addOrEditRule()}/>:
                    <div style={{'float':'left'}}></div>
                } 
                <div className="p-float-right">
                    <Button icon="pi pi-refresh" className="p-button-constrast p-float-right" tooltip={'Refresh'} tooltipOptions={{position: 'top'}} onClick={() => this.loadPagePromotionRules()} />
                </div>
            </div>;

        let tblCouponHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) &&
                    <Button icon="pi pi-plus" label={trans('promotion.mgt.create_coupon')} className="p-button-constrast" style={{'float':'left'}} onClick={() => this.addOrEditCoupon()}/>
                }
                <div className="p-float-right">
                    <Button icon="pi pi-refresh" className="p-button-constrast p-float-right" tooltip={'Refresh'} tooltipOptions={{position: 'top'}} onClick={() => this.loadPagePromotionCoupons()} />
                </div>
            </div>;

        return (
            <div className="p-grid">
                <PromotionForm ref={el => this.frmPromotion=el}
                    trans={trans} onSave={(data) => this.setState({promotion: data})}    
                />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            {this.state.promotion? this.state.promotion.name:''} 
                            <div className="p-toolbar-group-right">
                                <Link to={`/promotions`}>
                                    <Button icon="pi-md-keyboard-backspace" label={trans('promotion.mgt.back_to_campaign_list')} ></Button>
                                </Link>
                                {hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) &&
                                    <Button icon="pi pi-pencil" className="btn-custom-default" tooltip={trans('promotion.mgt.edit_campaign')} tooltipOptions={{position: 'top'}} style={{marginRight:'.25em'}} onClick={() => this.frmPromotion.popularFormData(this.state.promotion)}/>}
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">{trans('promotion.mgt.channel')}: {this.getChannelNames(trans,this.state.promotion.channelList).join(', ')}</div>
                            {this.state.promotion.type===PROMOTION_TYPE.promotion && <div className="p-col-12 p-md-4">{trans('promotion.mgt.allow_coupon')}: {this.state.promotion && this.state.promotion.allowCoupon?trans('promotion.mgt.yes'):trans('promotion.mgt.no')}</div>}
                            <div className="p-col-12 p-md-4">{trans('promotion.mgt.status')}: {this.state.promotion && this.state.promotion.valid?trans('promotion.mgt.valid'):trans('promotion.mgt.invalid')}</div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12">
                    <TabView activeIndex={this.state.ruleActiveTabIndex} onTabChange={(e) => this.setState({ruleActiveTabIndex: e.index})}>
                        <TabPanel header={trans('promotion.mgt.promotion_list')} leftIcon="pi pi-list">
                            <DataTable lazy={true} paginator={true} value={this.state.ruleList} header={tblRuleHeader}
                                first={this.state.rulePage * this.state.ruleRows}
                                rows={this.state.ruleRows} totalRecords={this.state.ruleTotal}
                                onPage={this.onRulePage} onSort={this.onRuleSort}
                                sortField={this.state.ruleSortField} sortOrder={this.state.ruleSortOrder}
                                responsive={true}
                                reorderableColumns onRowReorder={this.onRowReorder}
                            >
                                <Column rowReorder style={{ width: "4em" }} />
                                <Column field="id" header={trans('promotion.mgt.id')} sortable={true}/>
                                {/* {this.state.promotion.type===PROMOTION_TYPE.promotion &&                                                
                                    <Column field="priority" header={trans('promotion.mgt.priority')} sortable={true}/>} */}
                                <Column field="name" header={trans('promotion.mgt.name')} sortable={true}/>
                                {this.state.promotion.type===PROMOTION_TYPE.promotion && 
                                    [
                                        <Column field="type" header={trans('promotion.mgt.type')} body={(rowData) => translateOrderItemStatus(trans, PROMOTION_RULE_TYPES, rowData.type)} sortable={true}/>
                                        ,
                                        <Column field="exclusive" header={trans('promotion.mgt.exclusive')} body={(rowData) => rowData.exclusive?trans('promotion.mgt.yes'):trans('promotion.mgt.no')} sortable={true}/>
                                    ]
                                }
                                <Column field="valid" header={trans('promotion.mgt.status')} body={(rowData) => rowData.valid?trans('promotion.mgt.valid'):trans('promotion.mgt.invalid')} sortable={true}/>
                                <Column header="" body={this.ruleActionTemplate} style={{textAlign:'center',width:'8em'}}/>
                            </DataTable>
                        </TabPanel>
                        {/* <TabPanel header={trans('promotion.mgt.add_or_update')} headerClassName={classNames({'p-hide': !hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W])})} leftIcon="pi pi-plus" rightIcon="pi pi-pencil" className="p-padding-none"> */}
                        <TabPanel headerClassName={classNames({'p-hide': !hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W])})} leftIcon="pi pi-plus" rightIcon="pi pi-pencil" className="p-padding-none"
                            header={<span>{this.state.rule.id?trans('promotion.mgt.add'):<b>{trans('promotion.mgt.add')}</b>} {trans('promotion.mgt.or')} {!this.state.rule.id?trans('promotion.mgt.update'):<b>{trans('promotion.mgt.update')}</b>}</span>} 
                        >
                            <Fieldset legend={trans('promotion.mgt.header_information')} className="p-fieldset-padding-sm">
                                <div className="p-grid p-fluid form-group">
                                    {this.state.promotion.type===PROMOTION_TYPE.promotion &&
                                    <div className="p-col-12 p-md-3">
                                        <div className="p-grid">
                                            {/* <div className="p-col-12">
                                                <label className="p-label">* {trans('promotion.mgt.priority')}</label>
                                                <Spinner id="priority" value={this.state.rule.priority} onChange={(e) => this.setState({rule: {...this.state.rule, priority: e.target.value}})}/>
                                            </div> */}
                                            <div className="p-col-12">
                                                <label className="p-label">* {trans('promotion.mgt.type')} </label>
                                                <Dropdown id="types" value={this.state.rule.type} options={translateListSimple(trans, PROMOTION_RULE_TYPES)} onChange={(e) => this.setState({rule: {...this.state.rule, type: e.value}})} placeholder={trans('promotion.mgt.select_discount_type')}/>
                                                <div className="p-form-error">{this.state.errors.type}</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    }
                                    <div className="p-col-12 p-md-4">
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <label className="p-label">* {trans('promotion.mgt.title')} ({trans('promotion.mgt.manage')})</label>
                                                <InputText value={this.state.rule.name} onChange={(e) => this.setState({rule: {...this.state.rule, name: e.target.value}})} />
                                                <div className="p-form-error">{this.state.errors.name}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">{trans('promotion.mgt.discount_label')} ({trans('promotion.mgt.display')})</label>                                                                        
                                                <InputText value={this.state.rule.nameDisplay} onChange={(e) => this.setState({rule: {...this.state.rule, nameDisplay: e.target.value}})} />
                                                {/* <MultiLangInputText langs={enabledLocales} lang={defaultLocale} strings={this.state.rule.trans && this.state.rule.trans.nameDisplay ? this.state.rule.trans.nameDisplay : {}} onChange={this.updateNameDisplay}/> */}
                                                <div className="p-form-error">
                                                {this.state.errors.nameDisplay && enabledLocales.split(',').map(l => {
                                                    let error = this.state.errors.nameDisplay[`nameDisplay${l}`];
                                                    return error && <div>{parseMessage(trans, error.code, error.info?{count: error.info[0], lang: trans(`langs.${l}`)}:{})}</div>;
                                                })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-3 p-md-offset-1">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-padding-top-30">
                                                <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.rule.active} onChange={(e) => this.setState({rule: { ...this.state.rule, active: e.target.value}})} />
                                                <label className="p-margin-left-10 p-margin-right-30">{trans('promotion.mgt.active')}?</label>
                                            </div>
                                            <div className="p-col-12 p-padding-top-30">
                                                <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.rule.exclusive} onChange={(e) => this.setState({rule: { ...this.state.rule, exclusive: e.target.value}})} />
                                                <label className="p-margin-left-10">{trans('promotion.mgt.apply_promotion_if_matched_and_ignore_others')}?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                            
                            {this.state.rule.type && 
                            <React.Fragment>
                                {this.state.rule.type!==PROMOTION_RULE_TYPE.cart_adjustment && this.state.rule.type!==PROMOTION_RULE_TYPE.manual_order_discount && 
                                <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-20">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3 p-size-13">
                                            <h2 className="p-margin-top-10">{trans('promotion.mgt.filter')} {this.state.rule.type===PROMOTION_RULE_TYPE.product_bxgy?"("+trans('promotion.mgt.buy')+")":""}</h2>
                                            <p>{this.state.rule.type===PROMOTION_RULE_TYPE.product_bxgy?trans('promotion.mgt.filter_choose_buy_product'):trans('promotion.mgt.filter_choose_what_get_discount')}</p>
                                            <p>{trans('promotion.mgt.filter_note_to_exclude_item')}</p>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <div className="p-border-gray" style={{padding: '7.5px'}}>
                                                <RuleFilters trans={trans} errorFilter={this.state.errors.filter ? this.state.errors.filter : ''} data={this.state.rule.filters} menus={this.state.menus} categories={this.state.categories} products={this.state.products} onChange={(e) => this.setState({rule: {...this.state.rule, filters: e}})}/>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>}
                                
                                <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-20">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3 p-size-13">
                                            <h2 className="p-margin-top-10">{trans('promotion.mgt.discount')}</h2>
                                            <p>{(() => {
                                                switch(this.state.rule.type){
                                                    case PROMOTION_RULE_TYPE.cart_adjustment:
                                                    case PROMOTION_RULE_TYPE.product_adjustment:
                                                    case PROMOTION_RULE_TYPE.manual_order_discount:
                                                        return (trans('promotion.mgt.discount_select_type_and_value_promo_text'));
                                                    case PROMOTION_RULE_TYPE.product:
                                                        return '';
                                                    case PROMOTION_RULE_TYPE.product_bxgx:
                                                        return '';
                                                    case PROMOTION_RULE_TYPE.product_bxgy:
                                                        return '';
                                                    default: break;
                                                }
                                            })()}</p>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            <div className="p-border-gray" style={{padding: '7.5px'}}>
                                                <RuleDiscounts trans={trans} errorDiscount={this.state.errors ? this.state.errors : ''} data={this.state.rule.discounts} acrossQuantity={this.state.rule.acrossQuantity} type={this.state.rule.type} dishes={this.state.dishes} 
                                                    onChange={(e) => this.setState({rule: {...this.state.rule, discounts: e}})} onUpdateAcrossQuantity={(e) => this.setState({rule: {...this.state.rule, acrossQuantity: e}})}/>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>

                                <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-20">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-3 p-size-13">
                                            <h2 className="p-margin-top-10">{trans('promotion.mgt.rules')} ({trans('promotion.mgt.optional')})</h2>
                                            <p>{trans('promotion.mgt.include_aditional_conditions')} ({trans('promotion.mgt.if_necessary')})</p>
                                        </div>
                                        <div className="p-col-12 p-md-9">
                                            {this.state.rule.type!==PROMOTION_RULE_TYPE.manual_order_discount && 
                                            <Fieldset legend={trans('promotion.mgt.rule_condition')} className="p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-5">
                                                <RuleConditions trans={trans} errorCondition={this.state.errors ? this.state.errors : ''} data={this.state.rule.conditions} conditionMatchAll={this.state.rule.conditionMatchAll} dishes={this.state.dishes} categories={this.state.categories}
                                                    onChange={(e) => this.setState({rule: {...this.state.rule, conditions: e}})} onUpdateConditionMatchAll={(e) => this.setState({rule: {...this.state.rule, conditionMatchAll: e}})}/>
                                            </Fieldset>
                                            }
                                            <Fieldset legend={trans('promotion.mgt.rule_limit')} className="p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-10">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label p-w-normal">{trans('promotion.mgt.usage_limit')}</label>
                                                        <InputText keyfilter="int" value={this.state.rule.usageLimit} className="p-frames" onChange={(e) => this.setState({rule: {...this.state.rule, usageLimit: e.target.value}})}/>
                                                        <span className="p-form-error">{this.state.errors.usageLimit && <div>{parseMessage(trans, this.state.errors.usageLimit.code, this.state.errors.usageLimit.info?{count: this.state.errors.usageLimit.info[0]}:{})}</div>}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-3 p-md-offset-1">
                                                        <label className="p-label p-w-normal">{trans('promotion.mgt.valid_from')}</label>
                                                        <MaskedCalendar value={this.state.rule.startDateValue} className="p-frames" onChange={this.onRuleStartDateChange} showIcon={true} hourFormat="12" showTime={true}/>
                                                        {/* <span className="p-form-error">{this.state.errors.validFrom && <div>{parseMessage(trans, this.state.errors.validFrom.code, this.state.errors.validFrom.info?{count: this.state.errors.validFrom.info[0]}:{})}</div>}</span> */}
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <label className="p-label p-w-normal">{trans('promotion.mgt.valid_to')}</label>
                                                        <MaskedCalendar value={this.state.rule.endDateValue} className="p-frames" onChange={this.onRuleEndDateChange} showIcon={true} hourFormat="12" showTime={true}/>
                                                        <span className="p-form-error">{this.state.errors.validTo}</span>
                                                        {/* <span className="p-form-error">{this.state.errors.validTo && <div>{parseMessage(trans, this.state.errors.validTo.code, this.state.errors.validTo.info?{count: this.state.errors.validTo.info[0]}:{})}</div>}</span> */}
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </div>
                                </Fieldset>
                                <br/>
                                {/* <Panel header={trans('promotion.mgt.discount_bar_information')} toggleable>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-3 p-size-13">
                                        <h2 className="p-margin-top-10">{trans('promotion.mgt.discount_bar_setting')}</h2>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-10">
                                        <div className="p-col-12">
                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.rule.showDiscountBarOnMenu} onChange={(e) => this.setState({rule: { ...this.state.rule, showDiscountBarOnMenu: e.target.value}})} />
                                            <label className="p-margin-left-10 p-margin-right-30">{trans('promotion.mgt.show_on_menu')}?</label>
                                        </div>
                                        <div className="p-col-12">
                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.rule.showDiscountBarOnProduct} onChange={(e) => this.setState({rule: { ...this.state.rule, showDiscountBarOnProduct: e.target.value}})} />
                                            <label className="p-margin-left-10 p-margin-right-30">{trans('promotion.mgt.show_on_product')}?</label>  
                                        </div>
                                        <div className="p-col-12 p-grid">
                                            <div className="p-col-4">
                                                <label className="p-label">{trans('promotion.mgt.discount_bar_background_color')}</label>
                                                <div className="p-inputgroup">
                                                    <InputText value={this.state.rule.discountBarBackgroundColor} onChange={(e) => this.setState({rule: {...this.state.rule, discountBarBackgroundColor: e.target.value}})} />
                                                    <ColorPicker value={this.state.rule.discountBarBackgroundColor} onChange={(e) => this.setState({rule: {...this.state.rule, discountBarBackgroundColor: e.value}})} style={{ border: '1px solid #d0d0d0'}} /><br/>                           
                                                </div>
                                                <div className="p-form-error">
                                                    <span className="p-form-error">{this.state.errors.discountBarBackgroundColor && <div>{parseMessage(trans, this.state.errors.discountBarBackgroundColor.code, this.state.errors.discountBarBackgroundColor.info?{count: this.state.errors.discountBarBackgroundColor.info[0]}:{})}</div>}</span>
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <label className="p-label">{trans('promotion.mgt.discount_bar_text_color')}</label>
                                                <div className="p-inputgroup">
                                                    <InputText value={this.state.rule.discountBarTextColor} onChange={(e) => this.setState({rule: {...this.state.rule, discountBarTextColor: e.target.value}})} />
                                                    <ColorPicker value={this.state.rule.discountBarTextColor} onChange={(e) => this.setState({rule: {...this.state.rule, discountBarTextColor: e.value}})} style={{ border: '1px solid #d0d0d0'}} /><br/>                               
                                                </div>
                                                <div className="p-form-error">
                                                    <span className="p-form-error">{this.state.errors.discountBarTextColor && <div>{parseMessage(trans, this.state.errors.discountBarTextColor.code, this.state.errors.discountBarTextColor.info?{count: this.state.errors.discountBarTextColor.info[0]}:{})}</div>}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="p-col-6">
                                            <label className="p-label">{trans('promotion.mgt.discount_bar_content')}</label>
                                            <InputTextarea value={this.state.rule.discountBarContent} rows={3} cols={30} onChange={(e) => this.setState({rule: {...this.state.rule, discountBarContent: e.target.value}})} />
                                            {this.state.rule.trans && this.state.rule.trans.discountBarContent &&
                                                <MultiLangInputTextarea langs={enabledLocales} lang={defaultLocale} placeholder={trans('promotion.mgt.placeholder_insert_desc')}
                                                strings={this.state.rule.trans && this.state.rule.trans.discountBarContent ? this.state.rule.trans.discountBarContent : {}} onChange={this.updateDiscountBarContent}/>
                                            }
                                            <div className="p-form-error">
                                            {this.state.errors.barContent && enabledLocales.split(',').map(l => {
                                                let error = this.state.errors.barContent[`barContent${l}`];
                                                return error && <div>{parseMessage(trans, error.code, error.info?{count: error.info[0], lang: trans(`langs.${l}`)}:{})}</div>;
                                            })}
                                            </div>
                                        </div>
                                        </Fieldset>
                                    </div>
                                    
                                </div>
                                </Panel> */}
                            </React.Fragment>
                            }
                            <div className="p-grid">
                                <div className="p-col-12 p-margin-top-20">
                                    <Button label={trans('promotion.mgt.save')} icon="pi pi-save" style={{float: 'right'}} iconPos="left" onClick={this.handleSaveRule}/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel headerClassName={(!this.state.promotion || !this.state.promotion.allowCoupon?'p-hide':'')} header={trans('promotion.mgt.coupons')}>
                            <PromotionCouponForm ref={el => this.frmPromotionCoupon=el}
                                reloadTable={() => this.loadPromotion()}
                                promoId={this.props.match.params.id}
                                trans={trans} onSave={()=> this.loadPagePromotionCoupons()}
                            />

                            <div className="p-col-12">
                                <DataTable lazy={true} paginator={true} value={this.state.couponList} header={tblCouponHeader}
                                    first={this.state.couponPage * this.state.couponRows}
                                    rows={this.state.couponRows} totalRecords={this.state.couponTotal}
                                    onPage={this.onCouponPage} onSort={this.onCouponSort}
                                    sortField={this.state.couponSortField} sortOrder={this.state.couponSortOrder}
                                    responsive={true}>
                                    <Column field="id" header={trans('promotion.mgt.id')} sortable={true}/>
                                    <Column field="code" header={trans('promotion.mgt.code')} sortable={true}/>
                                    <Column header={trans('promotion.mgt.promotion')} body={(rowData) => rowData.promotionRule.name} />
                                    <Column field="used" header={trans('promotion.mgt.used')} sortable={true}/>
                                    <Column field="usageLimit" header={trans('promotion.mgt.limit_qty')} body={(rowData) => rowData.usageLimit?rowData.usageLimit:trans('promotion.mgt.unlimited')} sortable={true}/>
                                    <Column field="validFrom" header={trans('promotion.mgt.start_date')} sortable={true} body={(rowData) => rowData.validFrom? moment(rowData.validFrom).format('MM-DD-YYYY hh:mm A'):''}/>
                                    <Column field="validTo" header={trans('promotion.mgt.end_date')} sortable={true} body={(rowData) => rowData.validTo? moment(rowData.validTo).format('MM-DD-YYYY hh:mm A'):''} />
                                    <Column field="valid" header={trans('promotion.mgt.status')} body={(rowData) => rowData.valid?trans('promotion.mgt.valid'):trans('promotion.mgt.invalid')}/>
                                    <Column header="" body={this.couponActionTemplate} style={{textAlign:'center',width:'8em'}}/>
                                </DataTable>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}
export default withNamespaces('message')(PromotionDetails);
