import React, {useState, useImperativeHandle, forwardRef, useRef} from "react";

import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import AsyncSelect from 'react-select/async';
import { Fieldset } from "primereact/fieldset";
import { getAllUsers, getUser } from "../../constituent-management/contact/UserServices";
import { ContactSelectOption } from "../../core/components/contact/ContactSelectOption";
import { InputText } from "primereact/inputtext";
import PhoneInput from "react-phone-number-input";
import { createFrontDeskOrder } from "./BookingServices";
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { ContactForm } from "../../constituent-management/contact/ContactForm";

export const OrderForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("Create new Order")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const frmUser = useRef(null)
    const ddlUsers = useRef(null)

    let defaultCountry = localStorage.getItem('defaultCountry')
    defaultCountry = defaultCountry && defaultCountry!=='null' && defaultCountry!=='undefined' ? defaultCountry : null
    const [buyer, setBuyer] = useState(null)
    const [selectedBuyer, setSelectedBuyer] = useState(null)
    
    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader((e?'Edit':'Create new') + ' Order')
            setVisible(true)
        }
    }))

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setBuyer(null)
        setSelectedBuyer(null)
        setErrors({})
    }

    const handleSaveOrder = () => {
        createFrontDeskOrder(frmData)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('New order has been created')
                props.onSaveSuccess(res)
                closeForm()
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    setErrors(res.errorObj)
            }
        })
    }

    const loadUsers = (searchTerm, callback) => {
        getAllUsers(searchTerm)
        .then(res => {
            callback (res) 
        });
    }

    const getUserDetails = (id) => {
        getUser(id)
        .then(res => {
            setSelectedBuyer(res?res:null)
            if(res)
                popularBuyerData(res)
        })
    }

    const createNewContact = (e) => {
        ddlUsers.current.focus()
        setBuyer({value: e.id, label: e.name})
        setSelectedBuyer(e)
        popularBuyerData(e)
    }

    const popularBuyerData = (e) => {
        setFrmData({
            id: e.id,
            firstName: e.firstName,
            lastName: e.lastName,
            email: e.email,
            phone: e.phone
        })
    }

    const Option = props => ContactSelectOption(props)

    return(
        <React.Fragment>
            <ContactForm ref={frmUser}
                contact={(e) => createNewContact(e) }
            />

            <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false}>
                <div className="p-d-flex p-justify-between ">
                    <h2 className="p-margin-top-10">{header}</h2>
                    <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
                </div>

                <div className="p-sidebar-line p-mb-3"></div>

                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label className="p-label">Buyer</label>
                        <AsyncSelect ref={ddlUsers}
                            loadOptions={loadUsers} onChange={(e) => {setBuyer(e); if(e) getUserDetails(e.value)}}
                            placeholder='Type your search'
                            components={{Option}}
                            value={buyer}
                            isClearable
                        />
                        <div className="p-form-error">{errors.buyer}</div>
                        <div className="p-margin-top-15">
                            <Button label="New Buyer" icon="pi-md-person-add" className="p-button-info" onClick={(e) => frmUser.current.openForm()}/>
                        </div>
                    </div>

                    {selectedBuyer && selectedBuyer.id?
                    <React.Fragment>
                        <div className="p-fieldset-line"></div>
                        <div className="p-col-12">
                            <Fieldset legend="Buyer information">
                                <div className="p-grid form-group">
                                    <div className="p-col-4">Name:</div>
                                    <div className="p-col-8"><strong>{selectedBuyer.name}</strong></div>
                                </div>
                                <div className="p-grid form-group">
                                    <div className="p-col-4">{!selectedBuyer.email && '*'} Email:</div>
                                    <div className="p-col-8">
                                        {selectedBuyer.email?
                                        <strong>{selectedBuyer.email}</strong>
                                        :
                                        <React.Fragment>
                                            <InputText value={frmData.email} onChange={(e) => setFrmData({...frmData, email: e.target.value})}/>
                                            <span className="p-form-error">{errors.email}</span>
                                        </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="p-grid form-group">
                                    <div className="p-col-4">{!selectedBuyer.phone && '*'} Phone:</div>
                                    <div className="p-col-8">
                                        {selectedBuyer.phone?
                                        <strong>{selectedBuyer.phone}</strong>
                                        :
                                        <React.Fragment>
                                            <PhoneInput value={frmData.phone} defaultCountry={defaultCountry} onChange={(e) => setFrmData({...frmData, phone: e})} className="p-inputtext p-comment p-phoneinput"/>
                                            <span className="p-form-error">{errors.phone}</span>
                                        </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </React.Fragment>
                    :''}
                </div>

                <div className="p-sidebar-line p-my-3"></div>

                <div className="p-grid">
                    <div className="p-col-12 p-d-flex p-justify-between">
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                        <Button label="Submit" icon="pi pi-check" onClick={() => handleSaveOrder()}/>
                    </div>
                </div>
            </Sidebar>
        </React.Fragment>
    )
})