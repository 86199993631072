import React, { Component} from 'react';
// import ThroughProvider from 'react-through/lib/ThroughProvider';
import classNames from 'classnames';
import { AppTopbar } from './core/layout/AppTopbar';
import { AppFooter } from './core/layout/AppFooter';
import { AppMenu } from './core/layout/AppMenu';
import { AppBreadcrumb } from "./core/layout/AppBreadcrumb";
import { Route, Switch, Redirect } from 'react-router-dom';
import DomHandler from 'primereact/components/utils/DomHandler';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { withRouter } from 'react-router';
import './ripple.js';
import './App.scss';
import '@fullcalendar/core/main.css'

import Programs from "./crm/program/Programs";
import ProgramDetails from "./crm/program/ProgramDetails";
import {ProgramCourseSetupDetails} from "./crm/program/ProgramCourseSetupDetails";
import {MembershipTemplates} from "./crm/membership/MembershipTemplates";
import {MembershipTemplateDetails} from "./crm/membership/MembershipTemplateDetails";
import {MembershipTemplateLevelDetails} from "./crm/membership/MembershipTemplateLevelDetails";
import { ProgramAssessment } from './crm/program/ProgramAssessment';
import { ProgramAssessmentDetails } from './crm/program/ProgramAssessmentDetails';
import ProgramCopy from './crm/program/ProgramCopy';
import UsageFindTracking from './crm/program/UsageFindTracking';

import PromotionListView from "./pim/promotion/PromotionListView";
import PromotionDetails from "./pim/promotion/PromotionDetails";
import { createBrowserHistory } from "history";
import AccessDenied from './errors/access-denied/AccessDenied';
import NotFound from './errors/not-found/NotFound';
import ErrorPage from './errors/error-page/ErrorPage';
import { logOut } from './core/security/auth.jsx';
import { Participant } from './constituent-management/participant/Participant';
import { ParticipantDetails } from './constituent-management/participant/ParticipantDetails';
import { MemberScan } from './frontdesk/member-scan/MemberScan';
import { ScannerSetting } from './frontdesk/member-scan/ScannerSetting';
import { Budgets } from './crm/budget/Budgets';
import LoadingOverlay from './core/components/LoadingOverlay';
import lazyLoad from './core/components/lazyLoad';
import { Transactions } from './scp/accounting/transaction/Transactions';
import { TransactionDetails } from './scp/accounting/transaction/TransactionDetails';
import { Orders } from './pim/order/Orders';
import { OrderDetails } from './pim/order/OrderDetails';

import { GLAccounts } from './scp/accounting/gl_accounts/GLAccounts';
import { Reports } from './scp/report/Reports';
import { CRMReports } from './scp/report/CRMReports';

import { ROLES_CONFIG } from './roles';
import { Configuration } from './scp/tenant-configuration/Configuration';
import SimpleEventManagement from './scp/event-management/SimpleEventView';

import { WebErrorListView } from './web-errors/WebErrorListView';
import WebErrorDetailView from './web-errors/WebErrorDetailView';
import 'react-tippy/dist/tippy.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { TAXONOMY_TYPE } from './constants';
// import loadExtraUserData from './core/service/UserService';
// import SockJS from 'sockjs-client';
// import { Client } from '@stomp/stompjs';
import { ProgramCourseManageDetails } from './crm/program/ProgramCourseManageDetails';
import PermissionRoute from './core/security/PermissionRoute';
import  UserProfile from './constituent-management/user-profile/UserProfile';
import { NoteServices } from './constituent-management/note/NoteServices';
import WorkflowProgress from './form/workflow-progress/WorkflowProgress';
import ConnectedAppListView from './scp/connected-application/ConnectedAppListView';
import AuthRoute from './core/security/AuthRoute';

import { Dropdown } from 'primereact/dropdown';
import { Dashboard3 } from './scp/dashboard/Dashboard3';
import EventManagement from './scp/event/EventManagement';
import LiveAccountListView from './scp/event/EventLiveAccountListView';
import EventTimeListView from './scp/event/EventTimeListView';
import EventCategoryListView from './scp/event/EventCategoryListView';
import { FAQListView } from './scp/event/FAQListView';
import { loadPublicConfig } from './scp/tenant-configuration/ConfigruationServices';
import ApplicationListView from './scp/application/ApplicationListView';
import ApplicationRecordListView from './scp/application/ApplicationRecordListView';

import {ProductOptionManagement} from './pim/product_option/ProductOptionManagement'
import {ProductManagement} from './pim/product/ProductManagement'
import { TaxonListView } from './pim/taxon/TaxonListView';
import InventoryListView from './pim/inventory/InventoryListView';
import { InvoiceListView } from './pim/invoice/InvoiceListView';
import { InvoiceDetails } from './pim/invoice/InvoiceDetails';
import { AssetListView } from './pim/asset/AssetListView';
import { AssetDetails } from './pim/asset/AssetDetails';
import InventoryDetailView from './pim/inventory/InventoryDetailView';
import { AssetReportListView } from './pim/asset-schedule/AssetReportListView';

// import * as webEventService from './core/service/WebEventService';

import { BookingOrders } from './frontdesk/booking/BookingOrders';
import { BookingProcess } from './frontdesk/booking/BookingProcess';
import RoleDetails from './scp/access-management/role/RoleDetails';
import DepartmentDetailsView from './scp/departments/DepartmentDetailsView';
import {DepartmentListView} from './scp/departments/DepartmentListView';

import {CmsManagement} from './cms/CmsManagement'
import CampaignListView from './scp/campaign/CampaignListView';

import 'react-phone-number-input/style.css'
import { OperatingView } from './frontdesk/operating/OperatingView';

const AccessManagement = lazyLoad(() => import('./scp/access-management/AccessManagement'));
const ConstituentManagement = lazyLoad(() => import('./constituent-management/ConstituentManagement'));
const ImportExportManagement = lazyLoad(() => import('./scp/tool-util/upload/ImportExportManagement'));
const AttributeMangement = lazyLoad(() => import('./scp/attribute/AttributeMangement'));
const MailLingManagement = lazyLoad(() => import('./mail-management/MailLingManagement'));
const HRManagement = lazyLoad(() => import ('./crm/human_resource/HRManagement'));

class App extends Component {
    constructor() {
        super();
        this.state = {
            selectedApp: 'FEP',
            appMenu: this.createMenu('FEP'),
            appLogo: require('./assets-public/images/logo/fl_small_logo.png'),
            activeTopbarItem: null,
            layoutMode: 'static',
            mobileMenuActive: null,
            topbarMenuActive: null,
            currentRoute: null,
            menuActive: false,
            darkMenu: true,
            wsNotifications: [],
            userNotifications: [],
            refreshNotification : false
        };

        this.notificationCount = 0;

        this._history = createBrowserHistory();
        this.unlisten = this._history.listen((location, action) => {
        });

        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMobileMenuButtonClick = this.onTopbarMobileMenuButtonClick.bind(this);
        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onSidebarMouseEnter = this.onSidebarMouseEnter.bind(this);
        this.onSidebarMouseLeave = this.onSidebarMouseLeave.bind(this);
        this.onToggleMenuClick = this.onToggleMenuClick.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);

        this.noteServices = new NoteServices(); 

        this.initApp(this.state.selectedApp);
    }

    componentDidMount() {
        loadPublicConfig();
        // webEventService.initConnection();
        // webEventService.websocketEvent.on('message', (data) => showNotification('notify', 'Notify Message', data.message));
        // this.handleLoadNotification();
    }

    // stompHandle = () => {
    //     let appComponent = this;
        
    //     var wsc = new Client({
    //         brokerURL: WS_BASE_URL + '?access_token=' + `${localStorage.getItem('access_token')}`,
    //         debug: function (str) {
    //             console.log(str);
    //         },
    //         heartbeatIncoming: 4000,
    //         heartbeatOutgoing: 4000
    //     });

    //     //Fallback
    //     if (typeof WebSocket !== 'function') {
    //         wsc.webSocketFactory = function() {
    //             return new SockJS(WS_FALLBACK_BASE_URL + '?access_token=' + `${localStorage.getItem('access_token')}`);
    //         }
    //     }

    //     wsc.onConnect = function (frame) {
    //         wsc.subscribe('/topic/broadcast', function(payload) {
    //             let message = JSON.parse(payload.body);
    //             // showNotification('notify', 'Notify Message', message.body);
    //             // appComponent.setState({
    //             //     wsNotifications: message
    //             // });
    //             // this.handleNotification(message);
    //             // showUserNotification(message.body);
    //         });
    //         wsc.subscribe('/user/queue/notify', function(payload){
    //             let message = JSON.parse(payload.body);
    //             showUserNotification(message);
    //             appComponent.handleLoadNotification();
    //         });

    //         // wsc.publish({destination: "/send-user-msg", body: JSON.stringify({ title: 'user_subscribe', body: 'subscribed' })})
    //     };
        
    //     wsc.onStompError = function (frame) {
    //         // Will be invoked in case of error encountered at Broker
    //         // Bad login/passcode typically will cause an error
    //         // Complaint brokers will set `message` header with a brief message. Body may contain details.
    //         // Compliant brokers will terminate the connection after any error
    //         console.log('Broker reported error: ' + frame.headers['message']);
    //         console.log('Additional details: ' + frame.body);
    //     };
        
    //     wsc.activate();

    //     // //Load user extra data
    //     // // this.handleLoadExtraUserData();

    //     //Load Notifications of assigned user in the notification belt icon
    //     this.handleLoadNotification();
    // }

    // handleLoadNotification = () => {
    //     this.noteServices.getMyTasks().then(data => {
    //         this.setState({
    //             userNotifications : data
    //         })
    //     })
    // }

    // componentWillMount() {
    //     notificationEvent.on('USER_NOTIFICATION', this.onUserNotificationReceived);
    // }

    // onUserNotificationReceived = (message) => {
    //     // this.Dashboard2.current.getTasks();
    //     showNotification('info', 'Info Message', 'You have been assigned a new task !');
    // }

    // handleLoadNotification = () => {
    //     this.noteServices.getMyTasks().then(data => {
    //         this.setState({
    //             userNotifications : data
    //         })
    //     })
    // }

    // handleLoadExtraUserData = () => {
    //     loadExtraUserData().then(data => {
    //         this.setState({
    //             userNotifications: data.notifications
    //         });
    //     });
    // }

    // reloadUserNotification = () => {
    //     this.noteServices.getMyTasks().then(data => {
    //         this.setState({
    //             userNotifications : data
    //         })
    //     })
    // }

    handleLogout = (e, redirectTo="/login") => {
        e.preventDefault();
        logOut();
        this.props.history.push(redirectTo)
    }

    onWrapperClick(event) {
        if (!this.menuClick && !this.menuButtonClick && this.state.mobileMenuActive) {
            this.setState({mobileMenuActive: false});
        }

        if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if(!this.menuClick) {
            if(this.isHorizontal() || this.isOverlay()) {
                this.setState({
                    menuActive: false
                })
            }
        }

        this.menuClick = false;
        this.menuButtonClick = false;
        this.topbarMenuClick = false;
        this.topbarMenuButtonClick = false;
    }

    onTopbarItemClick(event) {
        this.topbarMenuClick = true;
        if (!event.toSearch && this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();

    }

    onMenuButtonClick(event) {
        this.menuButtonClick = true;

        if (this.isMobile()) {
            this.setState({mobileMenuActive: !this.state.mobileMenuActive});
        }

        event.preventDefault();
    }

    onTopbarMobileMenuButtonClick(event) {
        this.topbarMenuButtonClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        event.preventDefault();
    }

    onToggleMenuClick(event) {
        this.setState({layoutMode: (this.state.layoutMode !== 'static') ? 'static' : 'overlay'})
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onSidebarMouseEnter(event) {
        if (this.sidebarTimeout) clearTimeout(this.sidebarTimeout);
        if (this.sidebar) DomHandler.addClass(this.sidebar, 'layout-sidebar-active');
    }

    onSidebarMouseLeave(event) {
        this.sidebarTimeout = setTimeout(() => {
            if (this.sidebar) DomHandler.removeClass(this.sidebar, 'layout-sidebar-active');
        }, 250);
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onMenuItemClick(event) {
        if(!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    createMenu(app) {
        switch(app){
            default:
            case 'FEP': return [
                {label: 'Dashboard and Reports', icon: 'library_books', roles: [ROLES_CONFIG.DAR],
                    items: [
                        {label: 'Dashboard', icon: 'home', to: '/', roles: [ROLES_CONFIG.DAR_DASHBOARD]},
                        {label: 'Reports', icon: 'home', roles: [ROLES_CONFIG.DAR_RERPORT],
                            items: [
                                {label: 'Orders', icon: '', to: '/orders', roles: [ROLES_CONFIG.DAR_RERPORT_ORDER]},
                                {label: 'Invoices-Receipts', icon: '', to: '/invoices', roles: []},
                                {label: 'Participant', icon: '', to: '/participants'},
                                {label: 'Asset', icon: '', to: '/asset-report'}
                                // {label: 'Donations', icon: '', to: '/donations'},
                                // {label: 'Transactions', icon: '', to: '/transactions', roles: [ROLES_CONFIG.DAR_RERPORT_TRANSACTION]},
                                // {label: 'Predefine Reports', icon: '', to: '/reports', roles: [ROLES_CONFIG.DAR_RERPORT_RUN]},
                                // {label: 'Runtime Report', icon: '', to: '/crm-reports', roles: [ROLES_CONFIG.DAR_RERPORT_RUN]},
                            ]
                        },
                    ]
                },
                {label: 'Frontdesk Monitoring', icon: 'library_books', roles: [ROLES_CONFIG.FRONTDESK],
                    items: [
                        // {label: 'Scan', icon: '', roles: [ROLES_CONFIG.FD_MEMBER_SCAN], items: [
                        //     {label: 'Member Scan', icon: '', to: '/member-scan'},
                        // ]},
                        {label: 'Operations', icon: '', to: '/monitoring/operations', roles: [ROLES_CONFIG.FRONTDESK]},
                        {label: 'Product Booking', icon: '', to: '/monitoring/booking', roles: [ROLES_CONFIG.FRONTDESK]},
                        {label: 'Invoices-Receipts', icon: '', to: '/monitoring/invoices', roles: [ROLES_CONFIG.FRONTDESK]}
                    ]
                },
                {label: 'Constituent', icon: 'library_books', roles: [ROLES_CONFIG.CONSTITUENT],
                    items: [
                        {label: 'Contacts', icon: 'contacts', to: '/constituent-mgt/contacts', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT]},
                        // {label: 'Memberships', icon: 'library_books', to: '/constituent-mgt/memberships', roles: [ROLES_CONFIG.CONSTITUENT_MEMBERSHIP]},
                        // {label: 'Merge Duplicate', to: '/constituent-mgt/merge-duplicate', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_MERGE]},
                        // {label: 'Household', icon: 'library_books', to: '/constituent-mgt/household', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_HOUSEHOLD]},
                        // {label: 'Companies', icon: 'library_books', to: '/constituent-mgt/company', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_COMPANY]},
                        // {label: 'Teams', icon: 'library_books', to: '/constituent-mgt/teams'},
                        // {label: 'Departments', icon: 'library_books', to: '/scp/departments'},
                        // {label: 'Interests', icon: '', roles: [ROLES_CONFIG.CONSTITUENT_INTEREST], items: [
                        //     {label: 'Interest Mgt.', icon: 'library-books', to: '/constituent-mgt/interests'},
                        // ]},
                        // {label: 'Skills', icon: '', roles: [ROLES_CONFIG.CONSTITUENT_SKILL], items: [
                        //     {label: 'Talent Mgt.', icon: 'library-books', to: '/constituent-mgt/talents'},
                        //     // {label: 'Talent Mgt.', icon: 'library-books', to: '/constituent-mgt/talents_tree'},
                        // ]},
                    ]
                },
                // {label: 'Campaigns', icon: 'library_books', to: '/campaigns', roles: [ROLES_CONFIG.CAMPAIGN]},
                {label: 'Products', icon: 'library_books', roles: [ROLES_CONFIG.PRODUCT],
                    items: [
                        {label: 'Product Mgt.', icon: '', roles: [ROLES_CONFIG.PRODUCT], to: '/products'},
                        {label: 'Product Option Mgt.', icon: '', roles: [ROLES_CONFIG.PRODUCT], to:'/product-options'},
                        // {label: 'Membership', icon: '', roles: [ROLES_CONFIG.PRODUCT_MEMBERSHIP], items: [
                        //     {label: 'Membership Type Mgt.', icon: 'library-books', to: '/membership_templates'},
                        // ]},
                        // {label: 'Donation', icon: '', roles: [ROLES_CONFIG.PRODUCT_DONATION], items: [
                        //     {label: 'Donation Level Mgt.', icon: 'library-books', to: '/donation_templates'},
                        // ]},
                        // {label: 'Programs', icon: 'library_books', roles: [ROLES_CONFIG.PRODUCT_PROGRAM],
                        //     items: [
                        //         {label: 'Program Mgt.', icon: 'library-books', to: '/programs', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_DETAIL]},
                        //         {label: 'Copy Program', icon: 'library-books', to: '/programs-copy', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_COPY]},
                        //         {label: 'Fee Assistance', icon: '', to: '/fee_assistance', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_BUDGET]},
                        //         // {label: 'Category', icon: 'library-books', to: '/categories', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_CATEGORY]},
                        //         {label: 'Usage Find Tracking', icon: 'library-books', to: '/tracking'},
                        //         {label: 'Trainer Mgt.', to: '/hr-mgt/trainer'},
                        //         {label: 'Instructor Mgt.', to: '/hr-mgt/instructor'},
                        //     ]
                        // },
                        // {label: 'Events', icon: 'library_books',
                        //     items: [
                        //         {label: 'Event Mgt.', to: '/events'},
                        //         {label: 'Live Account Mgt.', to: '/live-accounts'},
                        //         {label: 'Speaker Mgt.', to: '/hr-mgt/speaker'},
                        //         {label: 'Marshaller Mgt.', to: '/hr-mgt/marshaller'},
                        //         {label: 'Time Mgt.', to: '/event-times'},
                        //         {label: 'Event Category Mgt.', to: '/event-category'},
                        //         {label: 'FAQ Mgt', to:'/faq'},
                        //     ]
                        // },
                        {label: 'Departments', icon: 'library_books', to: '/departments'},
                        {label: 'Product Category', icon: 'library-books', to: '/product-categories'},
                        // {label: 'Surveys', icon: 'library_books', roles: [ROLES_CONFIG.PRODUCT_SURVEY],
                        //     items: [
                        //         {label: 'Field Mgt.', icon: '', to: '/field/attribute-fields'},
                        //         {label: 'Survey Mgt.', icon: '', to:'/field/surveys'},
                        //     ]
                        // }
                    ]
                },
                {label: 'Assets', icon: 'library_books', roles: [ROLES_CONFIG.PRODUCT],
                    items: [
                        {label: 'Asset Mgt.', icon: 'library-books', to: '/assets-mgt'},
                        {label: 'Asset Category', icon: 'library-books', to: '/asset-categories'},
                        {label: 'Inventory Mgt', icon: 'library-books', to: '/inventories'}
                    ]
                },
                {label: 'Properties', icon : 'library_books',
                    items: [
                        {label: "Fields Attribute", icon: "library_books", roles: [ROLES_CONFIG.PROPERTIY_FIELDSET], items: [
                            {label: "Field Mgt.", icon: "", to: "/field/attribute-fields"},
                            {label: "Field Grouping", icon: "", to: "/field/attribute-sets"},
                            {label: "List Value Mgt.", icon: "", to: "/field/attribute-list"}
                        ]},
                        {label: "Applications", icon: "library_books", items: [
                            {label: "Application Mgt", icon: "", to: "/applications"},
                            {label: "Application Records", icon: "", to: "/applications/records"}
                        ]},
                        // {label: 'Calendar', icon: '', to:'/simple-event'},                        
                        // {label: 'Static Assets', icon: 'library-books', to: '/asset-management/documents', roles: [ROLES_CONFIG.PROPERTIY_ASSET]},
                        {label: 'Branch Mgt.', icon: 'library-books', to: '/branchs'},
                        {label: 'Location Mgt.', icon: 'library-books', to: '/locations'}
                    ]
                },
                {label: 'Human Resources', icon : 'library_books', 
                    items: [
                        {label: 'Marshaller Mgt.', to: '/hr-mgt/marshaller'},
                        {label: 'Trainer Mgt.', to: '/hr-mgt/trainer'},
                        // {label: 'Speaker Mgt.', to: '/hr-mgt/speaker'}
                    ]
                },
                // {label: 'Financial', icon: '', roles: [ROLES_CONFIG.FINANCIAL], items: [
                //     {label: 'Discount', icon: 'library_books', to: '/promotions', roles: [ROLES_CONFIG.FINANCIAL_DISCOUNT]},
                //     // {label: 'Financial Assistance Budget', icon: '', to: '/fee_assistance', roles: [ROLES_CONFIG.FINANCIAL_BUDGET]},
                //     // {label: 'Income Setting', icon: '', to: '/income-setting'},
                //     // {label: 'Scholarship Budget', icon: '', to: '/fee_assistance'},
                //     // {label: 'Scheduled Payment', icon: '', to: '/fee_assistance'},
                // ]},
                // {label: 'Accounting', icon: 'local_atm', roles: [ROLES_CONFIG.ACCOUNTING],
                    // items: [
                        // {label: 'GL Accounts', icon: '', to: '/gl-accounts', roles: [ROLES_CONFIG.ACCOUNTING_GLA]},
                        // {label: 'Payable Accounts', icon: '', to: '/payable-accounts', roles: [ROLES_CONFIG.ACCOUNTING_PA]},
                    // ]
                // },
                // {label: "Templates", icon: "library_books", roles: [ROLES_CONFIG.TEMPLATES],
                //     items: [
                //         {label: 'e-Mail Templates', icon: '', roles: [ROLES_CONFIG.TEMPLATES_EMAIL], to: '/mail-templates'},
                //         // {label: 'Letter Templates', icon: ''}
                //     ]
                // },
                {label: "CMS", icon: "library_books", roles: [],
                    items: [
                        {label: 'Dynamic pages', icon: '', roles: [], to: '/cms/dynamic-pages'},
                        // {label: 'Predefine pages', icon: '', roles: [], to: '/cms/predefine-page'},
                        {label: 'Menus', icon: '', roles: [], to: '/cms/menus'},
                        {label: 'Sliders', icon: '', to:'/cms/sliders'},
                        {label: 'Block contents', icon: '', to: '/cms/block-contents'},
                        {label: 'Documents', icon: 'library-books', to: '/cms/documents'}
                    ]
                },
                {label: 'System Configuration', icon: 'developer_board', roles: [ROLES_CONFIG.SYSTEM],
                    items: [
                        {label: 'Configurations', icon: 'pi pi-fw pi-cog', to: '/configurations', roles: [ROLES_CONFIG.SYSTEM_CONFIGURATION]},
                        {label: 'SMTP Setup', icon: 'mail', to: '/mail-mgt/accounts', roles: [ROLES_CONFIG.SYSTEM_SMTP]},
                        {label: 'e-Mail Templates', icon: '', roles: [ROLES_CONFIG.TEMPLATES_EMAIL], to: '/mail-mgt/templates'},
                        // {label: 'Mass e-Mail Tools', icon: 'library_books', to: '/mail-mgt/maillist'},
                        // {label: 'Payment Gateways', icon: 'pi pi-fw pi-cog', to: '/payment-gateways'},
                        // {label: 'Child Abuse/Offender Setup', icon: 'pi pi-fw pi-cog', to: '/child-abuse-list'}
                        // {label: 'Access Control', icon: 'library_books', roles: [ROLES_CONFIG.SYSTEM_ACL,ROLES_CONFIG.SUPER_ADMIN],
                        //     items: [
                        //         {label: "Users", icon: 'contacts', to: '/access-mgt/users', roles: [ROLES_CONFIG.SYSTEM_ACL_USER,ROLES_CONFIG.SUPER_ADMIN]},
                        //         {label: "Roles", icon: 'library_books', to: '/access-mgt/roles', roles: [ROLES_CONFIG.SYSTEM_ACL_ROLE,ROLES_CONFIG.SUPER_ADMIN]},
                        //         {label: "Permissions", icon: 'library_books', to: '/access-mgt/permissions', roles: [ROLES_CONFIG.SUPER_ADMIN]}, 
                        //     ]
                        // },
                        // {label: 'Scanner Setting', icon: 'pi pi-fw pi-cog', to: '/scanner-setting', electronOnly: true},
                        // {label: 'Import and Export', icon: 'import_export', roles: [ROLES_CONFIG.SYSTEM_IAE],
                        //     items: [
                        //         {label: 'Import Data', icon: 'cloud_upload',
                        //             items: [
                        //                 {label: 'Contacts', icon: 'cloud_upload', to: '/import-export/import/contacts', roles: [ROLES_CONFIG.SYSTEM_IAE_IMPORT_CONTACT]},
                        //                 {label: 'Courses', icon: 'cloud_upload', to: '/import-export/import/course', roles: [ROLES_CONFIG.SYSTEM_IAE_IMPORT_COURSE]}
                        //             ]
                        //         },
                        //         // {label: 'Export Data', icon: 'cloud_download', roles: [ROLES_CONFIG.EXPORT_V]}
                        //     ]
                        // },
                        // {label: 'Connected Application', icon: "pi-md-settings-applications", to: '/connected-app', roles: [ROLES_CONFIG.SYSTEM_APPAPI]},
                        // {label: 'Web Errors', icon: 'pi pi-info', to: '/web-errors', roles: [ROLES_CONFIG.SUPER_ADMIN]},
                    ]
                }
            ];

            case 'FORM': return [
            ];
        }
    }

    changeTheme(theme) {
        this.changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    changeLayout(theme) {
        this.changeStyleSheetUrl('layout-css', theme, 'layout');
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    isMobile() {
        return window.innerWidth <= 1024;
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    componentWillUnmount() {
        this.unlisten();
        // notificationEvent.remove('USER_NOTIFICATION', this.onUserNotificationReceived);
    }

    showNotification = (type, message) => {
        this.notification.show({severity: type, summary: type==='success'?'Success Message':'Error Message', detail: message});
    }

    initApp(app) {
        switch(app){
            case 'FEP':
            default:
                this.setState({
                    selectedApp: app,
                    appLogo: 'assets/fromlabs/fl_small_logo.png',
                    appMenu: this.createMenu(app)
                });
                // this._history.push("/");
                break;
            case 'SNS':
                this.setState({
                    selectedApp: app,
                    appLogo: 'assets/fromlabs/sns_small_logo.png',
                    appMenu: this.createMenu(app)
                });
                this._history.push("/sns");
                break;
        }
    }

    handleChangeApp = e => {
        if(e.value !== this.state.selectedApp){
            this.initApp(e.value)
        }
    }

    render() {
        let wrapperClass = classNames('layout-wrapper', {
                                        'layout-wrapper-static': this.state.layoutMode === 'static',
                                        'layout-wrapper-active': this.state.mobileMenuActive,
                                        'layout-menu-horizontal': this.state.layoutMode === 'horizontal'
                                    });
        let sidebarClassName = classNames("layout-sidebar",{'layout-sidebar-dark': this.state.darkMenu});
        const appList = [
            {label: 'FEP', value: 'FEP'},
            // {label: 'FORM', value: 'FORM'},
        ];
        const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}
                    onMouseEnter={this.onSidebarMouseEnter} onMouseLeave={this.onSidebarMouseLeave}>

                    <div className="sidebar-logo">
                        <a>
                            <img className="app-logo" alt="logo" src={this.state.appLogo} />
                            <span className="app-name"> {this.state.selectedApp}{appList.length > 1?<Dropdown style={{width:'42px'}} options={appList} onChange={this.handleChangeApp}></Dropdown>:''}</span>
                        </a>
                        <a className="sidebar-anchor" title="Toggle Menu" onClick={this.onToggleMenuClick}> </a>
                    </div>

                    <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height:'100%'}}>
                        <div className="layout-menu-container" >
                            <AppMenu model={this.state.appMenu} onRootMenuItemClick={this.onRootMenuItemClick} layoutMode={this.state.layoutMode}
                                    active={this.state.menuActive} onMenuItemClick={this.onMenuItemClick}/>
                        </div>
                    </ScrollPanel>
                </div>
                <div className="layout-main">
                    <AppTopbar profileName={localStorage.getItem('fullname')} layoutMode={this.state.layoutMode} activeTopbarItem={this.state.activeTopbarItem} onTopbarItemClick={(e) => this.onTopbarItemClick(e)}
                            onMenuButtonClick={this.onMenuButtonClick} onTopbarMobileMenuButtonClick={this.onTopbarMobileMenuButtonClick}
                            topbarMenuActive={this.state.topbarMenuActive} onLogout={this.handleLogout} wsNotifications={this.state.wsNotifications} userNotifications={this.state.userNotifications}
                            reloadUserNotification={this.reloadUserNotification}/>

                    <AppBreadCrumbWithRouter onLogout={this.handleLogout} />                    
                    <WorkflowProgress />
                    <div className="layout-content">
                        <LoadingOverlay />
                        <Switch>
                            <PermissionRoute path="/" exact component={Dashboard3} roles={[ROLES_CONFIG.DAR_DASHBOARD_R]}/>
                            <AuthRoute path="/500" component={ErrorPage} />
                            <AuthRoute path="/404" component={NotFound} />
                            <AuthRoute path="/401" component={AccessDenied} />

                            {/* <Route path="/access-mgt" component={AccessManagement} /> */}
                            <Route path="/constituent-mgt" component={ConstituentManagement} />
                            <Route path="/hr-mgt" component={HRManagement}/>
                            <Route path="/cms" component={CmsManagement}/>
                            <Route path="/mail-mgt" component={MailLingManagement}/>

                            <Route path="/import-export" component={ImportExportManagement} roles={[ROLES_CONFIG.SYSTEM_IAE_IMPORT, ROLES_CONFIG.EXPORT_V]} />

                            <Route path="/monitoring/operations" component={OperatingView} roles={[ROLES_CONFIG.FRONTDESK]}/>
                            <Route path="/monitoring/booking/:orderNumber" component={BookingProcess} roles={[ROLES_CONFIG.FRONTDESK]}/>
                            <Route path="/monitoring/booking" component={BookingOrders} roles={[ROLES_CONFIG.FRONTDESK]}/>
                            <Route path="/monitoring/invoices" component={InvoiceListView} roles={[ROLES_CONFIG.FRONTDESK]}/>

                            <Route path="/campaigns" component={CampaignListView}/>

                            <Route path={`/departments/:dId/role/:id`} name="Role Details View" render={(props) => <RoleDetails {...props} type='department' />}/>
                            <Route path={`/departments/:id`} name="Department Details View" component={DepartmentDetailsView}/>
                            <Route path={`/departments`} name="Department View" component={DepartmentListView}/>

                            <Route path="/membership_templates/:id/levels/:lid" name="Membership Level Details" component={MembershipTemplateLevelDetails}/>
                            <Route path="/membership_templates/:id" name="Membership Group Details" component={MembershipTemplateDetails}/>
                            <Route path="/membership_templates" name="Membership Group Management" component={MembershipTemplates}/>
                            <Route path="/programs/:pid/courses/:cid/manage/assessment/:aid" component={ProgramAssessmentDetails}/>
                            <Route path="/programs/:pid/courses/:cid/manage/assessment" component={ProgramAssessment}/>
                            <Route path="/programs/:pid/courses/:cid/manage" name="Course Manage" component={ProgramCourseManageDetails}/>
                            <Route path="/programs/:pid/courses/:cid/setup" name="Course Details" component={ProgramCourseSetupDetails}/>
                            <Route path="/programs/:id" name="Program Details" component={ProgramDetails}/>
                            <Route path="/programs" name="Program Management" component={Programs} roles={[ROLES_CONFIG.PRODUCT_PROGRAM_DETAIL_R]}/>
                            <Route path="/programs-copy" name="Copy Program" component={ProgramCopy} roles={[ROLES_CONFIG.PRODUCT_PROGRAM_DETAIL_R]}/>
                            <Route path="/fee_assistance" component={Budgets}/>

                            <Route path="/products" component={ProductManagement}/>
                            <Route path="/product-options" component={ProductOptionManagement}/>
                            
                            <Route path="/tracking" component={UsageFindTracking}/>

                            <Route path="/product-categories" render={props => <TaxonListView {...props} type={TAXONOMY_TYPE.category.value} secondaryType={TAXONOMY_TYPE.product.value} />}/>
                            <Route path="/asset-categories" render={props => <TaxonListView {...props} type={TAXONOMY_TYPE.category.value} secondaryType={TAXONOMY_TYPE.asset.value} />}/>
                            <Route path="/locations" render={props => <TaxonListView {...props} type={TAXONOMY_TYPE.location.value} />}/>
                            <Route path="/branchs" render={props => <TaxonListView {...props} type={TAXONOMY_TYPE.branch.value} />}/>

                            <Route path="/assets-mgt/:id" component={AssetDetails} />
                            <Route path="/assets-mgt" component={AssetListView} />

                            <Route path="/promotions/:id" name="Promotion Details" component={PromotionDetails}/>
                            <Route path="/promotions" name="Promotion Management" component={PromotionListView}/>
                            
                            <Route path="/participants/:id" component={ParticipantDetails} />
                            <Route path="/participants" component={Participant} />
                            <Route path="/field" component={AttributeMangement} />
                            <Route path="/member-scan" component={MemberScan} />
                            <Route path="/invoices/:id" component={InvoiceDetails} />
                            <Route path="/invoices" component={InvoiceListView} />
                            <Route path="/scanner-setting" component={ScannerSetting} />
                            <Route path="/simple-event" component={SimpleEventManagement}/>
                            <Route path="/profile" component={UserProfile}/>
                            
                            <Route path="/transactions/:id" component={TransactionDetails} />
                            <PermissionRoute path="/transactions" component={Transactions} roles={[ROLES_CONFIG.DAR_RERPORT_TRANSACTION_R]}/>
                            <PermissionRoute path="/orders/:id" component={OrderDetails} roles={[ROLES_CONFIG.DAR_RERPORT_ORDER_R]}/>
                            <PermissionRoute path="/orders?state=:orderState" component={Orders} roles={[ROLES_CONFIG.DAR_RERPORT_ORDER_R]}/>
                            <PermissionRoute path="/orders" component={Orders} roles={[ROLES_CONFIG.DAR_RERPORT_ORDER_R]}/>
                            <Route path="/gl-accounts" name = "GL Accounts" component={GLAccounts}/>
                            <PermissionRoute path="/reports" name="Reports" component={Reports} roles={[ROLES_CONFIG.DAR_RERPORT]}/>
                            <PermissionRoute path="/crm-reports" name = "CRM Reports" component={CRMReports} roles={[ROLES_CONFIG.DAR_RERPORT]}/>

                            <Route path="/web-errors/:id" name = "Web Errors" component={WebErrorDetailView}/>
                            <Route path="/web-errors" name = "Web Errors" component={WebErrorListView}/>
                            <Route path="/connected-app" name = "Connected Application" component={ConnectedAppListView} />

                            <Route path="/events" component={EventManagement} />
                            <Route path="/live-accounts" component={LiveAccountListView} />
                            <Route path="/event-times" component={EventTimeListView} />
                            <Route path="/event-category" component={EventCategoryListView} />
                            <Route path="/faq" component={FAQListView} />
                        
                            <Route path="/applications/records" component={ApplicationRecordListView} />
                            <Route path="/applications" component={ApplicationListView} />

                            <Route path="/inventories/:id" component={InventoryDetailView} />
                            <Route path="/inventories" component={InventoryListView} />

                            <Route path="/configurations" component={Configuration}/>

                            <Route path="/asset-report" component={AssetReportListView}/>

                            <Redirect from='*' to='/404' />
                        </Switch>
                    </div>

                    <AppFooter />

                    {this.state.mobileMenuActive && <div className="layout-main-mask"></div>}
                </div>
            </div>
        );
    }
}

export default App;
