import React, {Component} from 'react';
import moment from 'moment';
import {InputText} from 'primereact/inputtext';
import {Spinner} from 'primereact/spinner';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Fieldset} from "primereact/fieldset";
import {InputSwitch} from 'primereact/inputswitch';
import { generatingCouponCode, savePromotionCoupon } from './PromotionService';
import { showNotification } from '../../core/service/NotificationService';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { Sidebar } from 'primereact/sidebar';

export class PromotionCouponForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            coupon : {
                id: '',
                code: '',
                rule: '',
                usageLimit: '0',
                validFrom: '',
                validTo: '',
                active: true
            },
            rules: [],
            startDateValue: '',
            endDateValue: '',
            errors: {}
        }
    }

    popularFormData = (data, rules) => {
        const trans = this.props.trans;
        this.setState({
            coupon: {
                id: data?data.id:'',
                promoId: this.props.promoId,
                code: data?data.code:'',
                ruleId: data?data.promotionRule.id:'',
                usageLimit: data?!data.usageLimit?0:data.usageLimit:'',
                validFrom: data && data.validFrom?data.validFrom:'',
                validTo: data && data.validTo?data.validTo:'',
                active: data?data.active:true
            },
            startDateValue: data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : '',
            endDateValue: data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : '',
            rules: rules,
            formHeader: data?trans('promotion.mgt.edit_coupon'):trans('promotion.mgt.add_coupon'),
            visible: true,
            errors: {}
        });
    }

    generatingCode = () => {
        generatingCouponCode().then(res => {
            this.setState({coupon: {...this.state.coupon, code: res?res:''}});
        });
    }

    onStartDateChange = (e) => {
        this.setState({
            coupon: {...this.state.coupon, validFrom: moment(e.value).format('YYYY-MM-DD HH:mm')},
            startDateValue: e.value
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            coupon: {...this.state.coupon, validTo: moment(e.value).format('YYYY-MM-DD HH:mm')},
            endDateValue: e.value
        });
    }

    handleSaveCoupon = () => {
        savePromotionCoupon(this.state.coupon).then(res => {
            if(!res.errorCode){
                this.props.reloadTable();                
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode === 400) this.setState({errors: res.errorObj})
            }
        });
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){
        const trans = this.props.trans;

        return (
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend={trans('promotion.mgt.header_information')}>
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">* {trans('promotion.mgt.coupon_code')}: </label>
                                    <div className="p-inputgroup">
                                        <InputText id="code" placeholder="Insert code" value={this.state.coupon.code} onChange={(e) => this.setState({coupon: {...this.state.coupon, code:e.target.value}})}/>
                                        <Button label="Generate Code" className="p-button-info" onClick={this.generatingCode}/>
                                    </div>
                                    <div className="p-form-error">{this.state.errors.code}</div>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">* {trans('promotion.mgt.promotion')}: </label>
                                    <Dropdown id="rules" value={this.state.rules.find((el) => el.id === this.state.coupon.ruleId)} options={this.state.rules} onChange={(e) => this.setState({coupon: {...this.state.coupon, ruleId: e.value.id}})} style={{width:'100%'}} dataKey="id" optionLabel="name" placeholder={trans('promotion.mgt.pick_promotion')}/>
                                    <div className="p-form-error">{this.state.errors.rule}</div>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    <div className="p-col-12">
                        <Fieldset legend={trans('promotion.mgt.usage_setting')}>
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12 p-md-6">
                                    <label>{trans('promotion.mgt.start_date')}</label>
                                    <MaskedCalendar value={this.state.startDateValue} onChange={this.onStartDateChange} showIcon={true} hourFormat="12" showTime={true} hideOnDateTimeSelect={true}/>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label>{trans('promotion.mgt.end_date')}</label>
                                    <MaskedCalendar value={this.state.endDateValue} onChange={this.onEndDateChange} showIcon={true} hourFormat="12" showTime={true} hideOnDateTimeSelect={true}/>
                                </div>
                                <div className="p-col-12">
                                    <label>{trans('promotion.mgt.usage_limit')}</label>
                                    <Spinner value={this.state.coupon.usageLimit} onChange={(e) => this.setState({coupon: {...this.state.coupon, usageLimit: e.target.value}})}/>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">{trans('promotion.mgt.active')}?</label>
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.coupon.active} onChange={(e) => this.setState({coupon: { ...this.state.coupon, active: e.target.value}})} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label={trans('promotion.mgt.submit')} icon="pi pi-check" onClick={()=>this.handleSaveCoupon()}/>
                        <Button label={trans('promotion.mgt.cancel')} icon="pi-md-close" className="p-button-secondary" onClick={()=>this.onHide()}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}