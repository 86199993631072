import React, { Component } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Redirect, Link } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgramServices } from './ProgramServices';
import { withNamespaces } from 'react-i18next';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { Chips } from 'primereact/chips';
import { Checkbox } from 'primereact/checkbox';
import { Spinner } from 'primereact/spinner';
import { Calendar } from "primereact/calendar";
import { DepartmentServices, getListDepartments } from '../../scp/departments/DepartmentServices';
import { AttributeServices, getListAttributeSets } from '../../scp/attribute/AttributeServices';
import { GLAccountServices } from '../../scp/accounting/gl_accounts/GLAccountServices';
import { BudgetServices } from '../budget/BudgetServices';
import { PROG_WORK_TYPES, PROGRAM_WORK_TYPE, HR_RESOURCE_TYPE, PROPERTY_TYPE } from '../../constants';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { showNotification } from '../../core/service/NotificationService';
import { CloneProgramForm } from './CloneProgramForm';
import { TalentServices } from '../../constituent-management/talent/TalentServices';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { DepartmentForm } from '../../scp/departments/DepartmentForm';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import './ProgramTipInfo.js'
import Tippy from '@tippy.js/react';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import ProgramTipInfo from './ProgramTipInfo.js';
import { getListGrades } from '../../scp/tenant-configuration/ConfigruationServices';
import { getListHRs } from '../human_resource/HRServices';

const view = {
    program: 'program',
    course: 'course'
}

class Programs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewMode: view.program,
            isAdd: false,
            goToDetail: '',
            dataList: [],
            dataCoureList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            programFormDialogVisible: false,
            programWizardFormDialogVisible: false,
            filter: {
                viewType: 'all',
                name: '',
                workType: '',
                departmentId: '',
                categoryId: '',
                moreFilter: false,
                type:'program',
                status: ' ',
                campaignId: '',
                code:'',
            },
            program: {
                allowOnlineRegistration: true,
                showOnWeb: true,
                showNotAvailable: true,
                type: 'program',
                workType: PROGRAM_WORK_TYPE.simple,
                weight: 0,
                minAgeYear: '0',
                minAgeMonth: '0',
                maxAgeYear: '0',
                maxAgeMonth: '0',
                tags: [],
                calendars: []
            },
            skills: [],
            errors: {},
            activeCampaigns: [],
            campaigns: [],
            calendars: [],
        };

        // this.self = React.createRef();
        this.programServices = new ProgramServices();
        this.departmentServices = new DepartmentServices();
        this.attributeServices = new AttributeServices();
        this.glAccountServices = new GLAccountServices();
        this.budgetServices = new BudgetServices();
        this.talentServices = new TalentServices();
        this.campaignServices = new CampaignServices();
    }

    componentDidMount() {
        let querystring = require("query-string");
        let paramsUrl = querystring.parse(this.props.location.search);
        if(Object.keys(paramsUrl).length > 0){
            this.setState({
                filter: {
                    ...this.state.filter,
                    name: paramsUrl.name
                }
            },() => {
                this.getPagePrograms();
            })
        }else{
            this.getPagePrograms();
        }
        this.loadAllCalendar();
        this.loadAllDepartments();
        this.loadAllFilterCategories();
        // this.getListAttributeSet('program');
        this.getListAttributeSet('custom_field');
        this.getListAttributeSet('waiver');
        // this.loadAllGLAccounts('Cash');
        // this.loadAllGLAccounts('Receivable');
        // this.loadAllGLAccounts('Deferred');
        // this.loadAllGLAccounts('Program');
        // this.loadAllGLAccounts('Assistance');
        // this.loadAllGrades();
        // this.loadAllTalents();
        this.loadAllCampaigns();
        this.loadHRs(HR_RESOURCE_TYPE.marshaller.value);
        this.loadHRs(HR_RESOURCE_TYPE.trainer.value);
        this.loadProperties(PROPERTY_TYPE.branch.value);
        this.loadProperties(PROPERTY_TYPE.location.value);
    }

    getPagePrograms = () => {
        showloading();
        this.programServices.getPagePrograms(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                stoploading();
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
    }
    
    loadAllDepartments = () => {
        getListDepartments().then(res => this.setState({ departments: res }))
    }

    loadAllFilterCategories = () => {
        // getCategories({departmentId: dept}).then(res => this.setState({ filterCategories: res }));
    }

    loadAllDeptCategories = () => {
        // getCategories({departmentId: dept}).then(res => this.setState({ categories: res }));
    }

    loadHRs = (type) => {
        getListHRs({type: type})
        .then(res => {
            switch (type) {
                case HR_RESOURCE_TYPE.marshaller.value:
                    this.setState({ instructors: res })
                    break;
                case HR_RESOURCE_TYPE.trainer.value:
                    this.setState({ trainers: res })
                    break;
                default: break;
            }
        })
    }

    loadProperties = () => {
        // getListProperties({type: type})
        // .then(res => {
        //     switch (type) {
        //         case PROPERTY_TYPE.location.value:
        //             this.setState({ locations: res })
        //             break;
        //         case PROPERTY_TYPE.branch.value:
        //             this.setState({ branchs: res })
        //             break;
        //         default: break;
        //     }
        // });
    }

    loadAllProgramAttributeSet = () => {
        getListAttributeSets('program')
            .then(res => {
                res.unshift({ value: null, label: 'Not use' });
                this.setState({ attrSets: res });
            });
    }

    loadAllGrades = () => {
        getListGrades()
            .then(res => {
                this.setState({ grades: res });
            });
    }

    getListAttributeSet = (type) => {
        getListAttributeSets(type)
            .then(res => {
                res.unshift({ value: null, label: 'Not use' });
                switch (type) {
                    case 'program':
                        this.setState({ attrSets: res });
                        break;
                    case 'custom_field':
                        this.setState({ customFieldSets: res });
                        break;
                    case 'waiver':
                        this.setState({ waiverFieldSets: res });
                        break;
                    default: break;
                }
            });
    }

    loadAllDeptFeeAssistances = (dept) => {
        if(dept){
            this.budgetServices.getListBudgetOptionGroups('department', dept)
            .then(res => {
                this.setState({feeAssistances: res});
            });
        }else{
            this.setState({feeAssistances: []});
        }
    }

    loadAllGLAccounts = (type) => {
        this.glAccountServices.getAccountList(null, type)
            .then(res => {
                res.unshift({ value: null, label: 'Not use' });

                switch (type) {
                    case 'Cash':
                        this.setState({ cashGLAccounts: res });
                        break;
                    case 'Receivable':
                        this.setState({ receivableGLAccounts: res });
                        break;
                    case 'Deferred':
                        this.setState({ deferredGLAccounts: res });
                        break;
                    case 'Program':
                        this.setState({ programGLAccounts: res });
                        break;
                    case 'Assistance':
                        this.setState({ scholarshipGLAccounts: res });
                        break;
                    default: break;
                }
            });
    }

    loadListSkillDept = (id) => {
        this.departmentServices.getDepartmentTalentsLevels(id)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({ skills: res });
                }
            });
    }

    loadAllTalents = () => {
        this.talentServices.getTalentsLevels()
            .then(res => {
                if (!res.errorCode) {
                    this.setState({ filterTalents: res });
                }
            });
    }

    loadAllCampaigns = () => {
        const inactive = false;
        const active = true;
        this.campaignServices.getListCampaign(active).then(res => { this.setState({ activeCampaigns: res ? res : [] }) });
        this.campaignServices.getListCampaign(inactive).then(res => { this.setState({ campaigns: res ? res : [] }) });
	}

    loadAllCalendar = () => {
        this.programServices.getCalendars()
            .then(res => { this.setState({ calendars: res ? res : [] }) });
    }

    displayDefaulCalendar = () => {
        let data = this.state.program.calendars;
        let arrayHolder = [];
        for (let i = 0; i < data.length; i++) {
            arrayHolder.push(data[i].value)
        }
        return (
            arrayHolder
        )
    }

    onChangeCalendar = (data) => {
        let changeCalendars = [];
        let calendars = this.state.calendars;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < calendars.length; j++) {
                if (data[i] === calendars[j].value) {
                    changeCalendars.push(calendars[j])
                }
            }
        }
        this.setState({
            program: { ...this.state.program,
                calendars: changeCalendars }
        })
    }

    actionTemplate = (rowData) => {
        let viewModeStr = titleCaseText(this.state.viewMode);
        let manageUrl, tooltipModeStr;
        let dropActions = [
            // { label: 'Copy '+ viewModeStr, icon: 'pi pi-clone p-size-20', command: (e) =>  this.cloneProgram.popularState(rowData) },
            { label: 'Delete '+viewModeStr, icon: 'pi-md-close', command: () => this.deleteNotify.delete(rowData.id)}
        ];
        if(this.state.viewMode === 'program'){
            manageUrl = `/programs/${rowData.id}`;
            tooltipModeStr = 'Manage Program';
        } else {
            manageUrl = `/programs/${rowData.parentId}/courses/${rowData.id}/manage`;
            tooltipModeStr = 'Manage Course';
            dropActions.unshift({
                label: 'Course Setting ',
                icon: 'pi-md-settings',
                command: () => {
                    this.props.history.push(`/programs/${rowData.parentId}/courses/${rowData.id}/setup`)
                }
            })
        }
        return <SplitButton label="Manage" className="p-button-constrast" model={dropActions} tooltip={tooltipModeStr} tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(manageUrl)}/>
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.getPagePrograms();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.getPagePrograms();
        });
    }

    onFilterDepartmentChange = (e) => {
        this.setState({
            filter: { ...this.state.filter, departmentId: e.value, categoryId: '' }
        }, () => this.applyFilter());

        this.loadAllFilterCategories(e.value);
    }

    applyFilter = () => {
        this.setState({
            page: 0
        },() => {
            this.getPagePrograms();
        })
    }

    clearFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                name: '',
                workType: '',
                departmentId: '',
                categoryId: '',
                talent: '',
                status:'',
                campaignId: '',
                code:''
            }
        }, () => { this.applyFilter() });
    }

    onChagePageLength = l => {
        this.setState({
            rows: l,
            page: 0
        }, () => {
            this.applyFilter();
        })
    }

    removeProgram = (id) => {
        this.programServices.removeProgram(id)
            .then(res => {
                if (!res.errorCode) {
                    this.getPagePrograms();
                    showNotification('success', 'Success Message', 'Action Submitted');
                }
            })
    }

    addProgram = (type) => {
        this.setState({
            isAdd: true,
            program: { ...this.state.program, workType: type }
        });
    }

    onDepartmentChange = (e) => {
        let dept = e.value;
        this.loadAllDeptCategories(dept);
        this.loadListSkillDept(dept);
        // this.loadAllDeptFeeAssistances(dept);

        this.setState({
            program: {
                ...this.state.program,
                departmentId: dept,
                instructorIds: [],
                feeAssistances: '',
                enabledTalents: []
            }
        });
    }

    onRegStartDateChange = (e) => {
        this.setState({
            program: { ...this.state.program, regStartDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '') },
            regStartDateValue: e.value
        });
    }

    onRegEndDateChange = (e) => {
        this.setState({
            program: { ...this.state.program, regEndDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '') },
            regEndDateValue: e.value
        });
    }

    onMemberRegStartDateChange = (e) => {
        this.setState({
            program: { ...this.state.program, memberRegStartDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '') },
            memberRegStartDateValue: e.value
        });
    }

    onMemberRegEndDateChange = (e) => {
        this.setState({
            program: { ...this.state.program, memberRegEndDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '') },
            memberRegEndDateValue: e.value
        });
    }

    handleSaveProgram = () => {
        this.programServices.saveProgram(this.state.program).then(res => {
            if (!res.errorCode) {
                this.setState({ goToDetail: res });
            } else {
                showNotification('error', 'Error Message', res.errorMessage);
                if (res.errorCode === 400)
                    this.setState({ errors: res.errorObj });
            }
        });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.applyFilter();
        }
    }

    onChangeSelect = (values) => {
        let termArr = [];
        if (values) {
            values.map((item) => {
                termArr.push(item.value);
            })
            this.setState({
                program: {
                    ...this.state.program,
                    enabledTalents: termArr
                }
            })
        }
    }

    onChangeSwitchRegistration = (e) => {
        this.setState({
            program: {
                ...this.state.program,
                allowOnlineRegistration: e.value,
                showOnWeb: e.value ? true : this.state.program.showOnWeb
            }
        })
    }

    onClickMoreOrLessFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                talents: '',
                moreFilter: this.state.filter.moreFilter ? false : true
            }
        });
    };

    onCreateNewDept = (data) => {
        let tempArr = this.state.departments ? [...this.state.departments] : [];
        tempArr.push({label: data.name, value: data.id});
        this.setState({
            program: {...this.state.program,
                departmentId: data.id
            },
            departments: tempArr,
        })
    }

    onCreateNewCategory = (data) => {
        this.setState({
            program:{...this.state.program,
                departmentId: data.department ? data.department.id : '',
                categoryId: data.id
            },
        },() =>{
            this.loadAllDeptCategories(data.department.id);
        })
    }

    onBasicPriceGLAccountChange = (e) => {
        this.setState({ program: { ...this.state.program, 
                basicPriceGLAccount: e.value,
                membershipPriceGLAccount: this.state.program.membershipPriceGLAccount?this.state.program.membershipPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.program.staffPriceGLAccount?this.state.program.staffPriceGLAccount:e.value
            }
        })
    }

    onMembershipPriceGLAccountChange = (e) => {
        this.setState({ program: { ...this.state.program, 
                membershipPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.program.basicPriceGLAccount?this.state.program.basicPriceGLAccount:e.value,
                staffPriceGLAccount: this.state.program.staffPriceGLAccount?this.state.program.staffPriceGLAccount:e.value
            }
        })
    }

    onStaffPriceGLAccountChange = (e) => {
        this.setState({ program: { ...this.state.program, 
                staffPriceGLAccount: e.value,
                basicPriceGLAccount: this.state.program.basicPriceGLAccount?this.state.program.basicPriceGLAccount:e.value,
                membershipPriceGLAccount: this.state.program.membershipPriceGLAccount?this.state.program.membershipPriceGLAccount:e.value
            }
        })
    }

    onAddOrEditImage = (data, index) => {
        this.setState({
            tmpImage: {
                url: data?data:'',
                index: index
            },
            errors: {...this.state.errors,
                image: ''
            },
            isAddOrEditImage: true
        })
    }

    onSubmitImage = () => {
        let tmpArr = this.state.program.images?[...this.state.program.images]:[]
        let tmpImage = {...this.state.tmpImage}

        if(!tmpImage.url){
            this.setState({
                errors: {...this.state.errors,
                    image: 'Field is required'
                }
            })
            return
        }

        if(tmpImage.index || tmpImage.index === 0){
            tmpArr.splice(tmpImage.index, 1, tmpImage.url)
        }else{
            tmpArr.push(tmpImage.url)
        }
        this.setState({
            program: {...this.state.program,
                images: tmpArr
            },
            tmpImage: {},
            isAddOrEditImage: false
        })
    }

    onRemoveImage = (index) => {
        let tmpArr = [...this.state.program.images]
        tmpArr.splice(index, 1)
        this.setState({
            program: {...this.state.program,
                images: tmpArr
            }
        })
    }

    onCancelImage = () =>{
        this.setState({
            tmpImage: { url: '' },
            isAddOrEditImage: false
        })
    }

    imageDisplayTemplate = (data, index) => {
        return(
            <div className="p-grid">
                <div className="p-col-12 p-md-8" style={{wordBreak: 'break-word'}}>{data}</div>
                <div className="p-col-12 p-md-4 p-r">
                    <Button label="Edit" onClick={() => this.onAddOrEditImage(data, index)} />
                    <Button label="Remove" className="p-button-danger" onClick={() => this.onRemoveImage(index)} />
                </div>
            </div>
        )
    }

    imageFormTemplate = () => {
        return(
            <Fieldset legend="">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <InputText value={this.state.tmpImage.url} onChange={(e) => this.setState({tmpImage: {...this.state.tmpImage, url: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.image}</div>
                    </div>
                    <div className="p-col-12 p-r">
                        <Button label="Submit" className="p-button-info" onClick={() => this.onSubmitImage()} style={{width: 'auto'}} />
                        <Button label="Cancel" onClick={() => this.onCancelImage()} style={{width: 'auto'}} />
                    </div>
                </div>
            </Fieldset>
        )
    }

    onViewTypeChange = (data) =>{
        if(data.value)
            this.setState({
                viewMode: data.value,
                filter:{ 
                    viewType: 'all',
                    name: '',
                    workType: '',
                    departmentId: '',
                    categoryId: '',
                    moreFilter: false,
                    type:data.value,
                    code:'',
                },
                dataList: [],
            },() => this.getPagePrograms())
    }

    filterByProgram = () => {
        return(
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-6">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Name:</span>
                                <InputText value={this.state.filter.name} onKeyPress={this.handleKeyPress} onChange={(e) => {this.setState({ filter: { ...this.state.filter, name: e.target.value } })}} />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Code:</span>
                                <InputText value={this.state.filter.code} onKeyPress={this.handleKeyPress} onChange={(e) => {this.setState({ filter: { ...this.state.filter, code: e.target.value } })}} />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Type:</span>
                                <Dropdown value={this.state.filter.workType} options={PROG_WORK_TYPES} showClear={true} onChange={(e) => {this.setState({ filter: { ...this.state.filter, workType: e.value } }, () => this.applyFilter()) }} style={{ width: '100%' }} placeholder="Select type" />
                            </div>
                        </div>
                        {this.state.filter.moreFilter &&
                            <div className="p-col-12 ">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Talent:</span>
                                    <Dropdown value={this.state.filter.talent} options={this.state.filterTalents} showClear={true} onChange={(e) => {this.setState({ filter: { ...this.state.filter, talent: e.value } }, () => this.applyFilter()) }} style={{ width: '100%' }} placeholder="Select a Talent" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Department:</span>
                                <Dropdown value={this.state.filter.departmentId} options={this.state.departments} showClear={true} onChange={this.onFilterDepartmentChange} style={{ width: '100%' }} placeholder="Select a Department" />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Category:</span>
                                <Dropdown value={this.state.filter.categoryId} options={this.state.filterCategories} showClear={true} onChange={(e) => this.setState({ filter: { ...this.state.filter, categoryId: e.value }}, () => this.applyFilter())} style={{ width: '100%' }} placeholder="Select a Category" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    filterByCourse = () => {
        const statusFilterSelection = [
            {label: 'Enabled', value: 'true'},
            {label: 'Disabled', value: 'false'}
        ];
        return(
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-6">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Name:</span>
                                <InputText value={this.state.filter.name} onKeyPress={this.handleKeyPress} onChange={(e) => {this.setState({ filter: { ...this.state.filter, name: e.target.value } })}} />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Code:</span>
                                <InputText value={this.state.filter.code} onKeyPress={this.handleKeyPress} onChange={(e) => {this.setState({ filter: { ...this.state.filter, code: e.target.value } })}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Campaign:</span>
                                <Dropdown value={this.state.filter.campaignId} options={this.state.campaigns} showClear={true} onChange={(e) => this.setState({ filter: { ...this.state.filter, campaignId: e.value } }, () => this.applyFilter())} style={{ width: '100%' }} placeholder="Select a Campaign" />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Status:</span>
                                <Dropdown value={this.state.filter.status} options={statusFilterSelection} showClear={true} onChange={(e) => {this.setState({ filter: { ...this.state.filter, status: e.value } }, () => this.applyFilter()) }} style={{ width: '100%' }} placeholder="Select status" />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.goToDetail) {
            return <Redirect to={`/programs/${this.state.goToDetail}`} />
        }

        let tableLengthOptions = [
            {label: '5', command: () => this.onChagePageLength(5) },
            {label: '10', command: () => this.onChagePageLength(10) },
            {label: '25',command: () => this.onChagePageLength(25) },
            {label: '50',command: () => this.onChagePageLength(50) },
            {label: '100',command: () => this.onChagePageLength(100) }
        ];

        let btnViewitems = [
            { label: "Recently", icon: '',
                command: () => {
                    this.setState({
                        filter: {...this.state.filter,
                            viewType: 'recently'
                        }
                    }, () => {
                        this.getPagePrograms();
                    })
                }
            },
            { label: "All", icon: '',
                command: () => {
                    this.setState({
                        filter: {...this.state.filter,
                            viewType: 'all'
                        }
                    }, () => {
                        this.getPagePrograms();
                    })
                }
            }
        ]

        let btnCreateItems = [
            { label: 'Simple Program', icon: '', command: () => { this.addProgram(PROGRAM_WORK_TYPE.simple); } },
            { label: 'Daily/Weekly Program', icon: '', command: () => { this.addProgram(PROGRAM_WORK_TYPE.dw); } }
        ]

        const { t } = this.props;

        let header =
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                <div style={{ 'float': 'left' }} >
                    <SplitButton className="p-button-constrast" label={this.state.filter.viewType === 'all' ? 'View: All' : 'View: Recently'} icon="pi-md-list" model={btnViewitems}></SplitButton>
                    &nbsp;
                    <SplitButton label="Create Simple Program" icon="pi pi-plus" className="p-button-constrast" onClick={() => this.addProgram(PROGRAM_WORK_TYPE.simple)} model={btnCreateItems}></SplitButton>
                </div>
                <div style={{ 'float': 'right' }}>
                    {/* Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChagePageLength(e)} style={{ minWidth: "0" }} /> */}
                    {/* <Button className="p-margin-left-10" icon="pi pi-refresh" onClick={() => this.getPagePrograms(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}/> */}
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                        onClick={() => this.getPagePrograms()} model={tableLengthOptions}>
                    </SplitButton>
                </div>
                <h3 className="fle-datatable-header-title">{titleCaseText(this.state.viewMode + ' listing')}</h3>

            </div>;
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Programs</BreadcrumbsItem>

                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeProgram(e)}
                />
                <CloneProgramForm ref={el => this.cloneProgram = el} />

                <DepartmentForm ref={el => this.departmentForm = el}
                    dept={(data) => this.onCreateNewDept(data)}
                />

                <div className={"p-col-12 " + (this.state.isAdd ? 'p-hide' : '')}>
                    <div className="card card-w-title">
                        <Fieldset legend={titleCaseText(this.state.viewMode + ' filters')} >
                            {(()=>{
                                if(!this.state.viewMode || this.state.viewMode === view.program)
                                    return <React.Fragment>
                                        {this.filterByProgram()}
                                        <div className="p-grid">
                                            <div className="p-col-12 p-c">
                                                <a href="javascript:void(0)" className="nav-link" style={{ position: "relative" }} onClick={() => this.onClickMoreOrLessFilter()} >
                                                    {this.state.filter.moreFilter ?
                                                        <React.Fragment>
                                                            <i>less filters ...</i>
                                                            <i className="pi pi-chevron-up" style={{ position: "absolute", top: -1 }} />
                                                        </React.Fragment>
                                                        : <React.Fragment>
                                                            <i>more filters... </i>
                                                            <i className="pi pi-chevron-down" style={{ position: "absolute", top: -1 }} />
                                                        </React.Fragment>
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                else if(this.state.viewMode === view.course)
                                    return this.filterByCourse()
                            })()}
                            
                            <div className="p-grid">
                                <div className="p-col-12" style={{ "textAlign": "center" }}>
                                    <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={this.applyFilter} />
                                    <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={this.clearFilter} />
                                </div>
                            </div>
                        </Fieldset>

                        <DataTable className="p-datatable-scp" lazy={true} paginator={true} value={this.state.dataList}
                            first={this.state.page * this.state.rows}
                            rows={this.state.rows} totalRecords={this.state.total}
                            onPage={this.onPage} header={header} onSort={this.onSort}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                            selectionMode="single" responsive={true}
                        >
                            <Column field="code" header="Code" sortable={true} style={{ width: '12em' }}/>
                            <Column field="name" header="Name" sortable={true} style={{ minWidth: '16em'}}
                                body={(rowData) => {
                                    let link = '';
                                    if(this.state.viewMode === 'program') {
                                        link = `/programs/${rowData.id}`;
                                    } else {
                                        link = `/programs/${rowData.parentId}/courses/${rowData.id}`;
                                    }
                                    return (
                                        <Tippy
                                            content={<ProgramTipInfo data={rowData} type="program"/>}
                                            animation="fade"
                                            placement="right"
                                            trigger="mouseenter focus"
                                            interactive={true}
                                            arrow={true}
                                            theme="light"
                                            maxWidth= 'none'
                                            duration={[350, 200]}>
                                            <button className="Tippy-Button">
                                                <Link to={link} className="nav-link">
                                                    {rowData.name}
                                                </Link>
                                            </button>
                                        </Tippy>
                                    );
                                }}
                            />
                            {/* <Column field="workType" header={trans('programs.table_header.type')} body={rowData => rowData.workTypeName} sortable={true}/> */}
                            {/* <Column field="active" header="Active"/> */}
                            <Column header="Actions" body={this.actionTemplate} className='tc-actions' style={{ width: '12em', overlay: 'visible' }} />
                        </DataTable>
                    </div>
                </div>
                <div className={"p-col-12 " + (this.state.isAdd ? '' : 'p-hide')}>
                    <div className="card card-w-title">
                        <h2>
                            Create Program - {PROG_WORK_TYPES.filter(e => e.value === this.state.program.workType).map(e => e.label)}
                            <div className="p-toolbar-group-right">
                                <Button icon="pi-md-close" className="p-button-primary" onClick={() => this.setState({ isAdd: false })} />
                            </div>
                        </h2>
                    </div>
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Basic Information">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label>* Branch</label>
                                            <Dropdown value={this.state.program.branchId} options={this.state.branchs} onChange={(e) => this.setState({program: {...this.state.program, branchId: e.value}})}/>
                                            <div className="p-form-error">{this.state.errors.branch}</div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label>* Code</label>
                                            <InputText keyfilter={/[^\s]/} value={this.state.program.code} onChange={(e) => this.setState({program: {...this.state.program, code: replaceSpaceAndUpperCase(e.target.value)}})}/>
                                            <span className="p-form-error">{this.state.errors.code}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Name</label>
                                            <InputText value={this.state.program.name} onChange={(e) => this.setState({program: {...this.state.program, name: titleCaseText(e.target.value)}})} />
                                            <span className="p-form-error">{this.state.errors.name}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Description</label>
                                            <InputTextarea rows={4} cols={100} value={this.state.program.description} placeholder="Enter program description" onChange={(e) => this.setState({ program: { ...this.state.program, description: e.target.value } })} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">* Marshaller Emails (Seperated by comma)</label>
                                            <InputText value={this.state.program.moderatorEmail} onChange={(e) => this.setState({ program: { ...this.state.program, moderatorEmail: e.target.value }})} />
                                            <span className="p-form-error">{(this.state.errors.moderatorEmail)}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">* Primary Location</label>
                                            <Dropdown id="location" value={this.state.program.locationId} options={this.state.locations} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, locationId: e.value } })} style={{ width: '100%' }} placeholder="Select a Location" />
                                            <span className="p-form-error">{this.state.errors.location}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Secondary Location</label>
                                            <Dropdown id="location" value={this.state.program.secondaryLocationId} options={this.state.locations} filter={true} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, secondaryLocationId: e.value } })} style={{ width: '100%' }} placeholder="Select a Location" />
                                            <span className="p-form-error">{this.state.errors.secondaryLocation}</span>
                                        </div>
                                        {/* <div className="p-col-12">
                                            <label className="p-label">Attribute Set</label>
                                            <Dropdown options={this.state.attrSets} value={this.state.program.attributeSetId} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, attributeSetId: e.value } })} style={{ width: "100%" }} />
                                        </div> */}
                                        <div className="p-col-4">
                                            <label className="p-label">Weight (for order)</label>
                                            <InputText value={this.state.program.weight} keyfilter="int" onChange={(e) => this.setState({ program: { ...this.state.program, weight: e.target.value ? e.target.value : 0 } })} />
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Calendars">
                                <MultiSelect value={this.displayDefaulCalendar()} options={this.state.calendars} onChange={(e) => this.onChangeCalendar(e.value)} style={{ width: "100%" }} />
                                </Fieldset>
                            </div>

                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Classification">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">* Department</label>
                                            <div className="p-inputgroup">
                                                <Dropdown value={this.state.program.departmentId} options={this.state.departments} filter={true} onChange={this.onDepartmentChange} style={{ width: '100%' }} placeholder="Select a Department" />
                                                <Button label="New" icon="pi-md-plus" onClick={() => this.departmentForm.actionForm()} />
                                            </div>
                                            <span className="p-form-error">{this.state.errors.department}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">* Category <span className="p-form-error">(value depended on selected department)</span></label>
                                            <div className="p-inputgroup">
                                                <Dropdown value={this.state.program.categoryId} options={this.state.categories} filter={true} onChange={(e) => this.setState({ program: { ...this.state.program, categoryId: e.value } })} style={{ width: '100%' }} placeholder="Select a Category" />
                                                <Button label="New" icon="pi-md-plus" onClick={() => this.categoryForm.action()} />
                                            </div>
                                            <span className="p-form-error">{this.state.errors.category}</span>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Instructor">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <labe className="p-label">Instructor</labe>
                                            <MultiSelect value={this.state.program.instructorIds} options={this.state.instructors} filter={true} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, instructorIds: e.value } })} placeholder="Select Instructor" />
                                        </div>
                                        <div className="p-col-12">
                                            <labe className="p-label">Trainer</labe>
                                            <MultiSelect value={this.state.program.trainerIds} options={this.state.trainers} filter={true} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, trainerIds: e.value } })} placeholder="Select Trainer" />
                                        </div>
                                    </div>
                                </Fieldset>
                                {/* <Fieldset legend="Skills">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">Enabled Skills <span className="p-form-error">(value depended on selected department)</span></label>
                                            <Select multi
                                                values={this.state.skills.filter(x => this.state.program.enabledTalents.some(x2 => x2 === x.value))}
                                                options={this.state.skills}
                                                onChange={(values) => this.onChangeSelect(values)}
                                                style={{ width: '100%' }} placeholder="Select skills"
                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data by the department</span>) }}
                                            />
                                        </div>
                                    </div>
                                </Fieldset> */}
                                <Fieldset legend="Tags">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">(hit Enter add a tag)</label>
                                            <Chips placeholder="Type your tag here" value={this.state.program.tags} onChange={(e) => this.setState({ program: { ...this.state.program, tags: e.value } })}></Chips>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Images">
                                    <div className="p-grid">
                                        {!this.state.isAddOrEditImage &&
                                            <div className="p-col-12">
                                                <Button label="Add Image" onClick={() => this.onAddOrEditImage()} />
                                            </div>
                                        }
                                        {this.state.isAddOrEditImage && !this.state.tmpImage.index && this.state.tmpImage.index !== 0 &&
                                            <div className="p-col-12">
                                                {this.imageFormTemplate()}
                                            </div>
                                        }
                                        {this.state.program.images && this.state.program.images.map((value, index) => {
                                            return <React.Fragment>
                                                {index > 0 && <div className="p-col-12"><div className="p-hr"></div></div>}
                                                <div className="p-col-12">
                                                    {(()=>{
                                                        if(this.state.tmpImage.index === index){
                                                            return this.imageFormTemplate()
                                                        }else {
                                                            return this.imageDisplayTemplate(value, index)
                                                        }
                                                    })()}
                                                </div>
                                            </React.Fragment>
                                        })}
                                    </div>
                                </Fieldset>
                            </div>

                            <div className="p-col-12">
                                <Fieldset legend={this.state.program.workType === PROGRAM_WORK_TYPE.dw ? 'Other Settings' : 'Course Data Template'}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <Fieldset legend="Display">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.allowOnlineRegistration} onChange={(e) => this.onChangeSwitchRegistration(e)} />
                                                        <label className="p-margin-left-10">Allow online registration?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.showOnWeb} disabled={this.state.program.allowOnlineRegistration ? true : false} onChange={(e) => this.setState({ program: { ...this.state.program, showOnWeb: e.value } })} />
                                                        <label className="p-margin-left-10">Show on web?</label>
                                                    </div>
                                                    {/* <div className="p-col-12">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.showNotAvailable} onChange={(e) => this.setState({program: { ...this.state.program, showNotAvailable: e.value}})} />
                                                        <label className="p-margin-left-10">Show when not available (coming soon)?</label>
                                                    </div> */}
                                                </div>
                                            </Fieldset>
                                            <Fieldset legend="Field Set">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label">Custom Field Set</label>
                                                        <Dropdown value={this.state.program.customFieldSetId} options={this.state.customFieldSets} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, customFieldSetId: e.value } })} style={{ width: '100%' }} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Waiver Field Set</label>
                                                        <Dropdown value={this.state.program.waiverFieldSetId} options={this.state.waiverFieldSets} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, waiverFieldSetId: e.value } })} style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                            </Fieldset>
                                            {this.state.program.workType === PROGRAM_WORK_TYPE.dw &&
                                                <Fieldset legend="Reg. Schedule">
                                                    <div className="p-grid p-fluid form-group">
                                                        <div className="p-col-12 p-md-6">
                                                            <label className="p-label">* Non-member start</label>
                                                            <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.regStartDateValue} onChange={this.onRegStartDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                            <span className="p-form-error">{this.state.errors.regStartDate}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label className="p-label">* Non-member end</label>
                                                            <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.regEndDateValue} onChange={this.onRegEndDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                            <span className="p-form-error">{this.state.errors.regEndDate}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label className="p-label">* Member start</label>
                                                            <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.memberRegStartDateValue} onChange={this.onMemberRegStartDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                            <span className="p-form-error">{this.state.errors.memberRegStartDate}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label className="p-label">* Member end</label>
                                                            <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.memberRegEndDateValue} onChange={this.onMemberRegEndDateChange} showIcon={true} showTime={true} hideOnDateTimeSelect={true} />
                                                            <span className="p-form-error">{this.state.errors.memberRegEndDate}</span>
                                                        </div>
                                                    </div>
                                                </Fieldset>
                                            }
                                            {/* <Fieldset legend="Attendee Required">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label">Gender</label>
                                                        <Dropdown id="gender" value={this.state.program.gender} options={GENDERS} onChange={(e) => this.setState({ program: { ...this.state.program, gender: e.value } })} style={{ width: '100%' }} placeholder="Select gender" />
                                                        <span className="p-form-error">{this.state.errors.gender}</span>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Grades</label>
                                                        <MultiSelect value={this.state.program.grades} options={this.state.grades} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, grades: e.value } })} style={{ width: '100%' }} placeholder="Select grade" />
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">Min age</label>
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">YY:</span>
                                                            <InputText value={this.state.program.minAgeYear} keyfilter="int" onChange={(e) => this.setState({ program: { ...this.state.program, minAgeYear: e.target.value } })} />
                                                            <span className="p-inputgroup-addon">MM:</span>
                                                            <InputText value={this.state.program.minAgeMonth} keyfilter="int" onChange={(e) => this.setState({ program: { ...this.state.program, minAgeMonth: e.target.value } })} />
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.minAge}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">Max age</label>
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">YY:</span>
                                                            <InputText value={this.state.program.maxAgeYear} keyfilter="int" onChange={(e) => this.setState({ program: { ...this.state.program, maxAgeYear: e.target.value } })} />
                                                            <span className="p-inputgroup-addon">MM:</span>
                                                            <InputText value={this.state.program.maxAgeMonth} keyfilter="int" onChange={(e) => this.setState({ program: { ...this.state.program, maxAgeMonth: e.target.value } })} />
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.maxAge}</span>
                                                    </div>
                                                </div>
                                            </Fieldset> */}

                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <Fieldset legend={this.state.program.workType === PROGRAM_WORK_TYPE.dw ? 'Course Notification' : 'Notification'}>
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label p-margin-bottom-10">Notification by:</label>
                                                        <Checkbox inputId="cbNotifyEveryRegistration" checked={this.state.program.notifyEveryRegistration} onChange={(e) => this.setState({ program: { ...this.state.program, notifyEveryRegistration: e.checked } })} />
                                                        <label htmlFor="cbNotifyEveryRegistration" className="p-checkbox-label p-margin-right-30">Notify Every Registration?</label>
                                                        <Checkbox inputId="cbNotifyRegistrationReach" checked={this.state.program.notifyRegistrationReach} onChange={(e) => this.setState({ program: { ...this.state.program, notifyRegistrationReach: e.checked } })} />
                                                        <label htmlFor="cbNotifyRegistrationReach" className="p-checkbox-label">Registration Reached Threshold?</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-6 ">
                                                        <label className="p-label">Registration Number Threshold</label>
                                                        <InputText keyfilter="int" value={this.state.program.notifyRegistrationReachQuantity} onChange={(e) => this.setState({ program: { ...this.state.program, notifyRegistrationReachQuantity: e.target.value } })} />
                                                        <span className="p-form-error">{this.state.errors.notifyRegReachQuantity}</span>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                            {/* <Fieldset legend={this.state.program.workType === 'dw' ? 'Course Scholarship Data' : 'Scholarship'}>
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12 p-margin-top-10">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.scholarship} onChange={(e) => this.setState({ program: { ...this.state.program, scholarship: e.target.value } })} />
                                                        <label className="p-margin-left-10">Scholarship support?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Fee assistance <span className="p-form-error">(value depended on selected department)</span></label>
                                                        <SelectGroup isClearable={true} options={this.state.feeAssistances} onChange={(e) => this.setState({ program: { ...this.state.program, feeAssistanceId: e ? e.value : ''}})} style={{ width: '100%' }} />
                                                        <div className="p-form-error">{this.state.errors.feeAssistanceId}</div>
                                                    </div>
                                                </div>
                                            </Fieldset> */}
                                            {/* <Fieldset legend={this.state.program.workType === PROGRAM_WORK_TYPE.dw ? 'Course Accounting Data' : 'Accounting'}>
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label">Cash Account</label>
                                                        <Dropdown value={this.state.program.cashGLAccount} options={this.state.cashGLAccounts} filter={true} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, cashGLAccount: e.value } })} />
                                                        <div className="p-form-error">{this.state.errors.cashGLAccount}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Account Receivable</label>
                                                        <Dropdown value={this.state.program.receivableGLAccount} options={this.state.receivableGLAccounts} filter={true} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, receivableGLAccount: e.value } })} />
                                                        <div className="p-form-error">{this.state.errors.receivableGLAccount}</div>
                                                    </div>
                                                    <div className="p-fieldset-line"></div>
                                                    <div className="p-col-12 p-margin-top-10">
                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.program.deferred} onChange={(e) => this.setState({ program: { ...this.state.program, deferred: e.target.value } })} />
                                                        <label className="p-margin-left-10">Is Deferred?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Deferred Account</label>
                                                        <Dropdown value={this.state.program.deferredGLAccount} options={this.state.deferredGLAccounts} filter={true} showClear={true} onChange={(e) => this.setState({ program: { ...this.state.program, deferredGLAccount: e.value } })} />
                                                        <div className="p-form-error">{this.state.errors.deferredGLAccount}</div>
                                                    </div>
                                                    <div className="p-fieldset-line"></div>
                                                    <div className="p-col-12">
                                                        <div className="p-hr">Program Revenue Account</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Non-member</label>
                                                        <Dropdown value={this.state.program.basicPriceGLAccount} options={this.state.programGLAccounts} filter={true} showClear={true} onChange={(e) => this.onBasicPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                        <div className="p-form-error">{this.state.errors.basicPriceGLAccount}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Member</label>
                                                        <Dropdown value={this.state.program.membershipPriceGLAccount} options={this.state.programGLAccounts} filter={true} showClear={true} onChange={(e) => this.onMembershipPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                        <div className="p-form-error">{this.state.errors.membershipPriceGLAccount}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">Staff</label>
                                                        <Dropdown value={this.state.program.staffPriceGLAccount} options={this.state.programGLAccounts} filter={true} showClear={true} onChange={(e) => this.onStaffPriceGLAccountChange(e)} style={{ width: "100%" }} />
                                                        <div className="p-form-error">{this.state.errors.staffPriceGLAccount}</div>
                                                    </div>
                                                </div>
                                            </Fieldset> */}
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12">
                                <Button label="Save Program" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => this.handleSaveProgram()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(Programs);
