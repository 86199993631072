import React, { Component } from "react";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { AuditLogServices } from "./AuditLogServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";

export class AuditLog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            id: this.props.id,
            changeLog:{
                author: '',
                commitOn: '',
                type: '',
                changes: [] 
            },
            formDialog:{
                formHeader: 'Changes',
                visible: false
            },
            arrAuditLogs: []
        }
        this.auditLogServices = new AuditLogServices();
    }

    componentDidMount(){
        this.getPageAuditLog();
    }

    componentDidUpdate(prevProp){
        if(this.props.id !== prevProp.id){
            this.setState({
                id: this.props.id,
            },() => {
                this.getPageAuditLog();
            })
        }
    }

    getPageAuditLog = () => {
        this.auditLogServices.getPageAuditLogs(this.state.id, this.state.type)
        .then(res => {
            if(res) {
                this.setState({ arrAuditLogs: res });
            }
        })
    }

    getChangeDetails = (data) => {
        this.auditLogServices.getChangeDetails(this.state.id, data.version, this.state.type)
        .then(res => {
            if(res) {
                this.setState({
                    changeLog: {
                        id: data.id,
                        username: data.username,
                        commitOn: data.commitOn,
                        type: data.type,
                        changes: res 
                    }
                });
            }
        })
    }

    viewChangeDetails = (data) => {
        this.getChangeDetails(data);
        this.setState({ 
            formDialog: {
                ...this.state.formDialog,
                visible: true
            }
        });
    }

    hideFormDialog = () => {
        this.setState({
            formDialog: {
                ...this.state.formDialog,
                visible: false
            }
        })
    }

    render(){

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <DataTable value={this.state.arrAuditLogs}>
                        <Column field="username" header="Author" 
                            body={(rowData) => {
                                return(
                                    <React.Fragment>
                                        {rowData.username ? 
                                            <Link to={`/constituent-management/contacts/${rowData.id}`} className="nav-link">{rowData.username}</Link>
                                            : rowData.username
                                        }
                                    </React.Fragment>
                                );
                            }}
                        />
                        <Column header="Commit on" 
                            body={(rowData) => {
                                return ( <span>{moment(rowData.commitOn).format("MM-DD-YYYY hh:mm:ss A")}</span> );
                            }}
                        />
                        <Column field="type" header="Type" style={{width:'6em'}} />
                        <Column header="Changes" className="p-c" style={{width:'6em'}}
                            body={(rowData) => {
                                return (
                                    <a href="javascript:void(0);" className="nav-link" onClick={(e) => this.viewChangeDetails(rowData)}>{rowData.changes.length}</a>
                                );
                            }}
                        />
                    </DataTable>
                </div>

                <Sidebar visible={this.state.formDialog.visible} style={{overflowY: "auto", width: "30em"}} position="right" blockScroll={true} onHide={this.hideFormDialog}>
                    <h2>{this.state.formDialog.formHeader}</h2>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <table cellSpacing={0} cellSpacing={0} style={{width: "100%"}}>
                                <thead>
                                    <tr className="p-padding-bottom-10">
                                        <td>Author</td>
                                        <td>Commit on</td>
                                        <td>Type</td>
                                        <td>Changes</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {this.state.changeLog.username ? 
                                                <Link to={`/constituent-management/contacts/${this.state.changeLog.id}`} className="nav-link" >{this.state.changeLog.username} </Link>
                                                : this.state.changeLog.username
                                            }
                                        </td>
                                        <td>{moment(this.state.changeLog.commitOn).format("MM-DD-YYYY hh:mm:ss A")}</td>
                                        <td>{this.state.changeLog.type}</td>
                                        <td className="p-c">{this.state.changeLog.changes.length}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr></hr>

                            <ul className="p-padding-none" style={{listStyle: "none"}}>
                                {this.state.changeLog.changes.map((item, index) => {
                                    return (
                                        <li key={index} className="p-padding-10-0" style={{borderBottom: '1px solid #c5bfbf'}}>
                                            <div>
                                                {item.propertyName}: {item.type}
                                            </div>
                                            <div>
                                                {item.changes.length > 0 ? item.changes.map(change => {return <li>{change}</li>}) :''}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Close" icon="pi pi-times" className="p-button-secondary" onClick={this.hideFormDialog}/>
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
}