import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'primereact/spinner';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset'
import { InputSwitch } from 'primereact/inputswitch';
import { DISCOUNT_VALUE_TYPES, PROMOTION_RULE_TYPE, CONDITION_APPLY_FOR_TYPE, DISCOUNT_VALUE_TYPE, translateListSimple } from '../../constants';

export class RuleDiscountRanges extends Component {
    constructor(props){
        super(props);
        this.state = {
            discounts: props.data.length>0?props.data:[{
                loop: false,
                minQuantity: '0',
                maxQuantity: '0',
                discountValue: '0',
                discountValueType: DISCOUNT_VALUE_TYPE.percentage,
                applyForType: this.props.acrossQuantity?CONDITION_APPLY_FOR_TYPE.specific_product:CONDITION_APPLY_FOR_TYPE.same_product,
                applyQuantity: '0',
                applyForProduct: ''
            }],
            applyForTypes: props.applyForTypes,
            storeProducts: []
        }
    }

    componentDidMount(){
        this.loadDish();
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({
                discounts: this.props.data.length>0?this.props.data:[{
                    loop: false,
                    minQuantity: '0',
                    maxQuantity: '0',
                    discountValue: '0',
                    discountValueType: DISCOUNT_VALUE_TYPE.percentage,
                    applyForType: this.props.acrossQuantity?CONDITION_APPLY_FOR_TYPE.specific_product:CONDITION_APPLY_FOR_TYPE.same_product,
                    applyQuantity: '0',
                    applyForProduct: ''
                }]
            });
        }
    }
    
    loadDish = () => {
        // getListProducts({types: [PROD_TYPE.dish]}, true).then(res => {
        //     this.setState({ dish: res?res:[] })
        // })
    }

    updateApplyForTypes = (options) => {
        this.setState({applyForTypes: options});
    }

    addDiscountRange = () => {
        let tmpDiscounts = [...this.state.discounts];
        tmpDiscounts.push({
            loop: false,
            minQuantity: '0',
            maxQuantity: '0',
            discountValue: '0',
            discountValueType: DISCOUNT_VALUE_TYPE.percentage,
            applyForType: this.props.acrossQuantity?CONDITION_APPLY_FOR_TYPE.specific_product:CONDITION_APPLY_FOR_TYPE.same_product,
            applyQuantity: '0',
            applyForProduct: ''
        });
        this.props.onChange(tmpDiscounts);
    }

    removeDiscountRange = (idx) => {
        let tmpDiscounts = [...this.state.discounts];
        tmpDiscounts.splice(idx, 1);
        this.props.onChange(tmpDiscounts);
    }

    onElementChange = (idx, key, value) => {
        let tmpDiscounts = [...this.state.discounts];
        let discountItem = tmpDiscounts[idx];
        switch(key){
            case 'minQuantity':
                discountItem.minQuantity=value;
                break;
            case 'maxQuantity':
                discountItem.maxQuantity=value;
                break;
            case 'applyForType':
                discountItem.applyForType=value;
                if(value!=='specific_product'){
                    discountItem.applyForProduct='';
                }
                break;
            case 'applyQuantity':
                discountItem.applyQuantity=value;
                break;
            case 'applyForProduct':
                discountItem.applyForProduct=value;
                break;
            case 'discountValue':
                discountItem.discountValue=value;
                break;
            case 'discountValueType':
                discountItem.discountValueType=value;
                break;
            case 'loop':
                discountItem.loop=value;
                break;
            default:
                break;
        }
            
        tmpDiscounts.splice(idx, 1, discountItem);
        this.props.onChange(tmpDiscounts);
    }

    render(){
        const trans = this.props.trans;

        return (
            <div className="p-grid">
                {!this.state.discounts[0].loop &&
                <div className="p-col-12">
                    <Button label={trans('promotion.mgt.add_range')} icon="pi pi-plus" iconPos="left" onClick={this.addDiscountRange}/>
                </div>
                }
                <div className="p-col-12">
                    {this.state.discounts.map((val, idx) => {
                        return (
                            <div key={"range_"+idx}>
                                <Fieldset>
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-6 p-md-1">
                                            <label className="p-label">{this.props.type===PROMOTION_RULE_TYPE.product_bogo?trans('promotion.mgt.buy_qty'):trans('promotion.mgt.min_qty')}</label>
                                            <Spinner value={val.minQuantity} onChange={(e) => this.onElementChange(idx, 'minQuantity', e.value)}/>
                                        </div>
                                        {this.props.type===PROMOTION_RULE_TYPE.product_bogo?
                                        <div className="p-col-12 p-md-1">
                                            <label className="p-label">{trans('promotion.mgt.get_qty')}</label>
                                            <Spinner value={val.applyQuantity} onChange={(e) => this.onElementChange(idx, 'applyQuantity', e.value)}/>
                                        </div>
                                        :
                                        <div className="p-col-6 p-md-1">
                                            <label className="p-label">{trans('promotion.mgt.max_qty')}</label>
                                            <Spinner value={val.maxQuantity} onChange={(e) => this.onElementChange(idx, 'maxQuantity', e.value)}/>
                                        </div>
                                        }
                                        {this.props.type===PROMOTION_RULE_TYPE.product_bogo &&
                                            <React.Fragment>
                                                <div className="p-col-12 p-md-2">
                                                    <label className="p-label">{trans('promotion.mgt.apply_for')}</label>
                                                    <Dropdown value={val.applyForType} options={this.state.applyForTypes} onChange={(e) => this.onElementChange(idx, 'applyForType', e.value)}/>
                                                </div>
                                                <div className={"p-col-12 p-md-4 " + (val.applyForType!==CONDITION_APPLY_FOR_TYPE.specific_product?'p-hide':'')}>
                                                    <label className="p-label">Product</label>
                                                    <Dropdown value={val.applyForProduct} options={this.state.dish} filter={true} onChange={(e) => this.onElementChange(idx, 'applyForProduct', e.value)} style={{width: "100%"}} />
                                                </div>
                                            </React.Fragment>
                                        }
                                        <div className="p-col-5 p-md-1">
                                            <label className="p-label">{trans('promotion.mgt.discount_value')}</label>
                                            <Spinner value={val.discountValue} onChange={(e) => this.onElementChange(idx, 'discountValue', e.target.value)}/>
                                        </div>
                                        <div className="p-col-7 p-md-1">
                                            <label className="p-label">{trans('promotion.mgt.discount_type')}</label>
                                            <Dropdown value={val.discountValueType} options={translateListSimple(trans, DISCOUNT_VALUE_TYPES)} onChange={(e) => this.onElementChange(idx, 'discountValueType', e.value)}/>
                                        </div>
                                        {this.state.discounts.length>1 && !this.state.discounts[0].loop?
                                            <div className="p-col-12 p-md-1">
                                                <Button icon="pi-md-close" className="p-button-danger" style={{float: "right", top: "10px"}} onClick={() => this.removeDiscountRange(idx)}/>
                                            </div>
                                        :
                                            <div className="p-col-12 p-md-1">
                                                <label className="p-label">{trans('promotion.mgt.is_loop')}?</label>
                                                <InputSwitch onLabel="Yes" offLabel="No" checked={val.loop} onChange={(e) => this.onElementChange(idx, 'loop', e.target.value)} />
                                            </div>
                                        }
                                    </div>
                                </Fieldset>
                            </div>                           
                        );
                    })}                    
                </div>
            </div>
        );
    }
} 
export default withNamespaces('message')(RuleDiscountRanges)  