import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { Fieldset } from "primereact/fieldset";
import { MenuForm } from "../../cms/menu/MenuForm";
import { MenuItemForm } from "../../cms/menu/MenuItemForm";
import { BlockContentForm } from "../../cms/block-content/BlockContentForm";
// import { PageStyleSettingForm } from "../../cms/page-style/PageStyleSettingForm";
// import { getPageStyles } from "../../cms/page-style/PageStyleService";
import { getListBlockContents, removeBlockContent } from "../../cms/block-content/BlockContentServices";
import { getMenus, removeMenu, removeMenuItem } from "../../cms/menu/MenuServices";
import { TabView } from "primereact/tabview";
import { saveScpConfig } from "./ConfigruationServices";
import { UPLOAD_TYPE } from "../../constants";
import { getSliders, removeSliderItem, removeSlider } from "../../cms/slider/SliderServices";
import { SliderForm } from "../../cms/slider/SliderForm";
import { SliderItemForm } from "../../cms/slider/SliderItemForm";
import { SplitButton } from "primereact/splitbutton";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getPageContents } from "../../cms/content/PageContentService";

class TenantPageSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data?this.popularFormData(props.data):{},
            errors: {}
        }
    }

    componentDidMount(){
        // this.loadPageStyles();
        this.loadBlockContents();
        this.loadMenus();
        this.loadSliders();
        this.loadAllPublicPages();
    }

    popularFormData = (data) => {
        if(!data) return null
        return {...data, 
            faviconImageInfo: data&&data.faviconImageInfo&&Object.keys(data.faviconImageInfo).length>0?data.faviconImageInfo:null,
            faviconUploadType: data.faviconImageInfo&&data.faviconImageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            faviconUrl: data.faviconImageInfo&&data.faviconImageInfo.fileName?'':data.faviconUrl,
            mainLogoImageInfo: data&&data.mainLogoImageInfo&&Object.keys(data.mainLogoImageInfo).length>0?data.mainLogoImageInfo:null,
            mainLogoUploadType: data.mainLogoImageInfo&&data.mainLogoImageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            mainLogoUrl: data.mainLogoImageInfo&&data.mainLogoImageInfo.fileName?'':data.mainLogoUrl,
            secondaryLogoImageInfo: data&&data.secondaryLogoImageInfo&&Object.keys(data.secondaryLogoImageInfo).length>0?data.secondaryLogoImageInfo:null,
            secondaryLogoUploadType: data.secondaryLogoImageInfo&&data.secondaryLogoImageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            secondaryLogoUrl: data.secondaryLogoImageInfo&&data.secondaryLogoImageInfo.fileName?'':data.secondaryLogoUrl,
            mobileLogoImageInfo: data&&data.mobileLogoImageInfo&&Object.keys(data.mobileLogoImageInfo).length>0?data.mobileLogoImageInfo:null,
            mobileLogoUploadType: data.mobileLogoImageInfo&&data.mobileLogoImageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            mobileLogoUrl: data.mobileLogoImageInfo&&data.mobileLogoImageInfo.fileName?'':data.mobileLogoUrl
        }
    }

    handleSave = () => {
        this.setState({errors: {}});

        let maxFileSize = 104857600 // 100MB
        let totalFileSize = 0
        if(this.state.data.faviconFile){
            totalFileSize += this.state.data.faviconFile.size
        }
        if(this.state.data.mainLogoFile){
            totalFileSize += this.state.data.mainLogoFile.size
        }
        if(this.state.data.secondaryLogoFile){
            totalFileSize += this.state.data.secondaryLogoFile.size
        }
        if(this.state.data.mobileLogoFile){
            totalFileSize += this.state.data.mobileLogoFile.size
        }
        if(totalFileSize > maxFileSize){
            if(this.state.data.faviconFile){
                this.setState({errors: {...this.state.errors, faviconImage: 'Total file size not allowed (maximum: 100MB)'}})
            }
            if(this.state.data.mainLogoFile){
                this.setState({errors: {...this.state.errors, mainLogoImage: 'Total file size not allowed (maximum: 100MB)'}})
            }
            if(this.state.data.secondaryLogoFile){
                this.setState({errors: {...this.state.errors, secondaryLogoImage: 'Total file size not allowed (maximum: 100MB)'}})
            }
            if(this.state.data.mobileLogoFile){
                this.setState({errors: {...this.state.errors, mobileLogoImage: 'Total file size not allowed (maximum: 100MB)'}})
            }
            if(Object.keys(this.state.errors).length>0){
                return
            }
        }
        
        saveScpConfig({pageConfig: this.state.data}, 'pageConfig')
        .then((res) => {
            if(!res.errorCode){
                this.props.onSave(res.pageConfig);
                this.setState({data: this.popularFormData(res.pageConfig)})

                if(this.inputUploadFavicon) this.inputUploadFavicon.value=''
                if(this.inputUploadMainLogo) this.inputUploadMainLogo.value=''
                if(this.inputUploadSecondaryLogo) this.inputUploadSecondaryLogo.value=''
                if(this.inputUploadMobileLogo) this.inputUploadMobileLogo.value=''

                showSuccessNotify('Action Submitted');
            }else{
                if(res.errorCode === 400) this.setState({errors: res.errorObj})
                showErrorNotify(res.errorMessage);
            }
        })
    }
    
    loadAllPublicPages = () => {
        getPageContents(true)
        .then(res => this.setState({publicPages: res?res:[]}))
    }

    //#region PAGE STYLE
    // loadPageStyles = () => {
    //     getPageStyles()
    //     .then(res => {
    //         let tempArr = res?res:[];
    //         this.setState({
    //             styleSettings: tempArr,
    //             simpleStyleSettings: tempArr.map(s => {return {value: s.id, label: s.name}})
    //         });
    //     });
    // }

    // pageStyleActionTemplate = (rowData) => {
    //     return <Button label="Edit" onClick={() => this.pageStyleForm.popularFormData(rowData)} ></Button>
    // }

    // refreshPageStyle = (removePageStyleId) => {
    //     this.loadPageStyles();
    //     if(removePageStyleId){
    //         if(this.state.data.pageStyleId === removePageStyleId){
    //             this.setState({ data: {...this.state.data, pageStyleId: null } });
    //         }
    //     }
    // }
    //#endregion

    //#region Slider
    loadSliders = () => {
        getSliders()
        .then(res => {
            const tempArr = res?res:[]
            this.setState({
                sliders: tempArr,
                simpleSliders: tempArr.map(s => ({value: s.id, label: s.name}))
            });
        });
    }

    rowSliderExpansionTemplate = (data) => {
        let tblSliderItem = <TableHeader title={"List items of " + data.name} titleClassname="p-size-16"
            actionTemplate={<Button icon="pi pi-plus" label="Add Item" className="p-button-constrast" onClick={(e) => this.sliderItemForm.openForm(data.id)}/>}
            showLength={false} showRefresh={false}
            />

        return(
            <DataTable value={data.slides} header={tblSliderItem}>
                <Column field="name" header="Name"/>
                <Column field="weight" header="Weight" />
                <Column field="active" header="Enabled" body={rowData => rowData.active?'Yes':'No'} />
                <Column header="" bodyClassName="tc-actions" bodyStyle={{textAlign: 'right', minWidth: '10%' }}
                    body={(rowData) => 
                        <React.Fragment>
                            <Button icon="pi pi-pencil" className="p-button-info btn-sm" onClick={() => this.sliderItemForm.openForm(data.id, rowData)}/>
                            <Button icon="pi pi-times" className="p-button-danger btn-sm" onClick={() => this.deleteSlide(rowData.id)}/>
                        </React.Fragment>
                    }
                />
            </DataTable>
        )
    }

    deleteSlider = (id) => {
        removeSlider(id).then(res => {
            if(!res.errorCode){
                this.loadSliders()
                
                if(this.state.data.sliderId === id){
                    this.setState({ data: {...this.state.data, sliderId: null } })
                }

                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    deleteSlide = (id) => {
        removeSliderItem(id).then(res => {
            if(!res.errorCode){
                this.loadSliders()
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }
    //#endregion

    //#region BLOCK CONTENT
    loadBlockContents = () => {
        getListBlockContents()
        .then(res => {
            let tempArr = res?res:[];
            this.setState({
                blockContents: tempArr,
                simpleBlockContents: tempArr.map(s => ({value: s.id, label: s.title}))
            });
        });
    }

    deleteBlockContent = (id) => {
        removeBlockContent(id).then(res => {
            if(!res.errorCode){
                this.loadBlockContents()
                
                this.setState({ 
                    data: {
                        ...this.state.data, 
                        headerInfoContentId: this.state.data.headerInfoContentId===id?null:this.state.data.headerInfoContentId,
                        footerContentId: this.state.data.footerContentId===id?null:this.state.data.footerContentId 
                    }
                })

                this.blockContentForm.onHide()
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }
    //#endregion

    //#region MENU
    loadMenus = () => {
        getMenus().then(res => {
            const menus = res?res:[]

            this.setState({
                menus: menus,
                simpleMenus: menus.map(m => ({value: m.id, label: m.title}))
            })
        })
    }

    rowMenuExpansionTemplate = (data) => {
        let tblMenuItem = <TableHeader title={"List items of " + data.title} titleClassname="p-size-16"
            actionTemplate={<Button icon="pi pi-plus" label="Add Item" className="p-button-constrast" onClick={(e) => this.menuItemForm.openForm(data.id)}/>}
            showLength={false} showRefresh={false}
            />

        return(
            <TreeTable value={data.nodes} header={tblMenuItem}>
                <Column field="title" header="Title" expander/>
                <Column field="weight" header="Weight" />
                <Column field="enabled" header="Enabled" body={rowData => rowData.data.enabled?'Yes':'No'} />
                <Column header="" bodyClassName="tc-actions" bodyStyle={{textAlign: 'right', width: '10em' }}
                    body={(rowData) => 
                        <React.Fragment>
                            <Button icon="pi pi-pencil" className="p-button-info btn-sm" onClick={() => this.menuItemForm.openForm(data.id, rowData.data)}/>
                            <Button icon="pi pi-times" className="p-button-danger btn-sm" onClick={() => this.deleteMenuItem(data.id, rowData.data.id)}/>
                        </React.Fragment>
                    }
                />
            </TreeTable>
        )
    }

    deleteMenu = (id) => {
        removeMenu(id).then(res => {
            if(!res.errorCode){
                this.loadMenus()

                if(this.state.data.menuId === id){
                    this.setState({ data: {...this.state.data, menuId: null } });
                }

                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    deleteMenuItem = (mId, id) => {
        removeMenuItem(mId, id).then(res => {
            if(!res.errorCode){
                this.loadMenus()
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }
    //#endregion

    render() {
        let tblpageConfigStyleSetting = (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button icon="pi pi-plus" label="Create" className="p-float-left p-button-constrast" onClick={(e) => this.pageStyleForm.popularFormData()} style={{width: 'auto'}} />
                <div className="p-float-right">
                    <Button className="p-button-constrast p-margin-left-15" icon="pi pi-refresh" tooltip="Refresh" onClick={() => this.loadPageStyles()} />
                </div>
                <h3 className="fle-datatable-header-title" style={{marginTop: 0, marginBottom: 0}}>List of Page Style</h3>
            </div>
        );

        const tblHeaderBlockContent = <TableHeader title="Block Contents" 
            actionTemplate={<Button icon="pi pi-plus" label="Create Content" className="p-button-constrast" onClick={(e) => this.blockContentForm.openForm()}/>}
            showLength={false}
            refresh={() => this.loadBlockContents()}
        />

        const tblHeaderMenu = <TableHeader title="Menus" 
            actionTemplate={<Button icon="pi pi-plus" label="Create Menu" className="p-button-constrast" onClick={(e) => this.menuForm.openForm()}/>}
            showLength={false}
            refresh={() => this.loadMenus()}
        />

        const tblHeaderSlider = <TableHeader title="Sliders" 
            actionTemplate={<Button icon="pi pi-plus" label="Create Slider" className="p-button-constrast" onClick={(e) => this.sliderForm.openForm()}/>}
            showLength={false}
            refresh={() => this.loadSliders()}
            />

        return (
            <React.Fragment>
                <div className="p-grid">
                    {/* <PageStyleSettingForm ref={el => this.pageStyleForm = el} 
                        refreshTable={(removeId) => this.refreshPageStyle(removeId)}
                    /> */}
                    <BlockContentForm ref={el => this.blockContentForm = el} delete={(e) => this.deleteBlockContent(e)} refreshTable={() => this.loadBlockContents()}/>

                    <MenuForm ref={el => this.menuForm=el} refreshTable={() => this.loadMenus()}/>

                    <MenuItemForm ref={el => this.menuItemForm=el} refreshTable={() => this.loadMenus()}/>

                    <SliderForm ref={el => this.sliderForm=el} delete={(e) => this.deleteSlider(e)} refreshTable={() => this.loadSliders()}/>

                    <SliderItemForm ref={el => this.sliderItemForm=el} delete={(e) => this.deleteSlide(e)} refreshTable={() => this.loadSliders()}/>

                    <div className="p-col-12 p-md-6">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <Fieldset legend="General Setting">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <label className="p-label">Favicon Url</label>
                                            <div className="p-inputgroup">
                                                {(!this.state.data.faviconUploadType || this.state.data.faviconUploadType===UPLOAD_TYPE.external_url.value) ?
                                                    <React.Fragment>
                                                        <InputText value={this.state.data.faviconUrl} onChange={(e) => this.setState({data: {...this.state.data, faviconUrl: e.target.value}})} />
                                                        <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, faviconUploadType: UPLOAD_TYPE.upload_file.value}})} />
                                                    </React.Fragment>
                                                    :<React.Fragment>
                                                        {this.state.data.faviconImageInfo && this.state.data.faviconImageInfo.fileName &&
                                                            <span className="p-inputgroup-addon"><img src={this.state.data.faviconImageInfo.url} alt={this.state.data.faviconImageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                                        }
                                                        <input ref={el=>this.inputUploadFavicon=el} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({data: {...this.state.data, faviconFile: e.target.files[0]}})} />
                                                        <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, faviconUploadType: UPLOAD_TYPE.external_url.value}})} />
                                                    </React.Fragment> 
                                                }
                                            </div>
                                            <div className="p-form-error">{this.state.errors.faviconImage}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">App Title</label>
                                            <InputText value={this.state.data.appTitle} onChange={(e) => this.setState({data: {...this.state.data, appTitle: e.target.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Main Logo Url</label>
                                            <div className="p-inputgroup">
                                                {(!this.state.data.mainLogoUploadType || this.state.data.mainLogoUploadType===UPLOAD_TYPE.external_url.value) ?
                                                    <React.Fragment>
                                                        <InputText value={this.state.data.mainLogoUrl} onChange={(e) => this.setState({data: {...this.state.data, mainLogoUrl: e.target.value}})} />
                                                        <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, mainLogoUploadType: UPLOAD_TYPE.upload_file.value}})} />
                                                    </React.Fragment>
                                                    :<React.Fragment>
                                                        {this.state.data.mainLogoImageInfo && this.state.data.mainLogoImageInfo.fileName &&
                                                            <span className="p-inputgroup-addon"><img src={this.state.data.mainLogoImageInfo.url} alt={this.state.data.mainLogoImageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                                        }
                                                        <input ref={el=>this.inputUploadMainLogo=el} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({data: {...this.state.data, mainLogoFile: e.target.files[0]}})} />
                                                        <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, mainLogoUploadType: UPLOAD_TYPE.external_url.value}})} />
                                                    </React.Fragment> 
                                                }
                                            </div>
                                            <div className="p-form-error">{this.state.errors.mainLogoImage}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Secondary Logo Url</label>
                                            <div className="p-inputgroup">
                                                {(!this.state.data.secondaryLogoUploadType || this.state.data.secondaryLogoUploadType===UPLOAD_TYPE.external_url.value) ?
                                                    <React.Fragment>
                                                        <InputText value={this.state.data.secondaryLogoUrl} onChange={(e) => this.setState({data: {...this.state.data, secondaryLogoUrl: e.target.value}})} />
                                                        <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, secondaryLogoUploadType: UPLOAD_TYPE.upload_file.value}})} />
                                                    </React.Fragment>
                                                    :<React.Fragment>
                                                        {this.state.data.secondaryLogoImageInfo && this.state.data.secondaryLogoImageInfo.fileName &&
                                                            <span className="p-inputgroup-addon"><img src={this.state.data.secondaryLogoImageInfo.url} alt={this.state.data.secondaryLogoImageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                                        }
                                                        <input ref={el=>this.inputUploadSecondaryLogo=el} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({data: {...this.state.data, secondaryLogoFile: e.target.files[0]}})} />
                                                        <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, secondaryLogoUploadType: UPLOAD_TYPE.external_url.value}})} />
                                                    </React.Fragment> 
                                                }
                                            </div>
                                            <div className="p-form-error">{this.state.errors.secondaryLogoImage}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Mobile Logo Url</label>
                                            <div className="p-inputgroup">
                                                {(!this.state.data.mobileLogoUploadType || this.state.data.mobileLogoUploadType===UPLOAD_TYPE.external_url.value) ?
                                                    <React.Fragment>
                                                        <InputText value={this.state.data.mobileLogoUrl} onChange={(e) => this.setState({data: {...this.state.data, mobileLogoUrl: e.target.value}})} />
                                                        <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, mobileLogoUploadType: UPLOAD_TYPE.upload_file.value}})} />
                                                    </React.Fragment>
                                                    :<React.Fragment>
                                                        {this.state.data.mobileLogoImageInfo && this.state.data.mobileLogoImageInfo.fileName &&
                                                            <span className="p-inputgroup-addon"><img src={this.state.data.mobileLogoImageInfo.url} alt={this.state.data.mobileLogoImageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                                        }
                                                        <input ref={el=>this.inputUploadMobileLogo=el} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({data: {...this.state.data, mobileLogoFile: e.target.files[0]}})} />
                                                        <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => this.setState({data: {...this.state.data, mobileLogoUploadType: UPLOAD_TYPE.external_url.value}})} />
                                                    </React.Fragment> 
                                                }
                                            </div>
                                            <div className="p-form-error">{this.state.errors.mobileLogoImage}</div>
                                        </div>
                                        {/* <div className="p-col-12">
                                            <label className="p-label">Logo Height (px)</label>
                                            <Spinner value={this.state.data.logoHeight} min={0} onChange={(e) => this.setState({data: {...this.state.data, logoHeight: e.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Logo Header Url</label>
                                            <InputText value={this.state.data.logoUrl} onChange={(e) => this.setState({data: {...this.state.data, logoUrl: e.target.value}})} />
                                        </div> */}
                                        <div className="p-col-12">
                                            <label className="p-label">Page Width (px)</label>
                                            <InputText value={this.state.data.pageWidth} keyfilter="num" onChange={(e) => this.setState({data: {...this.state.data, pageWidth: e.target.value}})} />
                                            <div className="p-form-error">{this.state.errors.pageWidth}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Menu</label>
                                            <Dropdown value={this.state.data.menuId} options={this.state.simpleMenus} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, menuId: e.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Slider</label>
                                            <Dropdown value={this.state.data.sliderId} options={this.state.simpleSliders} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, sliderId: e.value}})} />
                                        </div>
                                        {/* <div className="p-col-12">
                                            <label className="p-label">Page Style</label>
                                            <Dropdown value={this.state.data.pageStyleId} options={this.state.simpleStyleSettings} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, pageStyleId: e.value}})} />
                                        </div> */}
                                        <div className="p-col-12">
                                            <label className="p-label">Header Info Content</label>
                                            <Dropdown value={this.state.data.headerInfoContentId} options={this.state.simpleBlockContents} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, headerInfoContentId: e.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Footer Content</label>
                                            <Dropdown value={this.state.data.footerContentId} options={this.state.simpleBlockContents} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, footerContentId: e.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Landing Page:</label>
                                            <Dropdown value={this.state.data.homePageId} options={this.state.publicPages} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, homePageId: e.value}})} />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <TabView style={{marginTop: '17px'}}>
                            {/* <TabView header="Styles">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <DataTable value={this.state.styleSettings} header={tblpageConfigStyleSetting} paginator rows={5} responsive >
                                            <Column field="name" header="Name" />
                                            <Column body={this.pageStyleActionTemplate} headerStyle={{ width: '10em'}} bodyStyle={{overflow: 'visible'}}></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </TabView> */}
                            <TabView header="Menus">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <DataTable value={this.state.menus} paginator rows={5} responsive
                                            expandedRows={this.state.menuExpandedRows} dataKey="id"
                                            onRowToggle={(e) => this.setState({ menuExpandedRows: e.data })}
                                            rowExpansionTemplate={this.rowMenuExpansionTemplate} header={tblHeaderMenu}
                                        >
                                            <Column expander style={{ width: '3.5em' }} />
                                            <Column field="title" header="Title" />
                                            <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                                                body={(rowData) => {
                                                    const actionItems = [
                                                        { label: 'Remove', icon: 'pi pi-times', command: (e) => this.deleteMenu(rowData.id)}
                                                    ]
                                                    return(
                                                        <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => this.menuForm.openForm(rowData)}></SplitButton>
                                                    )
                                                }}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </TabView>
                            <TabView header="Sliders">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <DataTable value={this.state.sliders} paginator rows={5} responsive
                                            expandedRows={this.state.sliderExpandedRows} dataKey="id"
                                            onRowToggle={(e) => this.setState({ sliderExpandedRows: e.data })}
                                            rowExpansionTemplate={this.rowSliderExpansionTemplate} header={tblHeaderSlider}
                                        >
                                            <Column expander style={{ width: '3.5em' }} />
                                            <Column field="name" header="Name" />
                                            <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                                                body={(rowData) => {
                                                    const actionItems = [
                                                        { label: 'Remove', icon: 'pi pi-times', command: (e) => this.deleteSlider(rowData.id)}
                                                    ]
                                                    return(
                                                        <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => this.sliderForm.openForm(rowData)}></SplitButton>
                                                    )
                                                }}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </TabView>
                            <TabView header="Block Contents">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <DataTable value={this.state.blockContents} header={tblHeaderBlockContent} paginator rows={5} responsive >
                                            <Column field="title" header="Title" />
                                            <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                                                body={(rowData) => {
                                                    const actionItems = [
                                                        { label: 'Remove', icon: 'pi pi-times', command: (e) => this.deleteBlockContent(rowData.id)}
                                                    ]
                                                    return(
                                                        <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => this.blockContentForm.openForm(rowData)}></SplitButton>
                                                    )
                                                }}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </TabView>
                        </TabView>
                    </div>
                </div>
                <div className="p-grid p-margin-top-10">
                    <div className="p-col-12">
                        <Button label="Save" icon="pi pi-save" iconPos="left" onClick={() => this.handleSave()} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TenantPageSetting;
