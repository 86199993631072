import React, {useState, useEffect} from 'react';
import moment, { HTML5_FMT } from 'moment';
import { TABLE_PAGING_LENGTH, TIME_SERIES_REPEAT_TYPE, PRODUCT_TYPE } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getPageProductAvailabilities, saveProductAvailability, removeProductAvailability } from './ProductServices';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';

const CREATE_TYPE = {
    manually: 0,
    autoDaily: 1
}

export const ProductAvailability = (props) => {
    const [frmVisible, setFrmVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})
    const [filter] = useState({availableForNow: true})
    const [data, setData] = useState([])
    const [isLoadTable, setLoadTable] = useState(false)
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })

    useEffect(() => {
        if(props.productId)
            setLoadTable(true)
        else
            setLoadTable(false)
    }, [props.productId])

    useEffect(() => {
        if(isLoadTable && props.productId){
            setPageable({...pageable, loading: true})
            loadPageProductAvailabilities();
        }
    }, [isLoadTable])

    const loadPageProductAvailabilities = () =>{
        getPageProductAvailabilities(props.productId, filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setData(res.content)
                setPageable({
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false)
            }
        })
    }

    const onPage = (e) =>{
        setPageable({
            ...pageable,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({
            ...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true)
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true)
    };


    const addOrEditItem = (e) => {
        setFrmData({
            id: e?e.id:null,
            createType: e?e.createType:CREATE_TYPE.manually,
            repeatType: e?e.repeatType:TIME_SERIES_REPEAT_TYPE.daily,
            repeatValues: e?e.repeatValues:[],
            dateRange: e && e.startDate!==e.endDate?true:false,
            startDate: e?e.startDate:null,
            endDate: e?e.endDate:null,
            startTime: e?e.startTime:null,
            endTime: e?e.endTime:null,
            startDateValue: e && e.startDate?moment(e.startDate, HTML5_FMT.DATE).toDate():null,
            endDateValue: e && e.endDate?moment(e.endDate, HTML5_FMT.DATE).toDate():null,
            startTimeValue: e && e.startTime?moment(e.startTime, HTML5_FMT.TIME).toDate():null,
            endTimeValue: e && e.endTime?moment(e.endTime, HTML5_FMT.TIME).toDate():null,
            minQty: e&&e.minQty?e.minQty:'',
            maxQty: e&&e.maxQty?e.maxQty:''
        })
        setFrmVisible(true)
    }

    const closeForm = () => {
        setFrmVisible(false)
        setFrmData({})
        setErrors({})
    }

    const deleteItem = (id) => {
        removeProductAvailability(props.productId, id)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const submitSaveItem = () => {
        showloading()

        let tmpData = {...frmData}
        tmpData.startDate = tmpData.startDateValue && moment(tmpData.startDateValue).isValid() ? moment(tmpData.startDateValue).format(HTML5_FMT.DATE) : ''
        tmpData.endDate = tmpData.endDateValue && moment(tmpData.endDateValue).isValid() ? moment(tmpData.endDateValue).format(HTML5_FMT.DATE) : ''
        tmpData.startTime = tmpData.startTimeValue && moment(tmpData.startTimeValue).isValid() ? moment(tmpData.startTimeValue).format(HTML5_FMT.TIME) : ''
        tmpData.endTime = tmpData.endTimeValue && moment(tmpData.endTimeValue).isValid() ? moment(tmpData.endTimeValue).format(HTML5_FMT.TIME) : ''

        saveProductAvailability(props.productId, tmpData)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Action submitted!')
                setLoadTable(true);
                closeForm();
            }else{
                showErrorNotify(res.errorMessage)
                if(res.errorCode===400)
                    setErrors(res.errorObj)
            }
        })
        .finally(stoploading)
    }

    const onCreateTypeChange = (e) => {
        setFrmData({
            ...frmData, 
            createType: e,
            dateRange: e===CREATE_TYPE.autoDaily?true:false
        })
    }

    const onWeekDaysChange = (e) => {
        let selectedValues = [...frmData.repeatValues]

        if(e.checked)
            selectedValues.push(e.value)
        else
            selectedValues.splice(selectedValues.indexOf(e.value), 1)

        setFrmData({...frmData, repeatValues: selectedValues})
    }

    const onDateChange = (e, key) => {
        switch(key){
            case 'start':
                setFrmData({...frmData, startDateValue: e})
                break;
            case 'end':
                setFrmData({...frmData, endDateValue: e})
                break;
            default: break;
        }        
    }

    const onDateBlur = (e, key) => {
        switch(key){
            case 'start':
                if(!frmData.startDateValue || !moment(frmData.startDateValue).isValid())
                    setFrmData({...frmData, startDateValue: null})
                break;
            case 'end':
                if(!frmData.endDateValue || !moment(frmData.endDateValue).isValid())
                    setFrmData({...frmData, endDateValue: null})
                break;
            default: break;
        }
    }

    const onTimeChange = (e, key) => {
        switch(key){
            case 'start':
                setFrmData({...frmData, startTimeValue: e})
                break;
            case 'end':
                setFrmData({...frmData, endTimeValue: e})
                break;
            default: break;
        }
    }

    const onTimeBlur = (key) => {
        switch(key){
            case 'start':
                if(!frmData.startTimeValue || !moment(frmData.startTimeValue).isValid())
                    setFrmData({...frmData, startTimeValue: null})
                break;
            case 'end':
                if(!frmData.endTimeValue || !moment(frmData.endTimeValue).isValid())
                    setFrmData({...frmData, endTimeValue: null})
                break;
            default: break;
        }
    }


    const tblHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                        <div className="p-float-right">
                            <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e)}  />
                        </div>
                        <h3 className="fle-datatable-header-title">Availabilities</h3>
                    </div>;

    return (
        <div className="p-grid">
            {!frmVisible && 
            <div className="p-col-12">
                <Button label="Add product availibility" icon="pi pi-plus" className="p-button-success btn-text-sm" iconPos="left" onClick={() => addOrEditItem()}/>
            </div>
            }
            {frmVisible && 
            <div className="p-col-12">
                <Fieldset legend="Availability setup">
                    <div className="p-grid p-fluid form-group">
                        {!frmData.id && 
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Creating by:</label>
                                <RadioButton value={CREATE_TYPE.manually} className="p-margin-left-20" onChange={(e) => onCreateTypeChange(e.value)} checked={frmData.createType === CREATE_TYPE.manually} />
                                <label className="p-radiobutton-label">Manual</label>
                                <RadioButton value={CREATE_TYPE.autoDaily} className="p-margin-left-20" onChange={(e) => onCreateTypeChange(e.value)} checked={frmData.createType === CREATE_TYPE.autoDaily} />
                                <label className="p-radiobutton-label">Auto create daily</label>
                            </div>
                            {frmData.createType===CREATE_TYPE.autoDaily && 
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Repeat on:</label>
                                    <RadioButton value={TIME_SERIES_REPEAT_TYPE.daily} className="p-margin-left-20" onChange={(e) => setFrmData({...frmData, repeatType: e.value})} checked={frmData.repeatType === TIME_SERIES_REPEAT_TYPE.daily} />
                                    <label className="p-radiobutton-label">Every day</label>
                                    <RadioButton value={TIME_SERIES_REPEAT_TYPE.weekDay} className="p-margin-left-20" onChange={(e) => setFrmData({...frmData, repeatType: e.value})} checked={frmData.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay} />
                                    <label className="p-radiobutton-label">Specific day in week</label>
                                </div>
                                {frmData.repeatType===TIME_SERIES_REPEAT_TYPE.weekDay && 
                                <div className="p-col-12">
                                    <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Day repeat:</label>
                                    <Checkbox inputId="cbMon" className="p-margin-left-20" value="mon" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('mon')}></Checkbox>
                                    <label htmlFor="cbMon" className="p-checkbox-label p-margin-right-15">Mon</label>
                                    <Checkbox inputId="cbTue" value="tue" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('tue')}></Checkbox>
                                    <label htmlFor="cbTue" className="p-checkbox-label p-margin-right-15">Tue</label>
                                    <Checkbox inputId="cbWed" value="wed" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('wed')}></Checkbox>
                                    <label htmlFor="cbWed" className="p-checkbox-label p-margin-right-15">Wed</label>
                                    <Checkbox inputId="cbThu" value="thu" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('thu')}></Checkbox>
                                    <label htmlFor="cbThu" className="p-checkbox-label p-margin-right-15">Thu</label>
                                    <Checkbox inputId="cbFri" value="fri" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('fri')}></Checkbox>
                                    <label htmlFor="cbFri" className="p-checkbox-label p-margin-right-15">Fri</label>
                                    <Checkbox inputId="cbSat" value="sat" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('sat')}></Checkbox>
                                    <label htmlFor="cbSat" className="p-checkbox-label p-margin-right-15">Sat</label>
                                    <Checkbox inputId="cbSun" value="sun" onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('sun')}></Checkbox>
                                    <label htmlFor="cbSun" className="p-checkbox-label">Sun</label>
                                    <div className="p-form-error" style={{paddingLeft: '100px'}}>{errors.repeatValues}</div>
                                </div>
                                }
                            </React.Fragment>
                            }
                        </React.Fragment>
                        }
                        
                        {((!frmData.id && frmData.createType===CREATE_TYPE.manually) || frmData.id) &&
                        <div className="p-col-12">
                            <Checkbox inputId="cbIsRange" checked={frmData.dateRange} onChange={(e) => setFrmData({...frmData, dateRange: e.checked})} />
                            <label htmlFor="cbIsRange" className="p-margin-left-10">Is date range?</label>
                        </div>
                        }
                        <div className="p-col-12">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-6 p-md-4">
                                    <label className="p-label">* {frmData.dateRange?'Start Date':'Date'}</label>
                                    <MaskedCalendar value={frmData.startDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'start')} onBlur={(e) => onDateBlur(e.target.value, 'start')} dateFormat="mm-dd-yy" />
                                    <span className="p-form-error">{errors.startDate}</span>
                                </div>
                                {frmData.dateRange && 
                                <div className="p-col-6 p-md-4">
                                    <label className="p-label">* End Date</label>
                                    <MaskedCalendar value={frmData.endDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'end')} onBlur={(e) => onDateBlur(e.target.value, 'end')} dateFormat="mm-dd-yy" />
                                    <span className="p-form-error">{errors.endDate}</span>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-6 p-md-4">
                                    <label className="p-label">* Start Time</label>
                                    <MaskedCalendar value={frmData.startTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'start')} onBlur={(e) => onTimeBlur(e.target.value, 'start')} hourFormat="12" timeOnly={true}/>
                                    <span className="p-form-error">{errors.startTime}</span>
                                </div>
                                <div className="p-col-6 p-md-4">
                                    <label className="p-label">* End Time</label>
                                    <MaskedCalendar value={frmData.endTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'end')} onBlur={(e) => onTimeBlur(e.target.value, 'end')} hourFormat="12" timeOnly={true}/>
                                    <span className="p-form-error">{errors.endTime}</span>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-6 p-md-4">
                                    <label className="p-label">Min q.ty</label>
                                    <InputText value={frmData.minQty} keyfilter="int" onChange={(e) => setFrmData({...frmData, minQty: e.target.value})}/>
                                    <span className="p-form-error">{errors.minQty}</span>
                                </div>
                                <div className="p-col-6 p-md-4">
                                    <label className="p-label">Max q.ty (leave blank as unlimited)</label>
                                    <InputText value={frmData.maxQty} keyfilter="int"  showIcon={true} onChange={(e) => setFrmData({...frmData, maxQty: e.target.value})}/>
                                    <span className="p-form-error">{errors.maxQty}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button label="Submit" className="btn-text-sm p-button-success" onClick={() => submitSaveItem()}/>
                            <Button label="Cancel" className="btn-text-sm p-button-default" onClick={() => closeForm()}/>
                        </div>
                    </div>
                </Fieldset>
            </div>
            }
            <div className="p-col-12">
                <DataTable lazy={true} paginator={true} value={data} loading={pageable.loading}
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                    emptyMessage="No content available!"
                >
                    <Column header="Date" field="startDate" sortable={true} 
                        body={(rowData) => moment(rowData.startDate).format('MM/DD/YYYY') + (rowData.startDate!==rowData.endDate?' - ' + moment(rowData.endDate).format('MM/DD/YYYY'):'')}
                    />
                    <Column header="Start Time" field="startTime" sortable={true} 
                        body={(rowData) => moment(rowData.startTime, HTML5_FMT.TIME).format('hh:mm A')}
                    />
                    <Column header="End Time" field="endTime" sortable={true} 
                        body={(rowData) => moment(rowData.endTime, HTML5_FMT.TIME).format('hh:mm A')}
                    />
                    <Column header="Min Q.ty" field="minQty" sortable={true}/>
                    <Column header="Max Q.ty" field="maxQty" sortable={true}/>
                    <Column header="" className="tc-actions" style={{textAlign: 'center', width: '15%' }} 
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteItem(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit"  model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => addOrEditItem(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}