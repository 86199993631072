const CONSTITUENT2 = 'SCP_CONSTITUENT_';
export const ROLES_CONFIG = {
    ACCESS_CONTROL_M: 'SCP_ACCESS_CONTROL_MANAGE',

    //Dashboard and Reports
    DAR: 'SCP_DAR',
    DAR_DASHBOARD: 'SCP_DAR_DASHBOARD',
    DAR_DASHBOARD_R: 'SCP_DAR_DASHBOARD_READ',
    DAR_RERPORT: 'SCP_DAR_REPORT',
    DAR_RERPORT_ORDER: 'SCP_DAR_REPORT_ORDER',
    DAR_RERPORT_ORDER_R: 'SCP_DAR_REPORT_ORDER',
    DAR_RERPORT_TRANSACTION: 'SCP_DAR_REPORT_TRANSACTION',
    DAR_RERPORT_TRANSACTION_R: 'SCP_DAR_REPORT_TRANSACTION_READ',
    DAR_RERPORT_RUN: 'SCP_DAR_REPORT_RUN',

    //Frontdesk
    FRONTDESK: 'SCP_FRONTDESK',
    FRONTDESK_SCAN: 'SCP_FRONTDESK_SCAN',
    FRONTDESK_BOOKING: 'SCP_FRONTDESK_BOOKING',
    FRONTDESK_BOOKING_PROGRAM: 'SCP_FRONTDESK_BOOKING_PROGRAM',
    FRONTDESK_BOOKING_PROGRAM_R: 'SCP_FRONTDESK_BOOKING_PROGRAM_READ',
    FRONTDESK_BOOKING_RESIDENCE: 'SCP_FRONTDESK_BOOKING_RESIDENCE',
    FRONTDESK_BOOKING_RESIDENCE_R: 'SCP_FRONTDESK_BOOKING_RESIDENCE_READ',
    FRONTDESK_INVOICE: 'SCP_FRONTDESK_INVOICE',

    //Constituent
    CONSTITUENT: 'SCP_CONSTITUENT',
    CONSTITUENT_CONTACT: 'SCP_CONSTITUENT_CONTACT',
    CONSTITUENT_CONTACT_MERGE: 'SCP_CONSTITUENT_CONTACT_MERGE',
    CONSTITUENT_CONTACT_HOUSEHOLD: 'SCP_CONSTITUENT_CONTACT_HOUSEHOLD',
    CONSTITUENT_CONTACT_COMPANY: 'SCP_CONSTITUENT_CONTACT_COMPANY',
    CONSTITUENT_MEMBERSHIP: 'SCP_CONSTITUENT_MEMBERSHIP',
    CONSTITUENT_INTEREST: 'SCP_CONSTITUENT_INTEREST',
    CONSTITUENT_SKILL: 'SCP_CONSTITUENT_SKILL',

    //Campaigns
    CAMPAIGN: 'SCP_CAMPAIGN',
    
    //Product
    PRODUCT: 'SCP_PRODUCT',
    PRODUCT_MEMBERSHIP: 'SCP_PRODUCT_MEMBERSHIP',
    PRODUCT_DONATION: 'SCP_PRODUCT_DONATION',
    PRODUCT_PROGRAM: 'SCP_PRODUCT_PROGRAM',
    PRODUCT_PROGRAM_DETAIL: 'SCP_PRODUCT_PROGRAM_DETAILS',
    PRODUCT_PROGRAM_DETAIL_R: 'SCP_PRODUCT_PROGRAM_DETAILS_READ',
    PRODUCT_PROGRAM_COPY: 'SCP_PRODUCT_PROGRAM_COPY',
    PRODUCT_PROGRAM_BUDGET: 'SCP_PRODUCT_PROGRAM_BUDGET',
    PRODUCT_PROGRAM_CATEGORY: 'SCP_PRODUCT_PROGRAM_CATEGORY',
    PRODUCT_RESIDENCE: 'SCP_PRODUCT_RESIDENCE',
    PRODUCT_RESIDENCE_BOOKING: 'SCP_PRODUCT_RESIDENCE_BOOKING',
    PRODUCT_RESIDENCE_ORDER: 'SCP_PRODUCT_RESIDENCE_ORDER',
    PRODUCT_RESIDENCE_MANAGEMENT: 'SCP_PRODUCT_RESIDENCE_MGT',
    PRODUCT_RESIDENCE_WORKFLOW: 'SCP_PRODUCT_RESIDENCE_WORKFLOW',
    PRODUCT_SURVEY: 'SCP_PRODUCT_SURVEY',
    PRODUCT_MERCHANDISE: 'SCP_PRODUCT_MERCHANDISE',
    PRODUCT_LOCKERS: 'SCP_PRODUCT_LOCKERS',

    //Financial
    FINANCIAL: 'SCP_FINANCIAL',
    FINANCIAL_DISCOUNT: 'SCP_FINANCIAL_DISCOUNT',
    FINANCIAL_BUDGET: 'SCP_FINANCIAL_BUDGET',

    //Accounting
    ACCOUNTING: 'SCP_ACCOUNTING',
    ACCOUNTING_GLA: 'SCP_ACCOUNTING_GLA',
    ACCOUNTING_PA: 'SCP_ACCOUNTING_PA',

    //Templates
    TEMPLATES: 'SCP_TEMPLATE',
    TEMPLATES_EMAIL: 'SCP_TEMPLATE_EMAIL',

    //Properties
    PROPERTIY: 'SCP_PROPERTY',
    PROPERTIY_FIELDSET: 'SCP_PROPERTY_FIELDSET',
    PROPERTIY_LOCATION: 'SCP_PROPERTY_LOCATION',
    PROPERTIY_ASSET: 'SCP_PROPERTY_ASSET',

    //System
    SYSTEM: 'SCP_SYSTEM',
    SYSTEM_CONFIGURATION: 'SCP_SYSTEM_CONFIGURATION',
    SYSTEM_SMTP: 'SCP_SYSTEM_SMTP',
    SYSTEM_ACL: 'SCP_SYSTEM_ACL',
    SYSTEM_ACL_USER: 'SCP_SYSTEM_ACL_USER',
    SYSTEM_ACL_ROLE: 'SCP_SYSTEM_ACL_ROLE',
    SYSTEM_IAE: 'SCP_SYSTEM_IAE',
    SYSTEM_IAE_IMPORT: 'SCP_SYSTEM_IAE_IMPORT',
    SYSTEM_IAE_IMPORT_CONTACT: 'SCP_SYSTEM_IAE_IMPORT_CONTACT',
    SYSTEM_IAE_IMPORT_COURSE: 'SCP_SYSTEM_IAE_IMPORT_COURSE',
    SYSTEM_APPAPI: 'SCP_SYSTEM_APPAPI',

    ORDERS_R: 'SCP_ORDERS_READ',
    FRONTDESK_V: 'SCP_FRONTDESK_VIEW',
    FD_MEMBER_SCAN: 'SCP_FD_MEMBER_SCAN',
    FD_PROGRAM_ORDER_R: 'SCP_FD_PROGRAM_ORDER_READ',
    FD_PROGRAM_ORDER_W: 'SCP_FD_PROGRAM_ORDER_WRITE',
    TRANSACTION_R: 'SCP_TRANSACTION_READ',
    TRANSACTION_DEAIL_R: 'SCP_TRANSACTION_DETAIL_READ',
    TRANSACTION_DEAIL_W: 'SCP_TRANSACTION_DETAIL_WRITE',
    TRANSACTION_DEAIL_D: 'SCP_TRANSACTION_DETAIL_DELETE',
    REPORT_R: 'SCP_REPORT_READ',
    REPORT_W: 'SCP_REPORT_WRITE',
    REPORT_D: 'SCP_REPORT_DELETE',

    ASSET_R: 'SCP_ASSET_READ',
    ASSET_W: 'SCP_ASSET_WRITE',
    CMS_V: 'SCP_CMS_VIEW',

    PROGRAM_V: 'SCP_PROGRAM_VIEW',
    PROGRAM_A: 'SCP_PROGRAM_ADD',
    PROGRAM_U: 'SCP_PROGRAM_UPDATE',
    PROGRAM_D: 'SCP_PROGRAM_DELETE',

    HOTEL_V: 'SCP_HOTEL_VIEW',

    IMPORT_EXPORT_V: 'SCP_IE_VIEW',
    IMPORT_V: 'SCP_IMPORT_VIEW',
    EXPORT_V: 'SCP_EXPORT_VIEW',

    ACCOUNTING_V: 'SCP_ACCOUNTING_VIEW',

    ADMIN: 'SCP_ADMIN',
    SUPER_ADMIN: 'SCP_SUPER_ADMIN',

    CAMPAIGN_R: 'SCP_CAMPAIGN_READ',
    CAMPAIGN_W: 'SCP_CAMPAIGN_WRITE',
    CAMPAIGN_D: 'SCP_CAMPAIGN_DELETE',

    CONTACT_R: CONSTITUENT2 + 'CONTACT_READ',
    CONTACT_W: CONSTITUENT2 + 'CONTACT_WRITE',
    CONTACT_DETAIL_R: CONSTITUENT2 + 'CONTACT_DETAIL_READ',
    CONTACT_DETAIL_W: CONSTITUENT2 + 'CONTACT_DETAIL_WRITE',
    CONTACT_INCOME_ADJUST_REQ: CONSTITUENT2 + 'CONTACT_INCOME_ADJUST_REQUEST',
    CONTACT_INCOME_ADJUST_AUTH: CONSTITUENT2 + 'CONTACT_INCOME_ADJUST_AUTHORIZE',

    GROUP_R: CONSTITUENT2 + 'GROUP_READ',
    GROUP_W: CONSTITUENT2 + 'GROUP_WRITE',
    GROUP_DETAIL_R: CONSTITUENT2 + 'GROUP_DETAIL_READ',
    GROUP_DETAIL_W: CONSTITUENT2 + 'GROUP_DETAIL_WRITE',
    GROUP_DETAIL_D: CONSTITUENT2 + 'GROUP_DETAIL_DELETE',
    GROUP_INCOME_ADJUST_REQ: CONSTITUENT2 + 'GROUP_INCOME_ADJUST_REQUEST',
    GROUP_INCOME_ADJUST_AUTH: CONSTITUENT2 + 'GROUP_INCOME_ADJUST_AUTHORIZE',

    INTERESTS_R: CONSTITUENT2 + 'INTERESTS_READ',
    INTERESTS_D: CONSTITUENT2 + 'INTERESTS_DELETE',
    INTERESTS_W: CONSTITUENT2 + 'INTERESTS_WRITE',

    SKILLS_R: CONSTITUENT2 + 'SKILLS_READ',
    SKILLS_W: CONSTITUENT2 + 'SKILLS_WRITE',
    SKILLS_D: CONSTITUENT2 + 'SKILLS_DELETE',
    SKILLS_DETAIL_R: CONSTITUENT2 + 'SKILLS_DETAIL_READ',
    SKILLS_DETAIL_W: CONSTITUENT2 + 'SKILLS_DETAIL_WRITE',

    SYSTEM_CONFIG_W: 'SCP_SYSTEM_CONFIG_WRITE',
    APP_CONFIG_W: 'SCP_APP_CONFIG_WRITE',

    //promotion management
    PROMOTION: 'SCP_PROMOTION',
    PROMOTION_MANAGEMENT: 'SCP_PROMOTION_MANAGEMENT',
    PROMOTION_MANAGEMENT_R: 'SCP_PROMOTION_MANAGEMENT_READ',
    PROMOTION_MANAGEMENT_W: 'SCP_PROMOTION_MANAGEMENT_WRITE',
    PROMOTION_MANAGEMENT_D: 'SCP_PROMOTION_MANAGEMENT_DELETE',
}