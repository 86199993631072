import React, {Component} from "react";
import moment from 'moment';
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { ProgramAssessmentServices } from "./ProgramAssessmentServices";
import { showNotification } from '../../core/service/NotificationService';
import { Sidebar } from "primereact/sidebar";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { Dropdown } from "primereact/dropdown";

export class ProgramAssessmentForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            assessment: {
                id: '',
                name: '',
                date: moment(new Date()).format('YYYY-MM-DD'),
                type: 'attendance'
            },
            dateValue: moment(new Date(), 'MM-DD-YYYY').toDate(),
            errors: {}
        }
        this.assessmentServices = new ProgramAssessmentServices();
    }

    componentDidUpdate(prevProps){
        if(this.props.data !== prevProps.data){
            this.popularForm(this.props.data);
            this.setState({
                errors: {}
            });
        }
    }

    handleSaveAssessment = () => {
        this.assessmentServices.saveProgramAssessment(this.props.program, this.props.course, this.state.assessment)
        .then(res => {
            if(res.status.code === 200 || res.status.code === 201){
                this.props.refreshTable();
                this.popularForm(null);
                this.props.hideFormDialog();
                showNotification('success', 'Success Message', 'Action submitted');
                this.setState({
                    errors: {}
                });
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.objError!==undefined) this.setState({errors: res.objError});
            }
        });
    }

    deleteAssessment = (e) =>{
        this.assessmentServices.deleteProgramAssessment(this.props.program, this.props.course, this.state.assessment.id)
        .then(res => {
            if(res.status.code === 200){
                this.props.hideFormDeleteDialog();
                this.props.refreshTable();
                showNotification('success', 'Success Message', 'Action deleted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    onChangeDate = (e) => {
        this.setState({
            dateValue: e.value,
            assessment: {
                ...this.state.assessment, date: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : ''
            }
        })
    }

    popularForm = (data) => {
        this.setState({
            assessment: {
                id: data ? data.id : '',
                name: data ? data.name : '',
                date: data ? data.date : moment(new Date()).format('YYYY-MM-DD'),
                type: data ? data.type : 'attendance'
            },
            dateValue: data ? moment(data.date, moment.HTML5_FMT.DATE).toDate() : moment(new Date(), 'MM-DD-YYYY').toDate()
        })
    }

    render(){
        let lstTypes = [
            {label: 'Attendance', value: 'attendance'},
            {label: 'Skill', value: 'skill',}
        ]

        return(
            <div>
                <Sidebar visible={this.props.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.props.hideFormDialog}>
                    <h2>{this.props.header}</h2>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset>
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Name</label>
                                        <InputText value={this.state.assessment.name} onChange={(e) => this.setState({assessment: {...this.state.assessment, name: e.target.value}})}/>
                                        <span className="p-form-error">{this.state.errors.name}</span> 
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Date</label>
                                        {/* <Calendar value={this.state.dateValue} dateFormat="mm-dd-yy" onChange={(e) => this.onChangeDate(e)}/> */}
                                        <MaskedCalendar value={this.state.dateValue} dateFormat="mm-dd-yy" showIcon={true} onChange={(e) => this.onChangeDate(e)}/>
                                        <span className="p-form-error">{this.state.errors.date}</span> 
                                    </div>
                                    {!this.state.assessment.id ? 
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">Type</label>
                                            <Dropdown value={this.state.assessment.type} options={lstTypes} onChange={(e) => this.setState({assessment: {...this.state.assessment, type: e.value}})} />
                                            <span className="p-form-error">{this.state.errors.type}</span> 
                                        </div>
                                        :''
                                    }
                                    
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={this.handleSaveAssessment}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.props.hideFormDialog}/>
                        </div>
                    </div>
                </Sidebar>
            </div>
        )
    }
}