import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'primereact/spinner';
import { DISCOUNT_VALUE_TYPES, DISCOUNT_VALUE_TYPE, translateListSimple } from '../../constants';

class RuleDiscount extends Component {
    constructor(props){
        super(props);
        this.state = {
            discount: {
                discountValue: props.data?props.data.discountValue:'0',
                discountValueType: props.data?props.data.discountValueType:DISCOUNT_VALUE_TYPE.percentage
            }
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({ discount: this.props.data });
        }
    }

    onElementChange = (key, value) => {
        let tmpDiscount = {...this.state.discount};
        switch(key){
            case 'discountValue':
                tmpDiscount.discountValue=value;
                break;
            case 'discountValueType':
                tmpDiscount.discountValueType=value;
                break;
            default:
                break;
        }
            
        this.props.onChange(tmpDiscount);
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12 p-md-2">
                            <label className="p-label">{trans('promotion.mgt.discount_value')}</label>
                            <Spinner value={this.state.discount.discountValue} onChange={(e) => this.onElementChange('discountValue', e.target.value)}/>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <label className="p-label">{trans('promotion.mgt.discount_type')}</label>
                            <Dropdown value={this.state.discount.discountValueType} options={translateListSimple(trans, DISCOUNT_VALUE_TYPES)} onChange={(e) => this.onElementChange('discountValueType', e.value)}/>
                        </div>
                    </div>                  
                </div>
            </div>
        );
    }
}
export default withNamespaces('message')(RuleDiscount)