import axios from 'axios';
import { API_BASE_URL } from '../constants';

export class FormLockServices {
    acquireFormLock(formKey, id, version){
        return axios.get(`${API_BASE_URL}/scp/api/form/lock/${formKey}/${id}`, { headers: {version: version}}).then(res => res.data);
    }

    releaseFormLock(formKey, id){
        return axios.post(`${API_BASE_URL}/scp/api/form/unlock/${formKey}/${id}`).then(res => res.data);
    }
}

export function acquireFormLock(formKey, id, version){
    return axios.get(`${API_BASE_URL}/scp/api/form/lock/${formKey}/${id}`, { headers: {version: version}}).then(res => res.data);
}

export function releaseFormLock(formKey, id){
    return axios.post(`${API_BASE_URL}/scp/api/form/unlock/${formKey}/${id}`).then(res => res.data);
}
