import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Sidebar } from 'primereact/sidebar';
import { Button } from "primereact/button";
import renderHTML from 'react-render-html';
import { ScrollPanel } from 'primereact/scrollpanel'
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { FIELD_DISPLAY_TYPE } from "../../constants";

export const WaiverSignForm = forwardRef((props, ref) => {

    const [index, setIndex] = useState(0)
    const [waiver, setWaiver] = useState({config: {}})
    const [answerValue, setAnswerValue] = useState({})
    const [enabledBtnAgree, setEnabledBtnAgree] = useState(true)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const onScroll = (e) => {
            let element = e.target
            let elementHeight = element.clientHeight
            if (element.scrollTop === e.target.scrollHeight - elementHeight) {
                setEnabledBtnAgree(false)
            }
        }
        let contentElement = document.getElementById("waiverContent")
        let scrollPanel 
        if(contentElement){
            scrollPanel = contentElement.getElementsByClassName("p-scrollpanel-content")[0]
            let elementHeight = scrollPanel.offsetHeight
            if(elementHeight <= 500){ // 500: height of content
                setEnabledBtnAgree(false)
            }else{
                scrollPanel.addEventListener("scroll", onScroll);
                return () => scrollPanel.removeEventListener("scroll", onScroll);
            }
        }
    }, [visible]);

    useImperativeHandle(ref, ()=>({
        openForm(index, data, value){
            setIndex(index)
            setWaiver(data)
            setAnswerValue(value)
            setEnabledBtnAgree(true)
            setVisible(true)
        }
    }))

    const onAccept = () => {
        if(props.onAccept){
            props.onAccept(index, waiver, true)
        }
        onHide()
    }

    const onHide = () => {
        setVisible(false)
        if(props.backToForm)
            props.backToForm()
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-lg p-sidebar-md" position="right" blockScroll={true} onHide={onHide}>
            <h3>{waiver.label}</h3>
            
            <div className="p-grid">
                <div className="p-col-12">
                    <i className="p-error">(*) Must read all conditions</i>
                </div>
                <div className="p-col-12">
                    {waiver.config.content &&
                        <div className="p-mb-2">
                            <ScrollPanel id="waiverContent" style={{ maxHeight: 500 }}>
                                {renderHTML(waiver.config.content)}
                            </ScrollPanel>
                        </div>
                    }
                    {/* {waiver.config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                        <Checkbox inputId="cb_waiverPromo" className="p-mr-1" onChange={(e) => props.onChange(e.checked)} checked={answerValue}></Checkbox>
                    } */}
                    {waiver.config.displayType===FIELD_DISPLAY_TYPE.textbox &&
                        <InputText value={answerValue} className="input-sm" onChange={(e) => setAnswerValue(e.target.value)}/>
                    }
                    {waiver.config.collectPhysicalWaiver &&
                        <React.Fragment>
                            <div className="p-margin-10-0"><input type="file" onChange={(e) => props} /></div>
                            {waiver.config.waiverTemplateURL &&
                                <React.Fragment>
                                    <div className="p-margin-10-0">Download waiver link <a href={`${waiver.config.waiverTemplateURL}`} className="nav-link" target="_blank" >here</a></div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-text-right">
                    <Button disabled={enabledBtnAgree} label={waiver.config.waiverPromo} className="p-button-secondary" icon="pi pi-check" onClick={() => onAccept()} style={{width: 'auto'}} ></Button>
                    <Button label="Close" className="p-ml-1" icon="pi pi-times" onClick={() => onHide()} style={{width: 'auto'}}></Button>
                </div>    
            </div>
        </Sidebar>
    )
})