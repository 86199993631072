import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
require("prop-types");
let ipcRenderer;
export class ScannerSetting extends Component {
constructor() {
    super();
    this.state = {
        devices: [],
        index: 0,
        savePath : ''
    };
}

componentDidMount() {
    if(ipcRenderer) {
        ipcRenderer.on("SEND_TO_RENDERER", this.handleRenderer);
        ipcRenderer.on("SEND_SCANNER_NAME", this.handleScannerIsNull);
        ipcRenderer.on('GET_SCANNER_NAME', this.handleDisplayScannerName);
        ipcRenderer.send("RETURN_SCANNER_LIST", "list-scanners");
        ipcRenderer.send("SCANNER_NAME_NEW", "NAME");
    }
}

componentWillUnmount() {
    if(ipcRenderer) {
        ipcRenderer.removeListener("SEND_TO_RENDERER", this.handleRenderer);
        ipcRenderer.removeListener("SEND_SCANNER_NAME",this.handleScannerIsNull);
        ipcRenderer.removeListener("GET_SCANNER_NAME", this.handleDisplayScannerName);
    }
}

  // logic error
handleRenderer = (event, data) => {
    if (data !== undefined) {
        console.log("returned devices: ", data);
        let devices = [];
            data.map((dev, index) => {
            devices.push({
            id: index,
            vendorPath: dev.vendorId,
            productPath: dev.productId,
            name: dev.product,
            code: dev.path
        });
    });
        console.log("list parsed devices: ", devices);
        this.setState
        ({
            devices: devices
        });
    }
};

onReaderChange = e => {
    this.setState({
        city: e.value.name,
        cities: e.value
    });
    if(ipcRenderer) {
        ipcRenderer.send("SELECT_SCANNER", e.value.code);
        ipcRenderer.send("SCANNER_NAME", e.value.name);
    }
};


handleScannerIsNull = (event,data) => {
    if(data == null){
        alert(null);  
    } else {
        if(ipcRenderer) {
            ipcRenderer.send("MAIN_TURN_ON_SCANNER", "GET_TURN_ON");
            ipcRenderer.send("MAIN_TURN_ON_SCANNER", "GET_TURN_OFF");
        }
    }
}

handleDisplayScannerName = (event,arg) => {
    this.setState({
        savePath : arg
    })
}

render() {
    return (
        <div>
        <div className="content-section introduction">
            <div className="feature-intro">
            <h1>Set up reader</h1>
            </div>
        </div>
        <div className="content-section implementation">
        <Dropdown
            value={this.state.cities}
            options={this.state.devices}
            onChange={this.onReaderChange}
            style={{ width: "150px" }}
            placeholder="Select the Scanner"
            optionLabel="name"
        />
        <div style={{ marginTop: ".5em" }}>
            {this.state.cities
                ? "Selected Scanner: " + this.state.cities.name
                : "No Scanner selected"}
        </div>
        <div style={{ marginTop: ".5em" }}>
            { "Previous Scanner: " + this.state.savePath}
        </div>
        </div>
    </div>
    );
}
}
