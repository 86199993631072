import React, { forwardRef, useImperativeHandle, useState} from "react";
import classNames from 'classnames';
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { saveTax, removeTax } from './TaxSerivce';
import { InputText } from "primereact/inputtext";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";

export const TaxForm = forwardRef((props, ref) => {

    const [data, setData] = useState({})
    const [formHeader, setFormHeader] = useState('Add Tax')
    const [visible, setVisible] = useState(false)
    const [errors, setErrrors] = useState({})

    useImperativeHandle(ref, ()=>({
        openForm(data){
            setData({
                id: data ? data.id : '',
                name: data ? data.name : '',
                rate01: data ? data.rate01 : 0,
                enabled: true,
            })
            setFormHeader(data?'Edit Tax':'Add Tax')
            setVisible(true)
            setErrrors({})
        }   
    }))

    const handelSave = () =>{
        setErrrors({})
        saveTax(data).then(res => {
            if(!res.errorCode){
                if(props.refreshData)
                    props.refreshData();
                onHide();
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400) setErrrors(res.errorObj)
            }
        })
    }

    const handleRemove= () => {
        removeTax(data.id).then(res => {
            if (!res.errorCode) {
                if (props.refreshData)
                    props.refreshData();
                onHide();
                showSuccessNotify('Action Submitted')
            } else {
                showErrorNotify('Cannot perform action');
            }
        });
    }

    const onHide = () => {
        setVisible(false)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} dismissable={false} onHide={()=>onHide()}>
            <h2>{formHeader}</h2>
            
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label className="p-label">* Name</label>
                    <InputText value={data.name} onChange={(e) => setData({...data, name: e.target.value} )} />
                    <span className="p-form-error">{errors.name}</span>
                </div>
                <div className="p-col-12">
                    <label className="p-label">Rate</label>
                    <InputText value={data.rate01} keyfilter="money" maxlength="6" onChange={(e) => setData({...data, rate01: e.target.value})} 
                        onFocus={(e) => setData({...data, rate01: e.target.value==='0'?'':e.target.value})}
                        onBlur={(e) => setData({...data, rate01: e.target.value?parseFloat(e.target.value).toFixed(3):0})}
                    />
                    <span className="p-form-error">{errors.rate01}</span>
                </div>
            </div>
            <div className="p-grid">
                {data.id &&
                    <div className="p-col-12 p-md-4 p-margin-top-30 p-line-top">
                        <Button label="Remove" icon="pi pi-trash" className="p-button-danger" onClick={() => handleRemove()}/>
                    </div>
                }
                <div className={classNames("p-col-12 p-r p-margin-top-30 p-line-top", {"p-md-8": data.id})}>
                    <Button label="Save" icon="pi pi-check" onClick={()=> handelSave()}/>
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={()=> onHide()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

