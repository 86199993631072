import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import moment from 'moment'
import { Sidebar } from 'primereact/sidebar'
import { Button } from 'primereact/button'
import { ATTRIBUTE_FIELD_SET_TYPE, FIELD_DIRECTION_TYPE, FIELD_DISPLAY_TYPE, FIELD_EXPRESSION_TYPE, FIELD_TYPE, GENDERS } from '../../constants'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import PhoneInput from "react-phone-number-input";
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService'
import { MultiSelect } from 'primereact/multiselect'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputMask } from 'primereact/inputmask'
import classNames from 'classnames'
import renderHTML from 'react-render-html';
import { ScrollPanel } from 'primereact/scrollpanel'
import { WaiverSignForm } from './WaiverSignForm'
import { getParticipantBookingInfo, assignParticipant } from './BookingServices'
import { getCountries, getStatesByCountry } from '../../scp/tenant-configuration/CountrySevices'

export const AssignParticipantForm = forwardRef((props, ref) => {
    const waiverForm = useRef(null)

    const [formHeader, setFormHeader] = useState('ASSIGN PARTICIPANT')
    const [createDirectly, setCreateDirectly] = useState(true)
    const [product, setProduct] = useState({})
    const [personalInfo, setPersonalInfo] = useState({})
    const [personalSet, setPersonalSet] = useState({})
    const [isNewAttendee, setNewAttendee] = useState(false)
    const [birthdayValue, setBirthdayValue] = useState(null)
    const [contacts, setContacts] = useState([])
    const [data, setData] = useState({})
    const [participant, setParticipant] = useState({})
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [customProperty, setCustomProperty] = useState({})
    const [customFieldValues, setCustomFieldValues] = useState([])
    const [waiverSet, setWaiverSet] = useState({})
    const [waiverFieldValues, setWaiverFieldValues] = useState([])
    const [isSignWaiver, setSignWaiver] = useState(true)
    const [countries, setCountries] = useState([])
    const [countryStates, setCountryStates] = useState([])

    let defaultCountry = localStorage.getItem('defaultCountry')
    defaultCountry = defaultCountry && defaultCountry!=='null' && defaultCountry!=='undefined' ? defaultCountry : null

    useEffect(()=>{
        loadCountries()
    },[])

    useEffect(()=>{
        if(contacts.length===0){
            setNewAttendee(true)
        }else{
            setNewAttendee(false)
        }
    },[contacts])

    useEffect(()=>{
        if(personalInfo.countryCode){
            loadCountryStates(personalInfo.countryCode)
        }else{
            setCountryStates([])
        }
        setPersonalInfo({...personalInfo, stateCode: null})
    },[personalInfo.countryCode])

    useEffect(() => {
        const handleScroll = (e) => {
            let tmpWaiverFields = waiverSet&&waiverSet.poolOfFields ? [...waiverSet.poolOfFields] : []

            let element = e.target
            let eleHeight = element.clientHeight
            if (element.scrollTop === e.target.scrollHeight - eleHeight) {
                let eleContainer = element.parentNode.parentNode
                let eleContainerId = eleContainer.getAttribute('id')
                let dataId = eleContainerId.replace('wv_', '')

                tmpWaiverFields.map(wf => {
                    if(wf.id === parseInt(dataId)){
                        wf.readOnly = false
                    }
                })
                setWaiverSet({...waiverSet, poolOfFields: tmpWaiverFields})
            }
        }
        
        if(visible) {
            let tmpWaiverFields = waiverSet&&waiverSet.poolOfFields ? [...waiverSet.poolOfFields] : []

            tmpWaiverFields.map(wf => {
                let contentElement = document.getElementById('wv_'+wf.id)
                let scrollPanel 
                if(contentElement){
                    scrollPanel = contentElement.getElementsByClassName("p-scrollpanel-content")[0]
                    let eleHeight = scrollPanel.offsetHeight
                    if(eleHeight <= 150){ // 150: height of content waiver
                        wf.readOnly = false
                    }else{
                        scrollPanel.addEventListener("scroll", handleScroll);
                        return () => scrollPanel.removeEventListener("scroll", handleScroll);
                    }
                }
            })
            setWaiverSet({...waiverSet, poolOfFields: tmpWaiverFields})
        }
    },[visible])

    useImperativeHandle(ref, () => ({
        openForm(orderId, orderItemId, product, participant, participantIdx, isCreateDirectly=false){
            setCreateDirectly(isCreateDirectly)
            setProduct(product)

            setData({
                orderId: orderId,
                orderItemId: orderItemId,
                participantId: participant?participant.id:null,
                participantIdx:participantIdx,
                attendeeId: participant?participant.userId:''
            })

            loadParticipantBooking(orderId, orderItemId, participant?participant.id:null)
        }
    }))

    const loadParticipantBooking = (orderId, orderItemId, participantId) => {
        getParticipantBookingInfo(orderId, orderItemId, participantId).then(res=>{
            if(!res.errorCode){
                let users = res.users?res.users:[]
                let tmpPersonalSet = res.personalSet?res.personalSet:{}
                let tmpCustomProperty = res.customProperty?res.customProperty:[]
                let tmpWaiverSet = res.waiverSet?res.waiverSet:[]
                let tmpParticipant = res.participant?res.participant:{}

                let mapFieldValues = res.participantFieldValues?res.participantFieldValues:{}
                let tmpCustomFieldValues = [];
                let tmpWaiverFieldValues = [];

                tmpCustomProperty && tmpCustomProperty.customFields && tmpCustomProperty.customFields.map(el => {
                    let itemValue = mapFieldValues[el.name];
                    let item = {
                        id: itemValue?itemValue.id:'',
                        setType: ATTRIBUTE_FIELD_SET_TYPE.custom_field,
                        fieldName: el.name,
                        storeType: el.config.storeType,
                        value: itemValue?itemValue.value:'',
                        valueArrays: itemValue && itemValue.valueArrays?itemValue.valueArrays:[],
                        dateValue: el.type===FIELD_TYPE.date && itemValue && itemValue.value?moment(itemValue.value, 'YYYY-MM-DD').toDate():'',
                        flagAnswer: itemValue?(itemValue.value==='true'?true:false):(el.defaultValue==='true'?true:false),
                    }

                    tmpCustomFieldValues.push(item);
                });

                tmpWaiverSet && tmpWaiverSet.poolOfFields && tmpWaiverSet.poolOfFields.map(el => {
                    let field = el.field
                    let itemValue = mapFieldValues[field.name];
                    let partAnswerObj = itemValue&&itemValue.storeType==='boolean'?JSON.parse(itemValue.value):{}
                    let item = {
                        id: itemValue?itemValue.id:'',
                        setType: ATTRIBUTE_FIELD_SET_TYPE.waiver,
                        fieldName: field.name,
                        fieldContent: field.config.content,
                        storeType: field.config.storeType,
                        value: field.config.displayType===FIELD_DISPLAY_TYPE.checkbox?'':itemValue?itemValue.value:'',
                        flagAnswer: itemValue?(partAnswerObj.accepted==='yes'?true:false):(el.defaultValue==='true'?true:false),
                    }
                    el.readOnly = (field.config.openInDialog||field.config.requiredReadAllToSignWaiver)?true:false

                    tmpWaiverFieldValues.push(item)
                });

                setContacts(users)
                setPersonalSet(tmpPersonalSet)
                setPersonalInfo({})
                setCustomProperty(tmpCustomProperty)
                setCustomFieldValues(tmpCustomFieldValues)
                setWaiverSet(tmpWaiverSet)
                setWaiverFieldValues(tmpWaiverFieldValues)
                setParticipant(tmpParticipant)

                setBirthdayValue(null)

                setVisible(true)
                setErrors({})
            }
        })
    }

    const loadCountries = () => {
        getCountries().then(res => setCountries(res?res:[]))
    }

    const loadCountryStates = (countryCode) => {
        getStatesByCountry(countryCode).then(res => setCountryStates(res?res:[]))
    }

    const handleSave = () => {
        let tmpData = {orderId: data.orderId, orderItemId: data.orderItemId, attendeeId: data.attendeeId, participantId: data.participantId}

        tmpData.personalInfo = {...personalInfo}
        tmpData.customFields = [...customFieldValues]
        tmpData.waiverFields = [...waiverFieldValues]

        assignParticipant(tmpData, isNewAttendee)
        .then(res=>{
            if(!res.errorCode){
                if(props.assignSuccess){
                    props.assignSuccess(data.orderItemId, data.participantIdx, res)
                }
                closeDialog()
                showSuccessNotify("Success")
            }else{
                if(res.errorCode===400 && res.errorObj) setErrors(res.errorObj)
                showErrorNotify(res.errorMessage, 'Error Message');
            }
        })
    }

    const onMemberChange = (e) => {
        setData({...data, attendeeId: e.value})
    }

    const onBirthdayPersonalChange = (e) => {
        setSignWaiver(isYoungMember(e.value))

        setPersonalInfo({...personalInfo,
            birthday: e.value&&moment(e.value).isValid()?moment(e.value).format(moment.HTML5_FMT.DATE):'',
        })
        setBirthdayValue(e.value)
    }

    const isYoungMember = (birthday) =>{
        let isYoung = true

        if(birthday){
            let nowMoment = moment()
            let birthdayMoment = moment(birthday)
            let duration = moment.duration(nowMoment.diff(birthdayMoment))
            let years = duration.years()?duration.years():0
            isYoung = years<18?true:false
        }else{
            isYoung = true
        }

        return isYoung
    }

    const onCustomFieldChange = (idx, value, field) =>{
        let tmpFieldValues = [...customFieldValues];
        onFieldChange(idx, value, field, tmpFieldValues)
        setCustomFieldValues(tmpFieldValues)
    }

    const onWaiverFieldChange = (idx, value, field) =>{
        let tmpFieldValues = [...waiverFieldValues];
        onFieldChange(idx, value, field, tmpFieldValues)
        setWaiverFieldValues(tmpFieldValues)
    }

    const onFieldChange = (idx, value, field, tmpFieldValues) => {
        let config = field&&field.config?field.config:{};

        switch(field.type){
            case FIELD_TYPE.date:
                tmpFieldValues[idx].value = value?moment(value).format(moment.HTML5_FMT.DATE):'';
                tmpFieldValues[idx].dateValue = value;
                break;
            case FIELD_TYPE.select:
                switch(config.displayType){
                    case FIELD_DISPLAY_TYPE.dropdown:
                        if(config.storeType!=='array'){
                            tmpFieldValues[idx].value = value;
                        }else{
                            tmpFieldValues[idx].valueArrays = value;
                        }
                        break;
                    case FIELD_DISPLAY_TYPE.checkbox:
                            let selectedValues = [...tmpFieldValues[idx].valueArrays];
        
                            if(value.checked)
                                selectedValues.push(value.value);
                            else
                                selectedValues.splice(selectedValues.indexOf(value.value), 1);
                    
                            tmpFieldValues[idx].valueArrays = selectedValues;
                        break;
                    case FIELD_DISPLAY_TYPE.radio:
                        tmpFieldValues[idx].value = value;
                        break;
                    default:
                        break;
                }
                break;
            case FIELD_TYPE.boolean:
                tmpFieldValues[idx].value = value+'';
                tmpFieldValues[idx].flagAnswer = value;
                break;
            case FIELD_TYPE.waiver:
                if(config.displayType===FIELD_DISPLAY_TYPE.checkbox){
                    tmpFieldValues[idx].flagAnswer = value;
                    tmpFieldValues[idx].value = '';
                }else if(config.displayType===FIELD_DISPLAY_TYPE.textbox){
                    tmpFieldValues[idx].value = value;
                }
                else if(config.displayType===FIELD_DISPLAY_TYPE.file){
                    tmpFieldValues[idx].fieldFile = value;
                }
                break;
            default:
                tmpFieldValues[idx].value = value;
                break;
        }
    }

    const onOpenToReadWaiver = (idx, field, value) => {
        setVisible(false)
        waiverForm.current.openForm(idx, field, value)
    }

    const onWaiverFormChange = (idx, field, answerValue) => {
        onWaiverFieldChange(idx, answerValue, field)
    }

    const onNewAttendee =(isAddNew)=>{
        setData({...data, attendeeId: ''})
        setNewAttendee(isAddNew)
        setSignWaiver(true)
        setBirthdayValue('')
        setPersonalInfo({
            firstName: '',
            lastName: '',
            gender: null,
            birthday: '',
            email: '',
            phone: null,
            registerType: null,
            emeContactName: '',
            emeContactPhone: null,
            emeContactEmail: ''
        })
    }

    const closeDialog = () => {
        setVisible(false)
        setSignWaiver(true)

        if(!createDirectly && props.backToForm)
            props.backToForm()
    }

    return (
        <React.Fragment>
            <WaiverSignForm ref={waiverForm} 
                onAccept={(idx, field, answerValue) => onWaiverFormChange(idx, field, answerValue)}
                backToForm={()=>setVisible(true)}
            />

            <Sidebar visible={visible} className="p-sidebar-lg" position="right" blockScroll={true} dismissable={false} onHide={() => closeDialog()} style={{overflowY: 'auto'}} >
                <h2>{formHeader}</h2>

                {createDirectly && 
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-message p-component p-message-success p-message-enter-done">
                            <div className="p-message-wrapper">
                                <span className="p-message-icon pi  pi-check"></span>
                                <span className="p-message-detail">Product has been added to cart. Please assign participant to continue.</span>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className="p-grid">
                    <div className="p-col-12">
                        <h3>Product: "{product.name}"</h3>
                    </div>
                    {contacts.length>0 &&
                        <div className={classNames("p-col-12", {"p-d-none": isNewAttendee})}>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><strong>Attendee:</strong></span>
                                <Dropdown value={data.attendeeId} options={contacts} onChange={(e) => onMemberChange(e)} placeholder="Select attendee"/>
                                <Button label="New member" icon="pi-md-person-add" onClick={() => onNewAttendee(true)} />
                            </div>
                            <div className="p-error">{errors.attendeeId}</div>
                        </div>
                    }
                
                    <div className={classNames("p-col-12", {"p-d-none": (contacts.length>0 && !isNewAttendee)})}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <span className="p-w-bold p-size-24">{personalSet&&personalSet.label ? personalSet.label : 'PERSONAL INFORMATION'}</span>
                            </div>
                            <div className="p-col-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-8 p-md-offset-3">
                                        <Button label="Back to select member in options" className="p-button-info" onClick={() => onNewAttendee(false)} />
                                    </div>
                                </div>
                            </div>
                            
                            {(()=>{
                                let personalField = personalSet&&personalSet.personalField ? personalSet.personalField : {}

                                    return <React.Fragment>
                                        {personalField.useTitleName &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredTitleName && '*'} {personalField.titleNameLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        {(()=>{
                                                            let tmpTitleArr = personalField.titleValue?personalField.titleValue.map(value=>{return{value:value, label: value}}):[]
                                                            return <Dropdown value={personalInfo.title} options={tmpTitleArr} onChange={(e) => setPersonalInfo({...personalInfo, title: e.value})} />
                                                        })()}
                                                        <div className="p-error">{errors.title}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="p-col-12">
                                            <div className="p-grid p-fluid p-ai-center">
                                                <div className="p-col-12 p-md-3 p-text-right">
                                                    <label>* {personalField.firstNameLabel?personalField.firstNameLabel:'First Name'}</label>
                                                </div>
                                                <div className="p-col-12 p-md-8">
                                                    <InputText value={personalInfo.firstName} onChange={(e) => setPersonalInfo({...personalInfo, firstName: e.target.value})} />
                                                    <div className="p-error">{errors.firstName}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {personalField.useMiddleName &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredMiddleName && '*'} {personalField.middleNameLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <InputText value={personalInfo.middleName} onChange={(e) => setPersonalInfo({...personalInfo, middleName: e.target.value})} />
                                                        <div className="p-error">{errors.middleName}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="p-col-12">
                                            <div className="p-grid p-fluid p-ai-center">
                                                <div className="p-col-12 p-md-3 p-text-right">
                                                    <label>* {personalField.lastNameLabel?personalField.lastNameLabel:'Last Name'}</label>
                                                </div>
                                                <div className="p-col-12 p-md-8">
                                                    <InputText value={personalInfo.lastName} onChange={(e) => setPersonalInfo({...personalInfo, lastName: e.target.value})} />
                                                    <div className="p-error">{errors.lastName}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {personalField.useGender &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredGender && '*'} {personalField.genderLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <Dropdown value={personalInfo.gender} options={GENDERS} onChange={(e) => setPersonalInfo({...personalInfo, gender: e.value})} />
                                                        <div className="p-error">{errors.gender}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {personalField.useBirthday &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredBirthday && '*'} {personalField.birthdayLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <Calendar value={birthdayValue} onChange={(e) => onBirthdayPersonalChange(e)} showIcon={true} dateFormat="mm-dd-yy" mask="99-99-9999" />
                                                        <div className="p-error">{errors.birthday}</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        }
                                        {personalField.useEmail &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredEmail && '*'} {personalField.emailLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <InputText keyfilter="email" value={personalInfo.email} onChange={(e) => setPersonalInfo({...personalInfo, email: e.target.value})} />
                                                        <div className="p-error">{errors.email}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {personalField.usePhone &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredPhone && '*'} {personalField.phoneLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <PhoneInput value={personalInfo.phone} defaultCountry={defaultCountry} onChange={(e) => setPersonalInfo({...personalInfo, phone: e})} className="p-component p-inputtext p-phoneinput" style={{width: "100%"}}/>
                                                        <div className="p-error">{errors.phone}</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        }
                                        {personalField.usePassportNRIC &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredPassportNRIC && '*'} {personalField.passportNRICLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <InputText value={personalInfo.passportNRIC} onChange={(e) => setPersonalInfo({...personalInfo, passportNRIC: e.target.value})} />
                                                        <div className="p-error">{errors.passportNRIC}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {personalField.useNationality &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredNationality && '*'} {personalField.nationalityLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        <Dropdown value={personalInfo.nationalityCode} options={countries} filter onChange={(e) =>setPersonalInfo({...personalInfo, nationalityCode: e.value})} />
                                                        <div className="p-error">{errors.nationality}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {personalField.useRegisterType &&
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid p-ai-center">
                                                    <div className="p-col-12 p-md-3 p-text-right">
                                                        <label>{personalField.requiredRegisterType && '*'} {personalField.registerTypeLabel}</label>
                                                    </div>
                                                    <div className="p-col-12 p-md-8">
                                                        {(()=>{
                                                            let valueList = personalField.registerTypeList?personalField.registerTypeList:[]
                                                            valueList.map(value => {return {value: value, label: value}})
                                                            return <Dropdown value={personalInfo.registerType} options={valueList} onChange={(e) => setPersonalInfo({...personalInfo, registerType: e.value})} />
                                                        })()}
                                                        <div className="p-error">{errors.registerType}</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        }
                                        {personalField.useAddress &&
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <hr />
                                                </div>
                                                <div className="p-col-12">
                                                    <span className="p-w-bold p-size-24">ADDRESS</span>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>* Address 1</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <InputText value={personalInfo.address1} onChange={(e) => setPersonalInfo({...personalInfo, address1: e.target.value})} />
                                                            <div className="p-error">{errors.address1}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>Address </label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <InputText value={personalInfo.address2} onChange={(e) => setPersonalInfo({...personalInfo, address2: e.target.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>* City </label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <InputText value={personalInfo.city} onChange={(e) => setPersonalInfo({...personalInfo, city: e.target.value})} />
                                                            <div className="p-error">{errors.city}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>* Zip Code </label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <InputText value={personalInfo.zipCode} onChange={(e) => setPersonalInfo({...personalInfo, zipCode: e.target.value})} />
                                                            <div className="p-error">{errors.zipCode}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>Country</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <Dropdown value={personalInfo.countryCode} options={countries} filter onChange={(e) => setPersonalInfo({...personalInfo, countryCode: e.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>State/Province</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <Dropdown value={personalInfo.stateCode} options={countryStates} filter onChange={(e) => setPersonalInfo({...personalInfo, stateCode: e.value})} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {personalField.useEmerContact &&
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <hr />
                                                </div>
                                                <div className="p-col-12">
                                                    <span className="p-w-bold p-size-24">EMERGENCY</span>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>{personalField.requiredEmerContact && '*'} {personalField.emerContactNameLabel}</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <InputText value={personalInfo.emeContactName} onChange={(e) => setPersonalInfo({...personalInfo, emeContactName: e.target.value})} />
                                                            <div className="p-error">{errors.emeContactName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>{personalField.requiredEmerContact && '*'} {personalField.emerContactPhoneLabel}</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <PhoneInput value={personalInfo.emeContactPhone} defaultCountry={defaultCountry} onChange={(e) => setPersonalInfo({...personalInfo, emeContactPhone: e})} className="p-component p-inputtext p-phoneinput" style={{width: "100%"}}/>
                                                            <div className="p-error">{errors.emeContactPhone}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-ai-center">
                                                        <div className="p-col-12 p-md-3 p-text-right">
                                                            <label>{personalField.requiredEmerContact && '*'} {personalField.emerContactEmailLabel}</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <InputText keyfilter="email" value={personalInfo.emeContactEmail} onChange={(e) => setPersonalInfo({...personalInfo, emeContactEmail: e.target.value})} />
                                                            <div className="p-error">{errors.emeContactEmail}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }     
                                    </React.Fragment>
                            })()}        
                        </div>
                    </div>
                    <div className="p-col-12">
                        {(()=>{
                            if(customProperty && customProperty.customFields && customProperty.customFields.length>0){
                                let customFields = customProperty.customFields

                                return <React.Fragment>
                                    <hr />
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <span className="p-w-bold p-size-24">{customProperty.customSetName ? customProperty.customSetName : 'ADDITIONAL INFORMATION'}</span>
                                        </div>
                                        <div className="p-col-12">
                                            {customFields.map((el, idx) => {
                                                let field = el;
                                                let config = field.config;

                                                if(field.type!==FIELD_TYPE.boolean){
                                                    return (
                                                        <div className="p-grid p-fluid p-ai-center">
                                                            <div className="p-col-12 p-md-3 p-text-right">
                                                                <label>{el.required?'* ':''}{field.label}</label>
                                                            </div>
                                                            <div className="p-col-12 p-md-8">
                                                                {(() => {
                                                                    switch(field.type){
                                                                        case FIELD_TYPE.text:
                                                                            if(config.expressionValidationType === FIELD_EXPRESSION_TYPE.maskString){
                                                                                return <InputMask value={customFieldValues[idx]?customFieldValues[idx].value:''} onChange={(e) => onCustomFieldChange(idx, e.target.value, field)} mask={config.expressionValidationInput}></InputMask>;
                                                                            }else{
                                                                                return <InputText value={customFieldValues[idx]?customFieldValues[idx].value:''} onChange={(e) => onCustomFieldChange(idx, e.target.value, field)} minLength={config.minLength?config.minLength:''} maxLength={config.maxLength?config.maxLength:''}/>;
                                                                            }
                                                                        case FIELD_TYPE.email:
                                                                            return <InputText value={customFieldValues[idx]?customFieldValues[idx].value:''} onChange={(e) => onCustomFieldChange(idx, e.target.value, field)} keyfilter="email"/>;
                                                                        case FIELD_TYPE.number:
                                                                            return <InputText value={customFieldValues[idx]?customFieldValues[idx].value:''} onChange={(e) => onCustomFieldChange(idx, e.target.value, field)} keyfilter="int"/>;
                                                                        case FIELD_TYPE.phone:
                                                                            return <PhoneInput value={customFieldValues[idx]?customFieldValues[idx].value:''} defaultCountry={defaultCountry} onChange={(e) => onCustomFieldChange(idx, e, field)} className="p-component p-inputtext p-phoneinput" />
                                                                        case FIELD_TYPE.select:
                                                                            let valueList = [...config.dataList]

                                                                            if(config.displayType===FIELD_DISPLAY_TYPE.dropdown){
                                                                                let dataList = [...config.listValues];

                                                                                if(config.storeType!=='array'){
                                                                                    if(config.useNoneLabel){
                                                                                        dataList.unshift({value: '', label: config.noneLabel});
                                                                                    }
                                                                                    
                                                                                    return <Dropdown value={customFieldValues[idx]?customFieldValues[idx].value:null} onChange={(e) => onCustomFieldChange(idx, e.value, field)} options={dataList} style={{width: '100%'}}/>
                                                                                }else{
                                                                                    return <MultiSelect value={customFieldValues[idx]?customFieldValues[idx].valueArrays:[]} onChange={(e) => onCustomFieldChange(idx, e.value, field)} options={dataList} style={{width: '100%'}}/>
                                                                                }
                                                                            }else{
                                                                                return (
                                                                                    <div className="p-grid">
                                                                                        {config.directionType === FIELD_DIRECTION_TYPE.horizontal &&
                                                                                            <React.Fragment>
                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                                    <div className="p-col-12">
                                                                                                        <RadioButton inputId={"rb_"+field.name+"_none"} value="" onChange={(e) => onCustomFieldChange(idx, e.value, field)} checked={customFieldValues[idx]&&customFieldValues[idx].value===''?true:false}></RadioButton>
                                                                                                        <label htmlFor={"rb_"+field.name+"_none"} className="p-checkbox-label p-ml-1">{config.noneLabel}</label>
                                                                                                    </div>
                                                                                                }
                                                                                                {valueList.map((v, i) => {
                                                                                                        return (
                                                                                                            <div key={i} className="p-col-12">
                                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                                    <React.Fragment>
                                                                                                                        <Checkbox inputId={"cb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idx, e , field)} checked={customFieldValues[idx]&&customFieldValues[idx].valueArrays.includes(v)}></Checkbox>
                                                                                                                        <label htmlFor={"cb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1">{v}</label>
                                                                                                                    </React.Fragment>
                                                                                                                }
                                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                                    <React.Fragment>
                                                                                                                        <RadioButton inputId={"rb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idx, e.value, field)} checked={customFieldValues[idx]&&customFieldValues[idx].value===v?true:false}></RadioButton>
                                                                                                                        <label htmlFor={"rb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1">{v}</label>
                                                                                                                    </React.Fragment>
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                    })    
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {config.directionType === FIELD_DIRECTION_TYPE.vertical &&
                                                                                            <div className="p-col-12">
                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                                    <React.Fragment>
                                                                                                        <RadioButton inputId={"rb_"+field.name+"_none"} value="" onChange={(e) => onCustomFieldChange(idx, e.value, field)} checked={customFieldValues[idx]&&customFieldValues[idx].value===''?true:false}></RadioButton>
                                                                                                        <label htmlFor={"rb_"+field.name+"_none"} className="p-checkbox-label p-ml-1 p-mr-3">{config.noneLabel}</label>
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                                {valueList.map((v, i) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={i}>
                                                                                                            {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                                <React.Fragment>
                                                                                                                    <Checkbox inputId={"cb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idx, e , field)} checked={customFieldValues[idx]&&customFieldValues[idx].valueArrays.includes(v)}></Checkbox>
                                                                                                                    <label htmlFor={"cb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1 p-mr-3">{v}</label>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                            {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                                <React.Fragment>
                                                                                                                    <RadioButton inputId={"rb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idx, e.value, field)} checked={customFieldValues[idx]&&customFieldValues[idx].value===v?true:false}></RadioButton>
                                                                                                                    <label htmlFor={"rb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1 p-mr-3">{v}</label>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })}        
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        case FIELD_TYPE.textarea:
                                                                            return <InputTextarea value={customFieldValues[idx]?customFieldValues[idx].value:''} onChange={(e) => onCustomFieldChange(idx, e.target.value, field)} rows={config.rows} autoResize={true}/>;
                                                                        case FIELD_TYPE.date:
                                                                            return <Calendar value={customFieldValues[idx]?customFieldValues[idx].dateValue:''} onChange={(e) => onCustomFieldChange(idx, e.value, field)} showIcon={true} dateFormat="mm-dd-yy" mask="99-99-9999" />
                                                                        case FIELD_TYPE.file:
                                                                            break;
                                                                        default:
                                                                            break;
                                                                    }
                                                                })()}
                                                                <div className="p-error">{errors["fieldValues_"+field.name]}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return <div className="p-grid p-fluid">
                                                        <div className="p-col-12 p-md-8 p-md-offset-3">
                                                            {(() => {
                                                                if(config.displayType===FIELD_DISPLAY_TYPE.radio){
                                                                    return (
                                                                        <React.Fragment>
                                                                            <label className="p-label p-margin-botton-5">{field.label}</label>
                                                                            <div className="p-grid p-margin-top-0">
                                                                                <div className="p-col-12">
                                                                                    <RadioButton inputId={"rb_0_"+field.name} value={true} onChange={(e) => onCustomFieldChange(idx, e.value, field)} checked={customFieldValues[idx]&&customFieldValues[idx].flagAnswer}/>
                                                                                    <label htmlFor={"rb_0_"+field.name} className="p-checkbox-label p-ml-1 p-mr-4">Yes</label>
                                                                                    <RadioButton inputId={"rb_1_"+field.name} value={false} onChange={(e) => onCustomFieldChange(idx, e.value, field)} checked={customFieldValues[idx]&&!customFieldValues[idx].flagAnswer}/>
                                                                                    <label htmlFor={"rb_1_"+field.name} className="p-checkbox-label p-ml-1">No</label>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    );
                                                                }else{
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Checkbox inputId={"cb_"+field.name} onChange={(e) => onCustomFieldChange(idx, e.checked, field)} checked={customFieldValues[idx]&&customFieldValues[idx].flagAnswer}></Checkbox>
                                                                            <label htmlFor={"cb_"+field.name} className="p-checkbox-label p-ml-1">{field.label}</label>
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            })()}
                                                            {config.content &&
                                                                <div className="p-margin-top-10 p-size-13">{config.content}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        })()}
                    </div>
                    <div className="p-col-12">
                        {(()=>{
                            if(waiverSet && waiverSet.poolOfFields && waiverSet.poolOfFields.length>0 && isSignWaiver){
                                let waiverFields = waiverSet.poolOfFields

                                return <React.Fragment>
                                    <hr />
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <span className="p-w-bold p-size-24">{waiverSet.label ? waiverSet.label : 'WAIVER'}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <ul className="p-list-group">
                                                {waiverFields.map((el, idx) => {
                                                    let field = el.field;
                                                    let config = field.config;

                                                    if(field.type===FIELD_TYPE.waiver){
                                                        return <li key={idx} className="p-mb-3">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <label className="p-w-bold">{el.required&&'*'} {field.label}</label>
                                                                    {config.openInDialog ?
                                                                        <Button label="Read waiver to sign" className="p-button-sm p-ml-2" onClick={() => onOpenToReadWaiver(idx, field, config.displayType===FIELD_DISPLAY_TYPE.checkbox?waiverFieldValues[idx].flagAnswer:waiverFieldValues[idx].value)} style={{width: 'auto'}} />
                                                                        :
                                                                        <React.Fragment>
                                                                            {config.content &&
                                                                                <React.Fragment>
                                                                                    {el.required && config.requiredReadAllToSignWaiver &&
                                                                                        <div className="p-mt-2 p-mb-2"><i className="p-error">(*) Must read all conditions</i></div>
                                                                                    }
                                                                                    <div className="p-mb-2" style={{fontSize: '13px'}}>
                                                                                        <ScrollPanel id={'wv_'+el.id} className="scroll-container p-mt-2" style={{ maxHeight: 150}}>
                                                                                            {renderHTML(config.content)}
                                                                                        </ScrollPanel>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                    <div className="p-mt-3">
                                                                        {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                            <Checkbox inputId={"cb_"+field.id} className="p-mr-1" disabled={el.readOnly} onChange={(e) => onWaiverFieldChange(idx, e.checked, field)} checked={waiverFieldValues[idx]&&waiverFieldValues[idx].flagAnswer}></Checkbox>
                                                                        }
                                                                        {config.displayType===FIELD_DISPLAY_TYPE.textbox &&
                                                                            <InputText value={waiverFieldValues[idx]?waiverFieldValues[idx].value:''} className="input-sm" onChange={(e) => onWaiverFieldChange(idx, e.target.value, field)}/>
                                                                        }
                                                                        <label htmlFor={"cb_"+field.id}>{config.waiverPromo}</label>
                                                                        {config.collectPhysicalWaiver &&
                                                                            <React.Fragment>
                                                                                <div className="p-margin-10-0"><input type="file" onChange={(e) => onWaiverFieldChange(idx, e.target.files[0], field)} /></div>
                                                                                {config.waiverTemplateURL &&
                                                                                    <React.Fragment>
                                                                                        <div className="p-margin-10-0">Download waiver link <a href={`${config.waiverTemplateURL}`} className="nav-link" target="_blank" >here</a></div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                        <div className="p-my-2 p-error">{errors["fieldValues_"+field.id]}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        })()}
                    </div>
                </div>
                <hr/>
                <div className="p-grid">
                    <div className="p-col-12 p-d-flex p-jc-between">
                        <Button label="Cancel" className="p-px-6" onClick={() => closeDialog()}/>
                        <Button label="Save" className="p-px-6 p-button-secondary"  onClick={(e) => handleSave()}/>
                    </div>
                </div>
            </Sidebar>
        </React.Fragment>
    )
})