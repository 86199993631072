import React, { useState, useEffect } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useRouteMatch } from 'react-router-dom'
import { SelectButton } from 'primereact/selectbutton'
import { Calendar } from 'primereact/calendar'
import { AssetRentingSchedules } from './AssetRentingSchedules'
import { Participants } from './Participants'
import { getListTaxons } from '../../pim/taxon/TaxonServices'
import { APP_FEP, TAXONOMY_TYPE } from '../../constants'
import { CustomerRequestView } from './CustomerRequestView'

const VIEW_SCREEN = {
    participant: {value: 'participant', label: 'Participants'},
    asset_schedule: {value: 'asset_schedule', label: 'Asset renting schedules'},
    asset_subscription: {value: 'asset_subscription', label: 'Asset subscriptions'},
    customer_request: {value: 'customer_request', label: 'Customer Request'},
}

export const OperatingView = () => {
    const match = useRouteMatch()
    const [viewScreen, setViewScreen] = useState(VIEW_SCREEN.participant.value)
    const [viewDate, setViewDate] = useState([new Date()])
    const [viewBranch, setViewBranch] = useState(0)
    const [branches, setBranches] = useState({})
    const [baseFilter, setBaseFilter] = useState({
        orderIdNumber: '',
        invoiceNumber: '',
        buyer: ''
    })

    useEffect(() => {
        loadAllBranchs();
    },[])

    const loadAllBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value})
        .then(res => {
            let branchObj = {}
            if(res && res.length>0){
                if(res.length>1){
                    branchObj[0] = {value: 0, label: 'All'}
                    res.map(o => {
                        branchObj[o.value] = o
                    })
                }else{
                    setViewBranch(res[0].value)
                }
            }else{
                setViewBranch(0)
            }

            setBranches(branchObj)
        })
    }

    return(
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Operation Monitoring</BreadcrumbsItem>

            <div className="p-col-12">
                <div className="card card-w-title p-pb-3">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h1 className="p-w-bold p-m-0">Operation Monitoring</h1>
                                </div>
                                <div className="p-col-12">
                                    <SelectButton value={viewScreen} options={Object.values(VIEW_SCREEN)} onChange={(e) => e && e.value && setViewScreen(e.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-d-flex p-ai-center">
                                    <span className="p-nowrap p-size-16 p-mr-3">View Date:</span>
                                    <Calendar value={viewDate} inputClassName="p-size-20" onChange={e => setViewDate(e.value)} showIcon={true} selectionMode="range" format="mm/dd/yy" />
                                </div>
                                {branches && Object.values(branches).length>0 && viewScreen!==VIEW_SCREEN.customer_request.value &&
                                    <div className="p-col-12 p-d-flex p-ai-center">
                                        <span className="p-nowrap p-size-16 p-mr-3">View Branch:</span>
                                        <div style={{width: '100%'}}>
                                            <SelectButton value={viewBranch} options={Object.values(branches)} onChange={(e) => e.value!==null && e.value!==undefined && setViewBranch(e.value)}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="card">
                    <h2 className="p-w-bold p-mt-0">{VIEW_SCREEN[viewScreen].label}</h2>

                    <div className="p-grid">
                        <div className="p-col-12">
                            {viewScreen===VIEW_SCREEN.participant.value && 
                            <Participants viewDate={viewDate} viewBranch={viewBranch}/>
                            }

                            {(viewScreen===VIEW_SCREEN.asset_schedule.value || viewScreen===VIEW_SCREEN.asset_subscription.value) && 
                            <AssetRentingSchedules viewDate={viewDate} viewBranch={viewBranch} subscription={viewScreen===VIEW_SCREEN.asset_subscription.value}/>
                            }
                            {viewScreen===VIEW_SCREEN.customer_request.value && 
                                <CustomerRequestView viewDate={viewDate}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}