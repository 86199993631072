import React, { forwardRef, useEffect, useImperativeHandle, useState} from "react";
import moment from "moment";
import 'moment-timezone';
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Fieldset } from "primereact/fieldset";
import { returnAsset } from "../../pim/asset-schedule/SchedulesService";
import { APP_FEP, TAXONOMY_TYPE } from "../../constants";
import { getListInventories } from "../../pim/inventory/InventoryService";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { getListTaxons } from "../../pim/taxon/TaxonServices";
import { getTimeZone } from "../../core/service/CommonService";
import { showloading, stoploading } from "../../core/service/LoadingService";

export const ReturnAssetForm = forwardRef((props, ref) => {
    const timezone = getTimeZone()

    const [data, setData] = useState({})
    const [branchs, setBranchs] = useState([])
    const [inventories, setInventories] = useState([])
    const [schedules, setSchedules] = useState({})
    const [formHeader, setFormHeader] = useState('Return Asset')
    const [visible, setVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        loadBranches()
    },[])

    useEffect(() => {
        if(data.branchId)
            loadInventories(data.branchId);
        else
            setInventories([])
    }, [data.branchId])

    useImperativeHandle(ref, ()=>({
        openForm(scheduleData){
            setData({
                assetScheduleId: scheduleData.id,
                branchId: scheduleData.asset&&scheduleData.asset.branch?scheduleData.asset.branch.id:null,
                inventoryId: scheduleData.asset&&scheduleData.asset.currentInventory?scheduleData.asset.currentInventory.id:null,
                productDamageDescription: '',
                overTime: isOverTime(scheduleData.end),
                additionalCharge: 0,
                feeDescription: '',
            })
            setSchedules(scheduleData)
            setVisible(true)
            setErrors({})
        }   
    }))

    const handleSubmit = () => {
        showloading()
        returnAsset(data)
        .then(res => {
            if(!res.errorCode){
                if(props.reloadTable){
                    props.reloadTable()
                }
                onHide()
                showSuccessNotify('Action submitted');
            }else{
                if (res.errorCode === 400)  setErrors(res.errorObj);
                showErrorNotify('Cannot perform action');
            }
            stoploading()
        })
    }

    const loadBranches = () => {
        getListTaxons({type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res));
    }

    const loadInventories = (branchId) => {
        getListInventories({app: APP_FEP, branchId: branchId}, true).then(res => setInventories(res))
    }

    const isOverTime = (endDate) => {
        const strNow = timezone?moment().tz(timezone).format('YYYY-MM-DD HH:mm'):moment().format('YYYY-MM-DD HH:mm')

        const now = moment(strNow)
        const end = moment(endDate)

        return now.isAfter(end)
    }

    const onHide = () => {
        setVisible(false)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={onHide}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{formHeader}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={onHide}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Fieldset legend="Rental Schedule">
                        <div className="p-grid">
                            <div className="p-col-12">
                                Asset: {schedules.asset&&schedules.asset.name}
                            </div>
                            <div className="p-col-12">
                                Start: {schedules.startDate && moment(schedules.startDate).format('MM/DD/YYYY')} {schedules.startTime && moment(schedules.startTime, moment.HTML5_FMT.TIME).format('hh:mm A')}
                            </div>
                            <div className="p-col-12">
                                End: {schedules.endDate && moment(schedules.endDate).format('MM/DD/YYYY')} {schedules.endTime && moment(schedules.endTime, moment.HTML5_FMT.TIME).format('hh:mm A')}
                            </div>
                            {data.overTime &&
                                <div className="p-col-12"><span className="p-form-error">(*) The rental period is over</span></div>
                            }
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Asset Information">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label>* Branch</label>
                                <Dropdown value={data.branchId} options={branchs} onChange={(e) => setData({...data, branchId: e.value, inventoryId: null})} />
                                <div className="p-form-error">{errors.branchId}</div>
                            </div>
                            <div className="p-col-12">
                                <label>* Current Inventory</label>
                                <Dropdown value={data.inventoryId} options={inventories} onChange={(e) => setData({...data, inventoryId: e.value})} />
                                <div className="p-form-error">{errors.inventoryId}</div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Product damage tracking">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Checkbox inputId="ckHasDamage" checked={data.hasDamage} onChange={(e) => setData({...data, hasDamage: e.checked})} />
                                <label htmlFor="ckHasDamage">Has damage?</label>
                            </div>
                            {data.hasDamage &&
                                <div className="p-col-12">
                                    <label>Description of damage</label>
                                    <InputTextarea value={data.productDamageDescription} onChange={(e) => setData({...data, productDamageDescription: e.target.value})} />
                                </div>
                            }
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Additional charge">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label>Fee</label>
                                <InputText keyfilter="money" value={data.additionalCharge} min={0} onChange={(e) => setData({...data, additionalCharge: e.target.value})} />
                                <div className="p-form-error">{errors.additionalCharge}</div>
                            </div>
                            <div className="p-col-12">
                                <label>Description</label>
                                <InputTextarea value={data.feeDescription} onChange={(e) => setData({...data, feeDescription: e.target.value})} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Save" icon="pi pi-check" onClick={()=> handleSubmit()}/>
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={()=> onHide()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

