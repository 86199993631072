import React, {Component} from "react";
import moment from 'moment';
import {ProgramServices} from "./ProgramServices";
import {AttributeServices, getListAttributeSets} from "../../scp/attribute/AttributeServices";
import {Fieldset} from "primereact/fieldset";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {MaskedCalendar} from "../../core/components/calendar/MaskedCalendar";
import { getListGrades} from "../../scp/tenant-configuration/ConfigruationServices";
import { showNotification } from "../../core/service/NotificationService";
import { ATTRIBUTE_FIELD_SET_TYPE } from "../../constants";
import { FieldWrapper } from "../../core/components/FieldWrapper";

const highlightChanged = (a, b) => {
    let flag = false;
    if(Array.isArray(a)){
        flag = a.length !== b.length && a.sort() !== b.sort();
    } else {
        flag = a !== b;
    }
    return flag ? " input-highlight" : "";
}


const highlightDateChanged = (a, b) => {
    const data = new Date(a);
    const termData = new Date(b);
    return a && data.getTime() !== termData.getTime() ? " input-highlight" : "";
}

export class RegistrationSettingsForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: this.props.data,
            personalFieldSets: [],
            customFieldSets: [],
            waiverFieldSets: [],
            courses: [],
            grades: [],
            errors: {},
            viewMode: true,
            isDirty: false,
            tmpData: this.props.data,
            tmpGradeHolder: []
        }
        this.programServices = new ProgramServices();
        this.attributeServices = new AttributeServices();
    }

    componentDidMount(){
        this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.personal);
        this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.custom_field);
        this.getListAttributeSet(ATTRIBUTE_FIELD_SET_TYPE.waiver);
        this.getListAllCourses(this.props.courseId);
        // this.getListGrades();
        // this.displayGrades(this.state.data.grades)
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.data).length!==0){
    //         if(JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)){
    //             if(JSON.stringify(this.state.data) !== JSON.stringify(this.state.termData)){
    //                 this.props.formEvent.emit('ENTER_EDIT_MODE')
    //             }else{
    //                 this.props.updating(false);
    //             }
    //         }
    //     }
    // }

    getListAttributeSet = (type) => {
        getListAttributeSets(type)
        .then(res => {
            res.unshift({value: null, label: 'Not use'});
            switch(type){
                case 'program':
                    this.setState({attrSets: res});
                    break;
                case ATTRIBUTE_FIELD_SET_TYPE.custom_field:
                    this.setState({customFieldSets: res});
                    break;
                case ATTRIBUTE_FIELD_SET_TYPE.waiver:
                    this.setState({waiverFieldSets: res});
                    break;
                case ATTRIBUTE_FIELD_SET_TYPE.personal:
                    this.setState({personalFieldSets: res});
                    break;
                default: break;
            }
        });
    }

    getListAllCourses = (exceptId) => {
        this.programServices.getListAllCourses()
        .then(res => {
            var index = res.findIndex((el) => el.value === exceptId);
            if (index > -1) {
                res.splice(index, 1);
            }
            this.setState({courses: res});
        });
    }

    getListGrades = () => {
        getListGrades()
        .then(res => {
            this.setState({grades: res});
        });
    }

    onEditMode = () => {
        this.setState({
            viewMode: false,
            isDirty: false,
        })
    }

    onCancelViewMode = () => {
        this.setState({
            discardVisible: this.state.isDirty ? true : false,
            viewMode: this.state.isDirty ? false : true
        })
    }

    handleSaveRegistrationSettings = () => {
        this.programServices.saveRegistrationSettings(this.state.data).then(res => {
            if(!res.errorCode){
                this.props.formEvent.emit('CHANGE_SAVED');
                this.props.refreshData(res);
                this.displayGrades(this.state.data.grades);
                showNotification('success', 'Success Message', 'Action submitted');
                this.setState({
                    data: this.props.data,
                    isDirty: false,
                    viewMode: true,
                    tmpData: this.props.data,
                    errors: {}
                })
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });

    }

    onRegStartDateChange = (e) => {
        this.setState({
            data: { ...this.state.data, 
                regStartDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                regStartDateValue: e.value,
                memberRegStartDate: this.state.data.memberRegStartDate ? this.state.data.memberRegStartDate : (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegStartDateValue: this.state.data.memberRegStartDateValue ? this.state.data.memberRegStartDateValue : e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onRegEndDateChange = (e) => {
        this.setState({
            data: { ...this.state.data, 
                regEndDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                regEndDateValue: e.value,
                memberRegEndDate: this.state.data.memberRegEndDate?this.state.data.memberRegEndDate:(e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegEndDateValue: this.state.data.memberRegEndDateValue?this.state.data.memberRegEndDateValue:e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onMemberRegStartDateChange = (e) => {
        this.setState({
            data: { ...this.state.data, 
                memberRegStartDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegStartDateValue: e.value,
                regStartDate: this.state.data.regStartDate ? this.state.data.regStartDate : (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                regStartDateValue: this.state.data.regStartDateValue ? this.state.data.regStartDateValue : e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onMemberRegEndDateChange = (e) => {
        this.setState({
            data: { ...this.state.data, 
                memberRegEndDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                memberRegEndDateValue: e.value,
                regEndDate: this.state.data.regEndDate ? this.state.data.regEndDate : (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                regEndDateValue: this.state.data.regEndDateValue ? this.state.data.regEndDateValue : e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onPriorityRegStartDateChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                priorityRegStartDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''),
                priorityRegStartDateValue: e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    displayCustomField = (id) => {
        const customField = this.state.customFieldSets.find(customField => customField.value === id);
        return (
            <div>
                {customField ? customField.label : 'Not use'}
            </div>
        )
    }

    displayWaiverField = (id) => {
        const waiverField = this.state.waiverFieldSets.find(waiverField => waiverField.value === id);
        return (
            <div>
                {waiverField ? waiverField.label : 'Not use'}
            </div>
        )
    }

    displayPersonalField = (id) => {
        const personalFiel = this.state.personalFieldSets.find(personalField => personalField.value === id);
        return (
            <div>
                {personalFiel ? personalFiel.label : 'Not use'}
            </div>
        )
    }

    onChangeDicarded = () => {
        this.setState({
            data: this.props.data,
            errors:{},
            viewMode: true,
            isDirty: false,
            tmpData: this.props.data,
        });
    }

    resetChange = (keyField) => {
        this.setState({
            data: {...this.state.data,
                [keyField]: this.state.tmpData[keyField]
            }
        })
    }

    resetDateChange = (keyField) => {
        let keyFieldLabel = keyField.replace("Value", "");
        this.setState({
            data: {
                ...this.state.data,
                [keyFieldLabel]: this.state.tmpData[keyFieldLabel],
                [keyField]: this.state.tmpData[keyField],
            }
        })
    }

    displayGrades = (data) =>{
        let arrayHolder = []
        data.forEach( item =>{
            arrayHolder.push(item)
            arrayHolder.push(",")
        })
        this.setState({
            tmpGradeHolder : arrayHolder
        })
        return(
            <div>{this.state.tmpGradeHolder}</div>
        )
    }

    focusField = (e) => {
        if(e.target.value === '0') {
            e.target.select()
        }
    }

    render(){
        return (
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Fieldset legend="Field Set">
                        {!this.props.viewMode ?
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                <FieldWrapper col="p-col-12" keyField="customFieldSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.customFieldSetId, this.state.tmpData.customFieldSetId)}>
                                    <label className="p-label">Custom Field Set</label>
                                    <Dropdown value={this.state.data.customFieldSetId} options={this.state.customFieldSets} showClear={true} onChange={(e) => {this.setState({data: {...this.state.data, customFieldSetId: e.value}}); this.props.formEvent.emit('ENTER_EDIT_MODE')}} style={{width:'100%'}}/>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12" keyField="waiverFieldSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.waiverFieldSetId, this.state.tmpData.waiverFieldSetId)}>
                                    <label className="p-label">Waiver Field Set</label>
                                    <Dropdown value={this.state.data.waiverFieldSetId} options={this.state.waiverFieldSets} showClear={true} onChange={(e) => {this.setState({data: {...this.state.data, waiverFieldSetId: e.value}}); this.props.formEvent.emit('ENTER_EDIT_MODE')}} style={{width:'100%'}}/>
                                </FieldWrapper>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                <FieldWrapper col="p-col-12" keyField="customFieldSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.customFieldSetId, this.state.tmpData.customFieldSetId)}>
                                    <label className="p-label">Custom Field Set</label>
                                    {this.displayCustomField(this.state.data.customFieldSetId)}
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12" keyField="waiverFieldSetId" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.waiverFieldSetId, this.state.tmpData.waiverFieldSetId)}>
                                    <label className="p-label">Waiver Field Set</label>
                                    {this.displayWaiverField(this.state.data.waiverFieldSetId)}
                                </FieldWrapper>
                                </div>                      
                            </React.Fragment>
                        }
                    </Fieldset>
                    {/* <Fieldset legend="Attendee Required">
                        {!this.props.viewMode ? 
                        <React.Fragment>
                            <div className="p-grid p-fluid form-group">
                            <FieldWrapper col="p-col-12" keyField="gender" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.gender, this.state.tmpData.gender)}>
                                <label className="p-label">Gender</label>
                                <Dropdown id="gender" value={this.state.data.gender} options={GENDERS} onChange={(e) => (this.setState({data: { ...this.state.data, gender: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} style={{width:'100%'}} placeholder="Select gender"/>
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12" keyField="grades" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.grades, this.state.tmpData.grades)}>
                                <label className="p-label">Grades</label>
                                <MultiSelect value={this.state.data.grades} options={this.state.grades} onChange={(e) => (this.setState({data: { ...this.state.data, grades: e.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))} style={{width:'100%'}} placeholder="Select grade"/>
                            </FieldWrapper>
                                <div className="p-col-12 p-md-6" style={{overflow:"hidden"}}>
                                    <label className="p-label">Min age</label>
                                    <div className="p-inputgroup p-grid">
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="minAgeYear" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.minAgeYear, this.state.tmpData.minAgeYear)}>
                                            <span className='p-inputgroup-addon'>YY: </span>
                                                    <InputText style={{position: "absolute"}} value={this.state.data.minAgeYear} onFocus={(e) => e.target.select()} keyfilter="int" onChange={(e) => (this.setState({data: { ...this.state.data, minAgeYear: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                        </FieldWrapper>
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="minAgeMonth" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.minAgeMonth, this.state.tmpData.minAgeMonth)}>
                                            <span className='p-inputgroup-addon'>MM: </span>
                                                    <InputText style={{position: "absolute"}} value={this.state.data.minAgeMonth} onFocus={(e) => e.target.select()} keyfilter="int" onChange={(e) => (this.setState({data: { ...this.state.data, minAgeMonth: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                        </FieldWrapper>
                                    </div>
                                    <span className="p-form-error">{this.state.errors.minAge}</span>
                                </div>
                                <div className="p-col-12 p-md-6" style={{overflow:"hidden"}}>
                                    <label className="p-label">Max age</label>
                                    <div className="p-inputgroup p-grid">
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="maxAgeYear" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.maxAgeYear, this.state.tmpData.maxAgeYear)}>
                                            <span className='p-inputgroup-addon'>YY:</span>
                                                <InputText style={{position: "absolute"}} value={this.state.data.maxAgeYear} onFocus={(e) => e.target.select()} keyfilter="int" onChange={(e) => (this.setState({data: { ...this.state.data, maxAgeYear: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>
                                        </FieldWrapper>
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="maxAgeMonth" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.maxAgeMonth, this.state.tmpData.maxAgeMonth)}>
                                            <span className='p-inputgroup-addon'>MM:</span>
                                                <InputText style={{position: "absolute"}} value={this.state.data.maxAgeMonth} onFocus={(e) => e.target.select()} keyfilter="int" onChange={(e) => (this.setState({data: { ...this.state.data, maxAgeMonth: Number(e.target.value)}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/>                                            
                                        </FieldWrapper>
                                    </div>
                                    <span className="p-form-error">{this.state.errors.maxAge}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="p-grid p-fluid form-group">
                            <FieldWrapper col="p-col-12" keyField="gender" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.gender, this.state.tmpData.gender)}>
                            <label className="p-label">Gender</label>
                                <div>{this.state.data.gender}</div>
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12" keyField="grades" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.grades, this.state.tmpData.grades)}>
                                <label className="p-label">Grades</label>
                                <div>{this.state.tmpGradeHolder}</div>
                                
                            </FieldWrapper>
                                <div className="p-col-12 p-md-6" style={{overflow:"hidden"}}>
                                    <label className="p-label">Min age</label>
                                    <div className="p-inputgroup p-grid">
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="minAgeYear" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.minAgeYear, this.state.tmpData.minAgeYear)}>
                                            <span className='p-inputgroup-addon'>YY: </span>
                                                <span className="p-inputgroup-addon">{this.state.data.minAgeYear}</span>
                                        </FieldWrapper>
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="minAgeMonth" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.minAgeMonth, this.state.tmpData.minAgeMonth)}>
                                            <span className='p-inputgroup-addon'>MM: </span>
                                                <span className="p-inputgroup-addon">{this.state.data.minAgeMonth}</span>
                                        </FieldWrapper>
                                    </div>
                                    <span className="p-form-error">{this.state.errors.minAge}</span>
                                </div>
                                <div className="p-col-12 p-md-6" style={{overflow:"hidden"}}>
                                    <label className="p-label">Max age</label>
                                    <div className="p-inputgroup p-grid">
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="maxAgeYear" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.maxAgeYear, this.state.tmpData.maxAgeYear)}>
                                            <span className='p-inputgroup-addon'>YY:</span>
                                                <span className="p-inputgroup-addon">{this.state.data.maxAgeYear}</span>
                                        </FieldWrapper>
                                        <FieldWrapper addStyle="hidden" col="p-col" keyField="maxAgeMonth" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.maxAgeMonth, this.state.tmpData.maxAgeMonth)}>
                                            <span className='p-inputgroup-addon'>MM:</span>
                                                <span className="p-inputgroup-addon">{this.state.data.maxAgeMonth}</span>
                                        </FieldWrapper>
                                    </div>
                                    <span className="p-form-error">{this.state.errors.maxAge}</span>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </Fieldset> */}
                </div>
                <div className="p-col-12 p-md-6">
                    {/* <Fieldset legend="Priority Reg. Setting">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <Checkbox inputId="cbPriorityBasePrevious" checked={this.state.data.priorityBasePrevious} onChange={(e)=> (this.setState({data: {...this.state.data, priorityBasePrevious: e.checked}}))}/>
                                <label htmlFor="cbPriorityBasePrevious" className="p-checkbox-label">Priority registration base previous attendance?</label>
                            </div>
                            {this.state.data.priorityBasePrevious?
                                [
                                    <div className="p-col-12">
                                        <label className="p-label">Previous course required</label>
                                        <Dropdown value={this.state.data.priorityRequiredId} options={this.state.courses} filter={true} onChange={(e) => (this.setState({data: { ...this.state.data, priorityRequiredId: e.value}}))} style={{width:'100%'}} placeholder="Select Course" />
                                        <span className="p-form-error">{this.state.errors.priorityRequiredId}</span>
                                    </div>,
                                    <div className="p-col-12 p-md-6">
                                        <label>Priority Reg. Start Date</label>
                                        <Calendar dateFormat="mm-dd-yy" hourFormat="12" value={this.state.priorityRegStartDateValue} onChange={this.onPriorityRegStartDateChange} showIcon={true} showTime={true}/>
                                        <span className="p-form-error">{this.state.errors.priorityRegStartDate}</span>
                                    </div>
                                ]
                            :''}
                            
                        </div>
                    </Fieldset> */}
                    <Fieldset legend="Reg. Schedule">
                        {!this.props.viewMode ? 
                        <React.Fragment>
                            <div className="p-grid p-fluid form-group">
                            <FieldWrapper col="p-col-12 p-md-6" keyField="regStartDateValue" resetChange={this.resetDateChange} highlight={highlightDateChanged(this.state.data.regStartDateValue, this.state.tmpData.regStartDateValue)}>
                            <label className="p-label">Reg. Start Date</label>
                                    <MaskedCalendar value={this.state.data.regStartDateValue} showIcon={true} showTime={true} hourFormat='12' hideOnDateTimeSelect={true} onChange={this.onRegStartDateChange} isDirty="false" dateFormat="mm-dd-yy" breakDateTime={true} /> 
                                    <span className="p-form-error">{this.state.errors.regStartDate}</span>
                                    {/* <DropdownCalendar value={this.state.data.regStartDateValue} showIcon={true} showTime={true} hourFormat='12' onChange={this.onRegStartDateChange} styleInline={true}/> */}
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="regEndDateValue" resetChange={this.resetDateChange} highlight={highlightDateChanged(this.state.data.regEndDateValue, this.state.tmpData.regEndDateValue)}>
                            <label className="p-label">Reg. End Date</label>
                                    <MaskedCalendar value={this.state.data.regEndDateValue} showIcon={true} showTime={true} hourFormat='12' hideOnDateTimeSelect={true} onChange={this.onRegEndDateChange} dateFormat="mm-dd-yy" breakDateTime={true} />
                                    <span className="p-form-error">{this.state.errors.regEndDate}</span>
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="memberRegStartDateValue" resetChange={this.resetDateChange} highlight={highlightDateChanged(this.state.data.memberRegStartDateValue, this.state.tmpData.memberRegStartDateValue)}>
                            <label className="p-label">Membership Reg. Start Date</label>
                                    <MaskedCalendar value={this.state.data.memberRegStartDateValue} showIcon={true} showTime={true} hourFormat='12' hideOnDateTimeSelect={true} onChange={this.onMemberRegStartDateChange} dateFormat="mm-dd-yy" breakDateTime={true} />
                                    <span className="p-form-error">{this.state.errors.memberRegStartDate}</span>
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="memberRegEndDateValue" resetChange={this.resetDateChange} highlight={highlightDateChanged(this.state.data.memberRegEndDateValue, this.state.tmpData.memberRegEndDateValue)}>
                            <label className="p-label">Membership Reg. End Date</label>
                                    <MaskedCalendar value={this.state.data.memberRegEndDateValue} showIcon={true} showTime={true} hourFormat='12' hideOnDateTimeSelect={true} onChange={this.onMemberRegEndDateChange} dateFormat="mm-dd-yy" breakDateTime={true} />
                                    <span className="p-form-error">{this.state.errors.memberRegEndDate}</span>
                            </FieldWrapper>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="p-grid p-fluid form-group">
                            <FieldWrapper col="p-col-12 p-md-6" keyField="regStartDateValue" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.data.regStartDateValue, this.state.tmpData.regStartDateValue)}>
                            <label className="p-label">Reg. Start Date</label>
                                    {this.state.data.regStartDate ? moment(this.state.data.regStartDate).format('MM-DD-YYYY hh:mm A') : 'None '}
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="regEndDateValue" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.data.regEndDateValue, this.state.tmpData.regEndDateValue)}>
                            <label className="p-label">Reg. End Date</label>
                                    {this.state.data.regEndDate ? moment(this.state.data.regEndDate).format('MM-DD-YYYY hh:mm A') : 'None '}
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="memberRegStartDateValue" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.data.memberRegStartDateValue, this.state.tmpData.memberRegStartDateValue)}>
                            <label className="p-label">Membership Reg. Start Date</label>
                                    {this.state.data.memberRegStartDate ? moment(this.state.data.memberRegStartDate).format('MM-DD-YYYY hh:mm A') : 'None ' }
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="memberRegEndDateValue" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.data.memberRegEndDateValue, this.state.tmpData.memberRegEndDateValue)}>
                            <label className="p-label">Membership Reg. End Date</label>
                                    {this.state.data.memberRegEndDate ? moment(this.state.data.memberRegEndDate).format('MM-DD-YYYY hh:mm A') : 'None '}
                            </FieldWrapper>
                            </div>
                        </React.Fragment>
                        }
                    </Fieldset>
                    <Fieldset legend="Reg. Quantity Range">
                        {!this.props.viewMode ?
                        <React.Fragment>
                            <div className="p-grid p-fluid form-group">
                            <FieldWrapper col="p-col-12 p-md-6" keyField="minReg" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.minReg, this.state.tmpData.minReg)}>
                                <label>Min Reg.</label>
                                {/* <Spinner value={this.state.data.minReg} min={0} onFocus={(e) => e.value.select()} onChange={(e) => (this.setState({data: { ...this.state.data, minReg: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/> */}
                                <InputText type="text" keyfilter="int" value={this.state.data.minReg} onFocus={(e) => this.focusField(e)} onChange={(e) => {this.setState({data: { ...this.state.data, minReg: e.target.value}}); this.props.formEvent.emit('ENTER_EDIT_MODE')}}/>
                                <span className="p-form-error">{this.state.errors.minReg}</span>      
                            </FieldWrapper>
                            <FieldWrapper col="p-col-12 p-md-6" keyField="maxReg" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.maxReg, this.state.tmpData.maxReg)}>
                                <label>Max Reg.</label>
                                {/* <Spinner value={this.state.data.maxReg} min={0} onChange={(e) => (this.setState({data: { ...this.state.data, maxReg: e.target.value}}), this.props.formEvent.emit('ENTER_EDIT_MODE'))}/> */}
                                <InputText type="text" keyfilter="int" value={this.state.data.maxReg} onFocus={(e) => this.focusField(e)} onChange={(e) => {this.setState({data: { ...this.state.data, maxReg: e.target.value}}); this.props.formEvent.emit('ENTER_EDIT_MODE')}}/>
                                <span className="p-form-error">{this.state.errors.maxReg}</span>
                            </FieldWrapper>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="p-grid p-fluid form-group">
                                <FieldWrapper col="p-col-12 p-md-6" keyField="minReg" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.minReg, this.state.tmpData.minReg)}>
                                    <label>Min Reg.</label>
                                    <div>{this.state.data.minReg}</div>
                                </FieldWrapper>
                                <FieldWrapper col="p-col-12 p-md-6" keyField="maxReg" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.maxReg, this.state.tmpData.maxReg)}>
                                    <label>Max Reg.</label>
                                    <div>{this.state.data.maxReg}</div>
                                </FieldWrapper>
                            </div>
                        </React.Fragment>
                        }
                    </Fieldset>
                    {this.state.data.id?
                    <Fieldset legend="Reg. Quantity Using">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label>Total of reg.: {this.state.data.product&&this.state.data.product.variants&&this.state.data.product.variants[0] ?<strong>{this.state.data.product.variants[0].onHold + this.state.data.product.variants[0].onSold}</strong> : ''}</label>
                            </div>
                            {this.state.data.product&&this.state.data.product.variants&&this.state.data.product.variants[0]&&this.state.data.product.variants[0].tracked?
                            <div className="p-col-12">
                                <label>Slot available: {this.state.data.product&&this.state.data.product.variants&&this.state.data.product.variants[0] ? <strong>{this.state.data.product.variants[0].onHand}</strong> : ''}</label>
                            </div>
                            :''}
                        </div>
                    </Fieldset>
                    :''}
                </div>
                {this.state.data.id?
                    <div className="p-col-12">
                        {!this.props.viewMode ?
                            <React.Fragment>
                                <Button label="Cancel Edit" icon="pi pi-lock" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.props.onCancelViewMode()} />
                                <Button label="Save Registration Settings" disabled={this.props.isDirty ? "" : "disabled"} icon="pi pi-save" iconPos="left" style={{'float':'right'}} onClick={(e) => this.handleSaveRegistrationSettings(e)}/>
                            </React.Fragment>
                            :
                            <Button label={this.props.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={this.props.acquireFormLock} />
                        }
                    </div>
                    :''
                }
            </div>
        );
    }
}