import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../core/security/auth.jsx';
import { login as requestLogin } from "./AuthServices.jsx";
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { withNamespaces, Trans } from 'react-i18next';
import { languages } from '../../core/internationalization/language';
import './Login.css';
import { showNotification } from '../../core/service/NotificationService.js';
import { showloading, stoploading } from '../../core/service/LoadingService.js';
import LoadingOverlay from '../../core/components/LoadingOverlay.js';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            language: {name: 'English', value: 'en'}
        };
    }

    handlePasswordEnter = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab'){
            this.login();
        }
    }

    changeLanguage = (e) => {
        this.setState({language: e.value});
        const { i18n } = this.props;
        i18n.changeLanguage(e.value.value);
    }

    login = () => {
        requestLogin(this.state.username, this.state.password)
        .then(res => {
            if(!res.error) this.setState({ redirectToReferrer: true });
            else showNotification('error', 'Sign in failed', 'Username or password is incorrect');
        })
        .catch(err => {
            showNotification('error', 'Sign in failed', 'Username or password is incorrect');
        })
    }

    render() {
        const { t } = this.props;
        const trans = (path) => t(path, { framework: "react-i18next" });
        const authenticated = isAuthenticated();

        let { from } = this.props.location.state || { from: { pathname: "/" } };
        let { redirectToReferrer } = this.state;
    
        if (redirectToReferrer || authenticated){
            return <Redirect to={from}/>;
        } 

        return (
            <div className="login-body">
                <div className="login-panel p-fluid">
                    <div className="login-panel-header">
                        <img className="logo" src={require('../../assets-public/images/logo/fl_small_logo.png')} alt="Logo"/>
                    </div>
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Trans i18nKey='login.signin_header'>
                                    <h1>Sign-in</h1>
                                </Trans>
                            </div>
                            <div className="p-col-12">
                                <Trans i18nKey='login.login_name'>
                                    <label>Login Name</label>
                                </Trans>
                                <InputText value={this.state.username} onChange={(e) => this.setState({username: e.target.value})}/>
                                    
                            </div>
                            <div className="p-col-12">
                                <Trans i18nKey='login.password'>
                                    <label>Password</label>
                                </Trans>
                                <Password value={this.state.password} feedback={false} onChange={(e) => this.setState({password: e.target.value})} onKeyPress={this.handlePasswordEnter}/>
                                    
                            </div>
                            <div className="p-col-12">
                                <Button label={ trans('login.signin')} icon="pi pi-user" onClick={() => this.login()} />
                            </div>
                            <div className="recover-username">
                                    Recover my 'Username': <Link to="/recover-username">Recover</Link>
                            </div>
                            <div className="reset-password">
                                    Forget my 'Password': <Link to="/reset-password">Reset</Link>
                            </div>
                            <div className="p-col-12">
                                {trans('login.change_language')}
                                <Dropdown 
                                    value={this.state.language} 
                                    options={languages} 
                                    onChange={this.changeLanguage} 
                                    style={{width:'150px'}} 
                                    optionLabel="name"/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(Login);