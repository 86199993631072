export function getLabelFromListSimple (list, value) {
    let lable = '';
    if(list){
        let item = list.find(item => item.value === value);
        if(item){
            lable = item.label;
        }
    }
    return lable;
}

export function onChangeDropdownWithItemSimple (value) {
    let valueOutput = value;
    if(typeof value === 'object'){
        valueOutput = value.value
    }
    return valueOutput;
}

export function getValueItemOnListSimple (list, value) {
    let valueObj = null;
    if(value){
        valueObj = list.find(item => item.value === value);
    }
    return valueObj?valueObj.value:null;
}
