import React, {Component} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export class NotifyOutDated extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const footer = (
            <div>
                <Button label="Refresh Page" icon="pi-md-refresh" onClick={() => this.props.formEvent.emit('RELOAD')}/>
            </div>
        );

        return (
            <Dialog footer={footer} visible={this.props.visible} contentStyle={styles.dialogContent} responsive={true} modal={true} showHeader={false} dismissableMask={true} onHide={this.props.onHide}>
                Your current data is outdated!
            </Dialog>
        );
    }
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}