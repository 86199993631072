import React, { Component } from 'react';
import { DepartmentServices }  from './DepartmentServices';
import { showNotification } from '../../core/service/NotificationService';
import { InputText } from "primereact/inputtext";
import { ColorPicker } from "primereact/colorpicker";
import { Spinner } from "primereact/spinner";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Link } from 'react-router-dom';
import Select from 'react-dropdown-select';
import { TalentServices } from "../../constituent-management/talent/TalentServices";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import { DataTable } from "primereact/datatable";
import { MemberDepartmentForm } from "./MemberDepartmentForm";
import { Column } from "primereact/column";
import { TreeTable } from 'primereact/treetable';
import { RoleServices } from '../../scp/access-management/role/RoleServices';
import { RoleForm } from '../../scp/access-management/role/RoleForm';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import { TAXONOMY_TYPE, APP_FEP, UPLOAD_TYPE } from '../../constants';
import { TaxonForm } from '../../pim/taxon/TaxonForm';
import { getTreeTaxon, removeTaxon } from '../../pim/taxon/TaxonServices';
import { SplitButton } from 'primereact/splitbutton';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { InputTextarea } from 'primereact/inputtextarea';

export default class DepartmentDetailsView extends Component {
    constructor(props){
        super(props);
        this.state = {
            member:{
                dataList: [],
                page: 0,
                rows: 10,
                total: 0,
                sortField: '',
                sortOrder: 0,
            },
            department: {},
            dataInfo: {},
            dataTalents: {},
            talents: [],
            expandedKeys: [],
            role:{
                dataList: [],
                page: 0,
                rows: 10,
                total: 0,
                sortField: '',
                sortOrder: 0,
            },
            errors: {}
        }
        this.departmentServices = new DepartmentServices();
        this.talentServices = new TalentServices();
        this.roleServices = new RoleServices();
    }

    componentDidMount(){
        this.getDepartment(); 
        this.getListTalents();
        this.getPageMembers();
        this.loadTreeCategory();
        this.getPageRoles();
    }

    //#region department info
    handleSaveInformation = () => {
        this.setState({errors: {}});
        let maxFileSize = 104857600 // 100MB
        if(this.state.dataInfo.file){
            let fileSize = this.state.dataInfo.file.size
            if(fileSize > maxFileSize){
                this.setState({errors: {image: 'Size not allowed (maximum: 100MB)'}})
                return
            }
        }
        this.departmentServices.saveDepartmentInfomation(this.state.dataInfo)
        .then(res => {
            if(!res.errorCode){
                this.popularDataForm(res);
                if(this.inputUpload) this.inputUpload.value=''
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        })
    }

    getDepartment = () => {
        this.departmentServices.getDepartment(this.props.match.params.id)
        .then(res => {
            if(res){
                this.popularDataForm(res);
            }
        })
    }

    popularDataForm = (data) => {
        this.setState({
            department: data ? data : {},
            dataInfo: {
                id: data ? data.id : '',
                code: data && data.code ? data.code : '',
                name: data ? data.name : '',
                imageInfo: data&&data.imageInfo&&Object.keys(data.imageInfo).length>0?data.imageInfo:null,
                externalImageLink: data&&data.imageInfo?(data.imageInfo.fileName?'':data.imageInfo.url):'',
                uploadType: data&&data.imageInfo&&data.imageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
                shortDescription: data && data.shortDescription ? data.shortDescription : '',
                fullDescription: data && data.fullDescription ? data.fullDescription : '',
                externalRef: data ? data.externalRef : false,
                goToUrl: data && data.goToUrl ? data.goToUrl : '',
                active: data ? data.active : true,
                bgColor: data && data.bgColor ? data.bgColor.substring(1) : '',
                color: data && data.color ? data.color.substring(1) : '',
                weight: data? data.weight : 0,
            },
            dataTalents:{
                id: data ? data.id : '',
                talents: data ? data.talents : '',
            }            
        })
    }
    //#endregion

    //#region department talents
    handleSaveTalents = () => {
        this.setState({errors: {}});
        this.departmentServices.saveDepartmentTalents(this.state.dataTalents)
        .then(res => {
            if(!res.errorCode){
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        });
    }

    getListTalents = () => {
        this.talentServices.getTalentsLevels()
        .then(res => {
            if(!res.errorCode){
                this.setState({talents: res});
            }
        });
    }

    onChangeSelect = (values) => {
        let termArr = [];
        if(values){
            values.map((item) => {
                termArr.push(item.value);
            })
            this.setState({
                dataTalents: {
                    ...this.state.dataTalents,
                    talents: termArr
                }
            })
        }
    }
    //#endregion

    //#region department member
    getPageMembers = () => {
        this.departmentServices.getPageMemberDepartment(this.props.match.params.id, this.state.member.page, this.state.member.rows, this.state.member.sortField, this.state.member.sortOrder)
        .then(res => {
            this.setState({
                member: {
                    ...this.state.member,
                    dataList: res.content,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rowse: res.pageable.pageSize
                }
            });
        })
    }

    onPageMember = (e) =>{
        this.setState({
            member: {
                ...this.state.member,
                page: e.page
            }
        },() => {
            this.getPageMembers();
        })
    }

    onSortMember = (e) => {
        this.setState({
            member: {
                ...this.state.member,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            }
        },()=> {
            this.getPageMembers();
        })
    }

    removeDepartmentMember = (id) => {
        this.departmentServices.deleteDepartmentMember(this.props.match.params.id, id)
        .then(res => {
            if(!res.errorCode){
                this.getPageMembers();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }
    //#endregion

    //#region deparment category
    loadTreeCategory = () => {
        getTreeTaxon({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [this.props.match.params.id]}).then(res => this.setState({categoryNodes: res}) );
    }

    removeCategory = (id) => {
        removeTaxon(id).then(res => {
            if(!res.errorCode){
                this.loadTreeCategory();
                showNotification('success', 'Success Message', 'Action submitted')
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    onAddOrEditCategory = (data) => {
        this.taxonForm.openForm(data, TAXONOMY_TYPE.category.value)
    }
    //#endregion

    //#region department role
    getPageRoles = () =>{
        this.roleServices.getPageRoles(this.state.role.page, this.state.role.rows, this.state.role.sortField, this.state.role.sortOrder, this.props.match.params.id)
        .then(res => {
            this.setState({
                role: {
                    ...this.state.member,
                    dataList: res.content,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rowse: res.pageable.pageSize
                }
            });
        })
    }

    onPageRole = (e) =>{
        this.setState({
            role: {
                ...this.state.role,
                page: e.page
            }
        },() => {
            this.getPageRoles();
        })
    }

    onSortRole = (e) => {
        this.setState({
            role: {
                ...this.state.role,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            }
        },()=> {
            this.getPageRoles();
        })
    }

    removeRoleDepartment = (id) => {
        this.roleServices.removeRole(id)
        .then(res => {
            if(!res.errorCode){
                this.getPageRoles();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    navToRoles = (parId,rowId) =>{
        this.props.history.push({
            pathname: `/departments/${parId}/role/${rowId}`,
            search: 'department',
        })
    }

    //#endregion

    render() {

        let tblHeaderMember = (<div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <Button icon="pi pi-plus" className="p-button-constrast" style={{ 'float': 'left' }} label="Add New Member" onClick={()=> this.memberDepartmentForm.action()}/>
            {/* <h3 className="fle-datatable-header-title">Members Listing</h3> */}
            </div>
        );
        let tblHeaderCategory = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <Button icon="pi pi-plus" label="Add Category" className="p-button-constrast" style={{ 'float': 'left' }} onClick={() => this.onAddOrEditCategory(null, TAXONOMY_TYPE.category)}/>
            <Button icon="pi pi-refresh" tooltip="Refresh Table" tooltipOptions={{position: 'top'}} style={{'float':'right'}} onClick={() => this.loadTreeCategory()}/>
            </div>;
        let tblHeaderRole = (<div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <Button icon="pi pi-plus" style={{'float':'left'}} onClick={()=> this.roleForm.action()}/>
            </div>
        );

        return (
            <div className="p-grid dashboard">
                <BreadcrumbsItem to="/scp/departments">Deparments</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>{this.state.department.name}</BreadcrumbsItem>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            {this.state.department.name}
                            <div className="p-toolbar-group-right">
                                <Button icon="pi pi-bars" className="p-button-icon" tooltip="Back To List" tooltipOptions={{position: 'top'}} onClick={() => this.props.history.push('/departments')}></Button>
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <TabView >
                                <TabPanel header="Information">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12 p-md-6">
                                                    <label className="p-label">* Code</label>
                                                    <InputText keyfilter={/[^\s]/} value={this.state.dataInfo.code} onChange={(e) => this.setState({dataInfo: {...this.state.dataInfo, code: replaceSpaceAndUpperCase(e.target.value)}})} />
                                                    <div className="p-form-error">{this.state.errors.code} </div>
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <label className="p-label">* Name</label>
                                                    <InputText value={this.state.dataInfo.name} onChange={(e) => this.setState({dataInfo: {...this.state.dataInfo, name: titleCaseText(e.target.value)}})} />
                                                    <div className="p-form-error">{this.state.errors.name} </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Description</label>
                                                    <InputTextarea value={this.state.department.shortDescription} rows="3" onChange={(e) => this.setState({department: {...this.state.department, shortDescription: e.target.value}})} />
                                                </div>
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.department.externalRef} onChange={(e) => this.setState({ department: { ...this.state.department, externalRef: e.target.value } })} />
                                                    <label className="p-label-inline p-margin-left-15">Is reference to external?</label>
                                                </div>
                                                {this.state.department.externalRef && 
                                                <div className="p-col-12">
                                                    <label className="p-label">Go To External Url</label>
                                                    <InputText value={this.state.department.goToUrl} onChange={(e) => this.setState({department: {...this.state.department, goToUrl: e.target.value}})} />
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <label className="p-label">Image Url</label>
                                                    <div className="p-inputgroup">
                                                        {(!this.state.dataInfo.uploadType || this.state.dataInfo.uploadType===UPLOAD_TYPE.external_url.value) ?
                                                            <React.Fragment>
                                                                <InputText value={this.state.dataInfo.externalImageLink} onChange={(e) => this.setState({dataInfo: {...this.state.dataInfo, externalImageLink: e.target.value}})} />
                                                                <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => this.setState({dataInfo: {...this.state.dataInfo, uploadType: UPLOAD_TYPE.upload_file.value}})} />
                                                            </React.Fragment>
                                                            :<React.Fragment>
                                                                {this.state.dataInfo.imageInfo && this.state.dataInfo.imageInfo.fileName &&
                                                                    <span className="p-inputgroup-addon"><img src={this.state.department.imageInfo.url} alt={this.state.dataInfo.imageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                                                }
                                                                <input ref={el=>this.inputUpload=el} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({dataInfo: {...this.state.dataInfo, file: e.target.files[0]}})} />
                                                                <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => this.setState({dataInfo: {...this.state.dataInfo, uploadType: UPLOAD_TYPE.external_url.value}})} />
                                                            </React.Fragment> 
                                                        }    
                                                    </div>
                                                    <div className="p-form-error">{this.state.errors.image}</div>
                                                </div>
                                                {/* <div className="p-col-12 p-md-6">
                                                    <label className="p-label">Background Color</label>
                                                    <div className="p-inputgroup">
                                                        <InputText value={this.state.dataInfo.bgColor} onChange={(e) => this.setState({ dataInfo: { ...this.state.dataInfo, bgColor: e.target.value }})} />
                                                        <ColorPicker id="bgColor" value={this.state.dataInfo.bgColor} onChange={(e) => this.setState({ dataInfo: { ...this.state.dataInfo, bgColor: e.value } })} style={{border: "1px solid #dedede"}} />
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <label className="p-label">Text Color</label>
                                                    <div className="p-inputgroup">
                                                        <InputText value={this.state.dataInfo.color} onChange={(e) => this.setState({ dataInfo: { ...this.state.dataInfo, color: e.target.value }})} />
                                                        <ColorPicker value={this.state.dataInfo.color} onChange={(e) => this.setState({ dataInfo: { ...this.state.dataInfo, color: e.value } })} style={{border: "1px solid #dedede"}} />
                                                    </div>
                                                </div> */}
                                                <div className="p-col-12 p-md-6">
                                                    <label className="p-label">Weight (for order)</label>
                                                    <InputText value={this.state.dataInfo.weight} keyfilter="int" onChange={(e) => this.setState({ dataInfo: { ...this.state.dataInfo, weight: e.target.value } })} />
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <label className="p-label">Active?</label>
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.dataInfo.active} onChange={(e) => this.setState({ dataInfo: { ...this.state.dataInfo, active: e.target.value } })} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-r">
                                            <Button label="Save Information" icon="pi pi-save" iconPos="left" onClick={() => this.handleSaveInformation()}/>
                                        </div>
                                    </div>
                                </TabPanel>
                                {/* <TabPanel header="Roles">
                                    <RoleForm ref={el => this.roleForm = el} 
                                        deptId={this.props.match.params.id}
                                        reloadTable={()=> this.getPageRoles()}
                                    />
                                    <DeleteNotification ref={el => this.deleteNotify=el}
                                        accessDelete={(id) => this.removeRoleDepartment(id)}
                                    />
                                    <div className="p-grid p-fluid ">                                        
                                        <div className="p-col-12 p-margin-15-0">
                                            <DataTable lazy={true} paginator={true} value={this.state.role.dataList}
                                                header={tblHeaderRole}
                                                first={this.state.role.page * this.state.role.rows}
                                                onPage={this.onPageRole} onSort={this.onSortRole}
                                                rows={this.state.role.rows} totalRecords={this.state.role.total}
                                                sortField={this.state.role.sortField} sortOrder={this.state.role.sortOrder}
                                                responsive={true}
                                            >
                                                <Column field="id" header="ID" />
                                                <Column field="name" header="Name" />
                                                <Column field="description" header="Description" />
                                                <Column header="" style={{textAlign:'center',width:'12em'}} 
                                                    body={(rowData) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Button icon='pi pi-search' className="p-button-info" onClick={() => this.navToRoles(this.props.match.params.id, rowData.id)} />
                                                                <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.roleForm.action(rowData)} />                                                            
                                                                <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.deleteNotify.delete(rowData.id)} />                                                            
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Talents">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">Enabled Skills</label>
                                            <Select values={this.state.dataTalents.talents ? this.state.talents.filter(x => this.state.dataTalents.talents.some(x2 => x2===x.value)) : []} options={this.state.talents} multi onChange={(values) => this.onChangeSelect(values)} style={{width:'100%'}}  placeholder="Select talents" />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-6 p-r">
                                            <Button label="Save Skils" icon="pi pi-save" iconPos="left" onClick={() => this.handleSaveTalents()}/>
                                        </div>
                                    </div>
                                </TabPanel> */}
                                <TabPanel header="Members">
                                    <div className="p-grid">
                                    <MemberDepartmentForm ref={el => this.memberDepartmentForm = el}
                                        id={this.props.match.params.id}
                                        refreshTable={() => this.getPageMembers()}
                                    />
                                    <DeleteNotification ref={el => this.deleteNotify=el}
                                        accessDelete={(id) => this.removeDepartmentMember(id)}
                                    />
                                    <div className="p-col-12">                                        
                                        <div className="card card-w-title">
                                            <DataTable lazy={true} paginator={true} value={this.state.member.dataList}
                                                header={tblHeaderMember}
                                                first={this.state.member.page * this.state.member.rows}
                                                onPage={this.onPageMember} onSort={this.onSortMember}
                                                rows={this.state.member.rows} totalRecords={this.state.member.total}
                                                sortField={this.state.member.sortField} sortOrder={this.state.member.sortOrder}
                                                responsive={true}
                                            >
                                                <Column field="member.firstName" header="First Name" />
                                                <Column field="member.lastName" header="Last Name" />
                                                <Column field="member.email" header="Email" />
                                                <Column field="member.birthday" header="Date of birth" />
                                                <Column header="Role" body={(rowData) => { return ( rowData.role ? rowData.role.name : '' )}} />
                                                <Column header="Locked" body={(rowData) => { return ( rowData.locked ? 'Yes' : 'No' )}} />
                                                <Column header="" style={{textAlign:'center',width:'8em'}} 
                                                    body={(rowData) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Button icon="pi pi-pencil" className="p-button-warning" tooltip="View Member" tooltipOptions={{position: 'top'}} onClick={() => this.memberDepartmentForm.action(rowData)} />                                                            
                                                                <Button icon="pi-md-close" className="p-button-danger" tooltip="Delete Member" tooltipOptions={{position: 'top'}} onClick={() => this.deleteNotify.delete(rowData.id)} />                                                            
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Categories">
                                    <TaxonForm ref={el=>this.taxonForm=el}
                                        deptId={this.props.match.params.id}
                                        reloadTable={() => this.loadTreeCategory()}
                                    />
                                    <ConfirmNotification ref={el=>this.confirmNotify=el}
                                        submitConfirm={(id) => this.removeCategory(id)}
                                        message="Are you sure you want to remove?"
                                    />

                                    <TreeTable value={this.state.categoryNodes} header={tblHeaderCategory} expandedKeys={this.state.expandedKeys}
                                        onToggle={(e) => this.setState({expandedKeys: e.value})} style={{marginTop: '.5em'}}
                                    >
                                        <Column field="code" header="Code" headerClassName="p-col-d" expander/>
                                        <Column field="name" header="Name" className="p-col-d" />
                                        <Column field="active" header="Active" className="p-col-d" bodyClassName="p-c" headerStyle={{width: '7em'}} body={(node) => {return node.data.active?'Yes':'No'}} />
                                        <Column field="weight" header="Weight" className="p-col-d" bodyClassName="p-c" headerStyle={{width: '7em'}} />
                                        <Column headerStyle={{width:'10em'}}
                                            body={(rowData) => {
                                                let items=[
                                                    {label: 'Remove', icon: 'pi-md-trash', command: () => this.confirmNotify.confirm(rowData.data.id)}
                                                ]
                                                return(
                                                    <SplitButton label="Edit" model={items} onClick={() => this.onAddOrEditCategory(rowData.data)}></SplitButton>
                                                )
                                            }}
                                        />
                                    </TreeTable>
                                </TabPanel>
                                
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

