import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { getTaxon, getListTaxons, saveTaxon } from './TaxonServices';
import { InputText } from "primereact/inputtext";
import { Spinner } from 'primereact/spinner';
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Sidebar } from 'primereact/sidebar';
import { showNotification } from "../../core/service/NotificationService.js";
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import { APP_FEP, ATTRIBUTE_FIELD_SET_TYPE, TAXONOMY_CATEGORY_TYPES, TAXONOMY_TYPE, UPLOAD_TYPE } from '../../constants';
import { getListDepartments } from '../../scp/departments/DepartmentServices';
import CKEditor from 'ckeditor4-react';
import { getListAttributeSets } from '../../scp/attribute/AttributeServices';
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

export const TaxonForm = forwardRef((props, ref) => {
    const inputUpload = useRef(null)

    const [data, setData] = useState({app: APP_FEP});
    const [visible, setVisible] = useState(false);
    const [formHeader, setFormHeader] = useState('');
    const [parentCategories, setParentCategories] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [branchs, setBranchs] = useState([]);
    const [locations, setLocations] = useState([]);
    const [attributeSets, setAttributeSets] = useState([]);
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, () => ({
        openForm(taxonData, type){
            popularForm(taxonData, type)
            setVisible(true);
            setFormHeader((taxonData?'Edit':'Add')+ ' ' + renderTypeName(type))
            setErrors({});
            if(type === TAXONOMY_TYPE.category.value || (taxonData && taxonData.type === TAXONOMY_TYPE.category.value)){
                loadDepartments();
                loadAtributeSets();
                if(props.deptId){
                    loadTaxons(props.deptId, taxonData?taxonData.id:null)
                }
            }
            if(type === TAXONOMY_TYPE.location.value || (taxonData && taxonData.type === TAXONOMY_TYPE.location.value)){
                loadBranchs();
                loadLocations(null, taxonData?taxonData.id:null);
            }
        }    
    }))

    const popularForm = (taxonData, type) => {
        setData({...data,
            id: taxonData ? taxonData.id : null,
            type: taxonData ? taxonData.type: type,
            secondaryType: taxonData ? taxonData.secondaryType: (props.deptId ? TAXONOMY_TYPE.product.value:(props.secondaryType?props.secondaryType:null)),
            departmentId: taxonData ? taxonData.departmentId : (props.deptId ? props.deptId : null),
            parentId: taxonData ? taxonData.parentId : null,
            branchId: taxonData ? taxonData.branchId : null,
            attributeSetId: taxonData ? taxonData.attributeSetId : null,
            code: taxonData && taxonData.code ? taxonData.code : '',
            name: taxonData && taxonData.name ? taxonData.name : '',
            slot: taxonData && taxonData.slot ? taxonData.slot : 1,
            active: taxonData ? taxonData.active : true,
            weight: taxonData ? taxonData.weight : 0,
            imageInfo: taxonData&&taxonData.imageInfo&&Object.keys(taxonData.imageInfo).length>0?taxonData.imageInfo:null,
            uploadType: taxonData&&taxonData.imageInfo&&taxonData.imageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            imageUrl: taxonData&&taxonData.imageInfo? (taxonData.imageInfo.fileName?'':taxonData.imageInfo.url) : '',
            description: taxonData ? taxonData.description : '',
            address: taxonData&&taxonData.address&&Object.keys(taxonData.address).length>0 ? taxonData.address : null
        })
    }

    const loadDepartments = () => {
        getListDepartments({externalRef: false}).then(res => setDepartments(res))
    }

    const loadTaxons = (deptId, excludeId, excludeParentId) => {
        let filter = {
            app: APP_FEP,
            departmentIds: deptId?[deptId]:[],
            secondaryTypea: props.deptId ? [TAXONOMY_TYPE.product.value] : (props.secondaryType?[props.secondaryType]:[]),
            excludeIds: excludeId?[excludeId]:[],
            excludeParentIds: excludeParentId?[excludeParentId]:[]
        }
        getListTaxons(filter, true).then(res => setParentCategories(res) );
    }

    const loadBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res) );
    }

    const loadLocations = (branchId, excludeId) => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.location.value, branchId: branchId}, true)
        .then(res => {
            let tmpArr = res?res:[];
            tmpArr = tmpArr.filter(item => item.value!==excludeId);
            setLocations(tmpArr)
        });
    }

    const loadAtributeSets = () => {
        getListAttributeSets(ATTRIBUTE_FIELD_SET_TYPE.custom_field).then(data => setAttributeSets(data?data:[]))
    }

    const handleSaveCategory = () => {
        setErrors({});
        let maxFileSize = 104857600 // 100MB
        if(data.file){
            let fileSize = data.file.size
            if(fileSize > maxFileSize){
                setErrors({image: 'Size not allowed (maximum: 100MB)'})
                return
            }
        }
        saveTaxon(data).then(res => {
            if (!res.errorCode) {
                if(props.reloadTable){
                    props.reloadTable();
                }
                if(props.newData){
                    props.newData(res);
                }
                if(inputUpload && inputUpload.current){
                    inputUpload.current.value=''
                }
                onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            } else {
                if(res.errorCode === 400) setErrors(res.errorObj);
                showNotification('error', 'Error Message', 'Cannot perform action');
            } 
        });
    }

    const onDepartmentChange = (e) => {
        loadTaxons(e.value);
        setData({...data,
            departmentId: e.value, 
            parentId: null
        })
    }

    const onBranchChange = (e) => {
        loadLocations(e.value);
        setData({...data,
            branchId: e.value, 
            parentId: null
        })
    }

    const onParentLocationChange = async (e) => {
        if(!data.branchId && e.value){
            let dataLocation = await getTaxon(e.value);
            if(!dataLocation.errorCode){
                setData({...data,
                    branchId: dataLocation.branchId,
                    parentId: e.value
                })
                loadLocations(dataLocation.branchId, data.id);
            }
        }else{
            setData({...data,
                parentId: e.value
            })
        }
    }

    const renderTypeName = (type) => {
        if(type){
            return TAXONOMY_TYPE[type]?TAXONOMY_TYPE[type].label:type;
        }
        return type;
    }

    const onHide = () =>{
        setVisible(false)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={onHide}>
            <h2>{formHeader}</h2>

            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Fieldset legend="Basic Information">
                        <div className="p-grid">
                            {(()=>{
                                if(data.type === TAXONOMY_TYPE.category.value){
                                    return <React.Fragment>
                                        {!props.deptId && !props.secondaryType &&
                                            <div className="p-col-12">
                                                <label className="p-label">* Type</label>
                                                <Dropdown value={data.secondaryType} options={Object.values(TAXONOMY_CATEGORY_TYPES)} onChange={(e) => setData({...data, secondaryType: e.value})} />
                                                <div className="p-form-error">{errors.secondaryType}</div>
                                            </div>
                                        }
                                        {(()=>{
                                            if(data.secondaryType === TAXONOMY_TYPE.product.value){
                                                return <React.Fragment>
                                                    {!props.deptId &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">* Department</label>
                                                            <Dropdown id="department" value={data.departmentId} options={departments} onChange={e => onDepartmentChange(e)} placeholder="Select Department" />
                                                            <div className="p-form-error">{errors.departmentId}</div>
                                                        </div>
                                                    }
                                                    {/* <div className="p-col-12">
                                                        <label className="p-label">Parent Category</label>
                                                        <Dropdown id="parentCategory" value={data.parentId} options={parentCategories} onChange={(e) => setData({...data, parentId: e.value})} showClear={true} placeholder="No Parent"/>
                                                        <div className="p-form-error">{errors.parentId}</div>
                                                    </div> */}
                                                </React.Fragment>
                                            }
                                        })()}
                                    </React.Fragment>
                                }
                                if(data.type === TAXONOMY_TYPE.location.value){
                                    return <React.Fragment>
                                        {/* <div className="p-col-12">
                                            <label className="p-label">* Branch</label>
                                            <Dropdown value={data.branchId} options={branchs} onChange={(e) => onBranchChange(e)} placeholder="Select a branch"/>
                                            <div className="p-form-error">{errors.branchId}</div>
                                        </div> */}
                                        <div className="p-col-12">
                                            <label className="p-label">Parent Location</label>
                                            <Dropdown value={data.parentId} options={locations} onChange={(e) => onParentLocationChange(e)} showClear={true} placeholder="No Parent"/>
                                            <div className="p-form-error">{errors.parentId}</div>
                                        </div>
                                    </React.Fragment>
                                }
                            })()}
                            <div className="p-col-12">
                                <label className="p-label">* Code</label>
                                <InputText keyfilter={/[^\s]/} value={data.code} onChange={(e) => setData({...data, code: replaceSpaceAndUpperCase(e.target.value)})} />
                                <div className="p-form-error">{errors.code} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={data.name} onChange={(e) => setData({...data, name: titleCaseText(e.target.value)})} />
                                <div className="p-form-error">{errors.name} </div>
                            </div>
                            {data.secondaryType === TAXONOMY_TYPE.asset.value &&
                                <div className="p-col-12">
                                    <label className="p-label">Customize Set</label>
                                    <Dropdown value={data.attributeSetId} options={attributeSets} onChange={(e) => setData({...data, attributeSetId: e.value})} />
                                </div>
                            }
                            {data.type === TAXONOMY_TYPE.category.value && data.secondaryType === TAXONOMY_TYPE.asset.value &&
                                <div className="p-col-12">
                                    <label className="p-label">Slot</label>
                                    <InputText keyfilter="pnum" value={data.slot} onChange={(e) => setData({...data, slot: e.target.value })} />
                                    <div className="p-form-error">{errors.slot} </div>
                                </div>
                            }
                            <div className="p-col-12">
                                <label className="p-label">Weight (for order)</label>
                                <InputText value={data.weight} keyfilter="int" onChange={(e) => setData({...data, weight: e.target.value })} />
                            </div>
                            {data.type === TAXONOMY_TYPE.category.value && data.secondaryType===TAXONOMY_TYPE.product.value && 
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label">Image Link</label>
                                    <div className="p-inputgroup">
                                        {(!data.uploadType || data.uploadType===UPLOAD_TYPE.external_url.value) ?
                                            <React.Fragment>
                                                <InputText value={data.imageUrl} onChange={(e) => setData({...data, imageUrl: e.target.value})} />
                                                <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setData({...data, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                            </React.Fragment>
                                            :<React.Fragment>
                                                {data.imageInfo && data.imageInfo.fileName &&
                                                    <span className="p-inputgroup-addon"><img src={data.imageInfo.url} alt={data.imageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                                }
                                                <input ref={inputUpload} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => setData({...data, file: e.target.files[0]})} />
                                                <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setData({...data, uploadType: UPLOAD_TYPE.external_url.value})} />
                                            </React.Fragment> 
                                        }
                                    </div>
                                    <div className="p-form-error">{errors.image}</div>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Description</label>
                                    <CKEditor data={data.description} onChange={e => setData({...data, description: e.editor.getData()})} />
                                </div>
                            </React.Fragment>
                            }
                            {(data.type===TAXONOMY_TYPE.branch.value || (data.type === TAXONOMY_TYPE.category.value && data.secondaryType===TAXONOMY_TYPE.product.value)) && 
                            <div className="p-col-12">
                                <label className="p-label">Active?</label>
                                <InputSwitch onLabel="Yes" offLabel="No" checked={data.active} onChange={(e) => setData({...data, active: e.target.value })} />
                            </div>
                            }      
                        </div>
                    </Fieldset>
                </div>
                {(data.type === TAXONOMY_TYPE.location.value || data.type === TAXONOMY_TYPE.branch.value) && 
                    <div className="p-col-12">
                        <Fieldset legend="Address information">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-label">Phone</label>
                                    <InputText value={data.address&&data.address.phone?data.address.phone:''} onChange={(e) => setData({...data, address: {...data.address, phone: e.target.value}}) } />
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Address</label>
                                    <InputText value={data.address&&data.address.address?data.address.address:''} onChange={(e) => setData({...data, address: {...data.address, address: e.target.value}}) } />
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Address Url</label>
                                    <InputText value={data.address&&data.address.addressLinkRef?data.address.addressLinkRef:''} onChange={(e) => setData({...data, address: {...data.address, addressLinkRef: e.target.value}}) } />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                }
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                    <Button label="Save" icon="pi pi-check" onClick={() => handleSaveCategory()} />
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => onHide()} />
                </div>
            </div>
        </Sidebar>
    );
})