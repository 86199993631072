import axios from "axios"
import {API_BASE_URL} from "../../constants";

export class ProgramAssessmentServices {

    getPageProgramAssessment(programId, courseId, page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/programs/${programId}/courses/${courseId}/assessment/page`, {
            params: {page, size, sort }
        }).then(res => res.data).catch(error => console.log(error));
    }

    getAssessment(programId, courseId, id){
        return axios.get(`${API_BASE_URL}/scp/api/programs/${programId}/courses/${courseId}/assessment/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveProgramAssessment(programId, courseId, form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/${programId}/courses/${courseId}/assessment`, form)
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteProgramAssessment(programId, courseId, id){
        return axios.delete(`${API_BASE_URL}/scp/api/programs/${programId}/courses/${courseId}/assessment/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveAssessmentParticipant(programId, courseId, data){
        return axios.post(`${API_BASE_URL}/scp/api/programs/${programId}/courses/${courseId}/assessment/${data.id}/participants`,data)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveTakeAssessment(programId, courseId, data){
        return axios.post(`${API_BASE_URL}/scp/api/programs/${programId}/courses/${courseId}/assessment/quick`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

}