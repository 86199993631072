import React, {Component} from 'react';
import { showNotification } from '../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ProgramServices } from './ProgramServices';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';

export class CancelProgramForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            program: {},
            participants: [],
            formHeader: 'Cancel Program',
            visible: false,
            errors: {}
        }
        this.programServices = new ProgramServices();
    }

    action = (program) => {
        this.loadData(program.id)
        this.setState({
            program: program,
            errors: {}
        })
    }

    loadData = (programId) => {
        this.programServices.getProgramToCancel(programId)
        .then(res => {
            if(!res.errorCode){
                let isRevocable = res.isRevocable
                let participants = res.participants

                if(isRevocable){
                    this.confirmNotify.confirm(programId)
                }else{
                    this.setState({
                        participants: participants,
                        visible: true,
                    })
                }
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        })
    }

    handleSumit = () => {
        this.setState({ errors: {} });

        this.programServices.cancelProgram(this.state.program.id)
        .then(res => {
            if(!res.errorCode){
                
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        })
    }

    onHide = () => {
        this.setState({ visible: false})
    }

    render(){

        return (
            <React.Fragment>
                <ConfirmNotification ref={el => this.confirmNotify = el}
                    submitConfirm={(id) => this.handleSumit(id)}
                    message="Are you sure you want to canel?"
                />

                <Sidebar visible={this.state.visible} dismissable={false} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                    <h2>{this.state.formHeader}</h2>

                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <label className="p-label">Name: {this.state.program.name}</label>
                        </div>
                        {this.state.participants && this.state.participants.length > 0 &&
                            <div className="p-col-12">
                                <div className="p-padding-5-0"><u>Note:</u> The program has already had participants.</div>
                                <div className="p-padding-5-0">Please cancel the participant first to perform this action.</div>
                            </div>
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            {this.state.participants && this.state.participants.length === 0 &&
                                <Button label="Submit" icon="pi pi-check" onClick={() => this.handleSumit()}/>
                            }
                            <Button label="Close" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                        </div>
                    </div>
                </Sidebar>
            </React.Fragment>
        )
    }
}