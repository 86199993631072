import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { saveMenu } from "./MenuServices";

export const MenuForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Menu")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})
    
    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader((e?'Edit':'New') + ' Menu')
            setVisible(true)
            setFrmData(popularFormData(e))
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data ? data.id : '',
            title: data && data.title ? data.title : ''
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const handleSaveMenu = () => {
        setErrors({})

        saveMenu(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()

                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        });
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12" >
                    <label className="p-label">* Title</label>
                    <InputText value={frmData.title} onChange={e => setFrmData({...frmData, title: e.target.value})} />
                    <span className="p-form-error">{errors.title}</span>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSaveMenu()}/>
                </div>
            </div>
        </Sidebar>
    )
})
