import React, {Component} from 'react';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { CONDITION_METHOD_TYPE, RULE_CONDITION_TYPE, DROPDOWN_MULTI_STYLES, DEFAULT_RULE_CONDITION, CONDITION_CART_METHOD_TYPES, CONDITION_DATE_TIME_METHOD_TYPES, MONTH_DAYS, WEEK_DAYS, translateListSimple } from '../../constants';
import { RadioButton } from 'primereact/radiobutton';
import Select from 'react-select';
import { InputText } from 'primereact/inputtext';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { parseMessage } from "../../core/utils/TranslationUtils";

class RuleConditions extends Component {
    constructor(props){
        super(props);
        this.state = {
            conditionMatchAll: props.conditionMatchAll,
            conditions: props.data
        };
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({
                conditions: this.props.data.length>0?this.props.data:[]
            });
        }
    }

    addCondition = () => {
        let tmpConditions = [...this.state.conditions];
        tmpConditions.push({...DEFAULT_RULE_CONDITION});
        this.props.onChange(tmpConditions);
    }

    removeCondition = (idx) => {
        let tmpConditions = [...this.state.conditions];
        tmpConditions.splice(idx, 1);
        this.props.onChange(tmpConditions);
    }

    onElementChange = (idx, key, value) => {
        let tmpConditions = [...this.state.conditions];
        let conditionItem = tmpConditions[idx];

        switch(key){
            case 'conditionType':
                conditionItem.conditionType=value;
                if(value===RULE_CONDITION_TYPE.subtotal || value===RULE_CONDITION_TYPE.item_quantity || value===RULE_CONDITION_TYPE.line_item_count){
                    conditionItem.methodType=CONDITION_METHOD_TYPE.greater_than;
                    conditionItem.values=new Array(1);
                }else if(value===RULE_CONDITION_TYPE.week_days || value===RULE_CONDITION_TYPE.month_days){
                    conditionItem.methodType=CONDITION_METHOD_TYPE.in_list;
                    conditionItem.values=null;
                }else if(value===RULE_CONDITION_TYPE.times){
                    conditionItem.values=new Array(2);
                    conditionItem.sValues=new Array(1);
                }
                break;
            case 'methodType':
                conditionItem.methodType=value;
                break;
            case 'minValue':
                if(conditionItem.conditionType===RULE_CONDITION_TYPE.times){
                    conditionItem.sValues[0]=value;
                    conditionItem.values[0]=moment(value).format(moment.HTML5_FMT.TIME);
                }else{
                    conditionItem.values[0]=value;
                }
                break;
            case 'maxValue':
                if(conditionItem.conditionType===RULE_CONDITION_TYPE.times){
                    conditionItem.sValues[1]=value;
                    conditionItem.values[1]=moment(value).format(moment.HTML5_FMT.TIME);
                }else{
                    conditionItem.values[1]=value;
                }
                break;
            case 'values':
                let tmpData = [];
                tmpData = value && value.map(item => (item.value));

                conditionItem.values=tmpData;
                break;
            default:
                break;
        }
            
        tmpConditions.splice(idx, 1, conditionItem);
        this.setState({conditions: tmpConditions});
        this.props.onChange(tmpConditions);
    }

    getConditionDayValues = (type, idx) => {
        let values = [];
        switch(type){
            case RULE_CONDITION_TYPE.month_days:
                values = this.state.conditions[idx].values ? MONTH_DAYS.filter(x1 => this.state.conditions[idx].values.some(x2 => x2 === x1.value)) : [];
                break;
            case RULE_CONDITION_TYPE.week_days:
                values = this.state.conditions[idx].values ? WEEK_DAYS.filter(x1 => this.state.conditions[idx].values.some(x2 => x2 === x1.value)) : [];
                break;
            default:
                break;
        }

        return values.length>0?values:null;
    }

    render(){
        const trans = this.props.trans;
        const errorEmptyIndex = this.props.errorCondition && this.props.errorCondition.ruleValues ? this.props.errorCondition.ruleValues.info === 0 ? 0 : this.props.errorCondition.ruleValues.info : '';
        const errorEmptyDateIndex = this.props.errorCondition && this.props.errorCondition.ruleDateValues ? this.props.errorCondition.ruleDateValues.info === 0 ? 0 : this.props.errorCondition.ruleDateValues.info : '';
        const errorEmptyTimeFromIndex = this.props.errorCondition && this.props.errorCondition.ruleTimeFromValues ? this.props.errorCondition.ruleTimeFromValues.info === 0 ? 0 : this.props.errorCondition.ruleTimeFromValues.info : '';
        const errorEmptyTimeToIndex = this.props.errorCondition && this.props.errorCondition.ruleTimeToValues ? this.props.errorCondition.ruleTimeToValues.info === 0 ? 0 : this.props.errorCondition.ruleTimeToValues.info : '';

        const conditionTypeOptions = [
            {
                label: trans('promotion.mgt.cart'),
                options: [
                    {value: RULE_CONDITION_TYPE.subtotal, label: trans('promotion.mgt.subtotal')},
                    {value: RULE_CONDITION_TYPE.item_quantity, label: trans('promotion.mgt.item_quantity')},
                    {value: RULE_CONDITION_TYPE.line_item_count, label: trans('promotion.mgt.line_item_count')}
                ],
            },
            {
                label: trans('promotion.mgt.date_and_time'),
                options: [
                    {value: RULE_CONDITION_TYPE.week_days, label: trans('promotion.mgt.days_in_week')},
                    {value: RULE_CONDITION_TYPE.month_days, label: trans('promotion.mgt.days_in_month')},
                    {value: RULE_CONDITION_TYPE.times, label: trans('promotion.mgt.time')}
                ],
            },
        ];

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <label className="p-margin-right-10">{trans('promotion.mgt.condition_relationship')}:</label>
                    <RadioButton inputId="rbConditionMatchAll0" name="conditionMatchAll" value={true} checked={this.state.conditionMatchAll} onChange={(e) => {this.setState({conditionMatchAll: e.value}); this.props.onUpdateConditionMatchAll(e.value)}} />
                    <label htmlFor="rbConditionMatchAll0" className="p-radiobutton-label p-margin-right-20">{trans('promotion.mgt.match_all')}</label>
                    <RadioButton inputId="rbConditionMatchAll1" name="conditionMatchAll" value={false} checked={!this.state.conditionMatchAll} onChange={(e) => {this.setState({conditionMatchAll: e.value}); this.props.onUpdateConditionMatchAll(e.value)}}/>
                    <label htmlFor="rbConditionMatchAll1" className="p-radiobutton-label">{trans('promotion.mgt.match_any')}</label>
                </div>
                <div className="p-col-12">
                    {this.state.conditions && this.state.conditions.length>0 && this.state.conditions.map((val, idx) => {
                        return (
                            <div key={"condition_"+idx} className="p-margin-bottom-10">
                                <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-3">
                                            <label className="p-label">{trans('promotion.mgt.condition_type')}</label>
                                            <Select value={conditionTypeOptions.map(x => x.options.find(y => y.value===val.conditionType))} options={conditionTypeOptions} className="p-select p-frames" styles={DROPDOWN_MULTI_STYLES} onChange={(e) => this.onElementChange(idx, 'conditionType', e.value)}/>
                                        </div>
                                        {(() => {
                                            switch(val.conditionType){
                                                case RULE_CONDITION_TYPE.subtotal:
                                                case RULE_CONDITION_TYPE.item_quantity:
                                                case RULE_CONDITION_TYPE.line_item_count:
                                                    return (
                                                        <React.Fragment>
                                                            <div className="p-col-12 p-md-3">
                                                                <label className="p-label p-w-normal">{trans('promotion.mgt.method_type')}</label>
                                                                <Dropdown value={val.methodType} className="p-frames" options={translateListSimple(trans, CONDITION_CART_METHOD_TYPES)}  onChange={(e) => this.onElementChange(idx, 'methodType', e.value)}/>
                                                            </div>
                                                            <div className="p-col-12 p-md-2">
                                                                <label className="p-label p-w-normal">{trans('promotion.mgt.condition_value')}</label>
                                                                <InputText keyfilter="num" value={val.values?val.values[0]:''} className="p-frames" onChange={(e) => this.onElementChange(idx, 'minValue', e.target.value)}/>
                                                                {errorEmptyIndex === idx &&
                                                                    <span className="p-form-error">{this.props.errorCondition.ruleValues && <div>{parseMessage(trans, this.props.errorCondition.ruleValues.code, this.props.errorCondition.ruleValues.info?{count: this.props.errorCondition.ruleValues.info[0]}:{})}</div>}</span>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                case RULE_CONDITION_TYPE.month_days:
                                                case RULE_CONDITION_TYPE.week_days:
                                                    return (
                                                        <React.Fragment>
                                                            <div className="p-col-12 p-md-2">
                                                                <label className="p-label p-w-normal">{trans('promotion.mgt.method_type')}</label>
                                                                <Dropdown value={val.methodType} className="p-frames" options={translateListSimple(trans, CONDITION_DATE_TIME_METHOD_TYPES)}  onChange={(e) => this.onElementChange(idx, 'methodType', e.value)}/>
                                                            </div>
                                                            <div className="p-col-12 p-md-5">
                                                                <label className="p-label p-w-normal">{trans('promotion.mgt.days')}</label>
                                                                <Select value={this.getConditionDayValues(val.conditionType, idx)} closeMenuOnSelect={false} isClearable isSearchable isMulti styles={DROPDOWN_MULTI_STYLES}
                                                                    options={val.conditionType===RULE_CONDITION_TYPE.month_days?MONTH_DAYS:translateListSimple(trans, WEEK_DAYS)} className="p-select p-frames" onChange={(e) => this.onElementChange(idx, 'values', e)}/>
                                                                {errorEmptyDateIndex === idx &&
                                                                    <span className="p-form-error">{this.props.errorCondition.ruleDateValues && <div>{parseMessage(trans, this.props.errorCondition.ruleDateValues.code, this.props.errorCondition.ruleDateValues.info?{count: this.props.errorCondition.ruleDateValues.info[0]}:{})}</div>}</span>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                case RULE_CONDITION_TYPE.times:
                                                    return (
                                                        <React.Fragment>
                                                            <div className="p-col-12 p-md-2">
                                                                <label className="p-label p-w-normal">{trans('promotion.mgt.from')}</label>
                                                                <MaskedCalendar value={val.sValues[0]} className="p-frames" onChange={(e) => this.onElementChange(idx, 'minValue', e.value)} showIcon={true} hourFormat="12" showTime={true} timeOnly={true}/>
                                                                {errorEmptyTimeFromIndex === idx &&
                                                                    <span className="p-form-error">{this.props.errorCondition.ruleTimeFromValues && <div>{parseMessage(trans, this.props.errorCondition.ruleTimeFromValues.code, this.props.errorCondition.ruleTimeFromValues.info?{count: this.props.errorCondition.ruleTimeFromValues.info[0]}:{})}</div>}</span>
                                                                }
                                                            </div>
                                                            <div className="p-col-12 p-md-2">
                                                                <label className="p-label p-w-normal">{trans('promotion.mgt.to')}</label>
                                                                <MaskedCalendar value={val.sValues[1]} className="p-frames" onChange={(e) => this.onElementChange(idx, 'maxValue', e.value)} showIcon={true} hourFormat="12" showTime={true} timeOnly={true}/>
                                                                {errorEmptyTimeToIndex === idx &&
                                                                    <span className="p-form-error">{this.props.errorCondition.ruleTimeToValues && <div>{parseMessage(trans, this.props.errorCondition.ruleTimeToValues.code, this.props.errorCondition.ruleTimeToValues.info?{count: this.props.errorCondition.ruleTimeToValues.info[0]}:{})}</div>}</span>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                default: break;
                                            }
                                        })()}
                                        
                                        {/* {(() => {
                                            if(val.methodType!==CONDITION_METHOD_TYPE.range){
                                                return (
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label">{trans('promotion.mgt.value')}</label>
                                                        <Spinner value={val.maxValue} onChange={(e) => this.onElementChange(idx, 'maxValue', e.target.value)}/>
                                                    </div>
                                                );                                            
                                            }else{
                                                return ([
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label">{trans('promotion.mgt.from')}</label>
                                                        <Spinner value={val.minValue} onChange={(e) => this.onElementChange(idx, 'minValue', e.target.value)}/>
                                                    </div>,
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label">{trans('promotion.mgt.to')}</label>
                                                        <Spinner value={val.maxValue} onChange={(e) => this.onElementChange(idx, 'maxValue', e.target.value)}/>
                                                    </div>
                                                ]);
                                            }
                                        })()} */}
                                        <div className="p-col-12 p-md-1">
                                            <Button icon="pi-md-close" className="p-button-danger btn-sm" style={{top: "19px"}} onClick={() => this.removeCondition(idx)}/>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>                           
                        );
                    })}                    
                </div>
                <div className="p-col-12 p-padding-top-0 p-padding-bottom-0">
                    <Button label={trans('promotion.mgt.add_condition')} icon="pi pi-plus" iconPos="left" onClick={this.addCondition}/>
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(RuleConditions)