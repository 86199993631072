import React, { Component } from 'react';
import { RoleServices } from './RoleServices';
import { TabView, TabPanel } from 'primereact/tabview';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { showNotification, showErrorNotify, showSuccessNotify } from '../../../core/service/NotificationService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { DepartmentServices } from '../../../scp/departments/DepartmentServices';
import { InputSwitch } from 'primereact/inputswitch';
import { PermissionServices } from '../permission/PermissionServices';
import { titleCaseText } from "../../../core/service/CommonService";

const defaultPad = 3;

export default class RoleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            role: {
                name: '',
                description: '',
                app: false,
                sysPermissions: [],
                permissions: [],
                permissionGroups: []
            },
            departmentName: '',
            permissions: [],
            errors: {},
        }
        this.permissionServices = new PermissionServices();
        this.roleServices = new RoleServices();
        this.departmentServices = new DepartmentServices();
    }

    componentDidMount() {
        this.getRole();
        this.getPermissions();
        this.getSysPermissions();
        if (this.props.match.params.dId)
            this.getDepartment();
    }

    getDepartment = () => {
        this.departmentServices.getDepartment(this.props.match.params.dId)
            .then(res => {
                if (res) {
                    this.setState({
                        departmentName: res.name
                    });
                }
            })
    }

    handleSaveInfo = () => {
        this.roleServices.updateInfo(this.state.id, this.state.role)
        .then(res => {
            if(!res.errorCode){
                this.setState({ errors: {} });
                showSuccessNotify('Action Submitted');
            }else{
                if(res.errorCode===400) this.setState({ errors: res.errorObj })
                showErrorNotify(res.errorMessage)
            }
        })
    }

    handleSavePermission = () => {
        this.roleServices.savePermissions(this.state.id, this.state.role)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Action Submitted');
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    getRole = () => {
        this.roleServices.getRole(this.props.match.params.id)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    id: res.id,
                    role: res
                })
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    getPermissions = () => {
        this.roleServices.getPermissions()
            .then(res => {
                if (res) {
                    this.setState({
                        permissions: res
                    })
                }
            })
    }

    getSysPermissions = () => {
        let type = this.props.location.search ? "department" : ""; 
        this.permissionServices.getPermissions(type)
            .then(res => {
                if (res) {
                    this.setState({
                        sysPermissions: res
                    })
                }
            })
    }

    onChangeCheckbox = (e, groupKey) => {
        let lstValue = [...this.state.role.permissions];
        let tmpKey = e.value;
        if (e.checked) {
            if (lstValue.indexOf(tmpKey) === -1) {
                lstValue.push(tmpKey);
            }
        } else {
            lstValue.splice(lstValue.indexOf(tmpKey), 1);
        }
        this.setState({
            role: {
                ...this.state.role,
                permissions: lstValue
            }
        })
    }

    renderPermission(permission, initPad) {
        if (permission.group) {
            return (
                <>
                    <tr className="p-datatable-row">
                        <td colSpan={6} style={{ fontWeight: 'bold', paddingLeft: `${initPad}em` }}>{permission.label}</td>
                    </tr>
                    {permission.children && permission.children.map((child) => {
                        return this.renderPermission(child, initPad + defaultPad);
                    })}
                </>
            )
        } else {
            let pad = initPad;
            let operations = permission.operations;
            let opRead = permission.fullKey + '_READ';
            let opWrite = permission.fullKey + '_WRITE';
            let opDelete = permission.fullKey + '_DELETE';
            let opRequest = permission.fullKey + '_REQUEST';
            let opAuthorize = permission.fullKey + '_AUTHORIZE';
            return (
                <tr className="p-datatable-row">
                    <td style={{ paddingLeft: `${pad}em` }}>{permission.label}</td>
                    <td style={{ textAlign: 'center' }}>{operations.includes('READ') ? <Checkbox inputId={opRead} value={opRead} onChange={(e) => this.onChangeCheckbox(e, permission.key)} checked={this.state.role.permissions.includes(opRead)} /> : ''}</td>
                    <td style={{ textAlign: 'center' }}>{operations.includes('WRITE') ? <Checkbox inputId={opWrite} value={opWrite} onChange={(e) => this.onChangeCheckbox(e, permission.key)} checked={this.state.role.permissions.includes(opWrite)} /> : ''}</td>
                    <td style={{ textAlign: 'center' }}>{operations.includes('DELETE') ? <Checkbox inputId={opDelete} value={opDelete} onChange={(e) => this.onChangeCheckbox(e, permission.key)} checked={this.state.role.permissions.includes(opDelete)} /> : ''}</td>
                    <td style={{ textAlign: 'center' }}>{operations.includes('REQUEST') ? <Checkbox inputId={opRequest} value={opRequest} onChange={(e) => this.onChangeCheckbox(e, permission.key)} checked={this.state.role.permissions.includes(opRequest)} /> : ''}</td>
                    <td style={{ textAlign: 'center' }}>{operations.includes('AUTHORIZE') ? <Checkbox inputId={opAuthorize} value={opAuthorize} onChange={(e) => this.onChangeCheckbox(e, permission.key)} checked={this.state.role.permissions.includes(opAuthorize)} /> : ''}</td>
                </tr>
            )
        }
    }

    renderPermissionLeafNode(permission) {

    }

    render() {
        let permissionRows = this.state.sysPermissions ? this.state.sysPermissions.map((topPermission) => {
            return this.renderPermission(topPermission, defaultPad);
        }) : '';

        return (
            <div className="p-grid">
                {this.state.role.departmentId ?
                    [
                        <div>
                            <BreadcrumbsItem to="/scp/departments">Departments</BreadcrumbsItem>
                            <BreadcrumbsItem to={`/scp/departments/${this.state.role.departmentId}`}>{this.state.departmentName}</BreadcrumbsItem>
                        </div>
                    ]
                    :
                    <BreadcrumbsItem to="/scp/access-management/roles">Roles</BreadcrumbsItem>
                }
                <BreadcrumbsItem to={this.props.match.url}>{this.state.role.name}</BreadcrumbsItem>

                <div className="p-col-12">
                    <TabView >
                        <TabPanel header="Information">
                            <Fieldset legend="Basic Information" >
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-6">
                                                <label>Name: </label>
                                                <InputText value={this.state.role.name} onChange={(e) => this.setState({ role: { ...this.state.role, name: titleCaseText(e.target.value) } })} />
                                            </div>
                                            <div className="p-col-6">
                                                <InputSwitch checked={this.state.role.app} onChange={(e) => this.setState({ role: { ...this.state.role, app: e.value } })} />
                                                <label htmlFor="app" className="p-checkbox-label"> <strong>Is App?</strong></label>
                                            </div>
                                            <div className="p-col-12">
                                                <label>Description</label>
                                                <InputTextarea rows={5} cols={30} value={this.state.role.description} onChange={(e) => this.setState({ role: { ...this.state.role, description: e.target.value } })} autoResize={true} />
                                            </div>
                                            <div className="p-col-12" style={{ textAlign: "right" }}>
                                                <Button label="Save Information" icon="pi pi-save" iconPos="left" onClick={(e) => this.handleSaveInfo()} style={{ width: "auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>
                        <TabPanel header="Permissions">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <div className="p-datatable p-component p-datatable-responsive p-datatable-hoverable-rows p-datatable-scp">
                                        <div className="p-datatable-wrapper">
                                            <table>
                                                <thead className="p-datatable-thead">
                                                    <tr>
                                                        <th style={{ width: '100%' }}><span className="p-column-title">Permission</span></th>
                                                        <th style={{ width: '7em' }}><span className="p-column-title">Read</span></th>
                                                        <th style={{ width: '7em' }}><span className="p-column-title">Write</span></th>
                                                        <th style={{ width: '7em' }}><span className="p-column-title">Delete</span></th>
                                                        <th style={{ width: '7em' }}><span className="p-column-title">Request</span></th>
                                                        <th style={{ width: '7em' }}><span className="p-column-title">Authorize</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="p-datatable-tbody">
                                                    {permissionRows}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div className="p-col-12">
                                    <Button label="Save Permissions" icon="pi pi-save" iconPos="left" onClick={(e) => this.handleSavePermission()} style={{ width: "auto" }} />
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}