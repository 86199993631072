import React, { Component } from 'react';
import moment from 'moment';
import { ProgramServices } from './ProgramServices';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';

import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'primereact/button';
import { showNotification } from '../../core/service/NotificationService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TransferParticipantForm } from './TransferParticipantForm';
import { MoveParticipantToClass } from './MoveParticipantToClass';
import { EnrollParticipantForm } from './EnrollParticipantForm';
import { Spinner } from 'primereact/spinner';
import { ParticipantOrderDetailsForm } from './ParticipantOrderDetailsForm';
import { CancelParticipantForm } from './CancelParticipantForm';
import { SplitButton } from 'primereact/splitbutton';
import { QuickAssessmentForm } from './QuickAssessmentForm';
import { Checkbox } from 'primereact/checkbox';
import { TABLE_PAGING_LENGTH } from '../../constants';
import { Dropdown } from 'primereact/dropdown';

export class ProgramCourseManageDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program: {},
            course: {},
            attendee: {
                success: {
                    dataList: [],
                    page: 0,
                    rows: 10,
                    total: 0,
                    sortField: "",
                    sortOrder: 0,
                },
                waiting: {
                    dataList: [],
                    page: 0,
                    rows: 10,
                    total: 0,
                    sortField: "",
                    sortOrder: 0,
                }
            },
            activeTabIndex: 0,
            errors: {},
        };
        this.programServices = new ProgramServices();
        this.participantServices = new ParticipantServices();
    }

    componentDidMount() {
        this.getProgram();
        this.loadTalentsLevels(this.props.match.params.pid, this.props.match.params.cid);
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.course).length!==0){
    //         if(JSON.stringify(prevState.course) !== JSON.stringify(this.state.course)){
    //             if(JSON.stringify(this.state.course) !== JSON.stringify(this.state.tmpCourse)){
    //                 this.setState({ isDirty: true })
    //             }else{
    //                 this.setState({ isDirty: false })
    //             }
    //         }
    //     }
    // }

    getProgram = () => {
        this.programServices.getProgram(this.props.match.params.cid, 'course')
            .then(res => {
                if (!res.errorCode) {
                    if (res.parent.id === parseInt(this.props.match.params.pid)) {
                        this.setState({
                            program: res.parent,
                            course: res,
                        }, () => {
                            this.popularCourseData(res);
                            this.getPageCourseAttendeesSuccess();
                            this.getPageCourseAttendeesWaiting();
                        });
                    }
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                }
            });
    }

    popularCourseData = (data) => {
        this.setState({
            course: {
                ...this.state.course,
                workType: this.state.program.workType,
                startDateValue: data.startDate ? moment(data.startDate, moment.HTML5_FMT.DATE).toDate() : '',
                endDateValue: data.endDate ? moment(data.endDate, moment.HTML5_FMT.DATE).toDate() : '',
                startTimeValue: data.startTime ? moment(data.startTime, moment.HTML5_FMT.TIME).toDate() : '',
                endTimeValue: data.endTime ? moment(data.endTime, moment.HTML5_FMT.TIME).toDate() : '',
                schedules: data.schedules ? data.schedules : {},
                basicPrice: data.product ? data.product.price : 0,
                memberPrice: data.product ? data.product.memPrice : 0,
                staffPrice01: data.product ? data.product.staffPrice01 : '',
                staffPrice02: data.product ? data.product.staffPrice02 : '',
                frequencies: data.frequencies && data.frequencies.length > 0 ? data.frequencies : [{name: '', periodType: 'monthly', periodLength: 1, numberOfOccurances: 2}],
                occurancePayAtDay: data.allowDraftPayment ? data.occurancePayAtDay : '1',
                fullPaymentDueDateValue: data.fullPaymentDueDate ? moment(data.fullPaymentDueDate, moment.HTML5_FMT.DATE).toDate() : '',
                deferredPaymentDueDate: data.product && data.deferredPaymentDueDate ? data.deferredPaymentDueDate : data.allowDraftPayment ? 1 : '',
                deferredPaymentDueDateValue: data.product && data.deferredPaymentDueDate ? !data.allowDraftPayment ? moment(data.deferredPaymentDueDate, 'YYYY-MM-DD').toDate() : '' : '',
                regStartDateValue: data.regStartDate ? moment(data.regStartDate, 'YYYY-MM-DD HH:mm').toDate() : '',
                regEndDateValue: data.regEndDate ? moment(data.regEndDate, 'YYYY-MM-DD HH:mm').toDate() : '',
                memberRegStartDateValue: data.memberRegStartDate ? moment(data.memberRegStartDate, 'YYYY-MM-DD HH:mm').toDate() : '',
                memberRegEndDateValue: data.memberRegEndDate ? moment(data.memberRegEndDate, 'YYYY-MM-DD HH:mm').toDate() : '',
            }
        });
    }

    //#region Participants
    getPageCourseAttendeesSuccess = () => {
        this.participantServices.getPageCourseParticipants(this.state.course.id, ['UNPAID', 'COMPLETED'], this.state.attendee.success.page, this.state.attendee.success.rows, this.state.attendee.success.sortField, this.state.attendee.success.sortOrder)
            .then(res => {
                this.setState({
                    attendee: {
                        ...this.state.attendee,
                        success: {
                            ...this.state.attendee.success,
                            dataList: res ? res.content : [],
                            loading: false,
                            page: res ? res.pageable.pageNumber : 0,
                            rows: res ? res.pageable.pageSize : 10,
                            total: res ? res.totalElements : 0
                        }
                    }
                });
            });
    }

    onAttendeeSuccessPage = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                success: {
                    ...this.state.attendee.success,
                    page: e.page
                }
            }
        } ,() => {
            this.getPageCourseAttendeesSuccess();
        });
    }

    onAttendeeSuccessSort = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                success: {
                    ...this.state.attendee.success,
                    sortField: e.sortField,
                    sortOrder: e.sortOrder
                }
            }
        } ,() => {
            this.getPageCourseAttendeesSuccess();
        });
    }

    cancelParticipant = (id) => {
        this.participantServices.cancelParticipant(id)
        .then(res => {
            if(!res.errorCode){
                this.getPageCourseAttendeesSuccess();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    checkinParticipant = (participantIds, isCheckin) => {
        this.participantServices.checkinParticipant(participantIds, isCheckin)
        .then(res => {
            if(!res.errorCode){
                let tmpArr = [...this.state.attendee.success.dataList]
                tmpArr.map(participant => {
                    if(participantIds.includes(participant.id)){
                        participant.checkin = isCheckin
                    }
                })
                this.setState({
                    attendee: { ...this.state.attendee,
                        success: { ...this.state.attendee.success,
                            dataList: tmpArr,
                        }
                    }
                })
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onCheckinAllChange = (e) => {
        let tmpIds = this.state.attendee.success.dataList.map(participant => participant.id)
        this.setState({
            chkCheckinAll: e.checked
        },() => this.checkinParticipant(tmpIds, this.state.chkCheckinAll))
    }

    renderColumnParticipant = () => {
        let data = {...this.state.course}
        let isShowCheckin = false;

        let now = moment()
        let startDate = moment(data.startDate+' '+data.startTime)
        let endDate = moment(data.endDate+' '+data.endTime)
        if(now.isSameOrAfter(startDate)){
            isShowCheckin = true
        }

        let columns = [
            <Column field="name" header="Name"
                body={(rowData) => {return ( <Link to={`/participants/${rowData.id}?p=${rowData.refId}&c=${rowData.childRefId}`}>{rowData.name}</Link> ); }}
            />,
            <Column field="email" header="Email"
                body={(rowData) => { return (<a href={rowData.email ? 'mailto:' + rowData.email : '#'}>{rowData.email}</a>); }}
            />,
            <Column field="birthday" header="Date of birth"
                body={(rowData) => { return (<span>{moment(rowData.birthday).format('MM-DD-YYYY')}</span>); }}
            />,
            <Column field="ageString" header="Age" style={{ width: '6em' }} />,
            // <Column field="grade" header="Grade" style={{ width: '6em' }} />,
            <Column field="gender" header="Gender" style={{ width: '6em' }} />,
            <Column field="phone" header="Phone"
                body={(rowData) => { return (<span>{rowData.phoneFmt}</span>); }}
            />,
            <Column field="created" header="Reg. on"
                body={(rowData) => { return (<span>{moment(rowData.created).format('MM-DD-YYYY hh:mm:ss A')}</span>); }}
            />,
            // <Column field="propertyName" header="Bus Route" />,
            <Column header="" className="tc-actions" style={{ width: '10em', overlay: 'visible' }} 
                body={(rowData) => { 
                    let dropActions = [
                        {   label: 'Purchase Details', icon: 'pi pi-info p-size-20',
                            command: () => { this.participantOrderDetailsForm.action(rowData.id) }
                        },
                        {   label: 'Transfer', icon: 'pi-md-swap-horiz',
                            command: () => { this.transferParticipantForm.action(rowData) }
                        },
                        // {   label: 'Assign a Bus Route', icon: 'pi-md-directions-bus',
                        //     command: (e) => { this.assignPropertyFrom.action(rowData.id) }
                        // },
                        {   label: 'Cancel', icon: 'pi pi-times',
                            command: () => { this.cancelParticipantForm.action(rowData) }
                        }
                    ];
                    return( 
                        <SplitButton label="View" className="p-button-constrast p-l" model={dropActions} tooltip={"View Details"} tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(`/participants/${rowData.id}?p=${rowData.refId}&c=${rowData.childRefId}`)}/>
                    ) 
                }}
            />
        ]

        if(isShowCheckin){
            let dataList = [...this.state.attendee.success.dataList]
            let participantChecked = []
            dataList.map(participant => {
                if(participant.checkin){
                    participantChecked.push(participant)
                }
            })
            let columnHeader = <span>
                {/* <Checkbox checked={isChk} onChange={(e) => this.onCheckinAllChange(e)} /> */}
                Check-in
            </span>
            let column = <Column field="checkin" header={columnHeader} bodyClassName="p-c" style={{ width: '10em' }} 
                body={rowData => { 
                    let isStarting = true
                    if(now.isAfter(endDate)){
                        isStarting = false
                    }
                    if(isStarting)
                        return <Checkbox checked={rowData.checkin} onChange={(e) => this.checkinParticipant([rowData.id], e.checked)} /> 
                    else return rowData.checkin ? 'Yes' : 'No'
                    
                }}
            />
            columns.splice(0, 0 , column)
        }

        return columns;
    }

    onChangePageLengthOfParticipant = length => {
        this.setState({
            attendee: { ...this.state.attendee,
                success: { ...this.state.attendee.success,
                    rows: length,
                    page: 0
                }
            }
        }, () => {
            this.getPageCourseAttendeesSuccess();
        })
    }
    //#endregion

    //#region Waiting list
    getPageCourseAttendeesWaiting = () => {
        this.participantServices.getPageCourseParticipants(this.state.course.id, ['WAITING'], this.state.attendee.waiting.page, this.state.attendee.waiting.rows, this.state.attendee.waiting.sortField, this.state.attendee.waiting.sortOrder)
            .then(res => {
                this.setState({
                    attendee: {
                        ...this.state.attendee,
                        waiting: {
                            ...this.state.attendee.waiting,
                            dataList: res ? res.content : [],
                            loading: false,
                            page: res ? res.pageable.pageNumber : 0,
                            rows: res ? res.pageable.pageSize : 10,
                            total: res ? res.totalElements : 0
                        }
                    }
                });
            });
    }

    onAttendeeWaitingPage = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                waiting: {
                    ...this.state.attendee.waiting,
                    page: e.page
                }
            }
        } ,() => {
            this.getPageCourseAttendeesWaiting();
        });
    }

    onAttendeeWaitingSort = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                waiting: {
                    ...this.state.attendee.waiting,
                    sortField: e.sortField,
                    sortOrder: e.sortOrder
                }
            }
        } ,() => {
            this.getPageCourseAttendeesWaiting();
        });
    }

    updatePriorityToParticipantWaiting = (partId, e) => {
        let tempArr = [...this.state.attendee.waiting.dataList];
        tempArr.map(part => {
            if(part.id === partId){
                let tempObj = {...part};
                tempObj.priority = e.value
                tempArr.splice(tempArr.indexOf(part), 1, tempObj);
            }
        })
        this.setState({
            attendee: {
                ...this.state.attendee,
                waiting: {
                    ...this.state.attendee.waiting,
                    dataList: tempArr
                }
            }
        },() => {
            this.participantServices.updatePriorityParticipant(partId, e.value)
            .then(res => {
                if(!res.errorCode){

                }else{
                    showNotification('error', 'Error Message', 'Cannot perform action');
                }
            })
        })
    }
    //#endregion

    quickAssessment = (type) => {
        let data = [];
        switch (type) {
            case 'level':
                data = this.state.course.talents ? this.state.talents.filter(talent => Object.keys(this.state.course.talents).some(enabledTalent => enabledTalent === talent.key)) : [];
                break;
            case 'skill':
                let talentCurrentCourse = this.state.course.talents ? { ...this.state.course.talents } : {};
                this.state.talents.map(talent => {
                    let temTalentObj = {
                        key: talent.key,
                        name: talent.name,
                        values: []
                    }
                    Object.entries(talentCurrentCourse).map(([keyCurrent, valueCurrent]) => {
                        if (talent.key === keyCurrent) {
                            talent.values.map(level => {
                                if (valueCurrent.current) {
                                    valueCurrent.current.map(item => {
                                        if (level.value === item) {
                                            temTalentObj.values.push(level);
                                        }
                                    })
                                }
                            })
                        }
                    })
                    data.push(temTalentObj);
                })
                break;
            default: break;
        }
        this.quickAssessmentForm.actionForm(type, data);
    }

    onAssessmentManagement = () => {
        this.setState({
            goToAssessManagement: true
        })
    }

    loadTalentsLevels = (pid, cid) => {
        this.programServices.getTalentsLevelsOfCourse(pid, cid)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        talents: res
                    })
                }
            });
    }

    render() {
        if (this.state.goToAssessManagement) {
            return <Redirect to={`/programs/${this.state.program.id}/courses/${this.state.course.id}/manage/assessment`} />;
        }

        let itemAssessments = [
            {
                label: 'Take Attendance',
                icon: '',
                command: () => {
                    this.quickAssessment("attendance");
                }
            },
            {
                label: 'Level Recommendation',
                icon: '',
                command: () => {
                    this.quickAssessment("level");
                }
            },
            {
                label: 'Skill Assessment',
                icon: '',
                command: () => {
                    this.quickAssessment("skill");
                }
            },
        ];

        let tblParticipantHeader = (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button label="Enroll to class" className="p-button-constrast" style={{'float':'left'}} onClick={() => this.enrollParticipantForm.action(this.state.program.id, this.state.course.id)}/>
                <div style={{ 'float': 'right' }}>
                    Show: <Dropdown className="p-datatable-length-selector" value={this.state.attendee.success.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChangePageLengthOfParticipant(e.value)} />
                    <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => this.getPageCourseAttendeesSuccess()}/>
                </div>
            </div>
            
        );

        let tblWaitingHeader = (
            <div className="p-clearfix p-r" style={{'lineHeight':'1.87em'}}>
                <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => this.getPageCourseAttendeesWaiting()} />
            </div>
            
        );
        return (
            <div className="p-grid">
                <BreadcrumbsItem to="/programs">Programs</BreadcrumbsItem>
                <BreadcrumbsItem to={`/programs/${this.state.program.id}`}>{this.state.program.name}</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>{this.state.course.name}</BreadcrumbsItem>

                <QuickAssessmentForm ref={el => this.quickAssessmentForm = el}
                    pId={this.props.match.params.pid}
                    cId={this.props.match.params.cid}
                />

                <TransferParticipantForm ref={el => this.transferParticipantForm = el} 
                    refreshTable={() => this.getPageCourseAttendeesSuccess()}
                />

                <MoveParticipantToClass ref={el => this.moveParticipantToClass = el} 
                    refreshTable={() => (this.getPageCourseAttendeesWaiting(), this.getPageCourseAttendeesSuccess())}
                />

                <EnrollParticipantForm ref={el => this.enrollParticipantForm = el} />

                <ParticipantOrderDetailsForm ref={el => this.participantOrderDetailsForm=el} />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Program: {this.state.program.name}
                            <div className="p-toolbar-group-right">
                                <Link to={`/programs/${this.props.match.params.pid}`} className="p-button p-component p-button-primary p-button-icon-only">
                                    <span className="pi pi-caret-left p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">Type: {this.state.program.workTypeName}</div>
                            <div className="p-col-12 p-md-4">Department: {this.state.program.departmentName}</div>
                            <div className="p-col-12 p-md-4">Category: {this.state.program.categoryName}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Course: {this.state.course.code + ' - ' + this.state.course.name}
                        </h1>
                        <div className="p-toolbar-group-right">
                            <SplitButton label="Assessment Mangement" model={itemAssessments} onClick={() => this.onAssessmentManagement()} ></SplitButton>
                        </div>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">Location: {this.state.course.locationName}</div>
                            <div className="p-col-12 p-md-4">Total attendees: {this.state.attendeeTotal}</div>
                            <div className="p-col-12 p-md-4">Active: {this.state.course.active ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <TabView>
                        <TabPanel header="Participants">
                            <CancelParticipantForm ref={el => this.cancelParticipantForm=el} 
                                refreshTable={() => this.getPageCourseAttendeesSuccess()}
                            />

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <DataTable lazy={true} paginator={true} value={this.state.attendee.success.dataList}
                                        header={tblParticipantHeader}
                                        first={this.state.attendee.success.page * this.state.attendee.success.rows}
                                        rows={this.state.attendee.success.rows} totalRecords={this.state.attendee.success.total}
                                        onPage={this.onAttendeeSuccessPage} onSort={this.onAttendeeSuccessSort}
                                        sortField={this.state.attendee.success.sortField} sortOrder={this.state.attendee.success.sortOrder}
                                        selectionMode="single" responsive={true}
                                    >
                                        {this.renderColumnParticipant()}
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Waiting list">
                            <CancelParticipantForm ref={el => this.cancelParticipantForm=el} 
                                refreshTable={() => this.getPageCourseAttendeesWaiting()}
                            />

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <DataTable lazy={true} paginator={true} value={this.state.attendee.waiting.dataList}
                                        header={tblWaitingHeader}
                                        first={this.state.attendee.waiting.page * this.state.attendee.waiting.rows}
                                        rows={this.state.attendee.waiting.rows} totalRecords={this.state.attendee.waiting.total}
                                        onPage={this.onAttendeeWaitingPage} onSort={this.onAttendeeWaitingSort}
                                        sortField={this.state.attendee.waiting.sortField} sortOrder={this.state.attendee.waiting.sortOrder}
                                        selectionMode="single" responsive={true}
                                    >
                                        <Column field="name" header="Name"
                                            body={(rowData) => {return (<Link to={`/participants/${rowData.id}?p=${rowData.refId}&c=${rowData.childRefId}`}>{rowData.name}</Link>); }}
                                        />
                                        <Column field="email" header="Email"
                                            body={(rowData) => {return (<a href={rowData.email ? 'mailto:' + rowData.email : '#'}>{rowData.email}</a>); }}
                                        />
                                        <Column field="birthday" header="Date of birth"
                                            body={(rowData) => { return (<span>{moment(rowData.birthday).format('MM-DD-YYYY')}</span>); }}
                                        />
                                        <Column field="ageString" header="Age" style={{ width: '6em' }} />
                                        {/* <Column field="grade" header="Grade" style={{ width: '6em' }} /> */}
                                        <Column field="gender" header="Gender" style={{ width: '8em' }} />
                                        <Column field="phone" header="Phone"
                                            body={(rowData) => { return (<span>{rowData.phoneFmt}</span>); }}
                                        />
                                        <Column field="created" header="Reg. on"
                                            body={(rowData) => { return (<span>{moment(rowData.created).format('MM-DD-YYYY hh:mm:ss A')}</span>); }}
                                        />
                                        <Column field="priority" header="Priority" 
                                            body={(rowData) => { 
                                                return (
                                                    <div className="p-fluid">
                                                        <Spinner value={rowData.priority} className="p-padding-left-0" onChange={(e) => this.updatePriorityToParticipantWaiting(rowData.id, e)} />
                                                    </div>
                                                ); 
                                            }}
                                        />
                                        <Column header="" className="tc-actions" style={{ textAlign: 'center', width: '10em', overlay: 'visible' }} 
                                            body={(rowData) => { 
                                                let dropActions = [
                                                    {   label: 'Purchase Details', icon: 'pi pi-info p-size-20',
                                                        command: () => { this.participantOrderDetailsForm.action(rowData.id) }
                                                    },
                                                    {   label: 'Move to actual class', icon: 'pi-md-swap-horiz',
                                                        command: () => { this.moveParticipantToClass.action(rowData) }
                                                    },
                                                    {   label: 'Cancel', icon: 'pi pi-times',
                                                        command: () => { this.cancelParticipantForm.action(rowData) }
                                                    }
                                                ];
                                                return( 
                                                    <SplitButton label="View" className="p-button-constrast p-l" model={dropActions} tooltip={"View Details"} tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(`/participants/${rowData.id}?p=${rowData.refId}&c=${rowData.childRefId}`)}/>
                                                ) 
                                            }}
                                        />
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        )
    };
}