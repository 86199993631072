import React, {useState, useEffect, useRef} from "react";
import { DataTable } from "primereact/datatable";
import {Link, useRouteMatch} from "react-router-dom";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { getPageProductOptions, removeProductOption } from "./ProductOptionServices";
import { ProductOptionForm } from "./ProductOptionForm";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Dropdown } from 'primereact/dropdown';
import { TABLE_PAGING_LENGTH, APP_FEP, PRODUCT_OPTION_TYPE } from "../../constants";
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { SplitButton } from "primereact/splitbutton";

export const ProductOptionListView = () => {
    const match = useRouteMatch();
    const frmOption = useRef(null);
    const [filter, setFilter] = useState({app: APP_FEP})
    const [data, setData] = useState([])
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })
    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(() => {
        if(isLoadTable)
            loadPageProductOptions();
    }, [isLoadTable])

    const loadPageProductOptions = () =>{
        showloading();
        getPageProductOptions(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setData(res.content)
                setPageable({
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false)
            }
        })
        .finally(stoploading())
    }

    const onPage = (e) =>{
        setPageable({
            ...pageable,
            loading:true,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({
            ...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true)
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true)
    };
    
    const deleteProductOption = (id) => {
        removeProductOption(id)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const tblHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                        <Button label="Create Option" className="p-button-constrast p-float-left" icon="pi pi-plus" onClick={()=>frmOption.current.openForm()}/>
                        <div className="p-float-right">
                            <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e)}  />
                            <Button className="p-button-constrast" tooltip="Refresh" tooltipOptions={{position: "top"}} icon="pi pi-refresh" style={{'float':'right'}} onClick={() => setLoadTable(true)}/>
                        </div>
                        <h3 className="fle-datatable-header-title">Option List</h3>
                    </div>;

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Product Options</BreadcrumbsItem>

            <ProductOptionForm ref={frmOption}
                refreshTable={() => setLoadTable(true)}
            />

            <div className="p-col-12">
                <h1>Product Options</h1>

                <DataTable lazy={true} paginator={true} value={data}
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column header="Name" field="name" sortable={true} />
                    <Column header="None Label" field="noneLabel" sortable={true} />
                    <Column header="Type" field="type" body={(rowData) => PRODUCT_OPTION_TYPE[rowData.type].label} sortable={true}/>
                    <Column header="" className="tc-actions" style={{textAlign: 'center', width: '10%' }} 
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteProductOption(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmOption.current.openForm(rowData)} model={actionItems}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    );
}