import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import {Button} from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'primereact/spinner';
import { Fieldset } from 'primereact/fieldset';
import { CONDITION_CART_TYPES, CONDITION_METHOD_TYPES, CONDITION_METHOD_TYPE, CONDITION_CART_TYPE } from '../../constants';
import { RadioButton } from 'primereact/radiobutton';

class RuleCartConditions extends Component {
    constructor(props){
        super(props);
        this.state = {
            conditions: props.data.length>0?props.data:[{cartType: CONDITION_CART_TYPE.cart_subtotal, methodType: CONDITION_METHOD_TYPE.equal, minValue: '0', maxValue: '0'}]
        };
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({
                conditions: this.props.data.length>0?this.props.data:[{cartType: CONDITION_CART_TYPE.cart_subtotal, methodType: CONDITION_METHOD_TYPE.equal, minValue: '0', maxValue: '0'}]
            });
        }
    }

    addCondition = () => {
        let tmpConditions = [...this.state.conditions];
        tmpConditions.push({cartType: CONDITION_CART_TYPE.cart_subtotal, methodType: CONDITION_METHOD_TYPE.equal, minValue: '0', maxValue: '0'});
        this.props.onChange(tmpConditions);
    }

    removeCondition = (idx) => {
        let tmpConditions = [...this.state.conditions];
        tmpConditions.splice(idx, 1);
        this.props.onChange(tmpConditions);
    }

    onElementChange = (idx, key, value) => {
        let tmpConditions = [...this.state.conditions];
        let conditionItem = tmpConditions[idx];
        switch(key){
            case 'cartType':
                conditionItem.cartType=value;
                break;
            case 'methodType':
                conditionItem.methodType=value;
                conditionItem.minValue=1;
                conditionItem.maxValue=1;
                break;
            case 'minValue':
                conditionItem.minValue=value;
                break;
            case 'maxValue':
                conditionItem.maxValue=value;
                break;
            default:
                break;
        }
            
        tmpConditions.splice(idx, 1, conditionItem);
        this.props.onChange(tmpConditions);
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        return (
            <div className="p-grid">
                {/* <div className="p-col-12">
                    <Button label={trans('promotion.mgt.add_condition')} icon="pi pi-plus" iconPos="left" onClick={this.addCondition}/>
                </div>
                <div className="p-col-12">
                    {this.state.conditions.map((val, idx) => {
                        return (
                            <div key={"condition_"+idx}>
                                <Fieldset>
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-3">
                                            <label className="p-label">{trans('promotion.mgt.cart_type')}</label>
                                            <Dropdown value={val.cartType} options={translateListSimple(trans,CONDITION_CART_TYPES)}  onChange={(e) => this.onElementChange(idx, 'cartType', e.value)}/>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <label className="p-label">{trans('promotion.mgt.method_type')}</label>
                                            <Dropdown value={val.methodType} options={translateListSimple(trans,CONDITION_METHOD_TYPES)}  onChange={(e) => this.onElementChange(idx, 'methodType', e.value)}/>
                                        </div>
                                        {(() => {
                                            if(val.methodType!==CONDITION_METHOD_TYPE.range){
                                                return (
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label">{trans('promotion.mgt.value')}</label>
                                                        <Spinner value={val.maxValue} onChange={(e) => this.onElementChange(idx, 'maxValue', e.target.value)}/>
                                                    </div>
                                                );                                            
                                            }else{
                                                return ([
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label">{trans('promotion.mgt.from')}</label>
                                                        <Spinner value={val.minValue} onChange={(e) => this.onElementChange(idx, 'minValue', e.target.value)}/>
                                                    </div>,
                                                    <div className="p-col-12 p-md-2">
                                                        <label className="p-label">{trans('promotion.mgt.to')}</label>
                                                        <Spinner value={val.maxValue} onChange={(e) => this.onElementChange(idx, 'maxValue', e.target.value)}/>
                                                    </div>
                                                ]);
                                            }
                                        })()}
                                        {this.state.conditions.length>1 && 
                                            <div className="p-col-12 p-md-1">
                                                <Button icon="pi-md-close" className="p-button-danger" style={{top: "10px"}} onClick={() => this.removeCondition(idx)}/>
                                            </div>
                                        }
                                    </div>
                                </Fieldset>
                            </div>                           
                        );
                    })}                    
                </div> */}
            </div>
        );
    }
}

export default withNamespaces('message')(RuleCartConditions)