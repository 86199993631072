import React, { Component, useEffect, useRef, useState } from 'react';
import {TreeTable} from 'primereact/treetable';
import {Column} from "primereact/column";
import {Button} from 'primereact/button';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { showNotification } from '../../core/service/NotificationService';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { APP_FEP, TABLE_PAGING_LENGTH, TAXONOMY_TYPE } from '../../constants';
import { TaxonForm } from './TaxonForm';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { Dropdown } from 'primereact/dropdown';
import { getPageTaxons, getTreeTaxon, removeTaxon } from './TaxonServices';
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { getListDepartments } from '../../scp/departments/DepartmentServices';

export const TaxonListView  = (props) => {
    const taxonForm = useRef(null);
    const confirmNotify = useRef(null);

    const [mapDepartments, setMapDepartments] = useState({})
    const [type, setType] = useState(props.type);
    const [secondaryType, setSecondaryType] = useState(props.secondaryType);
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({app: APP_FEP, type: props.type, secondaryTypes: props.secondaryType?[props.secondaryType]:[]})
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })

    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(()=>{
        setType(props.type);
        setFilter({...filter, type: props.type, secondaryTypes: props.secondaryType?[props.secondaryType]:[]})
        setLoadTable(true);
    },[props.type])

    useEffect(()=>{
        setSecondaryType(props.secondaryType);
        if(props.secondaryType===TAXONOMY_TYPE.product.value)
            loadDepartments()
        setFilter({...filter, type: props.type, secondaryTypes: props.secondaryType?[props.secondaryType]:[]})
        setLoadTable(true);
    },[props.secondaryType])

    useEffect(()=>{
        if(isLoadTable)
            loadPageTaxons()
    },[isLoadTable])

    const loadDepartments = () => {
        getListDepartments({externalRef: false}).then(res => {
            let mapDept = {}

            if(res && res.length>0){
                res.map(o => {
                    mapDept[o.value] = o
                })
            }

            setMapDepartments(mapDept);
        })
    }

    const loadPageTaxons = () =>{
        showloading();
        getPageTaxons(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setDataList(res.content)
                setPageable({...pageable,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false);
            }
        })
        .finally(stoploading())
    }

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        console.log(e)
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const handleRemove = (id) => {
        removeTaxon(id).then(res => {
            if(!res.errorCode){
                loadPageTaxons();
                showNotification('success', 'Success Message', 'Action submitted')
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    const renderHeaderPageName = () => {
        if(type===TAXONOMY_TYPE.category.value){
            if(secondaryType===TAXONOMY_TYPE.product.value) return 'Product Categories'
            else if(secondaryType===TAXONOMY_TYPE.asset.value) return 'Asset Categories'
        }
        return TAXONOMY_TYPE[type]?TAXONOMY_TYPE[type].label:type;
    }

    const onAddOrEdit = (data) => {
        taxonForm.current.openForm(data, props.type);
    }

    const actionTemplate = (rowData) => {
        let items=[
            {label: 'Remove', icon: 'pi-md-trash', command:()=> confirmNotify.current.confirm(rowData.id)}
        ]
        return (
            <React.Fragment>
                <SplitButton label="Edit" className="p-button-constrast" model={items} onClick={() => onAddOrEdit(rowData)}></SplitButton>
            </React.Fragment>
        );
    }

    const tblHeader = () =>{
        let headerName = ''
        if(type){
            switch (type) {
                case TAXONOMY_TYPE.category.value:
                    headerName = 'Categories'
                break;
                case TAXONOMY_TYPE.branch.value:
                    headerName = 'Branches'
                break;
                case TAXONOMY_TYPE.location.value:
                    headerName = 'Locations'
                break;
                default: break;
            }
        }
        return (
            <TableHeader title={headerName}
            actionTemplate={<Button icon="pi pi-plus" label={`Create ${TAXONOMY_TYPE[type]?TAXONOMY_TYPE[type].label:''}`} className="p-button-constrast" style={{ 'float': 'left' }} onClick={() => onAddOrEdit()}/>}
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
        />
        )
    }
    
    return (
        <div className="p-grid">
            <BreadcrumbsItem to={props.match.url}>{renderHeaderPageName()}</BreadcrumbsItem>

            <TaxonForm ref={taxonForm}
                secondaryType={props.secondaryType}
                reloadTable={() => loadPageTaxons()}
            />

            <ConfirmNotification ref={confirmNotify}
                submitConfirm={(id) => handleRemove(id)}
                message="Are you sure you want to remove?"
            />

            <div className="p-col-12">
                <h1>{renderHeaderPageName()}</h1>
                <DataTable lazy={true} paginator={true} value={dataList} loading={isLoadTable}
                    first={pageable.page * pageable.rows}
                    header={tblHeader()} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column header="ID" field="id" sortable={true} headerStyle={{width: '8em'}} />
                    <Column header="Code" field="code" sortable={true} />
                    <Column header="Name" field="name" sortable={true} />
                    {type===TAXONOMY_TYPE.category.value && secondaryType===TAXONOMY_TYPE.product.value && 
                    <Column header="Department" field="departmentId" sortable={true} 
                        body={rowData => mapDepartments[rowData.departmentId] && mapDepartments[rowData.departmentId].label}/>
                    }
                    <Column header="Weight" field="weight" headerStyle={{width: '7em'}} bodyClassName="p-c" />
                    {(type===TAXONOMY_TYPE.branch.value || (type===TAXONOMY_TYPE.category.value && secondaryType===TAXONOMY_TYPE.product.value)) && 
                        <Column header="Active" field="active" headerStyle={{width: '7em'}} bodyClassName="p-c" body={rowData => {return rowData.active?'Yes':'No'}} />
                    }
                    <Column headerStyle={{width: '10em'}} bodyClassName="tc-actions" body={rowData => actionTemplate(rowData)}/>
                </DataTable>
            </div>
        </div> 
    );
}