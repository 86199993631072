import React, { Component } from 'react';
import classNames from 'classnames';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { saveLiveAccount, removeLiveAccount } from './EventService';
import { showNotification } from '../../core/service/NotificationService';
import { Checkbox } from 'primereact/checkbox';
import {Fieldset} from 'primereact/fieldset';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import { LIVE_ACCOUNT_TYPES } from '../../constants';

export class EventLiveAccountForm extends Component {

    constructor(props) {
        super(props);
        this.state ={
            data:{zoomConfig:{}},
            formHeader: 'Event Live Account',
            liveAccount: {
                dataList: [],
                loading: true,
                page: 0,
                rows: 10,
                total: 0,
                sortField: '',
                sortOrder: 0,
            },
            visible: false,
            formAction: false,
            errors: {}
        }
    }

    popularFormData = (data) => {
        this.setState({
            data: {
                id: data&&data.id ? data.id : null,
                name: data&&data.name ? data.name : '',
                enabled: data&&data.enabled ? true : false,
                type: data&&data.type ? data.type : null,
                zoomConfig: data&&data.zoomConfig ? data.zoomConfig : {email: '', apiKey: '', apiSecret: '', accessToken: '', leaveUrl: ''}
            },
            seeSecretKey: false,
            visible: true,
            errors: {}
        })
    }

    handelSave = () => {
        this.setState({ errors: {} });

        saveLiveAccount(this.state.data)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    removeAccount = () => {
        removeLiveAccount(this.state.data.id)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onHide = () => {
        this.setState({visible: false})
    }

    render() {
        return (
            <Sidebar visible={this.state.visible} className="p-sidebar-md" position="right" onHide={() => this.onHide()} blockScroll={true} style={{overflowY: 'auto'}}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <Fieldset legend="Information">
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={this.state.data.name} onChange={(e) => this.setState({data: {...this.state.data, name: e.target.value}})} />
                                <div className="p-form-error">{this.state.errors.name}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Type</label>
                                <Dropdown options={LIVE_ACCOUNT_TYPES} value={this.state.data.type} onChange={(e) => this.setState({data: {...this.state.data, type: e.value}})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Enabled</label>
                                <Checkbox checked={this.state.data.enabled} onChange={(e) => this.setState({data: {...this.state.data, enabled: !this.state.data.enabled}})} />
                            </div>
                        </Fieldset>
                    </div>
                    
                    <div className="p-col-12">
                        <Fieldset legend="Account Config">
                            <div className="p-col-12">
                                <label className="p-label">Account Email</label>
                                <InputText className="p-col-11" value={this.state.data.zoomConfig.email} onChange={(e) => this.setState({data: {...this.state.data, zoomConfig: {...this.state.data.zoomConfig, email: e.target.value}}})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">API Key</label>
                                <InputText className="p-col-11" value={this.state.data.zoomConfig.apiKey} onChange={(e) => this.setState({data: {...this.state.data, zoomConfig: {...this.state.data.zoomConfig, apiKey: e.target.value}}})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">API Secret</label>
                                <div className="p-inputgroup">
                                    <InputText type={!this.state.seeSecretKey ? 'password' : 'text'} className="p-col-11" value={this.state.data.zoomConfig.apiSecret} onChange={(e) => this.setState({data: {...this.state.data, zoomConfig: {...this.state.data.zoomConfig, apiSecret: e.target.value}}})} />
                                    <span className="p-inputgroup-addon">
                                        <i style={{fontSize:22, cursor: 'pointer'}} className="pi pi-eye" onClick={() => this.setState({seeSecretKey : !this.state.seeSecretKey})}/>
                                    </span>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Access Token</label>
                                <InputTextarea className="p-col-11" value={this.state.data.zoomConfig.accessToken} onChange={(e) => this.setState({data: {...this.state.data, zoomConfig: {...this.state.data.zoomConfig, accessToken: e.target.value}}})} rows={4} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Leave URL</label>
                                <InputText className="p-col-11" value={this.state.data.zoomConfig.leaveUrl} onChange={(e) => this.setState({data: {...this.state.data, zoomConfig: {...this.state.data.zoomConfig, leaveUrl: e.target.value}}}) } />
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid p-margin-top-30">
                    {this.state.data.id &&
                        <div className="p-col-12 p-md-4">
                            <Button label="Remove" icon="pi-md-trash" className="p-button-danger" onClick={() => this.removeAccount()}/>
                        </div>
                    }
                    <div className={classNames("p-col-12 p-r", {"p-md-8": this.state.data.id})}>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.onHide()}/>
                        <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handelSave()}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}