import React, { Component } from "react";
import moment from "moment";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { NoteServices } from "./NoteServices";
import { showNotification } from '../../core/service/NotificationService';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import Avatar from 'react-avatar';
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { getPriorityLevelUse } from "../../scp/tenant-configuration/ConfigruationServices";
import { NOTE_STATUSES } from "../../constants"

export class NoteList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            note: {
                id: '',
                objectType: props.type,
                objectId: props.id,
                title: '',
                content: '',
                status: "create",
                priority: 2,
                notification: false
            },
            formActionDialog: {
                header: 'New Note',
                visible: false
            },
            arrNotes: [],
            arrNoteChks: [],
            arrNoteFilter: [],

            // note activities
            formViewDialog: {
                visible: false
            },
            noteActivity: {},
            noteActivities: [],
            errors: {}
        }
        this.noteServices = new NoteServices();
    }

    componentDidMount(){
        if(this.props.id && this.props.type)
            this.getListNotes(this.props.id, this.props.type);
        this.loadDepartments();
        // this.getPrioriyLevelUse();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.id !== prevProps.id){
            this.getListNotes(this.props.id, this.props.type)
        }
    }

    handleSaveNote = () => {
        console.log(this.state.note);
        this.noteServices.saveNote(this.state.note)
        .then(res => {
            if(!res.errorCode){
                let arr = [...this.state.arrNotes];
                if(arr.indexOf(arr.find(x => x.id===res.id)) !==-1)
                    arr.splice(arr.indexOf(arr.find(x => x.id===res.id)), 1, res)
                else
                    arr.unshift(res);
                this.setState({
                    arrNotes: arr
                });
                this.hideFormActionDialog();
                this.popularForm();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorObj) this.setState({errors: res.errorObj});   
            }
        })
    }

    getListNotes = () => {
        this.noteServices.getListNotes(this.props.id, this.props.type)
        .then(res => {
            if(!res.errorCode){
                if(res) {
                    this.setState({ arrNotes: res });
                }
            }
        })
    }

    getNote = (id) => {
        this.noteServices.getNote(id)
        .then(res => {
            if(!res.errorCode){
                this.popularForm(res);
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        })
    }

    loadDepartments = () => {
        let deptUsers = localStorage.getItem('depts');
        if(deptUsers && deptUsers !== 'undefined'){
            let deptsData = JSON.parse(deptUsers);
            let tempArrDepts = [];
            if(deptsData && deptsData.length > 0){
                deptsData.map(dept => {
                    tempArrDepts.push({label: dept.name, value: dept.id})
                });
            }
            this.setState({ departments: tempArrDepts });
        }
    }

    getPrioriyLevelUse = () => {
        getPriorityLevelUse()
        .then(res => {
            if(!res.errorCode){
                this.setState({ priorityLevelUse: res });
            }
        })
    }

    showFormActionDialog = () => {
        this.setState({
            formActionDialog: {
                ...this.state.formActionDialog,
                visible: true
            }
        })
    }

    hideFormActionDialog = () => {
        this.setState({
            formActionDialog: {
                ...this.state.formActionDialog,
                visible: false
            }
        })
    }

    onAddOrEdit = (id) => {
        if(!id){
            this.popularForm();
        }else{
            this.getNote(id);
            this.showFormActionDialog();
        }
    }

    onChangeCkh = (e) => {
        let termArr = [...this.state.arrNoteChks];
        if(e.checked){
            if(termArr.indexOf(e.value) === -1)
                termArr.push(e.value)
        }else{
            if(termArr.indexOf(e.value) !== -1)
                termArr.splice(termArr.indexOf(e.value), 1)
        }
        this.setState({
            arrNoteChks: termArr
        })
    }

    onDueDateChange = (e) => {
        this.setState({
            note: {
                ...this.state.note, 
                dueDate: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm')  : '',
                dueDateValue: e.value,
            }
        });
    }

    onFilter = (e) => {
        let tempArr = [...this.state.arrNoteFilter];
        if(e.checked){
            if(!tempArr.includes(e.value)){
                tempArr.push(e.value)
            }
        }else{
            tempArr.splice(tempArr.indexOf(e.value), 1);
        }
        this.setState({ arrNoteFilter: tempArr });
    }

    //#region note activity
    handleSaveNoteActivity = () => {
        this.noteServices.saveNoteActivity(this.state.note.id, this.state.noteActivity)
        .then(res => {
            if(!res.errorCode){
                let arr = [...this.state.arrNotes];
                if(arr.indexOf(arr.find(x => x.id===this.state.note.id)) !==-1){
                    let noteObj = {
                        ...this.state.note,
                        status: this.state.noteActivity.status
                    }
                    arr.splice(arr.indexOf(arr.find(x => x.id===this.state.note.id)), 1, noteObj);
                }
                this.setState({ 
                    note: {
                        ...this.state.note,
                        status: this.state.noteActivity.status
                    },
                    noteActivity: {
                        status: null,
                        message: ''
                    },
                    arrNotes: arr
                });
                this.getListNoteActivities(this.state.note.id);
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorObj) this.setState({errors: res.errorObj});             
            }
        })
    }

    getListNoteActivities = (id) => {
        this.noteServices.getListNoteActivities(id)
        .then(res => {
            if(!res.errorCode){
                if(res) {
                    this.setState({ noteActivities: res });
                }
            }
        })
    }

    
    viewFormDialog = (id) => {
        this.getNote(id);
        this.getListNoteActivities(id);
        this.showFormViewDialog();
    }

    showFormViewDialog = () => {
        this.setState({
            formViewDialog: {
                ...this.state.formViewDialog,
                visible: true
            }
        });
    }

    hideFormViewDialog = () => {
        this.setState({
            formViewDialog: {
                ...this.state.formViewDialog,
                visible: false
            }
        });
    }

    // #endregion
    popularForm = (data) => {
        this.setState({
            note: {
                ...this.state.note,
                id: data ? data.id : '',
                departmentId: data ? data.departmentId : '',
                title: data ? data.title: '',
                category: data ? data.category: 'general',
                content: data ? data.content: '',
                status: data ? data.status : 0,
                priority: data ? data.priority : 2,
                notification: data ? data.notification : false,
                dueDate: data ? data.dueDate : '',
                dueDateValue: data && data.dueDate ? moment(data.dueDate, 'YYYY-MM-DD hh:mm A').toDate() : '',
                date_dueDateValue: data && data.dueDate ? moment(data.dueDate, moment.HTML5_FMT.DATE).toDate() : '',
                time_dueDateValue: data && data.dueDate ? moment(data.dueDate, 'YYYY-MM-DD HH:mm').toDate() : '',
            },
            errors: {}
        },()=>{
            this.setState({
                formActionDialog: {
                    ...this.state.formActionDialog,
                    header: data ? 'Edit Note' : 'New Note'
                }
            })
            
        })
    }

    render(){
        let itemMenus = [
            {label: 'Add Note', icon: 'pi-md-plus',
                command: () => {
                    this.popularForm();
                    this.showFormActionDialog();
                }
            },
            {label: '...', icon: 'pi-md-refresh'},
        ];

        let lstPriority = [
            {label: 'Low', value: 1},
            {label: 'Normal', value: 2},
            {label: 'Medium', value: 3},
            {label: 'High', value: 4},
            {label: 'Critical', value: 5},
        ];
        if(!this.state.priorityLevelUse || (this.state.priorityLevelUse && this.state.priorityLevelUse===3)){
            lstPriority = lstPriority.filter(priority => (priority.value!==1 && priority.value!==5));
        }


        let lstCategories = [
            {label: 'General', value: 'general'},
            {label: 'Email', value: 'email'},
            {label: 'Call', value: 'call'},
            {label: 'Meeting', value: 'meeting'},
        ];

        let lstActivityStatus = NOTE_STATUSES.filter(x => x.value !== this.state.note.status);

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        {this.state.arrNotes && this.state.arrNotes.length > 0 ? 
                            <div className="p-col-12 p-md-9">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label>Category:</label>
                                    </div>
                                    <div className="p-col-12">
                                        <span className="p-margin-right-15" style={{whiteSpace: "nowrap"}}>
                                            <Checkbox inputId="cbGeneral" value="general" onChange={(e) => this.onFilter(e)} checked={this.state.arrNoteFilter.includes('general')} />
                                            <label htmlFor="cbGeneral">General</label>
                                        </span>
                                        <span className="p-margin-right-15" style={{whiteSpace: "nowrap"}}>
                                            <Checkbox inputId="cbEmail" value="email" onChange={(e) => this.onFilter(e)} checked={this.state.arrNoteFilter.includes('email')} />
                                            <label htmlFor="cbEmail">Email</label>
                                        </span>
                                        <span className="p-margin-right-15" style={{whiteSpace: "nowrap"}}>
                                            <Checkbox inputId="cbCall" value="call" onChange={(e) => this.onFilter(e)} checked={this.state.arrNoteFilter.includes('call')} />
                                            <label htmlFor="cbCall">Call</label>
                                        </span>
                                        <span style={{whiteSpace: "nowrap"}}>
                                            <Checkbox inputId="cbMeeting" value="meeting" onChange={(e) => this.onFilter(e)} checked={this.state.arrNoteFilter.includes('meeting')} />
                                            <label htmlFor="cbMeeting">Meeting</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            : ''
                        }
                        <div className={"p-col-12 p-r" + (this.state.arrNotes && this.state.arrNotes.length > 0 ? " p-md-3" : "")}>
                            <Button icon="pi-md-menu" className="secondary-btn" tooltip="Add Note" tooltipOptions={{position: 'top'}} onClick={(event) => this.menu.toggle(event)}/>
                        </div>
                    </div>
                    <Menu ref={el=>this.menu=el} popup={true} model={itemMenus}></Menu>
                    <hr />
                    <ul className="p-padding-none" style={{listStyle: 'none'}}>
                        {this.state.arrNotes.map((item, index) => {
                            let className = 'p-margin-bottom-10 p-padding-left-10 p-priority-' + item.priority ;
                            return (
                                this.state.arrNoteFilter.length === 0 || this.state.arrNoteFilter.includes(item.category) ?
                                    <li key={index} className={className} style={{borderBottom: '1px solid #d0c9c9',}}>
                                        <div className="p-grid">
                                            <div className="p-col-9 p-padding-top-10">
                                                {/* <Checkbox value={item.id} onChange={(e) => this.onChangeCkh(e)} checked={this.state.arrNoteChks.indexOf(item.id)!==-1 ? true : false} /> */}
                                                <span>{item.title} ({NOTE_STATUSES.find(x => x.value===item.status).label})</span>
                                                <div className="p-padding-top-10">
                                                    Category: {item.category ? (item.category.substring(0,1).toUpperCase() + item.category.substring(1)) : ''}
                                                </div>
                                                {item.dueDate ?
                                                    <div className="p-padding-top-10">
                                                        Due on: {moment(item.dueDate).format('MM-DD-YYYY hh:mm A')}
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                            <div className="p-col-3" style={{textAlign: 'right'}}>
                                                <Button icon="pi pi-pencil" className="p-button-warning" tooltip="Edit Note" tooltipOptions={{position: 'top'}} onClick={() => this.onAddOrEdit(item.id)} style={{height: '2em', width: '2em'}} />
                                                <Button icon="pi pi-search" className="p-button-info" tooltip="View Note" tooltipOptions={{position: 'top'}} onClick={() => this.viewFormDialog(item.id)} style={{height: '2em', width: '2em'}} />
                                            </div>
                                        </div>
                                        
                                    </li>
                                : ''
                            )
                        })}
                    </ul>
                </div>
                <Sidebar visible={this.state.formActionDialog.visible} style={{overflowY: 'auto', width: '25em'}} position="right" blockScroll={true} onHide={this.hideFormActionDialog}>
                    <h2>{this.state.formActionDialog.header}</h2>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">* Title</label>
                            <InputText value={this.state.note.title} onChange={(e)=> this.setState({note: {...this.state.note, title: e.target.value}})} maxLength="50" />
                            <div className="p-form-error">{this.state.errors.title}</div>                        
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Content</label>
                            <InputTextarea rows={3} cols={30} value={this.state.note.content} onChange={(e) => this.setState({note: {...this.state.note, content: e.target.value}})} />
                        </div>
                        <div className="p-col-12">
                            <label>Department</label>
                            <Dropdown options={this.state.departments} value={this.state.note.departmentId}  onChange={(e)=> this.setState({note: {...this.state.note, departmentId: e.value}})}/>
                        </div>
                        <div className="p-col-12">
                            <label>Category</label>
                            <Dropdown options={lstCategories} value={this.state.note.category}  onChange={(e)=> this.setState({note: {...this.state.note, category: e.value}})}/>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Priority</label>
                            <Dropdown value={this.state.note.priority} options={lstPriority} onChange={(e) => this.setState({note: {...this.state.note, priority: e.value}})} style={{width: '100%'}}
                                itemTemplate={(option) => {
                                    let className = 'p-priority p-priority-' + option.value;
                                    return(
                                        <div>
                                            <span className={className}></span>
                                            <span className="p-margin-left-10">{option.label}</span>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                        <div className="p-col-12">
                            <Checkbox value={this.state.note.notification} onChange={(e) => this.setState({note: {...this.state.note, notification: e.checked}})} checked={this.state.note.notification} />
                            <label>Notification?</label>                        
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Due Date</label>
                            <MaskedCalendar value={this.state.note.dueDateValue} showIcon={true} showTime={true} hourFormat="12" hideOnDateTimeSelect={true} breakDateTime={true} onChange={(e) => this.onDueDateChange(e)} dateFormat="mm-dd-yy"/>
                            <div className="p-form-error">{this.state.errors.dueDate}</div> 
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Save" icon="pi pi-check" onClick={this.handleSaveNote}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.hideFormActionDialog()}/>
                        </div>
                    </div>
                </Sidebar>
                <Sidebar visible={this.state.formViewDialog.visible} position="right" blockScroll={true} onHide={this.hideFormViewDialog} style={{overflowY: 'auto', width: '50em'}} >
                    <h2>{this.state.note.title} <i>({this.state.note && this.state.note.status && NOTE_STATUSES.find(x => x.value===this.state.note.status).label})</i></h2>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <span>{this.state.note.content}</span>
                        </div>
                    </div>
                    <Fieldset legend="Comment">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">* Status</label>
                                <Dropdown value={this.state.noteActivity.status} options={lstActivityStatus} onChange={(e) => this.setState({noteActivity: {...this.state.noteActivity, status: e.value}})} style={{width: '100%'}} />
                                <div className="p-form-error">{this.state.errors.status}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Message</label>
                                <InputTextarea rows={5} cols={30} value={this.state.noteActivity.message} onChange={(e) => this.setState({noteActivity: {...this.state.noteActivity, message: e.target.value}})} />
                            </div>
                        </div>
                        <div className="p-grid form-group">
                            <div className="p-col-12" style={{textAlign: 'right'}}>
                                <Button label="Save" icon="pi pi-check" onClick={this.handleSaveNoteActivity}/>                                
                            </div>
                        </div>
                    </Fieldset>
                    <br />
                    <div className="p-grid form-group">
                        <div className="p-col-12">
                            <ul className="p-margin-none p-padding-none" style={{listStyle: 'none', maxHeight: 450, overflow: 'auto'}}>
                                {this.state.noteActivities.map((item, index) => {
                                    return (
                                        <li key={index} style={{padding: 5}}>
                                            {item.user.avatar ? 
                                                <img src={item.user.avatar} width={30} style={{float: 'left'}} />
                                                : <Avatar className="topbar-profile-avatar" maxInitials={2} round={true} textSizeRatio={2} size={30} name={item.user.fistname + " " + item.user.lastName} style={{float: 'left'}} />
                                            }
                                            <div style={{marginLeft: 40, minHeight: 30}}>
                                                <div>({item.statusChange})</div>
                                                <div><i>{moment(item.created).format('MM-DD-YYYY HH:mm:ss')}</i></div>
                                                <div className="p-padding-5-0">{item.message}</div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Close" icon="pi pi-times" className="p-button-secondary" onClick={() => this.hideFormViewDialog()}/>
                        </div>
                    </div>
                </Sidebar>
            </div>            
        );
    }
}