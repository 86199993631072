import React, { Component, forwardRef, useState, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { OrderList } from "primereact/orderlist";
import {showNotification, showSuccessNotify, showErrorNotify} from '../../core/service/NotificationService';
import { Sidebar } from "primereact/sidebar";
import { removeProductOptionValue, saveProductOption } from "./ProductOptionServices";
import { Fieldset } from "primereact/fieldset";
import { PROD_TYPE, PRODUCT_OPTION_TYPE, APP_FEP } from "../../constants";
import { moneyFormat, titleCaseText } from "../../core/service/CommonService";
import { Checkbox } from "primereact/checkbox";
import { SplitButton } from "primereact/splitbutton";
import { RadioButton } from "primereact/radiobutton";

export const ProductOptionForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState('Add Product Option')
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({application: APP_FEP})
    const [valueItems, setValueItems] = useState([])
    const [frmItemVisible, setFrmItemVisible] = useState(false)
    const [frmValueItem, setFrmValueItem] = useState({})
    const [errors, setErrors] = useState({})

    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader((e?'Edit':'Add') + ' Product Option')
            setFrmData(popularFormData(e))
            setValueItems(e?e.values:[])
            setErrors({})
            setVisible(true)
        }
    }))

    const popularFormData = (e) => {
        return {
            id: e ? e.id: '',
            application: APP_FEP,
            name: e ? e.name: '',
            type: e ? e.type: PRODUCT_OPTION_TYPE.variant.value,
            noneLabel: e ? e.noneLabel: '',
            typeUsing: null,
            extraPrice: e && e.extraPrice,
            mandatory: e && e.mandatory
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData(popularFormData())
        setValueItems([])
        setErrors({})
    }

    const handleSave = () => {
        let toSave = {...frmData};
        toSave.values = valueItems;

        saveProductOption(toSave)
        .then(res => {
            if(!res.errorCode){
                props.refreshTable();
                closeForm()
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify(res.errorMessage)
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });
    }

    const valueItemTemplate = (item) => {
        if(item){
            return (
                <div className="p-clearfix">
                    <div className="p-grid p-vertical-c">
                        <div className="p-col-12 p-md-8">
                            <div>Value: <strong>{item.name}</strong></div>
                            {frmData.extraPrice && 
                                <div>Price: <strong>{moneyFormat(item.extraPrice01)}</strong></div>
                                // <div>Price(01/02): <strong>{moneyFormat(item.extraPrice01)}/{moneyFormat(item.extraPrice02)}</strong></div>
                            }
                        </div>
                        <div className="p-col-12 p-md-4 p-r">
                            <Button icon="pi pi-pencil" className="p-button-info btn-xs" onClick={() => addOreditValueItem(item)}/>
                            <Button icon="pi pi-times" className="p-button-danger btn-xs" onClick={() => removeValueItem(item)}/>
                            {/* <SplitButton label="Edit" className="p-l" onClick={() => addOreditValueItem(item)} model={[{label: 'Remove', icon: 'pi-md-close', command:() => removeValueItem(item)}]} style={{width: 'auto'}} /> */}
                        </div>
                    </div>
                    
                </div>
            )
        }
    }

    const onSaveValueItem = () => {
        let valueItem = {...frmValueItem};
        let isExtraPrice = frmData.extraPrice;
        
        if(!valueItem){
            showErrorNotify("Can't perform action!")
            return;
        }
        
        let lstValues = [...valueItems];
        let frmErrors = {};

        if(!valueItem.name) frmErrors.name = 'Field is required';
        if(isExtraPrice && (!valueItem.extraPrice01 && valueItem.extraPrice01 !== 0)) frmErrors.extraPrice01 = 'Field is required';
        // if(isExtraPrice && (!valueItem.extraPrice02 && valueItem.extraPrice02 !== 0)) frmErrors.extraPrice02 = 'Field is required';

        if(Object.keys(frmErrors).length>0){
            setErrors({...errors, frmValueItem: frmErrors})
            return;
        }
        
        let item = {
            id: valueItem.id?valueItem.id:'',
            name: valueItem.name,
            extraPrice01: parseFloat(valueItem.extraPrice01), 
            // extraPrice02: parseFloat(valueItem.extraPrice02),
            index: valueItem.index?valueItem.index:lstValues.length
        };
        
        if(lstValues[valueItem.index]){
            lstValues[valueItem.index] = item;
        }else{
            lstValues.push(item);
        }

        setValueItems(lstValues)
        setErrors({...errors, frmValueItem: {}})
        popularValueItemData()
        setFrmItemVisible(true)
    }

    const addOreditValueItem = (e) => {
        popularValueItemData(e)
        setFrmItemVisible(true)
    }

    const popularValueItemData = (e) => {
        setFrmValueItem({
            id: e?e.id:'',
            name: e?e.name:'',
            extraPrice01: e?e.extraPrice01:'',
            // extraPrice02: e?e.extraPrice02:'',
            index: e?e.id?valueItems.findIndex(item => item.id===e.id):e.index:null
        })
    }

    const removeValueItem = (item) => {
        if(frmData.id && item.id){
            removeProductOptionValue(frmData.id, item.id)
            .then(res => {
                if(!res.errorCode){
                    let isValueItemEditing = frmValueItem.id === item.id
                    if(isValueItemEditing){
                        popularValueItemData()
                        setFrmItemVisible(false)
                    }

                    let lstValues = [...valueItems]
                    lstValues.splice(lstValues.indexOf(lstValues.find(x => item.id === x.id)), 1)

                    setValueItems(lstValues)

                    showSuccessNotify('Action submitted');
                }else{
                    showErrorNotify(res.errorMessage);
                }
            });
        } else {
            let lstValues = [...valueItems];
            lstValues.splice(lstValues.indexOf(lstValues.find(x => item.counter === x.counter)), 1);
            setValueItems(lstValues);
        }
    }

    const onValueItemChange = (items) => {
        let lstValues = []

        if(items.length>0){
            items.forEach(i => i&&lstValues.push(i))
        }

        setValueItems(lstValues)
    }

    const renderHeaderListValue = () => {
        return(
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                <Button label="Add Value" icon="pi pi-plus" className="p-button-constrast p-float-left" style={{ width: 'auto' }} onClick={() => addOreditValueItem()} />
                <h3 className="fle-datatable-header-title p-margin-top-0 p-margin-bottom-0">List of Values</h3>
            </div>
        )
    }

    return (
        <Sidebar visible={visible} position="right" className="p-sidebar-md"  style={{ overflowY: "auto"}} blockScroll={true} onHide={closeForm}>
            <h2>{header}</h2>
            
            <div className="p-grid">
                <div className="p-col-12">
                    {/* {frmData.id ?
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <label>Type: <strong>{PRODUCT_OPTION_TYPE[frmData.type].label}</strong></label>
                        </div>
                    </div>
                    :
                    <Fieldset legend="Type">
                        <div className="p-grid p-fluid form-group">
                            {Object.values(PRODUCT_OPTION_TYPE).map((e, idx) => {
                                return (
                                    <div key={"optionType_" + idx} className="p-col-12">
                                    <RadioButton inputId={"optionType_" + idx} value={e.value} onChange={(e) => setFrmData({...frmData, type: e.value})} checked={frmData.type===e.value} />
                                    <label htmlFor={"optionType_" + idx} className="p-radiobutton-label">{e.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </Fieldset>
                    } */}
                    <Fieldset legend="Information">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: titleCaseText(e.target.value)})} />
                                <span className="p-form-error">{errors.name}</span>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">None label</label>
                                <InputText value={frmData.noneLabel} onChange={(e) => setFrmData({...frmData, noneLabel: e.target.value})} />
                                <span className="p-form-error">{errors.noneLabel}</span>
                            </div>
                            {frmData.type === PRODUCT_OPTION_TYPE.properties.value && 
                            <React.Fragment>
                                <div className="p-col-12">
                                    <Checkbox inputId="cbExtraPrice" checked={frmData.extraPrice} onChange={(e) => setFrmData({...frmData, extraPrice: e.checked})} />
                                    <label htmlFor="cbExtraPrice">Extra Price?</label>
                                </div>
                                <div className="p-col-12">
                                    <Checkbox inputId="cbMandatory" checked={frmData.mandatory} onChange={(e) => setFrmData({...frmData, mandatory: e.checked})} />
                                    <label htmlFor="cbMandatory">Mandatory?</label>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </Fieldset>

                    <Fieldset legend="Option values">
                        <div className="p-grid p-fluid form-group">
                            {frmItemVisible && 
                                <React.Fragment>
                                    <div className="p-col-12">
                                    <label className="p-label">* Name</label>
                                        <InputText id="valueInput" placeholder="Insert value" value={frmValueItem.name} onChange={(e) => setFrmValueItem({...frmValueItem, name: e.target.value })}/>
                                        <div className="p-form-error">{errors.frmValueItem && errors.frmValueItem.name}</div>
                                    </div>
                                    {frmData.extraPrice && 
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label className="p-label">* Price</label>
                                                <InputText keyfilter="money" value={frmValueItem.extraPrice01} maxLength={12} onChange={(e) => setFrmValueItem({...frmValueItem, extraPrice01: e.target.value})} />
                                                <span className="p-form-error">{errors.frmValueItem && errors.frmValueItem.extraPrice01}</span>
                                            </div>
                                            {/* <div className="p-col-12">
                                                <label className="p-label">* Price 02</label>
                                                <InputText keyfilter="money" value={frmValueItem.extraPrice02} maxLength={12} onChange={(e) => setFrmValueItem({...frmValueItem, extraPrice02: e.target.value})} />
                                                <span className="p-form-error">{errors.frmValueItem && errors.frmValueItem.extraPrice02}</span>
                                            </div> */}
                                        </React.Fragment>
                                    }
                                    <div className="p-col-12 p-r">
                                        <Button label="Save" className="p-button-info" onClick={() => onSaveValueItem()} style={{width: 'auto'}} />
                                        <Button label="Cancel" className="p-button-secondary" onClick={() => {popularValueItemData(); setFrmItemVisible(false)}} style={{width: 'auto'}}/>
                                    </div>
                                </React.Fragment>
                            }
                            <div className="p-col-12">
                                <OrderList value={valueItems} dragdrop={true} itemTemplate={valueItemTemplate}
                                    responsive={true} header={renderHeaderListValue()} listStyle={{height: '20em'}} 
                                    onChange={(e) => onValueItemChange(e.value)} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-grid">
                <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()} />
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()} />
                </div>
            </div>
        </Sidebar>
    )
})