import React, {useState, useEffect} from 'react';
import moment, { HTML5_FMT } from 'moment';
import { TABLE_PAGING_LENGTH, PRODUCT_TYPE, APP_FEP, PRODUCT_PRICE_UNIT, PRODUCT_CLASSIFICATION, SLOT_REQUIREMENT_TYPE } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getPageProductAvailabilities, saveProductAvailability, removeProductAvailability, saveAssociatedProduct, removeAssociatedProduct, getListProducts, saveGroupAssociation, removeGroupAssociation, getProduct } from './ProductServices';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Spinner } from 'primereact/spinner';
import { ProductPrices } from './ProductPrice';
import { InputText } from 'primereact/inputtext';

const DATA_TYPE = {
    group: 'group',
    product: 'product'
}

export const ProductAssociated = (props) => {
    const [frmVisible, setFrmVisible] = useState(false)
    const [dataType, setDataType] = useState(null)
    const [frmData, setFrmData] = useState({})
    const [frmGroupData, setFrmGroupData] = useState({})
    const [groupAssociations, setGroupAssociations] = useState([])
    const [availableProducts, setAvailableProducts] = useState([])
    const [errors, setErrors] = useState({prices: {}})
    const [data, setData] = useState(props.value)
    const [expandedRows, setExpandedRows] = useState([])
    const [filter, setFilter] = useState({
        app: APP_FEP,
        branchIds: props.branchId?[props.branchId]:[],
        excludedIds: [props.productId]
    })
    const [associatedProduct, setAssociatedProduct] = useState({})
    const [groupAssociation, setGroupAssociation] = useState({})

    useEffect(() => {
        if(props.productId){
            setGroupAssociations(data?data:[])
        }else{
            setGroupAssociations([])
        }
    }, [props.productId])

    useEffect(() => {
        let tmpGroup = frmData.groupId&&groupAssociations.find(group => group.id===frmData.groupId)?groupAssociations.find(group => group.id===frmData.groupId):{}
        setGroupAssociation(tmpGroup)
        if(frmData.groupId)
            setFilter({...filter, types: [tmpGroup.type]})
        setFrmData({...frmData, prices: []})
    }, [frmData.groupId])

    useEffect(() => {
        async function loadData(){
            let tmpGroup = frmData.groupId && groupAssociations.find(group => group.id===frmData.groupId)?groupAssociations.find(group => group.id===frmData.groupId):null
            let tmpAssociatedProduct={}
            let tmpDefaultPrices=[]

            if(tmpGroup){
                tmpAssociatedProduct = await getProduct(frmData.associatedId)
                
                if(frmData.id && frmData.prices && frmData.prices.length>0){
                    let tmpPrices = frmData.prices
                    if(tmpAssociatedProduct.classification===PRODUCT_CLASSIFICATION.variable.value){
                        if(!tmpAssociatedProduct.free && tmpAssociatedProduct.pricedByVariant && tmpAssociatedProduct.variants){
                            let isContaintPriceByVariant = tmpPrices.some(p => p.variant) // if there is change price option by variant
                            if(!isContaintPriceByVariant){
                                tmpPrices = []
                                for (const variant of tmpAssociatedProduct.variants) {
                                    let tmpVariantPrices = variant.prices?variant.prices.map(vp => {return {...vp, variant: variant.id}}):[]
                                    tmpPrices = tmpPrices.concat(tmpPrices, tmpVariantPrices)
                                }
                            }
                        }
                    }
                    tmpDefaultPrices = tmpPrices
                }else{
                    if(tmpAssociatedProduct.classification===PRODUCT_CLASSIFICATION.variable.value){
                        if(!tmpAssociatedProduct.free){
                            if(tmpAssociatedProduct.pricedByVariant){
                                if(tmpAssociatedProduct.variants){
                                    for (const variant of tmpAssociatedProduct.variants) {
                                        let tmpPrices = variant.prices?variant.prices.map(vp => {return {...vp, variant: variant.id}}):[]
                                        tmpDefaultPrices = [...tmpDefaultPrices, ...tmpPrices]
                                    }
                                }
                            }else{
                                tmpDefaultPrices = tmpAssociatedProduct.prices?tmpAssociatedProduct.prices:[]
                            }
                        }
                    }else{
                        if(!tmpAssociatedProduct.free){
                            tmpDefaultPrices = tmpAssociatedProduct.prices?tmpAssociatedProduct.prices:[]
                        }
                    }
                }
            }
            setFrmData({...frmData, prices: tmpDefaultPrices})
            setAssociatedProduct(tmpAssociatedProduct)
        }
        loadData()
    }, [frmData.associatedId])

    useEffect(() => {
        if(filter.types && filter.types.length>0)
            loadListProducts();
    }, [filter.types])

    const loadListProducts = () => {
        getListProducts(filter, false)
        .then(res => {
            setAvailableProducts(res)
            // setLoadProduct(false)
        })
    }

    const addOrEditItem = (type, e) => {
        setDataType(type)
        if(type===DATA_TYPE.group){
            setFrmGroupData({
                id: e?e.id:null,
                name: e?e.name:'',
                type: e?e.type:PRODUCT_TYPE.program.value,
                slotType: e?e.slotType:SLOT_REQUIREMENT_TYPE.particle.value,
                mandatory: e?e.mandatory:false,
                multiSelect: e?e.multiSelect:false,
                weight: e?e.weight:0
            })
        }else{
            setFrmData({
                id: e?e.id:null,
                type: e?e.type:null,
                product: e?e.associatedProduct:null,
                groupId: e?e.groupId:null,
                associatedId: e?e.associatedProduct.id:null,
                dependenceProductId: e?e.dependenceProductId:null,
                included: e?e.included:false,
                allowInputQty: e?e.allowInputQty:true,
                displayQtyAvailable: e?e.displayQtyAvailable:false,
                quantity: e?e.quantity : 0,
                rentalUnitApply: e && e.rentalUnitApply?e.rentalUnitApply:PRODUCT_PRICE_UNIT.hr.value,
                prices: e&&e.prices?e.prices:[],
                free: e&&e.free,
                priceOverride: e&&e.priceOverride,
                weight: e?e.weight:0
            })
        }
        
        setFrmVisible(true)
    }

    const closeForm = () => {
        setFrmVisible(false)
        setDataType(null)
        setFrmData({prices: []})
        setFrmGroupData({})
        setErrors({prices: {}})
    }

    const deleteGroupItem = (id) => {
        removeGroupAssociation(props.productId, id)
        .then(res => {
            if(!res.errorCode){
                let tmpData = [...data];
                const dataIdx = tmpData.findIndex(e => e.id===id);
                if(dataIdx!==-1)
                    tmpData.splice(dataIdx, 1);
                
                setData(tmpData)
                setGroupAssociations(tmpData?tmpData:[])

                if(frmVisible && id===frmGroupData.id)
                    closeForm()

                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const deleteAssociatedItem = (groupId, id) => {
        removeAssociatedProduct(groupId, id)
        .then(res => {
            if(!res.errorCode){
                let tmpData = [...data];
                const dataIdx = tmpData.findIndex(e => e.id===groupId);
                let dataItem = tmpData[dataIdx]

                let associatedProducts = [...dataItem.associatedProducts];

                const associatedIdx = associatedProducts.findIndex(e => e.id===id);
                if(associatedIdx!==-1)
                    associatedProducts.splice(associatedIdx, 1);

                dataItem.associatedProducts = associatedProducts;
                tmpData.splice(dataIdx, 1, dataItem);
                setData(tmpData)

                if(frmVisible && id===frmData.id)
                    closeForm()

                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const submitSaveGroupItem = () => {
        showloading()

        saveGroupAssociation(props.productId, frmGroupData)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Action submitted!')

                let tmpData = [...data];

                if(!frmGroupData.id){
                    tmpData.push(res);
                }else{
                    const dataIdx = tmpData.findIndex(e => e.id===res.id)
                    if(dataIdx!==-1)
                        tmpData.splice(dataIdx, 1, res)
                }

                setData(tmpData)
                // setGroupAssociations(tmpData.length>0?tmpData.map(e => ({value: e.id, label: e.name})):[])
                setGroupAssociations(tmpData?tmpData:[])

                closeForm();
            }else{
                showErrorNotify(res.errorMessage)
                if(res.errorCode===400)
                    setErrors(res.errorObj)
            }
        })
        .finally(stoploading)
    }

    const submitSaveAssociatedItem = () => {
        showloading()

        saveAssociatedProduct(props.productId, frmData)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Action submitted!')

                let tmpData = [...data];
                const dataIdx = tmpData.findIndex(e => e.id===frmData.groupId);
                let dataItem = tmpData[dataIdx]

                let associatedProducts = [...dataItem.associatedProducts];

                if(!frmData.id)
                    associatedProducts.push(res);
                else{
                    const associatedIdx = associatedProducts.findIndex(e => e.id===res.id);
                    if(associatedIdx!==-1)
                        associatedProducts.splice(associatedIdx, 1, res);
                }

                dataItem.associatedProducts = associatedProducts;
                tmpData.splice(dataIdx, 1, dataItem);
                setData(tmpData)
                closeForm();
            }else{
                showErrorNotify(res.errorMessage)
                if(res.errorCode===400)
                    setErrors(res.errorObj)
            }
        })
        .finally(stoploading)
    }

    const onChangeVariantPrice = (variantId, values) => {
        let tmpArr = []
        if(associatedProduct.classification===PRODUCT_CLASSIFICATION.variable.value){
            associatedProduct.variants&&associatedProduct.variants.map((v, i) => {
                if(v.id!==variantId){
                    let tmpPrices = frmData.prices.filter(p => p.variant===v.id)
                    tmpArr = [...tmpArr, ...tmpPrices]
                }
            })
        }
        tmpArr = [...tmpArr, ...values]
        setFrmData({...frmData, prices: tmpArr})
    }

    const tblHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                        <h3 className="fle-datatable-header-title">Associated Products</h3>
                    </div>;

    const rowAssociatedProductTemplate = (e) => {
        return(
            <DataTable value={e.associatedProducts} header="Associated Details" emptyMessage="No content available!"> 
                <Column header="Product" field="associatedProduct" sortable={true} body={(rowData) => rowData.associatedProduct.code + " - " + rowData.associatedProduct.name}/>
                <Column header="Method" field="included" body={(rowData) => rowData.included?'Included':'Optional'}/>
                <Column header="Quantity" field="quantity" body={(rowData) => (rowData.allowInputQty?'Max q.ty: ':'Q.ty included: ') + rowData.quantity}/>
                <Column header="" className="tc-actions" style={{textAlign: 'right', minWidth: '10%' }} 
                    body={(rowData) => {
                        return(
                            <React.Fragment>
                                <Button icon="pi pi-pencil" className="p-button-info btn-sm" onClick={() => addOrEditItem(DATA_TYPE.product, rowData)}/>
                                <Button icon="pi pi-times" className="p-button-danger btn-sm" onClick={() => deleteAssociatedItem(e.id, rowData.id)}/>
                            </React.Fragment>
                        )
                    }}
                />
            </DataTable>
        )
    }

    const renderOptions = (list) => {
        if(!list) return []
        return list.map(item => {return {value: item.id, label: item.name}})
    }

    const renderDependentProductOptionList = () => {
        let tmpArr = []
        for (const group of groupAssociations) {
            for (const assocationProduct of group.associatedProducts) {
                let tmpProduct = assocationProduct.associatedProduct
                if(tmpProduct.type===PRODUCT_TYPE.program.value){
                    let tmpItem = {value: tmpProduct.id, label: tmpProduct.name}
                    if(!tmpArr.includes(tmpItem)){
                        tmpArr.push(tmpItem)
                    }    
                }
            }
        }
        return tmpArr
    }

    const ASSOCIATED_TYPES = [PRODUCT_TYPE.program, PRODUCT_TYPE.rental]

    return (
        <div className="p-grid">
            {!frmVisible && 
            <div className="p-col-12">
                <Button label="Add associated product" icon="pi pi-plus" className="p-button-constrast" onClick={() => addOrEditItem(DATA_TYPE.product)}/>
                <Button label="Add associated group" icon="pi pi-plus" className="p-button-constrast" onClick={() => addOrEditItem(DATA_TYPE.group)}/>
            </div>
            }
            {frmVisible && 
            <div className="p-col-12">
                {dataType===DATA_TYPE.group &&
                <Fieldset legend="Associated group setup">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12 p-md-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label className="p-label">Group name</label>
                                    <InputText value={frmGroupData.name} onChange={(e) => setFrmGroupData({...frmGroupData, name: e.target.value})}/>
                                    <div className="p-form-error">{errors.name}</div>
                                </div>
                                <div className="p-col-12">
                                    <Checkbox inputId="cbMandatory" onChange={(e) => setFrmGroupData({...frmGroupData, mandatory: e.checked})} checked={frmGroupData.mandatory}></Checkbox>
                                    <label htmlFor="cbMandatory" className="p-checkbox-label p-margin-right-15">Mandatory product?</label>
                                </div>
                                <div className="p-col-12">
                                    <Checkbox inputId="cbMultiSelect" onChange={(e) => setFrmGroupData({...frmGroupData, multiSelect: e.checked})} checked={frmGroupData.multiSelect}></Checkbox>
                                    <label htmlFor="cbMultiSelect" className="p-checkbox-label p-margin-right-15">Allow multi selection?</label>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Weight (for order)</label>
                                    <InputText value={frmGroupData.weight} keyfilter="int" onChange={(e) => setFrmGroupData({...frmGroupData, weight: e.target.value})} />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-grid">
                                {frmGroupData.id ? 
                                    <div className="p-col-12">
                                        <label className="p-label">Group Type: {PRODUCT_TYPE[frmGroupData.type]&&PRODUCT_TYPE[frmGroupData.type].label}</label>
                                    </div>
                                    :
                                    <div className="p-col-12">
                                        <label className="p-label">Group Type</label>
                                        <Dropdown value={frmGroupData.type} options={ASSOCIATED_TYPES} onChange={(e) => setFrmGroupData({...frmGroupData, type: e.value})}/>
                                        <div className="p-form-error">{errors.type}</div>        
                                    </div>
                                }
                                
                                {frmGroupData.type === PRODUCT_TYPE.rental.value &&
                                    <div className="p-col-12">
                                        <label className="p-label-inline">Slot requirement:</label>
                                        <RadioButton inputId="rbSlotType_0" value={SLOT_REQUIREMENT_TYPE.full.value} className="p-margin-left-20" onChange={(e) => setFrmGroupData({...frmGroupData, slotType: e.value})} checked={frmGroupData.slotType === SLOT_REQUIREMENT_TYPE.full.value} />
                                        <label htmlFor="rbSlotType_0" className="p-radiobutton-label">{SLOT_REQUIREMENT_TYPE.full.label}</label>
                                        <RadioButton inputId="rbSlotType_1" value={SLOT_REQUIREMENT_TYPE.particle.value} className="p-margin-left-20" onChange={(e) => setFrmGroupData({...frmGroupData, slotType: e.value})} checked={frmGroupData.slotType === SLOT_REQUIREMENT_TYPE.particle.value} />
                                        <label htmlFor="rbSlotType_1" className="p-radiobutton-label">{SLOT_REQUIREMENT_TYPE.particle.label}</label>
                                        <div className="p-form-error">{errors.slotType}</div>        
                                    </div>
                                }
                            </div>
                        </div>
                        
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button label="Submit" className="btn-text-sm p-button-success" onClick={() => submitSaveGroupItem()}/>
                            <Button label="Cancel" className="btn-text-sm p-button-default" onClick={() => closeForm()}/>
                        </div>
                    </div>
                </Fieldset>
                }
                {dataType===DATA_TYPE.product && 
                <Fieldset legend="Associated product setup">
                    <div className="p-grid p-fluid form-group">
                        {frmData.id ?
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-display-block p-margin-bottom-10">In Group: <strong>{groupAssociation.name}</strong></label>
                                    <label>Associated Product: <strong>{frmData.product.code} - {frmData.product.name}</strong></label>
                                </div>
                                {props.classification===PRODUCT_CLASSIFICATION.combo.value && groupAssociation && groupAssociation.type===PRODUCT_TYPE.rental.value && 
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Dependence Product</label>
                                        <Dropdown value={frmData.dependenceProductId} options={renderDependentProductOptionList()} filter={true} onChange={(e) => setFrmData({...frmData, dependenceProductId: e.value})} placeholder="Select product" />
                                        <div className="p-form-error">{errors.dependenceProduct}</div>
                                    </div>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-col-12 p-md-4">
                                    <label className="p-label">* In group</label>
                                    <Dropdown value={frmData.groupId} options={renderOptions(groupAssociations)} onChange={(e) => setFrmData({...frmData, groupId: e.value})} placeholder="Select a group" />
                                    <div className="p-form-error">{errors.groupId}</div>
                                </div>
                                <div className="p-col-12 p-md-8">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <label className="p-label">* Associcated Product</label>
                                            <Dropdown value={frmData.associatedId} options={renderOptions(availableProducts)} filter={true} onChange={(e) => setFrmData({...frmData, associatedId: e.value})} placeholder="Select product" />
                                            <div className="p-form-error">{errors.associatedProduct}</div>
                                        </div>
                                        {props.classification===PRODUCT_CLASSIFICATION.combo.value && groupAssociation && groupAssociation.type===PRODUCT_TYPE.rental.value && 
                                            <div className="p-col-12">
                                                <label className="p-label">* Dependence Product</label>
                                                <Dropdown value={frmData.dependenceProductId} options={renderDependentProductOptionList()} filter={true} onChange={(e) => setFrmData({...frmData, dependenceProductId: e.value})} placeholder="Select product" />
                                                <div className="p-form-error">{errors.dependenceProduct}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Fieldset legend="Configuration">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <label className="p-label-inline p-margin-bottom-5">Associated method:</label>
                                        <RadioButton inputId="rbAssociationMethod0" name="associationMethod" className="p-margin-left-20" value={true} onChange={(e) => setFrmData({...frmData, included: e.value})} checked={Boolean(frmData.included)===true} />
                                        <label htmlFor="rbAssociationMethod0" className="p-margin-right-15">Included</label>
                                        <RadioButton inputId="rbAssociationMethod1" name="associationMethod" value={false} onChange={(e) => setFrmData({...frmData, included: e.value})} checked={Boolean(frmData.included)===false} />
                                        <label htmlFor="rbAssociationMethod1" className="p-margin-right-15">Optional</label>
                                    </div>
                                    {!frmData.included && 
                                    <React.Fragment>
                                        {frmData.type===PRODUCT_TYPE.addon.value && 
                                        <div className="p-col-12">
                                            <InputSwitch checked={frmData.displayQtyAvailable} onChange={(e) => setFrmData({...frmData, displayQtyAvailable: e.value})}/>
                                            <label className="p-margin-left-15"> Display quantity available</label>
                                        </div>
                                        }
                                        <div className="p-col-12">
                                            <label className="p-label-inline p-margin-bottom-5">Purchase Q.ty Method:</label>
                                            <RadioButton inputId="rbAssociationQtyMethod0" name="associationQtyMethod" className="p-margin-left-20" value={false} onChange={(e) => setFrmData({...frmData, allowInputQty: e.value})} checked={frmData.allowInputQty==false} />
                                            <label htmlFor="rbAssociationQtyMethod0" className="p-margin-right-15">Fixed</label>
                                            <RadioButton inputId="rbAssociationQtyMethod1" name="associationQtyMethod" value={true} onChange={(e) => setFrmData({...frmData, allowInputQty: e.value})} checked={frmData.allowInputQty==true} />
                                            <label htmlFor="rbAssociationQtyMethod1" className="p-margin-right-15">Allow input</label>
                                        </div>
                                    </React.Fragment>
                                    }
                                    <div className="p-col-12">
                                        <label className="p-label">{!frmData.included && frmData.allowInputQty?'Max Q.ty can purchase (leave 0 as unlimit)':'Included Q.ty'}</label>
                                        <Spinner value={frmData.quantity} min={0} onChange={(e) => setFrmData({...frmData, quantity: e.target.value})}/>
                                        <span className="p-form-error">{errors.quantity}</span>
                                    </div>
                                    <div className="p-col-6">
                                        <label className="p-label">Weight (for order)</label>
                                        <InputText value={frmData.weight} keyfilter="int" onChange={(e) => setFrmData({...frmData, weight: e.target.value})} />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <Fieldset legend="Unit price apply">
                                {groupAssociation.type===PRODUCT_TYPE.rental.value && 
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12">
                                        <label className="p-label">Rental unit apply:</label>
                                        {Object.values(PRODUCT_PRICE_UNIT).map((e,i) => {
                                            return (
                                                <React.Fragment>
                                                    <RadioButton inputId={"rbRentalUnit"+i} name="rentalUnitApply" value={e.value} onChange={(e) => setFrmData({...frmData, rentalUnitApply: e.value})} checked={frmData.rentalUnitApply===e.value} />
                                                    <label htmlFor={"rbRentalUnit"+i} className="p-margin-right-15">{e.label}</label>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                                }
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <InputSwitch checked={frmData.free} onChange={(e) => setFrmData({...frmData, free: e.value })} />
                                        <label className="p-margin-left-10">Free?</label>
                                    </div>
                                    {!frmData.free &&
                                        <div className="p-col-12">
                                            <Checkbox inputId="ckPriceOverride" checked={frmData.priceOverride} onChange={e => setFrmData({...frmData, priceOverride: e.checked})} />
                                            <label htmlFor="ckPriceOverride">Price Override?</label>
                                        </div>
                                        // <div className="p-col-12">
                                        //     <span style={{color: 'red', fontSize: '12px', fontStyle: 'Italic'}}>Note: Don't set price if you want to use original price of product</span>
                                        // </div>
                                    }
                                </div>
                                {!frmData.free && frmData.priceOverride &&
                                    <div className="p-grid">
                                        {(()=>{
                                            if(associatedProduct.classification===PRODUCT_CLASSIFICATION.variable.value){
                                                if(!associatedProduct.free){
                                                    if(associatedProduct.pricedByVariant){
                                                        return associatedProduct.variants&&associatedProduct.variants.map((v, i) => {
                                                            let tmpPrices = frmData.prices.filter(p => p.variant===v.id)
                                                            if(tmpPrices.length>0){
                                                                return <div key={i} className="p-col-12">
                                                                    <Fieldset legend={v.variantName}>
                                                                        <ProductPrices value={tmpPrices} type={associatedProduct.type} optional={false} errors={errors.prices?errors.prices:{}} onChange={(e) => onChangeVariantPrice(v.id, e)}/>
                                                                    </Fieldset>
                                                                </div>    
                                                            }
                                                        })
                                                    }else{
                                                        return <div className="p-col-12">
                                                            <ProductPrices value={frmData.prices} type={associatedProduct.type} optional={false} errors={errors.prices?errors.prices:{}} onChange={(e) => setFrmData({...frmData, prices: e})}/>
                                                        </div>
                                                    }
                                                }
                                            }else{
                                                return <div className="p-col-12">
                                                    <ProductPrices value={frmData.prices} type={associatedProduct.type} optional={false} errors={errors.prices?errors.prices:{}} onChange={(e) => setFrmData({...frmData, prices: e})}/>
                                                </div>
                                            }
                                        })()}
                                    </div>
                                }
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button label="Submit" className="btn-text-sm p-button-success" onClick={() => submitSaveAssociatedItem()}/>
                            <Button label="Cancel" className="btn-text-sm p-button-default" onClick={() => closeForm()}/>
                        </div>
                    </div>
                </Fieldset>
                }
            </div>
            }
            <div className="p-col-12">
                <DataTable value={data} responsive={true}
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowAssociatedProductTemplate} header={tblHeader}
                    emptyMessage="No content available!"
                >
                    <Column expander style={{ width: '3.5em' }} />
                    <Column field="name" header="Group Title" />
                    <Column header="Type" field="type" sortable={true} body={(rowData) => PRODUCT_TYPE[rowData.type]&&PRODUCT_TYPE[rowData.type].label}/>
                    <Column field="mandatory" header="Mandatory" body={(rowData) => rowData.mandatory?"Yes":"No"}/>
                    <Column field="multiSelect" header="Multi Selection" body={(rowData) => rowData.multiSelect?"Yes":"No"}/>
                    <Column field="weight" header="Weight" sortable={true}/>
                    <Column header="" className="tc-actions" style={{textAlign: 'center', width: '15%' }} 
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteGroupItem(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => addOrEditItem(DATA_TYPE.group, rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}