import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import React, { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from "react-router-dom";
import { APP_FEP, PRODUCT_CLASSIFICATION, PRODUCT_TYPE, TAXONOMY_TYPE } from "../../constants";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { getListDepartments } from "../../scp/departments/DepartmentServices";
import { getListTaxons } from "../taxon/TaxonServices";
import { ProductForm } from "./ProductForm";
import { getPageProducts, removeProduct } from "./ProductServices";

export const ProductListView = () => {
    const match = useRouteMatch();
    const frmProduct = useRef(null);
    const [filter, setFilter] = useState({app: APP_FEP})
    const [data, setData] = useState([])
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })
    const [isLoadTable, setLoadTable] = useState(true);
    const [branches, setBranches] = useState([])
    const [departments, setDeparments] = useState([])
    const [categories, setCategories] = useState([])
    const [locations, setLocations] = useState([])

    useEffect(()=>{
        loadAllBranchs()
        loadAllDepartments()
        loadAllLocations()
    },[])

    useEffect(() => {
        if(isLoadTable)
            loadPageProducts();
    }, [isLoadTable])

    useEffect(() => {
        setFilter({...filter, categoryIds: []})
        loadAllCategories(filter.departmentIds?filter.departmentIds[0]:null);
    }, [filter.departmentIds])

    const loadPageProducts = () =>{
        showloading()
        getPageProducts(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setData(res.content)
                setPageable({...pageable,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false)
            }
        })
        .finally(stoploading())
    }

    const onPage = (e) =>{
        setPageable({
            ...pageable,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const loadAllBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}).then(res => setBranches(res))
    }

    const loadAllDepartments = () => {
        getListDepartments({externalRef: false}).then(res => setDeparments(res))
    }

    const loadAllCategories = (deptId) => {
        if(!deptId){
            setCategories([])
            return;
        }

        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [deptId]}).then(res => setCategories(res))
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true)
    };

    const loadAllLocations = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.location.value}).then(res => setLocations(res))
    }

    const deleteProduct = (id) => {
        showConfirmNotify({
            message: 'Are you sure to remove this product? This action can not be undo.',
            accept: () => handleRemoveProduct(id)
        })
    }

    const handleRemoveProduct = (id) => {
        removeProduct(id)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorCode!==409?res.errorMessage:"Can't remove because it's used in another product!")
            }
        })
    }

    const applyFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setLoadTable(true)
    }

    const resetFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({app:APP_FEP, searchValue: ''})
        setLoadTable(true)
    }

    const createProductItems = [
        { label: 'Create Variable Product', icon: '', command: (e) => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.variable.value)},
        { label: 'Create Combo Product', icon: '', command: (e) => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.combo.value)},
    ]

    const tblHeader = (
        <TableHeader title="Products"
            actionTemplate={<SplitButton label="Create Simple Product"  model={createProductItems} icon="pi pi-plus" className="p-button-constrast p-float-left p-menu-width-auto" onClick={() => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.simple.value)}></SplitButton>}
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
        />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Products</BreadcrumbsItem>

            <ProductForm ref={frmProduct}
                refreshTable={() => setLoadTable(true)}
            />

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold">Products</h1>
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Name:</span>
                                            <InputText value={filter.searchValue} onChange={e => setFilter({...filter, searchValue: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Department:</span>
                                    <Dropdown value={filter.departmentIds&&filter.departmentIds.length>0?filter.departmentIds[0]:null} options={departments} showClear onChange={e => setFilter({...filter, departmentIds: e.value?[e.value]:[]})} />
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Category:</span>
                                    <Dropdown value={filter.categoryIds&&filter.categoryIds.length>0?filter.categoryIds[0]:null} options={categories} showClear onChange={e => setFilter({...filter, categoryIds: e.value?[e.value]:[]})} />
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Location:</span>
                                    <Dropdown value={filter.locationIds&&filter.locationIds.length>0?filter.locationIds[0]:null} options={locations} showClear onChange={e => setFilter({...filter, locationIds: e.value?[e.value]:[]})} />
                                </div>
                            </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Classification:</span>
                                            <Dropdown value={filter.classifications&&filter.classifications.length>0?filter.classifications[0]:null} options={Object.values(PRODUCT_CLASSIFICATION)} showClear onChange={e => setFilter({...filter, classifications: e.value?[e.value]:[]})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Type:</span>
                                            <Dropdown value={filter.types&&filter.types.length>0?filter.types[0]:null} options={Object.values(PRODUCT_TYPE)} showClear onChange={e => setFilter({...filter, types: e.value?[e.value]:[]})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Branch:</span>
                                            <Dropdown value={filter.branchIds&&filter.branchIds.length>0?filter.branchIds[0]:null} options={branches} showClear onChange={e => setFilter({...filter, branchIds: e.value?[e.value]:[]})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={() => resetFilter()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <DataTable emptyMessage="There's no product found" lazy={true} paginator={true} value={data} loading={isLoadTable}
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column header="Code" field="code" sortable={true}/>
                    <Column header="Name" field="name" sortable={true} />
                    <Column header="Class" field="classification" sortable={true} body={(rowData) => PRODUCT_CLASSIFICATION[rowData.classification] && PRODUCT_CLASSIFICATION[rowData.classification].label}/>
                    <Column header="Type" field="type" sortable={true} body={(rowData) => rowData.type && PRODUCT_TYPE[rowData.type] && PRODUCT_TYPE[rowData.type].label}/>
                    <Column header="Show In Store" field="show" body={(rowData) => rowData.active ? 'Yes' : 'No'}/>
                    {/* <Column header="Active" field="active" body={(rowData) => rowData.active ? 'Yes' : 'No'}/> */}
                    <Column header="" className="tc-actions" style={{textAlign: 'center', width: '10%' }} 
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteProduct(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit"  model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmProduct.current.openForm(rowData.classification, rowData.id)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    );
}