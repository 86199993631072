import React, { Component } from "react";
import { ProgramServices } from "./ProgramServices";
import moment from 'moment';
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { TimeRangeForm } from "./TimeRangeForm";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { showNotification } from '../../core/service/NotificationService';
import { renderErrors } from '../../core/service/ErrorService';
import { FieldWrapper } from "../../core/components/FieldWrapper";
import { DataTable } from "primereact/datatable";
import { SimpleEventServices } from "../../scp/event-management/SimpleEventService";
import { Column } from "primereact/column";
import SimpleEventScheduleForm from '../../scp/event-management/SimpleEventScheduleForm';

const highlightChanged = (a, b) => {
    const data = new Date(a);
    const termData = new Date(b);
    return data.getTime() !== termData.getTime() ? " input-highlight" : "";
}

export class ScheduleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                ...this.props.data,
                schedules: this.props.data.schedules ?
                    this.props.data.schedules
                    : {
                        mon: {},
                        tue: {},
                        wed: {},
                        thu: {},
                        fri: {},
                        sat: {},
                        sun: {},
                    }
            },
            termData: {
                ...this.props.data,
                schedules: this.props.data.schedules ?
                    this.props.data.schedules
                    : {
                        mon: {},
                        tue: {},
                        wed: {},
                        thu: {},
                        fri: {},
                        sat: {},
                        sun: {},
                    }
            },
            dayOfWeek: "",
            timeInDaySchedule: {
                s: "",
                e: "",
                onUpdate: false
            },
            frmTimeRange: {
                formVisible: false
            },
            errors: {},
            isDirty: false,
            viewMode: true,
            dicardVisible: false,
            eventList: [],
            page: 0,
            rows: 5,
            BusyRows:5,
            total: 0,
            eventBusyList: [],
            BusyPage: 0,
            BusyTotal: 0,
            formHeader: "Add Event",
            formVisible: false,
        }
        this.programServices = new ProgramServices();
        this.simpleEventService = new SimpleEventServices();
        this.baseState = this.state;
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.data).length!==0){
    //         if(JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)){
    //             if(JSON.stringify(this.state.data) !== JSON.stringify(this.state.termData)){
    //                 this.props.formEvent.emit('ENTER_EDIT_MODE')
    //             }else{
    //                 this.props.updating(false);
    //             }
    //         }
    //     }
    // }

    componentDidMount = () => {
        this.getListEvent();
        this.getListBusyEvent();
    }

    reloadEvent = ( )=>{
        this.getListEvent();
        this.getListBusyEvent();
    }

    getListEvent = () => {
        this.simpleEventService.getPageSimpleEventsOnCourse( this.props.data.id, false, this.state.page)
            .then(res => {
                this.setState({
                    eventList: res.content,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize,
                    total: res.totalElements,
                })
            })
    }

    getListBusyEvent = () => {
        this.simpleEventService.getPageSimpleEventsOnCourse(this.props.data.id, true, this.state.BusyPage)
            .then(res => {
                this.setState({
                    eventBusyList: res.content,
                    BusyPage: res.pageable.pageNumber,
                    BusyRows: res.pageable.pageSize,
                    BusyTotal: res.totalElements,
                })
            })
    }

    actionTemplate = (rowData) =>{
        //askjdhasiudghasiuhda
        return(
            <div className="p-col-12">
                <Button type="button" className="p-button-warning" label="Edit Event" onClick={() => {this.setState({ eventId: rowData.id, formVisible: true, formHeader: "Edit Event"  })}} />
            </div>
        )
    }

    onPage = (e) => {
        this.setState({
            page: e.page
        }, () => {
            this.getListEvent();
        });
    }

    onBusyPage = (e) => {
        this.setState({
            BusyPage: e.page
        }, () => {
            this.getListBusyEvent();
        });
    }

    handleSaveDaysSchedule = () => {
        this.programServices.saveScheduleSettings(this.state.data)
            .then(res => {
                if (!res.errorCode) {
                    this.props.refreshData(res);
                    showNotification('success', 'Success Message', 'Action Submitted');
                    this.setState({
                        errors: {},
                        termData: this.props.data
                    });
                    this.props.formEvent.emit('CHANGE_SAVED');
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                    if (res.errorCode === 400) this.setState({ errors: res.errorObj })
                }
            });

    }

    onCheckWeekDayChange = (day, e) => {
        this.setState({
            data: {
                ...this.state.data, schedules: {
                    ...this.state.data.schedules, [day]: {
                        ...this.state.data.schedules[day], use: e.checked
                    }
                }
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onStartDateChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                startDate: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
                startDateValue: e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onEndDateChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                endDate: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
                endDateValue: e.value
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onStartTimeChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                startTime: moment(e.value).format(moment.HTML5_FMT.TIME),
                startTimeValue: e.value,
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onEndTimeChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                endTime: moment(e.value).format(moment.HTML5_FMT.TIME),
                endTimeValue: e.value,
            }
        });
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onAddTime = (day) => {
        let lastTime = Object.keys(this.state.data.schedules).length !== 0 &&
            this.state.data.schedules[day] && this.state.data.schedules[day].times ?
            (this.state.data.schedules[day].times.length > 0 ? this.state.data.schedules[day].times[this.state.data.schedules[day].times.length - 1]
                : null)
            : null;
        if (lastTime !== null)
            this.popularFormRangeTime(null, null, lastTime.e);
        else
            this.popularFormRangeTime();
        this.setState({
            isDirty: true,
            dayOfWeek: day,
            frmTimeRange: {
                ...this.state.frmTimeRange,
                formVisible: true
            }
        })
    }

    resetChange = (keyField) => {
        let keyFieldLabel = keyField.replace("Value", "");
        this.setState({
            data: {
                ...this.state.data,
                [keyFieldLabel]: this.state.termData[keyFieldLabel],
                [keyField]: this.state.termData[keyField]
            }
        })
    }

    onEditTimeSchedule = (day, timeItem) => {
        let lstTimes = this.state.data.schedules[day].times;
        let indexTime = lstTimes.indexOf(timeItem);
        let time = {
            ...timeItem,
            onUpdate: true
        }
        lstTimes.splice(indexTime, 1, time)
        this.setState({
            dayOfWeek: day,
            data: {
                ...this.state.data, schedules: {
                    ...this.state.data.schedules, [day]: {
                        ...this.state.data.schedules[day], times: lstTimes
                    }
                }
            },
            frmTimeRange: {
                ...this.state.frmTimeRange,
                formVisible: true
            }
        })
        if (timeItem) {
            if (indexTime !== -1) {
                if (indexTime > 0 && indexTime < (lstTimes.length - 1)) {
                    this.popularFormRangeTime(timeItem.s, timeItem.e, lstTimes[indexTime - 1].e, lstTimes[indexTime + 1].s);
                } else if (indexTime === 0) {
                    if (lstTimes.length > 1)
                        this.popularFormRangeTime(timeItem.s, timeItem.e, null, lstTimes[indexTime + 1].s);
                    else
                        this.popularFormRangeTime(timeItem.s, timeItem.e);
                } else if (indexTime === (lstTimes.length - 1)) {
                    this.popularFormRangeTime(timeItem.s, timeItem.e, lstTimes[indexTime - 1].e);
                }
            } else
                this.popularFormRangeTime(timeItem.s, timeItem.e);
        }
        else
            this.popularFormRangeTime();
    }

    popularFormRangeTime = (startTime, endTime, beforeTime, afterTime) => {
        this.frmTimeRange.popularTimeForm(startTime, endTime, beforeTime, afterTime);

    }

    removeTimeSchedule = (day, timeItem) => {
        let lstTimes = Object.keys(this.state.data.schedules).length !== 0 ? [...this.state.data.schedules[day].times] : []
        lstTimes.splice(lstTimes.indexOf(timeItem), 1);
        this.setState({
            data: {
                ...this.state.data, schedules: {
                    ...this.state.data.schedules, [day]: {
                        ...this.state.data.schedules[day], times: lstTimes
                    }
                }
            }
        })
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    onSubmitFormRangeTime = (timeItem) => {
        let day = this.state.dayOfWeek;
        let lstTimes = Object.keys(this.state.data.schedules).length !== 0 &&
            this.state.data.schedules[day] && this.state.data.schedules[day].times ?
            [...this.state.data.schedules[day].times] : [];

        let indexTimeUpdate = lstTimes.indexOf(lstTimes.find(x => x.onUpdate));
        let time = { s: timeItem.start, e: timeItem.end }
        if (indexTimeUpdate !== -1)
            lstTimes.splice(lstTimes.indexOf(lstTimes.find(x => x.onUpdate)), 1, time);
        else
            lstTimes.push(time);
        this.setState({
            dayOfWeek: '',
            data: {
                ...this.state.data, schedules: {
                    ...this.state.data.schedules, [day]: {
                        ...this.state.data.schedules[day], times: lstTimes
                    }
                }
            },
            frmTimeRange: {
                ...this.state.frmTimeRange,
                formVisible: false
            }
        })
        this.props.formEvent.emit('ENTER_EDIT_MODE')
    }

    hideTimeFormDialog = () => {
        // reset time edit if not submit
        Object.values(this.state.data.schedules).forEach(value => {
            let arrTimes = value.times
            if (value.times !== undefined) {
                value.times.forEach(item => {
                    if (item.onUpdate !== undefined && item.onUpdate) {
                        value.times.splice(arrTimes.indexOf(item), 1, { s: item.s, e: item.e })
                    }
                })
            }
        })

        this.setState({
            frmTimeRange: { ...this.state.frmTimeRange, formVisible: false }
        })
    }

    onChangeDicarded = () => {
        this.setState(this.baseState);
    }

    onEdit = () => {
        this.props.acquireFormLock();
        this.setState({
            isDirty: true
        })
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Fieldset legend="Date Schedule ">
                        {!this.props.viewMode ?
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="startDateValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.startDateValue, this.state.termData.startDateValue)}>
                                        <label>Start Date</label>
                                        {/* <Calendar dateFormat="mm-dd-yy" value={this.state.data.startDateValue} onChange={this.onStartDateChange} showIcon={true}/> */}
                                        <MaskedCalendar value={this.state.data.startDateValue} showIcon={true} onChange={this.onStartDateChange} dateFormat="mm-dd-yy" />
                                        <span className="p-form-error">{renderErrors(this.state.errors.startDate)}</span>
                                    </FieldWrapper>
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="endDateValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.endDateValue, this.state.termData.endDateValue)}>
                                        <label>End Date</label>
                                        <MaskedCalendar value={this.state.data.endDateValue} showIcon={true} onChange={this.onEndDateChange} dateFormat="mm-dd-yy" />
                                        <span className="p-form-error">{renderErrors(this.state.errors.endDate)}</span>
                                        {/* <Calendar dateFormat="mm-dd-yy" value={this.state.data.endDateValue} onChange={this.onEndDateChange} showIcon={true}/> */}
                                    </FieldWrapper>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="startDateValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.startDateValue, this.state.termData.startDateValue)}>
                                        <label>Start Date</label>
                                        {/* <Calendar dateFormat="mm-dd-yy" value={this.state.data.startDateValue} onChange={this.onStartDateChange} showIcon={true}/> */}
                                        <div>{moment(this.state.data.startDateValue).format('MM-DD-YYYY')}</div>
                                    </FieldWrapper>
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="endDateValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.endDateValue, this.state.termData.endDateValue)}>
                                        <label>End Date</label>
                                        <div>{moment(this.state.data.endDateValue).format('MM-DD-YYYY')}</div>
                                        {/* <Calendar dateFormat="mm-dd-yy" value={this.state.data.endDateValue} onChange={this.onEndDateChange} showIcon={true}/> */}
                                    </FieldWrapper>
                                </div>
                            </React.Fragment>
                        }
                    </Fieldset>
                </div>
                <div className="p-col-12 p-md-6">
                    <Fieldset legend="Time Schedule">
                        {!this.props.viewMode ?
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="startTimeValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.startTimeValue, this.state.termData.startTimeValue)}>
                                        <label>Start Time</label>
                                        <MaskedCalendar value={this.state.data.startTimeValue} showIcon={true} onChange={this.onStartTimeChange} showTime={true} hourFormat="12" timeOnly={true} />
                                        <span className="p-form-error">{renderErrors(this.state.errors.startTime)}</span>
                                        {/* <Calendar hourFormat="12" timeOnly={true} value={this.state.data.startTimeValue} onChange={this.onStartTimeChange}/> */}
                                    </FieldWrapper>
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="endTimeValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.endTimeValue, this.state.termData.endTimeValue)}>
                                        <label>End Time</label>
                                        <MaskedCalendar value={this.state.data.endTimeValue} showIcon={true} onChange={this.onEndTimeChange} showTime={true} hourFormat="12" timeOnly={true} />
                                        <span className="p-form-error">{renderErrors(this.state.errors.endTime)}</span>
                                        {/* <Calendar hourFormat="12" timeOnly={true} value={this.state.data.endTimeValue} onChange={this.onEndTimeChange}/> */}
                                    </FieldWrapper>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="startTimeValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.startTimeValue, this.state.termData.startTimeValue)}>
                                        <label>Start Time</label>
                                        <div>{moment(this.state.data.startTimeValue).format('hh:mm A')}</div>
                                        {/* <Calendar hourFormat="12" timeOnly={true} value={this.state.data.startTimeValue} onChange={this.onStartTimeChange}/> */}
                                    </FieldWrapper>
                                    <FieldWrapper col="p-col-12 p-md-6" keyField="endTimeValue" resetChange={this.resetChange} highlight={highlightChanged(this.state.data.endTimeValue, this.state.termData.endTimeValue)}>
                                        <label>End Time</label>
                                        <div>{moment(this.state.data.endTimeValue).format('hh:mm A')}</div>
                                        {/* <Calendar hourFormat="12" timeOnly={true} value={this.state.data.endTimeValue} onChange={this.onEndTimeChange}/> */}

                                    </FieldWrapper>
                                </div>
                            </React.Fragment>
                        }

                    </Fieldset>
                </div>
                <div className="p-col-12 dashboard">
                    <TimeRangeForm ref={(el) => this.frmTimeRange = el}
                        visible={this.state.frmTimeRange.formVisible}
                        hideFormDialog={() => this.hideTimeFormDialog()}
                        submitFormDialog={(e) => this.onSubmitFormRangeTime(e)}
                    />
                    <Fieldset legend="Days of Week">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <span className="p-form-error">{renderErrors(this.state.errors.weekTimes)}</span>
                            </div>
                            <div className="p-col-12">
                                <table className="custom" cellSpacing="0" style={{ width: "100%" }}>
                                    {!this.props.viewMode ?
                                        <React.Fragment>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbMon" onChange={(e) => this.onCheckWeekDayChange('mon', e)} checked={this.state.data.schedules && this.state.data.schedules.mon ? this.state.data.schedules.mon.use : false}></Checkbox>
                                                        <label htmlFor="cbMon" className="p-checkbox-label">Monday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("mon")} disabled={this.state.data.schedules && this.state.data.schedules.mon ? (this.state.data.schedules.mon.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbTue" onChange={(e) => this.onCheckWeekDayChange('tue', e)} checked={this.state.data.schedules && this.state.data.schedules.tue ? this.state.data.schedules.tue.use : false}></Checkbox>
                                                        <label htmlFor="cbTue" className="p-checkbox-label">Tueday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("tue")} disabled={this.state.data.schedules && this.state.data.schedules.tue ? (this.state.data.schedules.tue.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbWed" onChange={(e) => this.onCheckWeekDayChange('wed', e)} checked={this.state.data.schedules && this.state.data.schedules.wed ? this.state.data.schedules.wed.use : false} ></Checkbox>
                                                        <label htmlFor="cbWed" className="p-checkbox-label">Wednesday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("wed")} disabled={this.state.data.schedules && this.state.data.schedules.wed ? (this.state.data.schedules.wed.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbThu" onChange={(e) => this.onCheckWeekDayChange('thu', e)} checked={this.state.data.schedules && this.state.data.schedules.thu ? this.state.data.schedules.thu.use : false} ></Checkbox>
                                                        <label htmlFor="cbThu" className="p-checkbox-label">Thursday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("thu")} disabled={this.state.data.schedules && this.state.data.schedules.thu ? (this.state.data.schedules.thu.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbFri" onChange={(e) => this.onCheckWeekDayChange('fri', e)} checked={this.state.data.schedules && this.state.data.schedules.fri ? this.state.data.schedules.fri.use : false} ></Checkbox>
                                                        <label htmlFor="cbFri" className="p-checkbox-label">Friday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("fri")} disabled={this.state.data.schedules && this.state.data.schedules.fri ? (this.state.data.schedules.fri.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbSat" onChange={(e) => this.onCheckWeekDayChange('sat', e)} checked={this.state.data.schedules && this.state.data.schedules.sat ? this.state.data.schedules.sat.use : false} ></Checkbox>
                                                        <label htmlFor="cbSat" className="p-checkbox-label">Saturday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("sat")} disabled={this.state.data.schedules && this.state.data.schedules.sat ? (this.state.data.schedules.sat.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbSun" onChange={(e) => this.onCheckWeekDayChange('sun', e)} checked={this.state.data.schedules && this.state.data.schedules.sun ? this.state.data.schedules.sun.use : false} ></Checkbox>
                                                        <label htmlFor="cbSun" className="p-checkbox-label">Sunday</label>
                                                        <Button icon="pi pi-plus" onClick={() => this.onAddTime("sun")} disabled={this.state.data.schedules && this.state.data.schedules.sun ? (this.state.data.schedules.sun.use ? false : true) : true} style={{ float: "right", fontSize: "10px" }} />
                                                    </th>
                                                </tr>
                                            </thead>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbMon" onChange={(e) => this.onCheckWeekDayChange('mon', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.mon ? this.state.data.schedules.mon.use : false}></Checkbox>
                                                        <label htmlFor="cbMon" className="p-checkbox-label">Monday</label>
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbTue" onChange={(e) => this.onCheckWeekDayChange('tue', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.tue ? this.state.data.schedules.tue.use : false}></Checkbox>
                                                        <label htmlFor="cbTue" className="p-checkbox-label">Tueday</label>
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbWed" onChange={(e) => this.onCheckWeekDayChange('wed', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.wed ? this.state.data.schedules.wed.use : false} ></Checkbox>
                                                        <label htmlFor="cbWed" className="p-checkbox-label">Wednesday</label>
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbThu" onChange={(e) => this.onCheckWeekDayChange('thu', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.thu ? this.state.data.schedules.thu.use : false} ></Checkbox>
                                                        <label htmlFor="cbThu" className="p-checkbox-label">Thursday</label>
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbFri" onChange={(e) => this.onCheckWeekDayChange('fri', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.fri ? this.state.data.schedules.fri.use : false} ></Checkbox>
                                                        <label htmlFor="cbFri" className="p-checkbox-label">Friday</label>
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbSat" onChange={(e) => this.onCheckWeekDayChange('sat', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.sat ? this.state.data.schedules.sat.use : false} ></Checkbox>
                                                        <label htmlFor="cbSat" className="p-checkbox-label">Saturday</label>
                                                    </th>
                                                    <th style={{ textAlign: "center", border: "1px solid #c5bfbf", padding: "5px" }}>
                                                        <Checkbox inputId="cbSun" onChange={(e) => this.onCheckWeekDayChange('sun', e)} disabled={true} checked={this.state.data.schedules && this.state.data.schedules.sun ? this.state.data.schedules.sun.use : false} ></Checkbox>
                                                        <label htmlFor="cbSun" className="p-checkbox-label">Sunday</label>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </React.Fragment>
                                    }

                                    {!this.props.viewMode ?
                                        <React.Fragment>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }} >
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.mon && this.state.data.schedules.mon.times && this.state.data.schedules.mon.use ?
                                                                this.state.data.schedules.mon.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0" >
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("mon", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('mon', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.tue && this.state.data.schedules.tue.times && this.state.data.schedules.tue.use ?
                                                                this.state.data.schedules.tue.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("tue", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('tue', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.wed && this.state.data.schedules.wed.times && this.state.data.schedules.wed.use ?
                                                                this.state.data.schedules.wed.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("wed", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('wed', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.thu && this.state.data.schedules.thu.times && this.state.data.schedules.thu.use ?
                                                                this.state.data.schedules.thu.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("thu", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('thu', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.fri && this.state.data.schedules.fri.times && this.state.data.schedules.fri.use ?
                                                                this.state.data.schedules.fri.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("fri", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('fri', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.sat && this.state.data.schedules.sat.times && this.state.data.schedules.sat.use ?
                                                                this.state.data.schedules.sat.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("sat", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('sat', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.sun && this.state.data.schedules.sun.times && this.state.data.schedules.sun.use ?
                                                                this.state.data.schedules.sun.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-8">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                    <div className="p-col-4">
                                                                                        <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onEditTimeSchedule("sun", item)} style={{ fontSize: "10px" }} />
                                                                                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeTimeSchedule('sun', item)} style={{ fontSize: "10px" }} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }} >
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.mon && this.state.data.schedules.mon.times && this.state.data.schedules.mon.use ?
                                                                this.state.data.schedules.mon.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0" >
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.tue && this.state.data.schedules.tue.times && this.state.data.schedules.tue.use ?
                                                                this.state.data.schedules.tue.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.wed && this.state.data.schedules.wed.times && this.state.data.schedules.wed.use ?
                                                                this.state.data.schedules.wed.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.thu && this.state.data.schedules.thu.times && this.state.data.schedules.thu.use ?
                                                                this.state.data.schedules.thu.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.fri && this.state.data.schedules.fri.times && this.state.data.schedules.fri.use ?
                                                                this.state.data.schedules.fri.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.sat && this.state.data.schedules.sat.times && this.state.data.schedules.sat.use ?
                                                                this.state.data.schedules.sat.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                    <td style={{ width: "calc(100%/7)", textAlign: "center", border: "1px solid #c5bfbf", verticalAlign: "initial" }}>
                                                        <ul style={{ listStyle: "none", padding: 0 }}>
                                                            {this.state.data.schedules && this.state.data.schedules.sun && this.state.data.schedules.sun.times && this.state.data.schedules.sun.use ?
                                                                this.state.data.schedules.sun.times.map((item, idx) => {
                                                                    return (
                                                                        <li key={idx} className="p-margin-10-0">
                                                                            <div className="p-grid">
                                                                                <React.Fragment>
                                                                                    <div className="p-col-12">
                                                                                        <label style={{ display: "inline-block", paddingTop: 5 }}>{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</label>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ""
                                                            }
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </React.Fragment>
                                    }

                                </table>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                {this.state.data.id ?
                    <div className="p-col-12">
                        {!this.props.viewMode ?
                            <React.Fragment>
                                <Button label="Cancel Edit" icon="pi pi-lock" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.props.onCancelViewMode()} />
                                <Button label="Save Days Schedule" icon="pi pi-save" disabled={this.props.isDirty ? "" : "disabled"} iconPos="left" style={{ 'float': 'right' }} onClick={() => this.handleSaveDaysSchedule()} />
                            </React.Fragment>
                            :
                            <div>
                                {/* <Button label={this.props.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={this.props.acquireFormLock} /> */}
                                <Button label={this.props.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={this.onEdit} />
                            </div>
                        }
                    </div>
                    : ''
                }
                <div className="p-col-12 dashboard">
                    <Fieldset legend="Events">
                            <React.Fragment>
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <div className="p-col-2">
                                            <Button label="Add Events" className="p-button-success" onClick={() => {this.setState({ eventId: 0, formVisible: true, formHeader: "Add Event"  })}} />
                                        </div>
                                    </div>
                                    <div className="p-col-6">
                                        <DataTable header="Additional Meeting" first={this.state.page * this.state.rows}
                                            onPage={this.onPage}
                                            rows={this.state.rows} totalRecords={this.state.total} lazy={true} paginator={true} value={this.state.eventList} >
                                            <Column header="Title" body={rowData => rowData.title} />
                                            <Column header="Start" body={rowData => rowData.start ? moment(rowData.start).format('MM-DD-YYYY hh:mm A') : 'None '} />
                                            <Column header="End" body={rowData => rowData.end ? moment(rowData.end).format('MM-DD-YYYY hh:mm A') : 'None '}  />
                                            <Column header="Actions"  body={this.actionTemplate}/>
                                        </DataTable>
                                    </div>
                                    <div className="p-col-6">
                                        <DataTable header="Off Dates" first={this.state.BusyPage * this.state.rows}
                                            rows={this.state.rows} totalRecords={this.state.BusyTotal} lazy={true} paginator={true} value={this.state.eventBusyList} >
                                            <Column header="Title" body={rowData => rowData.title} />
                                            <Column header="Start" body={rowData => rowData.start ? moment(rowData.start).format('MM-DD-YYYY hh:mm A') : 'None '}/>
                                            <Column header="End" body={rowData => rowData.end? moment(rowData.end).format('MM-DD-YYYY hh:mm A') : 'None '} />
                                            <Column header="Actions"  body={this.actionTemplate}/>
                                        </DataTable>
                                    </div>
                                </div>
                            </React.Fragment> 
                    </Fieldset>
                </div>
                <div>
                    <SimpleEventScheduleForm 
                    courseId={this.props.data.id}
                    courseName = {this.props.data.name}
                    formHeader={this.state.formHeader}
                    visible={this.state.formVisible} 
                    hideForm={() => this.setState({ formVisible: false })}
                    eventId={this.state.eventId}
                    reloadTable={() => this.reloadEvent()}
                    onSuccess={() => showNotification('success', 'Success Message', 'Action submitted')}
                    onError={() => showNotification('error', 'Error Message', 'Cannot perform action')}
                    />
                </div>
            </div>
        );
    }
}