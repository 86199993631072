import React, { Component } from 'react';
import { Prompt } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}

export default class PreventTransitionPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            lastLocation: null,
            confirmedNavigation: false,
        };

    }

    showModal = (location) => this.setState({
        visible: true,
        lastLocation: location,
    })

    closeModal = (callback) => this.setState({
        visible: false
    }, callback)

    handleCancel = () => {
        this.props.handleDiscard();
        this.setState({
            visible: false
        })
    }

    handleBlockedNavigation = (nextLocation) => {
        const { confirmedNavigation } = this.state
        const { shouldBlockNavigation } = this.props
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        const { navigate } = this.props
        const { lastLocation } = this.state
        if (lastLocation) {
            this.props.handleSave();
            this.setState({
                confirmedNavigation: true
            }, () => {
                // Navigate to the previous blocked location with your navigate function     
                navigate(lastLocation.pathname)
            })
        }
    })

    handleDiscardChange = () => this.closeModal(() => {
        const { navigate } = this.props
        const { lastLocation } = this.state
        if (lastLocation) {
            this.props.handleDiscard();
            this.setState({
                confirmedNavigation: true
            }, () => {     
                navigate(lastLocation.pathname)
            })
        }
    })

    render() {
        const { when } = this.props;

        const footerChangeNavigation = (
            <div>
                <Button label="Save Changes" icon="pi pi-check" onClick={this.handleConfirmNavigationClick} />
                <Button label="Cancel" onClick={this.handleCancel} />
                {/* <Button label="Discard Changes" className="p-button-secondary" onClick={this.handleDiscardChange} /> */}
                {/* <Button label="Review Changes" className="p-button-secondary" onClick={this.onHide} /> */}
            </div>
        );

        return (
            <React.Fragment >
                <Prompt when={when} message={this.handleBlockedNavigation} />

                <Dialog footer={footerChangeNavigation} visible={this.state.visible} contentStyle={styles.dialogContent} responsive={true} modal={true} showHeader={false} dismissableMask={true} onHide={this.closeModal}>
                    Do you want to save the changes?
                </Dialog>
            </React.Fragment>
        );
    }

}