import moment from "moment";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import { Sidebar } from "primereact/sidebar";
import QRCode from 'qrcode.react';
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { APPLICATION_COMPONENTT_TYPE, APPLICATION_RECORD_STATUS, APPLICATION_TYPE } from "../../constants";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";
import { updateStatusApplicationRecord, viewLog } from "./ApplicationService";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { getAppConfig } from "../../core/service/CommonService";

export const ViewLogApplicationForm = forwardRef((props, ref) => {
    const config = getAppConfig();

    const [participantId, setParticipantId] = useState(null)
    const [assetScheduleId, setAssetScheduleId] = useState(null)
    const [dataList, setDataList] = useState([])
    const [selectedLogs, setSelectedLogs] = useState([])
    const [formHeader, setFormHeader] = useState('Logs')
    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, ()=>({
        openForm(partId, scheduleId){
            loadLogActivities(partId, scheduleId)
        }   
    }))

    const handleApproveOrReject = (id, status) =>{
        updateStatusApplicationRecord(id, status)
        .then(res=>{
            if(!res.errorCode){
                loadLogActivities(participantId, assetScheduleId)
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const refreshForm = () => {
        loadLogActivities(participantId, assetScheduleId)
    }

    const loadLogActivities = (partId, scheduleId) => {
        showloading()

        viewLog(partId, scheduleId)
        .then(res=>{
            if(!res.errorCode){
                setParticipantId(partId)
                setAssetScheduleId(scheduleId)
                setDataList(res?res:[])
                setSelectedLogs([])
                setVisible(true)
            }else{
                showErrorNotify(res.errorMessage)
            }
            stoploading()
        })
    }

    const onCreateLog = (logId) => {
        let tmpArr = [...selectedLogs]
        if(tmpArr.includes(logId)){
            tmpArr.splice(tmpArr.indexOf(logId), 1)
        }else{
            tmpArr.push(logId)
        }
        setSelectedLogs(tmpArr)
    }

    const renderQRCodeUrl = (appAlias, participantId, assetScheduleId) => {
        if(config && config.scpFEDomain){            
            let logUrl = config.scpFEDomain+'/app/'+appAlias
            if(participantId && assetScheduleId){
                logUrl = logUrl + '?part='+participantId+'&as='+assetScheduleId
            }else{
                if(participantId){
                    logUrl = logUrl + '?part='+participantId
                }
                if(assetScheduleId){
                    logUrl = logUrl + '?as='+assetScheduleId
                }   
            }
            
            return logUrl
        }

        return null;
    }

    const renderLogActivity = (logIdx, logActivity, participant, assetSchedule, showParticipant=true) => {
        let applications = logActivity.applications?logActivity.applications:[]
        let mapApplicationRecords = logActivity.mapApplicationRecords?logActivity.mapApplicationRecords:{}
        let mapCustomFields = logActivity.mapCustomFields?logActivity.mapCustomFields:{}
        let mapWaiverFields = logActivity.mapWaiverFields?logActivity.mapWaiverFields:{}
        
        return(
            <div className="p-grid p-fluid">
                {participant && showParticipant &&
                    <div className="p-col-12">
                        <label>Participant: <span className="p-w-bold">{participant.name}</span></label>
                    </div>
                }
                
                {applications.map(app => {
                    const tmpAppRecords = mapApplicationRecords[app.id]
                    const appLogUrl = renderQRCodeUrl(app.alias, participant?participant.id:null, assetSchedule?assetSchedule.id:null)

                    return(
                        <div key={app.id} className="p-col-12">
                            <Fieldset legend={app.name}>
                                <div className="p-grid">
                                    {(()=>{
                                        if(!tmpAppRecords || tmpAppRecords.length===0){
                                            return <div className="p-col-12">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-sm-6">No log</div>
                                                    <div className="p-col-12 p-sm-6 p-r">
                                                        <Button label={selectedLogs.includes(logIdx+'_'+app.id)?'Hide QR':'Create Log'} onClick={() => onCreateLog(logIdx+'_'+app.id)} style={{width: 'auto'}} />
                                                    </div>
                                                    {selectedLogs.includes(logIdx+'_'+app.id) && 
                                                        <React.Fragment>
                                                            <div className="p-col-12"><hr/></div>
                                                            {appLogUrl?
                                                            <div className="p-col-12">
                                                                <div className="p-margin-bottom-5">Url: <a href={appLogUrl} target="blank">{appLogUrl}</a></div>
                                                                <div className="p-c">
                                                                    <QRCode value={appLogUrl} size={250} includeMargin={true} level="M"/>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="p-col-12">
                                                                Note: there's no setting for customer site url
                                                            </div>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        }else{
                                            return <div className="p-col-12">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-r">
                                                                <Button label={selectedLogs.includes(logIdx+'_'+app.id)?'Hide QR':'Create Log'} onClick={() => onCreateLog(logIdx+'_'+app.id)} style={{width: 'auto'}} />
                                                            </div>
                                                            {selectedLogs.includes(logIdx+'_'+app.id) && 
                                                                <React.Fragment>
                                                                    {appLogUrl?
                                                                    <div className="p-col-12">
                                                                        <div className="p-margin-bottom-5">Url: <a href={appLogUrl} target="blank">{appLogUrl}</a></div>
                                                                        <div className="p-c">
                                                                            <QRCode value={appLogUrl} size={250} includeMargin={true} level="M"/>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="p-col-12">
                                                                        Note: there's no setting for customer site url
                                                                    </div>
                                                                    }
                                                                    <div className="p-col-12"><hr/></div>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                    {tmpAppRecords.map((record, idxRecord) => {
                                                        let tmpPersonalInfos = record.personalInfo

                                                        return (
                                                            <div key={app.id+'_'+idxRecord} className="p-col-12">
                                                                <Panel header={'Log Num.#'+(idxRecord+1)+' ('+(record.date&&moment(record.date).format('MM/DD/YYYY hh:mm A'))+')'} toggleable collapsed={true} >
                                                                    <div className="p-grid">
                                                                        {(app.type===APPLICATION_TYPE.approval.value || app.type===APPLICATION_TYPE.answer.value) &&
                                                                        <React.Fragment>
                                                                            <div className="p-col-12">
                                                                                <div className="p-grid">
                                                                                    <div className="p-col-12 p-md-6">
                                                                                        Current status: <strong>{APPLICATION_RECORD_STATUS[record.status]?APPLICATION_RECORD_STATUS[record.status].label:APPLICATION_RECORD_STATUS.pending.label}</strong>
                                                                                    </div>
                                                                                    <div className="p-col-12 p-md-6 p-r">
                                                                                        {record.status!==APPLICATION_RECORD_STATUS.approved.value &&
                                                                                            <Button label="Approve" className="p-button-info" onClick={() => handleApproveOrReject(record.id, APPLICATION_RECORD_STATUS.approved.value)} style={{width: 'auto'}} />
                                                                                        }
                                                                                        {record.status!==APPLICATION_RECORD_STATUS.rejected.value &&
                                                                                            <Button label="Reject" className="p-button-warning" onClick={() => handleApproveOrReject(record.id, APPLICATION_RECORD_STATUS.rejected.value)} style={{width: 'auto'}} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="p-col-12"><hr/></div>
                                                                        </React.Fragment>
                                                                        }
                                                                        <div className="p-col-12">
                                                                            {app.contents && app.contents.map((content, idxContent) => {
                                                                                return content.components && content.components.map((component, idxComponent) => {
                                                                                    if(component.type===APPLICATION_COMPONENTT_TYPE.input_field.value){
                                                                                        let tmpPersonalSet = component.personalSet
                                                                                        let tmpPersonalField = tmpPersonalSet&&tmpPersonalSet.personalField?tmpPersonalSet.personalField:{}
                                                                                        let tmpCustomSet = component.customSet
                                                                                        let tmpWaiverSet = component.waiverSet
                                                                                        let tmpGuadianPersonalSet = tmpWaiverSet&&tmpWaiverSet.requiredGuardian?tmpWaiverSet.guardianPersonalSet:{}
                                                                                        let tmpGuardianPersonalField = tmpGuadianPersonalSet&&tmpGuadianPersonalSet.personalField?tmpGuadianPersonalSet.personalField:{}

                                                                                        let tmpPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                                                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                                                                            : {}
                                                                                        let tmpCustomFieldValue = mapCustomFields[record.id]&&tmpCustomSet&&mapCustomFields[record.id][tmpCustomSet.id]?mapCustomFields[record.id][tmpCustomSet.id]:{}
                                                                                        let tmpWaiverFieldValue = mapWaiverFields[record.id]&&tmpWaiverSet&&mapWaiverFields[record.id][tmpWaiverSet.id]?mapWaiverFields[record.id][tmpWaiverSet.id]:{}
                                                                                        let tmpGuardianPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
                                                                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
                                                                                            : {}

                                                                                        return(
                                                                                            <div key={app.id+'_'+idxRecord+'_'+idxContent} className="p-grid">
                                                                                                {tmpPersonalSet && 
                                                                                                    <div className="p-col-12">
                                                                                                        <div className="p-grid">
                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpPersonalSet&&tmpPersonalSet.label}</span></div>
                                                                                                            <div className="p-col-12">
                                                                                                                {tmpPersonalValue && Object.keys(tmpPersonalValue).map(key =>{
                                                                                                                    let tmpLabel
                                                                                                                    let tmpValue
                                                                                                                    if(key==='firstName'){ 
                                                                                                                        tmpLabel = tmpPersonalField['firstNameLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='lastName'){ 
                                                                                                                        tmpLabel = tmpPersonalField['lastNameLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='email'){ 
                                                                                                                        tmpLabel = tmpPersonalField['EmailLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='gender') {
                                                                                                                        tmpLabel = tmpPersonalField['genderLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='bithday'){
                                                                                                                        tmpLabel = tmpPersonalField['birthdayLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]&&moment(tmpPersonalValue[key]).format('MM/DD/YYYYY')
                                                                                                                    }
                                                                                                                    if(key==='phone'){ 
                                                                                                                        tmpLabel = tmpPersonalField['phoneLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='passportNRIC'){ 
                                                                                                                        tmpLabel = tmpPersonalField['passportNRICLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='nationalityCode'){ 
                                                                                                                        tmpLabel = tmpPersonalField['nationalityLabel']
                                                                                                                        tmpValue = tmpPersonalValue['nationalityName']
                                                                                                                    }
                                                                                                                    if(key==='registerType'){ 
                                                                                                                        tmpLabel = tmpPersonalField['registerTypeLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='emeContactName') {
                                                                                                                        tmpLabel = tmpPersonalField['emerContactNameLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='emeContactPhone') {
                                                                                                                        tmpLabel = tmpPersonalField['emerContactPhoneLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='emeContactEmail') {
                                                                                                                        tmpLabel = tmpPersonalField['emerContactEmailLabel']
                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(tmpLabel && tmpValue)
                                                                                                                        return (
                                                                                                                            <div className="p-grid">
                                                                                                                                <div className="p-col-6">{tmpLabel}: </div>
                                                                                                                                <div className="p-col-6">{tmpValue}</div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {tmpCustomSet&&
                                                                                                    <div className="p-col-12">
                                                                                                        <div className="p-grid">
                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpCustomSet&&tmpCustomSet.label}</span></div>
                                                                                                            <div className="p-col-12">
                                                                                                                {tmpCustomFieldValue && Object.values(tmpCustomFieldValue).map(item => {
                                                                                                                    return (
                                                                                                                        <div className="p-grid">
                                                                                                                            <div className="p-col-6">{item.fieldLabel}: </div>
                                                                                                                            <div className="p-col-6">{item.value}</div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {tmpWaiverSet&&
                                                                                                    <div className="p-col-12">
                                                                                                        <div className="p-grid">
                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpWaiverSet&&tmpWaiverSet.label}</span></div>
                                                                                                            <div className="p-col-12">
                                                                                                                {tmpWaiverFieldValue && Object.values(tmpWaiverFieldValue).map(item => {
                                                                                                                    let partAnswerObj = item&&item.storeType==='boolean'?JSON.parse(item.value):{}
                                                                                                                    return (
                                                                                                                        <div className="p-grid">
                                                                                                                            <div className="p-col-6">{item.fieldLabel}: </div>
                                                                                                                            <div className="p-col-6">{partAnswerObj.accepted}</div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {tmpWaiverSet && tmpWaiverSet.requiredGuardian && tmpGuardianPersonalValue && Object.keys(tmpGuardianPersonalValue).length>0 && 
                                                                                                    <div className="p-col-12">
                                                                                                        <div className="p-grid">
                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpGuadianPersonalSet&&tmpGuadianPersonalSet.label}</span></div>
                                                                                                            <div className="p-col-12">
                                                                                                                {Object.keys(tmpGuardianPersonalValue).map(key=>{
                                                                                                                    let tmpLabel
                                                                                                                    let tmpValue
                                                                                                                    if(key==='firstName'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['firstNameLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='lastName'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['lastNameLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='email'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['EmailLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='gender') {
                                                                                                                        tmpLabel = tmpGuardianPersonalField['genderLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='bithday'){
                                                                                                                        tmpLabel = tmpGuardianPersonalField['birthdayLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]&&moment(tmpPersonalValue[key]).format('MM/DD/YYYYY')
                                                                                                                    }
                                                                                                                    if(key==='phone'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['phoneLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='passportNRIC'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['passportNRICLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='nationalityCode'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['nationalityLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue['nationalityName']
                                                                                                                    }
                                                                                                                    if(key==='registerType'){ 
                                                                                                                        tmpLabel = tmpGuardianPersonalField['registerTypeLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='emeContactName') {
                                                                                                                        tmpLabel = tmpGuardianPersonalField['emerContactNameLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='emeContactPhone') {
                                                                                                                        tmpLabel = tmpGuardianPersonalField['emerContactPhoneLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(key==='emeContactEmail') {
                                                                                                                        tmpLabel = tmpGuardianPersonalField['emerContactEmailLabel']
                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                    }
                                                                                                                    if(tmpLabel && tmpValue)
                                                                                                                        return (
                                                                                                                            <div className="p-grid">
                                                                                                                                <div className="p-col-6">{tmpLabel}: </div>
                                                                                                                                <div className="p-col-6">{tmpValue}</div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            })}         
                                                                        </div>
                                                                    </div>
                                                                </Panel>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                            </Fieldset>
                        </div>
                    )
                })}
            </div>
        )
    }

    const closeForm = () => {
        setVisible(false)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{formHeader}</h2>
                <div>
                    <Button icon="pi pi-refresh" className="p-button-constrast p-mr-2" onClick={refreshForm}/>
                    <Button icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
                </div>
                
            </div>
            
            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid">
                <div className="p-col-12">
                    {(()=>{
                        if(dataList.length===1){
                            let participant = dataList[0].participant
                            let assetSchedule = dataList[0].assetSchedule
                            return renderLogActivity(0, dataList[0], participant, assetSchedule)
                        }else{
                            return dataList.map((logData, idx) => {
                                let participant = logData.participant
                                let assetSchedule = logData.assetSchedule
                                return(
                                    <Fieldset legend={participant.name}>
                                        {renderLogActivity(idx, logData, participant, assetSchedule, false)}
                                    </Fieldset>
                                )
                            })
                        }
                    })()}    
                </div>
            </div>
            
            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid p-margin-top-20">
                <div className="p-col-12 p-r">
                    <Button label="Close" icon="pi-md-close" className="p-button-secondary" onClick={()=> closeForm()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

