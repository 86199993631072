import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from "../../constants";
import querystring from "querystring";
import qs from "qs";

const USER_URL = API_BASE_URL + SCP_PATH + "/users";

export function getAllUsers(search, type, id){
    return axios.get(`${USER_URL}/list`, {
        params: {search, type, id}
    }).then(res => res.data).catch(error => console.log(error));
}

export function getUser(id){
    return axios.get(`${USER_URL}/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export class UserServices {
    //#region User Info
    getPageUsers(filter, page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.post(`${API_BASE_URL}/scp/api/users/page`, filter, {
            params: {page, size, sort}
        }).then(res => {
            return res.data
        }).catch(error => console.log(error));
    }

    downloadUserContacts(filter) {
        return axios.get(`${API_BASE_URL}/scp/api/users/download`, {
            params: {...filter}, responseType: 'blob'
        }).then(res => res.data).catch(error => console.log(error));
    }

    getAllUsers(search, type = null, id = null){
        return axios.get(`${API_BASE_URL}/scp/api/users/list`, {
            params: {search, type, id}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getUsersInOptionType(search, type = '', id = null){
        return axios.get(`${API_BASE_URL}/scp/api/users/list/get_by_type`, {
            params: {search, type, id}
        }).then(res => res.data).catch(error => console.log(error));
    }

    resetPassword(account) {
        return axios.get(`${API_BASE_URL}/scp/api/users/reset-password`, {
            params: {account}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    recoverUsername(email) {
        return axios.get(`${API_BASE_URL}/scp/api/users/recover-username`, {
            params: {email}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    saveChangePassword(data) {
        return axios.post(`${API_BASE_URL}/scp/api/users/save-change-pass`, 
            querystring.stringify({...data})
        )
        .then(res => res.data).catch(error => console.log(error));
    }

    getUser(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    saveUser(form, dataMoreOptions){
        return axios.post(`${API_BASE_URL}/scp/api/users`, form,
            {params: {...dataMoreOptions}}
        ).then(res => res.data)
    }

    saveUserInfo(form){
        return axios.post(`${API_BASE_URL}/scp/api/users/save_information`, form)
            .then(res => res.data)
    }

    deleteUser(id){
        return axios.post(`${API_BASE_URL}/scp/api/users/delete_user`, null,
            {params: {
                id
            }}
        ).then(res => res.data)
    }

    saveSecurityInfo(form){
        return axios.post(`${API_BASE_URL}/scp/api/users/save_security`, form)
            .then(res => res.data)
    }

    saveOthers(form){
        return axios.post(`${API_BASE_URL}/scp/api/users/save_others`, form)
            .then(res => res.data)
    }

    saveUserAvatar(form){
        return axios.post(`${API_BASE_URL}/scp/api/users/avatar`, form)
            .then(res => res.data)
    }

    updateStatusUser(id, status){
        return axios.post(`${API_BASE_URL}/scp/api/users/${id}/update_status`,
            querystring.stringify({
                status: status
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    activeUser(id){
        return axios.post(`${API_BASE_URL}/scp/api/users/${id}/active`)
        .then(res => res.data).catch(error => console.log(error));
    }

    sendMailVerifyAccountForCustomer(username){
        return axios.post(`${API_BASE_URL}/scp/api/users/send-mail-account-verification`, querystring.stringify({account: username})).then(res => res.data).catch(error => console.log(error))
    }

    sendMailVerifyAccountForSystem(username){
        return axios.post(`${API_BASE_URL}/scp/api/users/send-mail-account-verification/by-system`, querystring.stringify({account: username})).then(res => res.data).catch(error => console.log(error))
    }
    
    updateActiveStatus(id, enabled){
        return axios.post(`${API_BASE_URL}/scp/api/users/${id}/active_status`, null, {params: {enabled}})
            .then(res => res.data)
    }

    addToMailList(filter, data){
        return axios.post(`${API_BASE_URL}/scp/api/users/add-mail-list`, filter, {params: {...data}})
        .then(res => res.data).catch(error => console.log(error));
    }

    //#endregion

    //#region Adjust Income
    saveAdjustIncome(form){
        return axios.post(`${API_BASE_URL}/scp/api/users/adjust_income`, form).then(res => res.data)
    }
    saveAdjustIncomeByRequest(form){
        return axios.post(`${API_BASE_URL}/scp/api/users/adjust_income_by_request`, form).then(res => res.data)
    }
    //#endregion

    //#region user car
    saveCarInfo(uId, form){
        return axios.post(`${API_BASE_URL}/scp/api/users/${uId}/cars`, form).then(res => res.data).catch(error => console.log(error))
    }

    getListUserCars(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/${id}/cars/list`).then(res => res.data).catch(error => console.log(error));
    }

    removeUserCar(uId, carId){
        return axios.delete(`${API_BASE_URL}/scp/api/users/${uId}/cars/${carId}`).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion user car

    //#region emergency contact
    saveEmeContact(uId, form){
        return axios.post(`${API_BASE_URL}/scp/api/users/${uId}/emergency`, form).then(res => res.data).catch(error => console.log(error))
    }

    getListEmeContacts(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/${id}/emergency/list`).then(res => res.data).catch(error => console.log(error));
    }

    removeEmeContact(uId, carId){
        return axios.delete(`${API_BASE_URL}/scp/api/users/${uId}/emergency/${carId}`).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion emergency contact

    //#region member family
    getGroupUsers(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/${id}/groups`)
        .then(res=> res.data).catch(error => console.log(error));
    }

    saveGroupUser(id, data){
        return axios.post(`${API_BASE_URL}/scp/api/groups/member`, data, {
            params: {id}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getMembers(id, page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/users/group/${id}/members`, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    deleteMember(id){
        return axios.delete(`${API_BASE_URL}/scp/api/groups/${id}/member`)
        .then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region Payor
    getAllPayor(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/payors`, {
            params: {id}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getAllPayorUser(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/payors/list`, {
            params: {id}
        }).then(res => res).catch(error => console.log(error));
    }

    savePayor(data){
        return axios.post(`${API_BASE_URL}/scp/api/users/payors`, data
        ).then(res => res.data).catch(error => console.log(error));
    }

    deletePayor(id){
        return axios.delete(`${API_BASE_URL}/scp/api/users/payors`, {
            params: {id}
        }).then(res => res).catch(error => console.log(error));
    }

    getPageCourse(userId, page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/programs/${userId}/course/page`, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region Relation
    getPageRelations(userId, page, size, sortField, sortOrder){
        const order = sortOrder===1 ? 'asc' : sortOrder===-1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/users/${userId}/relations/page`, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getRelation(id){
        return axios.get(`${API_BASE_URL}/scp/api/users/relations/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveRelation(data){
        return axios.post(`${API_BASE_URL}/scp/api/users/relations`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteRelation(id){
        return axios.delete(`${API_BASE_URL}/scp/api/users/relations/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getListUserRelations(id, types){
        return axios.get(`${API_BASE_URL}/scp/api/users/${id}/relations/list`,
            {params: {types}, paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})}
        ).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region Payment
    getPageUserPayments(userId, page, size, sortField, sortOrder){
        const order = sortOrder===1 ? 'asc' : sortOrder===-1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/users/${userId}/payments/page`, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getUserPaymentDetails(uId, paymentId){
        return axios.get(`${API_BASE_URL}/scp/api/users/${uId}/payment/${paymentId}`).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region Spend on
    getPageUserSpendOn(userId, page, size, sortField, sortOrder){
        const order = sortOrder===1 ? 'asc' : sortOrder===-1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/users/${userId}/spend-on/page`, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region Tag
    getListUserTags(userId){
        return axios.get(`${API_BASE_URL}/scp/api/users/${userId}/tags/list`).then(res => res.data).catch(error => console.log(error));
    }

    saveUserTag(userId, data){
        return axios.post(`${API_BASE_URL}/scp/api/users/${userId}/tags`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    removeUserTag(userId, type, resourceType){
        return axios.delete(`${API_BASE_URL}/scp/api/users/${userId}/tags/${type}/${resourceType}`).then(res => res.data).catch(error => console.log(error))
    }
    //#endregion

    //#region contact document
    getPageUserFiles(uId, page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField==='created'?'createdAt':sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/users/${uId}/document/page`, {
            params: {page, size, sort}
        }).then(res => {return res.data}).catch(error => console.log(error));
    }

    getTreeUserFiles(uId){
        return axios.get(`${API_BASE_URL}/scp/api/users/${uId}/document/list`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }

    getUserFile(id, uId){
        return axios.get(`${API_BASE_URL}/scp/api/users/document/${id}/${uId}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveUserFile(uId, data){
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('id', data.id);
        formData.append('docType', data.docType);
        formData.append('appliedYear', data.appliedYear);
        formData.append('requestInfo', data.requestInfo);
        formData.append('status', data.status);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.post(`${API_BASE_URL}/scp/api/users/${uId}/document`, formData, config)
        .then(res => res.data).catch(error => console.log(error));
    }

    removeUserFile(userId, id){
        return axios.delete(`${API_BASE_URL}/scp/api/users/${userId}/document/${id}`).then(res => res.data).catch(error => console.log(error))
    }

    downloadDocument(uId, fileName){
        return axios.get(`${API_BASE_URL}/scp/api/users/${uId}/document/${fileName}`, {responseType: 'arraybuffer'})
        .then(res => res.data).catch(error => console.log(error));
    }

    printDocument(uId, fileName){
        return axios.post(`${API_BASE_URL}/scp/api/users/${uId}/document/${fileName}/print`, {responseType: 'arraybuffer'})
        .then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region skills
    saveUserTalents(data){
        return axios.post(`${API_BASE_URL}/scp/api/users/${data.id}/talents`, data).then(res => res.data).catch(error => console.log(error))
    }
    //#endregion

    //#region newsletter
    saveUserNewsletters(data){
        return axios.post(`${API_BASE_URL}/scp/api/users/${data.id}/communication-preferences`, data).then(res => res.data).catch(error => console.log(error))
    }
    //#endregion

    //#region other
    saveOtherInfo(uId, employmentInfo, residentInfo){
        return axios.post(`${API_BASE_URL}/scp/api/users/${uId}/save_other`, {employmentInfo, residentInfo}).then(res => res.data).catch(error => console.log(error))
    }
    //#endregion

    //#region Scan log
    getPageScanlog(userId, page, size, sortField, sortOrder){
        const order = sortOrder===1 ? 'asc' : sortOrder===-1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/users/${userId}/scan-log/page`, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region Security
    saveNewPassword(form){
        return axios.post(`${API_BASE_URL}/scp/api/profile/save-new-password`, form)
            .then(res => res.data)
    }

    saveNewPasscode(form){
        return axios.post(`${API_BASE_URL}/scp/api/profile/save-passcode`, form)
            .then(res => res.data)
    }
    //#endregion

}

//#region USER
export function getPageUsers(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${USER_URL}/page`, filter, {
        params: {page, size, sort}
    }).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function saveUser(form){
    return axios.post(`${USER_URL}`, form).then(res => res.data)
}

export function saveUserInfo(form){
    return axios.post(`${USER_URL}/save_information`, form)
        .then(res => res.data)
}

export function sendMailVerifyAccountForSystem(username){
    return axios.post(`${USER_URL}/send-mail-account-verification/by-system`, querystring.stringify({account: username})).then(res => res.data).catch(error => console.log(error))
}

export function updateActiveStatus(id, enabled){
    return axios.post(`${USER_URL}/${id}/active_status`, null, {params: {enabled}})
        .then(res => res.data)
}

export function activeUser(id){
    return axios.post(`${USER_URL}/${id}/active`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveSecurityInfo(form){
    return axios.post(`${USER_URL}/save_security`, form)
        .then(res => res.data)
}

export function saveUserAvatar(form){
    return axios.post(`${USER_URL}/avatar`, form)
        .then(res => res.data)
}

export function updateStatusUser(id, status){
    return axios.post(`${USER_URL}/${id}/update_status`,
        querystring.stringify({
            status: status
        })
    ).then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region EMERGENCY CONTACT
export function saveEmeContact(uId, form){
    return axios.post(`${USER_URL}/${uId}/emergency`, form).then(res => res.data).catch(error => console.log(error))
}

export function getListEmeContacts(id){
    return axios.get(`${USER_URL}/${id}/emergency/list`).then(res => res.data).catch(error => console.log(error));
}

export function removeEmeContact(uId, emeContactId){
    return axios.delete(`${USER_URL}/${uId}/emergency/${emeContactId}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion
