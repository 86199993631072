export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const USER_ID = process.env.REACT_APP_USER_ID;
export const USER_NAME = process.env.REACT_APP_USERNAME;

let loginURL = '';
if (process.env.REACT_APP_LOGIN_BASE_URL) {
  loginURL = process.env.REACT_APP_LOGIN_BASE_URL;
} else {
  loginURL = process.env.REACT_APP_API_BASE_URL;
};
export const LOGIN_BASE_URL = loginURL;

let pimURL = '';
if (process.env.REACT_APP_PIM_BASE_URL) {
  pimURL = process.env.REACT_APP_PIM_BASE_URL;
} else {
  pimURL = process.env.REACT_APP_API_BASE_URL;
};
export const PIM_BASE_URL = pimURL;
export const PIM_PATH = process.env.REACT_APP_PATH_PIM;

let paymentURL = '';
if (process.env.REACT_APP_PAYMENT_BASE_URL) {
  paymentURL = process.env.REACT_APP_PAYMENT_BASE_URL;
} else {
  paymentURL = process.env.REACT_APP_API_BASE_URL;
};
export const PAYMENT_BASE_URL = paymentURL + process.env.REACT_APP_PATH_PAYMENT;

let residenceURL = '';
if (process.env.REACT_APP_RESIDENCE_BASE_URL) {
  residenceURL = process.env.REACT_APP_RESIDENCE_BASE_URL;
} else {
  residenceURL = process.env.REACT_APP_API_BASE_URL;
};
export const RESIDENCE_BASE_URL = residenceURL;

let formURL = '';
if (process.env.REACT_APP_FORM_BASE_URL) {
  formURL = process.env.REACT_APP_FORM_BASE_URL;
} else {
  formURL = process.env.REACT_APP_API_BASE_URL;
};

let snsURL = '';
if (process.env.REACT_APP_SNS_BASE_URL) {
  snsURL = process.env.REACT_APP_SNS_BASE_URL;
} else {
  snsURL = process.env.REACT_APP_API_BASE_URL;
};

let mailServiceUrl = '';
if (process.env.REACT_APP_MAIL_BASE_URL) {
  mailServiceUrl = process.env.REACT_APP_MAIL_BASE_URL;
} else {
  mailServiceUrl = process.env.REACT_APP_API_BASE_URL;
};
export const MAIL_BASE_URL = mailServiceUrl;

export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;
export const WS_FALLBACK_BASE_URL = process.env.REACT_APP_WS_FALLBACK_BASE_URL;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
export const TENANT = process.env.REACT_APP_TENANT;

export const SCP_PATH = process.env.REACT_APP_PATH_SCP;
export const RESIDENCE_PATH = process.env.REACT_APP_PATH_RESIDENCE;
export const MAIL_PATH = process.env.REACT_APP_PATH_MAIL;
export const FORM_PATH = process.env.REACT_APP_PATH_FORM;
export const SNS_PATH = process.env.REACT_APP_PATH_SNS;

export const FORM_BASE_URL = formURL + FORM_PATH;
export const SNS_BASE_URL = snsURL + SNS_PATH;

export const APPLICATION_FORM_VIEW_URL = process.env.REACT_APP_APPLICATION_FORM_VIEW_URL;

let configServices = '';
if(process.env.REACT_APP_CONFIGURATION_BASE_URL){
  configServices = process.env.REACT_APP_CONFIGURATION_BASE_URL;
} else {
  configServices = process.env.REACT_APP_API_BASE_URL;
}
export const CONFIGURATION_BASE_URL = configServices;

export function getTenantAlias() {
  let parts = window.location.host.split(".");
  let subdomain = "ymca";
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 3) {
      subdomain = parts[0];
  }
  return subdomain.split("-")[0];
};

export const APP_FEP = 'fep';

export const PRICE_APPLY_TYPE = {
  normal: {value: 'normal', label: 'Normal'},
  membership: {value: 'membership', label: 'Membership'},
  staff: {value: 'staff', label: 'Staff'},
  staff_part: {value: 'staff_part', label: 'Staff Part Time'}
}

export const MESSAGE = {
  ERROR_FIELD_INPUT_REQUIRED: "Field require input",
  ERROR_FIELD_SELECT_REQUIRED: "Please select a value",
  INVALID_NUMBER_FORMAT: "Wrong number",
  INVALID_EMAIL_FORMAT: "Wrong email format",
  DUPLICATED_VALUE: "This value has existed"
};

export const TABLE_PAGING_LENGTH = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];

export const PROG_STATUSES = [
  { label: "All", value: "" },
  { label: "Active", value: true },
  { label: "Not Active", value: false }
];

export const PROG_WORK_TYPES = [
  { label: "Simple", value: "simple" },
  { label: "Daily/Weekly", value: "dw" }
];

export const NAME_TITLES = [
  { label: "Not use", value: "" },
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." }
];

export const GENDERS = [
  { label: "Any", value: "Any" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "NonBinary" }
];

export const GL_TYPES = [
  { label: "Cash", value: "Cash" },
  { label: "Receivable", value: "Receivable" },
  { label: "Deferred", value: "Deferred" },
  { label: "Program", value: "Program" },
  { label: "Membership", value: "Membership" },
  { label: "Assistance", value: "Assistance" }
];

export const FILTER_GROUP_TYPES = [
  { label: "All", value: "" },
  { label: "Household", value: "household" },
  { label: "Company", value: "company" }
];

export const GROUP_TYPES = [
  { label: "Household", value: "household" },
  { label: "Company", value: "company" }
];

export const GROUP_MEMBER_RELATIONSHIP_TYPES = [
  { label: "Grand", value: "Grand" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Child", value: "Child" }
];

export const USER_ORIGIN = {
  fep_imported: {value: 'fep_imported', label: 'Imported'},
  fep_admin: {value: 'fep_admin', label: 'Admin'},
  fep_customer: {value: 'fep_customer', label: 'Customer'},
}

export const USER_STATUS = {
  block: 'block',
  active: 'active',
  suspended: 'suspended'
}

export const USER_RELATIONSHIP_TYPE = {
  unknown: { label: "Unknown", value: "unknown" },
  friend: { label: "Friend", value: "friend" },
  family: { label: "Family", value: "family" },
  coworker: { label: "Co-worker", value: "coworker" },
  parent: { label: "Parent", value: "parent" },
  father: { label: "Father", value: "father" },
  mother: { label: "Mother", value: "mother" },
  child: { label: "Child", value: "child" },
  son: { label: "Son", value: "son" },
  daughter: { label: "Daughter", value: "daughter" }
}

export const USER_RELATIONSHIP_TYPES = [
  { label: "Unknown", value: "unknown" },
  { label: "Friend", value: "friend" },
  { label: "Family", value: "family" },
  { label: "Co-worker", value: "coworker" },
  { label: "Parent", value: "parent" },
  { label: "Father", value: "father" },
  { label: "Mother", value: "mother" },
  { label: "Child", value: "child" },
  { label: "Son", value: "son" },
  { label: "Daughter", value: "daughter" }
];

export const USER_KIND_OF_RELATIONSHIPS = [
  { label: "Emergency", value: "emergency" },
  { label: "Pickup", value: "pickup" },
  { label: "Payor", value: "payor" },
];

export const FIELD_TYPE = {
  text: "text",
  number: "number",
  email: "email",
  phone: "phone",
  select: "select",
  radio: "radio",
  checkbox: "checkbox",
  boolean: "yes_no",
  textarea: "textarea",
  file: "file",
  date: "date",
  time: "time",
  waiver: "waiver"
};

export const FIELD_EXPRESSION_TYPE = {
  none: 0,
  pattern: 1,
  maskString: 2
};

export const FIELD_DISPLAY_TYPE = {
  dropdown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textbox: "textbox",
  file: "file"
};

export const FIELD_DIRECTION_TYPE = {
  horizontal: 'horizontal',
  vertical: 'vertical'
}

export const ENCRYPTED_OPTIONS = [
  { label: "UNENCRYPTED", value: "Unencrypted" },
  { label: "SSL/TLS", value: "SSLTLS" },
  { label: "STARTTLS", value: "STARTTLS" }
];

export const DEPOSIT_TYPE = {
  installment: {value: 'installment', label: 'Installment'},
  pledge: {value: 'pledge', label: 'Security '}
}

export const PAYMENT_PLAN_TYPE = {
  full: "full",
  recurring: "recurring"
};

export const PAYMENT_GATEWAY = {
  ONSITE: 'ONSITE',
  STRIPE: 'STRIPE',
  SQUARE: 'SQUARE',
  PAYPAL: 'PAYPAL',
  MIDTRANS: 'MIDTRANS',
  HITPAY: 'HITPAY',
  BRAINTREE: 'BRAINTREE',
  SLIM_CD: 'SLIM_CD',
  LUMINATE_DONATION_API: 'LUMINATE_DONATION_API',
  RED_DOT: 'RED_DOT'
};

export const PAYMENT_GATEWAYS = [
  {label: 'SQUARE', value: PAYMENT_GATEWAY.SQUARE},
  {label: 'STRIPE', value: PAYMENT_GATEWAY.STRIPE},
  {label: 'PAYPAL', value: PAYMENT_GATEWAY.PAYPAL},
  {label: 'MIDTRANS', value: PAYMENT_GATEWAY.MIDTRANS},
  {label: 'HITPAY', value: PAYMENT_GATEWAY.HITPAY}
  // {label: 'BRAINTREE', value: "BRAINTREE"},
  // {label: 'SLIM_CD', value: "SLIM_CD"},
  // {label: 'LUMINATE_DONATION_API', value: "LUMINATE_DONATION_API"},
  // {label: 'RED_DOT', value: "RED_DOT"},
  // {label: 'FREE', value: "FREE"},
  // {label: 'USER_CREDIT', value: "USER_CREDIT"}
]

export const PAYMENT_METHOD = {
  online: {value: 'online', label: 'Online Pay'},
  cash: {value: 'cash', label: 'Cash'},
  voucher: {value: 'voucher', label: 'Voucher'},
  cheque: {value: 'cheque', label: 'Cheque'},
  swipe_card: {value: 'swipe_card', label: 'Swipe Card'},
  user_credit: {value: 'user_credit', label: 'User Credit'},
  bank_transfer: {value: 'bank_transfer', label: 'Bank Transfer'},
  credit_card: {value: 'credit_card', label: 'Credit Card'},
  card: {value: 'card', label: 'Card'},
  paynow_online: {value: 'paynow_online', label: 'Paynow Online'},
  alipay: {value: 'alipay', label: 'Ali Pay'},
  wechat: {value: 'wechat', label: 'Wechat'},
  grabpay: {value: 'grabpay', label: 'Grab Pay'}
};

export const ORDER_STATE = {
  cart: {value: 'cart', label: 'Cart'},
  new_order: {value: 'new_order', label: 'New'},
  viewed: {value: 'viewed', label: 'Viewed'},
  canceled: {value: 'canceled', label: 'Canceled'}
};

export const PAYMENT_STATE = {
  pending: {value: 'pending', label: 'Pending'},
  awaiting: {value: 'awaiting', label: 'Awaiting payment'},
  completed: {value: 'completed', label: 'Completed'},
  cancelled: {value: 'cancelled', label: 'Cancelled'}
};

export const CHANNEL_TYPE = {
  event: "event",
  program: "program",
  store: "store",
  membership: "membership",
  donation: "donation"
};

export const CHANNEL_TYPES = [
  { label: 'Event', value: CHANNEL_TYPE.event },
  { label: 'Program', value: CHANNEL_TYPE.program },
  { label: 'Store', value: CHANNEL_TYPE.store },
  { label: 'Membership', value: CHANNEL_TYPE.membership },
  { label: 'Donation', value: CHANNEL_TYPE.donation },
];

export const PRODUCT_CLASSIFICATION = {
  simple: {value: 'simple', label: 'Simple'},
  variable: {value: 'variable', label: 'Variable'},
  combo: {value: 'combo', label: 'Combo'},
  configuration: {value: 'configuration', label: 'Configuration'}
}

export const PRODUCT_TYPE = {
  program: {value: 'program', label: 'Program'},
  rental: {value: 'rental', label: 'Rental'},
  session: {value: 'session', label: 'Session'},
  addon: {value: 'addon', label: 'Addon'},
  membership: {value: 'membership', label: 'Membership'},
  donation: {value: 'donation', label: 'Donation level'}
}

export const PRODUCT_PRICE_UNIT = {
  min: {value: 'min', label: 'min'},
  hr: {value: 'hr', label: 'hr'},
  day: {value: 'day', label: 'day'},
  month: {value: 'month', label: 'month'},
  year: {value: 'year', label: 'year'}
}

export const GALLERY_TYPE = {
  image: 'image',
  video: 'video'
}

export const VARIANT_OPTION_TYPE = {
  option: {value: 'option', label: 'Product option'},
  custom: {value: 'custom', label: 'Custom input'}
}

export const PROD_TYPE = {
  personalTicket: "personal_ticket",
  normalTicket: "normal_ticket",
  sessionTicket: "session_ticket",
  groupTicket: "group_ticket",
  reservation: "reservation",
  allocation: "allocation",
  addon: "addon",
  merchandize: "merchandize",
  membership: "membership",
  donation: "donation"
};

export const NOTE_STATUSES = [
  { label: 'New', value: 'create' },
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Done', value: 'done' },
  { label: 'Reopen', value: 'reopen' },
];

export const BUDGET_TARGET_TYPE = {
  department: 'department',
  membership: 'membership',
  campaign: 'campaign',
  order_program: 'order_program'
};

export const MERGE_FIELDS = [
  "username",
  "type",
  "title",
  "nickname",
  "first Name",
  "middle Name",
  "last Name",
  "email",
  "secondary_email",
  "gender",
  "birthday",
  "cell Phone",
  "home Phone",
  "work Phone",
  "staff",
  "staff Type",
  "company Name",
  "company Since",
  "position",
  "grade",
];

export const SCHOLARSHIP_TYPES = [
  { value: 'general', label: 'General' },
  { value: 'membership', label: 'Membership' },
  { value: 'joiners_fee', label: 'Joiners Fee' },
  { value: 'programs', label: 'Programs' },
  { value: 'day_camp', label: 'Day Camp' },
  { value: 'childcare', label: 'Childcare' },
  { value: 'residence', label: 'Residence' }
];

export const CURRENCIES = [
  {label: 'USD', value: 'USD'},
  {label: 'EUR', value: 'EUR'},
  {label: 'SGD', value: 'SGD'},
  {label: 'IDR', value: 'IDR'},
];

export const CURRENCY_OBJECT = {
  USD: {symbol: '$', decimal: 2},
  EUR: {symbol: '€', decimal: 2},
  SGD: {symbol: 'S$', decimal: 2},
  IDR: {symbol: 'Rp', decimal: 0}
}

export const PAYMENT_USE_MODE = { 
  TEST: 'TEST',
  PRODUCTION: 'PRODUCTION'
}

export const PAYMENT_USE_MODES = [
  { label: "TEST", value: 'TEST'},
  { label: "PRODUCTION", value: 'PRODUCTION'}
]

export const CONTENT_TITLE_ALIGNMENT = { 
  left: 'left',
  center: 'center',
  right: 'right',
}

export const CONTENT_TITLE_ALIGNMENTS = [
  { label: "Left", value: CONTENT_TITLE_ALIGNMENT.left},
  { label: "Center", value: CONTENT_TITLE_ALIGNMENT.center},
  { label: "Right", value: CONTENT_TITLE_ALIGNMENT.right},
]

export const CONTENT_HERO_DISPLAY = { 
  none: 'none',
  left: 'left',
  both: 'both',
}

export const CONTENT_HERO_DISPLAYS = [
  { label: "None", value: CONTENT_HERO_DISPLAY.none},
  { label: "One Column", value: CONTENT_HERO_DISPLAY.left},
  { label: "Two Columns", value: CONTENT_HERO_DISPLAY.both},
]

export const CONTENT_HERO_BLOCK_TYPE = { 
  HTML: 'HTML',
  // IMAGE: 'IMAGE',
  SLIDER: 'SLIDER',
}

export const CONTENT_HERO_BLOCK_TYPES = [
  { label: "HTML", value: CONTENT_HERO_BLOCK_TYPE.HTML},
  // { label: "IMAGE", value: CONTENT_HERO_BLOCK_TYPE.IMAGE},
  { label: "SLIDER", value: CONTENT_HERO_BLOCK_TYPE.SLIDER},
]

export const CONTENT_LAYOUT_COLUMN = { 
  one_column: 'one_column',
  two_columns: 'two_columns',
  three_columns: 'three_columns',
}

export const CONTENT_LAYOUT_COLUMNS = [
  { label: "One Column", value: CONTENT_LAYOUT_COLUMN.one_column},
  { label: "Two Columns", value: CONTENT_LAYOUT_COLUMN.two_columns},
  { label: "Three Columns", value: CONTENT_LAYOUT_COLUMN.three_columns},
]

export const CONTENT_LAYOUT_COLUMN_TYPE = { 
  one_col_12: 'one_col_12',
  two_col_6_6: 'two_col_6_6',
  two_col_4_8: 'two_col_4_8',
  two_col_8_4: 'two_col_8_4',
  three_col_4_4_4: 'three_col_4_4_4',
  three_col_3_3_6: 'three_col_3_3_6',
  three_col_6_3_3: 'three_col_6_3_3',
  three_col_3_6_3: 'three_col_3_6_3'
}

export const CONTENT_COMPONENT_TYPE = {
  free_text : {value: 'free_text', label: 'Free text'},
  slider: {value: 'slider', label: 'Slider embed'},
  weather_widget: {value: 'weather_widget', label: 'Weather widget'},
  product_widget: {value: 'product_widget', label: 'Product widget'},
  facebook_embed: {value: 'facebook_embed', label: 'Facebook embed'},
  twitter_embed: {value: 'twitter_embed', label: 'Twitter embed'},
  google_embed: {value: 'google_embed', label: 'Google embed'},
  blog_listing: {value: 'blog_listing', label: 'Blog widget'},
  speaker: {value: 'speaker', label: 'Speaker widget'},
  event_ticket: {value: 'event_ticket', label: 'Ticket widget'},
}

export const MENU_TYPE = {
  PUBLIC_PAGE: { label: 'Public Page', value: 'PUBLIC_PAGE' },
  PREDEFINE_PAGE: { label: 'Predefine Page', value: 'PREDEFINE_PAGE'},
  LINK: { label: 'Link', value: 'LINK' },
  BLANK: { label: 'Blank', value: 'BLANK' }
}

export const PROMOTION_TYPE = {
  promotion: 'promotion',
  manual_discount: 'manual_discount'
}

export const PROMOTION_TYPES = [
  {value: PROMOTION_TYPE.promotion, label: 'Promotion'},
  {value: PROMOTION_TYPE.manual_discount, label: 'Manual Discount'}
]

export const CONDITION_CART_TYPE = {
  cart_subtotal: 'cart_subtotal',
  cart_quantities: 'cart_quantities',
  cart_item_line: 'cart_item_line'
}

export const CONDITION_CART_TYPES = [
  {value: CONDITION_CART_TYPE.cart_subtotal, label: 'promotion.mgt.condition_cart_type_subtotal'},
  {value: CONDITION_CART_TYPE.cart_quantities, label: 'promotion.mgt.condition_cart_type_quantities'},
  {value: CONDITION_CART_TYPE.cart_item_line, label: 'promotion.mgt.condition_cart_type_item_line'}
]

export const CONDITION_METHOD_TYPE = {
  less_than: 'less_than',
  less_than_or_equal: 'less_than_or_equal',
  greater_than: 'greater_than',
  greater_than_or_equal: 'greater_than_or_equal',
  range: 'range',
  in_list: 'in_list',
  not_in_list: 'not_in_list'
}

export const CONDITION_CART_METHOD_TYPES = [
  {value: CONDITION_METHOD_TYPE.greater_than, label: 'promotion.mgt.greater_than'},
  {value: CONDITION_METHOD_TYPE.greater_than_or_equal, label: 'promotion.mgt.greater_than_or_equal'},
  {value: CONDITION_METHOD_TYPE.less_than, label: 'promotion.mgt.less_than'},
  {value: CONDITION_METHOD_TYPE.less_than_or_equal, label: 'promotion.mgt.less_than_or_equal'}
]

export const CONDITION_DATE_TIME_METHOD_TYPES = [
  {value: CONDITION_METHOD_TYPE.in_list, label: 'promotion.mgt.in_list'},
  {value: CONDITION_METHOD_TYPE.not_in_list, label: 'promotion.mgt.not_in_list'}
]

export const DISCOUNT_VALUE_TYPE = {
  percentage: 'percentage',
  amount: 'amount',
  price: 'price'
}

export const DISCOUNT_VALUE_TYPES = [
  {value: DISCOUNT_VALUE_TYPE.percentage, label: 'promotion.mgt.percentage'},
  {value: DISCOUNT_VALUE_TYPE.amount, label: 'promotion.mgt.amount_value'},
  {value: DISCOUNT_VALUE_TYPE.price, label: 'promotion.mgt.fixed_price'}
]

export const PROMOTION_RULE_TYPE = {
  cart_adjustment: 'cart_adjustment',
  product_adjustment: 'product_adjustment',
  product_dependent: 'product_dependent',
  product_quantity: 'product_quantity',
  product_bxgx: 'product_bxgx',
  product_bxgy: 'product_bxgy',
  manual_order_discount: 'manual_order_discount'
}
export const PROMOTION_RULE_TYPES = [
  {value: PROMOTION_RULE_TYPE.cart_adjustment, label: 'promotion.mgt.rule_type_cart_adjustment'},
  {value: PROMOTION_RULE_TYPE.product_adjustment, label: 'promotion.mgt.rule_type_product_adjustment'},
  // {value: PROMOTION_RULE_TYPE.product_dependent, label: 'promotion.mgt.rule_type_product_dependent'},
  {value: PROMOTION_RULE_TYPE.product_quantity, label: 'promotion.mgt.rule_type_bulk_discount'},
  {value: PROMOTION_RULE_TYPE.product_bxgx, label: 'promotion.mgt.rule_type_buy_x_get_x'},
  {value: PROMOTION_RULE_TYPE.product_bxgy, label: 'promotion.mgt.rule_type_buy_x_get_y'}
]

export const CONDITION_BUY_TYPE = {
  any: 'any',
  each: 'each',
  combine: 'combine'
}

export const CONDITION_BUY_TYPES = [
  {value: CONDITION_BUY_TYPE.any, label: 'promotion.mgt.condition_buy_type_any'},
  {value: CONDITION_BUY_TYPE.each, label: 'promotion.mgt.condition_buy_type_each'},
  {value: CONDITION_BUY_TYPE.combine, label: 'promotion.mgt.condition_buy_type_combine'}
]

export const CONDITION_APPLY_TO_TYPE = {
  all_product: 'all_product',
  specific_menus: 'specific_menus',
  specific_categories: 'specific_categories',
  specific_products: 'specific_products'
}

export const CONDITION_APPLY_TO_TYPES = [
  {value: CONDITION_APPLY_TO_TYPE.all_product, label: 'promotion.mgt.all_dishes'},
  {value: CONDITION_APPLY_TO_TYPE.specific_menus, label: 'promotion.mgt.specific_menus'},
  {value: CONDITION_APPLY_TO_TYPE.specific_categories, label: 'promotion.mgt.specific_categories'},
  {value: CONDITION_APPLY_TO_TYPE.specific_products, label: 'promotion.mgt.specific_dish'}
]

export const CONDITION_APPLY_FOR_TYPE = {
  same_product: 'same_product',
  cheapest_product: 'cheapest_product',
  specific_product: 'specific_product'
}

export const CONDITION_APPLY_FOR_TYPES = [
  {value: CONDITION_APPLY_FOR_TYPE.same_product, label: 'promotion.mgt.condition_apply_for_type_same_product'},
  // {value: CONDITION_APPLY_FOR_TYPE.cheapest_product, label: 'promotion.mgt.condition_apply_for_type_cheapest_product'},
  {value: CONDITION_APPLY_FOR_TYPE.specific_product, label: 'promotion.mgt.condition_apply_for_type_specific_product'}
]

export const VOUCHER_STATUS = {
  New: 'New',
  Used: 'Used'
}

export const RULE_FILTER_OBJECT = {
  all_product: 'all_product',
  specific_products: 'specific_products',
  // specific_menus: 'specific_menus',
  specific_categories: 'specific_categories'
}

export const RULE_FILTER_OBJECTS = [
  {value: RULE_FILTER_OBJECT.all_product, label: 'promotion.mgt.rule_filter_obj_all_product'},
  {value: RULE_FILTER_OBJECT.specific_products, label: 'promotion.mgt.rule_filter_obj_specific_product'},
  // {value: RULE_FILTER_OBJECT.specific_menus, label: 'promotion.mgt.rule_filter_obj_specific_menus'},
  {value: RULE_FILTER_OBJECT.specific_categories, label: 'promotion.mgt.rule_filter_obj_specific_categories'}
]

export const RULE_FILTER_TYPE = {
  in_list: 'in_list',
  not_in_list: 'not_in_list'
}

export const RULE_FILTER_TYPES = [
  {value: RULE_FILTER_TYPE.in_list, label: 'promotion.mgt.in_list'},
  {value: RULE_FILTER_TYPE.not_in_list, label: 'promotion.mgt.not_in_list'}
]

export const RULE_CONDITION_TYPE = {
  subtotal: "subtotal",
  item_quantity: "item_quantity",
  line_item_count: "line_item_count",
  week_days: "week_days",
  month_days: "month_days",
  times: "times"
}

export const WEEK_DAYS = [
  {value: 'monday', label: 'promotion.mgt.mon'},
  {value: 'tuesday', label: 'promotion.mgt.tue'},
  {value: 'wednesday', label: 'promotion.mgt.wed'},
  {value: 'thursday', label: 'promotion.mgt.thu'},
  {value: 'friday', label: 'promotion.mgt.fri'},
  {value: 'saturday', label: 'promotion.mgt.sat'},
  {value: 'sunday', label: 'promotion.mgt.sun'}
];

export const MONTH_DAYS = [
  {value: '1', label: '1'},
  {value: '2', label: '2'},
  {value: '3', label: '3'},
  {value: '4', label: '4'},
  {value: '5', label: '5'},
  {value: '6', label: '6'},
  {value: '7', label: '7'},
  {value: '8', label: '8'},
  {value: '9', label: '9'},
  {value: '10', label: '10'},
  {value: '11', label: '11'},
  {value: '12', label: '12'},
  {value: '13', label: '13'},
  {value: '14', label: '14'},
  {value: '15', label: '15'},
  {value: '16', label: '16'},
  {value: '17', label: '17'},
  {value: '18', label: '18'},
  {value: '19', label: '19'},
  {value: '20', label: '20'},
  {value: '21', label: '21'},
  {value: '22', label: '22'},
  {value: '23', label: '23'},
  {value: '24', label: '24'},
  {value: '25', label: '25'},
  {value: '26', label: '26'},
  {value: '27', label: '27'},
  {value: '28', label: '28'},
  {value: '29', label: '29'},
  {value: '30', label: '30'},
  {value: '31', label: '31'}
];

export const DEFAULT_RULE_FILTER = {applyObject: RULE_FILTER_OBJECT.all_product, applyType: RULE_FILTER_TYPE.in_list, applyTo: []};
export const DEFAULT_RULE_DISCOUNT = {
  loop: false,
  minQuantity: 0,
  maxQuantity: 0,
  discountValue: 0,
  discountValueType: DISCOUNT_VALUE_TYPE.percentage,
  applyQuantity: 0,
  applyForProduct: ''
};
export const DEFAULT_RULE_CONDITION = {
  conditionType: RULE_CONDITION_TYPE.subtotal,
  methodType: CONDITION_METHOD_TYPE.greater_than,
  values: new Array(1),
  sValues: []
};

export const ORDER_ITEM_STATUS = {
  added: 'added',
  confirming: 'confirming',
  confirmed: 'confirmed',
  pending: 'pending',
  waiting: 'waiting',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled'
}

export const ORDER_ITEM_STATUSES = [
  {value: ORDER_ITEM_STATUS.pending, label:'promotion.mgt.order_item_status_pending'},
  {value: ORDER_ITEM_STATUS.waiting, label:'promotion.mgt.order_item_status_waiting'},
  {value: ORDER_ITEM_STATUS.processing, label:'promotion.mgt.order_item_status_processing'},
  {value: ORDER_ITEM_STATUS.completed, label:'promotion.mgt.order_item_status_completed'},
  {value: ORDER_ITEM_STATUS.canceled, label:'promotion.mgt.order_item_status_canceled'}
]

export const DROPDOWN_MULTI_STYLES = {
  control: styles => ({ ...styles, 
    backgroundColor: 'white', border: '1px solid #ced4da', borderRadius: '2px', padding: '0', minHeight: '34px',
    ':hover':{
      border: '1px solid #5aaf4b',
      boxShadow: '0 0 0 3px rgba(0,116,217,0.2)'
    }
  }),
  valueContainer: (style, {data}) => {return {...style, padding: '2px 5px'}},
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#5aaf4b',
      color: '#fff'
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#038459'
    },
  })
};

export const EVENT_TYPE = {
  challenge : 'challenge',
  camping: 'camping',
  phasing: 'phasing',
  conference: 'conference'
}

export const EVENT_TYPES = [
  {label: 'Challenge', value: EVENT_TYPE.challenge},
  {label: 'Camping', value: EVENT_TYPE.camping},
  {label: 'Phasing', value: EVENT_TYPE.phasing},
  {label: 'Conference', value: EVENT_TYPE.conference},
]

export const EVENT_SESSION_TYPE = {
  live : 'live',
  on_demand : 'on_demand',
}

export const EVENT_SESSION_TYPES = [
  {label: 'Live', value: EVENT_SESSION_TYPE.live},
  {label: 'On-demand', value: EVENT_SESSION_TYPE.on_demand},
]

export const EVENT_CONFERENCE_TYPE = {
  zoom : 'zoom',
}

export const EVENT_CONFERENCE_TYPES = [
  {label: 'Zoom', value: EVENT_CONFERENCE_TYPE.zoom},
]

export const EVENT_ZOOM_MEETING_TYPE = [
  {label: "Instant Meeting", value: 1},
  {label: "Schedule Meeting", value: 2},
  {label: "Webinar", value: 5}
]

export const EVENT_SESSION_PROPERTY_TYPE = {
  free_text : 'free_text',
  predefined_list : 'predefined_list',
}

export const EVENT_SESSION_PROPERTY_TYPES = [
  {label: 'Free Text', value: EVENT_SESSION_PROPERTY_TYPE.free_text},
  {label: 'Predefined List', value: EVENT_SESSION_PROPERTY_TYPE.predefined_list},
]

export const RANGE_BIRTHDAY = (new Date().getFullYear() - 100) + ':' + (new Date().getFullYear() + 5)

export function translateListSimple(trans, list){
  const result = [...list];
  result.forEach(i => {
    i.label = trans(i.label);
  });
  return result;
}

export function translateItemListSimple(trans, list, value){
  const item = list.find(i => i.value===value);
  return item?trans(item.label):value;
}

export const LIVE_ACCOUNT_TYPES  = [
  {label: 'Zoom', value: 'zoom'}
]

export const PARTICIPANT_STATUS = {
  pending: {value: 'pending', label: 'Pending'},
  processing: {value: 'processing', label: 'Processing'},
  completed: {value: 'completed', label: 'Completed'},
  canceled: {value: 'canceled', label: 'Canceled'}
}

export const PASSWORD_RESET_MODE = {
  resetPassword: {value: 'resetPassword', label: 'Send New Password'},
  confirmResetPassword: {value: 'confirmResetPassword', label: 'Send Reset Password Link'}
}

export const PAGE_PREDEFINED_URL_OPTIONS = [
  {label: 'Home (/)', value: "/"},
  {label: 'Products (/bookings)', value: "/bookings"},
  // {label: 'Speakers (/speakers)', value: "/speakers"},
  {label: 'Application logs (/app)', value: "/app"},
]

export const FAQ_STATUS = {
  new: 'new_faq',
  viewed: 'viewed',
  declined: 'declined'
}

export const FAQ_STATUSED = [
  {value: 'new_faq', label: 'NEW'},
  {value: 'viewed', label: 'VIEWED'},
  {value: 'declined', label: 'DECLINED'}
]

export const FAQ_REPLY_STATUS = {
  not_answered: 'Not Answered',
  answered: 'Answered',
  declined: 'Declined'
}

export const PRODUCT_IMAGE_TYPE = {
  teaser: 'teaser',
  gallery: 'gallery',
}

export const ATTRIBUTE_GROUP_TYPE = {
  personal: 'personal',
  custom_field: 'custom_field',
  waiver: 'waiver',
  program: 'program',
  newsletter: 'newsletter',
  survey: 'survey'
}

export const ATTRIBUTE_GROUP_TYPES = [
  {label: 'Personal', value: ATTRIBUTE_GROUP_TYPE.personal},
  {label: 'Custom field', value: ATTRIBUTE_GROUP_TYPE.custom_field},
  {label: 'Waiver', value: ATTRIBUTE_GROUP_TYPE.waiver},
  {label: 'Program attribute', value: ATTRIBUTE_GROUP_TYPE.program},
  {label: 'Newsletter', value: ATTRIBUTE_GROUP_TYPE.newsletter},
  {label: 'Survey', value: ATTRIBUTE_GROUP_TYPE.survey}
];

export const ATTRIBUTE_VALUE_LIST_TYPE = {
  normal: 'normal',
  skill_level: 'skill_level'
}

export const ATTRIBUTE_VALUE_LIST_OPTIONS = [
  {value: 'normal', label: 'Normal'},
  {value: 'skill_level', label:'Skill'}
]

export const ATTRIBUTE_FIELD_SET_TYPE = {
  personal: 'personal',
  custom_field: 'custom_field',
  waiver: 'waiver',
  program: 'program',
  newsletter: 'newsletter',
  survey: 'survey',
}

export const PRODUCT_OPTION_TYPE = {
  variant: {value: 'variant', label: 'Variant'},
  properties: {value: 'properties', label: 'Properties'}
}

export const COMPANY_OPTIONS = [
  {value: 'Bike Related Businesses', label: 'Bike Related Businesses'},
  {value: 'Education', label:'Education'},
  {value: 'For Profits', label: 'For Profits'},
  {value: "Non-Profits\\NGO", label: "Non-Profits\\NGO"},
  {value: 'Public Agencies', label: 'Public Agencies'}
]

export const TEAM_SECURITY_OPTIONS = [
  {value: 0, label: 'Public'},
  {value: 1, label: 'Security with key'}
]

export const TEAM_SECURITY_TYPES = {
  public: 'Public',
  secWithKey: 'Security with key'
}

export const TEAM_ROLE_OPTIONS = [
  {value: 'Captain', label: 'Captain'},
  {value: 'Vice Captain', label: 'Vice Captain'}
]

export const TEAM_ROLE_TYPES = {
  captain: 'Captain',
  viceCaptain: 'Vice Captain',
  member: 'Member'
}

export const PROGRAM_WORK_TYPE = {
  simple: 'simple',
  dw: 'dw',
}

export const PROPERTY_TYPE = {
  asset: {value: 'asset', label: 'Asset'},
  branch: {value: 'branch', label: 'Branch'},
  location: {value: 'location', label: 'Location'},
  warehouse: {value: 'warehouse', label: 'Warehouse'},
  property: {value: 'property', label: 'Property'},
  equipment: {value: 'equipment', label: 'Equipment'},
  storage: {value: 'storage', label: 'Storage'},
  bus_route: {value: 'bus_route', label: 'Bus Route'}
}

export const PROPERTY_LOCATION_TYPES = [
  PROPERTY_TYPE.branch,
  PROPERTY_TYPE.location
]

export const PROPERTY_ASSET_TYPES = [
  PROPERTY_TYPE.equipment,
  PROPERTY_TYPE.storage
]

export const HR_RESOURCE_TYPE = {
  marshaller: {value: 'marshaller', label: 'Marshaller'},
  speaker: {value: 'speaker', label: 'Speaker'},
  trainer: {value: 'trainer', label: 'Trainer'},
}

export const OBJECT_TYPE = {
  program: 'program',
  asset: 'asset',
  event: 'event'
}

export const OBJECT_TYPES = [
  {value: OBJECT_TYPE.program, label: 'Program'},
  {value: OBJECT_TYPE.asset, label: 'Asset'}
]

export const PAYMENT_STATUS = {
  pending: 'pending',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled'
};

export const ASSET_STATUS = {
  in_stock: {label: "In Stock", value: "in_stock"},
  out_stock: {label: "Out Stock", value: "out_stock"}
}

export const ASSET_STATUSES = [
  ASSET_STATUS.in_stock,
  ASSET_STATUS.out_stock
]

export const ASSET_SCHEDULE_STATUS = {
  pending: {label: "Pending", value: 'pending'},
  processing: {label: "Processing", value: 'processing'},
  in_scheduled: {label: "In Scheduled", value: 'in_scheduled'},
  using: {label: "Using", value: "using"},
  over_due: {label: "Over Due", value: "over_due"},
  ended: {label: "Ended", value: "ended"},
}

export const ASSET_SCHEDULE_STATUSES = [
  ASSET_SCHEDULE_STATUS.in_scheduled,
  ASSET_SCHEDULE_STATUS.using,
  ASSET_SCHEDULE_STATUS.over_due,
  ASSET_SCHEDULE_STATUS.ended
]

export const TAXONOMY_TYPE = {
  product: {value: 'product', label: 'Product'},
  category: {value: 'category', label: 'Category'},
  branch: {value: 'branch', label: 'Branch'},
  location: {value: 'location', label: 'Location'},
  asset: {value: 'asset', label: 'Asset'},
};

export const TAXONOMY_CATEGORY_TYPES = [
  TAXONOMY_TYPE.product,
  TAXONOMY_TYPE.asset,
]

export const APPLICATION_TYPE = {
  // sea_log: {value: 'sea_log', label: 'Sea Log'},
  normal: {value: 'normal', label: 'Normal'},
  approval: {value: 'approval', label: 'Approval'},
  answer: {value: 'answer', label: 'Answer'},
}

export const APPLICATION_OPTIONS = [
  APPLICATION_TYPE.sea_log
]

export const ASSET_TYPE = {
  equipment: {value: 'equipment', label: 'Equipment'},
  storage: {value: 'storage', label: 'Storage'},
}

export const TIME_SERIES_REPEAT_TYPE = {
  daily: 'daily',
  weekDay: 'weekDay'
}

export const SCHEDULE_TYPE = {
  inventory: {label: 'Inventory Schedule', value: 'inventory_schedule'},
  asset: {label: 'Asset Schedule', value: 'asset_schedule'}
}

export const PRICING_MODE = {
  normal: {label: 'Price without tax', value: 'normal'},
  included_tax: {label: 'Price included tax', value: 'included_tax'}
}

export const PAYMENT_MODE = {
  pre_pay: {label: 'Pre pay', value: 'pre_pay'},
  post_pay: {label: 'Post pay', value: 'post_pay'}
}

export const TIME_UNIT_TYPE = {
  min: {value: 'minute', label: 'Minute'},
  hr: {value: 'hours', label: 'Hours'}
}

export const APPLICATION_COMPONENTT_TYPE = {
  free_text: {value: 'free_text', label: 'Free Text', },
  input_field: {value: 'input_field', label: 'Input Field'}
}

export const SLOT_REQUIREMENT_TYPE = {
  full: {value: 'full', label: 'Full', },
  particle: {value: 'particle', label: 'Particle'}
}

export const UPLOAD_TYPE = {
  upload_file: {value: 'upload_file', label: 'Upload File', },
  external_url: {value: 'external_url', label: 'Link'}
}

export const APPLICATION_RECORD_STATUS = {
  pending: {value: 'pending', label: 'Pending'},
  approved: {value: 'approved', label: 'Approved'},
  rejected: {value: 'rejected', label: 'Rejected'}
}

export const APPLICATION_VALUE_TYPE = {
  user_classification: {value: 'user_classification', label: 'User Classification'},
  app_record_status: {value: 'app_record_status', label: 'App Record'},
}

export const GROUP_USER_TYPE = {
  household: {value: 'household', label: 'household'},
  company: {value: 'company', label: 'Company'},
}