import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export class BudgetServices {
    getPageBudgets(page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/budgets/page`, {
            params: {page, size, sort }
        }).then(res => res.data).catch(error => console.log(error));
    }

    getTreeBudgets(){
        return axios.get(`${API_BASE_URL}/scp/api/budgets/tree`).then(res => res.data).catch(error => console.log(error));
    }

    getListBudgetOptionGroups(targetType, targetId, parentTargetId){
        return axios.get(`${API_BASE_URL}/scp/api/budgets/list-option-group`, {
            params: {targetType, targetId, parentTargetId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getBudget(id){
        return axios.get(`${API_BASE_URL}/scp/api/budgets/${id}`).then(res => res.data).catch(error => console.log(error));
    }

    saveBudget(form){
        return axios.post(`${API_BASE_URL}/scp/api/budgets`, form).then(res => res.data).catch(error => console.log(error));
    }

    removeBudget(id){
        return axios.delete(`${API_BASE_URL}/scp/api/budgets/${id}`).then(res => res.data).catch(error => console.log(error));
    }
}