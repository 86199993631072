import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";
import { converObjetToFormData } from "../../core/service/CommonService";
const configFormDataHeader = {
  headers: { 'Content-Type': 'multipart/form-data' }
}

const SERVICE_BASE_URL = API_BASE_URL + SCP_PATH; 

export function getPageHRs(filter, page, size, sortField, sortOrder){
  const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
  const sort = sortField ? `${sortField},${order}`: "";
  return axios.get(`${SERVICE_BASE_URL}/human-resources/page`,{
      params: {page, size, sort, ...filter}
  })
  .then(res => res.data).catch(error => console.log(error));
}

export function getListHRs(filter, simple=true) {
  return axios.get(`${SERVICE_BASE_URL}/human-resources/list`, {params: {...filter, simple}}
  ).then(res => res.data).catch(error => console.log(error));
}

export function getHRDetail(id) {
  return axios.get(`${SERVICE_BASE_URL}/human-resources/${id}`)
  .then(res => res.data).catch(error => console.log(error));
}

export function saveHR(data){
  let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }
  return axios.post(`${SERVICE_BASE_URL}/human-resources/`, formData, configFormDataHeader).then(res => res.data).catch(error => console.log(error));
}

export function removeHR(id){
  return axios.delete(`${SERVICE_BASE_URL}/human-resources/${id}`).then(res => res.data).catch(error => console.log(error));
}