import React, {Component} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import lazyLoad from "../../core/components/lazyLoad";

const EventListView = lazyLoad(() => import('./EventListView'));
const EventDetails = lazyLoad(() => import('./EventDetails'));
const EventTicketDetails = lazyLoad(() => import('./EventTicketDetails'));

export default class EventManagement extends Component{
    render(){
        const baseUrl = this.props.match.url;

        return (
            <Switch>
                <Route path={`${baseUrl}/:id/ticket/:tid`} component={EventTicketDetails} />
                <Route path={`${baseUrl}/:id`} component={EventDetails} />
                <Route path={`${baseUrl}`} component={EventListView} />
                <Redirect from='*' to='/404' />
            </Switch>
        );
    }
}