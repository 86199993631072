import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { Fieldset } from "primereact/fieldset";
import { Password } from "primereact/password";
import { titleCaseText } from "../../core/service/CommonService";
import { getAttributeSet } from "../../scp/attribute/AttributeServices";
import { Dropdown } from "primereact/dropdown";
import { GENDERS, GROUP_USER_TYPE, RANGE_BIRTHDAY } from "../../constants";
import moment from "moment";
import { getCountries, getStatesByCountry } from "../../scp/tenant-configuration/CountrySevices";
import PhoneInput from "react-phone-number-input";
import { RadioButton } from "primereact/radiobutton";
import { getListGroups } from "../group/GroupServices";
import { getConfig } from "../../scp/tenant-configuration/ConfigruationServices";
import { saveUser } from "./UserServices";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";

export const ContactForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Contact")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({personalInfo: {}})
    const [personalSet, setPersonalSet] = useState({})
    const [countries, setCountries] = useState([])
    const [countryStates, setCountryStates] = useState([])
    const [groups, setGroups] = useState([])
    const [birthdayValue, setBirthdayValue] = useState(null)
    const [errors, setErrors] = useState({})

    let defaultCountry = localStorage.getItem('defaultCountry')
    defaultCountry = defaultCountry && defaultCountry!=='null' && defaultCountry!=='undefined' ? defaultCountry : null
    
    useImperativeHandle(ref, () => ({
        openForm(e){
            loadCountries()
            loadPersonalSet()
            loadGroups()
            setFrmData(popularFormData(e))
            setBirthdayValue(null)
            setVisible(true)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            username: '',
            password: '',
            email: '',
            secondaryEmail: '',
            groupAct: 0,
            groupId: null,
            groupType: 'household',
            personalInfo: {}
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const handleSave = () => {
        setErrors({})

        saveUser(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()
                if(props.contact)
                    props.contact(res)

                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        });
    }

    const loadCountries = () => {
        getCountries().then(res => setCountries(res?res:[]))
    }

    const loadCountryStates = (countryCode) => {
        getStatesByCountry(countryCode?countryCode:defaultCountry).then(res => setCountryStates(res?res:[]))
    }

    const loadGroups = () => {
        getListGroups().then(res => setGroups(res?res:[]))
    }

    async function loadPersonalSet(){
        let tmpConfig = await getConfig()
        
        if(tmpConfig && tmpConfig.accountConfig && tmpConfig.accountConfig.personalSetId){
            getAttributeSet(tmpConfig.accountConfig.personalSetId)
            .then(res => {
                setPersonalSet(res&&res.attributeSet?res.attributeSet:{})
            })
        }
    }

    const setPersonalInfo = (property, value) => {
        if(property==='countryCode'){
            setFrmData({...frmData,
                personalInfo: {...frmData.personalInfo,
                    countryCode: value,
                    stateCode: null
                }
            })
            if(value)
                loadCountryStates(value)
            else setCountryStates([])
        }else{
            setFrmData({...frmData,
                personalInfo: {...frmData.personalInfo,
                    [property]: property==='birthday'?(value&&moment(value).isValid()?moment(value).format(moment.HTML5_FMT.DATE):''):value
                }
            })
        }
        
        if(property==='birthday'){
            setBirthdayValue(value)
        }
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-lg" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <Fieldset legend="Account Information">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">Username</label>
                                <InputText value={frmData.username} keyfilter={/[^\s]/} onChange={(e)=> setFrmData({...frmData, username:e.target.value})}/>
                                <span className="p-form-error">{errors.username}</span>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">{frmData.username&&'*'} Password</label>
                                <Password value={frmData.password} onChange={(e)=> setFrmData({...frmData, password: e.target.value})}/>
                                <span className="p-form-error">{errors.password}</span>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">{personalSet.personalField&&personalSet.personalField.useEmail&&personalSet.personalField.requiredEmail&& '*'} Primary Email</label>
                                <InputText value={frmData.personalInfo&&frmData.personalInfo.email?frmData.personalInfo.email:''} keyfilter="email" onChange={(e)=> setPersonalInfo('email', e.target.value)}/>
                                <span className="p-form-error">{errors.email}</span>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">Secondary Email</label>
                                <InputText value={frmData.secondaryEmail} keyfilter="email" onChange={(e)=> setFrmData({...frmData, secondaryEmail:e.target.value})}/>
                                <span className="p-form-error">{errors.secondaryEmail}</span>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Basic Information">
                        <div className="p-grid">
                            {(()=>{
                                let personalField = personalSet.personalField?personalSet.personalField:{}
                                let personalInfo = frmData.personalInfo?frmData.personalInfo:{}

                                return <React.Fragment>
                                    {personalField.useTitleName &&
                                        <div className="p-col-12 p-md-3">
                                            <label className="p-label">{personalField.requiredTitleName && '*'} {personalField.titleNameLabel}</label>
                                            {(()=>{
                                                let tmpTitleArr = personalField.titleValue?personalField.titleValue.map(value=>{return{value:value, label: value}}):[]
                                                return <Dropdown value={personalInfo.title} options={tmpTitleArr} onChange={(e) => setPersonalInfo('title', e.value)} />
                                            })()}
                                            <div className="p-form-error">{errors.title}</div>
                                        </div>
                                    }
                                    <div className="p-col-12 p-md-3">
                                        <label className="p-label">* {personalField.firstNameLabel?personalField.firstNameLabel:'First Name'}</label>
                                        <InputText value={personalInfo.firstName?personalInfo.firstName:''} onChange={(e) => setPersonalInfo('firstName', titleCaseText(e.target.value))}/>
                                        <span className="p-form-error">{errors.firstName}</span>
                                    </div>
                                    {personalField.useMiddleName &&
                                        <div className="p-col-12 p-md-3">
                                            <label className="p-label">{personalField.requiredMiddleName && '*'} {personalField.middleNameLabel}</label>
                                            <InputText value={personalInfo.middleName?personalInfo.middleName:''} onChange={(e) => setPersonalInfo('middleName', e.target.value)} />
                                            <div className="p-form-error">{errors.middleName}</div>
                                        </div>
                                    }
                                    <div className="p-col-12 p-md-3">
                                        <label className="p-label">* {personalField.lastNameLabel?personalField.lastNameLabel:'Last Name'}</label>
                                        <InputText value={personalInfo.lastName?personalInfo.lastName:''} onChange={(e)=> setPersonalInfo('lastName', titleCaseText(e.target.value))}/>
                                        <span className="p-form-error">{errors.lastName}</span>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            {personalField.useGender &&
                                                <div className="p-col-12 p-md-4">
                                                    <label className="p-label">{personalField.requiredGender && '*'} {personalField.genderLabel}</label>
                                                    <Dropdown value={personalInfo.gender} options={GENDERS} onChange={(e) => setPersonalInfo('gender', e.value)} />
                                                    <div className="p-form-error">{errors.gender}</div>
                                                </div>
                                            }
                                            {personalField.useBirthday &&
                                                <div className="p-col-12 p-md-4">
                                                    <label className="p-label">{personalField.requiredBirthday && '*'} {personalField.birthdayLabel}</label>
                                                    <MaskedCalendar value={birthdayValue} onChange={(e) => setPersonalInfo('birthday', e.value)} showIcon={true} monthNavigator={true} yearNavigator={true} yearRange={RANGE_BIRTHDAY} dateFormat="mm-dd-yy" mask="99-99-9999" />
                                                    <div className="p-form-error">{errors.birthday}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {personalField.usePhone &&
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-4">
                                                    <label className="p-label">{personalField.requiredPhone && '*'} {personalField.phoneLabel}</label>
                                                    <PhoneInput value={personalInfo.phone} defaultCountry={defaultCountry} onChange={(e) => setPersonalInfo('phone', e)} className="p-component p-inputtext p-phoneinput" style={{width: "100%"}}/>
                                                    <div className="p-form-error">{errors.phone}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {personalField.usePassportNRIC &&
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">{personalField.requiredPassportNRIC && '*'} {personalField.passportNRICLabel}</label>
                                            <InputText value={personalInfo.passportNRIC?personalInfo.passportNRIC:''} onChange={(e) => setPersonalInfo('passportNRIC', e.target.value)} />
                                            <div className="p-form-error">{errors.passportNRIC}</div>
                                        </div>
                                    }
                                    {personalField.useNationality &&
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">{personalField.requiredNationality && '*'} {personalField.nationalityLabel}</label>
                                            <Dropdown value={personalInfo.nationalityCode} options={countries} filter onChange={(e) =>setPersonalInfo('nationalityCode', e.value)} />
                                            <div className="p-form-error">{errors.nationality}</div>
                                        </div>
                                    }
                                    {personalField.useRegisterType &&
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{personalField.requiredRegisterType && '*'} {personalField.registerTypeLabel}</label>
                                            {(()=>{
                                                let valueList = personalField.registerTypeList?personalField.registerTypeList:[]
                                                valueList.map(value => {return {value: value, label: value}})
                                                return <Dropdown value={personalInfo.registerType} options={valueList} onChange={(e) => setPersonalInfo('registerType', e.value)} />
                                            })()}
                                            <div className="p-form-error">{errors.registerType}</div>
                                        </div>
                                    }
                                </React.Fragment>
                            })()}
                        </div>
                    </Fieldset>
                </div>
                {(()=>{
                    let personalField = personalSet.personalField?personalSet.personalField:{}
                    let personalInfo = frmData.personalInfo?frmData.personalInfo:{}

                    if(personalField.useEmerContact){
                        return <div className="p-col-12">
                                <Fieldset legend="Emergency Contact">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{personalField.requiredEmerContact && '*'} {personalField.emerContactNameLabel}</label>
                                            <InputText value={personalInfo.emeContactName?personalInfo.emeContactName:''} onChange={(e) => setPersonalInfo('emeContactName', e.target.value)} />
                                            <div className="p-form-error">{errors.emeContactName}</div>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{personalField.requiredEmerContact && '*'} {personalField.emerContactPhoneLabel}</label>
                                            <PhoneInput value={personalInfo.emeContactPhone} defaultCountry={defaultCountry} onChange={(e) => setPersonalInfo('emeContactPhone', e)} className="p-inputtext p-comment p-phoneinput" style={{width: "100%"}}/>
                                            <div className="p-form-error">{errors.emeContactPhone}</div>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{personalField.requiredEmerContact && '*'} {personalField.emerContactEmailLabel}</label>
                                            <InputText keyfilter="email" value={personalInfo.emeContactEmail?personalInfo.emeContactEmail:''} onChange={(e) => setPersonalInfo('emeContactEmail', e.target.value)} />
                                            <div className="p-form-error">{errors.emeContactEmail}</div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                    }
                })()}
                {(()=>{
                    let personalField = personalSet.personalField?personalSet.personalField:{}
                    let personalInfo = frmData.personalInfo?frmData.personalInfo:{}
                    
                    if(personalField.useAddress){
                        return <div className="p-col-12">
                            <Fieldset legend="Address Option">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">* Street 1</label>
                                                <InputText value={personalInfo.address1?personalInfo.address1:''} onChange={(e)=> setPersonalInfo('address1', e.target.value)}/>
                                                <span className="p-form-error">{errors.address1}</span>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">Street 2</label>
                                                <InputText value={personalInfo.address2?personalInfo.address2:''} onChange={(e)=> setPersonalInfo('address2', e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">* City</label>
                                                <InputText value={personalInfo.city?personalInfo.city:''} onChange={(e)=> setPersonalInfo('city', e.target.value)}/>
                                                <span className="p-form-error">{errors.city}</span>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">Country</label>
                                                <Dropdown options={countries} value={personalInfo.countryCode} filter={true} onChange={(e)=> setPersonalInfo('countryCode', e.value)} style={{width: "100%"}} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">* Zip Code</label>
                                                <InputText value={personalInfo.zipCode?personalInfo.zipCode:''} onChange={(e)=> setPersonalInfo('zipCode', e.target.value)}/>
                                                <span className="p-form-error">{errors.zipCode}</span>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">State/Province</label>
                                                <Dropdown value={personalInfo.stateCode} options={countryStates} filter onChange={(e)=> setPersonalInfo('stateCode', e.value)} />
                                                <span className="p-form-error">{errors.state}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    }
                    
                })()}
                <div className="p-col-12">
                    <Fieldset legend="Household Options">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12 p-md-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        <RadioButton inputId="rbGroupAct0" value={0} name="groupAct" onChange={e => setFrmData({...frmData, groupAct: e.value})} checked={frmData.groupAct===0} />
                                        <label htmlFor="rbGroupAct0" className="p-margin-right-30">None</label>
                                        <RadioButton inputId="rbGroupAct1" value={1} name="groupAct" onChange={e => setFrmData({...frmData, groupAct: e.value })} checked={frmData.groupAct===1} />
                                        <label htmlFor="rbGroupAct1" className="p-margin-right-30">Add to existing Household/Group</label>
                                        <RadioButton inputId="rbGroupAct2" value={2} name="groupAct" onChange={e => setFrmData({...frmData, groupAct: e.value })} checked={frmData.groupAct===2} />
                                        <label htmlFor="rbGroupAct2">Create Household/Group</label>
                                    </div>
                                    {frmData.groupAct===1 &&
                                        [
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">* Household/Group</label>
                                                <Dropdown options={groups} value={frmData.groupId} onChange={(e) => setFrmData({...frmData, groupId: e.value})} placeholder="Select household/group"/>
                                                <span className="p-form-error">{errors.groupId}</span>
                                            </div>
                                        ]
                                    }
                                    {frmData.groupAct===2&&
                                        [
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">* Name of Household/Group</label>
                                                <InputText value={frmData.groupName} onChange={(e) => setFrmData({...frmData, groupName: titleCaseText(e.target.value)})}/>
                                                <span className="p-form-error">{errors.groupName}</span>
                                            </div>,
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">Type</label>
                                                <Dropdown options={Object.values(GROUP_USER_TYPE)} value={frmData.groupType} onChange={(e) => setFrmData({...frmData, groupType: e.value})} placeholder="Select type"/>
                                                <span className="p-form-error">{errors.groupType}</span>
                                            </div>
                                        ]
                                    }
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()}/>
                </div>
            </div>
        </Sidebar>
    )
})
