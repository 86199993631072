import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Spinner } from 'primereact/spinner';
import { SplitButton } from 'primereact/splitbutton';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { render } from 'react-dom';
import { Link } from 'react-router-dom';
import { APP_FEP, ASSET_SCHEDULE_STATUS, ASSET_SCHEDULE_STATUSES, ASSET_STATUS, ASSET_STATUSES, SCHEDULE_TYPE, TABLE_PAGING_LENGTH } from '../../constants';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { titleCaseText } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { AssetSchedules } from '../asset-schedule/AssetSchedules';
import { AssetForm } from '../asset/AssetForm';
import { getListAssets, getPageAssets, removeAsset } from '../asset/AssetServices';
import { getInventory, saveInventory } from './InventoryService';
import InventoryForm from './InventoryForm';

const InventoryDetailView = (props) => {
    const frmInventory = useRef(null);
    const frmAsset = useRef(null);
    const deleteNotify = useRef(null);

    const [data, setData] = useState({});
    const [filter, setFilter] = useState({ application: APP_FEP });
    const [pageable, setPageable] = useState({
        page: 0, total: 0, rows: 10, sortField: '', sortOrder: 0
    });
    const [scheduleFilter, setScheduleFilter] = useState({});

    const [assets, setAssets] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [reload, setReload] = useState(false);
    const [assetOptions, setAssetOptions] = useState([]);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        loadInventory();
    }, [])

    useEffect(() => {
        loadPageAssets();
    }, [filter.inventoryId, pageable.page, pageable.sortField, pageable.sortOrder])

    useEffect(() => {
        if(reload) {
            loadPageAssets();
        }
    }, [reload])

    useEffect(() => {
        if(data.id) {
            loadAssets();
        }
    }, [data.id]) 

    const loadInventory = () => {
        getInventory(props.match.params.id)
        .then(data => {
            if(!data.errorCode) {
                setData(data);
                setFilter({
                    ...filter,
                    inventoryId: data ? data.id : ''
                });
                setScheduleFilter({
                    ...scheduleFilter,
                    inventoryId: data ? data.id : ''
                })
            }
        })
    }

    const loadPageAssets = () => {
        showloading()
        getPageAssets(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            stoploading();
            setAssets(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setReload(false)
        }).finally(stoploading())
    };

    const loadAssets = () => {
        getListAssets({inventoryId: data.id}, true)
        .then(data => {
            if(data && Array.isArray(data)) {
                setAssetOptions(data)
            }
        })
    }

    const handleSaveInventory = () => {
        saveInventory(data)
        .then(data => {
            if(!data.errorCode) {
                showSuccessNotify("Action Submitted", "Success");
                setData(data);
            } else {
                showErrorNotify("Cannot perform the action", "Error");
            }
        })
    }

    const renderStatus = (rowData) => {
        if(rowData.status) {
            return ASSET_STATUS[rowData.status].label;
        }
    }

    const assetActionTemplate = (rowData) => {
        let dropActions = [
            { label: 'Delete Asset', icon: 'pi-md-trash',
                command: (e) => deleteNotify.current.confirm(rowData.id)
            }
        ];

        return (
            <SplitButton label="View" className="p-button-constrast p-l" model={dropActions} onClick={() => onAddOrEditAsset(rowData)}/>
        );
    }

    const onAddOrEditAsset = (data) => {
        props.history.push(`/assets-mgt/${data.id}`)
    }

    const deleteAsset = (id) => {
        removeAsset(id)
        .then(data => {
            if(!data.errorCode) {
                showSuccessNotify("Action Submitted", "Success");
                loadPageAssets();
            }
        })
    }

    const onPage = (e) => {
        setPageable({...pageable, page: e.value})
    }

    const onSort = (e) => {
        setPageable({
            ...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
    }

    const applyFilter = () => {
        setPageable({...pageable, page: 0});
        setReload(true);
    }

    const clearFilter = () => {
        setFilter({application: APP_FEP, inventoryId: data.id});
        setPageable({...pageable, page: 0});
        setReload(true);
    }
    
    const clearScheduleFilter = () => {
        setScheduleFilter({
            inventoryId: data ? data.id :''
        })
    }

    const tableHeader = 
        <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
            <Button icon="pi pi-plus" label={"Create new asset"} className="p-button-constrast" style={{ float: "left" }} onClick={(e) => frmAsset.current.openForm({inventory: data})} />
            <div style={{ 'float': 'right' }}>
                Show: <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onPage(e.value)} />
                <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => loadPageAssets()}/>
            </div>
            <h3 className="fle-datatable-header-title">Asset Listing</h3>
        </div>

    const footer =
        <React.Fragment>
            <Button label="Save Basic Info" icon="pi pi-save" onClick={() => handleSaveInventory()} />          
        </React.Fragment>

    return(
        <div className="p-grid">
            <div>
                <BreadcrumbsItem to={`/inventories`}>Inventories</BreadcrumbsItem>
                <BreadcrumbsItem to={props.match.url}>{data.name}</BreadcrumbsItem>
            </div>       

            <ConfirmNotification ref={deleteNotify}
                submitConfirm={(id) => deleteAsset(id)}
                message="Are you sure you want to remove?"
            />

            <InventoryForm ref={frmInventory}
                refresh={(data) => setData(data)}
            />

            <AssetForm ref={frmAsset}
                reloadTable={() => loadPageAssets()}
            />
            
            <div className="p-col-12">
            <div className="card card-w-title">
                <h1>
                    {data.code} - {data.name}
                    <div className="p-toolbar-group-right">
                        <Link to={`/inventories`}>
                            <Button icon="pi pi-list" className="p-button-icon" tooltip="Back To List" tooltipOptions={{position: 'top'}}></Button>
                        </Link>
                        <Button icon="pi pi-pencil" className="p-button-icon" tooltip="Edit Inventory" tooltipOptions={{position: 'top'}} onClick={() => frmInventory.current.openForm(data)}></Button>
                    </div>
                </h1>
                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-3">Branch: {data.branch && data.branch.name}</div>
                    <div className="p-col-12 p-md-3">Status: {data.active?'Active':'Inactive'}</div>
                </div>
            </div>
            </div>
            <div className="p-col-12">
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)} >
                <TabPanel
                    header={'Assets'}
                    leftIcon={'pi pi-sitemap'}
                >
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Filters">
                            <div className="p-grid p-fluid">

                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label>Equipment Status</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ASSET_STATUSES} value={filter.status} onChange={(e) => setFilter({...filter, status: e.value})} />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label>Schedule Status</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ASSET_SCHEDULE_STATUSES} value={filter.scheduleStatus} onChange={(e) => setFilter({...filter, scheduleStatus: e.value})} />
                                    </div>
                                </div>
                            </div> */}

                            </div>

                            <div className="p-grid">
                                <div className="p-col-12" style={{ textAlign: "center" }}>
                                    <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={applyFilter} />
                                    <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={clearFilter} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>

                    <div className="p-col-12">
                    <DataTable  lazy={true} header={tableHeader} paginator={true}
                            value={assets}
                            first={pageable.page * pageable.rows}
                            onPage={onPage} onSort={onSort}
                            rows={pageable.rows} totalRecords={pageable.total}
                            sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                            responsive={true}
                        >
                            <Column field="code" header="Code" />
                            <Column field="name" header="Name" />
                            <Column field="type" header="Type" />
                            <Column field="active" header="Active" body={(rowData) => {return( rowData.active ? 'Yes' : 'No' )}} />
                            <Column field="status" header="Status" body={(rowData) => renderStatus(rowData)} />
                            <Column header="Actions" body={assetActionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
                        </DataTable>
                    </div>
                </div>
                </TabPanel>

                <TabPanel header="Schedules" leftIcon="pi pi-calendar" >
                    <div className="p-col-12 p-margin-bottom-15">
                        <Fieldset legend="Filters">
                            <div className="p-grid p-fluid">

                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label>Equipment Status</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ASSET_STATUSES} value={scheduleFilter.assetStatus} onChange={(e) => setScheduleFilter({...scheduleFilter, assetStatus: e.value})} />
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label>Schedule Status</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={ASSET_SCHEDULE_STATUSES} value={scheduleFilter.status} onChange={(e) => setScheduleFilter({...scheduleFilter, status: e.value})} />
                                    </div>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <label>Asset</label>
                                    </div>
                                    <div className="p-col-12 p-md-9">
                                        <Dropdown options={assetOptions} value={scheduleFilter.assetId} onChange={(e) => setScheduleFilter({...scheduleFilter, assetId: e.value})} />
                                    </div>
                                </div>
                            </div>

                            </div>

                            <div className="p-grid">
                                <div className="p-col-12" style={{ textAlign: "center" }}>
                                    {/* <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={applyScheduleFilter} /> */}
                                    <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={clearScheduleFilter} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    <AssetSchedules type={SCHEDULE_TYPE.inventory.value} filter={scheduleFilter} inventoryId={props.match.params.id}/>
                </TabPanel>
            </TabView>
            </div>
        </div>
    )                                                       
}

export default InventoryDetailView;