import React, { Component } from 'react';
import {TreeTable} from 'primereact/treetable';
import {Column} from "primereact/column";
import {Button} from 'primereact/button';
import { GLAccountServices } from './GLAccountServices';
import { GLAccountForm } from './GLAccountForm';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { showNotification, showSuccessNotify, showErrorNotify } from '../../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { DeleteNotification } from '../../../core/components/DeleteNotification';

export class GLAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountNodes: [],
            accountList: [],
            expandedKeys: [],
            formDialogVisible: false,
            formDialogHeader: 'Add Account',
            page: 0,
            rows: 10,
            loading: true,
            sortField: "",
            sortOrder: 0,
        };

        this.accountServices = new GLAccountServices();
    }

    componentDidMount() {
        this.getTreeAccount();
    }

    getTreeAccount = () => {
        this.accountServices.getTreeAccount().then(res => {
            this.setState({
                accountNodes: res,
                loading: false
            })
        });
    }

    actionTemplate = (node) => {
        let dropActions = [
            {
                label: 'Delete Account',
                icon: 'pi-md-close',
                command: () => {
                    this.deleteNotify.delete(node.data.id);
                }
            }
        ];
        return <SplitButton label="Manage" className="p-button-constrast" model={dropActions} tooltip={'Edit Account'} tooltipOptions={{position: "top"}} onClick={() => this.addOrEditAccount(node.data)}/>
    }

    addOrEditAccount = (data) => {
        this.setState({
            formDialogHeader: data ? 'Edit Account' : 'Add Account',
            formDialogVisible: true
        });
        this.frmGlAccount.popularFormData(data);
        this.frmGlAccount.getAccountList(data?data.id:null);
    }

    removeAccount = (id) => {
        this.accountServices.removeAccount(id).then(res => {
            if(!res.errorCode){
                this.getTreeAccount();
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify(res.errorMessage);
            }
        });
    }

    onPage = e => {
        this.setState(
            {
                loading: true,
                page: e.page
            },
            () => {
                this.getTreeAccount();
            }
        );
    };

    onSort = e => {
        this.setState(
            {
                loading: true,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            },
            () => {
                this.getTreeAccount();
            }
        );
    };

    applyFilter = () => {
        this.getTreeAccount(this.state.rows,this.state.page);
    };

    onChagePageLength = l => {
        this.setState(
            {
                rows: l,
                page: 0
            },
            () => {
                this.applyFilter();
            }
        );
    };

    render() {

        let tableLengthOptions = [
            {
                label: '5',
                command: () => {
                    this.onChagePageLength(5);
                }
            },
            {
                label: '10',
                command: () => {
                    this.onChagePageLength(10);
                }
            },
            {
                label: '25',
                command: () => {
                    this.onChagePageLength(25);
                }
            },
            {
                label: '50',
                command: () => {
                    this.onChagePageLength(50);
                }
            },
            {
                label: '100',
                command: () => {
                    this.onChagePageLength(100);
                }
            }
        ];

        let tblHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <Button icon="pi pi-plus" className="p-button-constrast" label="Create GL Account" style={{ 'float': 'left' }} onClick={() => this.addOrEditAccount()}/>
            {/* <Button icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: 'top'}} style={{'float':'right'}} onClick={() => this.getTreeAccount()}/> */}
            <div style={{ float: "right" }}>
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                            onClick={() => this.getTreeAccount()} model={tableLengthOptions}>
                    </SplitButton>
            </div>
            </div>;

        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>GL Accounts</BreadcrumbsItem>

                <GLAccountForm ref={(el) => this.frmGlAccount = el} header={this.state.formDialogHeader} visible={this.state.formDialogVisible}
                    hideFormDialog={() => this.setState({formDialogVisible:false})}
                    reloadTable={() => this.getTreeAccount()}/>

                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeAccount(e)}
                />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>GL Account Management</h1>
                        <TreeTable 
                            value={this.state.accountNodes} 
                            header={tblHeader} paginator={true} 
                            expandedKeys={this.state.expandedKeys}
                            first={this.state.page * this.state.rows}
                            onPage={this.onPage}
                            onSort={this.onSort}
                            rows={this.state.rows}
                            sortField={this.state.sortField}
                            sortOrder={this.state.sortOrder}
                            onToggle={(e) => this.setState({expandedKeys: e.value})} 
                            scrollable={true}
                            style={{marginTop: '.5em'}  }>
                            <Column field="id" header="Code" style={{width: '25em'}} expander/>
                            <Column field="name" header="Name" style={{width: '25em'}}/>
                            <Column field="typeName" header="Type" style={{width: '25em'}}/>
                            {/* <Column field="active" body={(node, column) => {return node.data.active===true?'Yes':'No'}} header="Active"/> */}
                            <Column body={this.actionTemplate} style={{textAlign:'center',width:'10em'}}/>
                        </TreeTable>
                    </div>
                </div>
            </div> 
        );
    }
}