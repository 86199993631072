import React, {Component} from 'react';
import moment from 'moment';
import { BudgetServices } from './BudgetServices';
import { DepartmentServices, getListDepartments } from '../../scp/departments/DepartmentServices';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { InputSwitch } from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';
import { showNotification } from '../../core/service/NotificationService';
import { BUDGET_TARGET_TYPE } from '../../constants';
import { replaceSpaceAndUpperCase } from "../../core/service/CommonService";
import { Checkbox } from "primereact/checkbox";
import { GLAccountServices } from '../../scp/accounting/gl_accounts/GLAccountServices';

export class BudgetForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            header: '',
            visible: false,
            budget: {},
            parent: null,
            departments: [],
            campaigns: [],
            availableAmount: 0,
            minDate: '',
            maxDate: '',
            startDateValue: '',
            endDateValue: '',
            errors: {}
        }

        this.budgetServices = new BudgetServices();
        this.departmentServices = new DepartmentServices();
        this.campaignServices = new CampaignServices();
        this.glAccountServices = new GLAccountServices();
    }

    componentDidMount(){
        this.getListDepartment();
        this.getListCampaign();
        this.getListGlAccounts();
    }

    getListGlAccounts = () => {
        this.glAccountServices.getAccountList('', 'Assistance')
        .then(res => {
            this.setState({glAccounts: res});
        });
    }

    getListDepartment = () => {
        getListDepartments()
        .then(res => {
            this.setState({departments: res});
        });
    }

    getListCampaign = () => {
        this.campaignServices.getListCampaign(true)
        .then(res => {
            this.setState({campaigns: res});
        });
    }

    openBudgetForm = (data, parent) => {
        this.setState({
            header: data?'Edit ' + (parent?'Child':'') + ' Fee Assistance': 'New ' + (parent?'Child':'') + ' Fee Assistance',
            visible: true,
            errors: {}
        });

        this.popularFormData(data, parent);
    }

    popularFormData = (data, parent) => {
        let targetType = null;
        let targetId = '';
        
        if(data){
            targetType = data.targetType;
            targetId = data.targetId;
        }else{
            if(parent){
                if(parent.targetType===BUDGET_TARGET_TYPE.department){
                    targetType = BUDGET_TARGET_TYPE.campaign;
                }
            }else{
                targetType = BUDGET_TARGET_TYPE.department;
            }
        }

        this.setState({
            parent: parent,
            budget: {
                id: data?data.id:'',
                parentId: parent?parent.id:'',
                name: data?data.name:'',
                description: data?data.description:'',
                code: data?data.code:'',
                type: data?data.type:parent?parent.type:'scholarship',
                typeName: data?data.typeName:'',
                glAccount: parent?parent.glAccount:data?data.glAccount:'',
                targetType: targetType,
                targetId: targetId,
                total: 0,
                thresholdAmount: data ? data.thresholdAmount : 0,
                startAt: data && data.startAt?data.startAt:(parent && parent.startAt ? parent.startAt : ''),
                endAt: data && data.endAt?data.endAt:(parent && parent.endAt ? parent.endAt : ''),
                allowNegativeBalance: data ? data.allowNegativeBalance : false,
                active: data?data.active:true
            },
            availableAmount: parent? parent.available : 0,
            minDate: parent && parent.startAt ? moment(parent.startAt, 'YYYY-MM-DD').toDate() : moment().subtract(1, 'days').toDate(),
            maxDate: parent && parent.endAt ? moment(parent.endAt, 'YYYY-MM-DD').toDate() : '',
            startDateValue: data && data.startAt ? moment(data.startAt, 'YYYY-MM-DD').toDate() : (parent && parent.startAt ? moment(parent.startAt, 'YYYY-MM-DD').toDate() : ''),
            endDateValue: data && data.endAt ? moment(data.endAt, 'YYYY-MM-DD').toDate() : (parent && parent.endAt ? moment(parent.endAt, 'YYYY-MM-DD').toDate() : '')
        });
    }

    closeForm = () => {
        this.setState({
            visible: false,
            errors: {}
        });

        this.popularFormData(null, null);
    }

    onStartDateChange = (e) => {
        let tmpBudget = {...this.state.budget};

        tmpBudget.startAt = e.value?moment(e.value).format('YYYY-MM-DD'):'';

        let tmpStart = moment(e.value).format('MM-DD-YYYY');
        let tmpEnd = this.state.endDateValue;

        if(tmpStart!=='Invalid date'){
            let tmpEndDate = tmpEnd?moment(tmpEnd, 'MM-DD-YYYY'):'';

            if(!tmpEndDate || tmpEndDate==='Invalid date'){
                tmpEndDate = moment(e.value, 'MM-DD-YYYY');
                tmpEndDate.set({date: (tmpEndDate.date() - 1)});
                tmpEndDate.set({year: (tmpEndDate.year() + 1)});

                tmpEnd = tmpEndDate.toDate();
                tmpBudget.endAt = moment(tmpEnd).format('YYYY-MM-DD');
            }
        }

        this.setState({
            budget: tmpBudget,
            startDateValue: e.value,
            endDateValue: tmpEnd
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            budget: {...this.state.budget, endAt: (e.value ? moment(e.value).format('YYYY-MM-DD') : '')},
            endDateValue: e.value
        })
    }

    handleSaveBudget = () => {
        this.budgetServices.saveBudget(this.state.budget).then(res => {
            if(!res.errorCode){
                showNotification('success', 'Success Messaage', 'Action submitted!');
                this.props.reloadTable();
                this.closeForm();
            }else{
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
                
                showNotification('error', 'Error Messaage', res.errorMessage);
            }
        });
    }

    focusField = (e) => {
        if(e.target.value === '0') {
            e.target.select()
        }
    }

    render(){
        const budgetTypes = [
            {label: 'Scholarship', value: 'scholarship'},
            {label: 'Third-party Group', value: 'third_party'}
        ];

        const targetTypes = [
            {label: 'Prog. Department', value: BUDGET_TARGET_TYPE.department},
            {label: 'Membership', value: BUDGET_TARGET_TYPE.membership}
        ];

        return (
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={() => this.closeForm()}>
                <h2>{this.state.header}</h2>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Header Information">
                            <div className="p-grid p-fluid form-group">
                                {!this.state.parent?
                                <div className="p-col-12">
                                    {this.state.budget.id?
                                    <label className="p-w-bold">Type: {this.state.budget.typeName}</label>
                                    :
                                    <React.Fragment>
                                        <label className="p-label">Type</label>
                                        <Dropdown id="type" value={this.state.budget.type} options={budgetTypes} onChange={(e) => this.setState({budget: {...this.state.budget, type: e.target.value}})} style={{width:'100%'}} placeholder="Select a channel"/>
                                        <span className="p-form-error">{this.state.errors.type}</span>
                                    </React.Fragment>
                                    }
                                </div>
                                :''}
                                <div className="p-col-12">
                                <label className="p-label">Code</label>
                                    <InputText keyfilter={/[^\s]/} id="code" value={this.state.budget.code} onChange={(e) => this.setState({budget: {...this.state.budget, code: replaceSpaceAndUpperCase(e.target.value)}})} />
                                    <span className="p-form-error">{this.state.errors.code}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">* Name</label>
                                    <InputText value={this.state.budget.name} onChange={(e) => this.setState({budget: {...this.state.budget, name: e.target.value}})} />
                                    <span className="p-form-error">{this.state.errors.name}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">* GL account</label>
                                    <Dropdown value={this.state.budget.glAccount} options={this.state.glAccounts} filter={true} onChange={(e) => this.setState({budget: {...this.state.budget, glAccount: e.value}})} placeholder="--Select a GL account"/>
                                    <span className="p-form-error">{this.state.errors.glAccount}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Description</label>
                                    <InputTextarea id="description" rows={3} cols={100} value={this.state.budget.description} onChange={(e) => this.setState({budget: {...this.state.budget, description: e.target.value}})} />
                                </div>
                            </div>
                        </Fieldset>

                        {this.state.budget.targetType?
                        <Fieldset legend="Target Applying Settings">
                            <div className="p-grid p-fluid form-group">
                                {!this.state.parent?
                                <div className="p-col-12">
                                    <label className="p-label">* Type apply</label>
                                    <Dropdown value={this.state.budget.targetType} options={targetTypes} onChange={(e) => this.setState({budget: {...this.state.budget, targetType: e.value}})}/>
                                    <div className="p-form-error">{this.state.errors.targetType}</div>
                                </div>
                                :''}
                                {this.state.budget.targetType===BUDGET_TARGET_TYPE.department || this.state.budget.targetType===BUDGET_TARGET_TYPE.campaign?
                                <div className="p-col-12">
                                    <label className="p-label">* {this.state.budget.targetType===BUDGET_TARGET_TYPE.department?'Deparment':'Campaign'}</label>
                                    <Dropdown value={this.state.budget.targetId} options={this.state.budget.targetType===BUDGET_TARGET_TYPE.department?this.state.departments:this.state.campaigns} onChange={(e) => this.setState({budget: {...this.state.budget, targetId: e.value}})} 
                                        placeholder={this.state.budget.targetType===BUDGET_TARGET_TYPE.department?'-- Select a department --':'-- Select a campaign --'}/>
                                    <div className="p-form-error">{this.state.errors.targetId}</div>
                                </div>
                                :''}
                            </div>
                        </Fieldset>
                        :''}

                        <Fieldset legend="Balance Settings">
                            <div className="p-grid p-fluid form-group">
                                {this.state.parent?
                                    <div className="p-col-12 p-margin-top-10">
                                        <label style={{color: 'red'}}>Available amount can be assign: <strong>${this.state.availableAmount}</strong></label>
                                    </div>
                                :''}
                                <div className="p-col-12">
                                    <label className="p-label">{this.state.budget.id?'Amount receive':'Total amount'}</label>
                                    {/* <Spinner value={this.state.budget.total} onChange={(e) => this.setState({budget: {...this.state.budget, total: e.target.value}})}/> */}
                                    <InputText keyfilter="int" value={this.state.budget.total} onFocus={(e) => this.focusField(e)} onChange={(e) => this.setState({budget: {...this.state.budget, total: e.target.value}})}/>
                                    <div className="p-form-error">{this.state.errors.total}</div>
                                </div>
                                {!this.state.budget.targetType?
                                <div className="p-col-12">
                                    <Checkbox inputId="cbAllowNegativeBlance" checked={!this.state.budget.allowNegativeBalance} onChange={(e) => this.setState({budget: { ...this.state.budget, allowNegativeBalance: !e.checked}})} />
                                    <label htmlFor="cbAllowNegativeBlance">Do not allow negative balance</label>
                                </div>
                                :''}
                            </div>                                            
                        </Fieldset>

                        <Fieldset legend="Notification Settings">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">Threshold Amount</label>
                                    {/* <Spinner value={this.state.budget.thresholdAmount} onChange={(e) => this.setState({budget: {...this.state.budget, thresholdAmount: e.target.value}})}/> */}
                                    <InputText keyfilter="int" value={this.state.budget.thresholdAmount} onFocus={(e) => this.focusField(e)} onChange={(e) => this.setState({budget: {...this.state.budget, thresholdAmount: e.target.value}})}/>
                                    <div className="p-form-error">{this.state.errors.thresholdAmount}</div>
                                </div>
                            </div>
                        </Fieldset>

                        <Fieldset legend="Availability">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Start at</label>
                                    <MaskedCalendar value={this.state.startDateValue} minDate={this.state.minDate} maxDate={this.state.maxDate} showIcon={true} onChange={this.onStartDateChange} dateFormat="mm-dd-yy" />
                                    {/* <Calendar dateFormat="mm-dd-yy" hourFormat="24" showTime={true} value={this.state.startDateValue} onChange={this.onStartDateChange} showIcon={true}/> */}
                                    <span className="p-form-error">{this.state.errors.startAt}</span>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">End at</label>
                                    <MaskedCalendar value={this.state.endDateValue} minDate={this.state.minDate} maxDate={this.state.maxDate} showIcon={true} onChange={this.onEndDateChange} dateFormat="mm-dd-yy" />
                                    {/* <Calendar dateFormat="mm-dd-yy" hourFormat="24" showTime={true} value={this.state.endDateValue} onChange={this.onEndDateChange} showIcon={true}/> */}
                                    <span className="p-form-error">{this.state.errors.endAt}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Active?</label>
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.budget.active} onChange={(e) => this.setState({budget: { ...this.state.budget, active: e.target.value}})} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={() => this.handleSaveBudget()}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.closeForm()}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}