import React, {Component} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export class NotifyLocked extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const editingUser = this.props.user;
        const formKey = this.props.formKey;

        const footer = (
            <div>
                {/* <Button label="Try Again" icon="pi-md-refresh" onClick={this.props.onEditMode}/> */}
                <Button label="Close" icon="pi-md-clear" onClick={this.props.onHide}/>
            </div>
        );

        return (
            <Dialog footer={footer} visible={this.props.visible} contentStyle={styles.dialogContent} responsive={true} modal={true} showHeader={false} dismissableMask={true} onHide={this.props.onHide}>
                {editingUser.firstName} {editingUser.lastName} is editing!
            </Dialog>
        );
    }
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}