import moment from 'moment';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { OrderList } from 'primereact/orderlist';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import { Spinner } from 'primereact/spinner';
import React,  { forwardRef } from 'react';
import { useEffect } from 'react';
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import { FIELD_DIRECTION_TYPE, FIELD_DISPLAY_TYPE, FIELD_TYPE } from '../../constants';
import { showNotification } from '../../core/service/NotificationService';
import { getListAttributeValueList, saveAttributeField } from '../../scp/attribute/AttributeServices';
import { getCountries } from '../../scp/tenant-configuration/CountrySevices';
import { saveProductCustomField } from './ProductServices';
import { Calendar } from 'primereact/calendar'

export const CustomFieldForm = forwardRef((props, ref) => {
    const [attributeField, setAttributeField] = useState({})
    const [config, setConfig] = useState({
        dataList: []
    })
    const [advancedSetting, setAdvancedSetting] = useState({})
    const [countries, setCountries] = useState([])
    const [pickLists, setPickLists] = useState([])
    const [valueToInsert, setValueToInsert] = useState('')
    const [defaultCountry, setDefaultCountry] = useState('')

    const [formHeader, setFormHeader] = useState('New Field')
    const [visible, setVisible] = useState(false);
    const [errors, setErrors] = useState({})
    const [edit, setEdit] = useState(false);

    useImperativeHandle(ref, () => ({
        popularFormData: (data) => popularFormData(data)
    }))

    useEffect(() => {
        loadCountries();
        getValueLists();
    }, [])

    const popularFormData = (data) => {
        if(data) {
            setFormHeader('Edit Field')
            setEdit(true)
        }

        setAttributeField({
            ...attributeField,
            label: data ? data.label : '',
            name: data ? data.name : '',
            type: data ? data.type : 'text',
            typeName: data ? data.typeName : 'Text',
            weight: data ? data.weight : 0
        })

        setConfig(data ? data.config : getEmptyConfig())
        setAdvancedSetting({
            ...advancedSetting,
            required: data ? data.required : false,
            verifiable : data ? data.verifiable : false,
            obtainable: data ? data.obtainable : false,
            min: data ? data.min : '',
            max : data ? data.max : '',
            defaultValue : data ? data.defaultValue : '',
            defaultTrue : data ? data.defaultTrue : false
        })

        setVisible(true);
        setErrors({});
    }

    const getEmptyConfig = (type) => {
        let emptyConfig = {
            minLength: '',
            maxLength: '',
            expressionValidationType: 0,
            expressionValidationInput: '',
            helperMsg: '',
            defaultCountry: defaultCountry,
            useNoneLabel: false,
            noneLabel: 'None',
            useCustomList: true,
            customListId: '',
            dataList: [],
            rows: '5',
            dateFormat: 'mm-dd-yyyy',
            maxSize: '0',
            storeType: 'string',
            displayType: type==='select'?'dropdown':'checkbox',
            directionType: FIELD_DIRECTION_TYPE.horizontal,
            waiverPromo: 'I have read and agree with term and condition',
            content: '',
            hasExtraField: false,
            triggerValue: 'true',
            document: ''
        }
        return emptyConfig;
    }

    const loadCountries = () => {
        getCountries()
        .then(res => {
            setCountries(res ? res : [])
        })
    }

    const getValueLists = () => {
        getListAttributeValueList(true)
        .then(res => {
            setPickLists(res ? res : [])
        })
    }

    const onTypeChange = (e) => {
        let type = e.value;

        setConfig(getEmptyConfig(type))
        setAttributeField({...attributeField, type: type})
    }

    const addValueItem = () => {
        if(valueToInsert !== ''){
            let lstValues = config.dataList ? [...config.dataList] : [];
            
            if(lstValues.indexOf(valueToInsert)!==-1){
                showNotification('warn', 'Warning Message', 'Value already exist');
            }else{
                lstValues.push(valueToInsert);
                setValueToInsert('')
            }

            setConfig({...config, dataList: lstValues})
        }
    }

    const valueItemTemplate = (val) => {
        return (
            <div className="p-clearfix">
                <div style={{display: 'inline-block', margin: '10px 5px 0 0' }}>{val}</div>
                <Button icon="pi-md-close" className="p-button-danger" style={{'float':'right'}} onClick={() => removeValueItem(val)} />
            </div>
        );
    }

    const removeValueItem = (val) => {
        let lstValues = [...config.dataList];
        lstValues.splice(lstValues.indexOf(val), 1);
        setConfig({...config, dataList: lstValues})
    }

    const renderFieldConfig = (type) => {
        if(type==='text'||type==='phone'||type==='yes_no'||type==='textarea'||type==='date'||type==='select'||type==='checkbox'||type==='radio'||type==='waiver'){
            return (
                <div className="p-col-12">
                    <Fieldset legend="Configuration">
                        <div className="p-grid p-fluid form-group">
                            {(() => {
                                switch (type){
                                    case FIELD_TYPE.text:
                                        return ([
                                            <div className="p-col-12">
                                                <label>Min length</label>
                                                <InputText keyfilter="int" value={config.minLength} onChange={(e) => setConfig({...config, minLength: e.target.value})}/>
                                                <span className="p-form-error">{errors.minLength}</span>
                                            </div>,
                                            <div className="p-col-12">
                                                <label>Max length</label>
                                                <InputText keyfilter="int" value={config.maxLength} onChange={(e) =>  setConfig({...config, maxLength: e.target.value})}/>
                                                <span className="p-form-error">{errors.maxLength}</span>
                                            </div>,
                                            <div className="p-col-12">
                                                <label className="p-label">Validation Type:</label>
                                                <RadioButton inputId="validationType0" name="expressionValidationType" value="0" onChange={(e) =>  setConfig({...config, expressionValidationType: e.value})} checked={config.expressionValidationType == 0} />
                                                <label htmlFor="validationType0" className="p-radiobutton-label">None</label> 
                                                <RadioButton inputId="validationType1" name="expressionValidationType" className="p-margin-left-30" value="1" onChange={(e) => setConfig({...config, expressionValidationType: e.value})} checked={config.expressionValidationType == 1} />
                                                <label htmlFor="validationType1" className="p-radiobutton-label">Pattern</label>
                                                <RadioButton inputId="validationType2" name="expressionValidationType" className="p-margin-left-30" value="2" onChange={(e) => setConfig({...config, expressionValidationType: e.value})} checked={config.expressionValidationType == 2} />
                                                <label htmlFor="validationType2" className="p-radiobutton-label">Mask Input</label>
                                            </div>,
                                            (config.expressionValidationType!=0?
                                                <div className="p-col-12">
                                                    <label>{config.expressionValidationType==1?'Pattern':'Mask input'}</label>
                                                    <InputText value={config.expressionValidationInput} onChange={(e) => setConfig({...config, expressionValidationInput: e.value})}/>
                                                    <span className="p-form-error">{errors.validationInput}</span>
                                                </div>
                                                :''
                                            ),
                                            (config.expressionValidationType===1?
                                                <div className="p-col-12">
                                                    <label>Helper message</label>
                                                    <InputText value={config.helperMsg} onChange={(e) => setConfig({...config, helperMsg: e.target.value})}/>
                                                </div>
                                                :''
                                            )
                                        ]);
                                    case FIELD_TYPE.phone:
                                        return (
                                            <div className="p-col-12">
                                                <label className="p-label">Default country</label>
                                                <Dropdown options={countries} filter={true} value={config.defaultCountry} onChange={(e) => setConfig({...config, defaultCountry: e.value})} style={{width: "100%"}} placeholder="Pick a country" />
                                                <span className="p-form-error">{errors.defaultCountry}</span>
                                            </div>
                                        );
                                    case FIELD_TYPE.boolean:
                                        return (
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-margin-right-30 p-label">Display type:</label>
                                                    <RadioButton inputId="displayType0" name="displayType" value="checkbox" onChange={(e) => setConfig({...config, displayType: e.value})} checked={config.displayType === "checkbox"} />
                                                    <label htmlFor="displayType0" className="p-radiobutton-label">Checkbox</label> 
                                                    <RadioButton inputId="displayType1" name="displayType" className="p-margin-left-30" value="radio" onChange={(e) => setConfig({...config, displayType: e.value})} checked={config.displayType === "radio"} />
                                                    <label htmlFor="displayType1" className="p-radiobutton-label">Radio button</label>
                                                    <div className="p-form-error">{errors.displayType}</div>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Description</label>
                                                    <InputTextarea rows={3} cols={100} value={config.content} onChange={(e) => setConfig({...config, content: e.target.value})} />
                                                </div>
                                                <div className="p-col-12">
                                                    <Checkbox inputId="hasExtraField" onChange={(e) => setConfig({...config, hasExtraField: e.checked})} checked={config.hasExtraField}/>
                                                    <label htmlFor="hasExtraField" className="p-checkbox-label p-w-bold">Has extra field by selected value?</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Trigger extra field when value is:</label>
                                                    <RadioButton inputId="triggerValue0" name="triggerValue" value="true" onChange={(e) => setConfig({...config, triggerValue: e.value})} checked={config.triggerValue === "true"} />
                                                    <label htmlFor="triggerValue0" className="p-radiobutton-label p-margin-right-30">Yes</label> 
                                                    <RadioButton inputId="triggerValue1" name="triggerValue" value="false" onChange={(e) => setConfig({...config, triggerValue: e.value})} checked={config.triggerValue === "false"} />
                                                    <label htmlFor="triggerValue1" className="p-radiobutton-label">No</label>
                                                </div>
                                            </React.Fragment>
                                        );
                                    case FIELD_TYPE.textarea:
                                        return (
                                            <div className="p-col-12">
                                                <label htmlFor="rows" className="p-label">Rows number</label>
                                                <InputText value={config.rows} keyfilter="int" onChange={(e) => setConfig({...config, rows: e.target.value})}/>
                                                <span className="p-form-error">{errors.rows}</span>
                                            </div>
                                        );
                                    case FIELD_TYPE.date:
                                        return (
                                            <div></div>
                                        );
                                    case FIELD_TYPE.time:
                                        return(
                                            <div></div>
                                        )
                                    case FIELD_TYPE.select:  
                                        return ([
                                            <div className="p-col-12">
                                                <label className="p-label">Display type:</label>
                                                <RadioButton inputId="displayType0" name="displayType" value={FIELD_DISPLAY_TYPE.dropdown} onChange={(e) => setConfig({...config, displayType: e.value})} checked={config.displayType === FIELD_DISPLAY_TYPE.dropdown} />
                                                <label htmlFor="displayType0" className="p-radiobutton-label">Dropdown Select</label> 
                                                <RadioButton inputId="displayType1" name="displayType" className="p-margin-left-30" value={FIELD_DISPLAY_TYPE.checkbox} onChange={(e) => setConfig({...config, displayType: e.value})} checked={config.displayType === FIELD_DISPLAY_TYPE.checkbox} />
                                                <label htmlFor="displayType1" className="p-radiobutton-label">Checkbox</label>
                                                <RadioButton inputId="displayType2" name="displayType" className="p-margin-left-30" value={FIELD_DISPLAY_TYPE.radio} onChange={(e) => setConfig({...config, displayType: e.value})} checked={config.displayType === FIELD_DISPLAY_TYPE.radio} />
                                                <label htmlFor="displayType2" className="p-radiobutton-label">Radio Button</label>
                                            </div>,
                                            (config.displayType!==FIELD_DISPLAY_TYPE.dropdown&&
                                                <div className="p-col-12">
                                                    <label className="p-label">Direction type:</label>
                                                    <RadioButton inputId="directionType0" name="directionType" value={FIELD_DIRECTION_TYPE.horizontal} onChange={(e) => setConfig({...config, directionType: e.value})} checked={config.directionType === FIELD_DIRECTION_TYPE.horizontal} />
                                                    <label htmlFor="directionType0" className="p-radiobutton-label">Horizontal</label> 
                                                    <RadioButton inputId="directionType1" name="directionType" className="p-margin-left-30" value={FIELD_DIRECTION_TYPE.vertical} onChange={(e) =>  setConfig({...config, directionType: e.value})} checked={config.directionType === FIELD_DIRECTION_TYPE.vertical} />
                                                    <label htmlFor="directionType1" className="p-radiobutton-label">Vertical</label>
                                                </div>
                                            ),
                                            (config.displayType===FIELD_DISPLAY_TYPE.dropdown?
                                                <div className="p-col-12">
                                                    <label className="p-label">Control type:</label>
                                                    <RadioButton inputId="storeType0" name="storeType" value="string" onChange={(e) =>  setConfig({...config, storeType: e.value})} checked={config.storeType === 'string'} />
                                                    <label htmlFor="storeType0" className="p-radiobutton-label">Single select</label> 
                                                    <RadioButton inputId="storeType1" name="storeType" className="p-margin-left-30" value="array" onChange={(e) => setConfig({...config, storeType: e.value})} checked={config.storeType === 'array'} />
                                                    <label htmlFor="storeType1" className="p-radiobutton-label">Multi select</label>
                                                </div>
                                            :''),
                                            (config.displayType!==FIELD_DISPLAY_TYPE.checkbox?
                                                <div className="p-col-12">
                                                    <Checkbox inputId="useNoneLabel" onChange={(e) =>  setConfig({...config, useNoneLabel: e.checked})} checked={config.useNoneLabel}/>
                                                    <label htmlFor="useNoneLabel" className="p-checkbox-label">Use none option?</label>
                                                </div>
                                            :''),
                                            (config.displayType!==FIELD_DISPLAY_TYPE.checkbox && config.useNoneLabel?
                                                <div className="p-col-12">
                                                    <label>None option label</label>
                                                    <InputText value={config.noneLabel} onChange={(e) =>  setConfig({...config, noneLabel: e.target.value})}/>
                                                </div>
                                            :''),
                                            <div className="p-col-12">
                                                <label className="p-margin-right-30">Data source:</label>
                                                <RadioButton inputId="useCustomList0" name="useCustomList" value={true} onChange={(e) => setConfig({...config, useCustomList: e.value})} checked={config.useCustomList === true} />
                                                <label htmlFor="useCustomList0" className="p-radiobutton-label p-margin-right-30">From pick list</label> 
                                                <RadioButton inputId="useCustomList1" name="useCustomList" value={false} onChange={(e) => setConfig({...config, useCustomList: e.value})} checked={config.useCustomList === false} />
                                                <label htmlFor="useCustomList1" className="p-radiobutton-label">Input own list</label>
                                            </div>,
                                            (config.useCustomList?
                                                <div className="p-col-12">
                                                    <label className="p-label">Pick list</label>
                                                    <Dropdown id="pickList" value={config.customListId} options={pickLists} onChange={(e) => setConfig({...config, customListId: e.value})} style={{width:'100%'}} placeholder="Pick a predefine list"/>
                                                    <div className="p-form-error">{errors.pickList}</div>
                                                </div>
                                                :''),
                                            (!config.useCustomList?
                                                <div className="p-col-12">
                                                    <Fieldset legend="Values">
                                                        <div className="p-grid p-fluid form-group">
                                                            <div className="p-col-12">
                                                                <div className="p-inputgroup">
                                                                    <InputText id="valueInput" placeholder="Insert value" value={valueToInsert} onChange={(e) => setValueToInsert(e.target.value)}/>
                                                                    <Button label="Add value" className="p-button-info" onClick={() => addValueItem()}/>
                                                                </div>
                                                                <div className="p-form-error">{errors.dataList}</div>
                                                            </div>
                                                            <div className="p-col-12">
                                                                <OrderList value={config.dataList} dragdrop={true} itemTemplate={valueItemTemplate}
                                                                    responsive={true} header="List of Values" listStyle={{height: '20em'}} 
                                                                    onChange={(e) => setConfig({...config, dataList: e.value})} />
                                                            </div>
                                                        </div>                                                        
                                                    </Fieldset>
                                                </div>
                                            :'')
                                        ]);
                                    default: break;
                                }
                            })()}
                        </div>
                    </Fieldset>
                </div>
            );
        }
    }

    const renderAdvancedFieldConfig = (type) => {
        return (
            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <Checkbox inputId="required" onChange={(e) => setAdvancedSetting({...advancedSetting, required: e.checked})} checked={advancedSetting.required}></Checkbox>
                    <label htmlFor="required" className="p-checkbox-label">Required?</label>
                </div>
                {(() => {
                    switch (type) {
                        case 'select':
                            return ([
                                <div className="p-col-12">
                                    <Checkbox onChange={(e) => setAdvancedSetting({...advancedSetting, verifiable: e.checked})} checked={advancedSetting.verifiable}></Checkbox>
                                    <label className="p-checkbox-label">Verify? <i style={{fontSize:'11px'}}>(Please bypass if you don't use this field for program attribute setting)</i></label>
                                </div>,
                                <div className="p-col-12">
                                    <Checkbox onChange={(e) => setAdvancedSetting({...advancedSetting, obtainable: e.checked})} checked={advancedSetting.obtainable}></Checkbox>
                                    <label className="p-checkbox-label">Obtainable? <i style={{fontSize:'11px'}}>(Please bypass if you don't use this field for program attribute setting)</i></label>
                                </div>
                            ]);
                        case 'number':
                            return ([
                                <div className="p-col-12 p-md-6">
                                    <label>Min value</label>
                                    <Spinner value={advancedSetting.min} onChange={(e) => setAdvancedSetting({...advancedSetting, min: e.target.value})}/>
                                </div>,
                                <div className="p-col-12 p-md-6">
                                    <label>Max value</label>                                    
                                    <Spinner value={advancedSetting.max} onChange={(e) => setAdvancedSetting({...advancedSetting, max: e.target.value})}/>
                                </div>
                            ]);
                        case 'date':
                            return ([
                                <div className="p-col-12 p-md-6">
                                    <label>Min date</label>                                    
                                    <Calendar dateFormat="mm-dd-yy" value={advancedSetting.minDateValue} onChange={onMinDateChange} showIcon={true}/>
                                </div>,
                                <div className="p-col-12 p-md-6">
                                    <label>Max date</label>
                                    <Calendar dateFormat="mm-dd-yy" value={advancedSetting.maxDateValue} onChange={onMaxDateChange} showIcon={true}/>
                                </div>
                            ]);
                        default:
                            return null;
                    }
                })()}
            </div>
        );
    }

    const onMinDateChange = (e) => {
        setAdvancedSetting({
            ...advancedSetting,
            min: moment(e.value).format(moment.HTML5_FMT.DATE),
            minDateValue: e.value
        })
    }

    const onMaxDateChange = (e) => {
        setAdvancedSetting({
            ...advancedSetting,
            max: moment(e.value).format(moment.HTML5_FMT.DATE),
            maxDateValue: e.value
        })
    }

    const handleSaveField = () => {
        let data = {
            ...advancedSetting,
            attributeField: {
                ...attributeField,
                config: {...config}
            }
        };

        if(edit) {
            if(props.onUpdateField) {
                props.onUpdateField(data);
            }
        } else {
            saveProductCustomField(props.productId, data)
            .then(res => {
                if(!res.errorCode){
                    if(props.reloadTable){
                        props.reloadTable(res);
                    }
    
                    setAdvancedSetting({})
    
                    hideForm();
                    showNotification('success', 'Success Message', 'Action submitted');
                }else{
                    if(res.errorCode===400){
                        setErrors(res.errorObj);
                    }else{
                        showNotification('error', 'Error Message', res.errorMessage);
                    }
                }
            })
        }
    }

    const hideForm = () => {
        setAdvancedSetting({})
        setAttributeField({})
        setVisible(false);
        setEdit(false);
    }

    const fieldTypes = [
        {value: 'text', label: 'Text'},
        {value: 'number', label: 'Number'},
        {value: 'email', label: 'Email'},
        {value: 'phone', label: 'Phone'},
        {value: 'select', label: 'Select'},
        {value: 'yes_no', label: 'Yes/No'},
        {value: 'textarea', label: 'Textarea'},
        {value: 'file', label: 'File Upload'},
        {value: 'date', label: 'Date'},
        {value: 'time', label: 'Time'},
        {value: 'waiver', label: 'Waiver'}
    ];

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto' }} className="p-sidebar-md" position="right" blockScroll={true} onHide={hideForm}>
                <h2>{formHeader}</h2>
            
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <Fieldset legend="Basic Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    {attributeField.id || props.type==='newsletter'?
                                        <label className="p-label">Type: <strong>{attributeField.typeName}</strong></label>
                                    :
                                    <React.Fragment>
                                        <label className="p-label">Type</label>
                                        <Dropdown value={attributeField.type} disabled={edit} options={fieldTypes} onChange={(e) => onTypeChange(e)} style={{width:'100%'}} placeholder="Pick a type"/>
                                        <span className="p-form-error">{errors.type}</span>
                                    </React.Fragment>
                                    }
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Name (For manage)</label>
                                    <InputText keyfilter={/[^\s]*[\w]/} disabled={edit} value={attributeField.name} onChange={(e) => setAttributeField({...attributeField, name: e.target.value})} />
                                    <span className="p-form-error">{errors.name}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Label (For display)</label>
                                    <InputText value={attributeField.label} onChange={(e) => setAttributeField({...attributeField, label: e.target.value})} />
                                    <span className="p-form-error">{errors.label}</span>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    {renderFieldConfig(attributeField.type)}

                    <div className="p-col-12">
                        <Fieldset legend="Advanced Setting">
                            {renderAdvancedFieldConfig(attributeField.type)}
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Save Information" icon="pi pi-save" iconPos="left" style={{'float':'right'}} onClick={() => handleSaveField()}/>
                        <Button label="Close" icon="pi-md-close" className="p-button-secondary" onClick={hideForm}/>
                    </div>
                </div>
            </Sidebar>
    )
})