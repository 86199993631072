import React, {Component} from 'react';
import '../login/Login.css';
import {Password} from 'primereact/password';
import { Button } from 'primereact/button';
import { UserServices } from '../../constituent-management/contact/UserServices';
import ReCAPTCHA from "react-google-recaptcha";
import { showNotification } from '../../core/service/NotificationService';


export default class ResetPasswordLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            errors: {}
        }
        this.userServices = new UserServices();
    }

    componentDidMount() {
        this.getParams();
    }

    handleChangePassword = () => {
        if(!this.state.captchaFlag){
            alert("Captcha is required !");
            return;
        }
        this.userServices.saveChangePassword(this.state.newPassword, this.state.token)
        .then(data => {
            if(!data.errorCode){
                showNotification('success', 'Success Message', 'New password has been changed');
                this.props.history.push('/login');
            }else {
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(data.errorCode===400) this.setState({errors: data.errorObj})
            }
        })
    }

    getParams = () => {
        let query = new URLSearchParams(window.location.href);
        let token = query.get('t');
        this.setState({
            data: {...this.state.data,
                token: token
            }
        });
    }

    render() {
        return (
            <div className="login-body">
                <div className="login-panel p-fluid">
                    <div className="login-panel-header">
                        <img className="logo" src="assets/fromlabs/logo.png" alt="Logo"/>
                    </div>
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div style={{margin:"auto", textAlign:"center"}}>
                                <h1>Change New Password</h1>
                            </div>
                            <div className="p-col-12"><div className="p-form-error">{this.state.errors.token}</div></div>
                            <div className="p-col-12">
                                <label>New password</label>
                                <Password value={this.state.data.newPassword} onChange={(e) => this.setState({data: {...this.state.data, newPassword: e.target.value}})}/>
                                <div className="p-form-error">{this.state.errors.password}</div>
                            </div>
                            <div className="p-col-12">
                                <label>Confirm New password</label>
                                <Password value={this.state.data.confirmPassword} onChange={(e) => this.setState({data: {...this.state.data, confirmPassword: e.target.value}})} />
                                <div className="p-form-error">{this.state.errors.confirmPassword}</div>
                            </div>
                            <div className="p-margin-left-15">
                            <ReCAPTCHA
                                sitekey="6LeTGcoUAAAAAI4N9_s7t_WGyOyocpOcT-OzSy0B"
                                onChange={() => this.setState({captchaFlag : true})}
                            />
                            </div>
                            <div className="p-col-12">
                                <Button label="Submit" onClick={this.handleChangePassword}/>
                            </div>
                            <div className="p-col-12 ">
                                <Button label="Login" onClick={() => {window.open('/login'); window.close()}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}