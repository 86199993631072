import React, { Component } from "react";
import moment from 'moment';
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import { showNotification } from '../../core/service/NotificationService';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import { Dropdown } from "primereact/dropdown";
import { ASSET_SCHEDULE_STATUS, ASSET_SCHEDULE_STATUSES } from "../../constants";
import { deleteSchedule, saveSchedule } from "./SchedulesService";
import { getListAssets } from "../asset/AssetServices";

export default class AssetScheduleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedule: {
                startTimeValue: '',
                endTimeValue: '',
                recurringInfo: {}
            },
            visibleRight: false,
            errors: {},

            assetOptions: []
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.inventoryId !== this.state.inventoryId && this.state.inventoryId) {
            this.loadAssets();
        }
    }

    loadAssets = () => {
        if(this.state.inventoryId) {
            getListAssets({inventoryId: this.state.inventoryId}, true)
            .then(data => {
                if(data && Array.isArray(data)) {
                    this.setState({
                        assetOptions: data
                    })
                }
            })
        }
    }

    action = (assetId, data, inventoryId) => {
        this.setState({
            assetId: assetId ? assetId : data && data.assetId ? data.assetId : '',
            inventoryId: inventoryId ? inventoryId : '',
            schedule: {
                id: data ? data.id : '',
                title: data ? data.title : '',
                startDate: data ? moment(data.start).format('YYYY-MM-DD'): '',
                startValue: data ? moment(data.start, 'YYYY-MM-DD').toDate() : '',

                endDate: data ? moment(data.end).format('YYYY-MM-DD'): '',
                endValue:data ? moment(data.end, 'YYYY-MM-DD').toDate() : '',
                
                startTime: data ? moment(data.start).format('HH:mm') : '',
                startTimeValue: data ? moment(data.start).toDate() : '',

                endTime: data ? moment(data.end).format('HH:mm') : '',
                endTimeValue: data ? moment(data.end).toDate() : '',
                status: data ? data.status : ASSET_SCHEDULE_STATUS.in_scheduled.value,
                recurring: data ? data.recurring : false,
                recurringInfo: data ? data.recurringInfo : {}
            },
            requireAsset: !assetId && (!data || !data.assetId), 
            visible: true,
            formHeader: data ? 'Edit Schedule' : 'New Schedule',
            errors: {}
        })
    }

    handleSaveSchedule = () => {
        this.setState({ errors: {} });
        saveSchedule(this.state.assetId, this.state.schedule)
            .then(res => {
                if (!res.errorCode) {
                    if(this.props.reloadSchedule)
                        this.props.reloadSchedule();
                    this.hideForm();
                    showNotification('success', 'Success Message', 'Action submitted');
                } else if (res.errorCode === 400) {
                    this.setState({ errors: res.errorObj });
                } else {
                    showNotification('error', 'Error Message', 'Cannot perform action');
                }
            });
    }

    removeSchedule = (id) => {
        deleteSchedule(this.state.assetId, id).then(res => {
            if (!res.errorCode) {
                this.props.reloadSchedule();
                this.hideForm();
                showNotification('success', 'Success Message', 'Successfully Deleted');
            } else {
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    onStartDateChange = (e) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                startDate: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
                startValue: e.value,
            }
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                endDate: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
                endValue: e.value,
            }
        });
    }

    onStartTimeChange = (e) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                startTime: e.value ? moment(e.value).format(moment.HTML5_FMT.TIME) : '',
                startTimeValue: e.value 
            }
        })
    }

    onEndTimeChange = (e) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                endTime: e.value ? moment(e.value).format(moment.HTML5_FMT.TIME) : '',
                endTimeValue: e.value 
            }
        })
    }

    onStatusChange = (e) => {
        this.setState({
            schedule: {
                ...this.state.schedule,
                status: e.value
            }
        })
    }

    hideForm = () => {
        this.setState({
            assetId: null,
            inventoryId: null,
            schedule: {},
            requireAsset: false, 
            visible: false,
            errors: {}
        })
    }

    render() {
        const footer = (
            <React.Fragment>
                <Button label="Submit" icon="pi pi-check" onClick={() => this.handleSaveSchedule()} />
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.hideForm} />
                {this.state.schedule.id ? 
                    <Button label="Delete" icon="pi-md-trash" className="p-button-danger"  onClick={(e) => this.deleteNotify.delete(this.state.schedule.id)} />
                    : ''
                }
            </React.Fragment>
        );

        return (
            <div>
                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeSchedule(e)}
                />

                <Sidebar visible={this.state.visible} style={{ overflowY: "auto", width: "80em" }} position="right" blockScroll={true} onHide={e => this.hideForm()} >
                    <h2>{this.state.formHeader}</h2>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset legend="Locker Information">
                                <div className="p-grid p-fluid form-group">
                                    {this.state.requireAsset &&
                                        <div className="p-col-12 p-md-12">
                                            <label className="p-label">* Asset</label>
                                            <Dropdown options={this.state.assetOptions} value={this.state.assetId} showIcon={true} onChange={(e) => this.setState({assetId: e.value})} />
                                            <div className="p-form-error">{this.state.errors.title}</div>
                                        </div>
                                    }
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">* Title</label>
                                        <InputText value={this.state.schedule.title} onChange={(e) => this.setState({ schedule: { ...this.state.schedule, title: titleCaseText(e.target.value) } })} />
                                        <div className="p-form-error">{this.state.errors.title}</div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Start Date</label>
                                        <MaskedCalendar value={this.state.schedule.startValue} showIcon={true} onChange={(e) => this.onStartDateChange(e)} dateFormat="mm-dd-yy" />
                                        <span className="p-form-error">{this.state.errors.start}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* End Date</label>
                                        <MaskedCalendar value={this.state.schedule.endValue} showIcon={true} onChange={(e) => this.onEndDateChange(e)} dateFormat="mm-dd-yy" />
                                        <span className="p-form-error">{this.state.errors.end}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Start Time</label>
                                        <MaskedCalendar value={this.state.schedule.startTimeValue} onChange={(e) => this.onStartTimeChange(e)} showIcon showTime timeOnly hourFormat="12" />
                                        <span className="p-form-error">{this.state.errors.startTime}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* End Time</label>
                                        <MaskedCalendar value={this.state.schedule.endTimeValue} onChange={(e) => this.onEndTimeChange(e)} showIcon showTime timeOnly hourFormat="12" />
                                        <span className="p-form-error">{this.state.errors.endTime}</span>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">Schedule Status</label>
                                        <Dropdown options={ASSET_SCHEDULE_STATUSES} value={this.state.schedule.status} onChange={(e) => this.onStatusChange(e)} />
                                    </div>
                                    {this.state.schedule.recurring &&
                                        <div className="p-col-12">
                                            <label className="p-label">Recurring in every {this.state.schedule.recurringInfo.rentalQty} {this.state.schedule.recurringInfo.rentalUnit}</label>
                                        </div>
                                    }
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            {footer}
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
}
