import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService'
import { getPageSchedules, getScheduleList } from './SchedulesService';
import { Fieldset } from 'primereact/fieldset';
import { APP_FEP, ASSET_TYPE, ASSET_SCHEDULE_STATUS, ASSET_SCHEDULE_STATUSES, ASSET_STATUS, TABLE_PAGING_LENGTH, TAXONOMY_TYPE } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { getListTaxons } from '../taxon/TaxonServices';
import { getListInventories } from '../inventory/InventoryService';
import { getListAssets, getPageAssetDamagedTracking, getPageAssets } from '../asset/AssetServices';
import { Calendar } from 'primereact/calendar';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { getPageInvoices } from '../invoice/InvoiceServices';

export const AssetReportListView = (props) => {
    const match = useRouteMatch();

    const [branches, setBranchs] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [assets, setAssets] = useState([]);
    const [pageable, setPageable] = useState({page: 0, rows: 10})
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({})
    const [isLoaded, setLoaded] = useState(true)
    const [selectedViewType, setSelectedViewType] = useState(null)
    const [categories, setCategories] = useState([]);
    const [assetSchedules, setAssetSchedules] = useState([]);

    const currency = '$'

    useEffect(()=>{
        setSelectedViewType(VIEW_REPORT_TYPE.asset.value)
    },[])

    useEffect(() =>{
        switch(selectedViewType){
            case VIEW_REPORT_TYPE.asset.value: 
                loadBranches()
                loadInventories()
                loadCategories()
                loadPageAssets()
            break;
            case VIEW_REPORT_TYPE.schedule.value: 
                loadInventories()
                loadAssets()
                loadPageAssetSchedules()
            break;
            case VIEW_REPORT_TYPE.damage.value: 
                loadAssets()
                loadAssetSchedules()
                loadPageDamages()
            break;
            case VIEW_REPORT_TYPE.damage_charge.value: 
                loadAssets()
                loadPageDamageCharges()
            break;
            default: break
        }
    },[selectedViewType])

    useEffect(() =>{
        if(isLoaded){
            switch(selectedViewType){
                case VIEW_REPORT_TYPE.asset.value: 
                    loadPageAssets()
                break;
                case VIEW_REPORT_TYPE.schedule.value: 
                    loadPageAssetSchedules()
                break;
                case VIEW_REPORT_TYPE.damage.value: 
                    loadPageDamages()
                break;
                case VIEW_REPORT_TYPE.damage_charge.value: 
                    loadPageDamageCharges()
                break;
                default: break
            }
        }
    },[isLoaded])

    useEffect(()=>{
        loadInventories()
        loadAssets()
    },[filter.branchId])

    useEffect(()=>{
        loadAssets()
    },[filter.inventoryId])

    useEffect(()=>{
        switch(selectedViewType){
            case VIEW_REPORT_TYPE.damage.value: 
                loadAssetSchedules()
            break;
            default: break
        }
    },[filter.assetId])

    const loadBranches = () => {
        getListTaxons({type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res));
    }

    const loadInventories =  () => {
        getListInventories({app: APP_FEP, branchId: filter.branchId}, true)
        .then(res => setInventories(res))
    }

    const loadAssets = () => {
        getListAssets({app: APP_FEP, branchId: filter.branchId, inventoryId: filter.inventoryId}, true)
        .then(data => {
            setAssets(data)
        })
    }

    const loadCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value]}, true)
        .then(res => setCategories(res))
    }

    const loadAssetSchedules = () => {
        getScheduleList({assetId: filter.assetId})
        .then(res => {
            setAssetSchedules(res?res:[])
        })
    }

    const converAssetScheduleList = (assetScheduleList) => {
        let tmpArr=[]
        assetScheduleList&&assetScheduleList.map(item => {
            let tmpStartDate = moment(item.startDate)
            let tmpEndDate = moment(item.endDate)

            let isAdd = false

            if(filter.dateValue){
                let tmpSelectedStartDate = moment(moment(filter.dateValue[0]).format('MM-DD-YYYY'))
                let tmpSelectedEndDate = moment(moment(filter.dateValue[1]).format('MM-DD-YYYY'))
                
                if(tmpSelectedStartDate.isValid() && tmpSelectedEndDate.isValid()){
                    if(tmpStartDate.isSameOrAfter(tmpSelectedStartDate) && tmpEndDate.isSameOrBefore(tmpSelectedEndDate)){
                        isAdd = true
                    }
                }else if(tmpSelectedStartDate.isValid()){
                    if(tmpStartDate.isSameOrAfter(tmpSelectedStartDate) && tmpEndDate.isSameOrBefore(tmpSelectedStartDate)){
                        isAdd = true
                    }
                }
            }else{
                isAdd = true
            }
            if(isAdd){
                let tmpStartDateFmt = moment(item.startDate).format('MM/DD/YYYY') 
                let tmpEndDateFmt = moment(item.endDate).format('MM/DD/YYYY')
                let tmpStartTimeFmt = moment(item.startTime, moment.HTML5_FMT.TIME).format('hh:mm A')
                let tmpEndTimeFmt = moment(item.endTime, moment.HTML5_FMT.TIME).format('hh:mm A')
                tmpArr.push({value: item.id, label: (tmpStartDateFmt===tmpEndDateFmt?(tmpStartDateFmt+' '+tmpStartTimeFmt+'-'+tmpEndTimeFmt):(tmpStartDateFmt+' '+tmpStartTimeFmt+' - '+tmpEndDateFmt+' '+tmpEndTimeFmt))})
            }
        })
        return tmpArr
    }

    //#region ASSET
    const loadPageAssets = () => {
        getPageAssets(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
            setLoaded(false)
        })
    }

    const onPageAsset = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoaded(true)
    }

    const onSortAsset = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoaded(true)
    }

    const renderHeaderAsset = () => {
        return (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div className="p-float-right">
                    Show: {' '}
                    <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e)}  />
                    <Button className="p-button-constrast p-float-right" icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: "top"}} onClick={() => loadPageAssets()} />
                </div>
                <h3 className="fle-datatable-header-title">Asset Listing</h3>
            </div>
        );
    }

    const renderAsset = () => {
        return(
            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Filter">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Name:</span>
                                            <InputText value={filter.name} options={branches} onChange={(e) => onFilterChange('name', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Category:</span>
                                            <Dropdown value={filter.categoryId} options={categories} showClear onChange={(e) => onFilterChange("categoryId", e.value)} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Status:</span>
                                            <Dropdown value={filter.status} options={Object.values(ASSET_STATUS)} showClear onChange={(e) => onFilterChange("status", e.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Branch:</span>
                                            <Dropdown value={filter.branchIds&&filter.branchIds>0?filter.branchIds[0]:null} options={branches} showClear onChange={(e) => onFilterChange("branchIds", e.value?[e.value]:[])} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Inventory:</span>
                                            <Dropdown value={filter.inventoryId} options={inventories} showClear onChange={(e) => onFilterChange("inventoryId", e.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} style={{width: 'auto'}} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={()=>clearFilter()} style={{width: 'auto'}} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={dataList}
                        first={pageable.page * pageable.rows}
                        header={renderHeaderAsset()} onPage={onPageAsset} onSort={onSortAsset}
                        rows={pageable.rows} totalRecords={pageable.total}
                        sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable />
                        <Column field="code" header="Code" sortable />
                        <Column header="Category" body={rowData => rowData.category && rowData.category.name} />
                        <Column header="Branch" body={rowData => rowData.branch && rowData.branch.name} />
                        <Column header="Original Inventory" body={rowData => rowData.inventory && rowData.inventory.name} />
                        <Column header="Current Inventory" body={rowData => rowData.currentInventory && rowData.currentInventory.name} />
                        <Column header="Status" body={rowData => ASSET_STATUS[rowData.status]&&ASSET_STATUS[rowData.status].label} />
                    </DataTable>
                </div>
            </div>
        )
    }
    //#endregion

    //#region SCHEDULE
    const loadPageAssetSchedules = () => {
        getPageSchedules(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
            setLoaded(false)
        })
    }

    const onPageSchedule = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoaded(true)
    }

    const onSortSchedule = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoaded(true)
    }

    const renderHeaderSchedule = () => {
        return (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div className="p-float-right">
                    Show: {' '}
                    <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e)}  />
                    <Button className="p-button-constrast p-float-right" icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: "top"}} onClick={() => loadPageAssetSchedules()} />
                </div>
                <h3 className="fle-datatable-header-title">Asset Schedule Listing</h3>
            </div>
        );
    }

    const renderSchedule = () => {
        return(
            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Filter">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Inventory:</span>
                                    <Dropdown value={filter.inventoryId} options={inventories} showClear onChange={(e) => onFilterChange("inventoryId", e.value)} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Asset:</span>
                                    <Dropdown value={filter.assetId} options={assets} showClear onChange={(e) => onFilterChange("assetId", e.value)} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Status:</span>
                                    <Dropdown value={filter.statuses&&filter.statuses.length>0?filter.statuses[0]:null} options={ASSET_SCHEDULE_STATUSES} showClear onChange={(e) => onFilterChange("statuses", e.value?[e.value]:[])} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Date Range:</span>
                                    <Calendar value={filter.dateValue} onChange={(e) => onDateRangeChange(e)} showIcon={true} selectionMode="range" format="mm/dd/yy" />
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} style={{width: 'auto'}} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={()=>clearFilter()} style={{width: 'auto'}} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={dataList}
                        first={pageable.page * pageable.rows}
                        header={renderHeaderSchedule()} onPage={onPageSchedule} onSort={onSortSchedule}
                        rows={pageable.rows} totalRecords={pageable.total}
                        sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable />
                        <Column header="Asset" body={rowData => rowData.asset && rowData.asset.name} />
                        <Column header="Inventory" body={rowData => rowData.asset && rowData.asset.inventory && rowData.asset.inventory.name} />
                        <Column header="Status" body={rowData => ASSET_SCHEDULE_STATUS[rowData.status]&&ASSET_SCHEDULE_STATUS[rowData.status].label} />
                        <Column field="start" header="Schedule" sortable
                            body={rowData => {
                                let tmpStartDate = rowData.startDate ? moment(rowData.startDate).format('MM/DD/YYYY') : ''
                                let tmpEndDate = rowData.endDate ? moment(rowData.endDate).format('MM/DD/YYYY') : ''
                                let tmpStartTime = rowData.startTime ? moment(rowData.startTime, moment.HTML5_FMT.TIME).format('hh:mm A') : ''
                                let tmpEndTime = rowData.endTime ? moment(rowData.endTime, moment.HTML5_FMT.TIME).format('hh:mm A') : ''
                                return tmpStartDate===tmpEndDate?(tmpStartDate+' '+tmpStartTime+'-'+tmpEndTime):(tmpStartDate+' '+tmpStartTime+' - '+tmpEndDate+' '+tmpEndTime)
                            }} 
                        />
                    </DataTable>
                </div>
            </div>
        )
    }
    //#endregion

    //#region DAMAGE
    const loadPageDamages = () => {
        getPageAssetDamagedTracking(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
            setLoaded(false)
        })
    }

    const onPageDamage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoaded(true)
    }

    const onSortDamage = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoaded(true)
    }

    const renderHeaderDamage = () => {
        return (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div className="p-float-right">
                    Show: {' '}
                    <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e)}  />
                    <Button className="p-button-constrast p-float-right" icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: "top"}} onClick={() => loadPageAssetSchedules()} />
                </div>
                <h3 className="fle-datatable-header-title">Damge Listing</h3>
            </div>
        );
    }

    const renderDamage= () => {
        return(
            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Filter">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Asset:</span>
                                    <Dropdown value={filter.assetId} options={assets} showClear onChange={(e) => onFilterChange("assetId", e.value)} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Date Range:</span>
                                            <Calendar value={filter.dateValue} onChange={(e) => onDateRangeChange(e)} showIcon={true} selectionMode="range" format="mm/dd/yy" />
                                        </div>
                                    </div>        
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Schedule:</span>
                                            <Dropdown value={filter.assetScheduleId} options={converAssetScheduleList(assetSchedules)} showClear onChange={(e) => onFilterChange("assetScheduleId", e.value)} />
                                        </div>
                                    </div>        
                                </div>
                            </div>
                            
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} style={{width: 'auto'}} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={()=>clearFilter()} style={{width: 'auto'}} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={dataList}
                        first={pageable.page * pageable.rows}
                        header={renderHeaderDamage()} onPage={onPageDamage} onSort={onSortDamage}
                        rows={pageable.rows} totalRecords={pageable.total}
                        sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable />
                        <Column header="Asset" body={rowData => rowData.asset && rowData.asset.name} />
                        <Column header="Schedule"
                            body={rowData => {
                                let tmpStartDate = rowData.assetSchedule&&rowData.assetSchedule.startDate ? moment(rowData.assetSchedule.startDate).format('MM/DD/YYYY') : ''
                                let tmpEndDate = rowData.assetSchedule&&rowData.assetSchedule.endDate ? moment(rowData.assetSchedule.endDate).format('MM/DD/YYYY') : ''
                                let tmpStartTime = rowData.assetSchedule&&rowData.assetSchedule.startTime ? moment(rowData.assetSchedule.startTime, moment.HTML5_FMT.TIME).format('hh:mm A') : ''
                                let tmpEndTime = rowData.assetSchedule&&rowData.assetSchedule.endTime ? moment(rowData.assetSchedule.endTime, moment.HTML5_FMT.TIME).format('hh:mm A') : ''
                                return tmpStartDate===tmpEndDate?(tmpStartDate+' '+tmpStartTime+'-'+tmpEndTime):(tmpStartDate+' '+tmpStartTime+' - '+tmpEndDate+' '+tmpEndTime)
                            }} 
                        />
                        <Column field="description" header="Desciption" />
                    </DataTable>
                </div>
            </div>
        )
    }
    //#endregion

    //#region DAMAGE CHARGE
    const loadPageDamageCharges = () => {
        let tmpFilter = {...filter}
        tmpFilter.assetCharge = true
        getPageInvoices(tmpFilter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
            setLoaded(false)
        })
    }

    const onPageDamageCharge = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoaded(true)
    }

    const onSortDamageCharge = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoaded(true)
    }

    const renderHeaderDamageCharge = () => {
        return (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div className="p-float-right">
                    Show: {' '}
                    <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e)}  />
                    <Button className="p-button-constrast p-float-right" icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: "top"}} onClick={() => loadPageAssetSchedules()} />
                </div>
                <h3 className="fle-datatable-header-title">Damge Listing</h3>
            </div>
        );
    }

    const renderDamageCharge = () => {
        return(
            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Filter">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Payor Name/Email:</span>
                                    <InputText value={filter.customerNameOrEmail} onChange={(e) => onFilterChange('customerNameOrEmail', e.target.value)} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Asset:</span>
                                    <Dropdown value={filter.assetId} options={assets} showClear onChange={(e) => onFilterChange("assetId", e.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} style={{width: 'auto'}} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={()=>clearFilter()} style={{width: 'auto'}} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={dataList}
                        first={pageable.page * pageable.rows}
                        header={renderHeaderDamageCharge()} onPage={onPageDamageCharge} onSort={onSortDamageCharge}
                        rows={pageable.rows} totalRecords={pageable.total}
                        sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable />
                        <Column header="Payor"
                            body={(rowData) => {
                                return (
                                    <React.Fragment>
                                        <div>{rowData.payorName}</div>
                                        <div>{rowData.payorEmail}</div>
                                    </React.Fragment>
                                )
                            }}
                        />
                        <Column header="Asset" body={rowData => rowData.asset&&rowData.asset.name} />
                        <Column field="paid" header="Paid" sortable body={rowData => currency+rowData.paid} />
                        <Column header="Payment Date" date field="paymentCompletedAt" sortable={true}
                            body={(rowData) => moment(rowData.paymentCompletedAt).format('MM/DD/YYYY hh:mm A')}
                        />
                    </DataTable>
                </div>
            </div>
        )
    }
    //#endregion

    const onDateRangeChange = (e) => {
        let tmpStart = e.value&&e.value.length>0?e.value[0]:''
        let tmpEnd = e.value&&e.value.length>1?e.value[1]:''
        setFilter({...filter, 
            startDate: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
            endDate: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):'',
            dateValue: e.value,
            assetScheduleId: null
        });
    }

    const onFilterChange = (property, value) => {
        setFilter({...filter, [property]: value})
        setLoaded(true)
    }

    const applyFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setLoaded(true)
    }

    const clearFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({...filter,
            branchIds: [],
            branchId: null,
            categoryId: null,
            inventoryId: null,
            assetId: null,
            startDate: '',
            endDate: '',
            dateValue: null,
            assetScheduleId: null,
            customerNameOrEmail: '',
            status: null,
            statuses: []
        })
        setLoaded(true)
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoaded(true);
    };

    // const actionTemplate = (rowData) => {
    //     return (
    //         <Button className="p-button-primary" icon="pi-md-pencil" label="Edit" tooltip="Edit Tax" tooltipOptions={{position: "top"}} onClick={() => onAddOrEdit(rowData)}/>
    //     )
    // }

    const VIEW_REPORT_TYPE = {
        asset: {value: 'asset', label: 'Usage'},
        schedule: {value: 'schedule', label: 'Schedule'},
        damage: {value: 'damage', label: 'Damage'},
        damage_charge: {value: 'damage_charge', label: 'Damage Charge'},
    }

    return(
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Asset</BreadcrumbsItem>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-lg-4 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">View by:</span>
                                <Dropdown value={selectedViewType} options={Object.values(VIEW_REPORT_TYPE)} onChange={(e) => setSelectedViewType(e.value)} />
                            </div>            
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                {(()=>{
                    if(selectedViewType){
                        switch(selectedViewType){
                            case VIEW_REPORT_TYPE.asset.value: return renderAsset()
                            case VIEW_REPORT_TYPE.schedule.value: return renderSchedule()
                            case VIEW_REPORT_TYPE.damage.value: return renderDamage()
                            case VIEW_REPORT_TYPE.damage_charge.value: return renderDamageCharge()
                            default: break;
                        }    
                    }
                })()}
            </div>
        </div>
    )
}