import React, { Component } from 'react';
import moment from "moment";
import classNames from "classnames";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { showNotification } from '../../core/service/NotificationService';
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar"
import { saveTimeFrame, removeTimeFrame } from './EventService';

export class EventTimeForm extends Component {

    constructor(props) {
        super(props);
        this.state ={
            data:{},
            formHeader: 'Time Frame',
            visible: false,
            errors: {}
        }
    }

    popularFormData = (data) => {
        this.setState({
            data: {
                id: data?data.id:'',
                startTime: data?data.startTime:'',
                endTime: data?data.endTime:''
            },
            startTimeValue: data? moment(data.startTime, 'HH:mm').toDate():'',
            minTime: data?moment(data.startTime, 'HH:mm').toDate():'',
            endTimeValue:  data?moment(data.endTime, 'HH:mm').toDate():'',
            maxTime:  data?moment(data.endTime, 'HH:mm').toDate():'',
            visible: true,
            errors: {}
        })
    }

    handelSave = () => {
        this.setState({ errors: {} });
        saveTimeFrame(this.state.data)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    removeTimeFrame = () => {
        removeTimeFrame(this.state.data.id)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onStartTimeChange = (e) => {
        let maxTime = this.state.maxTime ? moment(this.state.maxTime) : '';
        let minTimeToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (minTimeToCompare && maxTime && minTimeToCompare.isAfter(maxTime));

        this.setState({
            data: {...this.state.data, 
                startTime: minTimeToCompare?minTimeToCompare.format('HH:mm'):'',
                endTime: !flag ? this.state.data.endTime : ''
            },
            startTimeValue: e.value,
            minTime: moment(e.value).isValid() ? e.value : '',
            endTimeValue: !flag ? this.state.endTimeValue : '',
            maxTime: !flag ? this.state.maxTime : ''
        });
    }

    onEndTimeChange = (e) => {
        let minTime = this.state.minTime ? moment(this.state.minTime) : '';
        let maxTimeToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (maxTimeToCompare && minTime && maxTimeToCompare.isBefore(minTime));

        this.setState({
            data: {...this.state.data, 
                startTime: !flag ? this.state.data.startTime : '',
                endTime: maxTimeToCompare?maxTimeToCompare.format('HH:mm'):''
            },
            startTimeValue: !flag ? this.state.startTimeValue : '',
            minTime: !flag ? this.state.minTime :'',
            endTimeValue: e.value,
            maxTime: moment(e.value).isValid() ? e.value : ''
        });
    }

    onHide = () => {
        this.setState({visible: false})
    }

    render() {
        return (
            <Sidebar visible={this.state.visible} className="p-sidebar-md" position="right" onHide={() => this.onHide()} blockScroll={true} style={{overflowY: 'auto'}}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12 p-md-6">
                        <label className="p-label">Start Time</label>
                        <MaskedCalendar value={this.state.startTimeValue} onChange={this.onStartTimeChange} maxDate={this.state.maxTime} showIcon={true} showTime={true} timeOnly={true} hourFormat='12' />
                        <div className="p-form-error">{this.state.errors.startTime}</div>
                    </div>
                    <div className="p-col-12 p-md-6">
                        <label className="p-label">End Time</label>
                        <MaskedCalendar value={this.state.endTimeValue} onChange={this.onEndTimeChange} minDate={this.state.minTime} showIcon={true} showTime={true} timeOnly={true} hourFormat='12' />
                        <div className="p-form-error">{this.state.errors.endTime}</div>
                    </div>
                </div>
                <div className="p-grid p-margin-top-30">
                    {this.state.data.id &&
                        <div className="p-col-12 p-md-4">
                            <Button label="Remove" icon="pi-md-trash" className="p-button-danger" onClick={() => this.removeTimeFrame()}/>
                        </div>
                    }
                    <div className={classNames("p-col-12 p-r", {"p-md-8": this.state.data.id})}>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.onHide()}/>
                        <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handelSave()}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}