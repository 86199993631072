import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import React, { useEffect, useRef, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { showNotification, showConfirmNotify } from '../../core/service/NotificationService';
import { DepartmentForm } from './DepartmentForm';
import { getPageDepartments, removeDepartment } from './DepartmentServices';

export const DepartmentListView = (props) => {
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(true);

    const departmentForm = useRef(null)

    useEffect(()=>{
        if(isLoadTable)
            loadPageDepartments()
    },[isLoadTable])

    const loadPageDepartments = () => {
        getPageDepartments(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblHeader = (
        <TableHeader title="Departments" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={<Button className="p-button-success" label="Create Department" icon="pi-md-plus" onClick={() => departmentForm.current.openForm(null)}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    const handleRemoveDepartment = (id) => {
        removeDepartment(id).then(res => {
            if(!res.errorCode){
                loadPageDepartments()
                showNotification('success', 'Success Message', 'Action submitted');;
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        });
    }

    const remove = (id) => {
        showConfirmNotify({
            accept: () => handleRemoveDepartment(id)
        })
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Departments</BreadcrumbsItem>

            <DepartmentForm ref={departmentForm}
                refreshTable={() => loadPageDepartments()}
            />

            <div className="p-col-12 datatableDepartment">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Departments</h1>
                </div>
                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column field="code" header="Code" sortable={true} />
                    <Column field="name" header="Name" sortable={true} />
                    <Column field="externalRef" header="Extenal Ref." sortable={true} body={(rowData) => rowData.externalRef?'Yes':'No'}/>
                    <Column field="weight" header="Weight" sortable={true} />
                    <Column className="dptActive" field="active" header="Active" body={rowData => <span className={classNames('status', {'active': rowData.active, 'inactive': !rowData.active})}>{rowData.active ? 'Yes' : 'No'}</span>}/>
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Edit', icon: 'pi pi-pencil', command: () => departmentForm.current.openForm(rowData)},
                                { label: 'Remove', icon: 'pi pi-times', command: () => remove(rowData.id)}
                            ]
                            return(
                                <SplitButton label="View" className="p-button-constrast p-l" model={actionItems} tooltip="Manage Department" tooltipOptions={{position: "top"}} onClick={() => props.history.push(`/departments/${rowData.id}`)}/>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
    
}