import React, { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { getPageParticipants, participantCheckin } from "../../constituent-management/participant/ParticipantServices";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport, getTimeZone } from "../../core/service/CommonService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { PARTICIPANT_STATUS } from "../../constants";
import { SplitButton } from "primereact/splitbutton";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { ParticipantTransferForm } from "../../constituent-management/participant/ParticipantTransferForm";
import { ConfirmNotification } from "../../core/components/ConfirmNotification";
import { ViewLogApplicationForm } from "../../scp/application/ViewLogApplicationForm";
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";

export const Participants = (props) => {
    const history = useHistory()

    const [pageable, setPageable] = useState({page: 0, rows: 10, total: 0, sortField: '', sortOrder: 0})
    const [dataList, setDataList] = useState([])
    const [isLoadTable, setLoadTable] = useState(true)

    const timezone = getTimeZone()

    const viewDate = props.viewDate;
    const tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
    const tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''

    const [filter, setFilter] = useState({
        statuses: [PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value, PARTICIPANT_STATUS.completed.value],
        dateFrom: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
        dateTo: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):'',
        branchId: props.viewBranch===0?null:props.viewBranch,
        orderIdNumber: '',
        invoiceNumber: '',
        buyer: ''
    })

    const viewLogForm = useRef()
    const transferForm = useRef()

    useEffect(() => {
        const viewDate = props.viewDate;
        let tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
        let tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''
        setFilter({
            ...filter,
            dateFrom: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
            dateTo: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):''
        });

        setLoadTable(true)
    }, [props.viewDate])

    useEffect(() => {
        setFilter({
            ...filter,
            branchId: props.viewBranch===0?null:props.viewBranch,
        });

        setLoadTable(true)
    }, [props.viewBranch])
    
    useEffect(() => {
        if(isLoadTable)
            loadPageParticipants()
    }, [isLoadTable])

    const loadPageParticipants = () => {
        getPageParticipants(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
            setLoadTable(false)
        })
    }

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true)
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true);
    };

    const clearFilter = () => {
        setFilter({
            ...filter,
            orderIdNumber: '',
            invoiceNumber: '',
            buyer: ''
        })

        setLoadTable(true)
    }

    const actionTemplate = (rowData) => {
        const strNow = timezone?moment().tz(timezone).format('YYYY-MM-DD HH:mm'):moment().format('YYYY-MM-DD HH:mm')

        const now = moment(strNow)
        const end = moment(rowData.end)

        let isExpired = false

        if(now.isAfter(end))
            isExpired = true

        if(!rowData.checkin && !isExpired){
            let items = [
                {label: 'View Log', icon: 'pi pi-search', command: () => viewLogForm.current.openForm(rowData.id)},
            ]

            // if(rowData.availabilityId && !isExpired){
            //     items.push({label: 'Transfer', icon: 'pi pi-arrow-right p-size-18', command: () => transferForm.current.openForm(rowData.id)})
            // }

            return <SplitButton label="Check-in" className="p-button-constrast p-l" model={items} onClick={() => handleCheckin(rowData)} />
        }else{
            return <Button label="View Log" className="p-button-constrast p-l" onClick={() => viewLogForm.current.openForm(rowData.id)} />
        }
    }

    const handleCheckin = (data) => {
        if([PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value].includes(data.status)){
            showConfirmNotify({
                message: 'The order related with participant has not been paid completely. Must process on order first!',
                acceptLabel: 'Yes, go to the order',
                accept: () => history.push(`/orders/${data.orderId}`)
            })
        }else{
            participantCheckin(data.id)
            .then(res => {
                if(!res.errorCode){
                    if(res.inCompletedLog){
                        viewLogForm.current.openForm(data.id)
                        showErrorNotify("Please complete the application log as required to continue!")
                    }else{
                        showSuccessNotify("Checked-in")
                        setLoadTable(true)
                    }
                }else{
                    showErrorNotify(res.errorMessage)
                }
            })
        }
    }

    const tblHeader = (
        <TableHeader title="Participants" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChagePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    )

    return (
        <div className="p-grid">
            <ParticipantTransferForm ref={transferForm} 
                refreshTable={() => setLoadTable(true)}
            />

            <ViewLogApplicationForm ref={viewLogForm} />
            
            <div className="p-col-12">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-text-bold">Filter By:</label>
                        <div className="p-grid p-fluid p-mt-1">
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Order Id/Code:</span>
                                    <InputText value={filter.orderIdNumber} onChange={e => setFilter({...filter, orderIdNumber: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                                </div>
                            </div>
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Invoice/Receipt Number:</span>
                                    <InputText value={filter.invoiceNumber} keyfilter={/^[0-9]*$/} onChange={e => setFilter({...filter, invoiceNumber: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                                </div>
                            </div>
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Buyer:</span>
                                    <InputText value={filter.buyer} onChange={e => setFilter({...filter, buyer: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)} placeholder="Name/email/phone"/>
                                </div>
                            </div>
                            <div className="p-col-3">
                                <Button label="Find" icon="pi pi-filter" iconPos="left" style={{width: 'auto'}} onClick={() => setLoadTable(true)} />
                                <Button label="Clear" icon="pi pi-trash" className="p-button-secondary p-ml-2" iconPos="left" style={{width: 'auto'}}  onClick={() => clearFilter()} />
                            </div>
                        </div>
                        {/* <div className="p-inputgroup">
                            <InputText value={filter.buyerName} onChange={e => onInputChange(e.target.value)} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)} placeholder="Type to search (order id/number, buyer name/email/phone)" />
                            {filter.buyerName &&<span className="p-inputgroup-addon" style={{padding: '0.5rem'}}><i className="pi pi-times p-size-18" onClick={() => {onInputChange(''); setLoadTable(true)}}></i></span>}
                            <Button label="Find" icon="pi pi-search" iconPos="left" onClick={() => setLoadTable(true)} />
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <DataTable
                    emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="ID" field="id" style={{width: '7%'}} className="p-c" body={rowData => <Button label={rowData.id} icon="pi pi-search" className="p-button-info" onClick={() => history.push(`/participants/${rowData.id}`)}/>} />
                    <Column header="Name" field="name" body={rowData => <React.Fragment><div>{rowData.name}</div>{rowData.email && <div className="p-mt-1">{rowData.email}</div>}</React.Fragment>} />
                    <Column header="Product Name" field="refName"/>
                    <Column header="Booking Status" field="status" className="p-c" body={rowData => <span className={classNames('status', rowData.status)}>{PARTICIPANT_STATUS[rowData.status] && PARTICIPANT_STATUS[rowData.status].label}</span>}/>
                    <Column header="Check-in" field="checkin" className="p-c" body={rowData => <span className={classNames('status', {'active': rowData.checkin, 'inactive': !rowData.checkin})}>{rowData.checkin?'Checked in':'Not yet'}</span>}/>
                    <Column header="Schedule" 
                        body={rowData => {
                            if(rowData.start || rowData.end){
                                const startDate = moment(rowData.start).format('MM/DD/YYYY')
                                const endDate = moment(rowData.end).format('MM/DD/YYYY')
                                const startTime = moment(rowData.start).format('hh:mm A')
                                const endTime = moment(rowData.end).format('hh:mm A')
                                return <React.Fragment>
                                    <div className="p-margin-bottom-5">Date: {startDate===endDate?startDate:(startDate+' - '+endDate)}</div>
                                    <div>Time: {startTime+' - '+endTime}</div>
                                </React.Fragment>    
                            }
                        }} 
                    />
                    <Column header="" body={actionTemplate} headerStyle={{width: '12em'}} bodyClassName="tc-actions"/>
                </DataTable>
            </div>
        </div>
    )
}