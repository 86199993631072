import React, {Component} from 'react';
import '../login/Login.css';
import {InputText} from "primereact/inputtext";
import { Button } from 'primereact/button';
import { UserServices } from '../../constituent-management/contact/UserServices';
import ReCAPTCHA from "react-google-recaptcha";

export default class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            account:'',
            password: '',
            captchaFlag : false,
            mailTemplateResetPassword: 0
        }

        this.userServices = new UserServices();
    }

    handleAccountEnter = (e) => {
        if (e.key === 'Enter'){
            
        }
    }

    handleChangePassword = () => {
        if(this.state.captchaFlag == false){
            alert("Captcha is required !");
        }else {
            this.userServices.resetPassword(this.state.account)
            .then(data => {
                if(data == "NOT_FOUND"){
                    alert("Invalid Username or Email");
                }else {
                    alert("New password has been sent to your inbox");
                }
            })
        }
    }

    // getMailTemplate = () => {
    //     this.configurationServices.getMailTemplate()
    //     .then(res => {
    //         this.setState({
    //             mailTemplateResetPassword: res.systemConfig.mailTemplateResetPassword ? res.systemConfig.mailTemplateResetPassword : 0
    //         });
    //     })
    // }

    componentDidMount() {
    }

    render() {
        return (
            <div className="login-body">
                 <div className="login-panel p-fluid">
                    <div className="login-panel-header">
                        <img className="logo" src="assets/fromlabs/logo.png" alt="Logo"/>
                    </div>
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h1>Reset Password</h1>
                            </div>
                            <div className="p-col-12 p-margin-bottom-20">
                                <label>Login Name or Email Address</label>
                                <InputText value={this.state.account} onChange={(e) => this.setState({account: e.target.value})} onKeyPress={this.handleAccountEnter}/>   
                            </div>
                            <div className="p-margin-left-10">
                            <ReCAPTCHA
                                sitekey="6LeTGcoUAAAAAI4N9_s7t_WGyOyocpOcT-OzSy0B"
                                onChange={(e) => this.setState({captchaFlag : true})}
                            />
                            </div>
                            <div className="p-col-12 p-margin-top-15">
                                <Button label="Submit" onClick={this.handleChangePassword}/>
                            </div>
                            <div className="p-col-12 ">
                                <Button label="Login" onClick={() => this.props.history.goBack()}/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}