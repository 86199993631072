import React, {Component} from "react";
import { getPageTransaction } from "./TransactionServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { APP_FEP } from "../../../constants";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { hasRole } from "../../../core/security/auth.jsx";
import { ROLES_CONFIG } from "../../../roles.js";
import { SplitButton } from "primereact/splitbutton";
import classNames from 'classnames';
import { getTablePageReport } from "../../../core/service/CommonService";

export class Transactions extends Component{

    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            index: 0,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            loading: true,
            filter: {
                app: APP_FEP
            }
        }
    }

    componentDidMount(){
        this.loadPageTransaction();
    }

    loadPageTransaction = () => {
        getPageTransaction(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            this.setState({
                dataList: res.content,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
        })
    }

    onPage = (e) => {
        this.setState({
            page: e.page
        },() => this.loadPageTransaction())
    }

    onSort = (e) => {
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => this.loadPageTransaction())
    }

    actionTemplate = (rowData) =>{
        if(hasRole([ROLES_CONFIG.TRANSACTION_DEAIL_R])){
            return (
                <Button label="View" className="p-button-constrast" style={{width: '7em'}} tooltip='View Order' tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(`/transactions/${rowData.id}`)}/>
            )
            // <div>
            //     <Link to={`/transactions/${rowData.id}`}>
            //         <Button icon="pi pi-search" className="p-button-warning" tooltip="View Transaction" tooltipOptions={{position: 'top'}}></Button>
            //     </Link>
            // </div>;
        }
    }

    applyFilter = () => {
        this.loadPageTransaction();
    };

    onChagePageLength = l => {
        this.setState(
            {
                rows: l,
                page: 0
            },
            () => {
                this.applyFilter();
            }
        );
    };

    statusTemplate = (rowData) => {
        return (
            <span className={classNames('status', rowData.status)}>{rowData.statusName}</span>
        );
    }

    render(){

        let tableLengthOptions = [
            {
                label: '5',
                command: () => {
                    this.onChagePageLength(5);
                }
            },
            {
                label: '10',
                command: () => {
                    this.onChagePageLength(10);
                }
            },
            {
                label: '25',
                command: () => {
                    this.onChagePageLength(25);
                }
            },
            {
                label: '50',
                command: () => {
                    this.onChagePageLength(50);
                }
            },
            {
                label: '100',
                command: () => {
                    this.onChagePageLength(100);
                }
            }
        ];

        let header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                {/* <Button icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: 'top'}} style={{'float':'right'}} onClick={() => this.getPageTransaction(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}/> */}
                <div style={{ float: "right" }}>
                    {/* Show:{" "}
                    <Dropdown
                        className="p-datatable-length-selector"
                        value={this.state.rows}
                        options={TABLE_PAGING_LENGTH}
                        onChange={e => this.onChagePageLength(e)}
                        style={{ minWidth: "0" }}
                    /> */}
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                        onClick={() => this.loadPageTransaction()} model={tableLengthOptions}>
                    </SplitButton>
                </div>
                <h3 className="fle-datatable-header-title">{getTablePageReport(this.state.page, this.state.rows, this.state.total)}</h3>
            </div>;

        return(
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Transactions</BreadcrumbsItem>
            
                <div className="p-col-12 datatableOrder" >
                    <div className="card card-w-title">
                        <h1>Transactions</h1>
                        <DataTable lazy={true} value={this.state.dataList}
                            scrollable={true}
                            header={header}
                            first={this.state.page * this.state.rows}
                            onPage={this.onPage} onSort={this.onSort}
                            rows={this.state.rows} totalRecords={this.state.total}
                            paginator={true}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        >
                            <Column header="ID" field="id" sortable={true} style={{textAlign: 'center', width: '2em'}} />
                            <Column header="Code" field="transId" sortable={true} style={{width: '5em'}} />
                            <Column header="Email" field="email" sortable={true} style={{width: '4em'}}
                                body={(rowData) => {
                                    return ( <a href={rowData.email ? 'mailto:'+rowData.email : '#'}>{rowData.email}</a> );
                                }}
                            />
                            <Column header="Channel" field="channel" sortable={true} body={(rowData) => rowData.channelName} style={{textAlign: 'center', width: '3em'}} />
                            <Column header="GateWay" field="gateway" sortable={true} style={{textAlign: 'center', width: '3em'}} />
                            <Column header="Amount" field="amount" body={(rowData) => {return (rowData.amount + ' ' + rowData.currencyShortSign)}} sortable={true} style={{textAlign:'right', width: '3em'}} />
                            <Column header="Purchase At" field="created" sortable={true} style={{ textAlign: 'center', width: '3em'}} />
                            <Column header="Status" field="status" body={this.statusTemplate} sortable={true} style={{textAlign: 'center', width: '3em'}} />
                            <Column header="" body={this.actionTemplate} style={{textAlign:'center', width:'3em'}} />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}