import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from "react";
import moment, { HTML5_FMT } from 'moment';
import classNames from 'classnames';
import {SelectButton} from 'primereact/selectbutton';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import {showErrorNotify, showSuccessNotify} from '../../core/service/NotificationService';
import { Sidebar } from "primereact/sidebar";
import { Spinner } from "primereact/spinner";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { RadioButton } from "primereact/radiobutton";
import Select from 'react-dropdown-select';
import { PRODUCT_CLASSIFICATION, APP_FEP, PRODUCT_TYPE, TAXONOMY_TYPE, HR_RESOURCE_TYPE, ATTRIBUTE_GROUP_TYPE, PRODUCT_IMAGE_TYPE, PRICE_APPLY_TYPE, GALLERY_TYPE, PRODUCT_PRICE_UNIT, PRODUCT_OPTION_TYPE, VARIANT_OPTION_TYPE, TIME_SERIES_REPEAT_TYPE, DEPOSIT_TYPE, UPLOAD_TYPE } from "../../constants";
import { titleCaseText, replaceSpaceAndUpperCase } from "../../core/service/CommonService";
import { saveProduct, getProduct, saveProductContent, saveProductAsset, changeProductSetField } from "./ProductServices";
import { getListDepartments } from "../../scp/departments/DepartmentServices";
import { getListTaxons } from "../taxon/TaxonServices";
import { getListHRs } from "../../crm/human_resource/HRServices";
import { getListAttributeSets } from "../../scp/attribute/AttributeServices";
import { ProductPrices } from "./ProductPrice";
import CKEditor from 'ckeditor4-react';
import { ProductGallery } from "./ProductGallery";
import { ProductAvailability } from "./ProductAvailability";
import { ProductVariant } from "./ProductVariant";
import { ProductAssociated } from "./ProductAssociation";
import { getListAssets } from "../asset/AssetServices";
import { Chips } from "primereact/chips";
import { getListProductOptions } from "../product_option/ProductOptionServices";
import { ProductCustomFieldForm } from "./ProductCustomFieldForm";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { getTaxes } from "../tax/TaxSerivce";
import { getApplications } from "../../scp/application/ApplicationService";
import { showloading, stoploading } from "../../core/service/LoadingService";

CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

const CREATE_TYPE = {
    manually: 0,
    autoDaily: 1
}

const PRODUCT_MENU_ITEMS = {
    basic: {label: 'Basic Information', value: 'basic'},
    content: {label: 'Content', value: 'content'},
    variant: {label: 'Variant', value: 'variant'},
    inventory: {label: 'Inventory', value: 'inventory'},
    availability: {label: 'Availability', value: 'availability'},
    associated: {label: 'Associated Products', value: 'associated'},
    asset: {label: 'Related Assets', value: 'asset'}
}; 

const DEFAULT_TIME_SERIES_DATA = {
    createType: CREATE_TYPE.manually,
    repeatType: TIME_SERIES_REPEAT_TYPE.daily,
    repeatValues: [],
    dateRange: false,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
}

const PRODUCT_TYPES = [
    PRODUCT_TYPE.program,
    PRODUCT_TYPE.rental,
    PRODUCT_TYPE.membership
]

export const ProductForm = forwardRef((props, ref) => {
    const productCustomProperty = useRef(null);
    const [header, setHeader] = useState('Add Product Option')
    const [visible, setVisible] = useState(false)
    const [menuActive, setMenuActive] = useState(PRODUCT_MENU_ITEMS.basic.value)
    const [productClass, setProductClass] = useState(null)
    const [productId, setProductId] = useState(null)
    const [frmData, setFrmData] = useState({application: APP_FEP, tags: [], rentalLimit: {}})
    const [timeSeriesData, setTimeSeriesData] = useState(DEFAULT_TIME_SERIES_DATA)
    const [taxes, setTaxes] = useState([])
    const [branches, setBranches] = useState([])
    const [departments, setDeparments] = useState([])
    const [categories, setCategories] = useState([])
    const [locations, setLocations] = useState([])
    const [variantOptions, setVariantOptions] = useState([])
    const [assetCategories, setAssetCategories] = useState([])
    const [assets, setAssets] = useState([])
    const [marshallers, setMarshallers] = useState([])
    const [presenters, setPresenters] = useState([])
    const [customFieldSets, setCustomFieldSets] = useState([])
    const [waiverFieldSets, setWaiverFieldSets] = useState([])
    const [errors, setErrors] = useState({prices: {}})
    const [isLoadData, setLoadData] = useState(false)
    const [applications, setApplications] = useState([])
    const [appConfig, setAppConfig] = useState(null)

    useImperativeHandle(ref, () => ({
        openForm(c, e){
            setHeader((e?'Edit':'Add') + ' ' + PRODUCT_CLASSIFICATION[c].label + ' Product')
            setProductId(e)
            setProductClass(c)
            setMenuActive(PRODUCT_MENU_ITEMS.basic.value)
            setLoadData(true)
        }
    }))

    useEffect(() => {
        let appConfig = localStorage.getItem('appConfig')
        appConfig = appConfig && appConfig!=='undefined' && appConfig!=='null' ? JSON.parse(appConfig) : {}
        setAppConfig(appConfig)

        loadAllTaxes()
        loadAllBranchs()
        loadAllDepartments()
        loadApplications()
    }, [])

    useEffect(() => {
        if(frmData.classification===PRODUCT_CLASSIFICATION.simple.value){
            if(frmData.type===PRODUCT_TYPE.program.value){
                loadAllLocations()
                loadAllAttributeSet(ATTRIBUTE_GROUP_TYPE.custom_field)
                loadAllAttributeSet(ATTRIBUTE_GROUP_TYPE.waiver)
            }

            if(frmData.type===PRODUCT_TYPE.program.value || frmData.type===PRODUCT_TYPE.session.value || frmData.type===PRODUCT_TYPE.rental.value){
                loadAllHRs(HR_RESOURCE_TYPE.marshaller.value)

                if(frmData.type!==PRODUCT_TYPE.rental.value)
                    loadAllHRs(frmData.type===PRODUCT_TYPE.program.value?HR_RESOURCE_TYPE.trainer.value:HR_RESOURCE_TYPE.speaker.value)

                if(frmData.type===PRODUCT_TYPE.program.value)
                    setTimeSeriesData(DEFAULT_TIME_SERIES_DATA)
            }
        }
    }, [frmData.type])

    useEffect(() => {
        if(frmData.classification===PRODUCT_CLASSIFICATION.variable.value)
            loadAllProductOptions()
    }, [frmData.classification])

    useEffect(() => {
        loadAllCategories(frmData.departmentId);
    }, [frmData.departmentId])

    useEffect(() => {
        loadAllAssetByCategory(frmData.assetCategoryId);
    }, [frmData.assetCategoryId])

    const loadAllTaxes = () => {
        getTaxes().then(res => setTaxes(res))
    }

    const loadAllBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}).then(res => setBranches(res))
    }

    const loadAllDepartments = () => {
        getListDepartments({externalRef: false}).then(res => setDeparments(res))
    }

    const loadAllCategories = (deptId) => {
        if(!deptId){
            setCategories([])
            return;
        }

        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [deptId]}).then(res => setCategories(res))
    }

    const loadAllAssetCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value]}).then(res => setAssetCategories(res))
    }

    const loadAllLocations = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.location.value}).then(res => setLocations(res))
    }

    const loadAllHRs = (type) => {
        getListHRs({type: type})
        .then(res => {
            switch (type) {
                case HR_RESOURCE_TYPE.marshaller.value:
                    setMarshallers(res)
                    break;
                case HR_RESOURCE_TYPE.trainer.value:
                case HR_RESOURCE_TYPE.speaker.value:
                    setPresenters(res)
                    break;
                default: break;
            }
        })
    }

    const loadAllProductOptions = () => {
        getListProductOptions({app: APP_FEP, type: PRODUCT_OPTION_TYPE.variant.value})
        .then(res => {
            setVariantOptions(res)
        });
    }

    const loadAllAttributeSet = (type) => {
        getListAttributeSets(type)
        .then(res => {
            switch (type) {
                case ATTRIBUTE_GROUP_TYPE.custom_field:
                    setCustomFieldSets(res)
                    break;
                case ATTRIBUTE_GROUP_TYPE.waiver:
                    setWaiverFieldSets(res)
                    break;
                default: break;
            }
        });
    }

    const loadAllAssetByCategory = (categoryId) => {
        if(!categoryId){
            setAssets([])
            return;
        }

        getListAssets({app: APP_FEP, categoryId: categoryId}).then(res => setAssets(res))
    }

    const loadApplications = () => {
        getApplications()
        .then(data => {
            setApplications(data?data:[])
        })
    }

    useEffect(() => {
        if(isLoadData){
            async function loadProduct(){
                if(productId){
                    const res = await getProduct(productId);

                    if(!res.errorCode){
                        setFrmData(popularFormData(res))
                        if(res.type && res.type===PRODUCT_TYPE.rental.value)
                            loadAllAssetCategories()
                        setErrors({prices: {}})
                        setVisible(true)
                    }else{
                        showErrorNotify(res.errorMessage)
                        setVisible(false)
                    }
                } else {
                    setFrmData(popularFormData(null))
                    setAssetCategories([])
                    setErrors({prices: {}})
                    setVisible(true)
                }
            }

            loadProduct()
        }
    }, [isLoadData])

    const closeForm = () => {
        setVisible(false)
        setLoadData(false)
        setProductId(null)
        setFrmData(popularFormData())
        setTimeSeriesData(DEFAULT_TIME_SERIES_DATA)
        setErrors({prices: {}})
    }

    const getApplicationLogDefault = (productType) => {
        let tmpApplicationLogs = []
        if(productType){
            if(appConfig.programConfig && appConfig.programConfig.applicationLogs && appConfig.programConfig.applicationLogs[productType]){
                tmpApplicationLogs = appConfig.programConfig.applicationLogs[productType]
            }     
        }
        return tmpApplicationLogs
    }

    const popularFormData = (e) => {        
        const data = {
            id: e?e.id:'',
            code: e?e.code:'',
            name: e?e.name:'',
            application: APP_FEP,
            classification: e?e.classification:productClass,
            type: e?e.type:(productClass===PRODUCT_CLASSIFICATION.simple.value || productClass===PRODUCT_CLASSIFICATION.variable.value)?PRODUCT_TYPE.program.value:null,
            description: e?e.description:'',
            content: e?e.content:null,
            teaserImage: e&&e.teaserImage&&Object.keys(e.teaserImage).length>0?e.teaserImage:null,
            teaserImageUrl: e&&e.teaserImage?(e.teaserImage.fileName?'':e.teaserImage.url):'',
            galleryImages: e&&e.galleryImages?e.galleryImages:[],
            videos: e&&e.videos?e.videos:[],
            marshallerId: e&&e.marshaller?e.marshaller.id:null,
            presenterId: e&&e.presenter?e.presenter.id:null,
            showPresenter: e&&e.showPresenter,
            tags: e&&e.tags?e.tags:[],
            applyTax: e? e.applyTax : false,
            taxId: e?e.taxId:appConfig.defaultTax,
            weight: e?e.weight:0,
            active: e?e.active:true,
            show: e?e.show:false,
            allowViewDetails:e&&e.allowViewDetails,
            free: e?e.free:false,
            pricedBySelected: e?e.pricedBySelected:false,
            prices: e&&e.prices?e.prices:[{applyFor: PRICE_APPLY_TYPE.normal.value, price: '', unit: PRODUCT_PRICE_UNIT.day.value, quantity: 1}],
            allowDeposit: e?e.allowDeposit:false,
            depositType: e && e.allowDeposit ? e.depositType : null,
            allowPayFull: e?e.allowPayFull:true,
            subscription: e?e.subscription:false,
            rentalLimit: e&&e.rentalLimit?e.rentalLimit:{minUnit: PRODUCT_PRICE_UNIT.hr.value, maxUnit: PRODUCT_PRICE_UNIT.hr.value},
            depositAmount: e?e.depositAmount:'',
            branchId: e?e.branchId:null,
            branchName: e?e.branchName:null,
            departmentId: e?e.departmentId:null,
            categoryIds: e?e.categoryIds:[],
            locationId: e?e.locationId:null,
            productCustomProperty: e?e.productCustomProperty: null,
            waiverFieldSetId: e?e.waiverFieldSetId:null,
            useApplicationLog: e&&e.useApplicationLog,
            applicationLogs: e&&e.applicationLogs?e.applicationLogs:getApplicationLogDefault(e?e.type:PRODUCT_TYPE.program.value),
            optionSelect: e&&e.options?e.options.map(o => o.id):[],
            options: e?e.options:[],
            pricedByVariant: e?e.pricedByVariant:false,
            variantOptionType: e&&e.classification===PRODUCT_CLASSIFICATION.variable.value?e.variantOptionType:(productClass===PRODUCT_CLASSIFICATION.variable.value?VARIANT_OPTION_TYPE.option.value:null),
            variantOptionSelect: e&&e.classification===PRODUCT_CLASSIFICATION.variable.value&&e.variantOptionType===VARIANT_OPTION_TYPE.option.value&&e.options?e.options.map(o => o.id):[],
            variantSelectionType: e?e.variantSelectionType:0,
            variants: e?e.variants:[],
            associatedGroups: e?e.associatedGroups:[],
            assetCategoryId: e?e.assetCategoryId:null,
            assetAcrossBranches: e?e.assetAcrossBranches:[],
            assetExcludedIds: e?e.assetExcludedIds:[],
            slot: e&&e.slot?e.slot:'',
        }

        return data
    }

    const onTypeChange = (e) => {
        let isFree = frmData.free
        if([PRODUCT_TYPE.rental.value, PRODUCT_TYPE.donation.value].includes(e.value))
            isFree = false

        let tmpApplicationLogs = getApplicationLogDefault(e.value)
        if(frmData.id){
            tmpApplicationLogs = frmData.applicationLogs
        }
        setFrmData({...frmData, type: e.value, free: isFree, applicationLogs: tmpApplicationLogs})
    }

    const onChangeAssetAcrossBranch = (values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setFrmData({...frmData, assetAcrossBranches: arrValues})
    }

    const onDepartmentChange = (e) => {
        setFrmData({...frmData, departmentId: e, categoryIds: []})
    }

    const onChangeCategory = (values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setFrmData({...frmData, categoryIds: arrValues})
    }

    const onWeekDaysChange = (e) => {
        let selectedValues = [...timeSeriesData.repeatValues]

        if(e.checked)
            selectedValues.push(e.value)
        else
            selectedValues.splice(selectedValues.indexOf(e.value), 1)

        setTimeSeriesData({...timeSeriesData, repeatValues: selectedValues})
    }

    const onDateChange = (e, key) => {
        switch(key){
            case 'start':
                setTimeSeriesData({...timeSeriesData, startDateValue: e})
                break;
            case 'end':
                setTimeSeriesData({...timeSeriesData, endDateValue: e})
                break;
            default: break;
        }        
    }

    const onDateBlur = (e, key) => {
        switch(key){
            case 'start':
                if(!timeSeriesData.startDateValue || !moment(timeSeriesData.startDateValue).isValid())
                    setTimeSeriesData({...timeSeriesData, startDateValue: null})
                break;
            case 'end':
                if(!timeSeriesData.endDateValue || !moment(timeSeriesData.endDateValue).isValid())
                    setTimeSeriesData({...timeSeriesData, endDateValue: null})
                break;
            default: break;
        }
    }

    const onTimeChange = (e, key) => {
        switch(key){
            case 'start':
                setTimeSeriesData({...timeSeriesData, startTimeValue: e})
                break;
            case 'end':
                setTimeSeriesData({...timeSeriesData, endTimeValue: e})
                break;
            default: break;
        }
    }

    const onTimeBlur = (key) => {
        switch(key){
            case 'start':
                if(!timeSeriesData.startTimeValue || !moment(timeSeriesData.startTimeValue).isValid())
                    setTimeSeriesData({...timeSeriesData, startTimeValue: null})
                break;
            case 'end':
                if(!timeSeriesData.endTimeValue || !moment(timeSeriesData.endTimeValue).isValid())
                    setTimeSeriesData({...timeSeriesData, endTimeValue: null})
                break;
            default: break;
        }
    }

    const onChangeVariantOptions = (values, type) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        if(type==='variant')
            setFrmData({...frmData, variantOptionSelect: arrValues})
        else
            setFrmData({...frmData, propertyOptionSelect: arrValues})
    }

    const onChangeAssetExclude = (values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setFrmData({...frmData, assetExcludedIds: arrValues})
    }

    const onChangeApplicationLog = (values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setFrmData({...frmData, applicationLogs: arrValues})
    }

    const handleSaveProduct = () => {
        showloading()

        let data = {...frmData}
        let images = []

        if(data.teaserImageUrl || frmData.teaserImageFile){
            if(frmData.teaserImageFile){
                let maxFileSize = 104857600 // 100MB
                if(frmData.teaserImageFile){
                    let fileSize = frmData.teaserImageFile.size
                    if(fileSize > maxFileSize){
                        setErrors({image: 'Size not allowed (maximum: 100MB)'})
                        return
                    }
                }
            }
            images.push(frmData.teaserImage = {
                type: PRODUCT_IMAGE_TYPE.teaser,
                uploadType: frmData.uploadType,
                url: !frmData.uploadType||frmData.uploadType===UPLOAD_TYPE.external_url.value?frmData.teaserImageUrl:'',
                file: frmData.uploadType===UPLOAD_TYPE.upload_file.value?frmData.teaserImageFile:null
            })
        }
        data.images = images.concat(data.galleryImages)

        if(!frmData.id && frmData.classification!==PRODUCT_CLASSIFICATION.combo.value && frmData.classification!==PRODUCT_CLASSIFICATION.configuration.value && frmData.type===PRODUCT_TYPE.program.value){
            let tmpTimeSeriesData = {...timeSeriesData}
            tmpTimeSeriesData.startDate = tmpTimeSeriesData.startDateValue && moment(tmpTimeSeriesData.startDateValue).isValid() ? moment(tmpTimeSeriesData.startDateValue).format(HTML5_FMT.DATE) : ''
            tmpTimeSeriesData.endDate = tmpTimeSeriesData.endDateValue && moment(tmpTimeSeriesData.endDateValue).isValid() ? moment(tmpTimeSeriesData.endDateValue).format(HTML5_FMT.DATE) : ''
            tmpTimeSeriesData.startTime = tmpTimeSeriesData.startTimeValue && moment(tmpTimeSeriesData.startTimeValue).isValid() ? moment(tmpTimeSeriesData.startTimeValue).format(HTML5_FMT.TIME) : ''
            tmpTimeSeriesData.endTime = tmpTimeSeriesData.endTimeValue && moment(tmpTimeSeriesData.endTimeValue).isValid() ? moment(tmpTimeSeriesData.endTimeValue).format(HTML5_FMT.TIME) : ''
            data.timeSeries = tmpTimeSeriesData
        }
        
        saveProduct(data)
        .then(res => {
            if(!res.errorCode){
                setFrmData(popularFormData(res))

                if(!data.id && data.type===PRODUCT_TYPE.rental.value)
                    loadAllAssetCategories()

                setProductId(res.id)
                setErrors({prices: {}})
                
                if(props.refreshTable)
                    props.refreshTable();

                showSuccessNotify('Product saved!')
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    setErrors(res.errorObj);
            }

            stoploading()
        })
    }

    const handleSaveProductContent = () => {
        showloading()

        let data = {...frmData}

        if(frmData.galleryImages){
            let maxFileSize = 104857600 // 100MB
            let totalFileSize = 0
            for (const galleryImage of frmData.galleryImages) {
                if(galleryImage.file){
                    totalFileSize += galleryImage.file.size
                }
            }    
            if(totalFileSize > maxFileSize){
                setErrors({image: 'Total file size not allowed (maximum: 100MB)'})
                return
            }
        }

        let images = []

        if(frmData.teaserImageUrl){
            images.push(frmData.teaserImage = {
                type: PRODUCT_IMAGE_TYPE.teaser,
                url: frmData.teaserImageUrl
            })
        }
        data.images = images.concat(frmData.galleryImages)

        saveProductContent(data)
        .then(res => {
            if(!res.errorCode){
                setFrmData(popularFormData(res))
                if(props.refreshTable)
                    props.refreshTable();
                showSuccessNotify('Product saved!')
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    setErrors(res.errorObj);
            }

            stoploading()
        })
    }

    const handleSaveProductAsset = () => {
        showloading()

        saveProductAsset(frmData)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Product saved!')
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    setErrors(res.errorObj);
            }

            stoploading()
        })
    }

    const renderProductMenuItems = () => {
        const c = frmData.classification;
        const t = frmData.type;

        let items = [PRODUCT_MENU_ITEMS.basic, PRODUCT_MENU_ITEMS.content];

        if((c===PRODUCT_CLASSIFICATION.simple.value && frmData.variants.length>0)){
            items.push(PRODUCT_MENU_ITEMS.inventory)
        }

        if(c===PRODUCT_CLASSIFICATION.variable.value)
            items.push(PRODUCT_MENU_ITEMS.variant)

        if(c!==PRODUCT_CLASSIFICATION.combo.value && c!==PRODUCT_CLASSIFICATION.configuration.value && (t===PRODUCT_TYPE.program.value || t===PRODUCT_TYPE.session.value))
            items.push(PRODUCT_MENU_ITEMS.availability)
            
        if((c===PRODUCT_CLASSIFICATION.combo.value || c===PRODUCT_CLASSIFICATION.configuration.value || ((c===PRODUCT_CLASSIFICATION.simple.value || c===PRODUCT_CLASSIFICATION.variable.value) && t===PRODUCT_TYPE.program.value)))
            items.push(PRODUCT_MENU_ITEMS.associated)

        if((c===PRODUCT_CLASSIFICATION.simple.value || c===PRODUCT_CLASSIFICATION.variable.value) && t===PRODUCT_TYPE.rental.value)
            items.push(PRODUCT_MENU_ITEMS.asset)

        return items
    }

    const viewProductCustomFields = () => {
        productCustomProperty.current.popularFormData(frmData.productCustomProperty);
    }

    const onCustomFieldChange = (customFields, isRemoved) => {
        let data = {
            ...frmData,
            productCustomProperty: {
                ...frmData.productCustomProperty,
                customFields: customFields
            }
        }

        saveProduct(data)
        .then(res => {
            if(!res.errorCode){
                if(props.refreshTable)
                    props.refreshTable();

                setFrmData(popularFormData({...frmData, productCustomProperty: res.productCustomProperty}))
                setProductId(res.id)
                setErrors({prices: {}})
                
                productCustomProperty.current.popularFormData(res.productCustomProperty.customFields);
                
                if(isRemoved) {
                    showSuccessNotify('Field removed!')
                } else {
                    showSuccessNotify('Field updated!')
                }

            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    setErrors(res.errorObj);
            }
        })
    }

    const onCreateTypeChange = (e) => {
        setTimeSeriesData({
            ...timeSeriesData, 
            createType: e,
            dateRange: e===CREATE_TYPE.autoDaily?true:false
        })
    }

    const handleChangeSetField = (customSetId, customSetName) => {
        changeProductSetField(frmData.id, customSetId, customSetName)
        .then(data => {
            if(!data.errorCode) {
                if(props.refreshTable)
                    props.refreshTable();

                setFrmData(popularFormData({...frmData, productCustomProperty: data}))
                setErrors({prices: {}})
                
                productCustomProperty.current.popularFormData(data);
                productCustomProperty.current.clearSetId();
                showSuccessNotify('Field set changed!')
            } else {
                showErrorNotify(data.errorMessage);
                if(data.errorCode===400)
                    setErrors(data.errorObj);
            }
        })
    }
    
    const reloadTable = (res) => {
        if(props.refreshTable) {
            props.refreshTable();
        }

        setFrmData(popularFormData(res))

        productCustomProperty.current.popularFormData(res.productCustomProperty.customFields);
    }

    return (
        <React.Fragment>
            <ProductCustomFieldForm ref={productCustomProperty} 
                productId={frmData.id}
                reloadTable={(res) => reloadTable(res)}
                onCustomFieldChange={(customFields, isRemoved) => onCustomFieldChange(customFields, isRemoved)}
                onChangeFieldSet={(customSetId, customSetName) => handleChangeSetField(customSetId, customSetName)}
            />
            
            <Sidebar visible={visible} position="right" className="p-sidebar-lg"  style={{ overflowY: "auto"}} blockScroll={true} onHide={closeForm}>
                <h2>{header}</h2>
                
                <div className="p-grid">
                    <div className="p-col-12">
                        {frmData.id && 
                        <React.Fragment>
                            {menuActive!==PRODUCT_MENU_ITEMS.basic.value &&
                            <React.Fragment>
                                <div>Product: <strong>{frmData.code} - {frmData.name}</strong></div>
                                <div className="p-mt-2 p-mb-3">Branch: <strong>{frmData.branchName}</strong></div>
                            </React.Fragment>
                            }
                            <SelectButton value={menuActive} className="p-margin-10-0" options={renderProductMenuItems()} onChange={(e) => e&&e.value&&setMenuActive(e.value)}></SelectButton>
                            <div className="p-hr p-margin-bottom-20"></div>
                        </React.Fragment>
                        }

                        <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.basic.value})}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="Basic Information">
                                        <div className="p-grid p-fluid form-group">
                                            {!frmData.id?
                                            [PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(frmData.classification) &&
                                            <div className="p-col-12">
                                                <label className="p-label">* Type</label>
                                                <Dropdown value={frmData.type} options={PRODUCT_TYPES} onChange={(e) => onTypeChange(e)} placeholder="Select a product type"/>
                                                <span className="p-form-error">{errors.type}</span>
                                            </div>
                                            :
                                            <div className="p-col-12">
                                                {[PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(frmData.classification) &&
                                                <div className="p-mb-2">Product Type: <strong>{PRODUCT_TYPE[frmData.type].label}</strong></div>
                                                }
                                                <div>Branch: <strong>{frmData.branchName}</strong></div>
                                                <hr/>
                                            </div>
                                            }
                                        </div>
                                        <div className="p-grid p-fluid form-group">
                                            {!frmData.id && branches.length>1 && 
                                            <div className="p-col-12">
                                                <label className="p-label">* Branch</label>
                                                <Dropdown value={frmData.branchId} options={branches} onChange={(e) => setFrmData({...frmData, branchId: e.value})} placeholder="Select branch"/>
                                                <span className="p-form-error">{errors.branch}</span>
                                            </div>
                                            }
                                            <div className="p-col-12 p-md-4">
                                                <label className="p-label">* Code</label>
                                                <InputText value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({...frmData, code: replaceSpaceAndUpperCase(e.target.value)})} />
                                                <span className="p-form-error">{errors.code}</span>
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <label className="p-label">* Name</label>
                                                <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: titleCaseText(e.target.value)})} />
                                                <span className="p-form-error">{errors.name}</span>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Teaser image url</label>
                                                <div className="p-inputgroup">
                                                    {((!frmData.uploadType && frmData.teaserImage && !frmData.teaserImage.fileName) || frmData.uploadType===UPLOAD_TYPE.external_url.value) ?
                                                        <React.Fragment>
                                                            <InputText value={frmData.teaserImageUrl} onChange={(e) => setFrmData({...frmData, teaserImageUrl: e.target.value})} />
                                                            <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                                        </React.Fragment>
                                                        :<React.Fragment>
                                                            {frmData.teaserImage && frmData.teaserImage.fileName &&
                                                                <span className="p-inputgroup-addon"><img src={frmData.teaserImage.url} alt={frmData.teaserImage.fileName} style={{width: 25, height: 25}} /></span>
                                                            }
                                                            <input className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => setFrmData({...frmData, teaserImageFile: e.target.files[0]})} />
                                                            <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value})} />
                                                        </React.Fragment> 
                                                    }
                                                </div>
                                                <div className="p-form-error">{errors.image}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Description</label>
                                                <InputTextarea value={frmData.description} rows="5" onChange={(e) => setFrmData({...frmData, description: e.target.value})} />
                                                <span className="p-form-error">{errors.description}</span>
                                            </div>
                                            {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value, PRODUCT_TYPE.session.value].includes(frmData.type) && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-label">Marshaller</label>
                                                    <Dropdown value={frmData.marshallerId} options={marshallers} onChange={(e) => setFrmData({...frmData, marshallerId: e.value})} placeholder="Select marshaller"/>
                                                </div>
                                                {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(frmData.type) && 
                                                <div className="p-col-12">
                                                    <label className="p-label">{frmData.type===PRODUCT_TYPE.program.value?'Trainer':'Speaker'}</label>
                                                    <div className="p-inputgroup">
                                                        <Dropdown value={frmData.presenterId} options={presenters} onChange={(e) => setFrmData({...frmData, presenterId: e.value})} placeholder={"Select " + (frmData.type===PRODUCT_TYPE.program.value?'Trainer':'Speaker')}/>
                                                        <span className="p-inputgroup-addon">
                                                            <Checkbox inputId="ckShowPresenter" checked={frmData.showPresenter} onChange={e => setFrmData({...frmData, showPresenter: e.checked})} />
                                                            <label htmlFor="ckShowPresenter">Is show?</label>
                                                        </span>
                                                    </div>
                                                </div>
                                                }
                                                {frmData.type===PRODUCT_TYPE.program.value && 
                                                <div className="p-col-12">
                                                    <label className="p-label">Location</label>
                                                    <Dropdown value={frmData.locationId} options={locations} onChange={(e) => setFrmData({...frmData, locationId: e.value})} filter={true} style={{ width: '100%' }} placeholder="Select a location" />
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                            {/* <div className="p-col-12">
                                                <Checkbox inputId="cbApplyTax" checked={frmData.applyTax} onChange={(e) => (setFrmData({...frmData, applyTax: e.checked}))} />
                                                <label htmlFor="cbApplyTax" className="p-margin-left-5">Apply tax?</label>
                                            </div> */}
                                        </div>
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12 p-md-4">
                                                <label className="p-label">Weight (for order)</label>
                                                <InputText value={frmData.weight} keyfilter="int" onChange={(e) => setFrmData({...frmData, weight: e.target.value })} />
                                            </div>
                                            <div className="p-col-12 p-md-8">
                                                <div className="p-grid p-fluid form-group">
                                                    {/* <div className="p-col-12" style={{paddingTop: '27px'}}>
                                                        <InputSwitch checked={frmData.active} onChange={(e) => setFrmData({...frmData, active: e.value })} />
                                                        <label className="p-margin-left-10">Active?</label>
                                                    </div> */}
                                                    <div className="p-col-12">
                                                        <InputSwitch checked={frmData.show} onChange={(e) => setFrmData({...frmData, show: e.value })} />
                                                        <label className="p-margin-left-10">Show in store as product?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <InputSwitch checked={frmData.allowViewDetails} onChange={(e) => setFrmData({...frmData, allowViewDetails: e.value })} />
                                                        <label className="p-margin-left-10">Allow view details?</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Hierarchy">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">{frmData.show&&'* '}Department</label>
                                                <Dropdown value={frmData.departmentId} options={departments} onChange={(e) => onDepartmentChange(e.value)} filter={true} style={{ width: '100%' }} placeholder="Select a department" />
                                                <span className="p-form-error">{errors.department}</span>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">{frmData.show&&'* '}Category <span className="p-form-error">(value depended on selected department)</span></label>
                                                <Select multi
                                                    values={categories && frmData.categoryIds && frmData.categoryIds.length>0 ? categories.filter(x1 => frmData.categoryIds.some(x2 => x2 === x1.value)) : []}
                                                    options={categories} onChange={(values) => onChangeCategory(values)}
                                                    style={{ width: '100%' }} placeholder="Not use"
                                                    noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                                />
                                                <span className="p-form-error">{errors.category}</span>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="Price Setting">
                                        <div className="p-grid p-fluid form-group">
                                            {frmData.type!==PRODUCT_TYPE.donation.value && 
                                            <div className="p-col-12">
                                                <InputSwitch checked={frmData.free} onChange={(e) => setFrmData({...frmData, free: e.value})} />
                                                <label className="p-margin-left-10">Free?</label>
                                            </div>
                                            }
                                            {!frmData.free && 
                                            <React.Fragment>
                                                {[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(frmData.classification) &&
                                                <div className="p-col-12">
                                                    <InputSwitch checked={frmData.pricedBySelected} onChange={(e) => setFrmData({...frmData, pricedBySelected: e.value})} />
                                                    <label className="p-margin-left-10">Priced by selected product?</label>
                                                </div>
                                                }
                                                {frmData.classification===PRODUCT_CLASSIFICATION.variable.value &&
                                                <div className="p-col-12">
                                                    <Checkbox inputId="cbPricedByVariant" checked={frmData.pricedByVariant} onChange={(e) => setFrmData({...frmData, pricedByVariant: e.checked})} />
                                                    <label htmlFor="cbPricedByVariant">Priced by variant?</label>
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                            {frmData.type===PRODUCT_TYPE.rental.value && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <InputSwitch checked={frmData.subscription} onChange={(e) => setFrmData({...frmData, subscription: e.value})} />
                                                    <label className="p-margin-left-10">Is subscription rental?</label>
                                                </div>
                                            </React.Fragment>
                                            }
                                        </div>
                                        {!frmData.free && (frmData.classification===PRODUCT_CLASSIFICATION.simple.value || 
                                            (frmData.classification===PRODUCT_CLASSIFICATION.variable.value && !frmData.pricedByVariant) ||
                                            ([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(frmData.classification) && !frmData.pricedBySelected)) && 
                                        <ProductPrices value={frmData.prices} type={frmData.type} errors={errors.prices?errors.prices:{}} onChange={(e) => setFrmData({...frmData, prices: e})}/>
                                        }
                                    </Fieldset>

                                    {!frmData.free && ([PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(frmData.classification) || ([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(frmData.classification) && !frmData.pricedBySelected)) &&
                                    <Fieldset legend="Tax Setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <InputSwitch checked={frmData.applyTax} onChange={(e) => setFrmData({...frmData, applyTax: e.value})} />
                                                <label className="p-margin-left-10">Apply tax?</label>
                                            </div>
                                            {frmData.applyTax && 
                                            <div className="p-col-12">
                                                <label className="p-label">* Tax apply</label>
                                                <Dropdown value={frmData.taxId} options={taxes} onChange={(e) => setFrmData({...frmData, taxId: e.value})}/>
                                                <div className="p-form-error">{errors.taxId}</div>
                                            </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    }

                                    {!frmData.free && [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value].includes(frmData.type) &&
                                    <Fieldset legend="Deposit Setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <InputSwitch checked={frmData.allowDeposit} onChange={(e) => setFrmData({...frmData, allowDeposit: e.value, depositType: e.value?DEPOSIT_TYPE.installment.value:null})} />
                                                <label className="p-margin-left-10">Allow deposit?</label>
                                            </div>
                                            {frmData.allowDeposit && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-label-inline">Deposit type:</label>
                                                    <RadioButton inputId="rbDepositType_0" value={DEPOSIT_TYPE.installment.value} className="p-margin-left-20" onChange={(e) => setFrmData({...frmData, depositType: e.value})} checked={frmData.depositType === DEPOSIT_TYPE.installment.value} />
                                                    <label htmlFor="rbDepositType_0" className="p-radiobutton-label">{DEPOSIT_TYPE.installment.label}</label>
                                                    <RadioButton inputId="rbDepositType_1" value={DEPOSIT_TYPE.pledge.value} className="p-margin-left-20" onChange={(e) => setFrmData({...frmData, depositType: e.value})} checked={frmData.depositType === DEPOSIT_TYPE.pledge.value} />
                                                    <label htmlFor="rbDepositType_1" className="p-radiobutton-label">{DEPOSIT_TYPE.pledge.label}</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">* Deposit amount</label>
                                                    <InputText type="text" keyfilter="int" value={frmData.depositAmount} onChange={(e) => setFrmData({...frmData, depositAmount: Number(e.target.value)})} />
                                                    <div className="p-form-error">{errors.depositAmount}</div>
                                                </div>
                                                {frmData.depositType===DEPOSIT_TYPE.installment.value && 
                                                <div className="p-col-12">
                                                    <InputSwitch inputId="cbAllowPayFull" checked={frmData.allowPayFull} onChange={(e) => setFrmData({...frmData, allowPayFull: e.value})} />
                                                    <label htmlFor="cbAllowPayFull" className="p-margin-left-10">Allow pay full? (Use in case deposit enabled)</label>
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </div>
                                    </Fieldset>
                                    }

                                    {frmData.classification===PRODUCT_CLASSIFICATION.variable.value && 
                                    <Fieldset legend="Variant Setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <span style={{color: 'red', fontSize: '12px', fontStyle: 'Italic'}}>
                                                    Note: Some setting marked with (!) can't be changed after inventory variant has been created!
                                                </span>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label-inline p-margin-bottom-5">Variant options type (!):</label>
                                                <RadioButton inputId="rbVariantOptBy0" name="variantOptionType" disabled={frmData.variants.length>0} className="p-margin-left-20" value={VARIANT_OPTION_TYPE.option.value} onChange={(e) => setFrmData({...frmData, variantOptionType: e.value})} checked={frmData.variantOptionType===VARIANT_OPTION_TYPE.option.value} />
                                                <label htmlFor="rbVariantOptBy0" className="p-margin-right-15">{VARIANT_OPTION_TYPE.option.label}</label>
                                                <RadioButton inputId="rbVarianOpttBy1" name="variantOptionType" disabled={frmData.variants.length>0} value={VARIANT_OPTION_TYPE.custom.value} onChange={(e) => setFrmData({...frmData, variantOptionType: e.value})} checked={frmData.variantOptionType===VARIANT_OPTION_TYPE.custom.value} />
                                                <label htmlFor="rbVariantOptBy1" className="p-margin-right-15">{VARIANT_OPTION_TYPE.custom.label}</label>
                                            </div>
                                            {frmData.variantOptionType===VARIANT_OPTION_TYPE.option.value && 
                                            <div className="p-col-12">
                                                <label className="p-label">* Variant options (!)</label>
                                                <Select multi disabled={frmData.variants.length>0}
                                                    values={variantOptions && frmData.variantOptionSelect && frmData.variantOptionSelect.length>0 ? variantOptions.filter(x1 => frmData.variantOptionSelect.some(x2 => x2 === x1.value)) : []}
                                                    options={variantOptions} onChange={(values) => onChangeVariantOptions(values, 'variant')}
                                                    style={{ width: '100%' }} placeholder="Select product options"
                                                    noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data</span>) }}
                                                />
                                                <div className="p-form-error">{errors.variantOption}</div>
                                            </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    }

                                    {frmData.type===PRODUCT_TYPE.rental.value &&
                                    <Fieldset legend="Rental Limit Setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <span style={{color: 'red', fontSize: '12px', fontStyle: 'Italic'}}>Note: Leave quantity blank as unlimited</span>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-grid p-fluid">
                                                    <div className="p-col-6">
                                                        <label className="p-label">Min q.ty</label>
                                                        <InputText value={frmData.rentalLimit.minQty} keyfilter="int" onChange={(e) => setFrmData({...frmData, rentalLimit: {...frmData.rentalLimit, minQty: e.target.value}})}/>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <label className="p-label">Min unit</label>
                                                        <Dropdown value={frmData.rentalLimit.minUnit} options={Object.values(PRODUCT_PRICE_UNIT)} onChange={(e) => setFrmData({...frmData, rentalLimit: {...frmData.rentalLimit, minUnit: e.value}})}/>                                                    </div>
                                                </div>
                                                <div className="p-form-error">{errors.rentalLimitMin}</div>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-grid p-fluid">
                                                    <div className="p-col-6">
                                                        <label className="p-label">Max q.ty</label>
                                                        <InputText value={frmData.rentalLimit.maxQty} keyfilter="int" onChange={(e) => setFrmData({...frmData, rentalLimit: {...frmData.rentalLimit, maxQty: e.target.value}})}/>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <label className="p-label">Max unit</label>
                                                        <Dropdown value={frmData.rentalLimit.maxUnit} options={Object.values(PRODUCT_PRICE_UNIT)} onChange={(e) => setFrmData({...frmData, rentalLimit: {...frmData.rentalLimit, maxUnit: e.value}})}/>                                                    </div>
                                                    </div>
                                                <div className="p-form-error">{errors.rentalLimitMax}</div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    }

                                    
                                    
                                    {frmData.type===PRODUCT_TYPE.rental.value && 
                                    <Fieldset legend="Slot setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">Slot</label>
                                                <InputText value={frmData.slot} keyfilter="num" onChange={(e) => setFrmData({...frmData, slot: e.target.value})}></InputText>
                                                <div className="p-form-error">{errors.slot}</div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    }
                                    
                                    {frmData.type!==PRODUCT_TYPE.donation.value && 
                                    <Fieldset legend="Tags">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">(hit Enter add a tag)</label>
                                                <Chips placeholder="Type your tag here" value={frmData.tags} onChange={(e) => setFrmData({...frmData, tags: e.value})}></Chips>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    }

                                    {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.donation.value].includes(frmData.type) && 
                                    <Fieldset legend="Custom field setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">Custom Field Set</label>
                                                {frmData.productCustomProperty && frmData.productCustomProperty.customFields ?
                                                    <Button label="Manage Custom Fields" icon="pi pi-search" onClick={() => viewProductCustomFields()}></Button>
                                                :
                                                    <Dropdown value={frmData.productCustomProperty && frmData.productCustomProperty.customSetId} options={customFieldSets} showClear={true} onChange={(e) => setFrmData({...frmData, productCustomProperty: {...frmData.productCustomProperty, customSetId: e.value}})} style={{ width: '100%' }} placeholder="Not use"/>
                                                }
                                            </div>
                                            {frmData.type===PRODUCT_TYPE.program.value && 
                                            <div className="p-col-12">
                                                <label className="p-label">Waiver Field Set</label>
                                                <Dropdown value={frmData.waiverFieldSetId} options={waiverFieldSets} showClear={true} onChange={(e) => setFrmData({...frmData, waiverFieldSetId: e.value})} style={{ width: '100%' }} placeholder="Not use"/>
                                            </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    }

                                    {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value].includes(frmData.type) && 
                                    <Fieldset legend="Required Application Log">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <InputSwitch checked={frmData.useApplicationLog} onChange={(e) => setFrmData({...frmData, useApplicationLog: e.value })} />
                                                <label className="p-margin-left-10">Use Application Log?</label>
                                            </div>
                                            {frmData.useApplicationLog &&
                                                <div className="p-col-12">
                                                    <label className="p-label">Application Log</label>
                                                    <Select multi
                                                        values={applications && frmData.applicationLogs && frmData.applicationLogs.length>0 ? applications.filter(x1 => frmData.applicationLogs.some(x2 => x2 === x1.value)) : []}
                                                        options={applications} onChange={(values) => onChangeApplicationLog(values)}
                                                        style={{ width: '100%' }} placeholder="Not use"
                                                        noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    }

                                    {!frmData.id && frmData.type===PRODUCT_TYPE.program.value && 
                                    <Fieldset legend="Time series setup">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                            <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Creating by:</label>
                                                <RadioButton value={CREATE_TYPE.manually} className="p-margin-left-20" onChange={(e) => onCreateTypeChange(e.value)} checked={timeSeriesData.createType === CREATE_TYPE.manually} />
                                                <label className="p-radiobutton-label">Manual</label>
                                                <RadioButton value={CREATE_TYPE.autoDaily} className="p-margin-left-20" onChange={(e) => onCreateTypeChange(e.value)} checked={timeSeriesData.createType === CREATE_TYPE.autoDaily} />
                                                <label className="p-radiobutton-label">Auto create daily</label>
                                            </div>
                                            {timeSeriesData.createType===CREATE_TYPE.autoDaily && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Repeat on:</label>
                                                    <RadioButton value={TIME_SERIES_REPEAT_TYPE.daily} className="p-margin-left-20" onChange={(e) => setTimeSeriesData({...timeSeriesData, repeatType: e.value})} checked={timeSeriesData.repeatType === TIME_SERIES_REPEAT_TYPE.daily} />
                                                    <label className="p-radiobutton-label">Every day</label>
                                                    <RadioButton value={TIME_SERIES_REPEAT_TYPE.weekDay} className="p-margin-left-20" onChange={(e) => setTimeSeriesData({...timeSeriesData, repeatType: e.value})} checked={timeSeriesData.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay} />
                                                    <label className="p-radiobutton-label">Specific day in week</label>
                                                </div>
                                                {timeSeriesData.repeatType===TIME_SERIES_REPEAT_TYPE.weekDay && 
                                                <div className="p-col-12">
                                                    <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Day repeat:</label>
                                                    <Checkbox inputId="cbMon" className="p-margin-left-20" value="mon" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('mon')}></Checkbox>
                                                    <label htmlFor="cbMon" className="p-checkbox-label p-margin-right-15">Mon</label>
                                                    <Checkbox inputId="cbTue" value="tue" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('tue')}></Checkbox>
                                                    <label htmlFor="cbTue" className="p-checkbox-label p-margin-right-15">Tue</label>
                                                    <Checkbox inputId="cbWed" value="wed" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('wed')}></Checkbox>
                                                    <label htmlFor="cbWed" className="p-checkbox-label p-margin-right-15">Wed</label>
                                                    <Checkbox inputId="cbThu" value="thu" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('thu')}></Checkbox>
                                                    <label htmlFor="cbThu" className="p-checkbox-label p-margin-right-15">Thu</label>
                                                    <Checkbox inputId="cbFri" value="fri" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('fri')}></Checkbox>
                                                    <label htmlFor="cbFri" className="p-checkbox-label p-margin-right-15">Fri</label>
                                                    <Checkbox inputId="cbSat" value="sat" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('sat')}></Checkbox>
                                                    <label htmlFor="cbSat" className="p-checkbox-label p-margin-right-15">Sat</label>
                                                    <Checkbox inputId="cbSun" value="sun" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('sun')}></Checkbox>
                                                    <label htmlFor="cbSun" className="p-checkbox-label">Sun</label>
                                                    <div className="p-form-error" style={{paddingLeft: '100px'}}>{errors.timeSeries && errors.timeSeries.repeatValues}</div>
                                                </div>
                                                }
                                                </React.Fragment>
                                            }
                                            {((!frmData.id && timeSeriesData.createType===CREATE_TYPE.manually)) &&
                                            <div className="p-col-12">
                                                <Checkbox inputId="cbIsRange" checked={timeSeriesData.dateRange} onChange={(e) => setTimeSeriesData({...timeSeriesData, dateRange: e.checked})} />
                                                <label htmlFor="cbIsRange" className="p-margin-left-10">Is date range?</label>
                                            </div>
                                            }
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-6">
                                                        <label className="p-label">* {timeSeriesData.dateRange?'Start Date':'Date'}</label>
                                                        <MaskedCalendar value={timeSeriesData.startDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'start')} onBlur={(e) => onDateBlur(e.target.value, 'start')} dateFormat="mm-dd-yy" />
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.startDate}</span>
                                                    </div>
                                                    {timeSeriesData.dateRange && 
                                                    <div className="p-col-6">
                                                        <label className="p-label">* End Date</label>
                                                        <MaskedCalendar value={timeSeriesData.endDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'end')} onBlur={(e) => onDateBlur(e.target.value, 'end')} dateFormat="mm-dd-yy" />
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.endDate}</span>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-6">
                                                        <label className="p-label">* Start Time</label>
                                                        <MaskedCalendar value={timeSeriesData.startTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'start')} onBlur={(e) => onTimeBlur(e.target.value, 'start')} hourFormat="12" timeOnly={true}/>
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.startTime}</span>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <label className="p-label">* End Time</label>
                                                        <MaskedCalendar value={timeSeriesData.endTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'end')} onBlur={(e) => onTimeBlur(e.target.value, 'end')} hourFormat="12" timeOnly={true}/>
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.endTime}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-6">
                                                        <label className="p-label">Min q.ty</label>
                                                        <InputText value={timeSeriesData.minQty} keyfilter="int" onChange={(e) => setTimeSeriesData({...timeSeriesData, minQty: e.target.value})}/>
                                                        <span className="p-form-error">{errors.minQty}</span>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <label className="p-label">Max q.ty (leave blank as unlimited)</label>
                                                        <InputText value={timeSeriesData.maxQty} keyfilter="int"  showIcon={true} onChange={(e) => setTimeSeriesData({...timeSeriesData, maxQty: e.target.value})}/>
                                                        <span className="p-form-error">{errors.maxQty}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    }

                                    {frmData.classification===PRODUCT_CLASSIFICATION.simple.value && [PRODUCT_TYPE.addon.value, PRODUCT_TYPE.session.value].includes(frmData.type) &&
                                    <Fieldset legend="Inventory Setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <Checkbox checked={frmData.stockTracking} onChange={(e) => setFrmData({...frmData, stockTracking: e.checked})} />
                                                Inventory stock tracking?
                                            </div>
                                            {frmData.stockTracking &&
                                                <div className="p-col-12">
                                                    <label className="p-label">* Q.ty on hand</label>
                                                    <Spinner value={frmData.stockInputQty} step={1} onChange={(e) => setFrmData({...frmData, stockInputQty: e.target.value })} />
                                                    <span className="p-form-error">{errors.stockQuantity}</span>
                                                </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    }
                                    
                                    {/* {frmData.type!==PRODUCT_TYPE.rental.value && frmData.type!==PRODUCT_TYPE.membership.value && frmData.type!==PRODUCT_TYPE.donation.value && 
                                    <React.Fragment>
                                        {!frmData.id?
                                            <Fieldset legend="Inventory Setting">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <span style={{color: 'red', fontSize: '12px', fontStyle: 'Italic'}}>Note: Some setting marked with (!) can't be changed after product has been created!</span>
                                                    </div>
                                                    {(()=>{
                                                        if(frmData.classification){
                                                            switch (frmData.classification) {
                                                                case PRODUCT_CLASSIFICATION.simple.value:
                                                                    return <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Variant options (!)</label>
                                                                            <Select multi
                                                                                values={productOptions && frmData.optionSelect && frmData.optionSelect.length>0 ? productOptions.filter(x1 => frmData.optionSelect.some(x2 => x2 === x1.value)) : []}
                                                                                options={productOptions} onChange={(values) => this.onChangeSelect(values)}
                                                                                style={{ width: '100%' }} placeholder="Select product options"
                                                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data</span>) }}
                                                                            />
                                                                            <span className="p-form-error">{errors.optionSelect}</span>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <Checkbox checked={frmData.stockTracking} onChange={(e) => setFrmData({...frmData, stockTracking: e.checked})} />
                                                                            Inventory stock tracking?
                                                                        </div>
                                                                        {frmData.stockTracking &&
                                                                            <div className="p-col-12">
                                                                                <label className="p-label">* Q.ty on hand</label>
                                                                                <Spinner value={frmData.stockInputQty} step={1} onChange={(e) => setFrmData({...frmData, stockInputQty: e.target.value })} />
                                                                                <span className="p-form-error">{errors.quantity}</span>
                                                                            </div>
                                                                        }
                                                                    </React.Fragment>
                                                                case PRODUCT_CLASSIFICATION.variable.value:
                                                                    return <React.Fragment>
                                                                        
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">* Variant options (!)</label>
                                                                            <Select
                                                                                values={productOptions && frmData.optionSelect && frmData.optionSelect.length>0 ? productOptions.filter(x1 => frmData.optionSelect.some(x2 => x2 === x1.value)) : []}
                                                                                options={productOptions} onChange={(values) => this.onChangeSelect(values)}
                                                                                style={{ width: '100%' }} placeholder="Select product options"
                                                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data</span>) }}
                                                                            />
                                                                            <span className="p-form-error">{errors.optionSelect}</span>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label p-margin-bottom-5">Variant selection method</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod0" name="variantSelectionType" value={0} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType===0} />
                                                                            <label htmlFor="rbVariantSelectionMethod0" className="p-margin-right-15">By select</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod1" name="variantSelectionType" value={1} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType===1} />
                                                                            <label htmlFor="rbVariantSelectionMethod1" className="p-margin-right-15">By matching</label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                default: break;
                                                            }
                                                        }
                                                    })()}
                                                </div>
                                            </Fieldset>
                                            :
                                            <Fieldset legend="Inventory Information">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label p-margin-bottom-5">Variant type: {this.renderProductClassification()}</label>
                                                    </div>
                                                    {frmData.options&&frmData.options.length>0 &&
                                                        <div className="p-col-12">
                                                            <label className="p-label p-margin-bottom-5">Variable options: </label>
                                                            <div>{frmData.options.map((e, i) => {return ((i>0?', ':'')+e.name) })}</div>
                                                        </div>
                                                    }
                                                    {(()=>{
                                                        if(frmData.classification){
                                                            switch (frmData.classification) {
                                                                case PRODUCT_CLASSIFICATION.simple.value:
                                                                    return <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <Checkbox checked={frmData.stockTracking} onChange={(e) => setFrmData({...frmData, stockTracking: e.checked})} />
                                                                            Inventory stock tracking?
                                                                        </div>
                                                                        {frmData.stockTracking &&
                                                                            <div className="p-col-12">
                                                                                <label className="p-label">* Stock input q.ty</label>
                                                                                <Spinner value={frmData.stockInputQty} step={1} onChange={(e) => setFrmData({...frmData, stockInputQty: e.target.value })} />
                                                                                <span className="p-form-error">{errors.quantity}</span>
                                                                            </div>
                                                                        }
                                                                        <div className="p-col-12">
                                                                            <Fieldset legend="Stock details">
                                                                                <div className="p-grid">
                                                                                    {frmData.stockTracking &&
                                                                                        <React.Fragment>
                                                                                            <div className="p-col-6">
                                                                                                <label className="p-label p-margin-bottom-5">Total q.ty: {frmData.variants[0].onHand + frmData.variants[0].onHold + frmData.variants[0].onSold}</label>
                                                                                            </div>
                                                                                            <div className="p-col-6">
                                                                                                <label className="p-label p-margin-bottom-5">Q.ty on hand: {frmData.variants[0].onHand}</label>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    <div className="p-col-6">
                                                                                        <label className="p-label p-margin-bottom-5">Q.ty on hold: {frmData.variants[0].onHold}</label>
                                                                                    </div>
                                                                                    <div className="p-col-6">
                                                                                        <label className="p-label p-margin-bottom-5">Q.ty on sold: {frmData.variants[0].onSold}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </Fieldset>
                                                                        </div>
                                                                    </React.Fragment>
                                                                case PRODUCT_CLASSIFICATION.variable.value:
                                                                    return <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label p-margin-bottom-5">Variant selection method</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod0" name="variantSelectionType" value={0} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType==0} />
                                                                            <label htmlFor="rbVariantSelectionMethod0" className="p-margin-right-15">By select</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod1" name="variantSelectionType" value={1} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType==1} />
                                                                            <label htmlFor="rbVariantSelectionMethod1" className="p-margin-right-15">By matching</label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                default: break;
                                                            }
                                                        }
                                                    })()}
                                                </div>
                                            </Fieldset>
                                        }
                                    </React.Fragment>
                                    } */}
                                </div>
                                <div className="p-col-12">
                                    <Button label="Save Information" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => handleSaveProduct()} />
                                </div>
                            </div>
                        </div>
                        
                        {frmData.id && 
                        <React.Fragment>
                            <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.content.value})}>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <Fieldset legend="Gallery Images">
                                            <ProductGallery value={frmData.galleryImages} type={GALLERY_TYPE.image} errors={errors} onChange={(e) => setFrmData({...frmData, galleryImages: e})}/>
                                        </Fieldset>
                                        <Fieldset legend="Videos">
                                            <ProductGallery value={frmData.videos} type={GALLERY_TYPE.video} onChange={(e) => setFrmData({...frmData, videos: e})}/>
                                        </Fieldset>
                                        <Fieldset legend="Long content">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <CKEditor data={frmData.content} rows={10} onChange={e => setFrmData({...frmData, content: e.editor.getData()})} 
                                                        config={{height: '300px'}}/>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                    <div className="p-col-12">
                                        <Button label="Save Content" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => handleSaveProductContent()} />
                                    </div>
                                </div>
                            </div>

                            {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(frmData.type) && 
                            <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.availability.value})}>
                                <ProductAvailability productId={productId} type={frmData.type} loadTable={productId?true:false}/>
                            </div>
                            }

                            {(frmData.classification===PRODUCT_CLASSIFICATION.variable.value || (frmData.classification===PRODUCT_CLASSIFICATION.simple.value && (frmData.type===PRODUCT_TYPE.session.value || frmData.type===PRODUCT_TYPE.addon.value))) && 
                            <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.inventory.value && menuActive!==PRODUCT_MENU_ITEMS.variant.value})}>
                                <ProductVariant value={frmData.variants} 
                                    variantOptions={frmData.options} productId={productId} 
                                    type={frmData.type} classification={frmData.classification} 
                                    pricedByVariant={frmData.pricedByVariant} variantOptionType={frmData.variantOptionType}/>
                            </div>
                            }

                            {(frmData.classification===PRODUCT_CLASSIFICATION.combo.value || frmData.classification===PRODUCT_CLASSIFICATION.configuration.value || ((frmData.classification===PRODUCT_CLASSIFICATION.simple.value || frmData.classification===PRODUCT_CLASSIFICATION.variable.value) && frmData.type===PRODUCT_TYPE.program.value)) && 
                            <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.associated.value})}>
                                <ProductAssociated value={frmData.associatedGroups} productId={productId} type={frmData.type} classification={frmData.classification}  branchId={frmData.branchId} />
                            </div>
                            }

                            {frmData.type===PRODUCT_TYPE.rental.value && 
                            <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.asset.value})}>
                                <Fieldset legend="Related asset setup">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">* Asset Category</label>
                                            <Dropdown value={frmData.assetCategoryId} options={assetCategories} onChange={(e) => setFrmData({...frmData, assetCategoryId: e.value})} placeholder="Select related asset category"/>
                                            <span className="p-form-error">{errors.assetCategory}</span>
                                        </div>
                                        {branches.length>1 && 
                                        <div className="p-col-12">
                                            <label className="p-label">Asset Across Branch</label>
                                            <Select multi
                                                values={branches && frmData.assetAcrossBranches && frmData.assetAcrossBranches.length>0 ? branches.filter(x1 => frmData.assetAcrossBranches.some(x2 => x2 === x1.value)) : []}
                                                options={branches.filter(br => br.value!==frmData.branchId)} onChange={(values) => onChangeAssetAcrossBranch(values)}
                                                style={{ width: '100%' }} placeholder="Select branch to across asset"
                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No across</span>) }}
                                            />
                                        </div>
                                        }
                                        <div className="p-col-12">
                                            <label className="p-label">Exclude asset</label>
                                            <Select multi
                                                values={assets && frmData.assetExcludedIds && frmData.assetExcludedIds.length>0 ? assets.filter(x1 => frmData.assetExcludedIds.some(x2 => x2 === x1.value)) : []}
                                                options={assets} onChange={(values) => onChangeAssetExclude(values)}
                                                style={{ width: '100%' }} placeholder="Not exclude"
                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-r">
                                            <Button label="Save Related Asset" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => handleSaveProductAsset()} />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            }
                        </React.Fragment>
                        }
                    </div>
                </div>
            </Sidebar>
        </React.Fragment>
    )
})