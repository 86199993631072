import React, { useEffect, useState, useRef} from 'react';
import { PAYMENT_STATE, ORDER_STATE, PAYMENT_GATEWAY } from '../../constants';
import { Button } from 'primereact/button';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { getFrontDeskOrder, completeOrderCheckoutAfterPayment } from './BookingServices';
import { showErrorNotify } from '../../core/service/NotificationService';
import { moneyFormat } from '../../core/service/CommonService';
import { ProductBookingForm } from './ProductBookingForm';
import { ProductListing } from './ProductListing';
import { CartReview } from './CartReview';
import { OrderCompleted } from './OrderCompleted';
import { OrderContext } from './OrderContext.js';
import { stoploading, showloading } from '../../core/service/LoadingService';

export const BookingProcess = () => {
    const params = useParams()
    const match = useRouteMatch()
    const history = useHistory()

    const bookingForm = useRef(null)

    const [currency, setCurrency] = useState('USD')
    const [order, setOrder] = useState(null)
    const [invoice, setInvoice] = useState(null)
    const [stepIndex, setStepIndex] = useState(0)

    const oParams = params.orderNumber?params.orderNumber.split('_'):[]
    const orderNumber = oParams[0]
    const paymentGatewayId = oParams[1]
    const gateway = oParams[2]
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [checkoutError, setCheckoutError] = useState(null)
    const [checkoutErrorCode, setCheckoutErrorCode] = useState(null)

    const processingSteps = [
        {
            label: 'Choose Product',
            style: {width: '33.33%'},
            disabled: order && order.orderState===ORDER_STATE.cart.value?false:true,
            command: (e) => setStepIndex(e.index)
        },
        {
            label: 'Review & Payment',
            style: {width: '33.33%'},
            disabled: order && order.orderState===ORDER_STATE.cart.value && order.items.length>0?false:true,
            command: (e) => setStepIndex(e.index)
        },
        {
            label: 'Confirmation',
            style: {width: '33.33%'},
            disabled: stepIndex===2 || (order && [ORDER_STATE.new_order.value, ORDER_STATE.viewed.value].includes(order.orderState))?false:true,
            command: (e) => setStepIndex(e.index)
        }
    ]

    useEffect(() => {
        const qsParams = new URLSearchParams(window.location.search)
        if(gateway){
            async function processingOrder(){
                showloading()

                const res = await getFrontDeskOrder(orderNumber)

                setOrder(res.order)
                setCurrency(res.order.currency)
                stoploading()

                if(gateway===PAYMENT_GATEWAY.HITPAY){
                    const reference = qsParams.get('reference')
                    const status = qsParams.get('status')
    
                    if(status==='completed'){
                        setStepIndex(2)
                        setCheckoutLoading(true)
                        handleCompleteOrderCheckout(orderNumber, null, gateway, paymentGatewayId, reference)
                    }else{
                        setStepIndex(1)
                    }
                }
            }

            processingOrder()
        }else{
            loadOperatingOrder(orderNumber)
        }
    }, [orderNumber])

    async function loadOperatingOrder(orderNumber){
        const res = await getFrontDeskOrder(orderNumber)
        if(!res.errorCode){
            const tmpOrder = res.order

            setOrder(tmpOrder)
            setCurrency(tmpOrder.currency)

            if([ORDER_STATE.new_order.value, ORDER_STATE.viewed.value].includes(tmpOrder.orderState))
                setStepIndex(2)
            else if(tmpOrder.orderState===ORDER_STATE.cart.value && tmpOrder.items.length>0)
                setStepIndex(1)
            else
                setStepIndex(0)

        }else{
            showErrorNotify(res.errorMessage)
            history.push('/monitoring/booking')
        }
    }

    const handleCompleteOrderCheckout = (orderNumber, method, gateway, pGatewayId, token) => {
        completeOrderCheckoutAfterPayment(orderNumber, method, gateway, pGatewayId, token)
        .then(res => {
            if(!res.errorCode) {
                setOrder(res.order)
                setInvoice(res.invoice)
            }else{
                setCheckoutErrorCode(res.errorCode)
                setCheckoutError(res.errorMessage)
            }

            setCheckoutLoading(false)
        })
    }

    const getOrderItemCount = () => {
        let itemCount = 0
        if(order && order.items && order.items.length>0){
            for(const item of order.items){
                itemCount += item.quantity
            }
        }
        return itemCount
    }

    const addOrEditCartItem = (productId, orderItemId) => {
        bookingForm.current.openProductBookingForm(orderNumber, productId, orderItemId);
    }

    return (
        order && 
        <OrderContext.Provider value={{
            orderNumber: orderNumber,
            order: order,
            setOrder: setOrder,
            invoice: invoice,
            setInvoice: setInvoice,
            currency: order.currency,
            pricingMode: order.pricingMode,
            stepProcess: stepIndex,
            setStepProcess: setStepIndex,
            processCheckoutLoading: checkoutLoading,
            setProcessCheckoutLoading: setCheckoutLoading,
            processOrderError: checkoutError,
            setProcessOrderError: setCheckoutError,
            processOrderErrorCode: checkoutErrorCode,
            addOrUpdateCartItem: addOrEditCartItem
        }}>
            <ProductBookingForm ref={bookingForm} onSaveSuccess={(e) => setOrder(e)}/>

            <div className="p-grid">
                <BreadcrumbsItem to="/monitoring/booking">Booking</BreadcrumbsItem>
                <BreadcrumbsItem to={match.url}>{order.id} - {order.orderNumber}</BreadcrumbsItem>

                <div className="p-col-12">
                    {(stepIndex!==2 || (stepIndex===2 && checkoutLoading)) && 
                    <div className="card card-w-title">
                        <div className="p-d-flex p-justify-between p-align-center">
                            <h1>Current Operating Order: [{order.id} - {order.orderNumber}]</h1>
                            <div>
                                <Button label="Back to Order selection" icon="pi-md-chevron-left" onClick={() => history.push('/monitoring/booking')}/>
                            </div>
                        </div>
                        
                        <div className="p-grid">
                            <div className="p-col-12 p-md-10">
                                <div className="p-grid">
                                    <div className="p-col-3">
                                        Total: <strong>{moneyFormat(order.total, currency)}</strong>
                                    </div>
                                    {order.pledgedDepositTotal>0 &&
                                    <div className="p-col-3">
                                        Security Deposit: <strong>{moneyFormat(order.pledgedDepositTotal, currency)}</strong>
                                    </div>
                                    }
                                    <div className="p-col-3">
                                        Checkout: <strong>{moneyFormat(order.checkoutTotal, currency)}</strong>
                                    </div>
                                    <div className="p-col-3">
                                        Paid: <strong>{moneyFormat(order.paidTotal, currency)}</strong>
                                    </div>
                                    <div className="p-col-12">
                                        Buyer: <strong>{order.billFirstName} {order.billLastName} - {order.billEmail} - [{order.billPhone}]</strong>
                                    </div>
                                </div>
                            </div>
                            {stepIndex===0?
                            <div className="p-col-12 p-md-2 p-r">
                                <button className="p-button p-component teal-btn" style={{padding: '15px 25px 15px 15px', width: 'auto', height: 'auto'}} onClick={(e) => {if(order.items.length>0){setStepIndex(1)} else {showErrorNotify('There no product in cart order!')}}}>
                                    <span className="fa-stack" data-count={getOrderItemCount()}>
                                        <i className="pi-md-shopping-cart p-size-36"></i>
                                    </span>
                                </button>
                            </div>
                            :''}
                        </div>
                    </div>
                    }

                    <div className="card p-padding-none">
                        <Steps model={processingSteps} activeIndex={stepIndex} onSelect={(e) => setStepIndex(e.index)} readOnly={false} />
                    </div>

                    {stepIndex===0 && <ProductListing />}
                    
                    {stepIndex===1 && <CartReview />}

                    {stepIndex===2 && <OrderCompleted />}
                </div>
            </div>
        </OrderContext.Provider>
    )
}