import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Breadcrumbs, BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export class AppBreadcrumb extends Component {

    static propTypes = {
        match: PropTypes.object
    }

    onBack = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="layout-breadcrumb">
                <Button style={{fontSize: "12px"}} className="p-margin-left-10 p-margin-right-10" label="Back" icon="pi-md-chevron-left" onClick={this.onBack}></Button>
                <BreadcrumbsItem to="/"><li style={{color: "#607D8B"}}><i className="pi pi-home"></i></li></BreadcrumbsItem>
                <ul style={{paddingLeft: "10px"}}>
                    <Breadcrumbs
                            separator={<li><i className="pi pi-chevron-right"></i></li>}
                            item={Link}>
                    </Breadcrumbs>
                </ul>
                
                <div className="layout-breadcrumb-options">
                    {/* <a title="Backup">
                        <i className="material-icons">backup</i>
                    </a>
                    <a title="Bookmark">
                        <i className="material-icons">bookmark</i>
                    </a> */}
                    <button className="p-link" title="Logout" onClick={this.props.onLogout}>
                        <i className="material-icons">power_settings_new</i>
                    </button>
                </div>
            </div>
        );
    }
}