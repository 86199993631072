import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TABLE_PAGING_LENGTH } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { getPageTaxonCategory } from './EventService';
import { EventCategoryForm } from './EventCategoryForm';

class EventCategoryListView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAdd: false,
            goToDetail: '',
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            filter: {},
            data: {},
            errors: {},
        };
    }

    componentDidMount() {
        this.loadPageEventCategory();
    }

    loadPageEventCategory = () => {
        showloading();
        getPageTaxonCategory(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                stoploading();
                this.setState({
                    loading: false,
                    dataList: res.content,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.loadPageEventCategory();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.loadPageEventCategory();
        });
    }

    actionTemplate = (rowData) => {
        return (
            <Button label="Edit" className="p-button-constrast" onClick={() => this.eventCategoryForm.popularFormData(rowData)}/>
        );
    }

    onChagePageLength = l => {
        this.setState({
            rows: l,
            page: 0
        }, () => {
            this.loadPageEventCategory();
        })
    }

    render() {
        const { t } = this.props;

        let header =
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                <div style={{ 'float': 'left' }} >
                    <Button label="Create" icon="pi pi-plus" className="p-button-constrast" onClick={() => this.eventCategoryForm.popularFormData()}></Button>
                </div>
                <div style={{ 'float': 'right' }}>
                    Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChagePageLength(e.value)} />
                    <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => this.loadPageEventCategory()}/>
                </div>
                <h3 className="fle-datatable-header-title">Event Category Listing</h3>

            </div>;
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Event Categories</BreadcrumbsItem>

                <EventCategoryForm ref={el => this.eventCategoryForm=el} 
                    refreshData={() => this.loadPageEventCategory()}
                />

                <div className="p-col-12">
                    <DataTable className="p-datatable-scp" lazy={true} paginator={true} value={this.state.dataList}
                        first={this.state.page * this.state.rows}
                        rows={this.state.rows} totalRecords={this.state.total}
                        onPage={this.onPage} header={header} onSort={this.onSort}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable={true}/>
                        <Column field="name" header="Name" sortable={true}/>
                        <Column field="color" header="Color" body={rowData => <div style={{width: 50, height: 20, backgroundColor: rowData.color}}></div>} />
                        <Column field="secondaryColor" header="Secondary Color" body={rowData => <div style={{width: 50, height: 20, backgroundColor: rowData.secondaryColor}}></div>} />
                        <Column header="Active" body={(rowData) => rowData.active ? "Yes" : "No"} />
                        <Column body={this.actionTemplate} className='tc-actions' style={{ width: '12em', overlay: 'visible' }} />
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(EventCategoryListView);
