import axios from 'axios';
import { API_BASE_URL, PIM_BASE_URL, PIM_PATH } from '../../constants';

const PIM_PROMOTION_URL = PIM_BASE_URL + PIM_PATH + '/promotions';

const configHeader = {
    headers: { 'Content-Type': 'multipart/form-data' }
}

//#region Promotion header
export function getPagePromotions(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField?`${sortField},${order}`:'';
    return axios.get(`${PIM_PROMOTION_URL}/page`, {
        params: {...filter, page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getPromotion(id){
    return axios.get(`${PIM_PROMOTION_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function savePromotion(form, priority){
    return axios.post(`${PIM_PROMOTION_URL}`, form, {params: {priority: priority}}).then(res => res.data).catch(error => console.log(error));
}

export function removePromotion(id){
    return axios.delete(`${PIM_PROMOTION_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function savePromotionPriority(listId){
    return axios.post(`${PIM_PROMOTION_URL}/promotion_priority`, listId).then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region Promotion Rule
export function getPagePromotionRules(promoId, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField?`${sortField},${order}`:'';
    return axios.get(`${PIM_PROMOTION_URL}/${promoId}/rules/page`, {
        params: {page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function savePromotionRule(promoId, form, priority){
    let formData = new FormData();

    for (var key in form ) {
        parseDataToForm(formData, key, form[key])
    }
    
    return axios.post(`${PIM_PROMOTION_URL}/${promoId}/rules`, formData, {params:{priority: priority}} , configHeader).then(res => res.data).catch(error => console.log(error));
}


function parseDataToForm (formData, objKey, objValue){
    if(objValue){
        if(typeof objValue === 'object'){
            if(Array.isArray(objValue)){
                let i=0;
                objValue.map(item => {
                    parseDataToForm(formData, objKey+'['+i+']', item);
                    i++;
                })
            }else if(Object.keys(objValue).length > 0){
                if (objKey.includes('trans')){
                    Object.entries(objValue).map(([keyTrans, item]) => {
                        for (var keyFilter in item ) {
                            formData.append(objKey+'.'+keyTrans+'['+keyFilter+']', item[keyFilter])
                        }
                    })
                }
                else{
                    for (const [keyObjData, valueObjData] of Object.entries(objValue)) {
                    parseDataToForm(formData, objKey+'.'+keyObjData, valueObjData)
                    }
                }
                
                
            }else{
                if(objKey !== 'image')
                    formData.append(objKey, objValue)
            }
        }else{
            formData.append(objKey, objValue)
        }
    }
    return formData;
}

export function removePromotionRule(promoId, id){
    return axios.delete(`${PIM_PROMOTION_URL}/${promoId}/rules/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function getListManualPromotionRules(channel){
    return axios.get(`${API_BASE_URL}/scp/api/promotions/rules/manual-list`, {params: {channel}}).then(res => res.data).catch(error => console.log(error));
}

export function savePromotionRulePriority(listId, promoId){
    return axios.post(`${PIM_PROMOTION_URL}/promotion_rule_priority/${promoId}`, listId).then(res => res.data).catch(error => console.log(error));
}

export function uploadFlyingImage(Id, files, index){
    let formData = new FormData();
    formData.append('id', Id);
    if(index===0 || index)
        formData.append('index', index);
    for( var i = 0; i < files.length; i++ ){
        formData.append('files', files[i]);
    }
    return axios.post(`${API_BASE_URL}/scp/api/promotions/rule/images`, formData, configHeader)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region Promotion Coupon
export function getPagePromotionCoupons(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = `${sortField},${order}`;
    return axios.get(`${PIM_PROMOTION_URL}/coupons/page`, {
        params: {...filter, page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function generatingCouponCode(){
    return axios.get(`${PIM_PROMOTION_URL}/coupons/generating_code`).then(res => res.data).catch(error => console.log(error));
}

export function savePromotionCoupon(form){
    return axios.post(`${PIM_PROMOTION_URL}/coupons`, form).then(res => res.data).catch(error => console.log(error));
}

export function removePromotionCoupon(id){
    return axios.delete(`${PIM_PROMOTION_URL}/coupons/${id}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion
