import React, {Component} from "react";
import {UserServices} from "./UserServices";
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Password} from "primereact/password";
import moment from "moment";
import { RadioButton } from "primereact/radiobutton";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { GENDERS, GROUP_USER_TYPE, RANGE_BIRTHDAY} from "../../constants";
import { showNotification, showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService.js";
import { Sidebar } from "primereact/sidebar";
import { GroupServices } from "../group/GroupServices";
import { Panel } from "primereact/panel";
import { titleCaseText } from "../../core/service/CommonService";
import { getCountries, getStatesByCountry } from "../../scp/tenant-configuration/CountrySevices";
import PhoneInput from "react-phone-number-input";

export class UserForm_old extends Component{
    constructor(props){
        super(props);
        this.state = {
            formHeader: 'New Contact',
            dataMoreOptions: {},
            user: {
                title: '',
                gender: 'Any',
                memberRole: null,
                groupAct: 0,
                groupType: 'household',
                phonePrimary: 'cellPhone',
                staff: false,
                staffType: 'staff_full_time',
                maxScan: 0
            },
            address: {},
            birthdayValue: '',
            minDate: moment().subtract(1, 'days').toDate(),
            groups:[],
            countries: [],
            countryStates: [],
            userExists: [],
            rules: [],
            addOther: false,
            labelExistGroup: '',
            defaultCountry: 'US',
            defaultState: null,
            errors: {}
        }
        this.userServices = new UserServices();
        this.groupServices = new GroupServices();
    }

    componentDidMount(){
        let defaultCountry = localStorage.getItem('defaultCountry');
        let defaultState = localStorage.getItem('defaultState');
        Promise.all([this.loadListGroups(), this.loadCountries(), this.loadCountryStates(defaultCountry)]);
        this.setState({
            defaultCountry: defaultCountry && defaultCountry!=='undefined'?defaultCountry:null,
            defaultState: defaultState && defaultState!=='undefined'?defaultState:null,
            address: {
                ...this.state.address,
                countryCode: defaultCountry && defaultCountry!=='undefined'?defaultCountry:null,
                stateCode: defaultState && defaultState!=='undefined'?defaultState:null
            }
        });
    }

    action = (channel, refId, childRefId, rule) => {
        let defaultCountry = localStorage.getItem('defaultCountry');
        let defaultState = localStorage.getItem('defaultState');
        this.popularUserInfoDisplay();
        this.setState({
            dataMoreOptions: {
                channel: channel,
                refId: refId,
                childRefId: childRefId,
                rule: rule && !Array.isArray(rule) ? rule.id : ''
            },
            user: this.popularStateUserInfo(),
            address: {
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                countryCode: defaultCountry && defaultCountry!=='undefined'?defaultCountry:'',
                stateCode: defaultState && defaultState!=='undefined'?defaultState:''
            },
            rules: rule ? (Array.isArray(rule) ? [...rule] : [rule]) : [],
            addOther: false,
            visible: true,
            errors: {}
        })
    }

    popularStateUserInfo = () => {
        return {
            username: '',
            password: '',
            email: '',
            secondaryEmail: '',
            title: '',
            firstName: '',
            middleName: '',
            lastName: '',
            gender: 'Any',
            birthday: '',
            memberRole: null,
            groupAct: 0,
            groupType: 'household',
            phonePrimary: 'cellPhone',
            staff: false,
            staffType: 'staff_full_time',
            addTrialMembership: false,
            maxScan: 0,
            membershipStart: '',
            membershipEnd: ''
        }
    }

    popularUserInfoDisplay = () => {
        this.setState({
            labelExistGroup: '',
            birthdayValue: '',
            membershipStartValue: '',
            membershipEndValue: '',
            userExists: [],
        })
    }

    loadListGroups = () => {
        this.groupServices.getListGroups()
            .then(res => { 
                this.setState({groups: res});
            });
    }

    loadCountries = () => {
        getCountries()
            .then(res => {
                this.setState({countries: res});
            });
    }

    loadCountryStates = (cId) => {
        if(cId && cId!=='undefined'){
            getStatesByCountry(cId)
            .then(res => {
                this.setState({countryStates: res});
            });
        }
    }

    onBirthdayChange = (e) => {
        this.setState({
            user: {
                ...this.state.user, 
                birthday: e.value?moment(e.value).format(moment.HTML5_FMT.DATE):''
            },
            birthdayValue: e.value
        });
    }

    onChangeCountry = (e) => {
        this.setState({
            address: {
                ...this.state.address, countryCode: e.value
            }
        });
        this.loadCountryStates(e.value);
    }

    onMembershipStartChange = (e) => {
        this.setState({
            user: {
                ...this.state.user, 
                membershipStart: e.value?moment(e.value).format(moment.HTML5_FMT.DATE):''
            },
            membershipStartValue: e.value
        });
    }

    onMembershipEndChange = (e) => {
        this.setState({
            user: {
                ...this.state.user, 
                membershipEnd: e.value?moment(e.value).format(moment.HTML5_FMT.DATE):''
            },
            membershipEndValue: e.value
        });
    }

    handleSaveUser = (continueCreate, addOther = false) => {
        let userObj = this.state.user;
        userObj.memberScanId = this.props.memberScanId;
        userObj.address = this.state.address;

        let objMoreOptions = this.state.dataMoreOptions;
        objMoreOptions.isCheckExists = !continueCreate

        this.setState({errors: {}});

        this.userServices.saveUser(userObj, objMoreOptions)
            .then(res => {
                if(!res.errorCode){
                    let user = res.user;
                    let group = res.group;
                    if(res.hasExists){
                        this.setState({
                            userExists: res.users
                        });
                    }else{
                        if(this.props.reloadTable){
                            this.props.reloadTable();
                        }
                        if(this.props.contact){
                            this.props.contact(user);
                        }
                        this.setState({
                            addOther: addOther
                        },() => {
                            if(!this.state.addOther){
                                this.onHide();
                            }else{
                                this.popularUserInfoDisplay();
                                if(group){
                                    let tempArr = [...this.state.groups];
                                    if(!tempArr.find(group => group.value===group.id)){
                                        tempArr.push({value: group.id, label: group.name});
                                        this.setState({
                                            user: this.popularStateUserInfo(),
                                            groups: tempArr,
                                            labelExistGroup: group.name + (group.address ? ' - ' + group.address.address1 : ''),
                                        },() => {
                                            this.setState({
                                                user: {...this.state.user,
                                                    groupId: group.id,
                                                    groupAct: 1, 
                                                    groupName: user.name + '\'s Household',
                                                    groupType: 'household',
                                                    memberRole: null 
                                                }
                                            })
                                        })
                                    }
                                }
                            }
                        })
                        showSuccessNotify('Action submitted');
                    }
                }else{
                    showErrorNotify(res.errorMessage);
                    if(res.errorCode===400) this.setState({errors: res.errorObj});
                }
            });
    }

    onHide = () => {
        this.setState({
            visible: false
        })
    }

    render(){
        const footer = (
            <React.Fragment>
                {this.state.userExists && this.state.userExists.length > 0 ?
                    <Button label="Yes, continue create"icon="pi pi-check" onClick={() => this.handleSaveUser(true)}/>
                    : 
                    <React.Fragment>
                        {!this.state.dataMoreOptions.channel ? <Button label="Save and add other" icon="pi pi-check" onClick={() => this.handleSaveUser(false, true)}/> : ''}
                        <Button label={!this.state.dataMoreOptions.channel ? 'Save and close' : 'Save'} icon="pi pi-check" onClick={() => this.handleSaveUser(false)}/>
                    </React.Fragment>
                }
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
            </React.Fragment>
        );

        let rules = [...this.state.rules];

        return(
            <Sidebar dismissable={false} visible={this.state.visible} style={{overflowY: 'auto', width: '80em'}} position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Account Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Username</label>
                                    <InputText value={this.state.user.username} keyfilter={/[^\s]/} onChange={(e)=> this.setState({user: {...this.state.user, username:e.target.value}})}/>
                                    <span className="p-form-error">{this.state.errors.username}</span>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">{this.state.user.username ? '*' : ''} Password</label>
                                    <Password value={this.state.user.password} onChange={(e)=> this.setState({user: {...this.state.user, password:e.target.value}})}/>
                                    <span className="p-form-error">{this.state.errors.password}</span>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">{this.state.user.username ? '*' : ''} Primary Email</label>
                                    <InputText value={this.state.user.email} keyfilter={/[^\s]/} onChange={(e)=> this.setState({user: {...this.state.user, email:e.target.value}})}/>
                                    <span className="p-form-error">{this.state.errors.email}</span>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Secondary Email</label>
                                    <InputText value={this.state.user.secondaryEmail} keyfilter={/[^\s]/} onChange={(e)=> this.setState({user: {...this.state.user, secondaryEmail:e.target.value}})}/>
                                    <span className="p-form-error">{this.state.errors.secondaryEmail}</span>
                                </div>
                            </div>
                        </Fieldset>
                        {/* {!this.state.dataMoreOptions.channel ? 
                            <Fieldset legend="Contact Type Option">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <Checkbox inputId="dbTrialMS" checked={this.state.user.addTrialMembership} onChange={(e) => this.setState({user: {...this.state.user, addTrialMembership:e.checked}})} />
                                        <label htmlFor="dbTrialMS">Is Guest?</label>
                                    </div>
                                    {this.state.user.addTrialMembership ? 
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-3">
                                                <label className="p-label">Max scan <i>(0 as unlimited)</i></label>
                                                <Spinner value={this.state.user.maxScan} onChange={(e)=> this.setState({user: {...this.state.user, maxScan: e.target.value}})}/>
                                                <span className="p-form-error">{this.state.errors.maxScan}</span>
                                            </div>
                                            <div className="p-col-9">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* Start Date</label>
                                                        <MaskedCalendar value={this.state.membershipStartValue} showIcon={true} minDate={this.state.minDate} onChange={(e) => this.onMembershipStartChange(e)} dateFormat="mm-dd-yy" />
                                                        <span className="p-form-error">{this.state.errors.membershipStart}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* End Date</label>
                                                        <MaskedCalendar value={this.state.membershipEndValue} showIcon={true}  minDate={this.state.minDate} onChange={(e) => this.onMembershipEndChange(e)} dateFormat="mm-dd-yy" />
                                                        <span className="p-form-error">{this.state.errors.membershipEnd}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-3">
                                                <Checkbox inputId="cbStaff" checked={this.state.user.staff} onChange={(e) => this.setState({user: {...this.state.user, staff:e.checked}})} />
                                                <label htmlFor="cbStaff">Is staff?</label>
                                            </div>
                                            {this.state.user.staff ? 
                                                <div className="p-col-12 p-md-9">
                                                    <label>Staff type:</label>
                                                    <RadioButton value="staff_full_time" className="p-margin-left-20" onChange={(e) => this.setState({user: {...this.state.user, staffType:e.value}})} checked={this.state.user.staffType === 'staff_full_time'} />
                                                    <label className="p-radiobutton-label">Full-time</label>
                                                    <RadioButton value="staff_part_time" className="p-margin-left-20" onChange={(e) => this.setState({user: {...this.state.user, staffType:e.value}})} checked={this.state.user.staffType === 'staff_part_time'} />
                                                    <label className="p-radiobutton-label">Part-time</label>
                                                </div>
                                                : ''
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                            </Fieldset>
                            : ''
                        } */}
                        <Fieldset legend="Basic Information">
                            <div className="p-grid p-fluid form-group">
                                {this.state.dataMoreOptions.channel && (this.state.dataMoreOptions.channel==='membership' || this.state.dataMoreOptions.channel==='program') ? 
                                    rules && rules.length > 0  ?
                                        <div className="p-col-12">
                                            <label>You're creating new contact to join {this.state.dataMoreOptions.channel} with the requirement:</label>
                                            {this.state.dataMoreOptions.channel==='membership' ?
                                                <label className="p-label">Age: From
                                                    {rules.map((rule, idx) => {
                                                        return( <span> {rule.minAge + (rule.maxAge===0?" and up":" to "+ rule.maxAge)} {idx < (rules.length-1) ? " | " : ""}</span> );
                                                    })}
                                                </label>
                                                : ''
                                            }
                                            {this.state.dataMoreOptions.channel==='program' ?
                                                <React.Fragment>
                                                    {/* <label className="p-label">Age: From {rules[0].minAge + (rules[0].maxAge===0?" and up":" to "+ rules[0].maxAge)}</label> */}
                                                    <label className="p-label">Age: From {rules[0].ageRange}</label>
                                                    <label className="p-label">Gender: {rules[0].gender}</label>
                                                </React.Fragment>
                                                : ''
                                            }
                                            <hr />
                                        </div>
                                        : ''
                                    : ''
                                }
                                {/* <div className="p-col-12 p-md-2">
                                    <label className="p-label">Title</label>
                                    <Dropdown options={NAME_TITLES} value={this.state.user.title} onChange={(e)=>this.setState({user: {...this.state.user, title:e.value}})} style={{"width": "100%"}} />
                                    <span className="p-form-error">{this.state.errors.title}</span>
                                </div> */}
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <label className="p-label">* First Name</label>
                                            <InputText value={this.state.user.firstName} onChange={(e) => this.setState({user: {...this.state.user, firstName:titleCaseText(e.target.value)}})}/>
                                            <span className="p-form-error">{this.state.errors.firstName}</span>
                                        </div>
                                        {/* <div className="p-col-12 p-md-4">
                                            <label className="p-label">Middle Name</label>
                                            <InputText value={this.state.user.middleName} onChange={(e)=> this.setState({user: {...this.state.user, middleName:titleCaseText(e.target.value)}})}/>
                                        </div> */}
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <label className="p-label">* Last Name</label>
                                            <InputText value={this.state.user.lastName} onChange={(e)=> this.setState({user: {...this.state.user, lastName:titleCaseText(e.target.value)}})}/>
                                            <span className="p-form-error">{this.state.errors.lastName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label className="p-label">Gender</label>
                                    <Dropdown options={GENDERS} value={this.state.user.gender} onChange={(e)=>this.setState({user: {...this.state.user, gender:e.value}})} style={{"width": "100%"}} />
                                </div>
                                <div className="p-col-12 p-md-5">
                                    <label className="p-label">* Date of birth</label>
                                    <MaskedCalendar value={this.state.birthdayValue} showIcon={true} onChange={(e) => this.onBirthdayChange(e)} monthNavigator={true} yearNavigator={true} yearRange={RANGE_BIRTHDAY} dateFormat="mm-dd-yy" />
                                    <span className="p-form-error">{this.state.errors.birthday}</span>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{this.state.user.phonePrimary&&this.state.user.phonePrimary==='cellPhone' ? '*': ''} Cell Phone</label>
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-8">
                                                    <PhoneInput value={this.state.user.cellPhone} defaultCountry={this.state.defaultCountry} onChange={(e) => this.setState({user: {...this.state.user, cellPhone: e}})} className="p-inputtext p-comment p-phoneinput" style={{width: "100%"}}/>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <RadioButton value={'cellPhone'} inputId="rb1" onChange={e => this.setState({user: { ...this.state.user, phonePrimary: e.value } })} checked={this.state.user.phonePrimary==='cellPhone'?true:false} /> primary?
                                                </div>
                                            </div>
                                            <span className="p-form-error">{this.state.errors.cellPhone}</span>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{this.state.user.phonePrimary&&this.state.user.phonePrimary==='homePhone' ? '*': ''} Home Phone</label>
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-8">
                                                    <PhoneInput value={this.state.user.homePhone} defaultCountry={this.state.defaultCountry}  onChange={(e) => this.setState({user: {...this.state.user, homePhone: e}})} className="p-inputtext p-comment p-phoneinput" style={{width: "100%"}}/>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                    <RadioButton value={'homePhone'} inputId="rb2" onChange={e => this.setState({user: { ...this.state.user, phonePrimary: e.value } })} checked={this.state.user.phonePrimary==='homePhone'?true:false} /> primary?
                                                </div>
                                            </div>
                                            <span className="p-form-error">{this.state.errors.homePhone}</span>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">{this.state.user.phonePrimary&&this.state.user.phonePrimary==='workPhone' ? '*': ''} Work Phone</label>
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-8">
                                                    <PhoneInput value={this.state.user.workPhone} defaultCountry={this.state.defaultCountry}  onChange={(e) => this.setState({user: {...this.state.user, workPhone: e}})} className="p-inputtext p-comment p-phoneinput" style={{width: "100%"}}/>
                                                </div>
                                                <div className="p-col-12 p-md-4">
                                                <RadioButton value={'workPhone'} inputId="rb3" onChange={e => this.setState({user: { ...this.state.user, phonePrimary: e.value } })} checked={this.state.user.phonePrimary==='workPhone'?true:false} /> primary?
                                                </div>
                                            </div>
                                            <span className="p-form-error">{this.state.errors.workPhone}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fieldset>
                        {this.state.userExists && this.state.userExists.length > 0 ? 
                            <Fieldset legend="Existing Members Notification">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <span className="labels-highlight">We found some person in household have similar information. Do you want to continue create person with your input?</span>
                                    </div>
                                    <div className="p-col-12">
                                        {this.state.userExists.map(user => {
                                            return(
                                                <React.Fragment>
                                                    <Panel>
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-4">
                                                            Name: <span className="p-w-bold">{user.name}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-4">
                                                            Email: <span className="p-w-bold">{user.email}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-4">
                                                            Birthday: <span className="p-w-bold">{moment(user.birthday).format("MM-DD-YYYY")}</span>
                                                        </div>
                                                    </div>
                                                    </Panel>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Fieldset>
                            : ''
                        }
                        {!this.state.dataMoreOptions.channel ? 
                            <Fieldset legend="Household Options">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12 p-md-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12">
                                                <RadioButton inputId="rbGroupAct0" value={0} name="groupAct" onChange={e => this.setState({user: { ...this.state.user, groupAct: e.value } })} checked={this.state.user.groupAct===0} />
                                                <label htmlFor="rbGroupAct0" className="p-margin-right-30">None</label>
                                                <RadioButton inputId="rbGroupAct1" value={1} name="groupAct" onChange={e => this.setState({user: { ...this.state.user, groupAct: e.value } })} checked={this.state.user.groupAct===1} />
                                                <label htmlFor="rbGroupAct1" className="p-margin-right-30">Add to existing Household/Group</label>
                                                <RadioButton inputId="rbGroupAct2" value={2} name="groupAct" onChange={e => this.setState({user: { ...this.state.user, groupAct: e.value } })} checked={this.state.user.groupAct===2} />
                                                <label htmlFor="rbGroupAct2">Create Household/Group</label>
                                            </div>
                                            {this.state.user.groupAct===1?
                                                [
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* Household/Group</label>
                                                        {!this.state.addOther ?
                                                            <React.Fragment>
                                                        <Dropdown options={this.state.groups} value={this.state.user.groupId} filter={true} onChange={(e) => this.setState({user: {...this.state.user, groupId: e.value}})} style={{width: "100%"}} placeholder="Select household/group"/>
                                                        <span className="p-form-error">{this.state.errors.groupId}</span>
                                                            </React.Fragment>
                                                            : <div>{this.state.labelExistGroup}</div>
                                                        }
                                                    </div>,
                                                    // <div className="p-col-12 p-md-6">
                                                    //     <label className="p-label">Relative Type</label>
                                                    //     <Dropdown options={GROUP_MEMBER_RELATIONSHIP_TYPES} value={this.state.user.memberRole} onChange={(e) => this.setState({user: {...this.state.user, memberRole: e.value}})} style={{width: "100%"}} placeholder="Select relative"/>
                                                    //     <span className="p-form-error">{this.state.errors.memberRole}</span>
                                                    // </div>
                                                ]
                                                :''
                                            }
                                            {this.state.user.groupAct===2?
                                                [
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* Name of Household/Group</label>
                                                        <InputText value={this.state.user.groupName} onChange={(e) => this.setState({user: {...this.state.user, groupName:titleCaseText(e.target.value)}})}/>
                                                        <span className="p-form-error">{this.state.errors.groupName}</span>
                                                    </div>,
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">Type</label>
                                                        <Dropdown options={Object.values(GROUP_USER_TYPE)} value={this.state.user.groupType} onChange={(e) => this.setState({user: {...this.state.user, groupType: e.value}})} style={{width: "100%"}} placeholder="Select type"/>
                                                        <span className="p-form-error">{this.state.errors.groupType}</span>
                                                    </div>
                                                ]
                                                :''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Fieldset>
                            : ''
                        }
                        <Fieldset legend="Address Option">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Street 1</label>
                                            <InputText value={this.state.address.address1} onChange={(e)=> this.setState({address: {...this.state.address, address1:titleCaseText(e.target.value)}})}/>
                                            <span className="p-form-error">{this.state.errors.address1}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">Street 2</label>
                                            <InputText value={this.state.address.address2} onChange={(e)=> this.setState({address: {...this.state.address, address2:titleCaseText(e.target.value)}})}/>
                                            <span className="p-form-error">{this.state.errors.address2}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* City</label>
                                            <InputText value={this.state.address.city} onChange={(e)=> this.setState({address: {...this.state.address, city:titleCaseText(e.target.value)}})}/>
                                            <span className="p-form-error">{this.state.errors.city}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">State/Province</label>
                                            <Dropdown options={this.state.countryStates} value={this.state.address.stateCode} onChange={(e)=> this.setState({address: {...this.state.address, stateCode: e.value}})} style={{width: "100%"}} />
                                            <span className="p-form-error">{this.state.errors.state}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-12">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Zip Code</label>
                                            <InputText value={this.state.address.zipCode} onChange={(e)=> this.setState({address: {...this.state.address, zipCode: e.target.value }})}/>
                                            <span className="p-form-error">{this.state.errors.zipCode}</span>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">Country</label>
                                            <Dropdown options={this.state.countries} value={this.state.address.countryCode} filter={true} onChange={(e)=> this.onChangeCountry(e)} style={{width: "100%"}} />
                                            <span className="p-form-error">{this.state.errors.country}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        {footer}
                    </div>
                </div>
            </Sidebar>
        );
    }
}


