import axios from "axios";
import {API_BASE_URL} from "../../constants"

export class AttributeServices {

    //#region FIELD
    getPageAttributeFields(page, size, sortField, sortOrder){
        let order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        let sort = sortField ? `${sortField==='typeName'?'type':sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/attributes/fields/page`,{
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getAttributeField(id){
        return axios.get(`${API_BASE_URL}/scp/api/attributes/fields/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveAttributeField(data){
        return axios.post(`${API_BASE_URL}/scp/api/attributes/fields`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteAttributeField(id){
        return axios.delete(`${API_BASE_URL}/scp/api/attributes/fields/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getPageAttributeFieldParticipantAnswers(fieldId, page, size, sortField, sortOrder){
        let order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        let sort = sortField ? `${sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/attributes/fields/${fieldId}/participant-answers`,
            {params: {page, size, sort}}
        ).then(res => res.data).catch(error => console.log(error));
    }

    getPageAttributeFieldSurvey(fieldId, page, size, sortField, sortOrder){
        let order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        let sort = sortField ? `${sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/attributes/fields/${fieldId}/survey`,
            {params: {page, size, sort}}
        ).then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region SET
    getPageAttributeSets(type, page, size, sortField, sortOrder){
        const order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        const sort = sortField ? `${sortField==='typeName'?'type':sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/attributes/sets/page`,{
            params: {type, page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getListAttributeSets(type){
        return axios.get(`${API_BASE_URL}/scp/api/attributes/sets/list`, {
            params: {type}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getAttributeSet(id){
        return axios.get(`${API_BASE_URL}/scp/api/attributes/sets/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveAttributeSet(data){
        return axios.post(`${API_BASE_URL}/scp/api/attributes/sets`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteAttributeSet(id){
        return axios.delete(`${API_BASE_URL}/scp/api/attributes/sets/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveAttributeSetField(setId, data){
        return axios.post(`${API_BASE_URL}/scp/api/attributes/sets/${setId}/set-fields`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteAttributeSetField(setId, id){
        return axios.delete(`${API_BASE_URL}/scp/api/attributes/sets/${setId}/set-fields/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region VALUE LIST
    getPageAttributeList(page, size, sortField, sortOrder){
        const order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        const sort = sortField ? `${sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/attributes/value-list/page`,{
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getListAttributeValueList(simple){
        return axios.get(`${API_BASE_URL}/scp/api/attributes/value-list/list`, {params: {simple}})
        .then(res => res.data).catch(error => console.log(error));
    }

    saveAttributeList(data){
        return axios.post(`${API_BASE_URL}/scp/api/attributes/value-list`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteAttributeList(id){
        return axios.delete(`${API_BASE_URL}/scp/api/attributes/value-list/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getListAttributeValueSkills(){
        return axios.get(`${API_BASE_URL}/scp/api/attributes/value-list/skills`)
        .then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

}

//#region ATTRIBUTE SET
export function getListAttributeSets(type){
    return axios.get(`${API_BASE_URL}/scp/api/attributes/sets/list`, {
        params: {type}
    }).then(res => res.data).catch(error => console.log(error));
}

export function getAttributeSet(id){
    return axios.get(`${API_BASE_URL}/scp/api/attributes/sets/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region VALUE LIST
export function getListAttributeValueList(simple=true){
    return axios.get(`${API_BASE_URL}/scp/api/attributes/value-list/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion
