import React, { useEffect, useRef, useState }  from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { getPageInventories, removeInventory } from './InventoryService';
import InventoryForm from './InventoryForm';
import { APP_FEP, TABLE_PAGING_LENGTH } from '../../constants';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { showloading, stoploading } from '../../core/service/LoadingService';

const InventoryListView = (props) => {
    const deleteNotify = useRef(null);
    const frmInventory = useRef(null);

    const [data, setData] = useState({});
    const [filter, setFilter] = useState({application: APP_FEP});
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })
    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(() => {
        if(isLoadTable)
            loadPageInventories();
    }, [isLoadTable])

    const loadPageInventories = () => {
        getPageInventories(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setData(res.content)
                setPageable({
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false)
            }
        })
    }

    const onPage = (e) =>{
        setPageable({
            ...pageable,
            loading:true,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({
            ...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true)
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true)
    };

    const onKeyPressEnter = (e) => {
        if(e.key === 'Enter'){
            setPageable({...pageable, loading: true, page: 0});
            setLoadTable(true)
        }
    }

    const applyFilter = () => {
        setPageable({...pageable, loading: true, page: 0});
        setLoadTable(true)
    }

    const clearFilter = () => {
        setFilter({application: APP_FEP});
        setPageable({...pageable, loading: true, page: 0});
        setLoadTable(true)
    }

    const actionTemplate = (rowData) => {
        let dropActions = [
            {label: 'Edit', icon: 'pi-md-pencil', command: (e) => frmInventory.current.openForm(rowData)},
            {label: 'Delete', icon: 'pi-md-trash', command: (e) => deleteNotify.current.delete(rowData.id)}
        ];

        return (
            <SplitButton label="View" icon="pi-md-search" className="p-button-constrast p-l" model={dropActions} onClick={() => props.history.push(`/inventories/${rowData.id}`)}/>
        );
    };

    const onAddOrEdit = (data) => {
        frmInventory.current.openForm(data);
    }

    const deleteInventory = (id) => {
        removeInventory(id)
        .then(data => {
            if(!data.errorCode) {
                showSuccessNotify("Action Submitted");
                setLoadTable(true);
            } else {
                showErrorNotify(data.errorMessage, "Error");
            }
        })
    }

    const tblHeader =
        <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
            <Button icon="pi pi-plus" label={"Create new inventory"} className="p-button-constrast" style={{ float: "left" }} onClick={(e) => onAddOrEdit()} />
            <div style={{ 'float': 'right' }}>
                Show: <Dropdown className="p-datatable-length-selector" value={pageable.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => onChagePageLength(e.value)} />
                <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => loadPageInventories()}/>
            </div>
            <h3 className="fle-datatable-header-title">Inventory Listing</h3>
        </div>

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={window.location.href}>
                Inventory
            </BreadcrumbsItem>
            
            <DeleteNotification ref={deleteNotify}
                accessDelete={(e) => deleteInventory(e)}
            />

            <InventoryForm ref={frmInventory}
                reloadTable={() => setLoadTable(true)}
            />

            {/* <div className="p-col-12">
                <div className="card card-w-title">
                    <Fieldset legend="Filter" >
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Code: </span>
                                    <InputText value={filter.code} onKeyPress={onKeyPressEnter} onChange={(e) => setFilter({...filter, code: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Name: </span>
                                    <InputText value={filter.name} onKeyPress={onKeyPressEnter} onChange={(e) => setFilter({...filter, name: e.target.value})} />
                                </div>
                            </div>
                        </div>
                        <div className="p-grid p-margin-top-10">
                            <div className="p-col-12" style={{ "textAlign": "center" }}>
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={applyFilter} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={clearFilter} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div> */}
            
            <div className="p-col-12">
                <h1>Inventories</h1>

                <DataTable lazy={true} paginator={true} value={data} loading={pageable.loading}
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column field="code" header="Code" />
                    <Column field="name" header="Name" />
                    <Column header="Branch" body={(rowData) => rowData.branch && rowData.branch.name}/>
                    <Column field="active" header="Active" body={(rowData) => {return( rowData.active ? 'Yes' : 'No')}} />
                    <Column header="Actions" body={actionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
                </DataTable>
                
            </div>
        </div>
    )
}

export default InventoryListView;