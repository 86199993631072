import React, {Component} from 'react';
import {MembershipTemplateServices} from './MembershipTemplateServices';
import {Button} from 'primereact/button';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import { MembershipTemplateForm } from "./MembershipTemplateForm";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SplitButton } from 'primereact/splitbutton';

export class MembershipTemplates extends Component{
    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            formVisible: false,
        }

        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    componentDidMount(){
        showloading();
        this.getPageTemplates();
    }

    getPageTemplates = () => {
        this.membershipTemplateServices.getPageMembershipTemplates('', this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                stoploading();
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
    }

    addOrEditMembershipTemplate = (data) => {
        this.frmMembershipTemplate.popularFormData(data);
        this.setState({formVisible: true});
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.getPageTemplates();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.getPageTemplates();
        });
    }

    removeMembershipTemplate = (id) => {
        this.membershipTemplateServices.removeMembershipTemplate(id).then(res => {
            if(!res.errorCode){
                this.getPageTemplates();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    render(){
        let tblHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <Button label="Create Membership Type" icon="pi pi-plus" className="p-button-constrast" style={{ 'float': 'left' }} onClick={() => this.addOrEditMembershipTemplate()}/>
            <Button tooltip="Refresh" tooltipOptions={{position: "top"}} icon="pi pi-refresh" style={{'float':'right'}} onClick={() => this.getPageTemplates()}/>
            </div>;

        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Membership Types</BreadcrumbsItem>

                <MembershipTemplateForm ref={(el) => this.frmMembershipTemplate = el} visible={this.state.formVisible}
                    reloadTable={(e) => this.getPageTemplates()}
                    hideFormDialog={(e) => this.setState({formVisible: false})}
                />

                <DeleteNotification ref={el => this.deleteNotify=el}
                    accessDelete={(e) => this.removeMembershipTemplate(e)}
                />
                
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Membership Types</h1>
                        <DataTable lazy={true} paginator={true} value={this.state.dataList} header={tblHeader}
                            first={this.state.page * this.state.rows}
                            rows={this.state.rows} totalRecords={this.state.total}
                            onPage={this.onPage} onSort={this.onSort}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                            selectionMode="single" responsive={true}>
                            <Column field="id" header="ID" sortable={true}/>
                            <Column field="name" header="Name" sortable={true}/>
                            <Column field="description" header="Description" sortable={true}/>
                            <Column field="minPrice" header="Min Price" sortable={true}/>
                            <Column field="weight" header="Weight" sortable={true}/>
                            <Column header="" className="tc-actions" style={{width:'12em', overlay: 'visible'}}
                                body={(rowData) => {
                                    let dropActions = [
                                        {   label: 'Edit Membership',icon: 'pi pi-pencil',
                                            command: (e) => { this.addOrEditMembershipTemplate(rowData) }
                                        },
                                        {   label: 'Delete Membership', icon: 'pi pi-times',
                                            command: (e) => { this.deleteNotify.delete(rowData.id) }
                                        }
                                    ];
                                    return <SplitButton className="actionBtn" label="View" className="p-button-constrast" model={dropActions} tooltip={'View Membership'} tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(`/membership_templates/${rowData.id}`)}/>
                                }} 
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}