/* eslint-disable eqeqeq */
import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'primereact/spinner';
import { DISCOUNT_VALUE_TYPES, DEFAULT_RULE_DISCOUNT, PROMOTION_RULE_TYPE, translateListSimple } from '../../constants';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { parseMessage } from "../../core/utils/TranslationUtils";

class RuleDiscounts extends Component {
    constructor(props){
        super(props);
        this.state = {
            acrossQuantity: props.acrossQuantity,
            discounts: props.data,
            dishes: props.dishes
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({ discounts: this.props.data });
        }
    }

    addDiscountRange = () => {
        let tmpDiscounts = [...this.state.discounts];
        tmpDiscounts.push({...DEFAULT_RULE_DISCOUNT});
        this.props.onChange(tmpDiscounts);
    }

    removeDiscountRange = (idx) => {
        let tmpDiscounts = [...this.state.discounts];
        tmpDiscounts.splice(idx, 1);
        this.props.onChange(tmpDiscounts);
    }

    onElementChange = (idx, key, value) => {
        let tmpDiscounts = [...this.state.discounts];
        let discountItem = tmpDiscounts[idx];
        switch(key){
            case 'minQuantity':
                discountItem.minQuantity=value;
                break;
            case 'maxQuantity':
                discountItem.maxQuantity=value;
                break;
            // case 'applyForType':
            //     discountItem.applyForType=value;
            //     if(value!=='specific_product'){
            //         discountItem.applyForProduct='';
            //     }
            //     break;
            case 'applyQuantity':
                discountItem.applyQuantity=value;
                break;
            case 'applyForProduct':
                discountItem.applyForProduct=value;
                break;
            case 'discountValue':
                discountItem.discountValue=value;
                break;
            case 'discountValueType':
                discountItem.discountValueType=value;
                break;
            case 'loop':
                discountItem.loop=value;
                break;
            default:
                break;
        }
            
        tmpDiscounts.splice(idx, 1, discountItem);
        this.props.onChange(tmpDiscounts);
    }

    render(){
        const trans = this.props.trans;
        const errorDiscountValueIndex = this.props.errorDiscount && this.props.errorDiscount.discountValue ? this.props.errorDiscount.discountValue.info == 0 ? 0 : this.props.errorDiscount.discountValue.info : '';
        const errorMinQuantityIndex = this.props.errorDiscount && this.props.errorDiscount.minQuantity ? this.props.errorDiscount.minQuantity.info == 0 ? 0 : this.props.errorDiscount.discountValue.info : '';
        const errorMaxQuantityIndex = this.props.errorDiscount && this.props.errorDiscount.maxQuantity ? this.props.errorDiscount.maxQuantity.info == 0 ? 0 : this.props.errorDiscount.maxQuantity.info : '';
        const errorApplyQuantityIndex = this.props.errorDiscount && this.props.errorDiscount.applyQuantity ? this.props.errorDiscount.applyQuantity.info == 0 ? 0 : this.props.errorDiscount.applyQuantity.info : '';
        const errorApplyProductIndex = this.props.errorDiscount && this.props.errorDiscount.applyProduct ? this.props.errorDiscount.applyProduct.info == 0 ? 0 : this.props.errorDiscount.applyProduct.info : '';
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {(() => {
                        switch(this.props.type){
                            case PROMOTION_RULE_TYPE.cart_adjustment:
                            case PROMOTION_RULE_TYPE.product_adjustment:
                            case PROMOTION_RULE_TYPE.manual_order_discount:
                                return (
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-md-2">
                                            <label className="p-label p-w-normal">{trans('promotion.mgt.discount_type')}</label>
                                            <Dropdown value={this.state.discounts[0].discountValueType} className="p-frames" options={translateListSimple(trans, DISCOUNT_VALUE_TYPES)} onChange={(e) => this.onElementChange(0, 'discountValueType', e.value)}/>
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <label className="p-label p-w-normal">{trans('promotion.mgt.discount_value')}</label>
                                            <InputText value={this.state.discounts[0].discountValue+''} className="p-frames" onChange={(e) => this.onElementChange(0, 'discountValue', e.target.value)}/>
                                            <span className="p-form-error">{this.props.errorDiscount.discountValue && <div>{parseMessage(trans, this.props.errorDiscount.discountValue.code, this.props.errorDiscount.discountValue.info?{count: this.props.errorDiscount.discountValue.info[0]}:{})}</div>}</span>
                                        </div>
                                    </div>
                                );
                            case PROMOTION_RULE_TYPE.product_quantity:
                            case PROMOTION_RULE_TYPE.product_bxgx:
                            case PROMOTION_RULE_TYPE.product_bxgy:
                                return (
                                    <div className="p-grid">
                                        {this.props.type!==PROMOTION_RULE_TYPE.product_bxgx && 
                                        <div className="p-col-12">
                                            <label className="p-margin-right-10">{trans('promotion.mgt.count_quantity_by')}:</label>
                                            <RadioButton inputId="rbAcrossQuantity1" name="paymentOption" value={false} checked={!this.state.acrossQuantity} onChange={(e) => {this.setState({acrossQuantity: e.value}); this.props.onUpdateAcrossQuantity(e.checked)}}/>
                                            <label htmlFor="rbAcrossQuantity1" className="p-radiobutton-label p-margin-right-20">{trans('promotion.mgt.individual_dish')}</label>
                                            <RadioButton inputId="rbAcrossQuantity0" name="paymentOption" value={true} checked={this.state.acrossQuantity} onChange={(e) => {this.setState({acrossQuantity: e.value}); this.props.onUpdateAcrossQuantity(e.checked)}} />
                                            <label htmlFor="rbAcrossQuantity0" className="p-radiobutton-label">{trans('promotion.mgt.filters_set_above')}</label>
                                        </div>
                                        }
                                        
                                        <div className="p-col-12">
                                            {this.state.discounts.map((val, idx) => {
                                                return (
                                                    <div key={"range_"+idx} className="p-margin-bottom-10">
                                                        <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-padding-bottom-0">
                                                            <div className="p-grid p-fluid form-group">
                                                                <div className="p-col-12 p-md-1">
                                                                    <label className="p-label p-w-normal">{this.props.type===PROMOTION_RULE_TYPE.product_quantity?trans('promotion.mgt.min_qty'):trans('promotion.mgt.buy_qty')}</label>
                                                                    <Spinner value={val.minQuantity} className="p-frames" onChange={(e) => this.onElementChange(idx, 'minQuantity', e.value)}/>
                                                                    {errorMinQuantityIndex == idx &&
                                                                        <span className="p-form-error">{this.props.errorDiscount.minQuantity && <div>{parseMessage(trans, this.props.errorDiscount.minQuantity.code, this.props.errorDiscount.minQuantity.info?{count: this.props.errorDiscount.minQuantity.info[0]}:{})}</div>}</span>
                                                                    }
                                                                </div>
                                                                {this.props.type===PROMOTION_RULE_TYPE.product_quantity?
                                                                <div className="p-col-12 p-md-1">
                                                                    <label className="p-label p-w-normal">{trans('promotion.mgt.max_qty')}</label>
                                                                    <Spinner value={val.maxQuantity} className="p-frames" onChange={(e) => this.onElementChange(idx, 'maxQuantity', e.value)}/>
                                                                    {errorMaxQuantityIndex == idx &&
                                                                        <span className="p-form-error">{this.props.errorDiscount.maxQuantity && <div>{parseMessage(trans, this.props.errorDiscount.maxQuantity.code, this.props.errorDiscount.maxQuantity.info?{count: this.props.errorDiscount.maxQuantity.info[0]}:{})}</div>}</span>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="p-col-12 p-md-1">
                                                                    <label className="p-label p-w-normal">{trans('promotion.mgt.get_qty')}</label>
                                                                    <Spinner value={val.applyQuantity} className="p-frames" onChange={(e) => this.onElementChange(idx, 'applyQuantity', e.value)}/>
                                                                    {errorApplyQuantityIndex == idx &&
                                                                        <span className="p-form-error">{this.props.errorDiscount.applyQuantity && <div>{parseMessage(trans, this.props.errorDiscount.applyQuantity.code, this.props.errorDiscount.applyQuantity.info?{count: this.props.errorDiscount.applyQuantity.info[0]}:{})}</div>}</span>
                                                                    }
                                                                </div>
                                                                }
                                                                {this.props.type===PROMOTION_RULE_TYPE.product_bxgy &&
                                                                    <div className="p-col-12 p-md-4">
                                                                        <label className="p-label p-w-normal">{trans('promotion.mgt.dish_get_discount')}</label>
                                                                        <Dropdown value={val.applyForProduct} options={this.props.dishes} className="p-frames" filter={true} onChange={(e) => this.onElementChange(idx, 'applyForProduct', e.value)} style={{width: "100%"}} placeholder={trans('promotion.mgt.choose_dish')}/>
                                                                        {errorApplyProductIndex == idx &&
                                                                            <span className="p-form-error">{this.props.errorDiscount.applyProduct && <div>{parseMessage(trans, this.props.errorDiscount.applyProduct.code, this.props.errorDiscount.applyProduct.info?{count: this.props.errorDiscount.applyProduct.info[0]}:{})}</div>}</span>
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className="p-col-12 p-md-2">
                                                                    <label className="p-label p-w-normal">{trans('promotion.mgt.discount_type')}</label>
                                                                    <Dropdown value={val.discountValueType} className="p-frames" options={translateListSimple(trans, DISCOUNT_VALUE_TYPES)} onChange={(e) => this.onElementChange(idx, 'discountValueType', e.value)}/>
                                                                </div>
                                                                <div className="p-col-12 p-md-2">
                                                                    <label className="p-label p-w-normal">{trans('promotion.mgt.discount_value')}</label>
                                                                    <InputText value={val.discountValue} className="p-frames" onChange={(e) => this.onElementChange(idx, 'discountValue', e.target.value)}/>
                                                                    {errorDiscountValueIndex == idx &&
                                                                        <span className="p-form-error">{this.props.errorDiscount.discountValue && <div>{parseMessage(trans, this.props.errorDiscount.discountValue.code, this.props.errorDiscount.discountValue.info?{count: this.props.errorDiscount.discountValue.info[0]}:{})}</div>}</span>
                                                                    }
                                                                </div>
                                                                {this.state.discounts.length>1 && !this.state.discounts[0].loop?
                                                                    <div className="p-col-12 p-md-1">
                                                                        <Button icon="pi-md-close" className="p-button-danger btn-sm" style={{top: '19px'}} onClick={() => this.removeDiscountRange(idx)}/>
                                                                    </div>
                                                                :
                                                                    <div className="p-col-12 p-md-1">
                                                                        <label className="p-label p-w-normal">{trans('promotion.mgt.is_recursive')}?</label>
                                                                        <InputSwitch onLabel="Yes" offLabel="No" checked={val.loop} className="p-margin-top-10" onChange={(e) => this.onElementChange(idx, 'loop', e.target.value)} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Fieldset>
                                                    </div>                           
                                                );
                                            })} 
                                        </div>
                                        {!this.state.discounts[0].loop &&
                                            <div className="p-col-12 p-padding-top-0 p-padding-bottom-0">
                                                <Button label={trans('promotion.mgt.add_range')} icon="pi pi-plus" iconPos="left" onClick={this.addDiscountRange}/>
                                            </div>
                                        }
                                    </div>
                                )
                            default: break;
                        }
                    })()}
                </div>
            </div>
        );
    }
}
export default withNamespaces('message')(RuleDiscounts)