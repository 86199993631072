import axios from "axios";
import {PIM_BASE_URL, PIM_PATH} from "../../constants";
import qs from 'qs';
import moment from "moment";

const PIM_INVOICE_URL = PIM_BASE_URL + PIM_PATH + "/invoices";

export function getPageInvoices(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : `dateCreated,${order}`;
    return axios.post(`${PIM_INVOICE_URL}/page`, filter, {
        params: {page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function completeInvoice(id, gateway, method, transId, reason){
    return axios.post(`${PIM_INVOICE_URL}/${id}/complete`, null, {params: {gateway, method, transId, reason}})
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelInvoice(id, reason){
    return axios.post(`${PIM_INVOICE_URL}/${id}/cancel`, null, {params:{reason}})
    .then(res => res.data).catch(error => console.log(error));
}

export function getInvoice(id){
    return axios.get(`${PIM_INVOICE_URL}/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListInvoices(filter){
    return axios.get(`${PIM_INVOICE_URL}/list`, {params: {...filter}, paramsSerializer: params => {return qs.stringify(params)}})
    .then(res => res.data).catch(error => console.log(error));
}

export function sendInvoiceMailRemind(id){
    return axios.post(`${PIM_INVOICE_URL}/${id}/send-mail-remind`)
    .then(res => res.data).catch(error => console.log(error));
}