import React, { Component } from 'react';
import moment from 'moment';
import classNames from 'classnames'
import { ProgramServices } from './ProgramServices';
import { AttributeServices } from '../../scp/attribute/AttributeServices';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { CampaignServices } from "../../scp/campaign/CampaignServices";

import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { AttributeForm } from "./AttributeForm";
import { ScheduleForm } from './ScheduleForm';
import { PriceSettingsForm } from './PriceSettingsForm';
import { RegistrationSettingsForm } from './RegistrationSettingsForm';
import { NotifyChangeTab } from '../../core/NotifyChangeTab';
import { showNotification } from '../../core/service/NotificationService';
import { AuditLog } from '../../audit/AuditLog';
import { TalentServices } from '../../constituent-management/talent/TalentServices';
import { renderErrors } from '../../core/service/ErrorService';
import { DepartmentServices } from '../../scp/departments/DepartmentServices';
import PreventTransitionPrompt from '../../core/PreventTransitionPrompt';
import { Dialog } from 'primereact/dialog';
import { FormLockServices } from '../../core/FormLockServices';
import { UserAccessServices } from '../../scp/access-management/user/UserAccessServices';
// import ProgressButton from 'react-progress-button';
import { NotifyLocked } from '../../core/NotifyLocked';
import EventEmitter from 'eventemitter3';
import { NotifyOutDated } from '../../core/NotifyOutDated';
import { FieldWrapper } from '../../core/components/FieldWrapper';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { CampaignForm } from '../../scp/campaign/CampaignForm';
import { stoploading, showloading } from '../../core/service/LoadingService';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import { PROPERTY_TYPE, HR_RESOURCE_TYPE } from '../../constants';
import { getListHRs } from '../human_resource/HRServices';

const formEvent = new EventEmitter();
const highlightChanged = (a, b) => {
    return a !== b ? " input-highlight" : "";
}

const highlightLabelChanged = (a, b) => {
    return a !== b ? " labels-highlight" : "";
}

export class ProgramCourseSetupDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program: {},
            course: {},
            frmPriceData: {},
            frmRegistrationData: {},
            frmScheduleData: {},
            locations: [],
            instructors: [],
            trainers: [],
            attendee: {
                success: {
                    dataList: [],
                    page: 0,
                    rows: 10,
                    total: 0,
                    sortField: "",
                    sortOrder: 0,
                },
                waiting: {
                    dataList: [],
                    page: 0,
                    rows: 10,
                    total: 0,
                    sortField: "",
                    sortOrder: 0,
                }
            },
            addons: [],
            activeTabIndex: 0,
            errors: {},
            visibleChangeTab: false,
            termFrmPriceData: {},
            termFrmRegistrationData: {},
            termFrmScheduleData: {},
            talents: [],
            listValues: [],
            viewMode: true,
            isDirty: false,
            campaigns: [],
            activeCampaigns: [],
            discardVisible: false,
            editButtonLabel: "Edit",
            editingUser: {},
            showLockedDialog: false,
            formKey: '',
            showOutDatedDialog: false,
            tmpCourse: {},
            tmpImage: {}
        };

        this.programServices = new ProgramServices();
        this.attributeServices = new AttributeServices();
        this.participantServices = new ParticipantServices();
        this.campaignServices = new CampaignServices();
        this.talentServices = new TalentServices();
        this.departmentServices = new DepartmentServices();
        this.formlockServices = new FormLockServices();
        this.userServices = new UserAccessServices();
        // this.productServices = new ProductServices();
    }

    componentWillMount() {
        formEvent.on('ENTER_EDIT_MODE', this.onEnterEditMode);
        formEvent.on('CHANGE_SAVED', this.onChangeSaved);
        formEvent.on('RELOAD', this.reloadProgram);
    }

    componentDidMount() {
        showloading();
        this.loadIntructors();
        this.loadTrainers();
        this.loadProperties(PROPERTY_TYPE.location.value);
        this.loadProperties(PROPERTY_TYPE.branch.value);
        Promise.all([
            this.programServices.getProgram(this.props.match.params.cid, 'course'),
            this.campaignServices.getListCampaign(true),
            // this.programServices.getTalentsLevelsOfCourse(this.props.match.params.pid, this.props.match.params.cid)
        ])
            .then(result => {
                stoploading();
                let program = result[0];
                let activeCampaigns = result[1];
                // let talents = result[2];
                if (!program.errorCode) {
                    if (program && program.parent.id === parseInt(this.props.match.params.pid)) {
                        this.popularFrmScheduleData(program);
                        this.setState({
                            program: program.parent,
                            course: program,
                            addons: program.product && program.product.addons ? program.product.addons : null
                        }, () => {
                            this.popularCourseData(program);
                            // this.getPageCourseAttendeesSuccess();
                            // this.getPageCourseAttendeesWaiting();
                        });
                    }
                } else {
                    showNotification('error', 'Error Message', program.errorMessage);
                }
                this.setState({
                    activeCampaigns: activeCampaigns
                });
                // if (!talents.errorCode) {
                //     this.setState({
                //         talents: talents
                //     });
                // }
                let courseCamp = this.state.activeCampaigns.find(campaign => campaign.value === this.state.course.campaignId);
                if(!courseCamp){
                    let arr = [...activeCampaigns];
                    arr.unshift({value: this.state.course.campaignId, label: this.state.course.campaignName});
                    this.setState({
                        activeCampaigns: arr
                    });
                }
                this.setState({ activeTabIndex: 0 });
                window.onbeforeunload = () => {
                    if (this.state.isDirty || !this.state.viewMode) {
                        this.releaseFormLock();
                        return "Leave?";
                    }
                };
            })

            
    }

    componentWillUnmount() {
        formEvent.removeListener('ENTER_EDIT_MODE', this.onEnterEditMode);
        formEvent.removeListener('CHANGE_SAVED', this.onChangeSaved);
        formEvent.removeListener('RELOAD', this.reloadProgram);
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.course).length!==0){
    //         if(JSON.stringify(prevState.course) !== JSON.stringify(this.state.course)){
    //             if(JSON.stringify(this.state.course) !== JSON.stringify(this.state.tmpCourse)){
    //                 this.setState({ isDirty: true })
    //             }else{
    //                 this.setState({ isDirty: false })
    //             }
    //         }
    //     }
    // }

    popularFrmScheduleData = (data) => {
        this.setState({
            frmScheduleData: {
                id: data.id,
                startDate: data ? data.startDate : '',
                endDate: data ? data.endDate : '',
                startTime: data ? data.startTime : '',
                endTime: data ? data.endTime : '',
                startDateValue: data && data.startDate ? moment(data.startDate, moment.HTML5_FMT.DATE).toDate() : '',
                endDateValue: data && data.endDate ? moment(data.endDate, moment.HTML5_FMT.DATE).toDate() : '',
                startTimeValue: data && data.startTime ? moment(data.startTime, moment.HTML5_FMT.TIME).toDate() : '',
                endTimeValue: data && data.endTime ? moment(data.endTime, moment.HTML5_FMT.TIME).toDate() : '',
                schedules: data.schedules
            }
        }, () => {
            this.setState({
                termFrmScheduleData: this.state.frmScheduleData
            })
        });
    }

    getProgram = () => {
        this.programServices.getProgram(this.props.match.params.cid, 'course')
            .then(res => {
                if (!res.errorCode) {
                    if (res && res.parent.id === parseInt(this.props.match.params.pid)) {
                        // this.loadProperties();
                        this.loadIntructors(res.parent.departmentId);
                        this.popularFrmScheduleData(res);
                        this.setState({
                            program: res.parent,
                            course: {...res,
                                applyTax: res && res.product ? res.product.applyTax : false
                            },
                            addons: res.product && res.product.addons ? res.product.addons : null
                        }, () => {
                            this.popularCourseData(res);
                            // this.getPageCourseAttendeesSuccess();
                            // this.getPageCourseAttendeesWaiting();
                        });
                    }
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                }
            });
    }

    reloadProgram = () => {
        this.programServices.getProgram(this.props.match.params.cid, 'course')
            .then(res => {
                if (!res.errorCode) {
                    if (res && res.parent.id === parseInt(this.props.match.params.pid)) {
                        // this.loadProperties();
                        this.loadIntructors(res.parent.departmentId);
                        this.popularFrmScheduleData(res);
                        // this.getPageCourseAttendeesSuccess();
                        this.setState({
                            program: res.parent,
                            course: res,
                            addons: res.product.addons,
                            showOutDatedDialog: false,
                        }, () => {
                            this.popularCourseData(res);
                        });
                    }
                }
            });
    }

    popularCourseData = (data) => {
        this.setState({
            course: {
                ...this.state.course,
                workType: this.state.program.workType,
                startDateValue: data.startDate ? moment(data.startDate, moment.HTML5_FMT.DATE).toDate() : '',
                endDateValue: data.endDate ? moment(data.endDate, moment.HTML5_FMT.DATE).toDate() : '',
                startTimeValue: data.startTime ? moment(data.startTime, moment.HTML5_FMT.TIME).toDate() : '',
                endTimeValue: data.endTime ? moment(data.endTime, moment.HTML5_FMT.TIME).toDate() : '',
                schedules: data.schedules ? data.schedules : {},
                basicPrice: data.product ? data.product.price01 : 0,
                memberPrice: data.product ? data.product.price02 : 0,
                staffPrice01: data.product ? data.product.price03 : '',
                staffPrice02: data.product ? data.product.price04 : '',
                frequencies: data.frequencies && data.frequencies.length > 0 ? data.frequencies : [{name: '', periodType: 'monthly', periodLength: 1, numberOfOccurances: 2}],
                occurancePayAtDay: data.allowDraftPayment ? data.occurancePayAtDay : '1',
                fullPaymentDueDateValue: data.fullPaymentDueDate ? moment(data.fullPaymentDueDate, moment.HTML5_FMT.DATE).toDate() : '',
                deferredPaymentDueDate: data.product && data.deferredPaymentDueDate ? data.deferredPaymentDueDate : data.allowDraftPayment ? 1 : '',
                deferredPaymentDueDateValue: data.product && data.deferredPaymentDueDate ? !data.allowDraftPayment ? moment(data.deferredPaymentDueDate, 'YYYY-MM-DD').toDate() : '' : '',
                regStartDateValue: data.regStartDate ? moment(data.regStartDate, 'YYYY-MM-DD HH:mm').toDate() : '',
                regEndDateValue: data.regEndDate ? moment(data.regEndDate, 'YYYY-MM-DD HH:mm').toDate() : '',
                memberRegStartDateValue: data.memberRegStartDate ? moment(data.memberRegStartDate, 'YYYY-MM-DD HH:mm').toDate() : '',
                memberRegEndDateValue: data.memberRegEndDate ? moment(data.memberRegEndDate, 'YYYY-MM-DD HH:mm').toDate() : ''
            }
        }, () => {
            this.setState({
                tmpCourse: this.state.course
            });
        });
    }

    loadAllCampaigns = () => {
        const inactive = false
        const active = true
        this.campaignServices.getListCampaign(inactive).then(res => { this.setState({ campaigns: res }) });
        this.campaignServices.getListCampaign(active).then(res => { this.setState({ activeCampaigns: res }) });

    }

    loadAllActiveCampaigns = () => {
        const active = true
        this.campaignServices.getListCampaign(active).then(res => { this.setState({ activeCampaigns: res }) });

    }

    loadIntructors = () => {
        getListHRs({type: HR_RESOURCE_TYPE.marshaller.value}).then(res => this.setState({ instructors: res }));
    }

    loadTrainers = () => {
        getListHRs({type: HR_RESOURCE_TYPE.trainer.value}).then(res => this.setState({ trainers: res }));
    }

    loadProperties = () => {
        // getListProperties({type: type}, true)
        // .then(res => {
        //     switch (type) {
        //         case PROPERTY_TYPE.location.value:
        //             this.setState({ locations: res })
        //             break;
        //         case PROPERTY_TYPE.branch.value:
        //             this.setState({ branchs: res })
        //             break;
        //         default: break;
        //     }
        // });
    }

    //#region Information
    handleSaveBasicInformation = () => {
        this.programServices.saveBasicInformation(this.state.course)
            .then(basicInfo => {
                if (! basicInfo.errorCode) {
                    formEvent.emit("CHANGE_SAVED");
                    showNotification('success', 'Success Message', 'Action submitted');
                    this.campaignServices.getListCampaign(true).then(res => {
                        this.setState({ activeCampaigns: res }, () => {
                            let coursCamp = res.find(campaign => campaign.value === this.state.course.campaignId);
                            if(!coursCamp) {
                                let arr = res;
                                arr.unshift({value: basicInfo.campaignId, label: basicInfo.campaignName})
                                this.setState({
                                    activeCampaigns: arr
                                })
                            }
                        })
                    });
                    this.refreshData(basicInfo);
                } else {
                    showNotification('error', 'Error Message', basicInfo.errorMessage);
                    // this.campaignServices.getListCampaign(true).then(res => {
                    //     this.setState({ activeCampaigns: res }, () => {
                    //         const discamp = this.state.campaigns.find(campaign => campaign.value === this.state.course.campaignId);
                    //         let check = 0;
                    //         if (res.some(item => discamp.label === item.label)) {
                    //             check = 1;
                    //         }
                    //         if (check === 0) {
                    //             let arr = res;
                    //             arr.unshift(discamp)
                    //             this.setState({
                    //                 activeCampaigns: arr
                    //             })
                    //         };
                    //     })
                    // });
                    if (basicInfo.errorCode === 400) this.setState({ errors:  basicInfo.errorObj });
                }
            });

    }

    onChangeSwitchRegistration = (e) => {
        this.setState({
            course: {
                ...this.state.course,
                allowOnlineRegistration: e.value,
                showOnWeb: e.value ? true : this.state.course.showOnWeb
            },
            isDirty: true
        })
    }

    displayCampaign = (id) => {
        const campaign = this.state.campaigns.find(campaign => campaign.value === id);
        return (
            <div>
                {campaign ? campaign.label : 'N/A'}
            </div>
        );
    }

    displayLocation = (id) => {
        const location = this.state.locations.find(location => location.value === id);
        return (
            <div>
                {location ? location.label : 'N/A'}
            </div>
        );
    }

    displayLableFromListSimple = (list, id) => {
        if(!id) return '';
        if(Array.isArray(id)){
            let str=''
            id.map(value => {
                list.map((item) => {
                    if(item.value === value)
                        str += (str?', ':'') + item.label;
                })
            })
            return str
        }
        let item = list.find(item => item.value === id);
        return (
            <div>
                {item ? item.label : 'N/A'}
            </div>
        );
    }

    onCreateNewCampaign = (createdCamp) => {
        let actvCamps = [...this.state.activeCampaigns];
        if(createdCamp.active) actvCamps.push({ label: createdCamp.name, value: createdCamp.id });
        this.setState({
            course: {
                ...this.state.course,
                campaignId: createdCamp && createdCamp.active ? createdCamp.id : this.state.course.campaignId
            },
            activeCampaigns: actvCamps,
            isDirty: true
        })
    }

    onAddLocation = (type) => {
        this.setState({
            addLocationType: type
        });
        // this.locationForm.action();
        this.locationForm.action(null, PROPERTY_TYPE.location.value);
    }

    onCreateNewLocation = (data) => {
        let tempArr = [...this.state.locations];
        tempArr.push({label: data.name, value: data.id})
        this.setState({
            course: {...this.state.course,
                locationId: this.state.addLocationType==='primary'? data.id : this.state.course.locationId,
                secondaryLocationId: this.state.addLocationType==='secondary'? data.id : this.state.course.secondaryLocationId
            },
            locations: tempArr,
            isDirty: true
        })
    }

    onAddOrEditImage = (data, index) => {
        this.setState({
            tmpImage: {
                url: data?data:'',
                index: index
            },
            errors: {...this.state.errors,
                image: ''
            },
            isAddOrEditImage: true
        })
    }

    onSubmitImage = () => {
        let tmpArr = this.state.course.images?[...this.state.course.images]:[]
        let tmpImage = {...this.state.tmpImage}

        if(!tmpImage.url){
            this.setState({
                errors: {...this.state.errors,
                    image: 'Field is required'
                }
            })
            return
        }

        if(tmpImage.index || tmpImage.index === 0){
            tmpArr.splice(tmpImage.index, 1, tmpImage.url)
        }else{
            tmpArr.push(tmpImage.url)
        }
        this.setState({
            course: {...this.state.course,
                images: tmpArr
            },
            tmpImage: {},
            isAddOrEditImage: false,
            isDirty: true
        })
    }

    onRemoveImage = (index) => {
        let tmpArr = [...this.state.course.images]
        tmpArr.splice(index, 1)
        this.setState({
            course: {...this.state.course,
                images: tmpArr
            },
            isDirty: true
        })
    }

    onCancelImage = () =>{
        this.setState({
            tmpImage: { url: '' },
            isAddOrEditImage: false
        })
    }

    imageDisplayTemplate = (data, index) => {
        return(
            <div className="p-grid">
                <div className={classNames('p-col-12', {'p-md-8': !this.state.viewMode})} style={{wordBreak: 'break-word'}}>{data}</div>
                {!this.state.viewMode &&
                    <div className="p-col-12 p-md-4 p-r">
                        <Button label="Edit" onClick={() => this.onAddOrEditImage(data, index)} />
                        <Button label="Remove" className="p-button-danger" onClick={() => this.onRemoveImage(index)} />
                    </div>
                }
            </div>
        )
    }

    imageFormTemplate = () => {
        return(
            <Fieldset legend="">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <InputText value={this.state.tmpImage.url} onChange={(e) => this.setState({tmpImage: {...this.state.tmpImage, url: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.image}</div>
                    </div>
                    <div className="p-col-12 p-r">
                        <Button label="Submit" className="p-button-info" onClick={() => this.onSubmitImage()} style={{width: 'auto'}} />
                        <Button label="Cancel" onClick={() => this.onCancelImage()} style={{width: 'auto'}} />
                    </div>
                </div>
            </Fieldset>
        )
    }
    //#endregion

    //#region Participants
    getPageCourseAttendeesSuccess = () => {
        this.participantServices.getPageCourseParticipants(this.state.course.id, ['UNPAID', 'COMPLETED'], this.state.attendee.success.page, this.state.attendee.success.rows, this.state.attendee.success.sortField, this.state.attendee.success.sortOrder)
            .then(res => {
                this.setState({
                    attendee: {
                        ...this.state.attendee,
                        success: {
                            ...this.state.attendee.success,
                            dataList: res ? res.content : [],
                            loading: false,
                            page: res ? res.pageable.pageNumber : 0,
                            rows: res ? res.pageable.pageSize : 10,
                            total: res ? res.totalElements : 0
                        }
                    }
                });
            });
    }

    onAttendeeSuccessPage = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                success: {
                    ...this.state.attendee.success,
                    page: e.page
                }
            }
        } ,() => {
            this.getPageCourseAttendeesSuccess();
        });
    }

    onAttendeeSuccessSort = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                success: {
                    ...this.state.attendee.success,
                    sortField: e.sortField,
                    sortOrder: e.sortOrder
                }
            }
        } ,() => {
            this.getPageCourseAttendeesSuccess();
        });
    }

    cancelParticipant = (id) => {
        this.participantServices.cancelParticipant(id)
        .then(res => {
            if(!res.errorCode){
                this.getPageCourseAttendeesSuccess();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }
    //#endregion

    //#region Waiting list
    getPageCourseAttendeesWaiting = () => {
        this.participantServices.getPageCourseParticipants(this.state.course.id, 'WAITING', this.state.attendee.waiting.page, this.state.attendee.waiting.rows, this.state.attendee.waiting.sortField, this.state.attendee.waiting.sortOrder)
            .then(res => {
                this.setState({
                    attendee: {
                        ...this.state.attendee,
                        waiting: {
                            ...this.state.attendee.waiting,
                            dataList: res ? res.content : [],
                            loading: false,
                            page: res ? res.pageable.pageNumber : 0,
                            rows: res ? res.pageable.pageSize : 10,
                            total: res ? res.totalElements : 0
                        }
                    }
                });
            });
    }

    onAttendeeWaitingPage = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                waiting: {
                    ...this.state.attendee.waiting,
                    page: e.page
                }
            }
        } ,() => {
            this.getPageCourseAttendeesWaiting();
        });
    }

    onAttendeeWaitingSort = (e) => {
        this.setState({
            attendee: {
                ...this.state.attendee,
                waiting: {
                    ...this.state.attendee.waiting,
                    sortField: e.sortField,
                    sortOrder: e.sortOrder
                }
            }
        } ,() => {
            this.getPageCourseAttendeesWaiting();
        });
    }

    updatePriorityToParticipantWaiting = (partId, e) => {
        let tempArr = [...this.state.attendee.waiting.dataList];
        tempArr.map(part => {
            if(part.id === partId){
                let tempObj = {...part};
                tempObj.priority = e.value
                tempArr.splice(tempArr.indexOf(part), 1, tempObj);
            }
        })
        this.setState({
            attendee: {
                ...this.state.attendee,
                waiting: {
                    ...this.state.attendee.waiting,
                    dataList: tempArr
                }
            }
        },() => {
            this.participantServices.updatePriorityParticipant(partId, e.value)
            .then(res => {
                if(!res.errorCode){

                }else{
                    showNotification('error', 'Error Message', 'Cannot perform action');
                }
            })
        })
    }
    //#endregion

    //#region Talents
    handleSaveSkill = () => {
        this.programServices.saveTalents(this.state.course).then(res => {
            if (!res.errorCode) {
                showNotification('success', 'Success Message', 'Action submitted');
                this.refreshData(res);
            } else {
                showNotification('error', 'Error Message', res.errorMessage);
                if (res.errorCode === 400) this.setState({ errors: res.errorObj });
            }
        });
    }

    loadTalentsLevels = (pid, cid) => {
        this.programServices.getTalentsLevelsOfCourse(pid, cid)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        talents: res
                    })
                }
            });
    }

    onChangeSelectSkill = (values, itemKey, type) => {
        let talentItem = this.state.talents.find(t => t.key===itemKey)
        let talentName = talentItem?talentItem.name:''
        let termArr = [];
        values.map((item) => {
            termArr.push(item.value);
        })
        this.setState({
            course: {
                ...this.state.course,
                talents: {
                    ...this.state.course.talents,
                    [itemKey]: {
                        talentName: talentName,
                        current: type === 'current' ? termArr : (this.state.course.talents && this.state.course.talents[itemKey] ? this.state.course.talents[itemKey].current : []),
                        require: type === 'require' ? termArr : (this.state.course.talents && this.state.course.talents[itemKey] ? this.state.course.talents[itemKey].require : [])
                    }
                }
            }
        })
    }
    //#endregion

    resetChange = (keyField) => {
        this.setState({
            course: {...this.state.course,
                [keyField]: this.state.tmpCourse[keyField]
            }
        })
    }

    //#region Add-on
    updateTblAddons = (data, removeId) => {
        let tmpDatas = [...this.state.addons];
        let idx = -1; 

        if(data){
            idx = this.state.addons.findIndex((el) => el.id === data.id);
        }else{
            idx = this.state.addons.findIndex((el) => el.id === removeId);
        }

        if(idx !== -1){
            if(data){
                tmpDatas.splice(idx, 1, data);
            }else{
                tmpDatas.splice(idx, 1);
            }
        }else{
            tmpDatas.push(data);
        }

        this.setState({addons: tmpDatas});
    }

    // removeProgramAddon = (id) => {
    //     this.productServices.removeProductAssociation(this.state.course.product.id, id).then(res => {
    //         if(!res.errorCode){
    //             this.updateTblAddons(null, id);
    //             showNotification('success', 'Success Message', 'Action Submitted');
    //         }else{
    //             showNotification('error', 'Error Message', res.errorMessage);
    //         }
    //     });
    // }
    //#endregion

    //#region Change tab
    onTabChange = (e) => {
        this.setState({
            tmpTabIndex: e.index
        });
        if (!this.state.viewMode && this.state.isDirty) {
            this.setState({ visibleChangeTab: true })
        } else {
            this.releaseFormLock();
            this.setState({
                activeTabIndex: e.index,
                isDirty: false,
                viewMode: true
            });
        }
    }

    handleTabCancel = () => {
        switch (this.state.activeTabIndex) {
            case 0: // tab Information
                this.reloadProgram();
                break;
            case 1: // tab Schedules
                this.scheduleForm.onChangeDicarded();
                break;
            case 2: // tab Price Setting
                this.priceSettingsForm.onChangeDicarded();
                break;
            case 3: // tab Registration Setting
                this.regSettingForm.onChangeDicarded();
                break;
            case 4: // tab Attribute
                this.attributeForm.onChangeDicarded();
                break;
            default: break;
        }
        this.setState({
            visibleChangeTab: false
        });
        this.releaseFormLock();
        formEvent.emit('CHANGE_SAVED', this.onChangeSaved);
    }

    handleCancelNavigation = () => {
        switch (this.state.activeTabIndex) {
            case 0: // tab Information
                this.setState({
                    course: this.state.tmpCourse
                });
                break;
            case 1: // tab Schedules
                this.scheduleForm.onChangeDicarded();
                break;
            case 2: // tab Price Setting
                this.priceSettingsForm.onChangeDicarded();
                break;
            case 3: // tab Registration Setting
                this.regSettingForm.onChangeDicarded();
                break;
            case 4: // tab Attribute
                this.attributeForm.onChangeDicarded();
                break;
            default: break;
        }
        this.setState({
            visibleChangeTab: false,
            // isDirty: false,
            // activeTabIndex: this.state.tmpTabIndex,
            discardVisible: false,
            // viewMode: true
        });
        this.releaseFormLock();
        formEvent.emit('CHANGE_SAVED', this.onChangeSaved);
    }

    saveChangeTab = () => {
        switch (this.state.activeTabIndex) {
            case 0: // tab Information
                this.handleSaveBasicInformation();
                break;
            case 1: // tab Schedules
                this.scheduleForm.handleSaveDaysSchedule();
                break;
            case 2: // tab Price Setting
                this.priceSettingsForm.handleSavePriceSetting();
                break;
            case 3: // tab Registration Setting
                this.regSettingForm.handleSaveRegistrationSettings();
                break;
            case 4: // tab Attribute
                this.attributeForm.handleSaveAttributeSettings();
                break;
            default: break;
        }
        this.setState({
            activeTabIndex: this.state.tmpTabIndex,
            visibleChangeTab: false,
            isDirty: false,
        })
    }

    onChangeDicarded = () => {
        switch (this.state.activeTabIndex) {
            case 0: // tab Information
                this.setState({
                    course: this.state.tmpCourse
                });
                break;
            case 1: // tab Schedules
                this.scheduleForm.onChangeDicarded();
                break;
            case 2: // tab Price Setting
                this.priceSettingsForm.onChangeDicarded();
                break;
            case 3: // tab Registration Setting
                this.regSettingForm.onChangeDicarded();
                break;
            case 4: // tab Attribute
                this.attributeForm.onChangeDicarded();
                break;
            default: break;
        }
        this.releaseFormLock();
        this.setState({
            isDirty: false,
            discardVisible: false,
            viewMode: true,
        });
    }

    onChangeSaved = () => {
        this.setState({
            isDirty: false,
            viewMode: true,
            formKey: '',
            editButtonLabel: "Edit"
        });
    }
    //#endregion

    //#region Form lock
    refreshData = (data) => {
        this.setState({
            course: data,
            isDirty: false,
            errors: {}
        }, () => {
            this.popularCourseData(data);
        });
    }

    acquireFormLock = (formKey) => {
        this.setState({
            formKey: formKey,
            editButtonLabel: "Checking..."
        }, () => { this.onEditMode() });
    }

    onCloseLockedDialog = () => {
        this.setState({
            editButtonLabel: "Edit",
            showLockedDialog: false,
        })
    }

    onCloseOutDatedDialog = () => {
        this.setState({
            editButtonLabel: "Edit",
            showOutDatedDialog: false,
        })
    }

    releaseFormLock = () => {
        if (!this.state.formKey) return;
        this.formlockServices.releaseFormLock(this.state.formKey, this.props.match.params.cid)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        formKey: '',
                        editButtonLabel: "Edit"
                    });
                }
            });
    }
    //#endregion

    //#region Edit mode
    onEditMode = () => {
        if (!this.state.formKey) return;
        this.formlockServices.acquireFormLock(this.state.formKey, this.props.match.params.cid, this.state.course.version)
            .then(res => {
                if (!res.errorCode) {
                    this.setState({
                        viewMode: false,
                        isDirty: false,
                        showLockedDialog: false
                    });
                } else {

                }
            })
            .catch(error => {
                let res = error.response;
                if (res.status === 423) {
                    this.setState({
                        viewMode: true,
                        isDirty: false,
                        editingUser: res.data,
                        showLockedDialog: true
                    });
                }
                if (res.status === 409) {
                    this.setState({
                        viewMode: true,
                        isDirty: false,
                        showOutDatedDialog: true
                    });
                }
            });
    }

    onEnterEditMode = () => {
        this.setState({
            viewMode: false,
            isDirty: true
        });
    }

    onCancelViewMode = () => {
        this.setState({
            discardVisible: this.state.isDirty ? true : false,
            viewMode: this.state.isDirty ? false : true,
            isAddOrEditImage: false
        });
        this.releaseFormLock();
    }
    //#endregion

    //#region Assessment
    quickAssessment = (type) => {
        let data = [];
        switch (type) {
            case 'level':
                data = this.state.course.talents ? this.state.talents.filter(talent => Object.keys(this.state.course.talents).some(enabledTalent => enabledTalent === talent.key)) : [];
                break;
            case 'skill':
                let talentCurrentCourse = this.state.course.talents ? { ...this.state.course.talents } : {};
                this.state.talents.map(talent => {
                    let temTalentObj = {
                        key: talent.key,
                        name: talent.name,
                        values: []
                    }
                    Object.entries(talentCurrentCourse).map(([keyCurrent, valueCurrent]) => {
                        if (talent.key === keyCurrent) {
                            talent.values.map(level => {
                                if (valueCurrent.current) {
                                    valueCurrent.current.map(item => {
                                        if (level.value === item) {
                                            temTalentObj.values.push(level);
                                        }
                                    })
                                }
                            })
                        }
                    })
                    data.push(temTalentObj);
                })
                break;
        }
        this.quickAssessmentForm.actionForm(type, data);
    }

    onAssessmentManagement = () => {
        this.setState({
            goToAssessManagement: true
        })
    }
    //#endregion

    render() {
        const asterik = <span style={{color: 'red',}}>*</span>;

        if (this.state.goToAssessManagement) {
            return <Redirect to={`/programs/${this.state.program.id}/courses/${this.state.course.id}/manage/assessment`} />;
        }

        let tblAddonHeader = 
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button icon="pi pi-plus" className="p-button-constrast" style={{'float':'left'}} onClick={() => this.frmProgramAddon.openForm(this.state.course.product, null, 'addon')}/>
                <h3 className="fle-datatable-header-title">Addons</h3>
            </div>;

        return (
            <div className="p-grid">
                <BreadcrumbsItem to="/programs">Programs</BreadcrumbsItem>
                <BreadcrumbsItem to={`/programs/${this.state.program.id}`}>{this.state.program.name}</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>{this.state.course.name}</BreadcrumbsItem>

                <PreventTransitionPrompt
                    when={this.state.isDirty}
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={() => this.state.isDirty}
                    handleSave={this.saveChangeTab}
                    handleDiscard={this.handleCancelNavigation}
                />

                <NotifyChangeTab
                    visible={this.state.visibleChangeTab}
                    saveChangeTab={() => this.saveChangeTab()}
                    discardTab={() => this.handleTabCancel()}
                    cancelChangeTab={() => this.setState({ visibleChangeTab: false })}
                />

                <NotifyLocked
                    visible={this.state.showLockedDialog}
                    user={this.state.editingUser}
                    onEditMode={() => this.onEditMode()}
                    onHide={() => this.onCloseLockedDialog()}
                />

                <NotifyOutDated
                    visible={this.state.showOutDatedDialog}
                    onHide={() => this.onCloseOutDatedDialog()}
                    formEvent={formEvent}
                />

                <Dialog visible={this.state.discardVisible} contentStyle={styles.dialogContent} responsive={true}
                    modal={true} dismissableMask={true} showHeader={false} onHide={() => this.setState({ visible: false })}
                    footer={<React.Fragment>
                        <Button label="Discard" icon="pi-md-close" onClick={() => this.handleCancelNavigation()} />
                        <Button label="Cancel" className="p-button-secondary" onClick={() => this.setState({ discardVisible: false })} />
                    </React.Fragment>}
                >
                    The changes have not been saved!
                </Dialog>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Program: {this.state.program.name}
                            <div className="p-toolbar-group-right">
                                <Link to={`/programs/${this.props.match.params.pid}`} className="p-button p-component p-button-primary p-button-icon-only">
                                    <span className="pi pi-caret-left p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">Type: {this.state.program.workTypeName}</div>
                            <div className="p-col-12 p-md-4">Department: {this.state.program.departmentName}</div>
                            <div className="p-col-12 p-md-4">Category: {this.state.program.categoryName}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Course: {this.state.course.code + ' - ' + this.state.course.name}
                        </h1>
                        {/* <div className="p-toolbar-group-right">
                            <SplitButton label="Assessment Mangement" model={itemAssessments} onClick={(e) => this.onAssessmentManagement()} ></SplitButton>
                        </div> */}
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">Location: {this.state.course.locationName}</div>
                            <div className="p-col-12 p-md-4">Total attendees: {this.state.attendeeTotal}</div>
                            <div className="p-col-12 p-md-4">Active: {this.state.course.active ? 'Yes' : 'No'}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.onTabChange(e)} >
                        <TabPanel header="Information">
                            <CampaignForm ref={el => this.campaignForm = el} 
                                campaign={(data) => this.onCreateNewCampaign(data)}
                            />

                            <div className="p-grid">
                                <div className="p-col-12 p-md-7">
                                    <Fieldset legend="Basic Information">
                                    <div className="p-grid p-fluid form-group">
                                        <FieldWrapper col="p-col-12" keyField="branchId">
                                            <label className="p-label">Branch: {this.state.program.branchName}</label>
                                        </FieldWrapper>
                                        {!this.state.viewMode ?
                                            <React.Fragment>
                                                <FieldWrapper col="p-col-12" keyField="campaignId" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.campaignId, this.state.tmpCourse.campaignId)}>
                                                    <label className="p-label">{asterik} Campaign</label>
                                                    <div className="p-inputgroup">
                                                        <Dropdown value={this.state.course.campaignId} options={this.state.activeCampaigns} onChange={(e) => this.setState({ course: { ...this.state.course, campaignId: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select a Campaign" />
                                                        <Button label="New" icon="pi-md-plus" onClick={() => this.campaignForm.action()} />
                                                    </div>
                                                    <span className="p-form-error">{renderErrors(this.state.errors.campaign)}</span>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12 p-md-6" keyField="code" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.code, this.state.tmpCourse.code)}>
                                                    <label className="p-label">{asterik} Code</label>
                                                    <InputText keyfilter={/[^\s]/} value={this.state.course.code} onChange={(e) => this.setState({course: {...this.state.course, code: replaceSpaceAndUpperCase(e.target.value)}, isDirty: true})} />
                                                    <span className="p-form-error">{renderErrors(this.state.errors.code)}</span>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12 p-md-6" keyField="name" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.name, this.state.tmpCourse.name)}>
                                                    <label className="p-label">{asterik} Name</label>
                                                    <InputText value={this.state.course.name} onChange={(e) => this.setState({course: {...this.state.course, name: titleCaseText(e.target.value)}, isDirty: true})} />
                                                    <span className="p-form-error">{renderErrors(this.state.errors.name)}</span>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="description" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.description, this.state.tmpCourse.description)}>
                                                    <label className="p-label">Description</label>
                                                    <InputTextarea id="description" rows={3} cols={100} value={this.state.course.description} placeholder="Enter course description" onChange={(e) => this.setState({ course: { ...this.state.course, description: e.target.value }, isDirty: true })} />
                                                </FieldWrapper>

                                                <FieldWrapper col="p-col-12" keyField="moderatorEmail" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.moderatorEmail, this.state.tmpCourse.moderatorEmail)}>
                                                    <label className="p-label">Marshaller Emails (Seperated by comma)</label>
                                                    <InputText id="emails" value={this.state.course.moderatorEmail} onChange={(e) => this.setState({ course: { ...this.state.course, moderatorEmail: e.target.value }, isDirty: true })} />
                                                    <span className="p-form-error">{renderErrors(this.state.errors.moderatorEmail)}</span>
                                                </FieldWrapper>

                                                <FieldWrapper col="p-col-12" keyField="locationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.locationId,this.state.tmpCourse.locationId)}>
                                                    <label className="p-label">{asterik} Primary Location</label>
                                                    <div className="p-inputgroup">
                                                        <Dropdown id="location" value={this.state.course.locationId} filter={true} showClear={true} options={this.state.locations} onChange={(e) => this.setState({ course: { ...this.state.course, locationId: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select a Location" />
                                                        <Button label="New" icon="pi-md-plus" onClick={() => this.onAddLocation('primary')} />
                                                    </div>
                                                    <span className="p-form-error">{renderErrors(this.state.errors.location)}</span>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="secondaryLocationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.secondaryLocationId,this.state.tmpCourse.secondaryLocationId)}>
                                                    <label className="p-label">Secondary Location</label>
                                                    <div className="p-inputgroup">
                                                        <Dropdown id="location" value={this.state.course.secondaryLocationId} filter={true} showClear={true} options={this.state.locations} onChange={(e) => this.setState({ course: { ...this.state.course, secondaryLocationId: e.value }, isDirty: true })} style={{ width: '100%' }} placeholder="Select a Location" />
                                                        <Button label="New" icon="pi-md-plus" onClick={() => this.onAddLocation('secondary')} />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="instructorIds" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.instructorIds,this.state.tmpCourse.instructorIds)}>
                                                    <label className="p-label">Instructor</label>
                                                    <MultiSelect value={this.state.course.instructorIds} filter={true} options={this.state.instructors} onChange={(e) => this.setState({ course: { ...this.state.course, instructorIds: e.value }, isDirty: true })} placeholder="Select Instructor" />
                                                    <span className="p-form-error">{renderErrors(this.state.errors.instructor)}</span>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="trainerIds" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.trainerIds,this.state.tmpCourse.trainerIds)}>
                                                    <label className="p-label">Trainer</label>
                                                    <MultiSelect value={this.state.course.trainerIds} filter={true} options={this.state.trainers} onChange={(e) => this.setState({ course: { ...this.state.course, trainerIds: e.value }, isDirty: true })} placeholder="Select Trainer" />
                                                    <span className="p-form-error">{renderErrors(this.state.errors.instructor)}</span>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12 p-md-4" keyField="weight" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.weight,this.state.tmpCourse.weight)}>
                                                    <label className="p-label" htmlFor="weight">Weight (for order)</label>
                                                    <InputText id="weight" keyfilter="int" value={this.state.course.weight} onChange={(e) => this.setState({ course: { ...this.state.course, weight: e.target.value }, isDirty: true })} />
                                                </FieldWrapper>
                                            </React.Fragment>
                                        :
                                            <React.Fragment>
                                                <FieldWrapper col="p-col-12" keyField="campaignName" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.campaignName, this.state.tmpCourse.campaignName)}>
                                                    <label className="p-label">Campaign</label>
                                                    {this.state.course.campaignName}
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12 p-md-6" keyField="code" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.code, this.state.tmpCourse.code)}>
                                                    <label className="p-label">Code</label>
                                                    <div>{this.state.course.code}</div>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12 p-md-6" keyField="name" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.name, this.state.tmpCourse.name)}>
                                                    <label className="p-label">Name</label>
                                                    <div>{this.state.course.name}</div>
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="description" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.description, this.state.tmpCourse.description)}>
                                                    <label className="p-label">Description</label>
                                                    <div>{this.state.course.description}</div>
                                                </FieldWrapper>
                                                {this.state.course.moderatorEmail == null || this.state.course.moderatorEmail === '' ?
                                                <FieldWrapper col="p-col-12" keyField="moderatorEmail" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.description, this.state.tmpCourse.description)}>
                                                    <label className="p-label">Marshaller Emails (Seperated by comma)</label>
                                                    <div>{this.state.program.moderatorEmail}</div>
                                                </FieldWrapper> 
                                                :
                                                <FieldWrapper col="p-col-12" keyField="moderatorEmail" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.description, this.state.tmpCourse.description)}>
                                                    <label className="p-label">Marshaller Emails (Seperated by comma)</label>
                                                    <div>{this.state.course.moderatorEmail}</div>
                                                </FieldWrapper>   
                                                }
                                                <FieldWrapper col="p-col-12" keyField="locationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.locationId,this.state.tmpCourse.locationId)}>
                                                    <label className="p-label">Primary Location</label>
                                                    {this.displayLocation(this.state.course.locationId)}
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="secondaryLocationId" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.secondaryLocationId,this.state.tmpCourse.secondaryLocationId)}>
                                                    <label className="p-label">Secondary Location</label>
                                                    {this.displayLocation(this.state.course.secondaryLocationId)}
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="instructorIds" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.instructorIds,this.state.tmpCourse.instructorIds)}>
                                                    <label className="p-label">Instructor</label>
                                                    {this.displayLableFromListSimple(this.state.instructors, this.state.course.instructorIds)}
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12" keyField="trainerIds" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.trainerIds,this.state.tmpCourse.trainerIds)}>
                                                    <label className="p-label">Trainer</label>
                                                    {this.displayLableFromListSimple(this.state.trainers, this.state.course.trainerIds)}
                                                </FieldWrapper>
                                                <FieldWrapper col="p-col-12 p-md-4" keyField="weight" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.weight,this.state.tmpCourse.weight)}>
                                                    <label className="p-label" htmlFor="weight">Weight</label>
                                                    <div>{this.state.course.weight}</div>
                                                </FieldWrapper>
                                            </React.Fragment>
                                        }
                                        </div>
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-md-5">
                                    <Fieldset legend="Notification">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">Notification by:</label>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cbNotifyWhenFull" disabled={this.state.viewMode ? true : false} checked={this.state.course.notifyWhenFull} onChange={(e) => this.setState({ course: { ...this.state.course, notifyWhenFull: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyWhenFull" className={'p-checkbox-label' + highlightLabelChanged(this.state.course.notifyWhenFull, this.state.tmpCourse.notifyWhenFull)}>Notify when full?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cbNotifyWhenWaitlistFull" disabled={this.state.viewMode ? true : false} checked={this.state.course.notifyWhenWaitlistFull} onChange={(e) => this.setState({ course: { ...this.state.course, notifyWhenWaitlistFull: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyWhenWaitlistFull" className={'p-checkbox-label' + highlightLabelChanged(this.state.course.notifyWhenWaitlistFull, this.state.tmpCourse.notifyWhenWaitlistFull)}>Notify when waitlist full?</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cbNotifyEveryRegistration"  disabled={this.state.viewMode ? true : false} checked={this.state.course.notifyEveryRegistration} onChange={(e) => this.setState({ course: { ...this.state.course, notifyEveryRegistration: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyEveryRegistration" className={'p-checkbox-label p-margin-right-30' + highlightLabelChanged(this.state.course.notifyEveryRegistration, this.state.tmpCourse.notifyEveryRegistration)}>Notify Every Registration?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cbNotifyRegistrationReach"  disabled={this.state.viewMode ? true : false} checked={this.state.course.notifyRegistrationReach} onChange={(e) => this.setState({ course: { ...this.state.course, notifyRegistrationReach: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbNotifyRegistrationReach" className={'p-checkbox-label' + highlightLabelChanged(this.state.course.notifyRegistrationReach, this.state.tmpCourse.notifyRegistrationReach)}>Registration Reached Threshold?</label>
                                                    </div>
                                                    <FieldWrapper col="p-col-12" keyField="notifyRegistrationReachQuantity" resetChange={this.resetChange} highlight={highlightChanged(this.state.course.notifyRegistrationReachQuantity,this.state.tmpCourse.notifyRegistrationReachQuantity)}>
                                                    <label className="p-label">Registration Number Threshold</label>
                                                        {!this.state.viewMode ?
                                                            <React.Fragment>
                                                                <InputText keyfilter="int" value={this.state.course.notifyRegistrationReachQuantity} onChange={(e) => this.setState({ course: { ...this.state.course, notifyRegistrationReachQuantity: e.target.value }, isDirty: true })} />
                                                                <span className="p-form-error">{renderErrors(this.state.errors.notifyRegReachQuantity)}</span>
                                                            </React.Fragment>
                                                            : <div>{this.state.course.notifyRegistrationReachQuantity}</div>
                                                        }
                                                    </FieldWrapper>        
                                                </div>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Display">
                                        {!this.state.viewMode ?
                                        <React.Fragment>
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.active} onChange={(e) => this.setState({ course: { ...this.state.course, active: e.value }, isDirty: true })} />
                                                    <label className={'p-margin-left-10' + highlightLabelChanged(this.state.course.active, this.state.tmpCourse.active)}>Active?</label>
                                                </div>
                                                {this.state.program.workType === 'simple' ?
                                                    [
                                                        <div key="0" className="p-col-12">
                                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.allowOnlineRegistration} onChange={(e) => this.onChangeSwitchRegistration(e)} />
                                                            <label className={'p-margin-left-10' + highlightLabelChanged(this.state.course.allowOnlineRegistration, this.state.tmpCourse.allowOnlineRegistration)}>Allow online registration?</label>
                                                        </div>,
                                                        <div key="1" className="p-col-12">
                                                            <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.showOnWeb} disabled={this.state.course.allowOnlineRegistration ? true : false} onChange={(e) => this.setState({ course: { ...this.state.course, showOnWeb: e.value }, isDirty: true })} />
                                                            <label className={'p-margin-left-10' + highlightLabelChanged(this.state.course.showOnWeb, this.state.tmpCourse.showOnWeb)}>Show on web?</label>
   
                                                        </div>
                                                        // ,<div key="2" className="p-col-12">
                                                        //     {!this.state.viewMode ?
                                                        //         <React.Fragment>
                                                        //             <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.showNotAvailable} onChange={(e) => this.setState({ course: { ...this.state.course, showNotAvailable: e.value }, isDirty: true })} />
                                                        //             <label className="p-margin-left-10">Show when not availability (available in future)?</label>
                                                        //         </React.Fragment>
                                                        //         :
                                                        //         <div>Show when not availability: {this.state.course.showNotAvailable ? 'Yes' : 'No'}</div>
                                                        //     }
                                                        // </div>
                                                    ]
                                                    : ''
                                                }
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <div>Active: {this.state.course.active ? 'Yes' : 'No'}</div>
                                                </div>
                                                {this.state.program.workType === 'simple' ?
                                                    [
                                                        <div key="0" className="p-col-12">
                                                            <div>Allow online registration: {this.state.course.allowOnlineRegistration ? 'Yes' : 'No'}</div>
                                                        </div>,
                                                        <div key="1" className="p-col-12">
                                                            <div>Show on web: {this.state.course.showOnWeb ? 'Yes' : 'No'}</div>
                                                        </div>
                                                        // ,<div key="2" className="p-col-12">
                                                        //     {!this.state.viewMode ?
                                                        //         <React.Fragment>
                                                        //             <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.course.showNotAvailable} onChange={(e) => this.setState({ course: { ...this.state.course, showNotAvailable: e.value }, isDirty: true })} />
                                                        //             <label className="p-margin-left-10">Show when not availability (available in future)?</label>
                                                        //         </React.Fragment>
                                                        //         :
                                                        //         <div>Show when not availability: {this.state.course.showNotAvailable ? 'Yes' : 'No'}</div>
                                                        //     }
                                                        // </div>
                                                    ]
                                                    : ''
                                                }
                                            </div>
                                        </React.Fragment>
                                        }
                                    </Fieldset>
                                    {/* <Fieldset legend="Images">
                                        <div className="p-grid">
                                            {!this.state.viewMode && !this.state.isAddOrEditImage &&
                                                <div className="p-col-12">
                                                    <Button label="Add Image" onClick={() => this.onAddOrEditImage()} />
                                                </div>
                                            }
                                            {!this.state.viewMode && this.state.isAddOrEditImage && !this.state.tmpImage.index && this.state.tmpImage.index !== 0 &&
                                                <div className="p-col-12">
                                                    {this.imageFormTemplate()}
                                                </div>
                                            }
                                            {this.state.course.images && this.state.course.images.map((value, index) => {
                                                return <React.Fragment>
                                                    {index > 0 && <div className="p-col-12"><div className="p-hr"></div></div>}
                                                    <div className="p-col-12">
                                                        {(()=>{
                                                            if(!this.state.viewMode && this.state.tmpImage.index === index){
                                                                return this.imageFormTemplate()
                                                            }else {
                                                                return this.imageDisplayTemplate(value, index)
                                                            }
                                                        })()}
                                                    </div>
                                                </React.Fragment>
                                            })}
                                        </div>
                                    </Fieldset> */}
                                </div>
                                {this.state.course.id ?
                                    <div className="p-col-12">
                                        {!this.state.viewMode ?
                                            <React.Fragment>
                                                <Button label="Cancel Edit" icon="pi pi-lock" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.onCancelViewMode()} />
                                                <Button label="Save Basic Information" disabled={this.state.isDirty ? "" : "disabled"} icon="pi pi-save" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.handleSaveBasicInformation()} />
                                            </React.Fragment>
                                            :
                                            <div>
                                                <Button label={this.state.editButtonLabel} icon="pi pi-lock-open" iconPos="right" style={{ 'float': 'right' }} onClick={() => this.acquireFormLock('programLock')} />
                                            </div>

                                        }
                                    </div>
                                    : ''
                                }
                            </div>
                        </TabPanel>
                        <TabPanel header="Schedules">
                            <ScheduleForm ref={el => this.scheduleForm = el}
                                data={this.state.course}
                                refreshData={(e) => this.refreshData(e)}
                                isDirty={this.state.isDirty}
                                viewMode={this.state.viewMode}
                                editButtonLabel={this.state.editButtonLabel}
                                acquireFormLock={() => this.acquireFormLock('programLock')}
                                onCancelViewMode={this.onCancelViewMode}
                                formEvent={formEvent}
                            />
                        </TabPanel>
                        <TabPanel header="Fees & Accounting">
                            <PriceSettingsForm ref={el => this.priceSettingsForm = el}
                                data={this.state.course}
                                deptId={this.state.program.departmentId}
                                campaignId={this.state.course.campaignId}
                                refreshData={(e) => this.refreshData(e)}
                                isDirty={this.state.isDirty}
                                viewMode={this.state.viewMode}
                                editButtonLabel={this.state.editButtonLabel}
                                acquireFormLock={() => this.acquireFormLock('programLock')}
                                onCancelViewMode={this.onCancelViewMode}
                                formEvent={formEvent}
                            />
                        </TabPanel>
                        <TabPanel header={this.state.program.workType === 'simple' ? 'Registration Settings' : ''} disabled={this.state.program.workType === 'simple' ? false : true}>
                            <RegistrationSettingsForm ref={el => this.regSettingForm = el}
                                data={this.state.course} courseId={this.props.match.params.cid}
                                refreshData={(e) => this.refreshData(e)}
                                isDirty={this.state.isDirty}
                                viewMode={this.state.viewMode}
                                editButtonLabel={this.state.editButtonLabel}
                                acquireFormLock={() => this.acquireFormLock('programLock')}
                                onCancelViewMode={this.onCancelViewMode}
                                formEvent={formEvent}
                            />
                        </TabPanel>
                        <TabPanel header={this.state.program.workType === 'simple' ? 'Addons' : ''} disabled={this.state.program.workType === 'simple' ? false : true}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <DataTable lazy={true} header={tblAddonHeader} value={this.state.addons}>
                                        <Column header="Name" body={rowData => <Link to={`/product-management/addon/${rowData.associationProduct.id}`} className="nav-link">{rowData.associationProduct.name}</Link> }/>
                                        <Column header="Price" body={rowData => rowData.unitPrice?'$'+rowData.unitPrice:'$'+rowData.associationProduct.price01}/>
                                        <Column header="Association method" 
                                            body={rowData => {
                                                if(rowData.included){
                                                    return "Included"
                                                }else{
                                                    return "Optional";
                                                }
                                            }}/>
                                        <Column header="Association Q.ty" 
                                            body={rowData => {
                                                if(rowData.included){
                                                    return rowData.quantity;
                                                }else{
                                                    if(rowData.allowInputQty)
                                                        return 'Max q.ty can purchase is ' + rowData.quantity;
                                                    else
                                                        return rowData.quantity + ' per selection';
                                                }
                                            }}/>
                                        <Column header="" style={{textAlign:'right',width:'8em'}}
                                            body={rowData => {
                                                return <div>
                                                    <Button icon="pi-md-close" className="p-button-danger" style={{'float':'right'}} onClick={() => this.removeProgramAddon(rowData.id)}/>
                                                    <Button icon="pi pi-pencil" className="p-button-warning" style={{'float':'right'}} onClick={() => this.frmProgramAddon.openForm(this.state.course.product, rowData, 'addon')}/>
                                                </div>;
                                            }}/>
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header={this.state.program.workType === 'simple' && this.state.program.attributeSet ? 'Attribute Set: ' + this.state.program.attributeSet.name : ''} disabled={this.state.program.workType === 'simple' && this.state.program.attributeSet ? false : true}>
                            <AttributeForm ref={el => this.attributeForm = el}
                                data={this.state.course}
                                refreshData={(e) => this.refreshData(e)}
                                updating={(e) => this.setState({ isDirty: e })}
                            />
                        </TabPanel>
                        <TabPanel header="Audits">
                            <AuditLog type="program" id={this.props.match.params.cid} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        )
    };
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}
