import React, { Component } from 'react';
import { FullCalendar } from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AssetScheduleForm from './AssetScheduleForm';
import { getSchedule, getInventorySchedules, getScheduleList } from './SchedulesService';
import { SCHEDULE_TYPE } from '../../constants';


const today = new Date();

export class AssetSchedules extends Component {
    constructor() {
        super();
        this.state = {
            formVisible: false,
            formHeader: "Add Schedule",
            scheduleId: null,
            events: [],
            options: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                defaultView: 'dayGridMonth',
                defaultDate: today,
                header: {
                    left: 'addScheduleButton',
                    center: 'title',
                    right: 'prev,next dayGridMonth,timeGridWeek,timeGridDay'
                },
                customButtons: {
                    addScheduleButton: {
                        text: 'Add Schedule',
                        click: () => {
                            this.propertyScheduleForm.action(this.props.id, null, this.props.inventoryId);
                        },
                    }
                },
                eventClick: (e) => {
                        this.handleOnClick(e);
                    },
                editable: true
            }
        }
    }

    componentDidMount() {
        if(this.props.type === SCHEDULE_TYPE.inventory.value) {
            this.getInventorySchedules();
        } else {
            this.getSchedules();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.type === SCHEDULE_TYPE.inventory.value) {
            if(prevProps.filter && this.props.filter && prevProps.filter.status !== this.props.filter.status) {
                this.getInventorySchedules();
            }
    
            if(prevProps.filter && this.props.filter && prevProps.filter.assetStatus !== this.props.filter.assetStatus) {
                this.getInventorySchedules();
            }

            if(prevProps.filter && this.props.filter && prevProps.filter.assetId !== this.props.filter.assetId) {
                this.getInventorySchedules();
            }
        } else {
            if(prevProps.filter && this.props.filter && prevProps.filter.status !== this.props.filter.status) {
                this.getSchedules();
            }
    
            if(prevProps.filter && this.props.filter && prevProps.filter.assetStatus !== this.props.filter.assetStatus) {
                this.getSchedules();
            }
        }
    }

    getInventorySchedules = () => {
        getInventorySchedules(this.props.filter)
        .then(data => {
            if(data && Array.isArray(data)) {
                this.setState({
                    events: data ? this.getDateTime(data) : []
                })
            }
        })
    }

    getSchedules = () => {
        getScheduleList(this.props.filter).then(data => this.setState({ 
            events: data ? this.getDateTime(data) : []
        }));
    }

    getDateTime = (data) => {
        if(data && Array.isArray(data)) {
            for(let i = 0; i < data.length; i++) {
                data[i].start = data[i].startDate + "T" + data[i].startTime + ":00";
                data[i].end = data[i].endDate + "T" + data[i].endTime + ":00";
                
                delete data[i].startTime;
                delete data[i].endTime;
            }
        }
        return data;
    }

    handleOnClick = (e) => {
        e.jsEvent.preventDefault();
        
        getSchedule(e.event.id)
        .then(data => {
            if(!data.errorCode) {
                this.propertyScheduleForm.action(this.props.id, data, this.props.inventoryId);
            }
        })
    }

    render() {

        return (
            <div className="p-grid aa">
                <AssetScheduleForm ref={el => this.propertyScheduleForm = el}
                    reloadSchedule={() => this.getSchedules()}
                />
                <div className="p-col-12">
                    <FullCalendar events={this.state.events} options={this.state.options} />
                </div>
            </div>
        );
    }
}
