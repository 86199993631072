import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Spinner } from 'primereact/spinner';
import { MultiSelect } from 'primereact/multiselect';
import { CONDITION_BUY_TYPES, CONDITION_METHOD_TYPE, CONDITION_BUY_TYPE, PROD_TYPE } from '../../constants';

class DependentCondition extends Component {
    constructor(props){
        super(props);
        this.state = {
            condition: {
                buyType: props.data?props.data.buyType:CONDITION_BUY_TYPE.any,
                methodType: props.data?props.data.methodType:CONDITION_METHOD_TYPE.equal,
                minQuantity: props.data?props.data.minQuantity:'0',
                maxQuantity: props.data?props.data.maxQuantity:'0',
                buyProducts: props.data?props.data.buyProducts:[],
                discountProducts: props.data?props.data.discountProducts:[]
            },
            dish:[]
        }
    }

    componentDidMount(){
        this.loadDish();
    }

    componentDidUpdate(prevProps){
        if(prevProps.data!==this.props.data){
            this.setState({
                condition: this.props.data
            });
        }
    }

    loadDish = () => {
        // getListProducts({types: [PROD_TYPE.dish]}, true).then(res => {
        //     this.setState({ dish: res?res:[] })
        // })
    }

    onElementChange = (key, value) => {
        let tmpCondition = {...this.state.condition};
        switch(key){
            case 'buyType':
                tmpCondition.buyType=value;
                break;
            case 'methodType':
                tmpCondition.methodType=value;
                tmpCondition.minQuantity='0';
                tmpCondition.maxQuantity='0';
                break;
            case 'minQuantity':
                tmpCondition.minQuantity=value;
                break;
            case 'maxQuantity':
                tmpCondition.maxQuantity=value;
                break;
            case 'buyProducts':
                tmpCondition.buyProducts=value;
                break;
            case 'discountProducts':
                tmpCondition.discountProducts=value;
                break;
            default:
                break;
        }
            
        this.props.onChange(tmpCondition);
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        return (
            <div className="p-grid">
                {/* <div className="p-col-12">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12 p-md-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">{trans('promotion.mgt.buy')}:</span>
                                <Dropdown value={this.state.condition.buyType} options={translateListSimple(trans,CONDITION_BUY_TYPES)} onChange={(e) => this.onElementChange('buyType', e.value)}/>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">{trans('promotion.mgt.quantity')}:</span>
                                <Dropdown value={this.state.condition.methodType} options={translateListSimple(trans,CONDITION_METHOD_TYPES)} onChange={(e) => this.onElementChange('methodType', e.value)}/>
                            </div>
                        </div>
                        {(() => {
                            if(this.state.condition.methodType!==CONDITION_METHOD_TYPE.range){
                                return (
                                    <div className="p-col-12 p-md-2">
                                        <Spinner value={this.state.condition.maxQuantity} onChange={(e) => this.onElementChange('maxQuantity', e.target.value)} style={{marginTop: 8}}/>
                                    </div>
                                );                                            
                            }else{
                                return (
                                    <div className="p-col-12 p-md-4 p-padding-top-15">
                                        <div className="p-inputgroup">
                                            <label style={{display: "block", margin: "2px 15px 0px 0px"}}>{trans('promotion.mgt.from')}</label>
                                            <Spinner value={this.state.condition.minQuantity} onChange={(e) => this.onElementChange('minQuantity', e.target.value)}/>
                                            <label style={{display: "block", margin: "2px 15px 0px 15px"}}>{trans('promotion.mgt.to')}</label>
                                            <Spinner value={this.state.condition.maxQuantity} onChange={(e) => this.onElementChange('maxQuantity', e.target.value)}/>
                                        </div>
                                    </div>
                                );
                            }
                        })()}
                    </div>
                </div>
                <div className="p-col-12">
                    <Fieldset legend={trans('promotion.mgt.product_buying')}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <MultiSelect value={this.state.condition.buyProducts} options={this.state.dish} onChange={(e) => this.onElementChange('buyProducts', e.value)} filter={true}/>
                            </div>
                        </div>
                    </Fieldset>
                    <Fieldset legend={trans('promotion.mgt.product_get_discount')}>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <MultiSelect value={this.state.condition.discountProducts} options={this.state.dish} onChange={(e) => this.onElementChange('discountProducts', e.value)} filter={true}/>
                            </div>
                        </div>
                    </Fieldset>
                </div> */}
            </div>
        );
    }
}
export default withNamespaces('message')(DependentCondition);