import React, { useState, useEffect, useRef } from "react";
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { useRouteMatch } from "react-router-dom";
import { getPageBlockContents, removeBlockContent } from "./BlockContentServices";
import { SplitButton } from "primereact/splitbutton";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { BlockContentForm } from "./BlockContentForm";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";

export const BlockContents = () => {
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(true);

    const frmBlockContent = useRef(null)

    useEffect(()=>{
        if(isLoadTable)
            loadPageBlockContents()
    },[isLoadTable])

    const loadPageBlockContents = () => {
        getPageBlockContents(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblHeader = (
        <TableHeader title="Block Contents" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                <Button className="p-button-success" label="Create Content" icon="pi-md-plus" onClick={() => frmBlockContent.current.openForm(null)}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    const deleteBlockContent = (id) => {
        removeBlockContent(id).then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Block Contents</BreadcrumbsItem>

            <BlockContentForm ref={frmBlockContent} refreshTable={() => setLoadTable(true)}/>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Block Contents</h1>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column field="title" header="Title" />
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: () => deleteBlockContent(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmBlockContent.current.openForm(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}