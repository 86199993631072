import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Dropdown } from 'primereact/dropdown';
import { TABLE_PAGING_LENGTH } from '../../constants';
import { getPagePageContent, removePageContent } from './PageContentService';
import { PageContentForm } from './PageContentForm';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from '../../core/service/CommonService';
import classNames from 'classnames';
import { SplitButton } from 'primereact/splitbutton';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';

export default class PageContentListView extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            filter: {}
        }
    }

    componentDidMount() {
        this.loadPagePageContents();
    }

    loadPagePageContents = () => {
        showloading();
        getPagePageContent(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            stoploading();
            this.setState({
                dataList: res.content,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
                total: res.totalElements
            });
        });
    }

    onPage = (e) =>{
        this.setState({
            page: e.page
        },() => this.loadPagePageContents)
    }

    onSort = (e) =>{
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => this.loadPagePageContents())
    }
    

    actionTemplate = (rowData) => {
        return <Button label="Edit" onClick={(e)=> this.pageContentForm.popularFormData(rowData)}/>
    }

    applyFilter = () => {
        this.setState({
            page: 0
        },() => this.loadPagePageContents())
        }
    
    clearFilter = () => {
        this.setState({
            filter: {...this.state.filter,
                title: '',     
            }
        }, () => {this.applyFilter()});
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter'){
            this.applyFilter();
        }
    }

    onChagePageLength = e => {
        this.setState({
            rows: e.value,
            page: 0
        }, () => this.loadPagePageContents())
    }

    removePage = (id) => {
        showConfirmNotify({
            accept: () => this.handleDeletePage(id)
        })
    }

    handleDeletePage = (id) => {
        removePageContent(id).then(res => {
            if(!res.errorCode){
                this.loadPagePageContents();
                showSuccessNotify('Action Submitted');
            }else{
                showErrorNotify('Cannot perform action');
            }
        })
    }

    render() {
        const tblHeader = (
            <TableHeader title="Pages" 
                pageReport={getTablePageReport(this.state.page, this.state.rows, this.state.total)}
                actionTemplate={
                    <Button className="p-button-success" label="Create Page" icon="pi-md-plus" onClick={() => this.pageContentForm.popularFormData()}/>}
                changePageLength={(e) => this.onChangePageLength(e)}
                refresh={() => this.loadPagePageContents()}
            />
        )
        
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Public Pages</BreadcrumbsItem>

                <PageContentForm ref={el => this.pageContentForm = el}
                    refreshTable={(e) => this.loadPagePageContents()}
                />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1 className="p-w-bold p-m-0">Public Page Contents</h1>
                    </div>

                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-6 p-as-end">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Title:</span>
                                <InputText value={this.state.filter.title} onKeyPress={this.handleKeyPress} onChange={(e) => this.setState({filter: { ...this.state.filter, title: e.target.value }})} />
                            </div>
                        </div>
                        <div className="p-col-4">
                            <Button icon="pi pi-filter" iconPos="left" label="Search" style={{width: 'auto'}} onClick={() => this.applyFilter()} />
                            <Button className="p-button-secondary p-ml-2" icon="pi pi-trash" iconPos="left" label="Clear" style={{width: 'auto'}} onClick={() => this.clearFilter()} />
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={this.state.dataList}
                        first={this.state.page * this.state.rows}
                        rows={this.state.rows} totalRecords={this.state.total}
                        onPage={this.onPage} header={tblHeader} onSort={this.onSort}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        responsive={true}
                    >
                        <Column field="title" header="Title" sortable={true}/>
                        <Column field="alias" header="Url Alias" sortable={true}/>
                        {/* <Column field="type" header="Type" sortable={true}/> */}
                        <Column field="status" header="Status" bodyClassName="p-c" body={rowData => <span className={classNames('status', {'active': rowData.status, 'inactive': !rowData.status})}>{rowData.status?'Active':'Inactive'}</span>} />
                        <Column bodyClassName="tc-actions p-r" style={{width: '13em'}} 
                            body={(rowData) => {
                                const actionItems = [
                                    { label: 'Remove', icon: 'pi pi-times', command: () => this.removePage(rowData.id)}
                                ]
                                return(
                                    <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => this.pageContentForm.popularFormData(rowData)}></SplitButton>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        );
    }
}
