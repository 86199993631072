import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import { ProgramServices } from './ProgramServices';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { showNotification } from '../../core/service/NotificationService';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { showloading, stoploading } from '../../core/service/LoadingService';
import AsyncSelect from 'react-select/async';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { SelectButton } from 'primereact/selectbutton';
import { Fieldset } from 'primereact/fieldset';

export class CloneProgramForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            programSelection: {},
            campaigns: [],
            errors: {}
        }
        this.programServices = new ProgramServices();
        this.campaignServices = new CampaignServices();
    }

    popularState = (data) => {
        this.setState({
            data: {
                id: data.id,
                name: data.name,
                code: data.code,
                type: data.type,
                campaignId: data.campaignId,
                startDate: data.startDate?moment(data.startDate).format(moment.HTML5_FMT.DATE):'',
                endDate: data.endDate?moment(data.endDate).format(moment.HTML5_FMT.DATE):'',
                startTime: data.startTime?moment(data.startTime, moment.HTML5_FMT.TIME).format(moment.HTML5_FMT.TIME):'',
                endTime: data.endTime?moment(data.endTime, moment.HTML5_FMT.TIME).format(moment.HTML5_FMT.TIME):'',
                regStartDate: data.regStartDate?moment(data.regStartDate).format('YYYY-MM-DD HH:mm'):'',
                regEndDate: data.endDate&&data.endTime?moment(data.endDate+' '+data.endTime).format('YYYY-MM-DD HH:mm'):'',
                programOption: ''
            },
            startDateValue: data.startDate?moment(data.startDate).toDate():'',
            minDate: data.startDate?moment(data.startDate).toDate():'',
            endDateValue: data.endDate?moment(data.endDate).toDate():'',
            maxDate: data.endDate?moment(data.endDate).toDate():'',
            startTimeValue: data.startTime?moment(data.startTime, moment.HTML5_FMT.TIME).toDate():'',
            minTime: data.startTime?moment(data.startDate, moment.HTML5_FMT.TIME).toDate():'',
            endTimeValue: data.endTime?moment(data.endTime, moment.HTML5_FMT.TIME).toDate():'',
            maxTime: data.endTime?moment(data.endTime, moment.HTML5_FMT.TIME).toDate():'',
            regStartDateValue: data.regStartDate?moment(data.regStartDate).toDate():'',
            regMinDate: data.regStartDate?moment(data.regStartDate).toDate():'',
            regEndDateValue: data.endDate&&data.endTime?moment(data.endDate+' '+data.endTime).toDate():'',
            regMaxDate: data.endDate&&data.endTime?moment(data.endDate+' '+data.endTime).toDate():'',
            programSelection: null,
            visible: data ? true : false,
            formHeader: data ? (data.type==='course' ? 'New Course ' : 'New Program') : '',
            errors: {}
        })
        this.loadAllCampaigns();
    }

    handleSave = () => {
        this.setState({ errors: {} });
        showloading();
        this.programServices.cloneProgram(this.state.data)
        .then(res => {
            stoploading();
            if(!res.errorCode){
                this.setState({
                    parentId: res.type==='course' ? res.parent.id : '',
                    goToDetail: res.id
                })
                if(this.props.refreshTable)
                    this.props.refreshTable()
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        })
    }

    loadAllCampaigns = () => {
        this.campaignServices.getListCampaign().then(res => {this.setState({campaigns: res})});
    }

    loadProgramOptions = (searchTerm, callback) => {
        this.programServices.getListAllPrograms(searchTerm)
        .then(res => { 
            callback (res)
        })
    }

    onDateChange = (e) => {
        this.setState({
            data: { ...this.state.data,
                date: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',
            },
            dateValue: e.value
        });
    }

    onStartDateChange = (e) => {
        let maxDate = this.state.maxDate ? moment(this.state.maxDate) : '';
        let isValidValue = e.value&&moment(e.value).isValid();
        let dateValue = moment(e.value);
        let flag = (maxDate && isValidValue && dateValue && dateValue.isAfter(maxDate));

        this.setState({
            data: {...this.state.data, 
                startDate: dateValue.format(moment.HTML5_FMT.DATE),
                endDate: !flag ? this.state.data.endDate : ''
            },
            startDateValue: e.value,
            minDate: moment(e.value).isValid() ? e.value : '',
            endDateValue: !flag ? this.state.endDateValue : '',
            maxDate: !flag ? this.state.maxDate : ''
        });
    }

    onEndDateChange = (e) => {
        let minDate = this.state.minDate ? moment(this.state.minDate) : '';
        let isValidValue = e.value&&moment(e.value).isValid();
        let dateValue = moment(e.value);
        let flag = (minDate && isValidValue && dateValue && dateValue.isBefore(minDate));

        this.setState({
            data: {...this.state.data, 
                startDate: !flag ? this.state.data.startDate : '',
                endDate: dateValue.format(moment.HTML5_FMT.DATE)
            },
            startDateValue: !flag ? this.state.startDateValue : '',
            minDate: !flag ? this.state.minDate :'',
            endDateValue: e.value,
            maxDate: moment(e.value).isValid() ? e.value : ''
        });
    }

    onStartTimeChange = (e) => {
        let maxTime = this.state.maxTime ? moment(this.state.maxTime) : '';
        let isValidValue = e.value&&moment(e.value).isValid();
        let timeValue = moment(e.value);
        let flag = (maxTime && isValidValue && timeValue && timeValue.isAfter(maxTime));
        
        this.setState({
            data: {...this.state.data, 
                startTime: timeValue.format(moment.HTML5_FMT.TIME),
                endTime: !flag ? this.state.data.endTime : '',
            },
            startTimeValue: e.value,
            minTime: moment(e.value).isValid() ? e.value : '',
            endTimeValue: !flag ? this.state.endTimeValue : '',
            maxTime: !flag ? this.state.maxTime : ''
        });
    }

    onEndTimeChange = (e) => {
        let minTime = this.state.minTime ? moment(this.state.minTime) : '';
        let isValidValue = e.value&&moment(e.value).isValid();
        let timeValue = moment(e.value);
        let flag = (minTime && isValidValue && timeValue && timeValue.isBefore(minTime));

        this.setState({
            data: {...this.state.data, 
                startTime: !flag ? this.state.data.startTime : '',
                endTime: timeValue.format(moment.HTML5_FMT.TIME),
            },
            startTimeValue: !flag ? this.state.startTimeValue : '',
            minTime: !flag ? this.state.minTime :'',
            endTimeValue: e.value,
            maxTime: moment(e.value).isValid() ? e.value : ''
        });
    }

    onRegStartDateChange = (e) => {
        let maxDate = this.state.regMaxDate ? moment(this.state.regMaxDate) : '';
        let isValidValue = e.value&&moment(e.value).isValid();
        let dateValue = moment(e.value);
        let flag = (maxDate && isValidValue && dateValue && dateValue.isAfter(maxDate));

        this.setState({
            data: {...this.state.data, 
                regStartDate: dateValue.format('YYYY-MM-DD HH:mm'),
                regEndDate: !flag ? this.state.data.regEndDate : ''
            },
            regStartDateValue: e.value,
            regMinDate: moment(e.value).isValid() ? e.value : '',
            regEndDateValue: !flag ? this.state.regEndDateValue : '',
            regMaxDate: !flag ? this.state.regMaxDate : ''
        });
    }

    onRegEndDateChange = (e) => {
        let minDate = this.state.regMinDate ? moment(this.state.regMinDate) : '';
        let isValidValue = e.value&&moment(e.value).isValid();
        let dateValue = moment(e.value);
        let flag = (minDate && isValidValue && dateValue && dateValue.isBefore(minDate));
        
        this.setState({
            data: {...this.state.data, 
                regStartDate: !flag ? this.state.data.regStartDate : '',
                regEndDate: dateValue.format('YYYY-MM-DD HH:mm')
            },
            regStartDateValue: !flag ? this.state.regStartDateValue : '',
            regMinDate: !flag ? this.state.regMinDate :'',
            regEndDateValue: e.value,
            regMaxDate: moment(e.value).isValid() ? e.value : ''
        });
    }

    render(){
        if (this.state.goToDetail) {
            if(this.state.data.type === 'program'){
                return <Redirect to={`/programs/${this.state.goToDetail}`}/>

            }else if(this.state.data.type === 'course'){
                return <Redirect to={`/programs/${this.state.parentId}/courses/${this.state.goToDetail}`}/>
            }
        }
        const optionButtons = [
            {label: 'To same program', value: ''},
            {label: 'Select a program', value: 'select'}
        ];
        return(
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={(e) => this.setState({visible: false})}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid form-group">
                    {this.state.data.type === 'course' ?
                        <React.Fragment>
                            <div className="p-col-12">
                                <SelectButton value={this.state.data.programOption} options={optionButtons} onChange={(e) => this.setState({data: {...this.state.data, programOption: e.value, parentId: ''}, programSelection: null})} />
                            </div>
                            {this.state.data.programOption === 'select' &&
                                <div className="p-col-12">
                                    <label className="p-label">Parent Program</label>
                                    <AsyncSelect 
                                        loadOptions={this.loadProgramOptions} onChange={(e) => this.setState({data: {...this.state.data, parentId: e ? e.value : ''}, programSelection: e})}
                                        placeholder='Type your search' isClearable
                                        value={this.state.programSelection}
                                    /> 
                                </div>
                            }
                            <div className="p-col-12">
                                <label className="p-label">* Campaign</label>
                                <Dropdown value={this.state.data.campaignId} options={this.state.campaigns} onChange={(e) => this.setState({data: {...this.state.data, campaignId: e.value}})} style={{width: "100%"}} />
                                <div className="p-form-error">{this.state.errors.campaign}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Code</label>
                                <InputText value={this.state.data.code} onChange={(e) => this.setState({data: {...this.state.data, code: e.target.value}})} />
                                <div className="p-form-error">{this.state.errors.code}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={this.state.data.name} onChange={(e) => this.setState({data: {...this.state.data, name: e.target.value}})} />
                                <div className="p-form-error">{this.state.errors.name}</div>
                            </div>
                            <div className="p-col-12">
                                <Fieldset legend="Course Schedule">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Start Date</label>
                                            <MaskedCalendar value={this.state.startDateValue} maxDate={this.state.maxDate} showIcon={true} onChange={e => this.onStartDateChange(e)} />
                                            <div className="p-form-error">{this.state.errors.startDate}</div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* End Date</label>
                                            <MaskedCalendar value={this.state.endDateValue} minDate={this.state.minDate} showIcon={true} onChange={e => this.onEndDateChange(e)} />
                                            <div className="p-form-error">{this.state.errors.endDate}</div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Start Time</label>
                                            <MaskedCalendar value={this.state.startTimeValue} onChange={e => this.onStartTimeChange(e)} showIcon={true} showTime={true} hourFormat="12" timeOnly={true} />
                                            <div className="p-form-error">{this.state.errors.startTime}</div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* End Time</label>
                                            <MaskedCalendar value={this.state.endTimeValue} onChange={e => this.onEndTimeChange(e)} showIcon={true} showTime={true} hourFormat="12" timeOnly={true} />
                                            <div className="p-form-error">{this.state.errors.endTime}</div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12">
                                <Fieldset legend="Registration Schedule">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <label className="p-label">* Reg. Start Date</label>
                                            <MaskedCalendar value={this.state.regStartDateValue} maxDate={this.state.regMaxDate} showIcon={true} showTime={true} hourFormat='12' hideOnDateTimeSelect={true} onChange={e => this.onRegStartDateChange(e)} />
                                            <div className="p-form-error">{this.state.errors.regStartDate}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">* Reg. End Date</label>
                                            <MaskedCalendar value={this.state.regEndDateValue} minDate={this.state.regMinDate} showIcon={true} showTime={true} hourFormat='12' hideOnDateTimeSelect={true} onChange={e => this.onRegEndDateChange(e)} />
                                            <div className="p-form-error">{this.state.errors.regEndDate}</div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        </React.Fragment>
                        : <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={this.state.data.name} onChange={(e) => this.setState({data: {...this.state.data, name: e.target.value}})} />
                                <div className="p-form-error">{this.state.errors.name}</div>
                            </div>
                            <div className="p-col-12">
                                <Checkbox inputId="copyCourse" checked={this.state.data.copyCourses} onChange={(e) => this.setState({data: {...this.state.data, copyCourses: e.checked}})} />
                                <label htmlFor="copyCourse">Also clone courses?</label>
                            </div>
                            {this.state.data.copyCourses &&
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <label className="p-label">Campaign</label>
                                        <Dropdown value={this.state.data.campaignId} options={this.state.campaigns} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, campaignId: e.value}})} style={{width: "100%"}} />
                                        <div className="p-form-error">{this.state.errors.campaign}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">New Start Date</label>
                                        <MaskedCalendar value={this.state.dateValue} showIcon={true} onChange={e => this.onDateChange(e)} dateFormat="mm-dd-yy" />
                                        <div className="p-form-error">{this.state.errors.date}</div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    
                </div>
                <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSave()}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e) => this.setState({visible: false})}/>
                        </div>
                    </div>
            </Sidebar>
        );
    }
}