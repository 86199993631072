import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { PROD_TYPE } from "../../constants";
import lazyLoad from '../../core/components/lazyLoad';

import {ProductListView} from './ProductListView';
// const ProductDetailView = lazyLoad(() => import('./ProductDetailView'));

export const ProductManagement = () => {
    const match = useRouteMatch()

    return (
        <Switch>
            {/* <Route path={`${match.url}/:id`} component={ProductDetailView} /> */}
            <Route path={`${match.url}`} component={ProductListView} />
            <Redirect from='*' to='/404' />
        </Switch>
    )
}