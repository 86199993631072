import axios from 'axios';
import { API_BASE_URL } from '../../constants';
import qs from 'qs';
import querystring from 'querystring'

export class ParticipantServices {
    getPageCourseParticipants(course, statuses, page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sortName = sortField && sortField === 'name'?'firstName':sortField;
        const sort = sortName ? `${sortName},${order}` : '';
        var qs = require('qs');        
        return axios.get(`${API_BASE_URL}/scp/api/participants/get_page_by_program_course`, {
            params: {course, statuses, page, size, sort}, paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
        }).then(res => res.data).catch(error => console.log(error));
    }

    getPageParticipants(filter, page, size, sortField, sortOrder){
        const order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        const sort = sortField ? `${sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/participants/page`,
            {params: {...filter, page, size, sort}, paramsSerializer: params => {return qs.stringify(params)}}, 
        ).then(res => res.data).catch(error => console.log(error));
    }

    getPageParticipantMembers(filter, page, size, sortField, sortOrder) {
        const order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        const sort = sortField ? `${sortField},${order}` : "";
        return axios.get(`${API_BASE_URL}/scp/api/teams/members/page`,
            {params: {...filter, page, size, sort}, paramsSerializer: params => {return qs.stringify(params)}}, 
        ).then(res => res.data).catch(error => console.log(error));
    }

    getListParticipants(isSimple) {
        return axios.get(`${API_BASE_URL}/scp/api/participants/list`, {
            params: {isSimple}
        }).then(res => res.data).catch(error => console.log(error));
    }    

    getParticipant(id){
        return axios.get(`${API_BASE_URL}/scp/api/participants/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getParticipantFieldValue(id){
        return axios.get(`${API_BASE_URL}/scp/api/participants/${id}/fields`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getParticipants(filter){
        return axios.get(`${API_BASE_URL}/scp/api/participants/list`,{
            params: {...filter}, paramsSerializer: params => {return qs.stringify(params)}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getListParticipantByProperty(propertyId, date){
        return axios.get(`${API_BASE_URL}/scp/api/participants/get_by_property`,{
            params: {
                propertyId: propertyId,
                date: date
            }
        }).then(res => res.data).catch(error => console.log(error));
    }

    moveParticipantToClass(partId, data){
        return axios.post(`${API_BASE_URL}/scp/api/participants/${partId}/move`, data).then(res => res.data).catch(error => console.log(error));
    }

    enrollParticipants(data){
        const querystring = require('querystring');
        return axios.post(`${API_BASE_URL}/scp/api/participants/enroll`,
            querystring.stringify({ 
                ...data
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    updatePriorityParticipant(partId, priority){
        let querystring = require('querystring');
        return axios.post(`${API_BASE_URL}/scp/api/participants/${partId}/update_priority`,
            querystring.stringify({ 
                priority: priority
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    cancelParticipant(partId, data){
        let querystring = require('querystring');
        return axios.post(`${API_BASE_URL}/scp/api/participants/${partId}/cancel`,
            querystring.stringify({ 
                ...data
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    getDetailOrderParticipant(partId){
        return axios.get(`${API_BASE_URL}/scp/api/participants/${partId}/order_details`).then(res => res.data).catch(error => console.log(error));
    }

    unregister(ids){
        return axios.post(`${API_BASE_URL}/scp/api/participants/unregister`, querystring.stringify({ ids: ids})).then(res => res.data).catch(error => console.log(error));
    }

    checkinParticipant(participantIds, isCheckin){
        let querystring = require('querystring');
        return axios.post(`${API_BASE_URL}/scp/api/participants/checkin`, querystring.stringify({participantIds, checkin: isCheckin })
        ).then(res => res.data).catch(error => console.log(error));
    }
}

export function getPageParticipants(filter, page, size, sortField, sortOrder){
    const order = sortOrder===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}` : "";
    return axios.post(`${API_BASE_URL}/scp/api/participants/page`, filter,  {
        params: {page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function transferParticipant(partId, availabilityId){
    return axios.post(`${API_BASE_URL}/scp/api/participants/${partId}/transfer`, null, {params: availabilityId}
    ).then(res => res.data).catch(error => console.log(error));
}

export function getParticipantTransfer(partId){
    return axios.get(`${API_BASE_URL}/scp/api/participants/transfer`, {params: {id: partId}}
    ).then(res => res.data).catch(error => console.log(error));
}

export function participantCheckin(partId){
    return axios.post(`${API_BASE_URL}/scp/api/participants/checkin/validate-app-log`, null,{params: {id: partId}}
    ).then(res => res.data).catch(error => console.log(error));
}

