import React, {Component} from 'react';
import { withNamespaces } from 'react-i18next';
import { Button} from 'primereact/button';
import { DataTable} from "primereact/datatable";
import { Column} from "primereact/column";
import { PromotionForm } from './PromotionForm';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';
import { getPagePromotions, removePromotion, savePromotionPriority } from './PromotionService';
import { SplitButton } from 'primereact/splitbutton';
import { APP_FEP, CHANNEL_TYPE, CHANNEL_TYPES, PROMOTION_TYPES } from '../../constants';
import { hasRole } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

class PromotionMgtView extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            formDialogHeader: 'Add Promotion',
            formDialogVisible: false,
            groupFormData: null,
            filter: {
                app: APP_FEP
            }
        }
        this.onRowReorder = this.onRowReorder.bind(this);
    }

    componentDidMount(){
        this.loadPagePromotions();
    }

    loadPagePromotions = () => {
        showloading();
        getPagePromotions(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            }).finally(()=>stoploading())
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        },() => this.loadPagePromotions());
        
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => this.loadPagePromotions())
        
    }

    actionTemplate = (rowData, column) => {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        let editCamp = {label: 'Edit Campaign', icon: 'pi-md-pencil', command: (e) => this.frmPromotion.popularFormData(rowData)};
        let deleteCamp = {label: 'Delete Campaign', icon: 'pi-md-close', command: (e) => this.deleteNotify.delete(rowData.id)};
        let modelItems = []
        if(hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W])){
            modelItems.push(editCamp);
        }
        if(hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_D])){
            modelItems.push(deleteCamp);
        }
        if(modelItems.length > 0){
            return (
                <SplitButton className="p-button-primary p-l" label={'View Promotion'} icon="pi pi-list" model={modelItems} onClick={() => this.props.history.push(`/promotions/${rowData.id}`)}></SplitButton>
            );
        }else{
            return (
                <Button className="p-button-primary p-c" label={'View Promotion'} icon="pi pi-list" onClick={() => this.props.history.push(`/promotions/${rowData.id}`)}/>
            );
        }
        
    }

    applyFilter = () => {
        this.loadPagePromotions();
    }

    onChagePageLength = (e) => {
        this.setState({
            rows: e.value,
            page: 0
        }, () => {
            this.applyFilter();
        })
    }

    removePromotion = (id) => {
        removePromotion(id).then(res => {
            if(!res.errorCode){
                this.loadPagePromotions();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    onRowReorder = (e) => {
        this.setState({ dataList: e.value });
        let arrayId = [];
        e.value.map((item) => {
            arrayId.push(item.id);
        });
        savePromotionPriority(arrayId)
    }

    render(){
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        let tblHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button icon="pi pi-plus" className="p-button-constrast" label={'Create Campaign'} style={{ 'float': 'left' }} onClick={() => this.frmPromotion.popularFormData()}/>:
                <div style={{ 'float': 'right' }}></div>
                {/* <div style={{ 'float': 'right' }}>
                    Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChagePageLength(e)} style={{ minWidth: "0" }} />
                </div> */}
                <Button icon="pi pi-refresh" className="p-button-constrast p-float-right" disabled={this.state.loadingRefresh} tooltip={'Refresh'} onClick={() => this.loadPagePromotions()} />
            </div>;

        return (
            <div className="p-grid">
                <PromotionForm ref={el => this.frmPromotion=el}
                    trans={trans} onSave={(res, isAdd) => isAdd?this.props.history.push(`/promotions/${res.id}`):this.loadPagePromotions()}
                    listLength = {this.state.dataList.length}
                />

                <DeleteNotification ref={el => this.deleteNotify=el}
                    accessDelete={(e) => this.removePromotion(e)}
                />
                
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>{'Campaign List'}</h1>
                        <DataTable lazy={true} paginator={true} value={this.state.dataList} header={tblHeader}
                            first={this.state.page * this.state.rows}
                            rows={this.state.rows} totalRecords={this.state.total}
                            onPage={this.onPage} onSort={this.onSort}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                            responsive={true}
                            reorderableColumns
                            onRowReorder={this.onRowReorder}
                        >
                            <Column rowReorder style={{ width: "4em" }} />
                            <Column field="id" header={'ID'} sortable={true}/>
                            <Column field="name" header={'Name'} sortable={true}/>
                            <Column field="allowCoupon" header={'Allow Coupon'} body={(rowData) => rowData.allowCoupon?'Yes':'No'} sortable={true}/>
                            <Column field="type" header={'Type'} body={(rowData) => PROMOTION_TYPES.find(t => t.value===rowData.type)?PROMOTION_TYPES.find(t => t.value===rowData.type).label:''}/>
                            {/* <Column field="channel" header={'Channel'} sortable={true}
                                body={rowData => {
                                    return rowData.channelList && rowData.channelList.length>0 && rowData.channelList.map(x => (CHANNEL_TYPES.find(t => t.value===x)!==null?CHANNEL_TYPES.find(t => t.value===x).label:'')).join(", ");
                                }}
                            /> */}
                            <Column field="valid" header={'Status'} body={(rowData) => rowData.valid?'Valid':'Invalid'}/>
                            <Column field="priority" header={'Priority'} sortable={true}/>
                            <Column field="" header="" bodyClassName="p-c" body={this.actionTemplate} style={{width:'20em', overflow: 'visible'}}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}
export default withNamespaces('message')(PromotionMgtView);
