import React, {Component} from "react";
import moment from 'moment';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";

export class TimeRangeForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            time: {
                start: '',
                end: ''
            },
            startValue: '',
            endValue: '',
            minTime: '',
            maxTime: '',
            visible: false,
            errors: {}
        }
    }

    openForm = (startTime, endTime) => {
        this.setState({
            time: {
                start: startTime ? startTime : '',
                end: endTime ? endTime : '',
            },
            startValue: startTime ? moment(startTime, moment.HTML5_FMT.TIME).toDate() : '',
            endValue: endTime ? moment(endTime, moment.HTML5_FMT.TIME).toDate() : '',
            minTime: startTime ? moment(startTime, moment.HTML5_FMT.TIME).toDate() : '',
            maxtime: endTime ? moment(endTime, moment.HTML5_FMT.TIME).toDate() : '',
            visible: true,
            errors: {}
        })
    }

    handelSubmit = () => {
        let timeData = {...this.state.time}
        let errors = {}
        if(!timeData.start){
            errors.start = 'Required';
        }else if(!moment(timeData.start, moment.HTML5_FMT.TIME).isValid()){
            errors.start = 'Invalid time';
        }
        if(!timeData.end){
            errors.end = 'Required';
        }else if(!moment(timeData.end, moment.HTML5_FMT.TIME).isValid()){
            errors.end = 'Invalid time';
        }
        if(Object.keys(errors).length>0){
            this.setState({
                errors: {...errors}
            })
            return
        }
        let flag = this.props.submit(this.state.time);
        if(flag === false) return;
        this.onHide();
    }

    onStartTimeChange = (e) => {
        // let flagEmpty = !moment(e.value, moment.HTML5_FMT.TIME).isValid() && !e.value.replace(/[_:\s]/g, '') ? true : false;
        // let value = e.value;
        // let end = this.state.time.end;
        // let endValue = this.state.endValue;
        // if(this.state.endValue){
        //     let timeEnd = moment(this.state.endValue, 'HH:mm');
        //     if(moment(value, moment.HTML5_FMT.TIME).isAfter(timeEnd)){ 
        //         timeEnd = moment(value);
        //         timeEnd.set({m: (timeEnd.minute() + 1)});
        //         end = moment(timeEnd).format(moment.HTML5_FMT.TIME);
        //         endValue = moment(timeEnd, moment.HTML5_FMT.TIME).toDate();
        //     }
        // }
        // this.setState({
        //     time: { 
        //         start: !flagEmpty? moment(value).format(moment.HTML5_FMT.TIME) : '',
        //         end: end,
        //     },
        //     startValue: value,
        //     endValue: endValue,
        // });
        let maxTime = this.state.maxTime ? moment(this.state.maxTime) : '';
        let minTimeToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (minTimeToCompare && maxTime && minTimeToCompare.isAfter(maxTime));

        this.setState({
            time: {
                start: minTimeToCompare?minTimeToCompare.format('HH:mm'):'',
                end: !flag ? this.state.time.end : ''
            },
            startValue: e.value,
            minTime: moment(e.value).isValid() ? e.value : '',
            endValue: !flag ? this.state.endValue : '',
            maxTime: !flag ? this.state.maxTime : ''
        });
    }

    onEndTimeChange = (e) => {
        // let flagEmpty = ! moment(e.value, moment.HTML5_FMT.TIME).isValid() && !e.value.replace(/[_:\s]/g, '') ? true : false;
        // let value = e.value;
        // let start = this.state.time.start;
        // let startValue = this.state.startValue;
        // if(this.state.startValue){
        //     let timeStart = moment(this.state.startValue, 'HH:mm');
        //     if(moment(value, moment.HTML5_FMT.TIME).isBefore(timeStart)){ 
        //         timeStart = moment(value);
        //         timeStart.set({m: (timeStart.minute() - 1)});
        //         start = moment(timeStart).format(moment.HTML5_FMT.TIME);
        //         startValue = moment(timeStart, moment.HTML5_FMT.TIME).toDate();
        //     }
        // }
        // this.setState({
        //     time: { 
        //         start: start,
        //         end: !flagEmpty ? moment(value).format(moment.HTML5_FMT.TIME) : '',
        //     },
        //     startValue: startValue,
        //     endValue: value,
        // });
        let minTime = this.state.minTime ? moment(this.state.minTime) : '';
        let maxTimeToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (maxTimeToCompare && minTime && maxTimeToCompare.isBefore(minTime));

        this.setState({
            time: { 
                start: !flag ? this.state.time.start : '',
                end: maxTimeToCompare?maxTimeToCompare.format('HH:mm'):''
            },
            startValue: !flag ? this.state.startValue : '',
            minTime: !flag ? this.state.minTime :'',
            endValue: e.value,
            maxTime: moment(e.value).isValid() ? e.value : ''
        });
    }

    onError = (errors) => {
        this.setState({ errors: errors });
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){
        const footer = (
            <div>
                <Button label="Submit" icon="pi pi-check" onClick={() => this.handelSubmit()} />
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
            </div>
        );

        return (
            <div>
                <Dialog contentStyle={{minHeight: "170px"}} header="Range Time" footer={footer} visible={this.state.visible} width="1050px" responsive={true} modal={true} onHide={this.onHide}>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <label>Start Time</label>
                            <MaskedCalendar value={this.state.startValue} showIcon={true} showTime={true} onChange={this.onStartTimeChange} hourFormat="12" timeOnly={true} />
                            <div className="p-form-error">{this.state.errors.start}</div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label>End Time</label>
                            <MaskedCalendar value={this.state.endValue} showIcon={true} showTime={true} onChange={this.onEndTimeChange} hourFormat="12" timeOnly={true}/>
                            <div className="p-form-error">{this.state.errors.end}</div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}