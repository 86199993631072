import React, {Component} from 'react';
import moment from 'moment';
import { ProgramServices } from './ProgramServices';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from "primereact/calendar";
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { showNotification } from '../../core/service/NotificationService';

export class AttributeForm extends Component{
    constructor(props){
        super(props);
        let dataAtt = {};
        let dataVerifies = {};
        if(props.data.attributes){
            props.data.attributes.map((item, i) => {
                dataAtt[item.name] = item.value;
                if(item.verify){
                    dataVerifies[item.name] = item.valueVerifies;
                }
            });
        }
        this.state = {
            data: props.data ? props.data : {},
            attributes: dataAtt,
            temAttribute: dataAtt,
            verifies: dataVerifies,
            termVerifies: dataVerifies,
            errors: {},
        };
        this.programServices = new ProgramServices();
    }

    // componentDidUpdate(prevProps, prevState){
    //     if(Object.keys(prevState.attributes).length!==0){
    //         if(JSON.stringify(prevState.attributes) !== JSON.stringify(this.state.attributes) || JSON.stringify(prevState.verifies) !== JSON.stringify(this.state.verifies)){
    //             if(JSON.stringify(this.state.attributes) !== JSON.stringify(this.state.temAttribute) || JSON.stringify(this.state.verifies) !== JSON.stringify(this.state.termVerifies)){
    //                 this.props.updating(true);
    //             }else{
    //                 this.props.updating(false);
    //             }
    //         }
    //     }
    // }

    handleSaveAttributeSettings = () => {
        this.programServices.saveAttributeSettings(this.state.data.id, {"attributes":this.state.attributes, "verifies":this.state.verifies})
        .then(res => {
            if(!res.errorCode){
                this.props.refreshData(res);
                showNotification('success', 'Success Message', 'Action Submitted');
                this.setState({
                    errors: {}
                });
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400) this.setState({errors: res.objError});
            }
        })
    }

    onChange = (key, value) => {
        this.setState({attributes: {...this.state.attributes, [key]: value}});
    }

    onChangeCheckbox = (key, e) => {
        if(this.state.attributes[key] === null) {
            this.state.attributes[key] = [];
        }
        let lstValues = this.state.attributes[key];
        if(e.checked){
            lstValues.push(e.value)
        }else{
            lstValues.splice(lstValues.indexOf(e.value), 1);
        }
        this.setState({attributes: {...this.state.attributes, [key]: lstValues}});
    }

    render() {
        return (
            <div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <div className="p-panel">
                            <div class="p-panel-titlebar"><span class="p-panel-title">Field</span></div>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-panel">
                            <div class="p-panel-titlebar"><span class="p-panel-title">Field Require</span></div>
                        </div>
                    </div>
                </div>
                    {this.props.data.attributes ? 
                        this.props.data.attributes.map((item, i) => {  
                            switch(item.format){
                                case "Text":
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-6">
                                                <label className="p-label">{item.label}</label>
                                                <InputText value={this.state.attributes[item.name]} onChange={(e) => this.onChange(item.name, e.target.value)} />
                                                <span className="p-form-error">{this.state.errors[item.name]}</span>
                                            </div>
                                        </div>
                                    )
                                case "Email":
                                // case "File Upload":
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-6">
                                                <label className="p-label">{item.label}</label>
                                                <InputText value={this.state.attributes[item.name]} onChange={(e) => this.onChange(item.name, e.target.value)} />
                                                <span className="p-form-error">{this.state.errors[item.name]}</span>
                                            </div>
                                        </div>
                                    )
                                case "Date":
                                    let valueDate = this.state.attributes[item.name] ? moment(this.state.attributes[item.name], "YYYY-MM-DD").toDate() : "";
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-6">
                                                <label className="p-label">{item.label}</label>
                                                <Calendar dateFormat={item.config.dateFormat} hourFormat="24" value={valueDate} onChange={(e) => this.onChange(item.name, e.value)} showIcon={true}/>
                                                <span className="p-form-error">{this.state.errors[item.name]}</span>
                                            </div>
                                        </div>
                                    )
                                case "Number":
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-6">
                                                <label className="p-label">{item.label}</label>
                                                <InputText keyfilter="int" value={this.state.attributes[item.name]} onChange={(e) => this.onChange(item.name, e.target.value)} />
                                                <span className="p-form-error">{this.state.errors[item.name]}</span>
                                            </div>
                                        </div>
                                    );
                                // case "Phone":
                                //     return (
                                //         <div className="p-col-12">
                                //             <label className="p-label">{item.label}</label>
                                //             <IntlTelInput defaultCountry={item.config.defaultCountry.toLowerCase()} value={this.state.phoneNumber!==null?this.state.phoneNumber:''} onPhoneNumberChange={this.onChangePhoneNumber} onSelectFlag={this.onChangePhoneFlag} css={['intl-tel-input', 'p-inputtext  p-component']} style={{width: "100%"}}/>
                                //         </div>
                                //     );
                                case "Textarea":
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-6">
                                                <label className="p-label">{item.label}</label>
                                                <InputTextarea value={this.state.attributes[item.name]} onChange={(e) => this.onChange(item.name, e.target.value)}/>
                                                <span className="p-form-error">{this.state.errors[item.name]}</span>
                                            </div>
                                        </div>
                                    )
                                case "Select":
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">{item.label}</label>
                                                <div className="p-grid">
                                                    <div className="p-col-6">
                                                        {item.config.displayType === "dropdown" ?
                                                            item.config.storeType === "string" ?
                                                                <Dropdown value={this.state.attributes[item.name]} options={item.config.listValues} onChange={(e) => this.onChange(item.name, e.value)} placeholder="Select Value" style={{width: "100%"}} />
                                                            : <MultiSelect value={this.state.attributes[item.name]} options={item.config.listValues} onChange={(e) => this.onChange(item.name, e.value)} placeholder="Select Value" style={{width: "100%"}} />
                                                        : item.config.listValues.map((valueItem, i) => {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    {item.config.displayType === "checkbox" ?
                                                                        <Checkbox value={valueItem.value} onChange={(e) => this.onChangeCheckbox(item.name, e)} checked={ this.state.attributes[item.name] != null ? this.state.attributes[item.name].some(o => o===valueItem.value) : false} />
                                                                        : <RadioButton name={item.name} value={valueItem.value} onChange={(e) => this.onChange(item.name, e.value)} checked={this.state.attributes[item.name] === valueItem.value} />
                                                                    }
                                                                    <label style={{marginRight: 15}}>{valueItem.label}</label>
                                                                </React.Fragment>
                                                            )})
                                                        }
                                                        <div><span className="p-form-error">{this.state.errors[item.name]}</span></div>
                                                    </div>
                                                    {item.verify ? 
                                                        <div className="p-col-6">
                                                            <MultiSelect value={this.state.verifies[item.name]} options={item.config.listValues} onChange={(e) => this.setState({verifies: {...this.state.verifies, [item.name]: e.value}})} placeholder="Select Value" style={{width: "100%"}} />
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                case "Yes/No":
                                    return (
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-6">
                                                <label className="p-label">{item.label} <i title={item.config.helperMsg} style={{color:"#5493b1"}}><b>( i )</b></i></label>
                                                {item.config.displayType === "checkbox" ?
                                                    <React.Fragment key={i}>
                                                        <Checkbox value={this.state.attributes[item.name]} onChange={(e) => this.onChange(item.name, e.checked?"yes":"no")} checked={this.state.attributes[item.name] === "yes"} />
                                                        <label>Yes?</label>
                                                    </React.Fragment>
                                                    : 
                                                    <React.Fragment key={i}>
                                                        <RadioButton name={item.name} value="yes" onChange={(e) => this.onChange(item.name, e.value)} checked={this.state.attributes[item.name] === "yes"} />
                                                        <label style={{marginRight: 15}}>Yes</label>
                                                        <RadioButton name={item.name} value="no" onChange={(e) => this.onChange(item.name, e.value)} checked={this.state.attributes[item.name] === "no"} />
                                                        <label style={{marginRight: 15}}>No</label>
                                                    </React.Fragment>
                                                }
                                                <div><span className="p-form-error">{this.state.errors[item.name]}</span></div>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        :""
                    }
                {Object.keys(this.state.attributes).length !== 0 ?
                    <div className="p-grid">
                        <div className="p-col-12" style={{textAlign: "right"}}>
                            <Button label="Save Attribute Set" icon="pi pi-save" iconPos="left" onClick={(e) => this.handleSaveAttributeSettings()} />
                        </div>
                    </div>
                    : ""
                }
            </div>
        );
    }
}