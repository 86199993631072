import React, {Component} from "react";
import moment from 'moment';
import { ParticipantServices } from "./ParticipantServices";
import { TabView, TabPanel } from "primereact/tabview";
import { Fieldset } from "primereact/fieldset";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { APPLICATION_COMPONENTT_TYPE, APPLICATION_RECORD_STATUS, APPLICATION_TYPE, CHANNEL_TYPE, PRODUCT_TYPE } from "../../constants";
import { showErrorNotify, showNotification, showSuccessNotify } from "../../core/service/NotificationService";
import { getOrderItem } from "../../pim/order/OrderService";
import { deleteApplicationRecord, getPageApplicationRecord, updateStatusApplicationRecord, viewLog } from "../../scp/application/ApplicationService";
import { SplitButton } from "primereact/splitbutton";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import ApplicationRecordForm from '../../scp/application/ApplicationRecordForm'
import QRCode from 'qrcode.react';
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { getAppConfig } from "../../core/service/CommonService";
import { getConfig } from "../../scp/tenant-configuration/ConfigruationServices";
import { getAttributeSet } from "../../scp/attribute/AttributeServices";
export class ParticipantDetails extends Component{

    constructor(props){
        super(props);
        this.state = {
            participant: {},
            program: {},
            course: {},
            participantFields: [],
            parameterUrl: {},
            participantCustomFields: [],
            participantWaiverFields: [],

            records: [],
            recordPage: 0,
            recordRows: 0,
            recordSortField: '',
            recordSortOrder: 0,
            applications: [],
            mapApplicationRecords: {},
            mapCustomFields: {},
            mapWaiverFields: {},
            personalSet: {}
        }
        this.participantSerices = new ParticipantServices();
    }

    componentDidMount(){
        var qs = require('qs');
        
        this.participantSerices.getParticipant(this.props.match.params.id)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    participant: res,
                    parameterUrl: qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
                });

                this.getPaiticipantFields(res.id);
                if(res.orderId && res.orderItemId){
                    this.loadOrderItem(res.orderId, res.orderItemId)
                }
            }else{
                showErrorNotify();
            }
        })
        
        this.loadLogs()
        this.loadPersonalSet()
    }

    getPaiticipantFields = (partId) => {
        this.participantSerices.getParticipantFieldValue(partId)
        .then(res => {
            this.setState({
                // participantFields: res.participantFieldValues
                participantCustomFields: res&&res.participantCustomFields?res.participantCustomFields:[],
                participantWaiverFields: res&&res.participantWaiverFields?res.participantWaiverFields:[],
            })
        })
    }

    loadSeaLogActivities = () => {
        getPageApplicationRecord({participantId: this.props.match.params.id}, this.state.recordPage, this.state.recordRows, this.state.recordSortField, this.state.recordSortOrder)
        .then(res => {
            this.setState({
                records: res.content,
                recordTotal: res.totalElements,
                loading: false,
                recordPage: res.pageable.pageNumber,
                recordRows: res.pageable.pageSize
            });
        })
    }

    loadOrderItem = (orderId, itemId) =>{
        getOrderItem(orderId, itemId)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    orderItem: res?res:{}
                })
            }else{
                showNotification('error', 'Error Message', '');
            }
        })
    }

    loadPersonalSet = async () => {
        let tmpConfig = await getConfig()
        
        if(tmpConfig && tmpConfig.programConfig && tmpConfig.programConfig.personalSetId){
            getAttributeSet(tmpConfig.programConfig.personalSetId)
            .then(res => {
                this.setState({personalSet: res&&res.attributeSet?res.attributeSet:{}})
            })
        }
    }

    headerOwnerTemplate = (data) => {
        let strHeader = 'Addons';
        if(data.product.type === PRODUCT_TYPE.rental.value){
            strHeader = 'Rental'
        }else if(data.product.type === PRODUCT_TYPE.program.value){
            strHeader = 'Program'
        }
        return strHeader
    }

    footerOwnerTemplate = (data) => {
        return <td colSpan={2}></td>
        // return (
        //     <React.Fragment>
        //         <td colSpan={3} className="p-r">Total Price</td>
        //         <td >{this.calculateGroupTotal(data.product.type)}</td>
        //     </React.Fragment>
        // ); 
    }

    calculateGroupTotal = (productType) => {
        let total = 0;

        if (this.state.orderItem.childItems) {
            for (let childItem of this.state.orderItem.childItems) {
                if (childItem.product.type === productType) {
                    total += childItem.total;
                }
            }
        }
        return total;
    }

    actionTemplate = (rowData) => {
        let dropActions = [
            { label: 'Delete record', icon: 'pi-md-trash',
                command: (e) => this.deleteNotify.delete(rowData.id)
            }
        ];

        return (
            <SplitButton label={``} icon="pi-md-search" className="p-button-constrast p-l" model={dropActions} onClick={() => this.applicationRecordForm.popularFormData(rowData)}/>
        );
    };

    removeApplicationRecord = (id) => {
        deleteApplicationRecord(id)
        .then(data => {
            if(!data.errorCode) {
                showSuccessNotify("Action Submitted");
                this.loadSeaLogActivities();
            } else {
                showErrorNotify(data.errorMessage, "Error");
            }
        })
    }

    onRecordPage = (e) =>{
        this.setState({
            recordPage: e.value
        }, () => this.loadSeaLogActivities())
    }

    onRecordSort = (e) => {
        this.setState({
            recordSortField: e.sortField,
            recordSortOrder: e.sortOrder
        }, () => this.loadSeaLogActivities())
    }

    handleApproveOrReject = (id, status) =>{
        updateStatusApplicationRecord(id, status)
        .then(res=>{
            if(!res.errorCode){
                this.loadLogs()
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    loadLogs = () => {
        viewLog(this.props.match.params.id)
        .then(res=>{
            if(!res.errorCode){
                let tmpData = res&&res[0]?res[0]:{}
                this.setState({
                    applications: tmpData.applications?tmpData.applications:[],
                    mapApplicationRecords: tmpData.mapApplicationRecords?tmpData.mapApplicationRecords:{},
                    mapCustomFields: tmpData.mapCustomFields?tmpData.mapCustomFields:{},
                    mapWaiverFields: tmpData.mapWaiverFields?tmpData.mapWaiverFields:{},
                    selectedLogs: []
                })
            }
        })    
    }

    onCreateLog = (logId) => {
        let tmpArr = this.state.selectedLogs?[...this.state.selectedLogs]:[]
        if(tmpArr.includes(logId)){
            tmpArr.splice(tmpArr.indexOf(logId), 1)
        }else{
            tmpArr.push(logId)
        }
        this.setState({
            selectedLogs: tmpArr
        })
    }
    
    renderQRCodeUrl = (toAlias) => {
        const config = getAppConfig()

        if(config && config.scpFEDomain){            
            let logUrl = config.scpFEDomain+'/app/'+toAlias
            return logUrl + '?part='+this.state.participant.id
        }

        return null;
    }

    render(){
        let participant = this.state.participant
        let user = this.state.participant.user?this.state.participant.user:{}
        let applications = this.state.applications?this.state.applications:[]
        let mapApplicationRecords = this.state.mapApplicationRecords?this.state.mapApplicationRecords:{}
        let mapCustomFields = this.state.mapCustomFields?this.state.mapCustomFields:{}
        let mapWaiverFields = this.state.mapWaiverFields?this.state.mapWaiverFields:{}
        let selectedLogs = this.state.selectedLogs?this.state.selectedLogs:{}
        let personalField = this.state.personalSet&&this.state.personalSet.personalField?this.state.personalSet.personalField:{}

        return(
            <div className="p-grid">
                <BreadcrumbsItem to="/participants">Participants</BreadcrumbsItem>
                <BreadcrumbsItem to={`${this.props.match.url}`}>{user.name}</BreadcrumbsItem>

                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeApplicationRecord(e)}
                />

                <ApplicationRecordForm ref={el => this.applicationRecordForm = el}/>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Name: {user.name}
                            <div className="p-toolbar-group-right">
                                {this.state.parameterUrl.c &&
                                    <Link to={`/programs/${this.state.parameterUrl.p}/courses/${this.state.parameterUrl.c}`} className="p-button p-component p-button-primary p-button-icon-only" title="Go to course">
                                        <span className="pi pi-bars p-c p-button-icon-left"></span>
                                    </Link>
                                }
                                <Link to={`/participants`} className="p-button p-component p-button-primary p-button-icon-only">
                                    <span className="pi pi-caret-left p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            {this.state.participant.refName && <div className="p-col-12 p-md-4">Product: {this.state.participant.refName}</div>}
                            {this.state.participant.childRefName && <div className="p-col-12 p-md-4">Child Product: {this.state.participant.childRefName}</div>}
                            <div className="p-col-12 p-md-4">Status: {this.state.participant.status}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-8">
                            <TabView>
                                <TabPanel header="Information">
                                    <Fieldset legend="Basic Information" >
                                        <div className="p-grid">
                                            {(()=>{
                                                let tmpUser = participant.user?participant.user:{}

                                                return <React.Fragment>
                                                    {personalField.useTitleName &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.titleNameLabel}: </label>
                                                            <span>{tmpUser.title}</span>
                                                        </div>
                                                    }
                                                    <div className="p-col-12 p-md-6">
                                                        <label>{personalField.firstNameLabel?personalField.firstNameLabel:'First Name'}: </label>
                                                        <span>{tmpUser.firstName}</span>
                                                    </div>
                                                    {personalField.useMiddleName &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.middleNameLabel}: </label>
                                                            <span>{tmpUser.middleName}</span>
                                                        </div>
                                                    }
                                                    <div className="p-col-12 p-md-6">
                                                        <label>{personalField.lastNameLabel?personalField.lastNameLabel:'Last Name'}: </label>
                                                        <span>{tmpUser.lastName}</span>
                                                    </div>
                                                    {personalField.useEmail &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.emailLabel}: </label>
                                                            <span>{tmpUser.email}</span>
                                                        </div>
                                                    }
                                                    {personalField.useGender &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.genderLabel}: </label>
                                                            <span>{tmpUser.gender}</span>
                                                        </div>
                                                    }
                                                    {personalField.useBirthday &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.birthdayLabel} (mm/dd/yyyy): </label>
                                                            <span>{tmpUser.birthday&&moment(tmpUser.birthday).format('MM/DD/YYYY')}</span>
                                                        </div>
                                                    }
                                                    {personalField.usePhone &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.phoneLabel}: </label>
                                                            <span>{tmpUser.phone}</span>
                                                        </div>
                                                    }
                                                    {personalField.usePassportNRIC &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.passportNRICLabel}: </label>
                                                            <span>{tmpUser.passportNRIC}</span>
                                                        </div>
                                                    }
                                                    {personalField.useNationality &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.nationalityLabel}: </label>
                                                            <span>{tmpUser.nationalityName}</span>
                                                        </div>
                                                    }
                                                    {personalField.useRegisterType &&
                                                        <div className="p-col-12 p-md-6">
                                                            <label>{personalField.registerTypeLabel}: </label>
                                                            <span>{tmpUser.registerType}</span>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                            })()}
                                            {/* <div className="p-col-12 p-md-6">
                                                <label>Chanel: </label>
                                                <span>{this.state.participant.channel}</span>
                                            </div> */}
                                            {/* {this.state.participant.schedule && Object.keys(this.state.participant.schedule).length>0 &&
                                                <React.Fragment>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Start Date: </label>
                                                        <span>{this.state.participant.schedule.startDate && moment(this.state.participant.schedule.startDate).format('MM-DD-YYYY')}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>End Date: </label>
                                                        <span>{this.state.participant.schedule.endDate && moment(this.state.participant.schedule.endDate).format('MM-DD-YYYY')}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Start Time: </label>
                                                        <span>{this.state.participant.schedule.startTime && moment(this.state.participant.schedule.startTime, moment.HTML5_FMT.TIME).format('hh:mm A')}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>End Time: </label>
                                                        <span>{this.state.participant.schedule.endTime && moment(this.state.participant.schedule.endTime, moment.HTML5_FMT.TIME).format('hh:mm A')}</span>
                                                    </div>
                                                </React.Fragment>
                                            } */}
                                            <div className="p-col-12 p-md-6">
                                                <label>Reg.on: </label>
                                                <span>{moment(participant.updated).format('MM-DD-YY hh:mm A')}</span>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    {personalField.useAddress &&
                                        <Fieldset legend="Address Information" >
                                            <div className="p-grid">
                                                {(()=>{
                                                    let tmpAddress = participant.user&&participant.user.address?participant.user.address:{}

                                                    return <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>Address 1: </label>
                                                                <span>{tmpAddress.address1}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>Address 2: </label>
                                                                <span>{tmpAddress.address2}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>City: </label>
                                                                <span>{tmpAddress.city}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>Zip Code: </label>
                                                                <span>{tmpAddress.zipcode}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>Country: </label>
                                                                <span>{tmpAddress.countryName}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>State/Province: </label>
                                                                <span>{tmpAddress.stateName}</span>
                                                            </div>
                                                        </React.Fragment>
                                                })()}
                                            </div>
                                        </Fieldset>
                                    }
                                    {personalField.useEmerContact &&
                                        <Fieldset legend="Emergency Information" >
                                            <div className="p-grid">
                                                {(()=>{
                                                    let tmpEmeContact = participant.user&&participant.user.emeContact?participant.user.emeContact:{}

                                                    return <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>{personalField.emerContactNameLabel}: </label>
                                                                <span>{tmpEmeContact.firstName}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>{personalField.emerContactPhoneLabel}: </label>
                                                                <span>{tmpEmeContact.phone}</span>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label>{personalField.emerContactEmailLabel}: </label>
                                                                <span>{tmpEmeContact.email}</span>
                                                            </div>
                                                        </React.Fragment>
                                                })()}
                                            </div>
                                        </Fieldset>
                                    }
                                    {this.state.participantCustomFields && this.state.participantCustomFields.length>0 &&
                                        <Fieldset legend="Additional Information">
                                            <DataTable value={this.state.participantCustomFields}>
                                                <Column field="fieldLabel" header="Label" />
                                                <Column field="value" header="Value" />
                                            </DataTable>
                                        </Fieldset>
                                    }
                                    {this.state.participantWaiverFields && this.state.participantWaiverFields.length>0 &&
                                        <Fieldset legend="Waiver">
                                            <DataTable value={this.state.participantWaiverFields}>
                                                <Column field="fieldLabel" header="Label" />
                                                <Column field="value" header="Value" 
                                                    body={rowData => {
                                                        let value = rowData.value
                                                        if(rowData.storeType==='boolean'){
                                                            let itemValue = rowData.value?JSON.parse(rowData.value):{}
                                                            value = itemValue.accepted
                                                        }
                                                        return value
                                                    }}
                                                />
                                            </DataTable>
                                        </Fieldset>
                                    }
                                </TabPanel>
                                <TabPanel header="Product">
                                    {(!this.state.orderItem || Object.keys(this.state.orderItem).length===0) ?
                                            <span>No data!</span>
                                        :
                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <Fieldset legend="Basic Information">
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-6">
                                                                Product: {this.state.orderItem.productName} {this.state.orderItem.productVariantName&&('['+this.state.orderItem.productVariantName+']')}
                                                            </div>
                                                            {this.state.orderItem.scheduleInfo &&
                                                                <div className="p-col-12 p-md-6">
                                                                    <div className="p-mb-1 p-ml-3">
                                                                        Start: {moment(this.state.orderItem.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                    </div>
                                                                    <div className="p-mt-2 p-ml-3">
                                                                        End: {moment(this.state.orderItem.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                    </div>        
                                                                </div>
                                                            }
                                                        </div>
                                                    </Fieldset>
                                                    
                                                </div>
                                                <div className="p-col-12">
                                                    <DataTable value={this.state.orderItem.childItems}
                                                        header="Items"
                                                        rowGroupMode="subheader" groupField="product.type"
                                                        rowGroupHeaderTemplate={this.headerOwnerTemplate} rowGroupFooterTemplate={this.footerOwnerTemplate}
                                                    >
                                                        <Column field="productName" header="Product" body={rowData=> rowData.productName+(rowData.productVariantName?('['+rowData.productVariantName+']'):'')} />
                                                        {/* <Column field="quantity" header="Quantity" /> */}
                                                        {/* <Column field="quantity" header="Quantity" /> */}
                                                        <Column header="Description" 
                                                            body={rowData=>{
                                                                if(rowData.scheduleInfo){
                                                                    return <React.Fragment>
                                                                        <div className="p-mb-1 p-ml-3">
                                                                            Start: {moment(rowData.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                        </div>
                                                                        <div className="p-mt-2 p-ml-3">
                                                                            End: {moment(rowData.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                        </div>        
                                                                    </React.Fragment>
                                                                }
                                                            }}
                                                        />
                                                    </DataTable>
                                                </div>
                                            </div>        
                                    }
                                </TabPanel>
                            </TabView>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <TabView>
                                <TabPanel header="Logs">
                                <div className="p-grid p-fluid">
                                {applications.map(app => {
                                    let tmpAppRecords = mapApplicationRecords[app.id]
                                    let appLogUrl  = this.renderQRCodeUrl(app.alias)

                                    return(
                                        <div key={app.id} className="p-col-12">
                                            <Fieldset legend={app.name}>
                                                <div className="p-grid">
                                                    {(()=>{
                                                        if(!tmpAppRecords || tmpAppRecords.length===0){
                                                            return <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <div className="p-col-12 p-sm-6">No log</div>
                                                                    <div className="p-col-12 p-sm-6 p-r">
                                                                        <Button label={selectedLogs.includes(app.id)?'Hide QR':'Create Log'} onClick={() => this.onCreateLog(app.id)} style={{width: 'auto'}} />
                                                                    </div>
                                                                    {selectedLogs.includes(app.id) && 
                                                                        <React.Fragment>
                                                                            <div className="p-col-12"><hr/></div>
                                                                            {appLogUrl?
                                                                            <div className="p-col-12">
                                                                                <div className="p-margin-bottom-5">Url: <a href={appLogUrl} target="blank">{appLogUrl}</a></div>
                                                                                <div className="p-c">
                                                                                    <QRCode value={appLogUrl} size={250} includeMargin={true} level="M"/>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="p-col-12">
                                                                                Note: there's no setting for customer site url
                                                                            </div>
                                                                            }
                                                                            
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }else{
                                                            return <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    {tmpAppRecords.map((record, idxRecord) => {
                                                                        let tmpPersonalInfos = record.personalInfo

                                                                        return (
                                                                            <div key={app.id+'_'+idxRecord} className="p-col-12">
                                                                                <Panel header={'Log Num.#'+(idxRecord+1)+' ('+(record.date&&moment(record.date).format('MM/DD/YYYY hh:mm A'))+')'} toggleable collapsed={true} >
                                                                                    <div className="p-grid">
                                                                                    {app.type===APPLICATION_TYPE.approval.value &&
                                                                                    <React.Fragment>
                                                                                        <div className="p-col-12">
                                                                                            <div className="p-grid">
                                                                                                <div className="p-col-12 p-md-6">
                                                                                                    Current status: <strong>{APPLICATION_RECORD_STATUS[record.status]?APPLICATION_RECORD_STATUS[record.status].label:APPLICATION_RECORD_STATUS.pending.label}</strong>
                                                                                                </div>
                                                                                                <div className="p-col-12 p-md-6 p-r">
                                                                                                    {record.status!==APPLICATION_RECORD_STATUS.approved.value &&
                                                                                                        <Button label="Approve" className="p-button-info" onClick={() => this.handleApproveOrReject(record.id, APPLICATION_RECORD_STATUS.approved.value)} style={{width: 'auto'}} />
                                                                                                    }
                                                                                                    {record.status!==APPLICATION_RECORD_STATUS.rejected.value &&
                                                                                                        <Button label="Reject" className="p-button-warning" onClick={() => this.handleApproveOrReject(record.id, APPLICATION_RECORD_STATUS.rejected.value)} style={{width: 'auto'}} />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="p-col-12"><hr/></div>
                                                                                    </React.Fragment>
                                                                                    }
                                                                                        <div className="p-col-12">
                                                                                            {app.contents && app.contents.map((content, idxContent) => {
                                                                                                return content.components && content.components.map((component, idxComponent) => {
                                                                                                    if(component.type===APPLICATION_COMPONENTT_TYPE.input_field.value){
                                                                                                        let tmpPersonalSet = component.personalSet
                                                                                                        let tmpPersonalField = tmpPersonalSet&&tmpPersonalSet.personalField?tmpPersonalSet.personalField:{}
                                                                                                        let tmpCustomSet = component.customSet
                                                                                                        let tmpWaiverSet = component.waiverSet
                                                                                                        let tmpGuadianPersonalSet = tmpWaiverSet&&tmpWaiverSet.requiredGuardian?tmpWaiverSet.guardianPersonalSet:{}
                                                                                                        let tmpGuardianPersonalField = tmpGuadianPersonalSet&&tmpGuadianPersonalSet.personalField?tmpGuadianPersonalSet.personalField:{}

                                                                                                        let tmpPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                                                                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                                                                                            : {}
                                                                                                        let tmpCustomFieldValue = mapCustomFields[record.id]&&tmpCustomSet&&mapCustomFields[record.id][tmpCustomSet.id]?mapCustomFields[record.id][tmpCustomSet.id]:{}
                                                                                                        let tmpWaiverFieldValue = mapWaiverFields[record.id]&&tmpWaiverSet&&mapWaiverFields[record.id][tmpWaiverSet.id]?mapWaiverFields[record.id][tmpWaiverSet.id]:{}
                                                                                                        let tmpGuardianPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
                                                                                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
                                                                                                            : {}

                                                                                                        return(
                                                                                                            <div key={app.id+'_'+idxRecord+'_'+idxContent} className="p-grid">
                                                                                                                {tmpPersonalSet && 
                                                                                                                    <div className="p-col-12">
                                                                                                                        <div className="p-grid">
                                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpPersonalSet&&tmpPersonalSet.label}</span></div>
                                                                                                                            <div className="p-col-12">
                                                                                                                                {tmpPersonalValue && Object.keys(tmpPersonalValue).map(key =>{
                                                                                                                                    let tmpLabel
                                                                                                                                    let tmpValue
                                                                                                                                    if(key==='firstName'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['firstNameLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='lastName'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['lastNameLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='email'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['EmailLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='gender') {
                                                                                                                                        tmpLabel = tmpPersonalField['genderLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='bithday'){
                                                                                                                                        tmpLabel = tmpPersonalField['birthdayLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]&&moment(tmpPersonalValue[key]).format('MM/DD/YYYYY')
                                                                                                                                    }
                                                                                                                                    if(key==='phone'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['phoneLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='passportNRIC'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['passportNRICLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='nationalityId'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['nationalityLabel']
                                                                                                                                        tmpValue = tmpPersonalValue['nationalityName']
                                                                                                                                    }
                                                                                                                                    if(key==='registerType'){ 
                                                                                                                                        tmpLabel = tmpPersonalField['registerTypeLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='emeContactName') {
                                                                                                                                        tmpLabel = tmpPersonalField['emerContactNameLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='emeContactPhone') {
                                                                                                                                        tmpLabel = tmpPersonalField['emerContactPhoneLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='emeContactEmail') {
                                                                                                                                        tmpLabel = tmpPersonalField['emerContactEmailLabel']
                                                                                                                                        tmpValue = tmpPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(tmpLabel && tmpValue)
                                                                                                                                        return (
                                                                                                                                            <div className="p-grid">
                                                                                                                                                <div className="p-col-6">{tmpLabel}: </div>
                                                                                                                                                <div className="p-col-6">{tmpValue}</div>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                })}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {tmpCustomSet&&
                                                                                                                    <div className="p-col-12">
                                                                                                                        <div className="p-grid">
                                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpCustomSet&&tmpCustomSet.label}</span></div>
                                                                                                                            <div className="p-col-12">
                                                                                                                                {tmpCustomFieldValue && Object.values(tmpCustomFieldValue).map(item => {
                                                                                                                                    return (
                                                                                                                                        <div className="p-grid">
                                                                                                                                            <div className="p-col-6">{item.fieldLabel}: </div>
                                                                                                                                            <div className="p-col-6">{item.value}</div>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                })}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {tmpWaiverSet&&
                                                                                                                    <div className="p-col-12">
                                                                                                                        <div className="p-grid">
                                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpWaiverSet&&tmpWaiverSet.label}</span></div>
                                                                                                                            <div className="p-col-12">
                                                                                                                                {tmpWaiverFieldValue && Object.values(tmpWaiverFieldValue).map(item => {
                                                                                                                                    let partAnswerObj = item&&item.storeType==='boolean'?JSON.parse(item.value):{}
                                                                                                                                    return (
                                                                                                                                        <div className="p-grid">
                                                                                                                                            <div className="p-col-6">{item.fieldLabel}: </div>
                                                                                                                                            <div className="p-col-6">{partAnswerObj.accepted}</div>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                })}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {tmpWaiverSet && tmpWaiverSet.requiredGuardian && tmpGuardianPersonalValue && Object.keys(tmpGuardianPersonalValue).length>0 && 
                                                                                                                    <div className="p-col-12">
                                                                                                                        <div className="p-grid">
                                                                                                                            <div className="p-col-12"><span className="p-w-bold">{tmpGuadianPersonalSet&&tmpGuadianPersonalSet.label}</span></div>
                                                                                                                            <div className="p-col-12">
                                                                                                                                {Object.keys(tmpGuardianPersonalValue).map(key=>{
                                                                                                                                    let tmpLabel
                                                                                                                                    let tmpValue
                                                                                                                                    if(key==='firstName'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['firstNameLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='lastName'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['lastNameLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='email'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['EmailLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='gender') {
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['genderLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='bithday'){
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['birthdayLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]&&moment(tmpPersonalValue[key]).format('MM/DD/YYYYY')
                                                                                                                                    }
                                                                                                                                    if(key==='phone'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['phoneLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='passportNRIC'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['passportNRICLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='nationalityId'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['nationalityLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue['nationalityName']
                                                                                                                                    }
                                                                                                                                    if(key==='registerType'){ 
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['registerTypeLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='emeContactName') {
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['emerContactNameLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='emeContactPhone') {
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['emerContactPhoneLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(key==='emeContactEmail') {
                                                                                                                                        tmpLabel = tmpGuardianPersonalField['emerContactEmailLabel']
                                                                                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                                                                                    }
                                                                                                                                    if(tmpLabel && tmpValue)
                                                                                                                                        return (
                                                                                                                                            <div className="p-grid">
                                                                                                                                                <div className="p-col-6">{tmpLabel}: </div>
                                                                                                                                                <div className="p-col-6">{tmpValue}</div>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                })}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            })}         
                                                                                        </div>
                                                                                    </div>
                                                                                </Panel>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    })()}
                                                </div>
                                            </Fieldset>
                                        </div>
                                    )
                                })}
                            </div>
                                    {/* {this.state.records && this.state.records.length > 0 ?
                                        <DataTable  lazy={true} header={`Sea Log Activities`} paginator={true}
                                            value={this.state.records}
                                            first={this.state.recordPage * this.state.recordRows}
                                            onPage={this.onRecordPage} onSort={this.onRecordSort}
                                            rows={this.state.recordRows} totalRecords={this.state.recordTotal}
                                            sortField={this.state.recordSortField} sortOrder={this.state.recordSortOrder}
                                            responsive={true}
                                        >
                                            <Column field="code" header="Code" />
                                            <Column field="application.name" header="Application" />
                                            <Column field="application.type" header="Type" body={(rowData) => {return( rowData.application && rowData.application.type === APPLICATION_TYPE.sea_log.value ? APPLICATION_TYPE.sea_log.label : rowData.application.type )}} />
                                            <Column field="date" header="Date" body={(rowData) => rowData.date ? moment(rowData.date).format("MM-DD-yyyy") : 'No Date'}/>
                                            <Column header="Actions" body={this.actionTemplate} className='tc-actions' style={{ textAlign: 'center', overlay: 'visible' }} />
                                        </DataTable>
                                    :   
                                        "No recent activity logs found"
                                    } */}
                                </TabPanel>
                                {/* <TabPanel header="Last Updated">
                                    Put who and when last update happen!
                                </TabPanel> */}
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
