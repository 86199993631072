import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Spinner } from "primereact/spinner";
import { InputSwitch } from "primereact/inputswitch";
import { ColorPicker } from "primereact/colorpicker";
import { saveDepartment } from "./DepartmentServices";
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import { UPLOAD_TYPE } from "../../constants";
import { InputTextarea } from "primereact/inputtextarea";
import { TalentServices } from "../../constituent-management/talent/TalentServices";

export const DepartmentForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Department")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const inputUpload = useRef(null)
    
    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader(e?'Edit Department':'New Department')
            setVisible(true)
            setFrmData(popularFormData(e))
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data ? data.id : '',
            code: data && data.code ? data.code : '',
            name: data ? data.name : '',
            // objData: data && data.objData ? data.objData: null,
            imageInfo: data&&data.imageInfo&&Object.keys(data.imageInfo).length>0?data.imageInfo:null,
            externalImageLink: data&&data.imageInfo?(data.imageInfo.fileName?'':data.imageInfo.url):'',
            uploadType: data&&data.imageInfo&&data.imageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            shortDescription: data && data.shortDescription ? data.shortDescription : '',
            fullDescription: data && data.fullDescription ? data.fullDescription : '',
            externalRef: data ? data.externalRef : false,
            goToUrl: data && data.goToUrl ? data.goToUrl : '',
            bgColor: data && data.bgColor ? data.bgColor.substring(1, 7) : '',
            color: data && data.color ? data.color.substring(1, 7) : '',
            weight: data? data.weight : 0,
            active: data ? data.active : true,
            talents: data && data.talents ? data.talents : []
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const handleSave = () => {
        setErrors({})
        let maxFileSize = 104857600 // 100MB
        if(frmData.file){
            let fileSize = frmData.file.size
            if(fileSize > maxFileSize){
                setErrors({image: 'Size not allowed (maximum: 100MB)'})
                return
            }
        }
        saveDepartment(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable) props.refreshTable()
                if(props.dept) props.dept(res);
                if(inputUpload && inputUpload.current) inputUpload.current.value=''
                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        });
    }

    // const getListSkill = () => {
    //     this.talentServices.getTalentsLevels()
    //     .then(res => {
    //         if(!res.errorCode){
    //             this.setState({talents: res});
    //         }
    //     });
    // }

    // const onChangeSelect = (values) => {
    //     let tmpArr = [];
    //     if(values){
    //         values.map((item) => {
    //             tmpArr.push(item.value);
    //         })
    //         setFrmData({...frmData, talents: tmpArr})
    //     }
    // }

    return (
        <Sidebar visible={visible} className="p-sidebar-md" style={{ overflowY: "auto"}} position="right" blockScroll={true} showCloseIcon={false}  onHide={closeForm} >
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Information">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">* Code</label>
                                <InputText keyfilter={/[^\s]/} value={frmData.code} onChange={(e) => setFrmData({...frmData, code: replaceSpaceAndUpperCase(e.target.value)})} />
                                <div className="p-form-error">{errors.code}</div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">* Name</label>
                                <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: titleCaseText(e.target.value)})} />
                                <div className="p-form-error">{errors.name}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Image Url</label>
                                <div className="p-inputgroup">
                                    {(!frmData.uploadType || frmData.uploadType===UPLOAD_TYPE.external_url.value) ?
                                        <React.Fragment>
                                            <InputText value={frmData.externalImageLink} onChange={(e) => setFrmData({...frmData, externalImageLink: e.target.value})} />
                                            <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                        </React.Fragment>
                                        :<React.Fragment>
                                            {frmData.imageInfo && frmData.imageInfo.fileName &&
                                                <span className="p-inputgroup-addon"><img src={frmData.imageInfo.url} alt={frmData.imageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                            }
                                            <input ref={inputUpload} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => setFrmData({...frmData, file: e.target.files[0]})} />
                                            <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value})} />
                                        </React.Fragment> 
                                    }    
                                </div>
                                <div className="p-form-error">{errors.image}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Description</label>
                                <InputTextarea value={frmData.shortDescription} rows="5" onChange={(e) => setFrmData({...frmData, shortDescription: e.target.value})} />
                            </div>
                            <div className="p-col-12">
                                <InputSwitch onLabel="Yes" offLabel="No" checked={frmData.externalRef} onChange={(e) => setFrmData({ ...frmData, externalRef: e.target.value })} />
                                <label className="p-label-inline p-margin-left-15">Is reference to external?</label>
                            </div>
                            {frmData.externalRef && 
                            <div className="p-col-12">
                                <label className="p-label">Go To External Url</label>
                                <InputText value={frmData.goToUrl} onChange={(e) => setFrmData({...frmData, goToUrl: e.target.value})} />
                            </div>
                            }
                        </div>
                        <div className="p-grid p-fluid p-padding-top-15">
                            {/* <div className="p-col-12">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Background Color</label>
                                        <div className="p-inputgroup">
                                            <InputText value={frmData.bgColor} onChange={(e) => setFrmData({ ...frmData, bgColor: e.target.value })} />
                                            <ColorPicker id="bgColor" value={frmData.bgColor} onChange={(e) => setFrmData({ ...frmData, bgColor: e.value })} style={{border: "1px solid #dedede"}} />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Text Color</label>
                                        <div className="p-inputgroup">
                                            <InputText value={frmData.color} onChange={(e) => setFrmData({ ...frmData, color: e.target.value })} />
                                            <ColorPicker value={frmData.color} onChange={(e) => setFrmData({ ...frmData, color: e.value })} style={{border: "1px solid #dedede"}} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="p-col-12">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Weight (for order)</label>
                                        <InputText value={frmData.weight} keyfilter="int" onChange={(e) => setFrmData({ ...frmData, weight: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Active?</label>
                                        <InputSwitch onLabel="Yes" offLabel="No" checked={frmData.active} onChange={(e) => setFrmData({ ...frmData, active: e.target.value })} style={{marginTop: '6px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    {/* <Fieldset legend="Talents">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">Enabled Talents</label>
                                <Select options={this.state.talents} multi onChange={(values) => this.onChangeSelect(values)} style={{width:'100%'}}  placeholder="Select talents" 
                                    values={this.state.talents.filter(x => this.state.department.talents.some(x2 => x2===x.value))}
                                />
                            </div>
                        </div>
                    </Fieldset> */}
                </div>
            </div>
            
            <div className="p-sidebar-line p-my-3"></div>
            
            <div className="p-grid">
                <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()} />
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()} />
                </div>
            </div>
        </Sidebar>
    );
})
