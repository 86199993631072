import React, {Component} from 'react';
import moment from 'moment';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import {InputTextarea} from 'primereact/inputtextarea';
import {MultiSelect} from 'primereact/multiselect';
import {InputSwitch} from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';
import { showNotification } from '../../core/service/NotificationService';
import { savePromotion } from './PromotionService';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { PROMOTION_TYPE, CHANNEL_TYPE, APP_FEP } from '../../constants';
import { getEvents } from '../../scp/event/EventService';

export class PromotionForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            promotion : {
                id: '',
                name: '',
                type: PROMOTION_TYPE.promotion,
                description: '',
                exclusive: false,
                allowCoupon: false,
                channel: CHANNEL_TYPE.event,
                channelList: [],
                refIds: [],
                startAt: '',
                endAt: '',
                active: false
            },
            startDateValue: '',
            endDateValue: '',
            events: [],
            errors: {}
        }
    }

    componentDidMount(){
        this.loadEvents();
    }

    popularFormData = (data) => {
        const trans = this.props.trans;
        this.setState({
            promotion: {
                id: data?data.id:'',
                application: APP_FEP,
                name: data?data.name:'',
                type: data?data.type:PROMOTION_TYPE.promotion,
                description: data?data.description:'',
                priority: data?data.priority+'': this.props.listLength,
                exclusive: data?data.exclusive:false,
                allowCoupon: data?data.allowCoupon:false,
                channel: data?data.channel:CHANNEL_TYPE.event,
                channelList: data ? (data.channelList?data.channelList:[data.channel]) : [],
                refIds: data&&data.refIds ? data.refIds : [],
                startAt: data && data.startAt?data.startAt:'',
                endAt: data && data.endAt?data.endAt:'',
                active: data?data.active:false
            },
            startDateValue: data && data.startAt ? moment(data.startAt, 'YYYY-MM-DD HH:mm').toDate() : '',
            endDateValue: data && data.endAt ? moment(data.endAt, 'YYYY-MM-DD HH:mm').toDate() : '',
            formHeader: data? trans('promotion.mgt.edit_campaign') : trans('promotion.mgt.add_promotion'),
            visible: true,
            errors: {}
        });
    }

    loadEvents = () => {
        getEvents(true).then(res=> { this.setState({ events: res?res:[]}) })
    }

    onStartDateChange = (e) => {
        this.setState({
            promotion: {...this.state.promotion, startAt: moment(e.value).format('YYYY-MM-DD HH:mm')},
            startDateValue: e.value
        })
    }

    onEndDateChange = (e) => {
        this.setState({
            promotion: {...this.state.promotion, endAt: moment(e.value).format('YYYY-MM-DD HH:mm')},
            endDateValue: e.value
        })
    }

    handleSave = () => {
        this.setState({ errors: {} });
        savePromotion(this.state.promotion, this.state.promotion.priority).then(res => {
            if(!res.errorCode){
                this.props.onSave(res, !this.state.promotion.id?true:false);
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode === 400) this.setState({errors: res.errorObj})
            }
        });
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){
        const trans = this.props.trans;
        const channels = [
            {label: "Event", value: CHANNEL_TYPE.event},
            {label: 'Program', value: CHANNEL_TYPE.program}
        ];

        return (
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend={trans('promotion.mgt.header_information')}>
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label>* {trans('promotion.mgt.name')}</label>
                                    <InputText value={this.state.promotion.name} onChange={(e) => this.setState({promotion: {...this.state.promotion, name: e.target.value}})} />
                                    <div className="p-form-error">{this.state.errors.name}</div>
                                </div>
                                {/* <div className="p-col-12">
                                    <label className="p-label">* {trans('promotion.mgt.channel')}</label>
                                    <Dropdown id="channel" value={this.state.promotion.channelList&&this.state.promotion.channelList.length>0?this.state.promotion.channelList[0]:null} options={channels} onChange={(e) => this.setState({promotion: {...this.state.promotion, channelList: e.value?[e.value]:[]}})} filter={false}/>
                                    <div className="p-form-error">{this.state.errors.channelList}</div>
                                </div> */}
                                {/* <div className="p-col-12">
                                    <label className="p-label">{trans('promotion.mgt.type')}</label>
                                    <Dropdown value={this.state.promotion.type} disabled={this.state.promotion.id?true:false} options={PROMOTION_TYPES} onChange={(e) => this.setState({promotion: {...this.state.promotion, type: e.value}})}/>
                                </div> */}
                                {this.state.promotion.channelList && this.state.promotion.channelList.length>0 && this.state.promotion.channelList[0]===CHANNEL_TYPE.event && 
                                    <div className="p-col-12">
                                        <label className="p-label">* {trans('promotion.mgt.event')}</label>
                                        <MultiSelect value={this.state.promotion.refIds} options={this.state.events} onChange={(e) => this.setState({promotion: {...this.state.promotion, refIds: e.value}})} filter={false}/>
                                        <div className="p-form-error">{this.state.errors.refId}</div>
                                    </div>
                                }
                                <div className="p-col-12">
                                    <label className="p-label">{trans('promotion.mgt.description')}</label>
                                    <InputTextarea id="helperMsg" rows={3} cols={100} value={this.state.promotion.description} onChange={(e) => this.setState({promotion: {...this.state.promotion, description: e.target.value}})} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    <div className="p-col-12">
                        <Fieldset legend={trans('promotion.mgt.usage_setting')}>
                            <div className="p-grid p-fluid form-group">
                                {/* {this.state.promotion.type===PROMOTION_TYPE.promotion && 
                                <div className="p-col-12">
                                    <label>{trans('promotion.mgt.priority')}</label>
                                    <Spinner value={this.state.promotion.priority} onChange={(e) => this.setState({promotion: {...this.state.promotion, priority: e.target.value}})} />
                                </div>
                                } */}
                                <div className="p-col-12 p-md-6">
                                    <label>{trans('promotion.mgt.start_date')}</label>
                                    <MaskedCalendar value={this.state.startDateValue} onChange={this.onStartDateChange} showIcon={true}showTime={true} hourFormat="12" />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label>{trans('promotion.mgt.end_date')}</label>
                                    <MaskedCalendar value={this.state.endDateValue} onChange={this.onEndDateChange} showIcon={true}showTime={true} hourFormat="12" />
                                </div>
                            </div>
                        </Fieldset>
                        <Fieldset legend={trans('promotion.mgt.other_setting')}>
                            <div className="p-grid p-fluid form-group">
                                {this.state.promotion.type===PROMOTION_TYPE.promotion && 
                                <div className="p-col-12">
                                    <label className="p-label">{trans('promotion.mgt.allow_use_coupon')}?</label>
                                    <InputSwitch renderTabHeader checked={this.state.promotion.allowCoupon} onChange={(e) => this.setState({promotion: { ...this.state.promotion, allowCoupon: e.target.value}})} />
                                </div>
                                }
                                <div className="p-col-12">
                                    <label className="p-label">{trans('promotion.mgt.active')}?</label>
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.promotion.active} onChange={(e) => this.setState({promotion: { ...this.state.promotion, active: e.target.value}})} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label={trans('promotion.mgt.submit')} icon="pi pi-check" onClick={this.handleSave}/>
                        <Button label={trans('promotion.mgt.cancel')} icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}
