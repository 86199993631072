import React, { Component } from 'react';
import { ConnectedAppServices } from './ConnectedAppServices';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import ConnectedAppForm from './ConnectedAppForm';
import { showNotification } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import classNames from 'classnames';
import { DeleteNotification } from "../../core/components/DeleteNotification";

export default class ConnectedAppListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: "",
            sortOrder: 0,
        }

        this.connectedAppServices = new ConnectedAppServices();
    }

    componentDidMount(){
        showloading();
        this.getPagesAppToken();
    }

    getPagesAppToken = () => {
        this.connectedAppServices.getPageAppToken(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                stoploading();
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
    }

    onPage = (e) => {
        this.setState(
            {
                loading: true,
                page: e.page
            },
            () => {
                this.getPagesAppToken();
            }
        );
    };

    onSort = (e) => {
        this.setState(
            {
                loading: true,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            },
            () => {
                this.getPagesAppToken();
            }
        );
    };

    applyFilter = () => {
        this.setState(
            {
                loading: true
            },
            () => {
                this.getPagesAppToken();
            }
        );
    };


    onChagePageLength = l => {
        this.setState(
            {
                rows: l,
                page: 0
            },
            () => {
                this.applyFilter();
            }
        );
    };

    addNewAppToken = () => {
        this.appTokenForm.openForm();
    };

    handleDeleteToken = (apiKey) => {
        this.connectedAppServices.deleteAppToken(apiKey)
            .then(() => {
                showNotification('success', 'Success Message', 'Action submitted');
                this.getPagesAppToken();
            });
    }


    actionTemplate = (rowData) => {
        let dropActions = [
            {
                label: 'Delete Token',
                icon: 'pi pi-times',
                command: () => {
                    this.deleteNotify.delete(rowData.apiKey);
                }
            }
        ];
        return <SplitButton label="Edit" className="p-button-constrast" model={dropActions} tooltip='Edit Campaign' tooltipOptions={{position: "top"}} onClick={() => this.appTokenForm.openForm(rowData)}/>
    };

    activeTemplate = (rowData) => {
        return (
            <span className={classNames('customeStatus', 'status-' + rowData.active)}>{rowData.active ? 'YES' : 'NO'}</span>
        );
    }

    render() {

        let tableLengthOptions = [
            {
                label: '5',
                command: () => {
                    this.onChagePageLength(5);
                }
            },
            {
                label: '10',
                command: () => {
                    this.onChagePageLength(10);
                }
            },
            {
                label: '25',
                command: () => {
                    this.onChagePageLength(25);
                }
            },
            {
                label: '50',
                command: () => {
                    this.onChagePageLength(50);
                }
            },
            {
                label: '100',
                command: () => {
                    this.onChagePageLength(100);
                }
            }
        ];

        let header = (
            <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
                <div style={{ float: "left" }}>
                    <Button icon="pi-md-add" className="p-button-constrast" label="Add Token" onClick={() => this.addNewAppToken()}/>
                </div>
                <div style={{ float: "right" }}>
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                            onClick={() => this.getPagesAppToken()} model={tableLengthOptions}>
                    </SplitButton>
                </div>
            </div>
        );

        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Application Token</BreadcrumbsItem>

                <ConnectedAppForm
                    ref={el => this.appTokenForm = el}
                    reloadTable={() => this.getPagesAppToken()}
                />

                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.handleDeleteToken(e)}
                />

                <div className="p-col-6">
                    <DataTable
                        lazy={true}
                        header={header}
                        paginator={true}
                        value={this.state.dataList}
                        first={this.state.page * this.state.rows}
                        onPage={this.onPage}
                        onSort={this.onSort}
                        rows={this.state.rows}
                        totalRecords={this.state.total}
                        sortField={this.state.sortField}
                        sortOrder={this.state.sortOrder}
                        responsive={true}
                    >
                        <Column field="apiKey" header="Api Key" sortable={true} />
                        <Column body = {rowData => { return <div>{rowData.user.name} - [{rowData.user.email}] </div>}} header="User Infomation" sortable={true} />
                        <Column className="active" field="active" style={{ width: "6em" }} body={this.activeTemplate} header="Active" />
                        <Column
                            header=""
                            className="tc-actions"
                            body={this.actionTemplate}
                            style={{ width: "10em" }}
                        />
                    </DataTable>
                </div>
                <div className="p-col-6">
                    <div className="card card-w-title">
                        <h1>
                            Application Token
                        </h1>
                    </div>
                </div>

            </div>
        )
    }
}