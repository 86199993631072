import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { GALLERY_TYPE, PRODUCT_IMAGE_TYPE, UPLOAD_TYPE } from '../../constants';


export const ProductGallery = (props) => {
    const inputUpload = useRef(null)

    const defaultImage = {type: PRODUCT_IMAGE_TYPE.gallery, url: ''}
    const defaultVideo = {videoUrl: '', videoChatUrl:''}
    const [frmVisible, setFrmVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [dataIdx, setDataIdx] = useState()
    const [errors, setErrors] = useState({})
    const value = props.value?props.value.map(item => {return {...item, uploadType: item.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value}}):[]
    
    useEffect(()=>{
        if(props.errors && Object.keys(props.errors).length>0){
            setErrors(props.errors)
        }
    }, [props.errors])
    
    const addOrEditItem = (idx) => {
        if(idx===undefined){
            setFrmData(props.type===GALLERY_TYPE.image?{...defaultImage}:{...defaultVideo})
        }else{
            if(props.type === GALLERY_TYPE.image){
                setFrmData({...value[idx], 
                    uploadType: value[idx].fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value, 
                    url: value[idx].fileName?'':value[idx].url
                })
            }else{
                setFrmData({...value[idx]})
            }
        }

        setDataIdx(idx)
        setFrmVisible(true)
    }

    const closeForm = () => {
        setFrmVisible(false)
        setFrmData({})
        setDataIdx(null)
    }

    const removeItem = (idx) => {
        if(idx!==undefined){
            let lstValue = [...value];
            lstValue.splice(idx, 1);
            if(idx===dataIdx && frmVisible){
                closeForm()
            }
            props.onChange(lstValue);
        }
    }

    const submitItem = () => {
        let frmErrors = {}

        switch(props.type){
            case GALLERY_TYPE.image:
                if(!frmData.uploadType || frmData.uploadType===UPLOAD_TYPE.external_url.value){
                    if(!frmData.url)
                    frmErrors.url = 'Field is required!'
                }else{
                    if(!frmData.file) frmErrors.url = 'Field is required!'
                    else {
                        let fileName = frmData.file.name
                        let extFile = fileName.substr(fileName.lastIndexOf('.')+1)
                        if(!['png', 'jpg', 'jpeg'].includes(extFile)){
                            frmErrors.url = 'Wrong image format (allowed formats: png, jpg, jpeg)'
                        }else{
                            frmData.fileName = fileName
                        }
                    }
                }
                
                break;
            case GALLERY_TYPE.video:
                if(!frmData.videoUrl)
                    frmErrors.videoUrl = 'Field is required!'
                break;
            default: break;
        }

        if(Object.keys(frmErrors).length>0){
            setErrors(frmErrors)
            return
        }

        let lstValue = [...value];
        
        if(dataIdx===undefined){
            lstValue.push(frmData);
        }else{
            lstValue.splice(dataIdx, 1, frmData);
        }
        if(inputUpload && inputUpload.current){
            inputUpload.current.value=''
        }
        
        closeForm()
        props.onChange(lstValue);
    }

    return (
        <div className="p-grid">
            {!frmVisible && 
            <div className="p-col-12">
                <Button label={props.type===GALLERY_TYPE.image?'Add image':'Add video'} className="btn-text-sm" onClick={() => addOrEditItem()}/>
            </div>
            }
            {errors.image && <div className="p-col-12"><div className="p-form-error">{errors.image}</div></div>}
            {frmVisible && 
            <div className="p-col-12">
                <div className="p-grid p-fluid form-group">
                    {props.type===GALLERY_TYPE.image?
                    <div className="p-col-12">
                        <label className="p-label">* Image</label>
                        {/* <InputText value={frmData.url} onChange={(e) => setFrmData({...frmData, url: e.target.value})}/> */}
                        <div className="p-inputgroup">
                            {!frmData.uploadType || frmData.uploadType===UPLOAD_TYPE.external_url.value ?
                                <React.Fragment>
                                    <InputText value={frmData.url} onChange={(e) => setFrmData({...frmData, url: e.target.value})} />
                                    <Button label="Upload" icon="pi pi-upload" iconPos="right" onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                </React.Fragment>
                                :<React.Fragment>
                                    <input ref={inputUpload} className="p-inputtext p-component" type="file" accept="image/png, image/jpeg" onChange={(e) => setFrmData({...frmData, file: e.target.files[0]})} />
                                    <Button label="Url" icon="pi pi-external-link p-size-20" iconPos="right" onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value})} />
                                </React.Fragment> 
                            }
                        </div>
                        <div className="p-form-error">{errors.url}</div>
                    </div>
                    :
                    <React.Fragment>
                        <div className="p-col-12">
                            <label className="p-label">* Video url</label>
                            <InputText value={frmData.url} onChange={(e) => setFrmData({...frmData, videoUrl: e.target.value})}/>
                            <div className="p-form-error">{errors.videoUrl}</div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Video chat url</label>
                            <InputText value={frmData.url} onChange={(e) => setFrmData({...frmData, videoChatUrl: e.target.value})}/>
                        </div>
                    </React.Fragment>
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r">
                        <Button label="Submit" className="btn-text-sm p-button-success" onClick={() => submitItem()}/>
                        <Button label="Cancel" className="btn-text-sm p-button-default" onClick={() => closeForm()}/>
                    </div>
                </div>
            </div>
            }
            <div className="p-col-12">
                {value && value.length>0 && value.map((val, idx) => {
                    return (
                        <div key={"price_"+idx} className="p-margin-bottom-10">
                            <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-padding-bottom-0">
                                <div className="p-grid">
                                    <div className="p-col-10">
                                        {props.type===GALLERY_TYPE.image?
                                        <label>Image Url: {val.fileName?<img src={val.url} alt={val.fileName} style={{width: 30}} />:val.url}</label>
                                        :
                                        <React.Fragment>
                                            <label className="p-display-block">Video Url: {val.videoUrl}</label>
                                            {val.videoChatUrl &&
                                            <label className="p-display-block">Video Url: {val.videoChatUrl}</label>
                                            }
                                        </React.Fragment>
                                        }
                                    </div>
                                    <div className="p-col-2 p-r">
                                        <Button icon="pi pi-pencil" className="btn-xs p-button-info" onClick={() => addOrEditItem(idx)}/>
                                        <Button icon="pi pi-times" className="btn-xs p-button-danger" onClick={() => removeItem(idx)}/>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>                           
                    );
                })} 
            </div>
        </div>
    )
}