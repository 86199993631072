/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import moment from 'moment';
import 'moment-timezone';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { getPageSchedules, pickupSchedule, closeExpiredSchedule } from "../../pim/asset-schedule/SchedulesService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ASSET_SCHEDULE_STATUS, ORDER_STATE, PRODUCT_PRICE_UNIT } from "../../constants";
import { ReturnAssetForm } from "./ReturnAssetForm";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport, getTimeZone, moneyFormat } from "../../core/service/CommonService";
import classNames from "classnames";
import { ViewLogApplicationForm } from "../../scp/application/ViewLogApplicationForm";
import { SplitButton } from "primereact/splitbutton";
import { useHistory } from "react-router-dom";
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";

export const AssetRentingSchedules = (props) => {
    const history = useHistory()

    const viewLogForm = useRef(null)
    const returnAssetForm = useRef(null)

    const [pageable, setPageable] = useState({page: 0, rows: 10, total: 0, sortField: '', sortOrder: 0})
    const [dataList, setDataList] = useState([])
    const [isLoadTable, setLoadTable] = useState(true)

    const timezone = getTimeZone()

    const viewDate = props.viewDate;
    const tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
    const tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''

    const [filter, setFilter] = useState({
        filterByKeyword: true,
        statuses: [ASSET_SCHEDULE_STATUS.pending.value, ASSET_SCHEDULE_STATUS.processing.value, ASSET_SCHEDULE_STATUS.in_scheduled.value, ASSET_SCHEDULE_STATUS.using.value],
        dateFrom: !props.subscription && tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
        dateTo: !props.subscription && tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):'',
        orderStates: [ORDER_STATE.new_order.value, ORDER_STATE.viewed.value],
        branchId: props.viewBranch===0?null:props.viewBranch,
        subscription: props.subscription,
        orderIdNumber: '',
        invoiceNumber: '',
        buyer: ''
    })

    useEffect(() => {
        if(!props.subscription){
            const viewDate = props.viewDate;
            let tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
            let tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''
            setFilter({...filter, 
                dateFrom: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
                dateTo: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):'',
                orderIdNumber: '',
                invoiceNumber: '',
                buyer: ''
            });

            setLoadTable(true)
        }
    }, [props.viewDate])

    useEffect(() => {
        setFilter({...filter,
            branchId: props.viewBranch===0?null:props.viewBranch,
        });

        setLoadTable(true)
    }, [props.viewBranch])

    useEffect(() => {
        if(!props.subscription){
            const viewDate = props.viewDate;
            let tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
            let tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''
            setFilter({...filter, 
                dateFrom: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
                dateTo: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):'',
                subscription: props.subscription,
            });
        }else{
            setFilter({...filter,
                dateFrom: '',
                dateTo: '',
                subscription: props.subscription,
            });
        }

        setLoadTable(true)
    }, [props.subscription])

    useEffect(() => {
        if(isLoadTable)
            loadPageSchedules()
    }, [isLoadTable])

    const loadPageSchedules = () => {
        getPageSchedules(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
            setLoadTable(false)
        })
    }

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true)
    }

    const onChagePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true);
    };

    const clearFilter = () => {
        setFilter({
            ...filter,
            orderIdNumber: '',
            invoiceNumber: '',
            buyer: ''
        })

        setLoadTable(true)
    }

    const actionTemplate = (rowData) => {
        const strNow = timezone?moment().tz(timezone).format('YYYY-MM-DD HH:mm'):moment().format('YYYY-MM-DD HH:mm')

        const now = moment(strNow)
        const start = moment(rowData.start)
        const end = moment(rowData.end)

        let isExpired = false

        if(now.isAfter(end))
            isExpired = true

        let actionItems = [
            {label: 'View Log', icon: 'pi pi-search', command: () => viewLogForm.current.openForm(null, rowData.id)},
        ]

        if(rowData.status !== ASSET_SCHEDULE_STATUS.using.value){
            if(!isExpired){
                return <SplitButton label="Assign" className="p-button-constrast p-l" model={actionItems} onClick={() => handleAssignAsset(rowData)} />
            }else{
                return (
                    <Button label="Close" icon="pi pi-times" className="p-button-constrast" onClick={() => closeExpiredAssetSchedule(rowData.id)}/>
                )
            }
        }else{
            return <SplitButton label="Return" className="p-button-constrast p-l" model={actionItems} onClick={() => returnAssetForm.current.openForm(rowData)} />
        }
    }

    const handleAssignAsset = (data) => {
        if([ASSET_SCHEDULE_STATUS.pending.value, ASSET_SCHEDULE_STATUS.processing.value].includes(data.status)){
            showConfirmNotify({
                message: 'The order related with asset schedule has not been paid completely. Must process on order first!',
                acceptLabel: 'Yes, go to the order',
                accept: () => history.push(`/orders/${data.orderId}`)
            })
        }else{
            const strNow = timezone?moment().tz(timezone).format('YYYY-MM-DD HH:mm'):moment().format('YYYY-MM-DD HH:mm')

            const now = moment(strNow)
            const start = moment(data.start)
            const end = moment(data.end)

            if(!now.isAfter(end)){
                if(now.isBefore(start)){
                    showConfirmNotify({
                        message: "Current doesn't match with schedule time. Are you sure to do this?",
                        accept: () => assignAsset(data.id)
                    })
                }else{
                    assignAsset(data.id)
                }
            }
        }
    }

    const assignAsset = (scheduleId) => {
        pickupSchedule(scheduleId)
        .then(res =>{
            if(!res.errorCode){
                if(res.inCompletedLog){
                    viewLogForm.current.openForm(null, scheduleId)
                    showErrorNotify("Please complete the application log as required to continue!")
                }else{
                    setLoadTable(true)
                    showSuccessNotify('Asset has been assigned')
                }    
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    const closeExpiredAssetSchedule = (id) => {
        closeExpiredSchedule()
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Schedule has been closed')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    const tblHeader = (
        <TableHeader title={props.subscription?'Asset Subscriptions':'Asset Renting Schedules'}
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChagePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    )

    return (
        <div className="p-grid">
            <ViewLogApplicationForm ref={viewLogForm} />

            <ReturnAssetForm ref={returnAssetForm} 
                reloadTable={()=>setLoadTable(true)}
            />

            <div className="p-col-12">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-text-bold">Filter By:</label>
                        <div className="p-grid p-fluid p-mt-1">
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Order Id/Code:</span>
                                    <InputText value={filter.orderIdNumber} onChange={e => setFilter({...filter, orderIdNumber: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                                </div>
                            </div>
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Invoice/Receipt Number:</span>
                                    <InputText value={filter.invoiceNumber} keyfilter={/^[0-9]*$/} onChange={e => setFilter({...filter, invoiceNumber: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                                </div>
                            </div>
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Buyer:</span>
                                    <InputText value={filter.buyer} onChange={e => setFilter({...filter, buyer: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)} placeholder="Name/email/phone"/>
                                </div>
                            </div>
                            <div className="p-col-3">
                                <Button label="Find" icon="pi pi-filter" iconPos="left" style={{width: 'auto'}} onClick={() => setLoadTable(true)} />
                                <Button label="Clear" icon="pi pi-trash" className="p-button-secondary p-ml-2" iconPos="left" style={{width: 'auto'}}  onClick={() => clearFilter()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <DataTable
                    emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="Order" body={rowData => (rowData.orderId+' - '+rowData.orderNumber)} />
                    <Column field="buyerName" header="Buyer" body={rowData => <React.Fragment><div>{rowData.buyerName + ' - [' + rowData.buyerEmail + ']'}</div>{rowData.buyerPhone && <div>{rowData.buyerPhone}</div>}</React.Fragment>} />
                    <Column field="asset.name" header="Asset" />
                    <Column field="status" header="Status" className="p-c" body={rowData => <span className={classNames('status', rowData.status)}>{ASSET_SCHEDULE_STATUS[rowData.status] && ASSET_SCHEDULE_STATUS[rowData.status].label}</span>} />
                    <Column header="Schedule" 
                        body={rowData => {
                            if(rowData.start || rowData.end){
                                const startDate = moment(rowData.start).format('MM/DD/YYYY')
                                const endDate = moment(rowData.end).format('MM/DD/YYYY')
                                const startTime = moment(rowData.start).format('hh:mm A')
                                const endTime = moment(rowData.end).format('hh:mm A')
                                return <React.Fragment>
                                    <div className="p-margin-bottom-5">Date: {startDate===endDate?startDate:(startDate+' - '+endDate)}</div>
                                    <div>Time: {startTime+' - '+endTime}</div>
                                </React.Fragment>    
                            }
                        }} 
                    />
                    {props.subscription && 
                    <Column header="Subscription" 
                        body={rowData => {
                            const subscriptionInfo = rowData.subscriptionInfo
                            if(subscriptionInfo)
                                return(
                                    <React.Fragment>
                                        <div>Period: {subscriptionInfo.period} {PRODUCT_PRICE_UNIT[subscriptionInfo.periodType].label}{subscriptionInfo.period>1?'s':''}</div>
                                        <div className="p-margin-top-5">Times: {subscriptionInfo.times}</div>
                                        {subscriptionInfo.pledgedDeposit && subscriptionInfo.pledgedDeposit>0 && 
                                        <div className="p-margin-top-5">Security deposit: {moneyFormat(subscriptionInfo.pledgedDeposit)}</div>
                                        }
                                        <div></div>
                                    </React.Fragment>
                                )
                        }} 
                    />
                    }
                    <Column body={actionTemplate} headerStyle={{width: '12em'}} bodyClassName="tc-actions"/>
                </DataTable>
            </div>
        </div>
    )
}

