import React, { Component } from 'react';
import { MembershipTemplateServices } from "./MembershipTemplateServices";
import { Fieldset } from 'primereact/fieldset';
import { InputText } from "primereact/inputtext";
import { Spinner } from 'primereact/spinner';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Checkbox } from "primereact/checkbox";

import { showNotification } from '../../core/service/NotificationService';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";

export class MembershipTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formHeader: '',
            template: {},
            errors: {}
        }
        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    popularFormData = (data) => {
        this.setState({
            formHeader: (data?'Edit':'Add') + ' Membership Type',
            template: {
                id: data ? data.id : '',
                name: data ? data.name : '',
                description: data ? data.description : '',
                minPrice: data ? data.minPrice : '0',
                weight: data ? data.weight : 0,
                active: true,
                online: data ? data.online : false
            },
            errors: {}
        });
    }

    resetForm = () => {
        this.popularFormData(null);
    }

    handleSaveMembershipTemplate = () => {
        this.membershipTemplateServices.saveMembershipTemplate(this.state.template).then(res => {
            if(!res.errorCode){
                if (!this.state.template.id) {
                    this.props.reloadTable();
                    this.resetForm();
                } else {
                    this.props.reloadTable(res);
                }

                showNotification('success', 'Success Message', 'Action Submitted');
                this.props.hideFormDialog();
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });
    }

    render() {
        const footer = (
            <React.Fragment>
                <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveMembershipTemplate()}/>
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.props.hideFormDialog}/>
            </React.Fragment>
        );

        return (
            <Sidebar dismissable={false} visible={this.props.visible} style={{overflowY: 'auto', width: '40em'}} position="right" blockScroll={true} onHide={(e) => this.props.hideFormDialog()}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">* Name</label>
                                    <InputText value={this.state.template.name} onChange={(e) => this.setState({ template: { ...this.state.template, name: titleCaseText(e.target.value) } })} />
                                    <div className="p-form-error">{this.state.errors.name} </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Min price per month</label>
                                    <Spinner value={this.state.template.minPrice} min="0" onChange={(e) => this.setState({template: {...this.state.template, minPrice: e.target.value}})} />
                                </div>
                                <div className="p-col-12">
                                    <InputTextarea id="description" rows={5} cols={100} value={this.state.template.description} placeholder="Description" onChange={(e) => this.setState({ template: { ...this.state.template, description: e.target.value } })} />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Weight (for order)</label>
                                    <InputText value={this.state.template.weight} keyfilter="int" onChange={(e) => this.setState({template: {...this.state.template, weight: e.target.value}})} />
                                </div>
                                <div className="p-col-12">
                                    <Checkbox checked={this.state.template.online} onChange={(e) => this.setState({template: {...this.state.template, online: e.checked}})} />
                                    <label>Show Online</label>
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        {footer}
                    </div>
                </div>
            </Sidebar>
        );
    }
}