import React, {Component} from 'react';
import {FullCalendar} from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import SimpleEventScheduleForm from './SimpleEventScheduleForm';
import { SimpleEventServices } from "./SimpleEventService";
import { SimpleCalendarServices } from '../simple-calendar/SimpleCalendarServices';
import SimpleCalendarForm from '../simple-calendar/SimpleCalendarForm';
import { Dropdown } from 'primereact/dropdown';
import { showNotification } from '../../core/service/NotificationService';

export default class SimpleEventSchedule extends Component {
    constructor() {
        super();
        this.state = {
            date: '',
            events: [],
            eventId: null,
            simpleCalendarId: null,
            formHeader: "Add Event",
            formVisible: false,
            simpleCalendars: [],
            simpleCalendarFormVisible: false,
            loading: false,
            index: 0,
            page: 0,
            rows: 10,
            total: 0,
            sortField: "",
            sortOrder: 0,
            filter: {
                calendarId: '',
            },
            options: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                defaultView: 'dayGridMonth',
                defaultDate: this.date,
                header: {
                    left: 'addScheduleButton + addSimpleCalendarButton',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                editable: true,
                customButtons: {
                    addScheduleButton: {
                        text: 'Add Event',
                        click: () => {
                        this.setState({
                            eventId: 0,
                            formVisible: true,
                            formHeader: "Add Event"
                        })
                        }
                    },
                    addSimpleCalendarButton: {
                        text: 'Add Calendar',
                        click: () => {
                            this.setState({
                                simpleCalendarFormVisible: true,
                                formHeader: "Add Calendar"
                            })
                        }
                    }
                },
                eventClick: (info) => {
                    this.setState({
                        eventId: info.event.id,
                        formVisible: true,
                        formHeader: "Edit Event"
                    });
                },
                calendarClick: (data) => {
                    this.setState({
                        simpleCalendarId: data.simpleCalendars.id,
                        simpleCalendarFormVisible: true,
                        formHeader: "Edit Calendar"
                    });
                }
            }
        };
        this.SimpleEventServices = new SimpleEventServices();
        this.SimpleCalendarServices = new SimpleCalendarServices();
    }

    componentDidMount() {
        this.getEventSchedules();
        // let date = new Date().getDate();
        this.getListSimpleCalendar();
    }

    getEventSchedules = () => {
        this.SimpleEventServices.getPageSimpleEvents(this.state.filter,
            this.state.page,
            this.state.rows,
            this.state.sortField,
            this.state.sortOrder)
            .then(res => {
                this.setState({
                    events: res.content,
                    total: res.totalElements,
                    loading:false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            })
    }

    getListSimpleCalendar = () => {
        this.SimpleCalendarServices.getListSimpleCalendars()
        .then(res => {
            let arr = res;
            arr.unshift({value: '', label: 'All'});
            this.setState({
                lstCalendars: arr ? arr : []
            })
        })
    }

    applyFilter = () => {
        this.getEventSchedules();                
    }
    onChagePageLength = (e) => {
        this.setState({
            rows: e.value,
            page: 0
        }, () => {
            this.applyFilter();
        })
    }

    onPage = e => {
        this.setState(
            {
                loading: true,
                page: e.page
            },
            () => {
                this.getSimpleEvents();
            }
        );
    };

    clearFilter = () => {
        this.setState({
            filter: {...this.state.filter,
                calendarId: '',
            }
        }, () => {this.applyFilter()});
    }

    onFilterByCalendar = (e) => {
        this.setState({
                    filter: {...this.state.filter,
                        calendarId: e.value,
                    }
                },() => {
                    this.applyFilter();
                })
    }

    render() {

        return (
            <div>
                <SimpleEventScheduleForm calendarId={this.state.filter.calendarId} eventId={this.state.eventId}
                    visible={this.state.formVisible} formHeader={this.state.formHeader}
                    hideForm={() => this.setState({ formVisible: false })}
                    reloadTable={() => this.getEventSchedules()}
                    onSuccess={(e) => showNotification('success', 'Success Message', 'Action submitted')}
                    onError={(e) => showNotification('error', 'Error Message', 'Cannot perform action')}
                />
                <SimpleCalendarForm formHeader={this.state.formHeader}
                    ref={el => this.simple = el}
                    visible={this.state.simpleCalendarFormVisible}
                    hideForm={() => this.setState({ simpleCalendarFormVisible: false})}
                    reloadTable={() => this.getListSimpleCalendar()}
                    onSuccess={(e) => showNotification('success', 'Success Message', 'Action submitted')}
                    onError={(e) => showNotification('error', 'Error Message', 'Cannot perform action')}
                    simpleCalendarId={this.state.simpleCalendarId}
                />
                <div className="content-section implementation">
                    <Dropdown value={this.state.filter.calendarId} style={{position:'absolute', marginLeft:'220px', marginTop:'10px'}} options={this.state.lstCalendars} showIcon={true} onChange={(e) => this.onFilterByCalendar(e)}/>
                    <FullCalendar  events={this.state.events} options={this.state.options} />
                </div>
            </div>
        );
    }
}