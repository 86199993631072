import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Spinner } from 'primereact/spinner';
import {Checkbox} from 'primereact/checkbox';
import React, {useState, useEffect} from 'react';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { removeInventory, saveInventory } from './InventoryService';
import classNames from 'classnames';
import { APP_FEP, TAXONOMY_TYPE } from '../../constants';
import { getListTaxons } from '../taxon/TaxonServices';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';

const InventoryForm = forwardRef((props, ref) => {
    const [errors, setErrors] = useState({})
    const [branches, setBranches] = useState([])
    const [frmData, setData] = useState({})
    const [visible, setVisible] = useState(false)

    const [formHeader, setFormHeader] = useState('New Inventory')

    useImperativeHandle(ref, () => ({
        openForm: (data) => popularFormData(data)
    }));

    useEffect(() => {
        loadAllBranchs()
    }, [])

    const popularFormData = (e) => {
        setData({
            id: e?e.id : '',
            application: APP_FEP,
            code: e?e.code : '',
            name: e?e.name : '',
            branchId: e?e.branchId:null,
            weight : e?e.weight : 0,
            active: e?e.active : false
        });
        setFormHeader(e?'Edit Inventory':'New Inventory');
        setVisible(true);
    }
    
    const hideForm = () => {
        setData({});
        setErrors({});
        setVisible(false);
    }

    const loadAllBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}).then(res => setBranches(res))
    }

    const handleSave = () => {
        setErrors({});
        saveInventory(frmData)
        .then(res => {
            if(!res.errorCode){
                if(props.reloadTable)
                    props.reloadTable();
                if(props.refresh)
                    props.refresh(res)

                hideForm();
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify('Cannot perform action');
                if(res.errorCode===400) setErrors(res.errorObj)
            }
        })
    }

    const deleteInventory = () => {
        removeInventory(frmData.id)
        .then(res => {
            if(!res.errorCode){
                if(props.reloadTable)
                    props.reloadTable();
                hideForm();
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify('Cannot perform action');
            }
        })
    }

    return(
        <Sidebar dismissable={false} visible={visible} style={{ overflowY: "auto", width: "40em" }} position="right" blockScroll={true} onHide={hideForm} >
            <h2>{formHeader}</h2>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <label className="p-label">* Code</label>
                    <InputText className="p-col-12" value={frmData.code} onChange={(e) => setData({...frmData, code: e.target.value})}/>
                    <span className="p-form-error">{errors.code}</span>
                </div>
                <div className="p-col-12">
                    <label className="p-label">* Name</label>
                    <InputText className="p-col-12" value={frmData.name} onChange={(e) => setData({...frmData, name: e.target.value})}/>
                    <span className="p-form-error">{errors.name}</span>
                </div>
                {branches.length>1 && 
                <div className="p-col-12">
                    <label className="p-label">* Branch</label>
                    <Dropdown value={frmData.branchId} options={branches} onChange={(e) => setData({...frmData, branchId: e.value})} placeholder="Select branch"/>
                    <span className="p-form-error">{errors.branch}</span>
                </div>
                }
                <div className="p-col-6">
                    <InputSwitch checked={frmData.active} onChange={(e) => setData({...frmData, active: e.value })} />
                    <label className="p-margin-left-10">Active?</label>
                </div>
            </div>
            <div className="p-grid p-margin-top-30">
                {frmData.id &&
                    <div className="p-col-12 p-md-4">
                        <Button label="Remove" icon="pi-md-trash" className="p-button-danger" onClick={() => deleteInventory()}/>
                    </div>
                }
                <div className={classNames("p-col-12 p-r", {"p-md-8": frmData.id})}>
                    <Button label="Save" icon="pi-md-save" onClick={() => handleSave()}/>
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => hideForm()}/>
                </div>
            </div>
        </Sidebar>
    )
})

export default InventoryForm;