import React, { useState, useEffect, useRef } from "react";
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { useRouteMatch } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { getConfig } from "../../scp/tenant-configuration/ConfigruationServices";
import { getApplication, getPageApplicationRecord } from "../../scp/application/ApplicationService";
import { APPLICATION_RECORD_STATUS } from "../../constants";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import ApplicationRecordForm from "../../scp/application/ApplicationRecordForm";

export const CustomerRequestView = (props) => {
    const match = useRouteMatch()

    const [application, setApplication] = useState({})
    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        statuses: [APPLICATION_RECORD_STATUS.pending.value
    ]})
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(false);

    const applicationRecordForm = useRef(null)

    useEffect(()=>{
        loadConfig()
    },[])

    useEffect(() => {
        if(filter.applicationId){
            let viewDate = props.viewDate;
            let tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
            let tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''
            setFilter({
                ...filter,
                dateFrom: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
                dateTo: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):''
            });
    
            setLoadTable(true)
        }
    }, [props.viewDate])

    useEffect(()=>{
        if(isLoadTable)
            loadPageApplicationRecords()
    },[isLoadTable])

    const loadConfig = () => {
        getConfig()
        .then(res => {
            if(!res.errorCode){
                if(!res.contactApplicationId){
                    showErrorNotify('No contact application setting in the configuration')
                }else{
                    let viewDate = props.viewDate;
                    let tmpStart = viewDate&&viewDate.length>0?viewDate[0]:''
                    let tmpEnd = viewDate&&viewDate.length>1?viewDate[1]:''
                    loadApplication(res.contactApplicationId)
                    setFilter({...filter, 
                        applicationId: res.contactApplicationId,
                        dateFrom: tmpStart&&moment(tmpStart).isValid()?moment(tmpStart).format('YYYY-MM-DD'):'',
                        dateTo: tmpEnd&&moment(tmpEnd).isValid()?moment(tmpEnd).format('YYYY-MM-DD'):''
                    })
                    setLoadTable(true)
                }
            }
        })
    }

    const loadApplication = (applicationId) => {
        getApplication(applicationId)
        .then(res=>{
            if(!res.errorCode){
                setApplication(res)
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const loadPageApplicationRecords = () => {
        getPageApplicationRecord(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblHeader = (
        <TableHeader title="Customer Request" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
        />
    );

    const clearFilter = () => {
        setFilter({...filter,
            name: '',
        })

        setLoadTable(true)
    }

    return (
        <div className="p-grid">

            <ApplicationRecordForm ref={applicationRecordForm}
                refreshTable={()=>loadPageApplicationRecords()}
            />

            <div className="p-col-12">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-text-bold">Filter By:</label>
                        <div className="p-grid p-fluid p-mt-1">
                            <div className="p-col-3 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon p-text-bold">Name:</span>
                                    <InputText value={filter.name} onChange={e => setFilter({...filter, name: e.target.value})} onKeyPress={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                                </div>
                            </div>
                            <div className="p-col-3">
                                <Button label="Find" icon="pi pi-filter" iconPos="left" style={{width: 'auto'}} onClick={() => setLoadTable(true)} />
                                <Button label="Clear" icon="pi pi-trash" className="p-button-secondary p-ml-2" iconPos="left" style={{width: 'auto'}}  onClick={() => clearFilter()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="ID" field="id" sortable  />
                    <Column header="Log by" body={rowData => rowData.user && rowData.user.name} />
                    {/* <Column header="Status" 
                        body={rowData => {
                            if(!rowData.status || rowData.status===APPLICATION_RECORD_STATUS.pending.value){
                                return APPLICATION_RECORD_STATUS.pending.label
                            }else if(rowData.status===APPLICATION_RECORD_STATUS.approved.value){
                                return APPLICATION_RECORD_STATUS.approved.label
                            }else if(rowData.status===APPLICATION_RECORD_STATUS.rejected.value){
                                return APPLICATION_RECORD_STATUS.rejected.label
                            }
                        }} 
                    /> */}
                    <Column header="Log on" field="date" sortable  body={(rowData) => rowData.date ? moment(rowData.date).format("MM/DD/YYYY hh:mm A") : 'No Date'}/>
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            return <Button label="View" icon="pi pi-search" className="p-button-constrast" onClick={() => applicationRecordForm.current.openForm(rowData.id)} />
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}