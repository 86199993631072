import React from 'react';
import classNames from "classnames";
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { showNotification } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { ColorPicker } from 'primereact/colorpicker';
import { getPageTaxonCategory, removeTaxonCategory, saveTaxonCategory} from './EventService';
import { Checkbox } from 'primereact/checkbox';

export class EventCategoryForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            formHeader: 'Event Category',
            visible: false,
            errors: {}
        };
    }

    popularFormData = (data) => {
        this.setState({
            data: {
                id: data?data.id:'',
                name: data&&data.name?data.name:'',
                description: data&&data.description?data.description:'',
                color: data&&data.color?data.color.replace(/[#]/, ''):'',
                secondaryColor: data&&data.secondaryColor?data.secondaryColor.replace(/[#]/, ''):'',
                imageUrl: data&&data.imageUrl?data.imageUrl:'',
                active:data&&data.active?data.active:false
            },
            visible: true,
            errors: {}
        })
    }


    handleSave = () => {
        this.setState({ errors: {} });

        saveTaxonCategory(this.state.data)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    loadPageEventCategories= () => {
        getPageTaxonCategory(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            this.setState({
                    dataList: res.content,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize,
                    total: res.totalElements
            });
        });
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.loadPageEventCategories();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.loadPageEventCategories();
        });
    }

    removeEventCategory = () => {
        removeTaxonCategory(this.state.data.id)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    actiontTemplate = (rowData, column) => {
        let items = [
            { label: 'Remove', icon: 'pi-md-trash', command: (e) => this.removeResource(rowData.id)}
        ]
        return (
            <SplitButton label="Edit" className="p-button-constrast p-l" model={items} onClick={() => this.popularFormData(rowData)}/>
        );
    }

    onHide = () => {
        this.setState({visible: false})
    }

    render() {
        return(
            <Sidebar visible={this.state.visible} className="p-sidebar-md" position="right" onHide={() => this.onHide()} blockScroll={true} style={{overflowY: 'auto'}}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-label">* Name</label>
                        <InputText value={this.state.data.name} onChange={(e) => this.setState({data: {...this.state.data, name: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.name}</div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Description</label>
                        <InputText value={this.state.data.description} onChange={(e) => this.setState({data: {...this.state.data, description: e.target.value}})} />
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Color</label>
                        <div className="p-inputgroup">
                            <InputText value={this.state.data.color} onChange={(e) => this.setState({data: {...this.state.data, color: e.target.value}})} />
                            <ColorPicker value={this.state.data.color} onChange={(e) => this.setState({data: {...this.state.data, color: e.value}})} />
                        </div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Secondary Color</label>
                        <div className="p-inputgroup">
                            <InputText value={this.state.data.secondaryColor} onChange={(e) => this.setState({data: {...this.state.data, secondaryColor: e.target.value}})} />
                            <ColorPicker value={this.state.data.secondaryColor} onChange={(e) => this.setState({data: {...this.state.data, secondaryColor: e.value}})} />
                        </div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Image Url</label>
                        <InputText value={this.state.data.imageUrl} onChange={(e) => this.setState({data: {...this.state.data, imageUrl: e.target.value}})} />
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Active ?</label>
                        <Checkbox checked={this.state.data.active} onChange={() => this.setState({data: {...this.state.data, active: !this.state.data.active}})} />
                    </div>
                </div>
                <div className="p-grid p-margin-top-30 p-line-to">
                    {this.state.data.id &&
                        <div className="p-col-12 p-md-4">
                            <Button label="Remove" icon="pi-md-trash" className="p-button-danger" onClick={() => this.removeEventCategory()}/>
                        </div>
                    }
                    <div className={classNames("p-col-12 p-r", {"p-md-8": this.state.data.id})}>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.onHide()}/>
                        <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSave()}/>
                    </div>
                </div>
            </Sidebar>
        )
    }
}