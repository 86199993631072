import React, {Component} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ProgramServices } from './ProgramServices';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AsyncSelect from 'react-select/async';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';
import { SelectButton } from "primereact/selectbutton";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export default class ProgramCopy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                fromCampaignId: '',
                toCampaignId: '',
                fromProgramId: '',
                toProgramId: "",
                date: moment(new Date()).format("YYYY-MM-DD"),
                dateValue: moment(new Date(), "MM-DD-YYYY").toDate(),
                toProgramOption: ''
            },
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            campaigns: [],
            activeCampaigns: [],
            errors: {}
        }
        this.programServices = new ProgramServices();
        this.campaignServices = new CampaignServices();
    }

    componentDidMount(){
        this.loadAllCampaigns();
    }

    handleCopy = () => {
        this.setState({ errors: {} });
        showloading();
        this.programServices.copyProgram(this.state.data)
        .then(res => {
            stoploading();
            if(!res.errorCode){
                if(res.id === this.state.data.fromProgramId){
                    this.getPageCourses();
                }
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode === 400)
                    this.setState({errors: res.errorObj});
            }
        })
    }

    loadAllCampaigns = () => {
        const inactive = false
        const active = true
        this.campaignServices.getListCampaign(inactive).then(res => { this.setState({ campaigns: res }) });
        this.campaignServices.getListCampaign(active).then(res => { this.setState({ activeCampaigns: res }) });

    }

    getPageCourses = () => {
        if(this.state.data.fromProgramId && this.state.data.fromCampaignId){
            let filter={campaignId: this.state.data.fromCampaignId}
            this.programServices.getPageProgramCourses(this.state.data.fromProgramId, filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
            .then(res => {
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
        }else{
            this.setState({
                dataList: [],
                total: 0,
                loading: false,
                page: 0,
                rows: 10
            });
        }
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.getPageCourses();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => {
            this.getPageCourses();
        });
    }

    onDateChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                date: e.value ? moment(e.value).format('YYYY-MM-DD') : '',
                dateValue: e.value
            },
        });
    }

    onChangeFromCampaign = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                fromCampaignId: e.value,
                toCampaignId: this.state.data.toCampaignId ? this.state.data.toCampaignId : e.value
            }
        }, () => {
            if(this.state.data.fromCampaignId && this.state.data.fromProgramId){
                this.getPageCourses();
            }
        })
    }

    onChangeFromProgram = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                fromProgramId: e ? e.value : '',
            },
            fromProgramSelection: e
        }, () => {
            this.getPageCourses();
        })
    }

    onChangeToCampaign = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                toCampaignId: e.value,
            }
        })
    }

    onChangeToProgram = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                toProgramId: e.value
            }
        })
    }

    handleKeyUp = () => {
        if(this.state.data.fromCampaignId && this.state.data.fromProgramId){
            let self = this;
            setTimeout(() => {
                self.getPageCourses();                    
            }, 1000);
        }
    }

    loadProgramOptions = (searchTerm, callback) => {
        this.programServices.getListAllPrograms(searchTerm)
        .then(res => { 
            callback (res)
        })
    }

    render(){
        const optionButtons = [
            {label: 'To same program', value: ''},
            {label: 'Select a program', value: 'select'},
            {label: 'Create Program', value: 'new'}
        ];

        return(
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Program Copy</BreadcrumbsItem>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Source">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-4">
                                                            <label>Campaign</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <Dropdown value={this.state.data.fromCampaignId} options={this.state.campaigns} onChange={(e) => this.onChangeFromCampaign(e)} />
                                                            <div className="p-form-error">{this.state.errors.fromCampaign}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-4">
                                                            <label>Program</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <AsyncSelect 
                                                                loadOptions={this.loadProgramOptions} onChange={(e) => this.onChangeFromProgram(e)}
                                                                placeholder='Type your search' isClearable
                                                                value={this.state.fromProgramSelection}
                                                            />
                                                            <div className="p-form-error">{this.state.errors.fromProgram}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Destination">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-4">
                                                            <label>Campaign</label>
                                                        </div>
                                                        <div className="p-col-12 p-md-8">
                                                            <Dropdown value={this.state.data.toCampaignId} options={this.state.activeCampaigns} onChange={(e) => this.onChangeToCampaign(e)} />
                                                            <div className="p-form-error">{this.state.errors.toCampaign}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                    <div className="p-col-12 p-md-4"></div>
                                                        <div className="p-col-12 p-md-8">
                                                            <SelectButton value={this.state.data.toProgramOption} options={optionButtons} onChange={(e) => this.setState({data: {...this.state.data, toProgramOption: e.value}})} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12">
                                                    {this.state.data.toProgramOption ? 
                                                        this.state.data.toProgramOption === 'select' ?
                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>Targeted Program</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-8">
                                                                    <AsyncSelect 
                                                                        loadOptions={this.loadProgramOptions} onChange={(e) => this.setState({data: {...this.state.data, toProgramId: e ? e.value : ''}, toProgramSelection: e})}
                                                                        placeholder='Type your search' isClearable
                                                                        value={this.state.toProgramSelection}
                                                                    />
                                                                    <div className="p-form-error">{this.state.errors.toProgram}</div>
                                                                </div>
                                                            </div>
                                                            : <div className="p-grid">
                                                                <div className="p-col-12 p-md-4">
                                                                    <label>New Program Name</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-8">
                                                                    <InputText value={this.state.data.toProgramName} onChange={(e) => this.setState({data: {...this.state.data, toProgramName: e.target.value}})} />
                                                                    <div className="p-form-error">{this.state.errors.toProgramName}</div>
                                                                </div>
                                                            </div>
                                                        : ''
                                                    }
                                                </div>
                                                <div className="p-col-12">
                                                    <div className="p-grid">
                                                        <label className="p-col-12 p-md-4">
                                                            <label>New Start Date</label>
                                                        </label>
                                                        <div className="p-col-12 p-md-8">
                                                            <MaskedCalendar value={this.state.data.dateValue} showIcon={true} dateFormat="mm-dd-yy" onChange={(e) => this.onDateChange(e)} monthNavigator={true} yearNavigator={true} yearRange="1900:2050" />
                                                            <div className="p-form-error">{this.state.errors.date}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12" style={{"textAlign": "center"}}>
                                            <Button icon="pi pi-clone" iconPos="left" label="Copy" disabled={this.state.dataList.length>0 ? "" : 'disabled'} onClick={this.handleCopy} />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        </div> 
                        
                        <DataTable className="p-datatable-scp" lazy={true} paginator={true} value={this.state.dataList}
                            header="Course Preview"
                            first={this.state.page * this.state.rows}
                            rows={this.state.rows} totalRecords={this.state.total}
                            onPage={this.onPage} onSort={this.onSort}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                            selectionMode="single" responsive={true}
                        >
                            <Column field="code" header="Course" sortable={true}/>
                            <Column field="name" header="Name" sortable={true} />
                            <Column field="startDate" header="Date" sortable={true} style={{width:'15em'}}
                                body={(rowData) => {
                                    let startDate = moment(moment(rowData.startDate, moment.HTML5_FMT.DATE).toDate()).format('MM-DD-YYYY');
                                    let endDate = moment(moment(rowData.endDate, moment.HTML5_FMT.DATE).toDate()).format('MM-DD-YYYY');
                                    return <div>{startDate !== endDate?startDate + ' - ' + endDate:startDate}</div>;
                                }}
                            />
                            <Column field="startTime" header="Time" sortable={true} style={{width:'12em'}}
                                body={(rowData) => {
                                    let startTime = moment(moment(rowData.startTime, moment.HTML5_FMT.TIME).toDate()).format('hh:mm A');
                                    let endTime = moment(moment(rowData.endTime, moment.HTML5_FMT.TIME).toDate()).format('hh:mm A');
                                    return <div>{startTime} - {endTime}</div>;
                                }}
                            />
                            <Column style={{textAlign:'center', width:'6em'}}
                                body={(rowData) => {
                                    return (
                                        <Link to={`/programs/${rowData.fromProgramId}/courses/${rowData.id}`} className="p-button p-component p-button-warning p-button-icon-only">
                                            <span className="pi pi-search p-c p-button-icon-left"></span><span className="p-button-text p-c">p-btn</span>
                                        </Link>
                                    );
                                        
                                }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}