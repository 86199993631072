import React, {Component} from "react";
import { UserServices } from "./UserServices";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { showNotification } from '../../core/service/NotificationService';
import AsyncSelect  from 'react-select/async';
import { USER_RELATIONSHIP_TYPES } from "../../constants";
import { Sidebar } from "primereact/sidebar";
import SelectOption from "../../core/components/contact/SelectionOption.js";
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

const noneContact = 'noneContact';
const existContact = 'existContact';

export class AddRelationForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            contactB: {},
            formHeader: '',
            relation:{
                userAFunction: []
            },
            lstUsers: [],
            errors: {}
        }
        this.userServices = new UserServices();
    }

    popularFormData = (contactA, contactB, data) => {
        let tmpRelation = data ? data : {};
        if(data && !data.userB){
            tmpRelation.relatedContact = noneContact;
        }else{
            tmpRelation.relatedContact = existContact;
        }

        tmpRelation.status = data ? data.status : 'current';
        tmpRelation.userAFunction = data ? data.userAFunction : []

        if(contactA) tmpRelation.userA = contactA.id;
        if(contactB) tmpRelation.userB = contactB.id;
        
        this.setState({
            contactB: contactB,
            formHeader: !data?'New Relationship':'Edit Relationship',
            visible: true,
            relation: tmpRelation,
            phoneValue: data && data.phoneObj ? ((data.phoneObj.countryCode.toLowerCase()!==this.state.phoneCodeValue ? ('+' + data.phoneObj.dialCode) : '') + data.phoneObj.phoneNumber) : (this.state.phoneCodeValue!=='us' ? '+1' : ''),
            phoneCodeValue: data && data.phoneObj ? data.phoneObj.countryCode.toLowerCase() : 'us',
            secondaryPhoneValue: data && data.secondaryPhoneObj ? ((data.secondaryPhoneObj.countryCode.toLowerCase()!==this.state.secondaryPhoneCodeValue ? ('+' + data.secondaryPhoneObj.dialCode) : '') + data.secondaryPhoneObj.phoneNumber) : (this.state.secondaryPhoneCodeValue && this.state.secondaryPhoneCodeValue!=='us' ? '+1' : ''),
            secondaryPhoneCodeValue: data && data.secondaryPhoneObj ? data.secondaryPhoneObj.countryCode.toLowerCase() : 'us',
            errors:{}
        });
    }

    handleSaveRelationship = () => {
        this.userServices.saveRelation(this.state.relation)
        .then(res => {
            if(!res.errorCode){
                if(this.props.reloadTable)
                    this.props.reloadTable();
                if(this.props.reloadData)
                    this.props.reloadData(res)
                this.onHide();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        });
    }

    buildPhoneObj = (status, value, countryData) => {
        let phoneValue = "";
        if(value !== ""){
            if(value.indexOf("+"+countryData.dialCode) !== -1){
                phoneValue = value.substr(("+"+countryData.dialCode).length);
            }else{
                phoneValue = value;
            }
        }
        let phoneObj = {
            phoneNumber: phoneValue,
            dialCode: countryData.dialCode,
            countryCode: countryData.iso2.toUpperCase(),
            phoneValid: status
        }
        
        return phoneObj;
    }

    onChangePhone = (status, value, countryData, object) => {
        let phoneObj = this.buildPhoneObj(status, value, countryData);
        switch(object){
            case 'primaryPhone':
                this.setState({
                    relation: {
                        ...this.state.relation,
                        phone: phoneObj.phoneNumber? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber: ''
                    },
                    phoneValue: phoneObj.phoneNumber,
                    phoneCodeValue: phoneObj.countryCode.toLowerCase()
                });
            break;
            case 'secondaryPhone':
                this.setState({
                    relation: {
                        ...this.state.relation,
                        secondaryPhone: phoneObj.phoneNumber? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber: ''
                    },
                    secondaryPhoneValue: phoneObj.phoneNumber,
                    secondaryPhoneCodeValue: phoneObj.countryCode.toLowerCase()
                });
            break;
            default: break;
        }
    }

    onRelatedContactChange = (e) => {
        this.setState({ relation: { ...this.state.relation, 
            relatedContact: e.value,
            userB: e.value === noneContact && !this.state.relation.id ? '' : this.state.relation.userB
        }})
    }

    onCheckboxKindOfRelationshipChange = (e) => {
        let tmpArr = [...this.state.relation.userAFunction];
        if(e.checked){
            if(!tmpArr.includes(e.value)) tmpArr.push(e.value);
        }else{
            tmpArr.splice(tmpArr.indexOf(e.value),1);
        }
        this.setState({
            relation: {...this.state.relation,
                userAFunction: tmpArr
            }
        })
    }

    onHide = () => {
        this.setState({ visible: false})
    }

    loadUserOptions = (searchTerm, callback) => {
        this.userServices.getAllUsers(searchTerm, 'relationship', this.props.uId)
        .then(res => { 
            callback (res) 
        });
    }

    render(){
        const lstStatuss = [
            {label: "Current", value:"current"},
            {label: "Former", value:"former"}
        ];

        const footer = (
            <React.Fragment>
                <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveRelationship()}/>
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e) => this.onHide()}/>
            </React.Fragment>
        );

        const Option = props => SelectOption(props)

        return (
            <Sidebar dismissable={false} visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={(e) => this.onHide()}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid form-group">
                    {!this.state.relation.id ? 
                        <div className="p-col-12">
                            <label className="p-label">Related contact from</label>
                            <React.Fragment>
                                <RadioButton inputId="rbExistContact" value={existContact} onChange={(e) => this.onRelatedContactChange(e)} checked={this.state.relation.relatedContact === existContact} />
                                <label htmlFor="rbExistContact" className="p-radiobutton-label" >Existing Contact</label>
                                <RadioButton inputId="rbNoneContact" value={noneContact} className="p-margin-left-20"  onChange={(e) => this.onRelatedContactChange(e)} checked={this.state.relation.relatedContact === noneContact} />
                                <label htmlFor="rbNoneContact" className="p-radiobutton-label" >None Contact</label>        
                            </React.Fragment>
                        </div>
                        : ''
                    }
                    {this.state.relation.relatedContact === existContact ?
                        <div className="p-md-6 p-col-12">
                            <label className="p-label">{!this.state.relation.id ? '*' : ''} Related Contact</label>
                            {!this.state.relation.id ?
                                <React.Fragment>
                                    <AsyncSelect
                                        loadOptions={this.loadUserOptions}
                                        value={this.state.relation.userB ? this.state.lstUsers.find(x => x.value===this.state.relation.userB) : ''}
                                        onChange={(e) => this.setState({relation: {...this.state.relation, userB: e.value}})} 
                                        placeholder='Type your search'
                                        components={{ Option }}
                                    />
                                    <div className="p-form-error">{this.state.errors.userB}</div>
                                </React.Fragment>
                            : this.state.relation.nameUserB
                            }
                        </div>
                        : ''
                    }
                    
                    <div className={"p-col-12" + (this.state.relation.relatedContact === existContact ? ' p-md-6' : '')}>
                        <label className="p-label">* Relationship</label>
                        <Dropdown value={this.state.relation.relationOfB} options={USER_RELATIONSHIP_TYPES} onChange={(e) => this.setState({relation: {...this.state.relation, relationOfB: e.value}})} style={{width: "100%", marginTop: 13}} placeholder="Select relation type"/>
                        <div className="p-form-error">{this.state.errors.relationOfB}</div>
                    </div>
                    {this.state.relation.relatedContact === noneContact ?
                        <div className="p-col-12">
                            <Fieldset legend="Contact Information">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* First Name</label>
                                        <InputText value={this.state.relation.firstName} onChange={(e)=> this.setState({relation: {...this.state.relation, firstName:e.target.value}})}/>
                                        <span className="p-form-error">{this.state.errors.firstName}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Last Name</label>
                                        <InputText value={this.state.relation.lastName} onChange={(e)=> this.setState({relation: {...this.state.relation, lastName:e.target.value}})}/>
                                        <span className="p-form-error">{this.state.errors.lastName}</span>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Email</label>
                                        <InputText value={this.state.relation.email} onChange={(e)=> this.setState({relation: {...this.state.relation, email:e.target.value}})}  style={{ width: "100%" }} />
                                        <span className="p-form-error">{this.state.errors.email}</span>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Primary Phone </label>
                                        <IntlTelInput autoPlaceholder={false} format={true} value={this.state.phoneValue} onPhoneNumberChange={(s,v,c,n) => this.onChangePhone(s,v,c, 'primaryPhone')} onSelectFlag={(v,c,n,s) => this.onChangePhone(s,v,c, 'primaryPhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{ width: "100%" }} />
                                        <span className="p-form-error">{this.state.errors.phone}</span>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">Secondary Phone </label>
                                        <IntlTelInput autoPlaceholder={false} format={true} value={this.state.secondaryPhoneValue} onPhoneNumberChange={(s,v,c,n) => this.onChangePhone(s,v,c, 'secondaryPhone')} onSelectFlag={(v,c,n,s) => this.onChangePhone(s,v,c, 'secondaryPhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{ width: "100%" }} />
                                        <span className="p-form-error">{this.state.errors.secondaryPhone}</span>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        : ''
                    }
                    {this.state.relation.relatedContact === existContact ?
                        <div className="p-md-6 p-col-12">
                        <label className="p-label">* Reciprocal Relationship</label>
                            <Dropdown value={this.state.relation.relationOfA} options={USER_RELATIONSHIP_TYPES} onChange={(e) => this.setState({relation: {...this.state.relation, relationOfA: e.value}})} style={{width: "100%"}} placeholder="Select relation type"/>
                            <div className="p-form-error">{this.state.errors.relationOfA}</div>
                        </div>
                        : ''
                    }
                    <div className={"p-col-12" + (this.state.relation.relatedContact === existContact ? ' p-md-6' : '')}>
                        <label className="p-label">Status</label>
                        <Dropdown value={this.state.relation.status} options={lstStatuss} onChange={(e) => this.setState({relation: {...this.state.relation, status: e.value}})} style={{width: "100%"}} />
                    </div>
                    <div className="p-md-6 p-col-12">
                        <Checkbox inputId="cbEC" value="emergency" onChange={(e) => this.onCheckboxKindOfRelationshipChange(e)} checked={this.state.relation.userAFunction.includes('emergency')} />
                        <label htmlFor="cbEC" className="p-checkbox-label">As Emergency Contact</label>
                    </div>
                    <div className="p-md-6 p-col-12">
                        <Checkbox inputId="cbPP" value="pickup" onChange={(e) => this.onCheckboxKindOfRelationshipChange(e)} checked={this.state.relation.userAFunction.includes('pickup')} />
                        <label htmlFor="cbPP" className="p-checkbox-label">As Pickup Person</label>
                    </div>
                    <div className="p-md-6 p-col-12">
                        <Checkbox inputId="cbPC" value="payor" onChange={(e) => this.onCheckboxKindOfRelationshipChange(e)} checked={this.state.relation.userAFunction.includes('payor')} />
                        <label htmlFor="cbPC" className="p-checkbox-label">As Payor</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Description</label>
                        <InputTextarea rows={5} value={this.state.relation.description} onChange={(e) => this.setState({relation: {...this.state.relation, description: e.target.value}})} autoResize={true} />
                    </div>
                </div>
                
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        {footer}
                    </div>
                </div>
            </Sidebar>
        );
    }
}