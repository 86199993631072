import React, { useContext } from "react";
import { Button } from 'primereact/button';
import { PRODUCT_TYPE, PRODUCT_CLASSIFICATION, PRICE_APPLY_TYPE, PRODUCT_PRICE_UNIT } from "../../constants";
import { getCurrencySymbol } from "../../core/service/CommonService";
import { OrderContext } from "./OrderContext";

export const ProductItem = (props) => {
    const orderContext = useContext(OrderContext)
    const currencySymbol = getCurrencySymbol(orderContext.currency)
    const product = props.data;

    const renderProductPrice = (product) => {
        if(!product.free){
            if((([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(product.classification) && !product.pricedBySelected) || 
                (product.classification===PRODUCT_CLASSIFICATION.variable.value && !product.pricedByVariant) || product.classification===PRODUCT_CLASSIFICATION.simple.value) && product.prices.length>0){
                return (
                    <div className="product-price">
                        {product.prices.map((p, i) => {
                            return (
                                p.applyFor===PRICE_APPLY_TYPE.normal.value && 
                                <React.Fragment key={i}>
                                    {i>0&&<React.Fragment>, </React.Fragment>}
                                    <sup>{currencySymbol}</sup>{p.price}{product.type===PRODUCT_TYPE.rental.value&&<React.Fragment>/{p.quantity>1&&(p.quantity + ' ')}{PRODUCT_PRICE_UNIT[p.unit].label}</React.Fragment>}
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            }else if(product.classification===PRODUCT_CLASSIFICATION.variable.value && product.pricedByVariant && product.variants.length>0 && product.variants[0].prices.length>0){
                return (
                    <div className="product-price">
                        {product.variants[0].prices.map((p, i) => {
                            return (
                                p.applyFor===PRICE_APPLY_TYPE.normal.value && 
                                <React.Fragment key={i}>
                                    {i>0&&<React.Fragment>, </React.Fragment>}
                                    <sup>{currencySymbol}</sup>{p.price}{product.type===PRODUCT_TYPE.rental.value&&<React.Fragment>/{p.quantity>1&&(p.quantity + ' ')}{PRODUCT_PRICE_UNIT[p.unit].label}</React.Fragment>}
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            }else if([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(product.classification) && product.pricedBySelected){
                if(product.associatedGroups.length>0){
                    const tmpProd = product.associatedGroups[0].associatedProducts.length>0?product.associatedGroups[0].associatedProducts[0].associatedProduct:null;

                    if(tmpProd){
                        return(
                            renderProductPrice(tmpProd)
                        )
                    }
                }
            }
        }
        
        return <div className="product-price">Free</div>;
    }

    return(
        <div className="card product-item p-d-flex p-flex-column">
            <div className="product-title">{product.name}</div>
            {renderProductPrice(product)}
            <div className="p-d-flex p-justify-between p-mb-2">
                {product.branchName && <div><i className="fas fa-home"></i> {product.branchName}</div>}
                {product.locationName && <div><i className="fas fa-map-marker-alt"></i> {product.locationName}</div>}
            </div>
            <div style={{flex: '1 1 auto'}}>
                <div className="product-desc">{product.description}</div>
            </div>
            <div className="p-text-center">
                <Button label="Add" icon="pi pi-shopping-cart" onClick={() => orderContext.addOrUpdateCartItem(product.id)}></Button>
            </div>
        </div>
    )
}