import React from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Fieldset } from 'primereact/fieldset';
import moment from 'moment';
import { APPLICATION_COMPONENTT_TYPE, APPLICATION_RECORD_STATUS, APPLICATION_TYPE } from '../../constants';
import { updateStatusApplicationRecord, getApplicationRecord, answerApplicationRecord } from './ApplicationService';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton  } from 'primereact/selectbutton';

const REPLY_ACTION={
    reply: {label: 'Reply',value: 'reply'},
    reject: {label: 'Reject',value: 'reject'}
}
export default class ApplicationRecordForm extends React.Component {
    constructor() {
        super();

        this.state = {
            participant: {},
            assetSchedule: {},
            application: {},
            record: {},
            mapCustomFields: {},
            mapWaiverFields: {},
            answer: {},
            formHeader: "Application Record",
            visible: false,
            errors: {}
        }
    }

    openForm = (id) => {
        getApplicationRecord(id)
        .then(res=>{
            if(!res.errorCode){
                let tmpApplication = res.application?res.application:{}
                let tmpRecord = res.applicationRecord?res.applicationRecord:{}
                let tmpAnswer = {id: tmpRecord.id}

                this.setState({
                    application: tmpApplication,
                    record: res.applicationRecord?res.applicationRecord:{},
                    mapCustomFields: res.mapCustomFields?res.mapCustomFields:{},
                    mapWaiverFields: res.mapWaiverFields?res.mapWaiverFields:{},
                    answer: tmpAnswer,
                    visible: true
                })
            }else{

            }
        })
    }

    handleApproveOrReject = (status) =>{
        updateStatusApplicationRecord(this.state.record.id, status)
        .then(res=>{
            if(!res.errorCode){
                if(this.props.refreshTable){
                    this.props.refreshTable()
                }
                this.closeForm()
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    handleAnswer = () =>{
        let tmpData = {...this.state.answer}
        tmpData.type = !this.state.replyAction||this.state.replyAction===REPLY_ACTION.reply.value?APPLICATION_RECORD_STATUS.approved.value:APPLICATION_RECORD_STATUS.rejected.value

        answerApplicationRecord(tmpData)
        .then(res=>{
            if(!res.errorCode){
                if(this.props.refreshTable){
                    this.props.refreshTable()
                }
                this.closeForm()
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj})
            }
        })
    }
    
    closeForm = () => {
        this.setState({
            visible: false,
            data: {},
            application: {},
            participant: {},
            fieldValues:[],
            errors: {}
        })
    }

    render() {
        let app = this.state.application
        let record = this.state.record
        let tmpPersonalInfos = record.personalInfo
        let mapCustomFields = this.state.mapCustomFields
        let mapWaiverFields = this.state.mapWaiverFields
        let participant = record.participant?record.participant:{}
        let assetSchedule = record.assetSchedule?record.assetSchedule:{}
        let tmpAnswer = this.state.answer
        let errors = this.state.errors

        return(
            <Sidebar  dismissable={false} visible={this.state.visible} style={{ overflowY: "auto", width: "40em" }} position="right" blockScroll={true} onHide={this.closeForm} >
                <h2>{this.state.formHeader}</h2>
                {(app.type===APPLICATION_TYPE.approval.value || app.type===APPLICATION_TYPE.answer.value) &&
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            {(!record.status || record.status===APPLICATION_RECORD_STATUS.pending.value) && <Message severity="info" text={app.type===APPLICATION_TYPE.answer.value?"New":APPLICATION_RECORD_STATUS.pending.label}/>}
                            {record.status===APPLICATION_RECORD_STATUS.approved.value && <Message severity="success" text={app.type===APPLICATION_TYPE.answer.value?"Answered":APPLICATION_RECORD_STATUS.approved.value}/>}
                            {record.status===APPLICATION_RECORD_STATUS.rejected.value && <Message severity="warn" text={APPLICATION_RECORD_STATUS.rejected.label} style={{width: 'auto'}} />}
                        </div>
                    </div>
                }
                
                <Fieldset legend="Information">
                    <div className="p-grid">
                        {/* <div className="p-col-12">
                            <label className="p-label">Code: {record.code}</label>
                        </div> */}
                        <div className="p-col-12">
                            <label className="p-label">Application: {app.name}</label>
                        </div>
                        {assetSchedule && assetSchedule.id &&
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">Asset: {assetSchedule.asset && assetSchedule.asset.name}</label>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Date: {moment(assetSchedule.start).format('MM/DD/YYYY hh:mm A')} - {moment(assetSchedule.end).format('MM/DD/YYYY hh:mm A')}</label>
                            </div>
                        </React.Fragment>
                        }
                        {participant && participant.id &&
                            <div className="p-col-12">
                                <label className="p-label">Participant: {participant.user&&participant.user.name}</label>
                            </div>
                        }
                        {(app.type===APPLICATION_TYPE.approval.value) &&
                            <div className="p-col-12">
                                <label className="p-label">Status: {record.status&&APPLICATION_RECORD_STATUS[record.status]?APPLICATION_RECORD_STATUS[record.status].label:APPLICATION_RECORD_STATUS.pending.label}</label>
                            </div>
                        }
                        <div className="p-col-12">
                            <label className="p-label">Log on : {record.date&&moment(record.date).format('MM/DD/YYYY hh:mm A')}</label>
                        </div>
                    </div>
                </Fieldset>
                <Fieldset legend="Record">
                    <div className="p-grid">
                        {(()=>{
                            if(app.type===APPLICATION_TYPE.approval.value){
                                let htmlButton = []
                                if(record.status!==APPLICATION_RECORD_STATUS.approved.value){
                                    htmlButton.push(<Button label="Approve" className="p-button-info" onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.approved.value)} style={{width: 'auto'}} />)
                                }
                                if(record.status!==APPLICATION_RECORD_STATUS.rejected.value){
                                    htmlButton.push(<Button label="Reject" className="p-button-warning" onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.rejected.value)} style={{width: 'auto'}} />)
                                }
                                return <React.Fragment>
                                    <div className="p-col-12 p-r">{htmlButton}</div>
                                    <div className="p-col-12"><hr /></div>
                                </React.Fragment>
                            }
                        })()}
                        <div className="p-col-12">
                            {app.contents && app.contents.map((content, idxContent) => {
                                return content.components && content.components.map((component, idxComponent) => {
                                    if(component.type===APPLICATION_COMPONENTT_TYPE.input_field.value){
                                        let tmpPersonalSet = component.personalSet
                                        let tmpPersonalField = tmpPersonalSet&&tmpPersonalSet.personalField?tmpPersonalSet.personalField:{}
                                        let tmpCustomSet = component.customSet
                                        let tmpWaiverSet = component.waiverSet
                                        let tmpGuadianPersonalSet = tmpWaiverSet&&tmpWaiverSet.requiredGuardian?tmpWaiverSet.guardianPersonalSet:{}
                                        let tmpGuardianPersonalField = tmpGuadianPersonalSet&&tmpGuadianPersonalSet.personalField?tmpGuadianPersonalSet.personalField:{}

                                        let tmpPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                            : {}
                                        let tmpCustomFieldValue = tmpCustomSet&&mapCustomFields[tmpCustomSet.id]?mapCustomFields[tmpCustomSet.id]:{}
                                        let tmpWaiverFieldValue = tmpWaiverSet&&mapWaiverFields[tmpWaiverSet.id]?mapWaiverFields[tmpWaiverSet.id]:{}
                                        let tmpGuardianPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
                                            : {}

                                        return(
                                            <div key={app.id+'_'+'_'+idxContent} className="p-grid">
                                                {tmpPersonalSet &&
                                                    <React.Fragment>
                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12"><span className="p-w-bold">{tmpPersonalSet&&tmpPersonalSet.label}</span></div>
                                                                <div className="p-col-12">
                                                                    {tmpPersonalValue && Object.keys(tmpPersonalValue).map(key =>{
                                                                        let tmpLabel
                                                                        let tmpValue
                                                                        if(key==='title'){ 
                                                                            tmpLabel = tmpPersonalField['titleNameLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='firstName'){ 
                                                                            tmpLabel = tmpPersonalField['firstNameLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='middleName'){ 
                                                                            tmpLabel = tmpPersonalField['middleNameLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='lastName'){ 
                                                                            tmpLabel = tmpPersonalField['lastNameLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='email'){
                                                                            tmpLabel = tmpPersonalField['emailLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='gender') {
                                                                            tmpLabel = tmpPersonalField['genderLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='birthday'){
                                                                            tmpLabel = tmpPersonalField['birthdayLabel']
                                                                            tmpValue = tmpPersonalValue[key]&&moment(tmpPersonalValue[key]).format('MM/DD/YYYYY')
                                                                        }
                                                                        if(key==='phone'){ 
                                                                            tmpLabel = tmpPersonalField['phoneLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='passportNRIC'){ 
                                                                            tmpLabel = tmpPersonalField['passportNRICLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(key==='nationalityCode'){ 
                                                                            tmpLabel = tmpPersonalField['nationalityLabel']
                                                                            tmpValue = tmpPersonalValue['nationalityName']
                                                                        }
                                                                        if(key==='registerType'){ 
                                                                            tmpLabel = tmpPersonalField['registerTypeLabel']
                                                                            tmpValue = tmpPersonalValue[key]
                                                                        }
                                                                        if(tmpLabel && tmpValue)
                                                                            return (
                                                                                <div className="p-grid">
                                                                                    <div className="p-col-6">{tmpLabel}: </div>
                                                                                    <div className="p-col-6">{tmpValue}</div>
                                                                                </div>
                                                                            )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {tmpPersonalField.useAddress &&
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                <div className="p-col-12"><span className="p-w-bold">Address</span></div>
                                                                    <div className="p-col-12">
                                                                        {tmpPersonalValue && Object.keys(tmpPersonalValue).map(key =>{
                                                                            let tmpLabel
                                                                            let tmpValue
                                                                            if(key==='address1'){ 
                                                                                tmpLabel = 'Address 1'
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(key==='address2'){ 
                                                                                tmpLabel = 'Address 2'
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(key==='city'){ 
                                                                                tmpLabel = 'City'
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(key==='zipCode'){ 
                                                                                tmpLabel = 'Zip Code'
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(key==='countryCode'){ 
                                                                                tmpLabel = 'Country'
                                                                                tmpValue = tmpPersonalValue['countryName']
                                                                            }
                                                                            if(key==='stateCode'){ 
                                                                                tmpLabel = 'State/Province'
                                                                                tmpValue = tmpPersonalValue['stateName']
                                                                            }
                                                                            if(tmpLabel && tmpValue)
                                                                                return (
                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-6">{tmpLabel}: </div>
                                                                                        <div className="p-col-6">{tmpValue}</div>
                                                                                    </div>
                                                                                )
                                                                        })}
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {tmpPersonalField.useEmerContact &&
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                <div className="p-col-12"><span className="p-w-bold">Emergency</span></div>
                                                                    <div className="p-col-12">
                                                                        {tmpPersonalValue && Object.keys(tmpPersonalValue).map(key =>{
                                                                            let tmpLabel
                                                                            let tmpValue
                                                                            if(key==='emeContactName') {
                                                                                tmpLabel = tmpPersonalField['emerContactNameLabel']
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(key==='emeContactPhone') {
                                                                                tmpLabel = tmpPersonalField['emerContactPhoneLabel']
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(key==='emeContactEmail') {
                                                                                tmpLabel = tmpPersonalField['emerContactEmailLabel']
                                                                                tmpValue = tmpPersonalValue[key]
                                                                            }
                                                                            if(tmpLabel && tmpValue)
                                                                                return (
                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-6">{tmpLabel}: </div>
                                                                                        <div className="p-col-6">{tmpValue}</div>
                                                                                    </div>
                                                                                )
                                                                        })}
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {tmpCustomSet&&
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12"><span className="p-w-bold">{tmpCustomSet&&tmpCustomSet.label}</span></div>
                                                            <div className="p-col-12">
                                                                {tmpCustomFieldValue && Object.values(tmpCustomFieldValue).map(item => {
                                                                    return (
                                                                        <div className="p-grid">
                                                                            <div className="p-col-6">{item.fieldLabel}: </div>
                                                                            <div className="p-col-6">{item.value}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {tmpWaiverSet&&
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12"><span className="p-w-bold">{tmpWaiverSet&&tmpWaiverSet.label}</span></div>
                                                            <div className="p-col-12">
                                                                {tmpWaiverFieldValue && Object.values(tmpWaiverFieldValue).map(item => {
                                                                    let partAnswerObj = item&&item.storeType==='boolean'?JSON.parse(item.value):{}
                                                                    return (
                                                                        <div className="p-grid">
                                                                            <div className="p-col-6">{item.fieldLabel}: </div>
                                                                            <div className="p-col-6">{partAnswerObj.accepted}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {tmpWaiverSet && tmpWaiverSet.requiredGuardian && tmpGuardianPersonalValue && Object.keys(tmpGuardianPersonalValue).length>0 && 
                                                    <div className="p-col-12">
                                                        <div className="p-grid">
                                                            <div className="p-col-12"><span className="p-w-bold">{tmpGuadianPersonalSet&&tmpGuadianPersonalSet.label}</span></div>
                                                            <div className="p-col-12">
                                                                {Object.keys(tmpGuardianPersonalValue).map(key=>{
                                                                    let tmpLabel
                                                                    let tmpValue
                                                                    if(key==='firstName'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['firstNameLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='lastName'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['lastNameLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='email'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['EmailLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='gender') {
                                                                        tmpLabel = tmpGuardianPersonalField['genderLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='bithday'){
                                                                        tmpLabel = tmpGuardianPersonalField['birthdayLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]&&moment(tmpPersonalValue[key]).format('MM/DD/YYYYY')
                                                                    }
                                                                    if(key==='phone'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['phoneLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='passportNRIC'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['passportNRICLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='nationalityCode'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['nationalityLabel']
                                                                        tmpValue = tmpGuardianPersonalValue['nationalityName']
                                                                    }
                                                                    if(key==='registerType'){ 
                                                                        tmpLabel = tmpGuardianPersonalField['registerTypeLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='emeContactName') {
                                                                        tmpLabel = tmpGuardianPersonalField['emerContactNameLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='emeContactPhone') {
                                                                        tmpLabel = tmpGuardianPersonalField['emerContactPhoneLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(key==='emeContactEmail') {
                                                                        tmpLabel = tmpGuardianPersonalField['emerContactEmailLabel']
                                                                        tmpValue = tmpGuardianPersonalValue[key]
                                                                    }
                                                                    if(tmpLabel && tmpValue)
                                                                        return (
                                                                            <div className="p-grid">
                                                                                <div className="p-col-6">{tmpLabel}: </div>
                                                                                <div className="p-col-6">{tmpValue}</div>
                                                                            </div>
                                                                        )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                })
                            })}         
                        </div>
                    </div>
                </Fieldset>
                {(()=>{
                    let tmpPersonalField=null
                    app.contents && app.contents.map((content, idxContent) => {
                        content.components && content.components.map((component, idxComponent) => {
                            if(component.type===APPLICATION_COMPONENTT_TYPE.input_field.value){
                                let tmpPersonalSet = component.personalSet
                                tmpPersonalField = tmpPersonalSet?tmpPersonalSet.personalField:null
                            }
                        })
                    })

                    if(app.type===APPLICATION_TYPE.answer.value && tmpPersonalField && Object.keys(tmpPersonalField).length>0){
                        return <React.Fragment>
                                <Fieldset legend="Reply Actions">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <SelectButton value={this.state.replyAction?this.state.replyAction:REPLY_ACTION.reply.value} options={Object.values(REPLY_ACTION)} onChange={(e) => this.setState({replyAction: e.value?e.value:this.state.replyAction})}></SelectButton>
                                        </div>
                                        {(!this.state.replyAction || this.state.replyAction===REPLY_ACTION.reply.value) &&
                                            <React.Fragment>
                                                {!tmpPersonalField.useEmail&&
                                                    <div className="p-col-12">
                                                        <label className="p-lable">* Email</label>
                                                        <InputText value={tmpAnswer.email} keyfilter="email" onChange={e => this.setState({answer: {...tmpAnswer, email: e.target.value}})} />
                                                        <div className="p-form-error">{errors.email}</div>
                                                    </div>
                                                }
                                                <div className="p-col-12">
                                                    <label className="p-lable">* Content</label>
                                                    <InputTextarea rows={5} value={tmpAnswer.content} onChange={(e) => this.setState({answer: {...tmpAnswer, content: e.target.value}})} />
                                                    <div className="p-form-error">{errors.content}</div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {this.state.replyAction===REPLY_ACTION.reject.value&&
                                            <div className="p-col-12">
                                                <label className="p-lable">* Reason</label>
                                                <InputTextarea rows={5} value={tmpAnswer.content} onChange={(e) => this.setState({answer: {...tmpAnswer, content: e.target.value}})} />
                                                <div className="p-form-error">{errors.content}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className="p-grid p-margin-top-10">
                                        <div className="p-col-12 p-r">
                                            <Button label="Submit" onClick={() => this.handleAnswer()} />
                                        </div>
                                    </div>
                                </Fieldset>
                        </React.Fragment>
                    }
                })()}
                
                <div className="p-grid p-margin-top-30">
                    <div className={"p-col-12 p-r"}>
                        <Button label="Close" icon="pi-md-close" className="p-button-secondary" onClick={() => this.closeForm()}/>
                    </div>
                </div>
            </Sidebar>
        )
    }
}