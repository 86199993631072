import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {Link} from 'react-router-dom';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {TreeTable} from 'primereact/treetable';
import { Dropdown } from 'primereact/dropdown';
import { TABLE_PAGING_LENGTH } from '../../constants';
import {BudgetServices} from './BudgetServices';
import { BudgetForm } from './BudgetForm';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { showNotification } from '../../core/service/NotificationService';
import classNames from 'classnames';
import { SplitButton } from 'primereact/splitbutton';

export class Budgets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            budgetNodes: [],
            expandedKeys: [],
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
        };

        this.budgetServices = new BudgetServices();
    }

    componentDidMount() {
        showloading();
        this.getTreeBudgets();
    }

    getTreeBudgets = () => {
        this.budgetServices.getTreeBudgets()
        .then(res => {
            stoploading();
            this.setState({budgetNodes: res});
        });
    }

    // getPageBudgets = () => {
    //     this.budgetServices.getPageBudgets(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
    //         .then(res => {
    //             stoploading();
    //             this.setState({
    //                 dataList: res.content,
    //                 total: res.totalElements,
    //                 loading: false,
    //                 page: res.pageable.pageNumber,
    //                 rows: res.pageable.pageSize
    //             });
    //         });
    // }

    // actionTemplate = (rowData, column) => {
    //     return (
    //         <div>
    //             <Button icon="pi-md-close" tooltip="Remmove" className="p-button-danger" style={{'float':'right'}} onClick={() => this.removeBudget(rowData.id)}/>
    //             <Button icon="pi pi-pencil" tooltip="Edit" tooltipOptions={{position: 'top'}} className="p-button-warning" style={{'float':'right'}} onClick={() => this.frmBudget.openBudgetForm(rowData)}/>
    //         </div>
    //     );
    // }

    actionTemplate = (node, column) => {
        let dropActions = [
            {
                label: 'Edit fee assistance',
                icon: 'pi pi-pencil',
                command: (e) => {
                    this.frmBudget.openBudgetForm(node.data, node.data.parent);
                }
            }
        ];
        return (
            <React.Fragment>
                
                {/* <Button icon="pi-md-close" className="p-button-danger" style={{'float':'right'}} onClick={() => this.removeBudgetAssign(node.data.id)}/> */}
                {node.data.targetType?
                // <Button icon="pi pi-plus" tooltip="Add child fee assistance" tooltipOptions={{position: 'top'}} className="p-button-success" style={{ 'float': 'left' }} onClick={() => this.frmBudget.openBudgetForm(null, node.data)}/>
                <SplitButton label="Manage" className="p-button-constrast" model={dropActions} tooltip='Add child fee assistance' tooltipOptions={{position: "top"}} onClick={(e)=> this.frmBudget.openBudgetForm(null, node.data)}/>
                :
                <Button label="Edit" tooltip="Edit fee assistance" tooltipOptions={{position: 'top'}} style={{maxWidth: '6em'}} className="p-button-constrast" onClick={() => this.frmBudget.openBudgetForm(node.data, node.data.parent)}/>
                }
            </React.Fragment>
        );
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.getPageBudgets();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.getPageBudgets();
        });
    }

    onChangePageLength = (e) => {
        this.setState({
            rows: e.value,
            page: 0
        }, () => {
            this.getPageBudgets();
        });
    }

    removeBudget = (id) => {
        this.budgetServices.removeBudget(id).then(res => {
            if(!res.errorCode){
                this.getPageBudgets();
                showNotification('success', 'Success message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    budgetActiveTemplate = (node, column) => {
        return (
            <span className={classNames('status', {'active': node.data.active && node.data.inAvailable, 'inactive': !node.data.active || !node.data.inAvailable})}>{node.data.active && node.data.inAvailable?'Yes':'No'}</span>
        );
    }

    render(){
        let tblHeader = 
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button icon="pi pi-plus" label="New Fee Assistance" className="p-button-constrast" style={{ 'float': 'left' }} onClick={() => this.frmBudget.openBudgetForm(null, null)}/>
                {/* <Button icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: 'top'}} style={{'float':'right'}} onClick={() => this.getPageBudgets(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}/> */}
                {/* <div style={{ 'float': 'right' }}> */}
                    {/* Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChangePageLength(e)} style={{ minWidth: "0" }} /> */}
                    {/* <Button className="p-margin-left-10" icon="pi pi-refresh" onClick={() => this.getPagePrograms(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}/> */}
                {/* </div> */}
            </div>;

        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Fee Assistance</BreadcrumbsItem>

                <BudgetForm ref={(el) => this.frmBudget = el}
                    reloadTable={() => this.getTreeBudgets()}/>
                
                <div className="p-col-12">
                    <div className="card card-w-title treetableBudget">
                        <h1>Fee Assistance Listing</h1>
                        <TreeTable value={this.state.budgetNodes} header={tblHeader} expandedKeys={this.state.expandedKeys} 
                            onToggle={(e) => this.setState({expandedKeys: e.value})} style={{marginTop: '.5em'}}>
                            <Column field="name" header="Name" bodyClassName="p-nowrap" expander/>
                            <Column field="code" header="Code" style={{width:'10%'}}/>
                            <Column field="typeName" header="Type" style={{width:'7%'}}/>
                            <Column field="targetTypeName" header="Apply for" style={{width:'7%'}}/>
                            <Column field="targetName" header="Target Name"/>
                            <Column field="total" header="Total" bodyClassName="p-r" style={{width:'7%'}}/>
                            <Column field="withdrawn" header="Withdrawn" bodyClassName="p-r" style={{width:'7%'}}/>
                            <Column field="available" header="Available" bodyClassName="p-r" style={{width:'7%'}}/>
                            {/* <Column field="active" header="Active" body={(node) => {return node.data.active && node.data.inAvailable?'Yes':'No'}} bodyClassName="p-c" style={{width:'5%'}}/> */}
                            <Column field="active" header="Active" body={this.budgetActiveTemplate} bodyClassName="p-c" style={{width:'5%'}}/>
                            <Column header="" body={this.actionTemplate} bodyClassName="tc-actions" style={{width:'10em'}}/>
                        </TreeTable>
                    </div>
                </div>
            </div>
        );
    }
}