import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";
import querystring from "querystring";

export class GroupServices {
  
  //#region Group 
  getPageGroups(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.get(`${API_BASE_URL}/scp/api/groups/page`, {
        params: {...filter, page, size, sort}
    }).then(res => {
        return res.data
    }).catch(error => console.log(error));
  }

  getListGroups(type, userId, simple=true){
    return axios.get(`${API_BASE_URL}/scp/api/groups/list`, {params: {type: type, userId: userId, simple: simple}})
    .then(res => {
        return res.data
    }).catch(error => console.log(error));
  }

  getListGroupByUsers(uid){
    return axios
      .get(`${API_BASE_URL}/scp/api/groups/user/${uid}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  getGroup(id) {
    return axios
      .get(`${API_BASE_URL}/scp/api/groups/${id}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  saveGroup(data) {
    return axios
      .post(`${API_BASE_URL}/scp/api/groups`, data)
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  createGroupByUser(uid){
    return axios.post(`${API_BASE_URL}/scp/api/groups/user/${uid}`)
    .then(res => res.data).catch(error => console.log(error));
  }

  joinGroup(data) {
    return axios.post(`${API_BASE_URL}/scp/api/groups/join`, 
      querystring.stringify({
        userId: data.userId,
        groupId: data.groupId
      })
    ).then(res => res.data).catch(error => console.log(error));
  }
  //#endregion

  //#region Adjust Income
  saveAdjustIncome(form){
      return axios.post(`${API_BASE_URL}/scp/api/groups/adjust_income`, form).then(res => res.data)
  }
  saveAdjustIncomeByRequest(form){
      return axios.post(`${API_BASE_URL}/scp/api/groups/adjust_income_by_request`, form).then(res => res.data)
  }
  //#endregion

  //#region Member
  getPageMembers(id, page, size, sortField, sortOrder) {
    const order = sortOrder === 1 ? "asc" : sortOrder === -1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}` : "";
    return axios
      .get(`${API_BASE_URL}/scp/api/groups/${id}/members`, {
        params: { page, size, sort }
      })
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  saveGroupMember(data) {
    return axios
      .post(`${API_BASE_URL}/scp/api/groups/member`, data)
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  changeGroupMemberOwner(gid, id){
    return axios
    .post(`${API_BASE_URL}/scp/api/groups/${gid}/member/${id}/change-owner`)
    .then(res => res.data)
    .catch(error => console.log(error));
  }

  deleteGroupMember(gid, id) {
    return axios
      .delete(`${API_BASE_URL}/scp/api/groups/${gid}/member/${id}`)
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  getListGroupMembers(id, uId) {
    return axios
      .get(`${API_BASE_URL}/scp/api/groups/${id}/members/list`, {params: {uId: uId}})
      .then(res => res.data)
      .catch(error => console.log(error));
  }

  saveAllMemberToGroup(gId, members) {
    return axios.post(`${API_BASE_URL}${SCP_PATH}/groups/members/save`, members, {params: {gId : gId}})
      .then(res => res.data)
      .catch(error => console.log(error));
  }
  //#endregion
}

export function getListGroups(type, userId, simple=true){
  return axios.get(`${API_BASE_URL}/scp/api/groups/list`, {params: {type: type, userId: userId, simple: simple}})
  .then(res => {
      return res.data
  }).catch(error => console.log(error));
}

export function createGroupByUser(uid){
  return axios.post(`${API_BASE_URL}/scp/api/groups/user/${uid}`)
  .then(res => res.data).catch(error => console.log(error));
}

export function getListGroupByUsers(uid){
  return axios.get(`${API_BASE_URL}/scp/api/groups/user/${uid}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getPageMembers(id, page, size, sortField, sortOrder) {
  const order = sortOrder === 1 ? "asc" : sortOrder === -1 ? "desc" : "";
  const sort = sortField ? `${sortField},${order}` : "";
  return axios.get(`${API_BASE_URL}/scp/api/groups/${id}/members`, {
      params: { page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function deleteGroupMember(gid, id) {
  return axios.delete(`${API_BASE_URL}/scp/api/groups/${gid}/member/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function changeGroupMemberOwner(gid, id){
  return axios.post(`${API_BASE_URL}/scp/api/groups/${gid}/member/${id}/change-owner`)
  .then(res => res.data).catch(error => console.log(error));
}
