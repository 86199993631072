import React, {Component, useState} from 'react';

import { PRICE_APPLY_TYPE, PRODUCT_TYPE, PRODUCT_PRICE_UNIT } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Spinner } from 'primereact/spinner';
import { Fieldset } from 'primereact/fieldset';
import classNames from 'classnames';

export const ProductPrices = (props) => {
    const defaultPrice = {applyFor: PRICE_APPLY_TYPE.normal.value, price: '', unit: PRODUCT_PRICE_UNIT.min.value, quantity: 1}
    const prices = props.value
    const optional = props.optional!==undefined?props.optional:true

    const addPrice = () => {
        let lstPrices = [...prices];
        lstPrices.push({...defaultPrice});
        props.onChange(lstPrices);
    }

    const removePrice = (idx) => {
        let lstPrices = [...prices];
        lstPrices.splice(idx, 1);
        props.onChange(lstPrices);
    }

    const onElementChange = (idx, key, value) => {
        let lstPrices = [...prices];
        let priceItem = lstPrices[idx];

        switch(key){
            case 'applyFor':
                priceItem.applyFor=value;
                break;
            case 'price':
                priceItem.price=value;
                break;
            case 'unit':
                priceItem.unit=value;
                break;
            case 'quantity':
                priceItem.quantity=value;
                break;
            default:
                break;
        }
            
        lstPrices.splice(idx, 1, priceItem);
        props.onChange(lstPrices);
    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                {prices && prices.length>0 && prices.map((val, idx) => {
                    return (
                        <div key={"price_"+idx} className="p-margin-bottom-10">
                            <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-padding-bottom-0">
                                <div className="p-grid p-fluid form-group">
                                    {props.type!==PRODUCT_TYPE.donation.value && props.type!==PRODUCT_TYPE.membership.value &&
                                        <div className={classNames({
                                            'p-col-5 p-md-4': !props.type || props.type===PRODUCT_TYPE.program.value || props.type===PRODUCT_TYPE.session.value || props.type===PRODUCT_TYPE.addon.value,
                                            'p-col-3': props.type===PRODUCT_TYPE.rental.value
                                        })}>
                                            <label className="p-label p-w-normal">Apply for</label>
                                            {optional ?
                                                <Dropdown value={val.applyFor} className="p-frames" options={Object.values(PRICE_APPLY_TYPE)} onChange={(e) => onElementChange(idx, 'applyFor', e.value)}/>
                                                :
                                                <div className="p-margin-top-5">{PRICE_APPLY_TYPE[val.applyFor]&&PRICE_APPLY_TYPE[val.applyFor].label}</div>
                                            }
                                            <div className="p-form-error">{props.errors['type_'+idx]}</div>
                                            {val.variant && <div className="p-form-error">{props.errors['type_variant_'+val.variant+'_'+idx]}</div>}
                                        </div>
                                    }
                                    <div className={classNames({
                                        'p-col-6 p-md-7': !props.type || props.type===PRODUCT_TYPE.program.value || props.type===PRODUCT_TYPE.session.value || props.type===PRODUCT_TYPE.addon.value,
                                        'p-col-3': props.type===PRODUCT_TYPE.rental.value,
                                        'p-col-4': props.type===PRODUCT_TYPE.membership.value,
                                        'p-col-12': props.type===PRODUCT_TYPE.donation.value 
                                    })}>
                                        <label className="p-label p-w-normal">Price</label>
                                        <Spinner value={val.price} min={0} step="0.25" onChange={(e) => onElementChange(idx, 'price', e.value)} />
                                        <div className="p-form-error">{props.errors['price_'+idx]}</div>
                                        {val.variant && <div className="p-form-error">{props.errors['price_variant_'+val.variant+'_'+idx]}</div>}
                                    </div>
                                    {(props.type===PRODUCT_TYPE.rental.value || props.type===PRODUCT_TYPE.membership.value) && 
                                    <React.Fragment>
                                        <div className={classNames({
                                            'p-col-3': props.type===PRODUCT_TYPE.rental.value,
                                            'p-col-4': props.type===PRODUCT_TYPE.membership.value
                                        })}>
                                            <label className="p-label p-w-normal">Unit</label>
                                            {optional ?
                                                <Dropdown value={val.unit} options={Object.values(PRODUCT_PRICE_UNIT)} onChange={(e) => onElementChange(idx, 'unit', e.value)}/>
                                                :
                                                <div className="p-margin-top-5">{PRODUCT_PRICE_UNIT[val.unit]&&PRODUCT_PRICE_UNIT[val.unit].label}</div>
                                            }
                                            <span className="p-form-error">{props.errors['unit_'+idx]}</span>
                                            {val.variant && <div className="p-form-error">{props.errors['unit_variant_'+val.variant+'_'+idx]}</div>}
                                        </div>
                                        <div className={classNames({
                                            'p-col-2': props.type===PRODUCT_TYPE.rental.value,
                                            'p-col-3': props.type===PRODUCT_TYPE.membership.value
                                        })}>
                                            <label className="p-label p-w-normal">Quantity</label>
                                            {optional ?
                                                <Spinner value={val.quantity} min={0} step="1" onChange={(e) => onElementChange(idx, 'quantity', e.value)} />
                                                :
                                                <div className="p-margin-top-5">{val.quantity}</div>
                                            }
                                            <span className="p-form-error">{props.errors['quantity_'+idx]}</span>
                                            {val.variant && <div className="p-form-error">{props.errors['quantity_variant_'+val.variant+'_'+idx]}</div>}
                                        </div>
                                    </React.Fragment>
                                    }
                                    {(optional && prices.length>1) &&
                                        <div className="p-col-12 p-md-1">
                                            <Button icon="pi-md-close" className="p-button-danger btn-xs" style={{top: '15px'}} onClick={() => removePrice(idx)}/>
                                        </div>
                                    }
                                    <div className="p-col-12"><div className="p-form-error">{props.errors[idx]}</div></div>
                                </div>
                            </Fieldset>
                        </div>                           
                    );
                })} 
            </div>
            {optional &&
                <div className="p-col-12 p-padding-top-0 p-padding-bottom-0 p-r">
                    <Button label="Add price" icon="pi pi-plus" className="p-button-success btn-text-sm" iconPos="left" onClick={() => addPrice()}/>
                </div>
            }
        </div>
    )
}