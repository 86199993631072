import axios from 'axios';
import { API_BASE_URL } from '../constants';

export class AuditLogServices {
    getPageAuditLogs(id, type, size=10){
        return axios.get(`${API_BASE_URL}/scp/api/audit/logs/${id}`, {params: {type, size}})
            .then(res => res.data).catch(error => console.log(error));
    }

    getChangeDetails(id, version, type){
        return axios.get(`${API_BASE_URL}/scp/api/audit/logs/${id}/version/${version}`, {params: {type}})
            .then(res => res.data).catch(error => console.log(error));
    }
}