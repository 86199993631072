import React, { useEffect, useState, useRef} from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import { APP_FEP, PAYMENT_STATE, ORDER_STATE } from '../../constants';
import { Button } from 'primereact/button';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { hasRole } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

import { getPageOrders } from '../../pim/order/OrderService';
import { moneyFormat, getTablePageReport } from '../../core/service/CommonService';
import classNames from 'classnames';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { OrderForm } from './OrderForm';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { cancelFrontDeskOrder } from './BookingServices';

const defaultFilter = {
    app: APP_FEP,
    orderStates: [ORDER_STATE.cart.value],
    origins: ['fep_admin'],
    idOrNumber: '',
    buyer: '',
}

export const BookingOrders = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const frmOrder = useRef(null)

    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState(defaultFilter)
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'createdAt',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(()=>{
        if(isLoadTable)
            loadPageOrders()
    },[isLoadTable])

    const loadPageOrders = () => {
        getPageOrders(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const onFilterKeyPress = (e) => {
        if (e.key === 'Enter') {
            setLoadTable(true)
        }
    }

    const applyFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setLoadTable(true)
    }

    const resetFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter(defaultFilter)
        setLoadTable(true)
    }

    const cancelOrder = (orderNumber) => {
        showConfirmNotify({
            message: 'Are you sure to cancel this order? This action can not be undo',
            accept: () => handleCancelOrder(orderNumber)
        })
    }

    const handleCancelOrder = (orderNumber) => {
        showloading()

        cancelFrontDeskOrder(orderNumber)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify("Order has been canceled!")
                setLoadTable(true)
            }else{
                showErrorNotify(res.errorMessage)
            }
            stoploading()
        })
    }

    const tblOrderHeader = (
        <TableHeader title="Operating Orders" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                hasRole([ROLES_CONFIG.FD_PROGRAM_ORDER_W]) && <Button className="p-button-success" label="Create Order" icon="pi-md-plus" onClick={() => frmOrder.current.openForm(null)}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Booking</BreadcrumbsItem>

            <OrderForm ref={frmOrder} 
                onSaveSuccess={(e) => history.push(`/monitoring/booking/${e.orderNumber}`)} 
            />
                
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold">Operating Booking Orders</h1>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <label className="p-size-16 p-fs-italic"><strong><u>Note:</u></strong> Must select or create a order to continue make booking for product.</label>
                        </div>
                    </div>
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-4 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">ID/Code:</span>
                                    <InputText keyfilter="int" value={filter.idOrNumber} onKeyPress={onFilterKeyPress} onChange={(e) => setFilter({...filter, idOrNumber: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-4 p-as-end">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Buyer:</span>
                                    <InputText value={filter.buyer} onKeyPress={onFilterKeyPress} onChange={(e) => setFilter({...filter, buyer: e.target.value})} placeholder="Name/email/phone"/>
                                </div>
                            </div>
                            <div className="p-col-4">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" style={{width: 'auto'}} onClick={() => applyFilter()} />
                                <Button className="p-button-secondary p-ml-2" icon="pi pi-trash" iconPos="left" label="Clear" style={{width: 'auto'}} onClick={() => resetFilter()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <DataTable 
                    emptyMessage="There's no operating booking order found" header={tblOrderHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="ID - Number" field="id" bodyClassName="p-c" style={{width:'10em'}} sortable={true}
                        body={rowData => <Button label={rowData.id + " - " + rowData.orderNumber} className="p-button-constrast" tooltip='Select order to process' tooltipOptions={{position: "top"}} onClick={() => history.push(`/monitoring/booking/${rowData.orderNumber}`)}/>}/>
                    <Column header="Buyer" field="billEmail" sortable={true} bodyClassName="p-nowrap" style={{width: '18em'}}
                        body={(rowData) => <React.Fragment><div>{rowData.billName + ' - [' + rowData.billEmail + ']'}</div>{rowData.billPhone && <div>{rowData.billPhone}</div>}</React.Fragment> }/>
                    {/* <Column header="Buyer" field="billEmail" sortable={true} bodyClassName="p-nowrap" style={{width: '18em'}}
                        body={(rowData) => {
                            return (
                                <Tippy
                                    content={<ContactDetailsCard data={rowData}/>}
                                    animation="fade"
                                    placement="right"
                                    trigger="mouseenter focus"
                                    interactive={true}
                                    arrow={true}
                                    theme="light"
                                    maxWidth= 'none'
                                    duration={[350, 200]}>
                                    <button className="Tippy-Button">
                                    {rowData.customerName + ' - [' + rowData.customerEmail + ']'}
                                    </button>
                                </Tippy>
                            );
                            }} /> */}
                    <Column header="Total" field="total" body={(rowData) => moneyFormat(rowData.total, rowData.currency)} bodyClassName="p-r p-w-bold" sortable={true} style={{width: '7em'}} />
                    <Column header="Security Deposit" field="pledgedDepositTotal" body={(rowData) => rowData.pledgedDepositTotal>0?moneyFormat(rowData.pledgedDepositTotal, rowData.currency):''} bodyClassName="p-r p-w-bold" sortable={true} style={{width: '8em'}} />
                    <Column header="Paid" field="paidTotal" body={(rowData) => moneyFormat(rowData.paidTotal, rowData.currency)} bodyClassName="p-r p-w-bold" sortable={true} style={{width: '7em'}} />
                    <Column header="Payment State" field="paymentState" body={(rowData) => <span className={classNames('status', rowData.paymentState)}>{PAYMENT_STATE[rowData.paymentState].label}</span>} bodyClassName="p-c" sortable={true} style={{width: '8em'}} />
                    <Column header="Order State" field="orderState" body={(rowData) => <span className={classNames('status', rowData.orderState)}>{ORDER_STATE[rowData.orderState].label}</span>} bodyClassName="p-c" sortable={true} style={{ textAlign: 'center', width: '6em'}} />
                    <Column header="Created At" field="createdAt" style={{width: '10em'}} bodyClassName="p-nowrap p-c" sortable={true} 
                        body={rowData => rowData.checkoutCompletedAt?moment(rowData.checkoutCompletedAt).format('MM/DD/YYYY hh:mm A'):moment(rowData.createdAt).format('MM/DD/YYYY hh:mm A')}
                    />
                    <Column header="" style={{width: '8em'}} bodyClassName="p-c"
                        body={rowData => <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => cancelOrder(rowData.orderNumber)}></Button>}
                    />
                </DataTable>
            </div>
        </div>
    )
}