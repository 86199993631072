import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types'
import {hasRole} from './auth';

const PermissionRoute = ({ component: Component, roles, ...rest}) => {
    const authorized = hasRole(roles)
    return (
        <Route
            {...rest}
            render={(props) => (
                authorized ? (
                    <Component type={rest.type} key={rest.key} {...props} />
                ) : (
                    <Redirect to={{
                        pathname: rest.redirectTo,
                        state: { from: props.location }
                    }}
                    />
                )
            )}
        />
    )
}

PermissionRoute.propTypes = {
    roles: PropTypes.array.isRequired
}

export default PermissionRoute