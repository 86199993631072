import React, { Component } from 'react';
import moment from "moment";
import classNames from "classnames";
import { DashboardServices } from './DashboardServices';
import { SelectButton } from 'primereact/selectbutton';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/components/chart/Chart';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { formatMoney } from "accounting-js";

const DATE_VIEW_TYPE = {
    today: 'today',
    last_7_days: 'last_7_days',
    last_15_days: 'last_15_days',
    last_30_days: 'last_30_days',
    range: 'range'
}

const DATE_VIEW_TYPES = [
    {value: DATE_VIEW_TYPE.today, label: 'Today'},
    {value: DATE_VIEW_TYPE.last_7_days, label: 'Last 7 Days'},
    {value: DATE_VIEW_TYPE.last_15_days, label: 'Last 15 Days'},
    {value: DATE_VIEW_TYPE.last_30_days, label: 'Last 30 Days'},
    {value: DATE_VIEW_TYPE.range, label: 'Date Range'}
]

export class Dashboard3 extends Component {

    constructor() {
        super();
        this.state = {
            summaryStatistic:{},
            filter: {
                byDate: DATE_VIEW_TYPE.today,
                dateFrom: '',
                dateTo: '',
            },
            startDateValue: '',
            endDateValue: '',
        }
        this.dashboardServices = new DashboardServices();
    }

    componentDidMount(){
        let dateFrom = moment(new Date(), moment.HTML5_FMT.DATE);
        dateFrom.set({h: 0, m: 0, s: 0});
        let dateTo= moment(new Date(), moment.HTML5_FMT.DATE);
        dateTo.set({h: 23, m: 59, s: 59});
        this.setState({
            filter: {...this.state.filter,
                dateFrom: dateFrom.format('YYYY-MM-DD HH:mm'),
                dateTo: dateTo.format('YYYY-MM-DD HH:mm')
            }
        },() => this.loadStatistic());
    }

    loadStatistic = () => {
        this.dashboardServices.getStatistic(this.state.filter)
        .then(res => {
            let summaryStatistic = res&&res.summaryStatistic?res.summaryStatistic:{};
            let chartStatistic = res&&res.chartStatistic?res.chartStatistic:[];

            let dates = [];
            let numOfDays = 0;
            let tempChartData = [];


            if(chartStatistic.length > 0){
                chartStatistic.map(data => {
                    dates.push(moment(data.orderDate, 'YYYY-MM-DD HH:mm').format('MM/DD/YYYY'));
                    tempChartData.push(parseFloat(data.total?data.total:0).toFixed(2));
                
                    numOfDays += 1;
                })
            }else{
                dates.push(moment(new Date()).format('MM/DD/YYYY'));
            }
            

            let chartData={
                labels: dates, 
                datasets: [
                    {label: '', backgroundColor: '#ffd54f', barPercentage: 0.8, barThickness: 50, maxBarThickness: 70, minBarLength: 5, data: tempChartData}, 
                ]
            };
            
            this.setState({
                summaryStatistic: summaryStatistic,
                numOfDays: numOfDays,
                chartStatistic: chartData
            })
        })
    }

    onChangeByDate = (e) => {
        this.setState({
            filter: {...this.state.filter,
                byDate: e.value
            }
        }, () => {
            let dateFrom = moment(new Date(), moment.HTML5_FMT.DATE);
            let dateTo = moment(new Date(), moment.HTML5_FMT.DATE);

            if(e.value!==DATE_VIEW_TYPE.range){
                let today = moment(new Date(), moment.HTML5_FMT.DATE);

                switch(e.value){
                    case 'week':
                        let dateOfToday = today.date();
                        dateFrom.set({date: dateOfToday-(today.day())});
                        dateTo.set({date: dateOfToday+(7-today.day()-1)});
                        break;
                    case 'month':
                        dateFrom.set({date: 1});
                        dateTo.set({date: today.daysInMonth()});
                        break;
                    case DATE_VIEW_TYPE.last_7_days:
                        dateFrom = today.subtract(6, 'days');
                        break;
                    case DATE_VIEW_TYPE.last_15_days:
                        dateFrom = today.subtract(14, 'days');
                        break;
                    case DATE_VIEW_TYPE.last_30_days:
                        dateFrom = today.subtract(29, 'days');
                        break;
                    default: break;
                }

                dateFrom.set({h: 0, m: 0, s: 0});
                dateTo.set({h: 23, m: 59, s: 59});
            }else{
                dateFrom = moment('2020-10-01 00:00', 'YYYY-MM-DD HH:mm');
                dateTo = moment(new Date(), moment.HTML5_FMT.DATE);
            }

            this.setState({
                filter: {...this.state.filter,
                    dateFrom: dateFrom.format('YYYY-MM-DD HH:mm'),
                    dateTo: dateTo.format('YYYY-MM-DD HH:mm'),
                },
                startDateValue: dateFrom.toDate(),
                minDate: dateFrom.toDate(),
                endDateValue: dateTo.toDate(),
                maxDate: dateTo.toDate()
            },() => this.loadStatistic());
        });
    }

    onStartDateChange = (e) => {
        let maxDate = this.state.maxDate ? moment(this.state.maxDate) : '';
        let minDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (minDateToCompare && maxDate && minDateToCompare.isAfter(maxDate));
        if(minDateToCompare){
            minDateToCompare.set({h: 0, m: 0, s: 0});
        }
        this.setState({
            filter: {...this.state.filter, 
                dateFrom: minDateToCompare?minDateToCompare.format('YYYY-MM-DD HH:mm'):'',
                dateTo: !flag ? this.state.filter.dateTo : ''
            },
            startDateValue: e.value,
            minDate: moment(e.value).isValid() ? e.value : '',
            endDateValue: !flag ? this.state.endDateValue : '',
            maxDate: !flag ? this.state.maxDate : ''
        },() => this.loadStatistic());
    }

    onEndDateChange = (e) => {
        let minDate = this.state.minDate ? moment(this.state.minDate) : '';
        let maxDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (maxDateToCompare && minDate && maxDateToCompare.isBefore(minDate));
        if(maxDateToCompare){
            maxDateToCompare.set({h: 23, m: 59, s: 59});
        }
        this.setState({
            filter: {...this.state.filter, 
                dateFrom: !flag ? this.state.filter.dateFrom : '',
                dateTo: maxDateToCompare?maxDateToCompare.format('YYYY-MM-DD HH:mm'):''
            },
            startDateValue: !flag ? this.state.startDateValue : '',
            minDate: !flag ? this.state.minDate :'',
            endDateValue: e.value,
            maxDate: moment(e.value).isValid() ? e.value : ''
        },() => this.loadStatistic());
    }

    onTypeChange = (e) => {
        this.setState({
            filter: {...this.state.filter, channels: e.value }
        },() => this.loadStatistic())
    }

    render() {
        const barChartOptions = {
            responsive: true,
            tooltips: {
                mode: 'index',
                intersect: true
            },
            scales: {
                yAxes: [{
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    ticks: {
                        min: 0
                    }
                }]
            }
        }

        return(
            <div className="p-grid dashboard">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <SelectButton value={this.state.filter.byDate} options={DATE_VIEW_TYPES} onChange={(e) => this.onChangeByDate(e)} ></SelectButton>
                                </div>
                                {this.state.filter.byDate === DATE_VIEW_TYPE.range && 
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">From date:</span>
                                                    <MaskedCalendar value={this.state.startDateValue} onChange={this.onStartDateChange} showIcon={true} maxDate={this.state.maxDate} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">To date:</span>
                                                    <MaskedCalendar value={this.state.endDateValue} onChange={this.onEndDateChange} showIcon={true} minDate={this.state.minDate} />
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                }        
                            </div>
                        </div>
                        {/* <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Type:</span>
                                <MultiSelect value={this.state.filter.channels} options={CHANNEL_TYPES} onChange={(e) => this.onTypeChange(e)} style={{width: '100%'}} />
                            </div>
                        </div> */}
                    </div>
                </div>
                
                <div className="p-col-12 p-md-6">
                    <div className="overview-box overview-box-2">
                        <div className="overview-box-header"></div>
                        <div className="overview-box-content">
                            <div className="p-col-12">
                                <div className="overview-box-icon"><i className="pi pi-dollar"></i></div>
                                <div className="overview-box-title">Total Revenue</div>
                                <div className="overview-box-count">{formatMoney(this.state.summaryStatistic.totalRevenue?this.state.summaryStatistic.totalRevenue:0, {symbol:'', precision: 2, thousand: ',', decimal: '.'})}</div>
                            </div>
                            <hr/>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="overview-box-title">Ticket</div>
                                    <div className="overview-box-count">{this.state.summaryStatistic.countRevenue?this.state.summaryStatistic.countRevenue:0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames("p-col-12", {"p-md-6": this.state.numOfDays<=7})}>
                    <Panel header="Sales Graph">
                        <Chart type={this.state.numOfDays>3?"line":"bar"} data={this.state.chartStatistic} options={barChartOptions}/>
                    </Panel>
                </div>
            </div>
        );
    }
}