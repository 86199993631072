import axios from "axios";
import {API_BASE_URL} from "../../../constants";

export class PermissionServices{

    getTreePermissions() {
        return axios.get(`${API_BASE_URL}/scp/api/acl/permissions/tree`).then(res => res.data).catch(error => console.log(error));
    }

    getPermissions(type2) {
        let type = type2 ? "department"
        : "";
        return axios.get(`${API_BASE_URL}/scp/api/acl/permissions`,{
            params:{type}
        }).then(res => res.data).catch(error => console.log(error));
    }

    savePermission(data){
        return axios.post(`${API_BASE_URL}/scp/api/acl/permissions`, data)
            .then(res => res.data).catch(error => console.log(error))
    }

    removePermission(key){
        return axios.delete(`${API_BASE_URL}/scp/api/acl/permissions/${key}`)
            .then(res => res.data).catch(error => console.log(error))
    }
}