import CKEditor from 'ckeditor4-react';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { SplitButton } from "primereact/splitbutton";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ATTRIBUTE_FIELD_SET_TYPE } from "../../constants";
import { getListAttributeSets } from "../attribute/AttributeServices";
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

export const ApplicationContentForm = forwardRef((props, ref) => {

    const [data, setData] = useState({})
    const [tmpComponent, setTmpComponent] = useState({})
    const [customSets, setCustomSets] = useState([])
    const [personalSets, setPersonalSets] = useState([])
    const [waiverSets, setWaiverSets] = useState([])
    const [formHeader, setFormHeader] = useState('Step')
    const [visible, setVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, ()=>({
        openForm(contentData){
            setData({
                title: contentData?contentData.title:'',
                components: contentData&&contentData.components?contentData.components:[]
            })
            setVisible(true)
            setErrors({})
            loadAttributeSets(ATTRIBUTE_FIELD_SET_TYPE.personal)
            loadAttributeSets(ATTRIBUTE_FIELD_SET_TYPE.custom_field)
            loadAttributeSets(ATTRIBUTE_FIELD_SET_TYPE.waiver)
        }   
    }))

    const handleSaveForm = () => {
        if(!data.title || !data.title.trim()){
            setErrors({titel: 'Field is required'})
            return 
        }
        props.onSubmit(data)
        onHide()
    }

    const loadAttributeSets = (type) => {
        getListAttributeSets(type)
        .then(res => {
            switch(type){
                case ATTRIBUTE_FIELD_SET_TYPE.personal:
                    setPersonalSets(res)
                    break;
                case ATTRIBUTE_FIELD_SET_TYPE.custom_field:
                    setCustomSets(res)
                    break;
                case ATTRIBUTE_FIELD_SET_TYPE.waiver:
                    setWaiverSets(res)
                    break;
                default: break;
            }
        });
    }

    const onSubmitComponent = () => {
        let tmpComponentArr = data.components?[...data.components]:[]
        let item = {
            type: tmpComponent.type,
            content: tmpComponent.content,
            personalSetId: tmpComponent.personalSetId,
            customSetId: tmpComponent.customSetId,
            waiverSetId: tmpComponent.waiverSetId
        }
        if(!tmpComponent.index && tmpComponent.index!==0){
            tmpComponentArr.push(item)
        }else{
            tmpComponentArr.splice(tmpComponent.index, 1, item)
        }
        setData({...data, components: tmpComponentArr})
        setTmpComponent({
            type: null,
            content: '',
            personalSetId: null,
            customSetId: null,
        })
    }

    const onAddOrEditComponent = (idx, type, data) => {
        setTmpComponent({
            type: data?data.type:type,
            content: data?data.content:'',
            personalSetId: data?data.personalSetId:null,
            customSetId: data?data.customSetId:null,
            waiverSetId: data?data.waiverSetId:null,
            index: idx
        })
    }

    const onRemoveComponent = (idx) => {
        let tmpComponentArr = [...data.components]
        tmpComponentArr.splice(idx, 1)
        setData({...data, components: tmpComponentArr})
    }

    const onCancelComponent = () => {
        setTmpComponent({
            type: null,
            content: '',
            personalSetId: null,
            customSetId: null,
        })
    }

    const onHide = () => {
        setTmpComponent({})
        setVisible(false)
    }

    const COMPONENTT_TYPE = {
        free_text: {value: 'free_text', label: 'Free Text', command:()=> onAddOrEditComponent(null, COMPONENTT_TYPE.free_text.value)},
        input_field: {value: 'input_field', label: 'Input Field',  command:()=> onAddOrEditComponent(null, COMPONENTT_TYPE.input_field.value)}
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} dismissable={false} onHide={()=>onHide()}>
            <h2>{formHeader}</h2>
            
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label>* Title</label>
                    <InputText value={data.title} onChange={e => setData({...data, title: e.target.value})} />
                    <div className="p-form-error">{errors.title}</div>
                </div>
                <div className="p-col-12">
                    <Fieldset>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <SplitButton label={"Add Component: "+(tmpComponent.type?COMPONENTT_TYPE[tmpComponent.type].label:'')} icon="pi pi-plus" model={Object.values(COMPONENTT_TYPE)} />
                            </div>
                            {(()=>{
                                if(tmpComponent.type){
                                    return <React.Fragment>
                                        {tmpComponent.type === COMPONENTT_TYPE.free_text.value &&
                                            <div className="p-col-12">
                                                <label>Content</label>
                                                <CKEditor data={tmpComponent.content} onChange={(e) => setTmpComponent({...tmpComponent, content: e.editor.getData() })} />
                                            </div>
                                        }
                                        {tmpComponent.type === COMPONENTT_TYPE.input_field.value &&
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label>Personal Set</label>
                                                    <Dropdown value={tmpComponent.personalSetId} options={personalSets} showClear onChange={e => setTmpComponent({...tmpComponent, personalSetId: e.value})} />
                                                </div>
                                                <div className="p-col-12">
                                                    <label>Custom Set</label>
                                                    <Dropdown value={tmpComponent.customSetId} options={customSets} showClear onChange={e => setTmpComponent({...tmpComponent, customSetId: e.value})} />
                                                </div>
                                                <div className="p-col-12">
                                                    <label>Waiver Set</label>
                                                    <Dropdown value={tmpComponent.waiverSetId} options={waiverSets} showClear onChange={e => setTmpComponent({...tmpComponent, waiverSetId: e.value})} />
                                                </div>
                                            </React.Fragment>
                                        }
                                        <div className="p-col-12 p-r">
                                            <Button label="Cancel" onClick={() => onCancelComponent()} style={{width: 'auto'}} />
                                            <Button label="Submit" onClick={() => onSubmitComponent()} style={{width: 'auto'}} />
                                        </div>
                                    </React.Fragment>
                                }
                            })()}
                        </div>    
                    </Fieldset>
                </div>
                {data.components && data.components.length>0 &&
                    <div className="p-col-12">
                        <Fieldset legend="Contents">
                            <div className="p-grid">
                                {data.components.map((component, index) => {
                                    return <div key={index} className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-lg-6">{COMPONENTT_TYPE[component.type] && COMPONENTT_TYPE[component.type].label}</div>
                                                {index!==tmpComponent.index &&
                                                    <div className="p-col-12 p-lg-6 p-r">
                                                        <Button className="p-button-warning" icon="pi pi-pencil" onClick={() => onAddOrEditComponent(index, component.type, component)} />
                                                        <Button className="p-button-danger" icon="pi pi-times" onClick={() => onRemoveComponent(index)} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                })}
                            </div>
                        </Fieldset>
                    </div>
                }
                
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                    <Button label="Save" icon="pi pi-check" onClick={()=> handleSaveForm()}/>
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={()=> onHide()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

