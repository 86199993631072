import React, {Component} from "react";
import { MemberScanServices } from "./MemberScanServices";
import { TabView, TabPanel } from "primereact/tabview";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { showNotification } from "../../core/service/NotificationService";
import { AvatarForm } from "../../constituent-management/contact/AvatarForm";
import moment from "moment";
import { UserServices } from "../../constituent-management/contact/UserServices";
import { Link } from "react-router-dom";
import Avatar from 'react-avatar';
import { GroupServices } from "../../constituent-management/group/GroupServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AddRelationForm } from "../../constituent-management/contact/AddRelationForm";
import { MembershipServices } from "../../constituent-management/membership/MembershipServices";
import { Panel } from "primereact/panel";
import { UserForm_old } from "../../constituent-management/contact/UserForm_old";
import { NoteList } from "../../constituent-management/note/NoteList";

let ipcRenderer;
export class MemberScan extends Component{

    constructor(props){
        super(props);
        this.state = {
            tabIndex: 0,
            memberId: '',
            member: {},
            avatarFormDialogVisible: false,
            userRelations: [],
            groupMembers: [],
            groupMemberRelations: [],
            memberships: [],
            membershipMemberRelations: [],
            arrEmeContacts: [],
            userFormVisible: false,
            errors: {}
        }
        this.memberScanServices = new MemberScanServices();
        this.userServices = new UserServices();
        this.groupServices = new GroupServices();
        this.membershipServices = new MembershipServices();
    }

    componentDidMount() {
        if(ipcRenderer){
            ipcRenderer.on('SEND_TO_MEMBER_SCAN', this.returnFromScanner);
            ipcRenderer.send("MAIN_TURN_ON_SCANNER", "GET_TURN_ON");
        }
    }

    componentWillUnmount() {
        if(ipcRenderer){
            ipcRenderer.removeListener('SEND_TO_MEMBER_SCAN', this.returnFromScanner);
            ipcRenderer.send("MAIN_TURN_OFF_SCANNER", "GET_TURN_OFF");
        }
    }

    returnFromScanner = (event, data) => {
        let memberId = data;
        this.setState({
            memberId: memberId
        }, () => { this.getMemberDetails(memberId) });
    }

    getMemberDetails = (memberId) => {
        this.setState({errors: {}});
        this.memberScanServices.getMemberDetails(memberId).then(res => {
            let user = res.user;
            let membership = res.membership;
            let errors = res.errors;
            this.popularData(user);
            this.setState({
                tabIndex: 0,
                membershipInfo: membership
            })
            if(errors && errors.errorCode === 400){ 
                this.setState({ errors: errors.errorObj});
            }
            if(res.errorCode === 404){
                this.popularData();
                showNotification('warn', 'Nofitication Message', 'We could not found your request');
            }
        })
    }

    getListUserRelations = (id) => {
        this.userServices.getListUserRelations(id)
            .then(res => {
                if(!res.errorCode){
                    if(res) this.setState({userRelations: res});
                }else{
                    showNotification('warn', 'Nofitication Message', 'We could not found your request');
                }
            });
    }

    getGroupUser = (uId) => {
        this.groupServices.getListGroupByUsers(uId)
            .then(res => {
                if (res && res.length > 0) {
                    this.getListGroupMembers(res[0].id);
                } else{
                    this.setState({ 
                        groupMembers: [],
                        groupMemberRelations: []
                    });
                }
            });
    }

    getListGroupMembers = (gId) => {
        this.groupServices.getListGroupMembers(gId, this.state.member.id)
            .then(res => {
                if(!res.errorCode){
                    this.setState({
                        groupMembers: res.members,
                        groupMemberRelations: res.memberRelations
                    });
                }else{
                    showNotification('warn', 'Nofitication Message', 'We could not found your request');
                }
            });
    }

    getListEmeContact = (id) => {
        this.userServices.getListEmeContacts(id)
        .then(res => {
            if(!res.errorCode){
                if(res) {
                    let termArr = [];
                    res.map((item, index) => {
                        if(item.primary){
                            termArr.push(item);
                        }
                    })
                    this.setState({ arrEmeContacts: termArr });
                }
            }
        })
    }

    updateRelation = (data) => {
        let tempGroupMemberArr = [...this.state.groupMemberRelations];
        tempGroupMemberArr.splice(tempGroupMemberArr.indexOf(tempGroupMemberArr.find(relation => relation.id===data.id)), 1, data);
        let tempMembershipMemberArr = [...this.state.membershipMemberRelations];
        tempMembershipMemberArr.splice(tempMembershipMemberArr.indexOf(tempMembershipMemberArr.find(relation => relation.id===data.id)), 1, data);

        this.setState({
            groupMemberRelations: tempGroupMemberArr,
            membershipMemberRelations: tempMembershipMemberArr
        })
    }

    getMemberships = (userId) => {
        this.membershipServices.getUserMemberships(userId).then(res => {
            let data = res.data;
            this.setState({
                memberships: res
            });
            if(!data){
                this.setState({
                    membershipMemberRelations: []
                })
            }
        });
    }

    popularData = (user) => {
        this.setState({
            member: user ? user : {}
        });
        if(user){
            this.getListUserRelations(user.id);
            this.getGroupUser(user.id);
            this.getListEmeContact(user.id);
            this.getMemberships(user.id);
        }else{
            this.setState({
                userRelations: [],
                groupMembers: [],
                groupMemberRelations: [],
                memberships: [],
                membershipMemberRelations: []
            });
        }
    }

    handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            this.getMemberDetails(this.state.memberId);
        }
    }

    render(){
        let groupMemberRelations = this.state.groupMemberRelations;
        let membershipMemberRelations = this.state.membershipMemberRelations;

        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Member Scan</BreadcrumbsItem>

                <AvatarForm
                    data={this.state.member}
                    visible={this.state.avatarFormDialogVisible}
                    hideFormDialog={(e) => this.setState({ avatarFormDialogVisible: false })}
                    refreshData={(e) => this.setState({member: e})}
                />

                <AddRelationForm ref={el => this.frmRelation = el}
                    reloadData={(data) => this.updateRelation(data)}
                    uId={this.state.member ? this.state.member.id : ''}
                />

                <UserForm_old ref={el => this.userForm = el}
                    memberScanId={this.state.member.id}
                />

                <div className="p-col-12">
                    <div className="card">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-6">
                                <div className="p-inputgroup">
                                    <InputText placeholder="Insert value" value={this.state.memberId} onKeyPress={this.handleKeyPress} onChange={(e) => this.setState({memberId: e.target.value})}/>
                                    <Button label="Go" className="p-button-info" onClick={(e) => this.getMemberDetails(this.state.memberId)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {this.state.member.id ? 
                    <div>
                        <hr style={{border: "2px solid #e6e6e6", width: "100%", marginBottom: "15"}}/>
                        <div className="p-col-12">
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="card">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-6">
                                                    <div style={{width: "200px", position: "relative"}}>
                                                        <img src={this.state.member.avatar ? this.state.member.avatar : 'null'} width="200" height="200" />
                                                        <span className="pi pi-camera" style={{ fontSize: "2.3em", cursor: "pointer", position: "absolute", bottom: 0, right: 10, background: "#0000008f", color: "#ffffff", borderRadius: 20, padding: 3 }} onClick={(e) => this.setState({ avatarFormDialogVisible: true })}></span>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-6">
                                                    <h1>
                                                        {this.state.member.name}
                                                    </h1>
                                                    {this.state.membershipInfo ?
                                                        <div className="p-margin-bottom-10" style={{borderBottom: '1px solid #e2e1e1'}}>
                                                            <h3 className="p-margin-none">
                                                                Membership {this.state.membershipInfo.usageType==='trial'?'(Trial)':''}
                                                            </h3>
                                                            {this.state.membershipInfo.usageType === 'trial'?
                                                                <div className="p-form-error p-margin-bottom-5"><i>{this.state.errors.member}</i></div>
                                                            : ''}
                                                        </div>
                                                        : ''
                                                    }
                                                    <div>
                                                        <label>Membership ID: </label>
                                                        <span>{this.state.member.memberId}</span>
                                                    </div>
                                                    <div>
                                                        <label>Gender: </label>
                                                        <span>{this.state.member.gender}</span>
                                                    </div>
                                                    <div>
                                                        <label>P.Email: </label>
                                                        <span>{this.state.member.email}</span>
                                                    </div>
                                                    <div>
                                                        <label>S.Email: </label>
                                                        <span>{this.state.member.secondaryEmail}</span>
                                                    </div>
                                                    <div>
                                                        <label>D.O.B: </label>
                                                        <span>{moment(this.state.member.birthday).format('MM-DD-YYYY')}</span>
                                                    </div>
                                                    {this.state.membershipInfo && this.state.membershipInfo.usageType !== 'trial' ?
                                                        <div>
                                                            <Button label="Create Guest" className="p-button-warning" onClick={(e) => this.userForm.action('membership')} />
                                                        </div>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="card">
                                            {/* <Fieldset legend="Emergency Contact 1" >
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Name: </label>
                                                        <span>{this.state.member.emeName}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Relationship: </label>
                                                        <span>{this.state.member.emeRelationship}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Phone: </label>
                                                        <span>{this.state.member.emePhoneObj ? this.state.member.emePhoneObj.dialCode + this.state.member.emePhoneObj.phoneNumber : ''}</span>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                            <Fieldset legend="Emergency Contact 2" >
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Name: </label>
                                                        <span>{this.state.member.emeAltName}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Relationship: </label>
                                                        <span>{this.state.member.emeAltRelationship}</span>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label>Phone: </label>
                                                        <span>{this.state.member.emeAltPhoneObj ? this.state.member.emeAltPhoneObj.dialCode + this.state.member.emeAltPhoneObj.phoneNumber : ''}</span>
                                                    </div>
                                                </div>
                                            </Fieldset> */}
                                            <DataTable value={this.state.arrEmeContacts} header="Emergency Contact">
                                                <Column field="name" header="Name" />
                                                <Column field="relationship" header="Relationship" />
                                                <Column field="phone" header="Phone" 
                                                    body={(rowData) =>{
                                                        return (
                                                            <span>{rowData.phoneObj ? rowData.phoneObj.phoneNumberFormated : ''}</span>
                                                        );
                                                    }} 
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-grid dashboard">
                                    <div className="p-col-12 p-md-6">
                                        <TabView activeIndex={this.state.tabIndex} onTabChange={(e) => this.setState({tabIndex: e.index})}>
                                            <TabPanel header="Information">
                                                <Fieldset legend="Contact Information" >
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Name: </label>
                                                            <span>{this.state.member.firstName} {this.state.member.lastName}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Title: </label>
                                                            <span>{this.state.member.title}</span>
                                                        </div>
                                                        {/* <div className="p-col-12 p-md-6">
                                                            <label>Gender: </label>
                                                            <span>{this.state.member.gender}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>D.O.B: </label>
                                                            <span>{moment(this.state.member.birthday).format('MM-DD-YYYY')}</span>
                                                        </div> */}
                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <label>Cell Phone: </label>
                                                                    <span>{this.state.member.cellPhoneObj ? this.state.member.cellPhoneObj.phoneNumberFormated : ''}</span>
                                                                    <span> {this.state.member.phonePrimary==='cellPhone' ? '(primary)' : ''}</span>
                                                                </div>
                                                                <div className="p-col-12">
                                                                    <label>Home Phone: </label>
                                                                    <span>{this.state.member.homePhoneObj ? this.state.member.homePhoneObj.phoneNumberFormated : ''}</span>
                                                                    <span> {this.state.member.phonePrimary==='homePhone' ? '(primary)' : ''}</span>
                                                                </div>
                                                                <div className="p-col-12">
                                                                    <label>Work Phone: </label>
                                                                    <span>{this.state.member.workPhoneObj ? this.state.member.workPhoneObj.phoneNumberFormated : ''}</span>
                                                                    <span> {this.state.member.phonePrimary==='workPhone' ? '(primary)' : ''}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Classification: </label>
                                                            <span>{this.state.member.classification ? this.state.member.classification.toString() : ''}</span>
                                                        </div>
                                                    </div>
                                                </Fieldset>
                                                <Fieldset legend="Address Information" >
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Address: </label>
                                                            <span>{this.state.member.primaryAddress ? this.state.member.primaryAddress.fullAddress : ''}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>City: </label>
                                                            <span>{this.state.member.primaryAddress ? this.state.member.primaryAddress.city : ''}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Zip Code: </label>
                                                            <span>{this.state.member.primaryAddress ? this.state.member.primaryAddress.zipCode : ''}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6"></div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Country: </label>
                                                            <span>{this.state.member.primaryAddress ? this.state.member.primaryAddress.countryName : ''}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>State/Province: </label>
                                                            <span>{this.state.member.primaryAddress ? this.state.member.primaryAddress.stateName : ''}</span>
                                                        </div>
                                                    </div>
                                                </Fieldset>
                                                {this.state.membershipInfo ? 
                                                <Fieldset legend="Membership Information" >
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-6">
                                                            <div className="p-grid p-fluid">
                                                                <div className="p-col-12">
                                                                    <label>Type: </label>
                                                                    <span>{this.state.membershipInfo.type}</span>
                                                                </div>
                                                                <div className="p-col-12">
                                                                    <label>Standing: </label>
                                                                    <span>{this.state.membershipInfo.status}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <div className="p-grid p-fluid">
                                                                <div className="p-col-12">
                                                                    <label>Date Join: </label>
                                                                    <span>{moment(this.state.membershipInfo.purchase).format('MM-DD-YYYY')}</span>
                                                                </div>
                                                                <div className="p-col-12">
                                                                    <label>End Date: </label>
                                                                    <span>{moment(this.state.membershipInfo.end).format('MM-DD-YYYY')}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.membershipInfo && this.state.membershipInfo.usageType === 'trial'?
                                                            <div className="p-col-12">
                                                                <div className="p-grid p-fluid">
                                                                    <div className="p-col-12 p-md-6">
                                                                        <label>Max scan: </label>
                                                                        <span>{this.state.membershipInfo.maxScan}</span>
                                                                    </div>
                                                                    <div className="p-col-12 p-md-6">
                                                                        <label>Scan count: </label>
                                                                        <span>{this.state.membershipInfo.scanCount + (this.state.membershipInfo.status==='ACTIVE'?1:0)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Fieldset>
                                                : ''
                                            }
                                                <Fieldset legend="Education Information" >
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Grade: </label>
                                                            <span>{this.state.member.grade}</span>
                                                        </div>
                                                    </div>
                                                </Fieldset>
                                                {/* <Fieldset legend="Creation Information" >
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Origin: </label>
                                                            <span>{this.state.member.origin}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Created by: </label>
                                                            <span>{this.state.member.name}</span>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>Create At: </label>
                                                            <span>{moment(this.state.member.created).format('MM-DD-YYYY')}</span>
                                                        </div>
                                                    </div>
                                                </Fieldset> */}
                                            </TabPanel>
                                            <TabPanel header={!this.state.membershipInfo || (this.state.membershipInfo && this.state.membershipInfo.usageType !== 'trial') ? "Membership Mgt" : ''}
                                                disabled={!this.state.membershipInfo || (this.state.membershipInfo && this.state.membershipInfo.usageType !== 'trial') ? false : true}
                                            >
                                                {this.state.memberships.map((membership, index) => {
                                                    let slot = 0;
                                                    let rules = membership.rules;
                                                    let slotUnlimited = false;
                                                    if(rules){
                                                        rules.map(item=> { 
                                                            slot += item.quantity;
                                                            slotUnlimited = item.quantity === 0;
                                                        })
                                                    }
                                                    return(
                                                        <div className="p-col-12">
                                                            <Panel key={index} header={membership.name} toggleable={true} collapsed={index === 0 ? false : true}>
                                                                <Fieldset legend="Basic Information" >
                                                                    <div className="p-grid">
                                                                        <div className="p-col-12 p-md-6">
                                                                            <div className="p-grid">
                                                                                <div className="p-col-12">
                                                                                    <label>{membership.name ? membership.name : 'N/A'}</label>
                                                                                </div>
                                                                                <div className="p-col-12">
                                                                                    <label>Type: {membership.type}</label>
                                                                                </div>
                                                                                <div className="p-col-12">
                                                                                    <label>Membership ID: {this.state.member.memberId}</label>
                                                                                </div>
                                                                                <div className="p-col-12">
                                                                                    <label>Standing: {membership.status}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-col-12 p-md-6">
                                                                            <div className="p-grid">
                                                                                <div className="p-col-12">
                                                                                    <label>Join Date: {membership.purchase ? moment(membership.purchase).format('MM-DD-YYYY') : ''}</label>
                                                                                </div>
                                                                                <div className="p-col-12">
                                                                                    <label>End Date: {membership.end ? moment(membership.end).format('MM-DD-YYYY') : ''}</label>
                                                                                </div>
                                                                                <div className="p-col-12">
                                                                                    <label>
                                                                                        Total members: {membership.members && membership.members.length > 0 ? membership.members.length : 'No member.'}
                                                                                        <div>
                                                                                            <span className="p-margin-right-10">
                                                                                                {membership.totalMembers.map((item, index) => {
                                                                                                    let type = item.type.charAt(0,1).toUpperCase()+item.type.substr(1)
                                                                                                    return( 
                                                                                                        <React.Fragment>
                                                                                                            <span>{item.totalMemberType} - {type}(age from {item.minAge} - {item.maxAge})</span> 
                                                                                                            {index===(rules.length-1) || rules.length===1? '' : ' | '}
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })}
                                                                                            </span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fieldset>
                                                                {membership.members && membership.members.length > 0 ?
                                                                    <Fieldset legend="Members">
                                                                        <div className="p-grid">
                                                                        {membership.members.map((item, index) => {
                                                                            let memberRelationObj = membershipMemberRelations.find(member => member.userB===item.user.id);
                                                                            return (
                                                                                <div className="p-col-12 p-md-4">
                                                                                    <div style={{border: "1px solid #eaeaea"}}>
                                                                                        <div className="p-padding-10-0 p-c" style={{background: "#ececec"}}>
                                                                                            {item.user.avatar ? 
                                                                                                <img src={item.user.avatar} width="50" alt="Avatar"/>
                                                                                                : <Avatar maxInitials={2} round={true} textSizeRatio={2} size={50} name={item.user.name} style={{margin: 0}} />
                                                                                            }
                                                                                        </div>
                                                                                        <div style={{minHeight: 85}}>
                                                                                            <div className="p-c p-padding-5-0">{item.user.name}</div>
                                                                                            <div className="p-c p-padding-5-0">Age: {item.user.ageString}</div>
                                                                                            <div className="p-c p-padding-5-0" style={{position: "relative"}}>
                                                                                                {this.state.member.id !== item.user.id ? 
                                                                                                    memberRelationObj ?
                                                                                                        <React.Fragment>
                                                                                                            {memberRelationObj.relationOfB.substring(0,1).toUpperCase()+memberRelationObj.relationOfB.substring(1)}
                                                                                                            <span className="pi pi-pencil" onClick={(e) => this.frmRelation.popularFormData(this.state.member, item.user, memberRelationObj)} style={{position: "absolute", bottom: 5, cursor: "pointer", fontSize: 20}}></span>
                                                                                                        </React.Fragment>
                                                                                                        : <React.Fragment>
                                                                                                            Add Relationship
                                                                                                            <span className="pi pi-plus" onClick={(e) => this.frmRelation.popularFormData(this.state.member, item.user)} style={{position: "absolute", bottom: 5, cursor: "pointer"}}></span>
                                                                                                        </React.Fragment>
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )})
                                                                        }
                                                                        </div>
                                                                    </Fieldset>
                                                                    : ''
                                                                }
                                                            </Panel>
                                                        </div>
                                                    )
                                                })}
                                                
                                            </TabPanel>
                                            <TabPanel header="Household">
                                                {/* <div className="messages">
                                                    <ul>
                                                        {this.state.groupMembers.map((item, index) => {
                                                            return(
                                                                <li key={index}>
                                                                    <div style={{cursor: 'auto'}}>
                                                                        {item.user.avatar ? 
                                                                            <img src={item.user.avatar} width="45" alt="Avatar"/>
                                                                            : <Avatar className="topbar-profile-avatar" maxInitials={2} round={true} textSizeRatio={2} size={45} name={item.user.name} style={{margin: 0}} />
                                                                        }
                                                                        <div>
                                                                            <Link className="nav-link" to={`/constituent-management/contacts/${item.user.id}`} target="_blank">
                                                                                <span style={{display: 'block'}}><b>{item.user.name}</b></span>
                                                                            </Link>
                                                                            <span className="message">{item.memberRole}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div> */}
                                                <div className="p-grid">
                                                {this.state.groupMembers.map((item, index) => {
                                                    let memberRelationObj = groupMemberRelations.find(member => member.userB===item.user.id);
                                                    return (
                                                        <div className="p-col-12 p-md-4">
                                                            <div style={{border: "1px solid #eaeaea"}}>
                                                                <div className="p-padding-10-0 p-c" style={{background: "#ececec"}}>
                                                                    {item.user.avatar ? 
                                                                        <img src={item.user.avatar} width="50" alt="Avatar"/>
                                                                        : <Avatar maxInitials={2} round={true} size={50} textSizeRatio={2} name={item.user.name} style={{margin: 0}} />
                                                                    }
                                                                </div>
                                                                <div style={{minHeight: 80}}>
                                                                    <div className="p-c p-padding-5-0">{item.user.name}</div>
                                                                    <div className="p-c p-padding-5-0">Age: {item.user.ageString}</div>
                                                                    <div className="p-c p-padding-5-0" style={{position: "relative"}}>
                                                                        {this.state.member.id !== item.user.id ? 
                                                                            memberRelationObj ?
                                                                                <React.Fragment>
                                                                                    {memberRelationObj.relationOfB.substring(0,1).toUpperCase()+memberRelationObj.relationOfB.substring(1)}
                                                                                    <span className="pi pi-pencil" onClick={(e) => this.frmRelation.popularFormData(this.state.member, item.user, memberRelationObj)} style={{position: "absolute", bottom: 5, cursor: "pointer", fontSize: 20}}></span>
                                                                                </React.Fragment>
                                                                                : <React.Fragment>
                                                                                    Add Relationship
                                                                                    <span className="pi pi-plus" onClick={(e) => this.frmRelation.popularFormData(this.state.member, item.user)} style={{position: "absolute", bottom: 5, cursor: "pointer"}}></span>
                                                                                </React.Fragment>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )})
                                                }
                                                </div>
                                            </TabPanel>
                                            <TabPanel header="Relationship">
                                                <div className="messages">
                                                    <ul>
                                                        {this.state.userRelations.map((item, index) => {
                                                            return(
                                                                <li key={index}>
                                                                    <div style={{cursor: 'auto'}}>
                                                                        {item.avatarB? 
                                                                            <img src={item.avatarB} width="45" alt="Avatar"/>
                                                                            : <Avatar maxInitials={2} round={true} textSizeRatio={2} size={45} name={item.nameUserB} style={{margin: 0}} />
                                                                        }
                                                                        <div>
                                                                            <Link className="nav-link" to={`/constituent-management/contacts/${item.userB}`} target="_blank">
                                                                                <span style={{display: 'block'}}><b>{item.nameUserB}</b></span>
                                                                            </Link>
                                                                            <span className="message">{item.relationOfB}</span>
                                                                        </div>
                                                                        <div className="p-margin-left-20">
                                                                            {item.emergencyContact ? 
                                                                                <label className="p-padding-0-10" title="Emergency Contact" style={{color: '#fff', background: 'green'}}>EC</label>
                                                                                : ''
                                                                            }
                                                                            &nbsp;
                                                                            {item.pickupPerson ? 
                                                                                <label className="p-padding-0-10" title="Pickup Person" style={{color: '#fff', background: 'orange'}}>PP</label>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <TabView>
                                            <TabPanel header="Notes">
                                                <NoteList id={this.state.member.id} type='contact' />
                                            </TabPanel>
                                            
                                            {/* <TabPanel header="Membership Payment History">
                                                
                                            </TabPanel> */}
                                        </TabView>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
                }
            </div>
        );
    }
}