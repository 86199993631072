import React, { useEffect, useRef, useState } from "react";
import classNames from 'classnames';
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification, showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { Dropdown } from 'primereact/dropdown';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SplitButton } from "primereact/splitbutton";
import { APP_FEP, TABLE_PAGING_LENGTH, TAXONOMY_TYPE } from "../../constants";
import { getPageAssets, removeAsset, transferAsset } from "./AssetServices";
import { ConfirmNotification } from "../../core/components/ConfirmNotification";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AssetForm } from "./AssetForm";
import { Fieldset } from "primereact/fieldset";
import { getListTaxons } from "../taxon/TaxonServices";
import { getListInventories } from "../inventory/InventoryService";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { TransferAssetForm } from "./TransferAssetForm";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";

export const AssetListView = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const assetForm = useRef(null)
    const confirmNotify = useRef(null)
    const transferAssetForm = useRef(null)

    const [dataList, setDataList] = useState([])
    const [filter, setFilter] = useState({
        app: APP_FEP,
        name: '',
        branchIds: [],
        inventoryId: null,
        categoryId: null
    })
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: 0
    })
    
    const [isLoadTable, setLoadTable] = useState(true);
    const [branches, setBranchs] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [bulkAction, setBulkAction] = useState(null);
    const [selectedAssets, setSelectedAsses] = useState([]);

    useEffect(()=>{
        loadBranches()
        loadCategories()
    },[])

    useEffect(()=>{
        if(isLoadTable)
            loadPageAsset()
    },[isLoadTable])

    useEffect(()=>{
        if(filter.branchIds&&filter.branchIds.length>0){
            loadInventories(filter.branchIds[0])
        }else{
            setInventories([])
        }
    },[filter.branchIds])


    const loadBranches = () => {
        getListTaxons({type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res));
    }

    const loadInventories =  (branchId) => {
        getListInventories({app: APP_FEP, branchId: branchId}, true)
        .then(res => setInventories(res))
    }

    const loadCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value]}, true)
        .then(res => setCategories(res))
    }

    const loadPageAsset = () => {
        showloading()
        getPageAssets(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            stoploading();
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
            setSelectedAsses([])
        }).finally(stoploading())
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            loading:true,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const handleRemove = (id) => {
        removeAsset(id).then(res => {
            if (!res.errorCode) {
                loadPageAsset();
                showSuccessNotify('Successfully Deleted');
            } else {
                showErrorNotify('Cannot perform action');
            }
        });
    }

    const actionTemplate = (rowData) => {
        let dropActions = [
            { label: 'Edit Asset', icon: 'pi-md-pencil',
                command: () => assetForm.current.openForm(rowData)
            },
            { label: 'Clone Asset', icon: 'pi-md-content-copy',
                command: () => assetForm.current.openForm(rowData, true)
            },
            { label: 'Delete Asset', icon: 'pi-md-trash',
                command: () => confirmNotify.current.confirm(rowData.id)
            }
        ];
        return (
            <SplitButton label="View" className="p-button-constrast p-l" model={dropActions} onClick={() => history.push(`/assets-mgt/${rowData.id}`)}/>
        );
    };

    const onAddOrEdit = (data) => {
        assetForm.current.openForm(data)
    }

    const onChangePageLength = (e) => {
        setPageable({
            rows: e.value,
            page: 0
        })
        setLoadTable(true);
    };

    const onFilterChange = (property, value) => {
        setFilter({...filter, [property]: value})
        setLoadTable(true)
    }

    const applyFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setLoadTable(true)
    }

    const clearFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({
            app: APP_FEP,
            name: '',
            branchIds: [],
            inventoryId: null,
            categoryId: null
        })
        setLoadTable(true)
    }

    const tblHeader = (
        <TableHeader title="Assets" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                <Button className="p-button-success" label="Create Content" icon="pi-md-plus" onClick={() => onAddOrEdit()}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    const onApplyBulkAction = () =>{
        let assetIds = selectedAssets.map(p => p.id)
        if(bulkAction === 'transfer'){
            transferAssetForm.current.openForm(assetIds)
        }
    }

    const BULK_ACTIONS = [
        {label: 'Transfer', value: 'transfer'},
    ]

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Assets</BreadcrumbsItem>

            <AssetForm ref={assetForm}
                reloadTable={() => loadPageAsset()}
            />

            <ConfirmNotification ref={confirmNotify}
                submitConfirm={(id) => handleRemove(id)}
                message="Are you sure you want to remove?"
            />

            <TransferAssetForm ref={transferAssetForm}
                reloadTable={() => loadPageAsset()}
            />
            <div className="p-col-12">
                <Fieldset legend="Filter">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Name:</span>
                                <InputText value={filter.name} options={branches} onChange={(e) => setFilter({...filter, name: e.target.value})} />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Branch:</span>
                                <Dropdown value={filter.branchIds&&filter.branchIds.length>0?filter.branchIds[0]:null} options={branches} showClear onChange={(e) => onFilterChange("branchIds", e.value?[e.value]:[])} />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Category:</span>
                                <Dropdown value={filter.categoryId} options={categories} showClear onChange={(e) => onFilterChange("categoryId", e.value)} />
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Inventory:</span>
                                <Dropdown value={filter.inventoryId} options={inventories} showClear onChange={(e) => onFilterChange("inventoryId", e.value)} />
                            </div>
                        </div>
                        <div className="p-col-12 p-c">
                            <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} style={{width: 'auto'}} />
                            <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={()=>clearFilter()} style={{width: 'auto'}} />
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12">
                <Panel>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Bulk Action:</span>
                                <Dropdown className="p-margin-top-10" value={bulkAction} options={BULK_ACTIONS} showClear onChange={(e) => setBulkAction(e.value)} placeholder="Select action" />
                                <Button label="Apply" disabled={selectedAssets.length===0 || !bulkAction} onClick={() => onApplyBulkAction()} />
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
            <div className="p-col-12">
                <DataTable emptyMessage="There's no record found" header={tblHeader} lazy={true} paginator={true}
                    value={dataList}
                    first={pageable.page * pageable.rows}
                    onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                    selection={selectedAssets} onSelectionChange={e => setSelectedAsses(e.value)}
                >
                    <Column selectionMode="multiple" className="p-c" headerStyle={{width: '4em'}} />
                    <Column field="code" header="Code" />
                    <Column field="name" header="Name" />
                    <Column header="Category" body={rowData => rowData.category && rowData.category.name} />
                    <Column header="Branch" body={rowData => rowData.branch && rowData.branch.name} />
                    <Column header="Original Inventory" body={rowData => rowData.inventory && rowData.inventory.name} />
                    <Column header="Current Inventory" body={rowData => rowData.currentInventory && rowData.currentInventory.name} />
                    <Column field="active" header="Active" headerStyle={{width: '8em'}} bodyClassName="p-c" body={(rowData) => {return( rowData.active ? 'Yes' : 'No' )}} />
                    <Column header="Actions" body={actionTemplate} className='tc-actions' headerStyle={{width: '12em'}} bodyStyle={{ overlay: 'visible' }} />
                </DataTable>
                
            </div>
        </div>
    );
}
