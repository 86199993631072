import React from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {getPageFAQ, saveFAQ} from './EventService';
import { FAQ_REPLY_STATUS, FAQ_STATUS } from '../../constants';
import {FAQForm} from './FAQForm'

export class FAQListView extends React.Component {
    constructor() {
        super();

        this.state = {
            dataList: [],
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            errors: {},
        }
    }

    componentDidMount() {
        this.loadPageFAQ();
    }

    loadPageFAQ = () => {
        getPageFAQ(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            if(!res.errorCode) {
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
            }
        })
    }

    actionTemplate = (rowData) => {
        return (
            <Button label="View" className="p-button-constrast" onClick={() => this.handleViewFAQ(rowData)}/>
        );
    }

    handleViewFAQ = (rowData) => {
        if(rowData.status === FAQ_STATUS.new) {
            rowData = {
                ...rowData,
                status: FAQ_STATUS.viewed
            }

            saveFAQ(rowData)
            .then(data => {
                if(data && data.id) {
                    this.fqaForm.popularFormData(data);
                }
            })
        } else {
            this.fqaForm.popularFormData(rowData);
        }
    }

    statusTemplate = (rowData) => {
        return(
                rowData.status === FAQ_STATUS.viewed && rowData.answer
            ?
                FAQ_REPLY_STATUS.answered
            :   
                rowData.status === FAQ_STATUS.declined 
            ?
                FAQ_REPLY_STATUS.declined
            :   
                FAQ_REPLY_STATUS.not_answered
        )
    }

    render() {
        let header =
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                {/* <div style={{ 'float': 'left' }} >
                    <Button label="Create" icon="pi pi-plus" className="p-button-constrast" onClick={() => this.frmTime.popularFormData()}></Button>
                </div> */}
                {/* <div style={{ 'float': 'right' }}>
                    Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChagePageLength(e.value)} />
                    <Button className="p-button-constrast p-margin-left-10" icon="pi pi-refresh" onClick={() => this.loadPageEventTime()}/>
                </div> */}
                <h3 className="fle-datatable-header-title">FAQ Listing</h3>
            </div>;
        return(
            <React.Fragment>
                <div className="p-grid">
                    {/* <BreadcrumbsItem to={this.props.match.url}>Event Time</BreadcrumbsItem> */}

                    <FAQForm ref={el => this.fqaForm=el} 
                        refreshData={() => this.loadPageFAQ()}
                    />

                    <div className="p-col-12">
                        <DataTable className="p-datatable-scp" lazy={true} paginator={true} value={this.state.dataList}
                            first={this.state.page * this.state.rows}
                            rows={this.state.rows} totalRecords={this.state.total}
                            onPage={this.onPage} header={header} onSort={this.onSort}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                            responsive={true}
                        >
                            <Column field="id" header="ID" sortable={true}/>
                            <Column field="event.name" header="Event" sortable={true} />
                            <Column field="eventSession.title" header="Event Session" sortable={true}/>
                            <Column field="question" header="Question" sortable={true} />
                            <Column field="status" header="Status" body={this.statusTemplate}/>
                            <Column body={this.actionTemplate} className='tc-actions' style={{ width: '12em', overlay: 'visible' }} />
                        </DataTable>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}