import React, {Component} from "react";
import { getPageParticipants, participantCheckin, ParticipantServices } from "./ParticipantServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Fieldset } from "primereact/fieldset";
import { MultiSelect } from "primereact/multiselect";
import { TABLE_PAGING_LENGTH, PARTICIPANT_STATUS, APP_FEP, PRODUCT_TYPE } from "../../constants";
import { SplitButton } from "primereact/splitbutton";
import { showErrorNotify, showNotification, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { ConfirmNotification } from "../../core/components/ConfirmNotification";
import { ParticipantTransferForm } from "./ParticipantTransferForm";
import moment from "moment"
import { InputText } from "primereact/inputtext";
import { getListProducts, getProductAvailabilities } from "../../pim/product/ProductServices";
import { Calendar } from "primereact/calendar";
import { ViewLogApplicationForm } from "../../scp/application/ViewLogApplicationForm";
import classNames from "classnames";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport, getTimeZone } from "../../core/service/CommonService";
export class Participant extends Component{
    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            rows: 10,
            page: 0,
            total: 0,
            sortField: '',
            sortOrder: 0,
            filter:{},
            products: []
        }
        this.participantServices = new ParticipantServices();
    }

    componentDidMount(){
        this.loadPageParticipants();
        this.loadProducts()
    }

    handleCheckin = (data) => {
        if([PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value].includes(data.status)){
            showConfirmNotify({
                message: 'The order related with participant has not been paid completely. Must process on order first!',
                acceptLabel: 'Yes, go to the order',
                accept: () => this.props.history.push(`/orders/${data.orderId}`)
            })
        }else{
            participantCheckin(data.id)
            .then(res => {
                if(!res.errorCode){
                    if(res.inCompletedLog){
                        this.viewLogForm.openForm(data.id)
                        showErrorNotify("Please complete the application log as required to continue!")
                    }else{
                        showSuccessNotify("Checked-in")
                        this.loadPageParticipants()
                }
                }else{
                    showErrorNotify(res.errorMessage)
                }
            })
        }
    }

    loadPageParticipants(){
        getPageParticipants(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            this.setState({
                dataList: res.content,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
        })
    }

    onPage = (e) =>{
        this.setState({
            page: e.page
        },() => this.loadPageParticipants())
    }

    onSort = (e) =>{
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => this.loadPageParticipants())
    }

    loadProducts = () => {
        getListProducts({app: APP_FEP, types: [PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value]}).then(res=>{
            this.setState({products: res?res:[]})
        }) 
    }

    loadProductAvailabilities = () => {
        if(this.state.filter.refId){
            getProductAvailabilities(this.state.filter.refId, {isAvailableByCurrent: false}, false)
            .then(res=>{
                this.setState({availabilities: res?res:[]})
            }) 
        }else{
            this.setState({availabilities: []})
        }
        
    }

    handleUnregister = (ids) => {
        if(!ids || ids.length === 0) return
        this.participantServices.unregister(ids)
        .then(res => {
            if(!res.errorCode){
                this.loadPageParticipants();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onProductChange = (e) => {
        this.setState({
            filter: {...this.state.filter,
                refId: e.value,
                productAvailabilityId: null
            }
        },()=>{
            this.loadProductAvailabilities()
        })
    }

    onDateChange = (e) => {
        this.setState({
            filter: {...this.state.filter, 
                dateValue: e.value,
                dateFrom: e&&e.value[0]?moment(e.value[0]).format(moment.HTML5_FMT.DATE):'',
                dateTo: e&&e.value[1]?moment(e.value[1]).format(moment.HTML5_FMT.DATE):'',
                productAvailabilityId: null
            }
        })
    }

    renderListAvailability = () => {
        let {availabilities, filter} = this.state

        let tmpArr = []
        if(filter.dateValue && filter.dateValue.length>0){
            availabilities && availabilities.map(availability => {
                let tmpStartDate = moment(availability.startDate)
                let tmpEndDate = moment(availability.endDate)
    
                let tmpSelectedStartDate = moment(moment(filter.dateValue[0]).format('MM-DD-YYYY'))
                let tmpSelectedEndDate = moment(moment(filter.dateValue[1]).format('MM-DD-YYYY'))
                
                if(tmpSelectedStartDate.isValid() && tmpSelectedEndDate.isValid()){
                    if(tmpStartDate.isSameOrAfter(tmpSelectedStartDate) && tmpEndDate.isSameOrBefore(tmpSelectedEndDate)){
                        tmpArr.push(availability)
                    }
                }else if(tmpSelectedStartDate.isValid()){
                    if(tmpStartDate.isSameOrAfter(tmpSelectedStartDate) && tmpEndDate.isSameOrBefore(tmpSelectedStartDate)){
                        tmpArr.push(availability)
                    }
                }
            })
        }else{
            tmpArr = availabilities?[...availabilities]:[]
        }
        
        let tmpItems = tmpArr.map(item => {
            let startDateFmt = moment(item.startDate).format('MM/DD/YYYY')
            let endtDateFmt = moment(item.endDate).format('MM/DD/YYYY')
            let startTimeFmt = moment(item.startTime, moment.HTML5_FMT.TIME).format('hh:mm A')
            let endTimeFmt = moment(item.endTime, moment.HTML5_FMT.TIME).format('hh:mm A')

            return {value: item.id, label: (item.dateFrom===item.dateTo?(startDateFmt+' '+startTimeFmt+' - '+endTimeFmt):(startDateFmt+' '+startTimeFmt+ ' - '+endtDateFmt+' '+endTimeFmt))} 
        })
        return tmpItems
    }

    clearFilter = () => {
        this.setState({
            filter: {...this.state.filter,
                name: '',
                email: '',
                statuses: [],
                refId: null,
                dateValue: '',
                dateFrom: '',
                dateTo: '',
                productAvailabilityId: null,
                idOrOrderNumber:''
            }
        },() => this.loadPageParticipants())
    }

    applyFilter = () => {
        this.setState({
            page: 0
        },() => this.loadPageParticipants())
    }

    actionTemplate = (rowData) => {
        if(rowData.status!==PARTICIPANT_STATUS.canceled.value){
            const timezone = getTimeZone()
            const strNow = timezone?moment().tz(timezone).format('YYYY-MM-DD HH:mm'):moment().format('YYYY-MM-DD HH:mm')

            const now = moment(strNow)
            const end = moment(rowData.end)

            let isExpired = false

            if(now.isAfter(end))
                isExpired = true

            if(!rowData.checkin && !isExpired){
                let items = [
                    {label: 'View Log', icon: 'pi pi-search', command: () => this.viewLogForm.openForm(rowData.id)},
                ]

                // if(rowData.availabilityId && !isExpiredSchedule){
                //     items.push({label: 'Transfer', icon: 'pi pi-arrow-right p-size-18', command: () => transferForm.current.openForm(rowData.id)})
                // }

                return <SplitButton label="Check-in" className="p-button-constrast p-l" model={items} onClick={() => this.handleCheckin(rowData)} />
            }else{
                return <Button label="View Log" className="p-button-constrast p-l" onClick={() => this.viewLogForm.openForm(rowData.id)} />
            }
        }else{
            return ''
        }
    }

    onRedirectTo = (to) => {
        this.props.history.push(to)
    }

    onChagePageLength = l => {
        this.setState({
            rows: l,
            page: 0
        }, () => {
            this.applyFilter();
        })
    }

    applyBulkAction = () => {
        let selectedPartIds = this.state.selectedParticipants?this.state.selectedParticipants.map(p => p.id):[]
        this.handleUnregister(selectedPartIds)
        this.setState({selectedParticipants: []})
    }

    render(){
        const tblHeader = (
            <TableHeader title="Participants" 
                pageReport={getTablePageReport(this.state.page, this.state.rows, this.state.total)}
                changePageLength={(e) => this.onChagePageLength(e)}
                refresh={() => this.loadPageParticipants()}
                />
        )
        
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={`${this.props.match.url}`}>Participants</BreadcrumbsItem>

                <ParticipantTransferForm ref={el=>this.participantTransfer=el} 
                    refreshTable={() => this.loadPageParticipants()}
                />

                <ConfirmNotification ref={el => this.confirmNotify = el}
                    submitConfirm={(id) => this.handleUnregister([id])}
                    message="Are you sure you want to unregister?"
                />

                <ViewLogApplicationForm ref={el=>this.viewLogForm=el} />

                <div className="p-col-12">
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Name:</span>
                                            <InputText value={this.state.filter.name} onChange={(e) => this.setState({filter:{...this.state.filter, name: e.target.value}})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Email:</span>
                                            <InputText value={this.state.filter.email} keyfilter="email" onChange={(e) => this.setState({filter:{...this.state.filter, email: e.target.value}})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Status:</span>
                                            <MultiSelect options={Object.values(PARTICIPANT_STATUS)} value={this.state.filter.statuses} onChange={(e) => this.setState({filter:{...this.state.filter, statuses: e.value}})} />
                                        </div>
                                    </div>        
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Date Range:</span>
                                            <Calendar value={this.state.filter.dateValue} onChange={e => this.onDateChange(e)} showIcon={true} selectionMode="range" format="mm/dd/yy" />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-6 p-padding-bottom-0">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Product:</span>
                                                    <Dropdown value={this.state.filter.refId} options={this.state.products} showClear onChange={(e) => this.onProductChange(e)} />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-6 p-padding-bottom-0">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Availability:</span>
                                                    <Dropdown value={this.state.filter.productAvailabilityId} options={this.renderListAvailability()} showClear onChange={e => this.setState({filter:{...this.state.filter, productAvailabilityId: e.value}})} />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">ID/Code Order:</span>
                                            <InputText value={this.state.filter.idOrOrderNumber} onChange={(e) => this.setState({filter: {...this.state.filter, idOrOrderNumber: e.target.value}})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid p-margin-top-10">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={this.applyFilter} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={this.clearFilter} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                {/* <div className="p-col-12">
                    <Panel>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={this.state.bulkAction?this.state.bulkAction:''} options={bulkActionItems} onChange={(e) => this.setState({ bulkAction: e.value })} />
                                    <Button label="Apply" disabled={!this.state.bulkAction} onClick={() => this.applyBulkAction()} />
                                </div>            
                            </div>
                        </div>
                    </Panel>
                </div> */}
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={this.state.dataList}
                        header={tblHeader} 
                        first={this.state.page * this.state.rows}
                        onPage={this.onPage} onSort={this.onSort}
                        rows={this.state.rows} totalRecords={this.state.total}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        responsive={true}
                        selection={this.state.selectedParticipants} onSelectionChange={e => this.setState({selectedParticipants: e.value})}
                    >
                        {/* <Column selectionMode="multiple" style={{width: '4em'}} /> */}
                        <Column header="ID" field="id" sortable={true} style={{width: '7%'}} className="p-c" body={rowData => <Button label={rowData.id} icon="pi pi-search" className="p-button-info" onClick={() => this.props.history.push(`/participants/${rowData.id}`)}/>} />
                        <Column header="Name" field="name" body={rowData => <React.Fragment><div>{rowData.name}</div>{rowData.email && <div className="p-mt-1">{rowData.email}</div>}</React.Fragment>} />
                        <Column header="Product Name" field="refName"/>
                        <Column header="Booking Status" field="status" className="p-c" body={rowData => <span className={classNames('status', rowData.status)}>{PARTICIPANT_STATUS[rowData.status] && PARTICIPANT_STATUS[rowData.status].label}</span>}/>
                        <Column header="Check-in" field="checkin" className="p-c" body={rowData => rowData.status!==PARTICIPANT_STATUS.canceled.value?<span className={classNames('status', {'active': rowData.checkin, 'inactive': !rowData.checkin})}>{rowData.checkin?'Checked in':'Not yet'}</span>:''}/>
                        <Column header="Schedule" 
                            body={rowData => {
                                if(rowData.start || rowData.end){
                                    const startDate = moment(rowData.start).format('MM/DD/YYYY')
                                    const endDate = moment(rowData.end).format('MM/DD/YYYY')
                                    const startTime = moment(rowData.start).format('hh:mm A')
                                    const endTime = moment(rowData.end).format('hh:mm A')
                                    return <React.Fragment>
                                        <div className="p-margin-bottom-5">Date: {startDate===endDate?startDate:(startDate+' - '+endDate)}</div>
                                        <div>Time: {startTime+' - '+endTime}</div>
                                    </React.Fragment>    
                                }
                            }} 
                        />
                        <Column header="" body={this.actionTemplate} headerStyle={{width: '12em'}} bodyClassName="tc-actions"/>
                    </DataTable>
                </div>
            </div>
        );
    }
}
