import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Fieldset } from "primereact/fieldset";
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { RefundNotification } from './RefundNotification';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { moneyFormat } from '../../core/service/CommonService';
import { cancelOrder, completeOrder, getOrderDetails, convertToPostPay } from './OrderService';
import moment from 'moment';
import { APP_FEP, ORDER_STATE, PAYMENT_STATE, PRODUCT_CLASSIFICATION, PRICING_MODE, DEPOSIT_TYPE, PAYMENT_GATEWAY, PAYMENT_METHOD, PAYMENT_MODE} from '../../constants';
import { getListTransactions } from '../../scp/accounting/transaction/TransactionServices';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { completeInvoice, getListInvoices } from '../invoice/InvoiceServices';

const ObjectType = {
    order: {value: 'order', label: 'Order'}, invoice: {value: 'invoice', label: 'Invoice'}
}

const ActionType = {
    complete: {value: 'complete', label: 'Complete'}, cancel: {value: 'cancel', label: 'Cancel'}
}

export class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: null,
            invoices: [],
            transactions: [],
            actionDialogVisible: false,
            actionId: '',
            actionObj: '',
            actionType: '',
            actionReason: '',
            errors: {}
        }
    }

    componentDidMount() {
        this.getOrderDetails();
    }

    getOrderDetails = () => {
        getOrderDetails(this.props.match.params.id)
        .then(res => {
            if (!res.errorCode) {
                this.setState({order: res});
                this.loadListInvoices();
            } else {
                showErrorNotify(res.errorMessage)
            }
        });
    }

    handleCompleteOrder = () => {
        const params = { reason: this.state.actionReason }

        completeOrder(this.state.order.id, params)
        .then(res => {
            if (!res.errorCode) {
                this.setState({order: res});
                this.loadListInvoices();
                showSuccessNotify('Action submitted!')
            } else {
                if (res.errorCode === 400) this.setState({ errors: res.errorObj });
                showErrorNotify(res.errorMessage)
            }
        });
    }

    handleCompletedInvoice = () => {
        completeInvoice(this.state.actionId, this.state.gateway, this.state.method, this.state.transactionId, this.state.actionReason)
        .then(res => {
            if (!res.errorCode) {
                this.setState({order: res.order?res.order:{}})
                this.loadListInvoices()
                this.setState({actionDialogVisible: false})
                showSuccessNotify('Action submitted!')
            } else {
                if (res.errorCode === 400) this.setState({ errors: res.errorObj });
                showErrorNotify(res.errorMessage)
            }
        });
    }

    openActionDialog = (objType, action, data) => {
        const dialogHeader = ActionType[action].label + " " + ObjectType[objType].label
        const dialogPromoText = "You're attempting to " + action + " " + objType + ". This action can't undo!"
        
        let tmpActionId, tmpGateway, tmpMethod
        if(objType === ObjectType.order.value){
            if(action===ActionType.complete.value){
                let orderInvoices = this.state.invoices.filter(i => !i.orderItem&&i.status===PAYMENT_STATE.awaiting.value)
                let tmpOrderInvoice = orderInvoices.length>0?orderInvoices[0]:null
    
                tmpActionId = tmpOrderInvoice.id
                tmpGateway = PAYMENT_GATEWAY.ONSITE
                tmpMethod = tmpOrderInvoice.method&&tmpOrderInvoice.method===PAYMENT_METHOD.bank_transfer.value?tmpOrderInvoice.method:PAYMENT_METHOD.cash.value
            }
        }else{
            if(action===ActionType.complete.value){
                tmpActionId = data.id
                tmpGateway = PAYMENT_GATEWAY.ONSITE
                tmpMethod = data.method&&data.method===PAYMENT_METHOD.bank_transfer.value?data.method:PAYMENT_METHOD.cash.value
            }
        }

        this.setState({
            actionId: tmpActionId,
            actionObj: objType,
            actionType: action,
            dialogHeader: dialogHeader,
            dialogPromoText: dialogPromoText,
            gateway: tmpGateway,
            method: tmpMethod,
            transactionId: '',
            actionReason: '',
            errors: '',
            actionDialogVisible: true,
        });
    }

    onDialogConfirm = () => {
        if(this.state.actionObj===ObjectType.order.value){
            if(this.state.actionType===ActionType.complete.value){
                // this.handleCompleteOrder()
                this.handleCompletedInvoice()
            }else if(this.state.actionType===ActionType.cancel.value)
                this.cancelOrder()
        }else if(this.state.actionObj===ObjectType.invoice.value){
            if(this.state.actionType===ActionType.complete.value)
                this.handleCompletedInvoice()
        }
    }

    cancelOrder = () => {
        cancelOrder(this.props.match.params.id, this.state.actionReason)
        .then(res => {
            if (!res.errorCode) {
                this.setState({
                    order: res,
                    cancelVisible: false
                });
                showSuccessNotify('Action submitted!')
            } else {
                if (res.errorCode === 400) this.setState({ errors: res.errorObj });
                showErrorNotify(res.errorMessage)
            }
        });
    }

    reloadOrder = () => {
        this.getOrderDetails();
    }

    loadListInvoices = () => {
        getListInvoices({app: APP_FEP, orderId: this.props.match.params.id})
        .then(res => {
            if (!res.errorCode) {
                this.setState({ invoices: res ? res : [] });

                if(res && res.length>0){
                    const invoiceIds = res.map(i => (i.id))
                
                    getListTransactions({app: APP_FEP, invoiceIds: invoiceIds})
                    .then(res => {
                        this.setState({transactions: res?res:[]})
                    });
                }
            } else {
                showErrorNotify(res.errorMessage)
            }
        });
    }

    onChangeToPostPay = (id) => {
        showConfirmNotify({
            message: 'Are you sure to convert this order into Post Pay method?',
            accept: () => this.convertOrderToPostPay(id)
        })
    }

    convertOrderToPostPay = (id) => {
        convertToPostPay(id)
        .then(res => {
            if (!res.errorCode) {
                this.setState({
                    order: res
                });
                showSuccessNotify('Action submitted!')
            } else {
                showErrorNotify(res.errorMessage)
            }
        })
    }

    moneyFmt =(price) => {
        return moneyFormat(price, this.state.order.currency)
    }

    render() {
        var dialogActionFooter = (
            <div>
                <Button label="Submit" icon="pi pi-check" onClick={() => this.onDialogConfirm()} />
                <Button label="Cancel" icon="pi-md-close" onClick={() => this.setState({ actionDialogVisible: false })} />
            </div>
        );
        return (
            <div className="p-grid">
                <BreadcrumbsItem to="/orders">Orders</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>Order #{this.props.match.params.id}</BreadcrumbsItem>

                {this.state.order && 
                <React.Fragment>
                    <ConfirmNotification ref={el => this.completeNotify = el}
                        submitConfirm={() => this.handleCompleteOrder()}
                        message="Are you sure you want to complete order?"
                    />

                    <Dialog header={this.state.dialogHeader} footer={dialogActionFooter} visible={this.state.actionDialogVisible} width="500px" modal={true} onHide={() => this.setState({ actionDialogVisible: false })}>
                        <div className="p-grid p-fluid form-group">
                            {this.state.actionType===ActionType.complete.value && 
                                <div className="p-col-12 p-size-16 p-w-bold" style={{color: '#a00303'}}>
                                    The order has paid. Are you sure to take this action!
                                </div>
                            }
                            <div className="p-col-12 p-size-16 p-w-bold" style={{color: '#a00303'}}>
                                {this.state.dialogPromoText}
                            </div>
                            {this.state.actionType===ActionType.complete.value && 
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <label className="p-label">Select one of the payment options below:</label>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-py-2">
                                            <RadioButton inputId="rdPaymentMethod0" className="p-mr-2" onChange={(e) => this.setState({method: PAYMENT_METHOD.cash.value})} checked={this.state.method === PAYMENT_METHOD.cash.value} />
                                            <label htmlFor="rdPaymentMethod0">{PAYMENT_METHOD.cash.label}</label>
                                        </div>
                                        <div className="p-py-2">
                                            <RadioButton inputId="rdPaymentMethod1" className="p-mr-2" onChange={(e) => this.setState({method: PAYMENT_METHOD.bank_transfer.value})} checked={this.state.method === PAYMENT_METHOD.bank_transfer.value} />
                                            <label htmlFor="rdPaymentMethod1">{PAYMENT_METHOD.bank_transfer.label}</label>
                                        </div>
                                    </div>
                                    {this.state.method===PAYMENT_METHOD.bank_transfer.value &&
                                        <div className="p-col-12">
                                            <label className="p-label">* Transaction Number:</label>
                                            <InputText value={this.state.transactionId} onChange={e => this.setState({transactionId: e.target.value})} />
                                            <div className="p-form-error">{this.state.errors.transactionId}</div>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                            <div className="p-col-12">
                                <label className="p-label">Please input a reason:</label>
                                <InputTextarea rows={4} value={this.state.actionReason} onChange={(e) => this.setState({ actionReason: e.target.value })} placeholder="Enter a reason" />
                                <span className="p-form-error">{this.state.errors.reason}</span>
                            </div>
                        </div>
                    </Dialog>
                    
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <h1>
                                Order Details
                                <div className="p-toolbar-group-right">
                                    <Link to={`/orders`}>
                                        <Button icon="pi pi-bars" className="p-button-text" tooltip="Return" tooltipOptions={{ position: 'top' }} ></Button>
                                    </Link>
                                </div>
                            </h1>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label>No. #: <span className="p-w-bold">{this.state.order.orderNumber}</span></label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label>Order state: <span className="p-w-bold">{ORDER_STATE[this.state.order.orderState].label}</span></label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <label>Order status: <span className="p-w-bold">{PAYMENT_STATE[this.state.order.paymentState].label}</span></label>
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label>Payment mode: <span className="p-w-bold">{PAYMENT_MODE[this.state.order.paymentMode].label}</span></label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <label>Created At: <span className="p-w-bold">{this.state.order.checkoutCompletedAt && moment(this.state.order.checkoutCompletedAt).format('MM/DD/YYYY HH:mm')}</span></label>
                                </div>
                                {this.state.order.note && 
                                <div className="p-col-12">
                                    <label>Note: <span>{this.state.order.note}</span></label>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-4">
                                    <Fieldset legend="Buyer Details">
                                        <p><span className="info-title">Name:</span><span>{this.state.order.billName}</span></p>
                                        <p><span className="info-title">Email:</span><span>{this.state.order.billEmail}</span></p>
                                        <p><span className="info-title">Phone:</span><span>{this.state.order.billPhone}</span></p>
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-lg-3">
                                    <Fieldset legend="Price Details">
                                        <p className="p-size-16"><span className="info-title">Item total:</span><span className="info-price">{this.moneyFmt(this.state.order.itemsTotal)}</span></p>
                                        {this.state.order.pricingMode===PRICING_MODE.included_tax.value &&
                                        <p><span className="info-title">Item total without tax:</span><span className="info-price">{this.moneyFmt(this.state.order.itemsTotal - this.state.order.taxTotal)}</span></p>
                                        }
                                        {this.state.order.mapTaxes && Object.keys(this.state.order.mapTaxes).length>0 && 
                                            Object.values(this.state.order.mapTaxes).map(t => {
                                                return (
                                                    <p><span className="info-title">{t.name} <span className="p-text-italic">({t.rate}%)</span>:</span><span className="info-price">{this.moneyFmt(t.total)}</span></p>
                                                )
                                            })
                                        }
                                        <div className="p-hr p-padding-top-0"></div>
                                        <p className="p-size-16"><span className="info-title">Order total:</span><span className="info-price">{this.moneyFmt(this.state.order.total)}</span></p>
                                        {this.state.order.pledgedDepositTotal!==0 &&
                                        <p className="p-size-16"><span className="info-title">Security  deposit:</span><span className="info-price">{this.moneyFmt(this.state.order.pledgedDepositTotal)}</span></p>
                                        }
                                        {this.state.order.paymentState===PAYMENT_STATE.pending.value &&
                                        <p className="p-size-16"><span className="info-title">Checkout total:</span><span className="info-price">{this.moneyFmt(this.state.order.checkoutTotal)}</span></p>
                                        }                                        
                                        <p className="p-size-16"><span className="info-title p-w-bold">Paid total:</span><span className="info-price p-w-bold">{this.moneyFmt(this.state.order.paidTotal)}</span></p>
                                    </Fieldset>
                                </div>      
                                {this.state.order.orderState!==ORDER_STATE.canceled.value &&
                                    <div className="p-col-12 p-lg-5">
                                        <Fieldset legend="Order Action">
                                            <div className="p-grid">
                                                {this.state.order.paymentState!==PAYMENT_STATE.completed.value && 
                                                <div className="p-col-12">
                                                    <div class="p-messages p-component p-messages-warn p-messages-enter-done">
                                                        <div class="p-messages-wrapper p-p-size-16">
                                                            <span class="p-messages-icon pi pi-exclamation-triangle"></span>
                                                            <span class="p-messages-summary">Order need to pay remain: {this.moneyFmt(this.state.order.total - this.state.order.paidTotal)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <div className="p-col-12">
                                                    {this.state.order.paymentState!==PAYMENT_STATE.completed.value && 
                                                        <Button label={"Complete ("+this.moneyFmt(this.state.order.total - this.state.order.paidTotal)+")"} icon="pi-md-check" iconPos="left" onClick={() => this.openActionDialog(ObjectType.order.value, ActionType.complete.value, this.state.order)} className="p-button-success p-margin-right-10 p-margin-bottom-10" />
                                                    }

                                                    {this.state.order.paymentState!==PAYMENT_STATE.completed.value && this.state.order.paymentMode===PAYMENT_MODE.pre_pay.value &&
                                                        <Button label="Change to Post Pay" icon="pi pi-arrow-right" iconPos="left" onClick={() => this.onChangeToPostPay(this.state.order.id)} className="p-button-success p-margin-right-10 p-margin-bottom-10" />
                                                    }

                                                    {this.state.order.orderState!==ORDER_STATE.canceled.value && 
                                                        <Button label="Cancel Order" icon="pi-md-close" iconPos="left" onClick={() => this.openActionDialog(ObjectType.order.value, ActionType.cancel.value, this.state.order)} className="p-button-danger p-margin-bottom-10" />
                                                    }
                                                </div>
                                            </div>  
                                        </Fieldset>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <h1>Order Items</h1>

                            <div className="p-datatable p-component">
                                <div className="p-datatable-wrapper">
                                    <table style={{width: '100%'}}>
                                        <thead className="p-datatable-thead">
                                            <tr>
                                                <th>Product</th>
                                                <th style={{width: '5%'}}>Q.ty</th>
                                                <th style={{width: '8%'}}>Unit Price</th>
                                                {this.state.order.pricingMode===PRICING_MODE.normal.value &&
                                                <th style={{width: '8%'}}>Tax</th>
                                                }
                                                <th style={{width: '9%'}}>Total</th>
                                                <th style={{width: '10%'}}>Deposit</th>
                                                <th style={{width: '10%'}}>Checkout</th>
                                                <th style={{width: '20%'}}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody className="p-datatable-tbody">
                                            {this.state.order.items && this.state.order.items.map((item) => {
                                                let tmpProduct = item.product;

                                                return(
                                                    <React.Fragment key={item.id}>
                                                        <tr key={item.id} className="p-datatable-row" style={{backgroundColor: '#f0f0f0'}}>
                                                            <td>
                                                                <div className="p-w-bold">
                                                                    {item.productName}{item.productVariantName&&(' - ['+item.productVariantName+']')} 
                                                                </div>
                                                                {item.autoRecurring ? 
                                                                <div className="p-margin-10-0">
                                                                    - Subscription in every {item.subscriptionInfo.period} {item.subscriptionInfo.periodType}{item.subscriptionInfo.period>1?'s':''}
                                                                </div>
                                                                :
                                                                item.rentalInfo && 
                                                                <div className="p-margin-10-0">
                                                                    - Rent in {item.rentalInfo.rentalQty} {item.rentalInfo.rentalUnit}{item.rentalInfo.rentalQty>1?'s':''}
                                                                </div>
                                                                }
                                                            </td>
                                                            <td className="p-c">
                                                                {(![PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) || !item.pricedByChildren) && 
                                                                    item.quantity
                                                                }
                                                            </td>
                                                            <td className="p-r">
                                                                {[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren?'':this.moneyFmt(item.unitPrice)}
                                                            </td>
                                                            {this.state.order.pricingMode===PRICING_MODE.normal.value &&
                                                            <td className="p-r">
                                                                {[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren?'':this.moneyFmt(item.taxPrice)}
                                                            </td>
                                                            }
                                                            <td className="p-r">
                                                                {this.moneyFmt(item.totalFinal)}
                                                            </td>
                                                            <td className="p-r">
                                                                {item.deposit && this.moneyFmt(item.depositTotal)}
                                                                {item.deposit && <div className="p-size-12 italic">(As {DEPOSIT_TYPE[item.depositType].label})</div>}
                                                            </td>
                                                            <td className="p-r">{this.moneyFmt(item.checkout)}</td>
                                                            <td>
                                                                {item.scheduleInfo &&
                                                                    <React.Fragment>
                                                                        <div className="p-mb-1 p-ml-3">
                                                                            Start: {moment(item.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                        </div>
                                                                        <div className="p-mt-2 p-ml-3">
                                                                            End: {moment(item.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                        </div>        
                                                                    </React.Fragment>
                                                                }
                                                            </td>
                                                        </tr>
                                                        {item.childItems && item.childItems.map((childItem) => {

                                                            return(
                                                                <tr key={childItem.id} className="p-datatable-row" style={{backgroundColor: '#fff'}}>
                                                                    <td className="">
                                                                        <div className="p-padding-left-30">
                                                                            {childItem.productName}{childItem.productVariantName&&(' - ['+childItem.productVariantName+']')}
                                                                        </div>
                                                                        {childItem.rentalInfo && 
                                                                        <div className="p-margin-10-0 p-padding-left-30">
                                                                            - Rent in {childItem.rentalInfo.rentalQty} {childItem.rentalInfo.rentalUnit}{childItem.rentalInfo.rentalQty>1?'s':''}
                                                                        </div>
                                                                        }
                                                                    </td>
                                                                    <td className="p-c">
                                                                        {childItem.quantity}
                                                                    </td>
                                                                    <td className="p-r">
                                                                        {this.moneyFmt(childItem.total)}
                                                                    </td>
                                                                    {this.state.order.pricingMode===PRICING_MODE.normal.value &&
                                                                    <td className="p-r">
                                                                        {this.moneyFmt(childItem.taxPrice)}
                                                                    </td>
                                                                    }
                                                                    <td className="p-r">{this.moneyFmt(childItem.totalFinal)}</td>
                                                                    <td className="p-r"></td>
                                                                    <td className="p-r"></td>
                                                                    <td>
                                                                        {childItem.scheduleInfo &&
                                                                            <React.Fragment>
                                                                                <div className="p-mb-1 p-ml-3">
                                                                                    Start: {moment(childItem.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                                </div>
                                                                                <div className="p-mt-2 p-ml-3">
                                                                                    End: {moment(childItem.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                                </div>        
                                                                            </React.Fragment>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <h1>Invoices-Receipts</h1>
                            <DataTable value={this.state.invoices}>
                                <Column header="#No." field="id" bodyClassName="p-c" style={{width: '8%'}}
                                    body={rowData=> {
                                        return (
                                            <Link to={`/invoices/${rowData.id}`} className="p-button p-component p-button-info p-button-text-icon-left">
                                                <span className="pi pi-search p-c p-button-icon-left"></span> <span className="p-button-text p-c">{rowData.id}</span>
                                            </Link>
                                        )
                                    }}
                                />
                                <Column header="Payor" field="payorName" />
                                <Column header="Type" field="status" body={(rowData) => rowData.status===PAYMENT_STATE.completed.value?'Receipt':'Invoice'}/>
                                <Column header="Total" field="total" bodyClassName="p-r" body={rowData => this.moneyFmt(rowData.total)} />
                                <Column header="Amount Due" field="checkout" bodyClassName="p-r" body={rowData => rowData.status!==PAYMENT_STATE.completed.value?this.moneyFmt(rowData.checkout):''} />
                                <Column header="Paid" field="paid" bodyClassName="p-r" body={rowData => rowData.status!==PAYMENT_STATE.completed.value?this.moneyFmt(rowData.paid):this.moneyFmt(rowData.checkout)} />
                                <Column header="Created/Completed At" field="" bodyClassName="p-r" body={rowData => rowData.status!==PAYMENT_STATE.completed.value?moment(rowData.dateCreated).format('MM/DD/YYYY hh:mm A'):rowData.paymentCompletedAt&&moment(rowData.paymentCompletedAt).format('MM/DD/YYYY hh:mm A')} />
                                <Column header="" field="" bodyClassName="p-r" 
                                    body={(rowData) => {
                                        if((rowData.status===PAYMENT_STATE.pending.value || rowData.status===PAYMENT_STATE.awaiting.value)&&this.state.order.orderState!==ORDER_STATE.canceled.value){
                                            return <Button label="Complete" icon="pi-md-check" className="p-button-success" onClick={() => this.openActionDialog(ObjectType.invoice.value, ActionType.complete.value, rowData)}/>
                                        }
                                    }}
                                />
                            </DataTable>
                        </div>
                        {this.state.transactions.length>0 && 
                        <div className="card card-w-title">
                            <h1>Transactions</h1>
                            <DataTable value={this.state.transactions} responsive={true}>
                                <Column header="Invoice Ref.#" field="invoiceId" bodyClassName="p-c" style={{width: '8%'}}/>
                                <Column header="Gateway" field="gateway"/>
                                <Column header="Method" field="method"/>
                                <Column header="Trans Id" field="transId"/>
                                <Column header="Amount" field="amount" bodyClassName="p-r" body={(rowData) => this.moneyFmt(rowData.amount)} />
                                <Column header="Paid At" field="completedAt" bodyClassName="p-r" body={rowData => rowData.completedAt&&moment(rowData.completedAt).format('MM/DD/YYYY hh:mm A')} />
                            </DataTable>
                        </div>
                        }
                    </div>
                </React.Fragment>
                }
            </div>
        );
    }
}
