import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import React, { useState, useEffect, useRef } from 'react';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { TaxForm } from '../../pim/tax/TaxForm';
import { getPageTaxs, getTaxes } from '../../pim/tax/TaxSerivce';
import { saveScpConfig } from './ConfigruationServices';
import { PRICING_MODE } from '../../constants';
import { RadioButton } from 'primereact/radiobutton';

export const TenantTaxSetting = (props) => {
    const taxForm = useRef(null)

    const [dataConfig, setDataConfig] = useState({})
    const [pageable, setPageble] = useState({})
    const [dataList, setDataList] = useState([])
    const [isLoaded, setLoaded] = useState(true)
    const [taxes, setTaxes] = useState([])

    useEffect(() =>{
        setDataConfig(props.data)
    },[props.data])

    const handleSaveConfig = () => {
        saveScpConfig(dataConfig, 'tax')
        .then(res => {
            if(!res.errorCode){
                if(props.onSave){
                    props.onSave({pricingMode: res.pricingMode, defaultTax: res.defaultTax})
                }
                showSuccessNotify('Action Submitted');
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    useEffect(() =>{
        if(isLoaded){
            loadPageTaxs()
            loadTaxes()
            setLoaded(false)
        }
    },[isLoaded])

    const loadTaxes = () => {
        getTaxes()
        .then(data => {
            setTaxes(data)
        })
    }

    const loadPageTaxs = () => {
        getPageTaxs(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageble({
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
        })
    }

    const onPage = (e) =>{
        setPageble({
            page: e.page
        })
        setLoaded(true)
    }

    const onSort = (e) =>{
        setPageble({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoaded(true)
    }

    const onAddOrEdit = (data) => {
        taxForm.current.openForm(data)
    }

    const renderHeader = () => {
        return (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div style={{ 'float': 'left' }} >
                    <Button icon="pi pi-plus" className="p-button-constrast" label="Creat Tax" onClick={() => onAddOrEdit()} style={{'float':'left'}}/>
                </div>
                <Button className="p-button-constrast p-float-right" icon="pi pi-refresh" tooltip="Refresh" tooltipOptions={{position: "top"}} onClick={() => loadPageTaxs()} />
                <h3 className="fle-datatable-header-title">List of Taxes</h3>
            </div>
        );
    }

    const actionTemplate = (rowData) => {
        return (
            <Button className="p-button-primary" icon="pi-md-pencil" label="Edit" tooltip="Edit Tax" tooltipOptions={{position: "top"}} onClick={() => onAddOrEdit(rowData)}/>
        )
    }

    return(
        <div className="p-grid">
            <TaxForm ref={taxForm}
                refreshData={() => {loadPageTaxs(); loadTaxes()}}
            />
            <div className="p-col-12 p-md-6">
                <Fieldset legend="Tax Setting">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-w-bold p-display-inline-block">Pricing mode: </label>
                            <RadioButton value={PRICING_MODE.normal.value} className="p-margin-left-20" onChange={(e) => setDataConfig({...dataConfig, pricingMode: e.value})} checked={dataConfig.pricingMode===PRICING_MODE.normal.value}/>
                            <label className="p-radiobutton-label">{PRICING_MODE.normal.label}</label>
                            <RadioButton value={PRICING_MODE.included_tax.value} className="p-margin-left-20" onChange={(e) => setDataConfig({...dataConfig, pricingMode: e.value})} checked={dataConfig.pricingMode===PRICING_MODE.included_tax.value} />
                            <label className="p-radiobutton-label">{PRICING_MODE.included_tax.label}</label>

                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="p-label">Default Tax</label>
                            <Dropdown value={dataConfig.defaultTax} options={taxes} showClear onChange={(e) => setDataConfig({...dataConfig, defaultTax: e.value})} />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button label="Save" onClick={() => handleSaveConfig()} />
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12 p-md-6">
                <Fieldset legend="Tax Setting">
                    <DataTable
                        lazy={true} paginator={true} value={dataList}
                        first={pageable.page * pageable.rows}
                        header={renderHeader()} onPage={onPage} onSort={onSort}
                        rows={pageable.rows} totalRecords={pageable.total}
                        sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable />
                        <Column field="name" header="Name" sortable />
                        <Column field="rate01" header="Rate" body={rowData => rowData.rate01 + "%"} sortable />
                        <Column body={actionTemplate} headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />
                    </DataTable>
                </Fieldset>
            </div>
        </div>
    )
}